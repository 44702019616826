import { useEffect, useState } from "react";
import { useCountdown } from "usehooks-ts";

/** The countdown's options. */
type CountdownOptions = {
    /** The countdown's starting number, initial value of the returned number. */
    countStart: number;
    /**
     * The countdown's interval, in milliseconds.
     * @default 1000
     */
    intervalMs?: number;
    /**
     * True if the countdown is increment.
     * @default false
     */
    isIncrement?: boolean;
    /**
     * The countdown's stopping number. Pass `-Infinity` to decrease forever.
     * @default 0
     */
    countStop?: number;
};

/**
 * Custom hook for an interruptible timer.
 *
 * @param {Function} onTimerEnd - Callback function to be executed when the timer ends.
 * @param {Function} onTimerRestart - Callback function to be executed when the timer is restarted.
 * @returns {Object} - An object containing functions to control the timer.
 */
const useInterruptibleTimer = (onTimerEnd: () => void, onTimerRestart: () => void, options?: CountdownOptions) => {
    const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown(options ?? { countStart: 4, countStop: 0 });
    const [shouldRestart, setShouldRestart] = useState(false);

    useEffect(() => {
        startCountdown();
    }, [startCountdown]);

    useEffect(() => {
        if (shouldRestart) {
            onTimerRestart();
            resetCountdown();
            startCountdown();
            setShouldRestart(false);
        }
    }, [onTimerRestart, resetCountdown, shouldRestart, startCountdown]);

    useEffect(() => {
        if (count === 0) {
            onTimerEnd();
        }
    }, [count, onTimerEnd, stopCountdown]);

    return { setShouldRestart, stopCountdown, startCountdown };
};

export default useInterruptibleTimer;