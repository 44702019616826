import {
  ADD_BOOKMARK,
  ADD_BOOKMARK_FAIL,
  ADD_BOOKMARK_SUCCESS,
  GET_BOOKMARKS,
  GET_BOOKMARKS_FAIL,
  GET_BOOKMARKS_SUCCESS,
  REMOVE_BOOKMARK,
  REMOVE_BOOKMARK_FAIL,
  REMOVE_BOOKMARK_SUCCESS,
} from '../Constants/BookmarkConstants';

const initialState = {
  loading: false,
  result: null,
  error: null,
  addRemoveLoading: false,
  addRemoveError: null,
};

const bookmarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKMARKS: {
      return {
        ...state,
        loading: true,
        error: null,
        result: null,
      };
    }
    case GET_BOOKMARKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        result: action.payload.data.data.result,
      };
    }
    case GET_BOOKMARKS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        result: null,
      };
    }
    case ADD_BOOKMARK: {
      return {
        ...state,
        addRemoveLoading: true,
        addRemoveError: null,
      };
    }
    case ADD_BOOKMARK_SUCCESS: {
      return {
        ...state,
        addRemoveLoading: false,
      };
    }
    case ADD_BOOKMARK_FAIL: {
      return {
        ...state,
        addRemoveLoading: false,
        addRemoveError: action.error,
      };
    }
    case REMOVE_BOOKMARK: {
      return {
        ...state,
        addRemoveLoading: true,
        addRemoveError: null,
      };
    }
    case REMOVE_BOOKMARK_SUCCESS: {
      const { id } = action.payload.config.params;
      const newResult = state.result?.data?.filter((e) => e.id !== +id);

      return {
        ...state,
        addRemoveLoading: false,
        addRemoveError: null,
        result: { data: newResult },
      };
    }
    case REMOVE_BOOKMARK_FAIL: {
      return {
        ...state,
        addRemoveLoading: false,
        addRemoveError: action.error,
      };
    }
    default:
      return state;
  }
};

export default bookmarkReducer;
