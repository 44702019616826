import React from 'react';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './ContactUsButton.module.scss';

const ContactUsButton = ({
  image, title, details, detailsStyles, onClick, isMobile,
}) => {
  const { language } = useSelector(
    (state) => state.authentication,
  );
  return (
    <Col
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classNames(
        styles.contactUsButton,
        onClick ? !isMobile && styles.pointer : '',
      )}
      onClick={!isMobile && onClick}
    >
      <div className={styles.buttonDiv}>
        <div className={styles.image}>
          <img
            className={styles.imageStyle}
            src={image}
            alt="ContactUs Button"
          />
        </div>
        <div className={language === 'ar' ? styles.textContainer : styles.textContainerEng}>
          <div>
            <span className={styles.titleStyle}>{title}</span>
          </div>
          <div className={language === 'ar' ? 'direction-left' : null}>
            <span className={classNames(styles.detailsStyle, detailsStyles)}>
              {details}
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

ContactUsButton.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  detailsStyles: PropTypes.string,
  isMobile: PropTypes.bool,
};

ContactUsButton.defaultProps = {
  detailsStyles: null,
  isMobile: false,
};

export default ContactUsButton;
