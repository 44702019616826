const GET_VIDEO_DETAILS = 'GET_VIDEO_DETAILS';
const GET_VIDEO_DETAILS_FAIL = 'GET_VIDEO_DETAILS_FAIL';
const GET_VIDEO_DETAILS_SUCCESS = 'GET_VIDEO_DETAILS_SUCCESS';
const CLEAR_VIDEO_DETAILS = 'CLEAR_VIDEO_DETAILS';

const GET_SHOW_DETAILS = 'GET_SHOW_DETAILS';
const GET_SHOW_DETAILS_FAIL = 'GET_SHOW_DETAILS_FAIL';
const GET_SHOW_DETAILS_SUCCESS = 'GET_SHOW_DETAILS_SUCCESS';
const CLEAR_SHOW_DETAILS = 'CLEAR_SHOW_DETAILS';

export {
  GET_VIDEO_DETAILS, GET_VIDEO_DETAILS_FAIL, GET_VIDEO_DETAILS_SUCCESS, CLEAR_VIDEO_DETAILS,
  GET_SHOW_DETAILS, GET_SHOW_DETAILS_FAIL, GET_SHOW_DETAILS_SUCCESS, CLEAR_SHOW_DETAILS,
};
