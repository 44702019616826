import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Eye from "@Assets/Images/component-icons/Eye";
import EyeSlash from "@Assets/Images/component-icons/EyeSlash";
import "./InputField.scss";

const InputField = (props) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShouldShowPassword((prev) => !prev);
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.authentication);
  const [field, meta] = useField(props);
  const {
    size,
    type,
    label,
    alertText,
    placeholder,
    onChange,
    disabled,
    autocomplete,
    as,
    checkbox,
    readOnly,
    rows,
    inputClassName,
    inpt,
    clicked,
    labelClassName,
    groupClassName,
  } = props;
  const isPassword = props.type === "password";
  const fieldType = isPassword ? (shouldShowPassword ? "text" : "password") : type;
  const hasError = meta.touched && meta.error;

  return !checkbox ? (
    <Form.Group className={groupClassName}>
      <Form.Label dir="auto" className={labelClassName}>
        {label}
      </Form.Label>
      <div className={isPassword ? 'fieldContainer' : ''}>
        <Form.Control
          dir="auto"
          id={language === "ar" ? "ARform" : "ENform"}
          className={classNames(
            readOnly ? "disabled" : inputClassName,
            "inputContainer"
          )}
          disabled={disabled}
          name={field.name}
          size={size}
          type={fieldType}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete={autocomplete}
          value={field.value || inpt}
          as={as}
          readOnly={readOnly}
          rows={rows}
        />
        {isPassword && (
          <div className="eyeIconButton">
            {shouldShowPassword ? (
              <Eye onClick={togglePasswordVisibility} />
            ) : (
              <EyeSlash onClick={togglePasswordVisibility} />
            )}
          </div>
        )}
      </div>
      {
        hasError && (
          <Form.Text dir="auto" className="error">
            {t(alertText)}
          </Form.Text>
        )
      }
    </Form.Group>
  ) : (
    <Form.Group>
      <div className="d-flex flex-row align-items-center gap-1">
        <Form.Check
          dir="auto"
          type={type}
          name="checkBox"
          checked={clicked}
          onChange={onChange}
        />
        {label && (
          <span dir="auto" onClick={onChange} className="form-check-label">
            {label}
          </span>
        )}
      </div>
      {hasError && (
        <Form.Text dir="auto" className="error">
          {t(alertText)}
        </Form.Text>
      )}
    </Form.Group>
  );
};

InputField.defaultProps = {
  size: "",
  label: "",
  type: "text",
  alertText: "",
  disabled: false,
  placeholder: "",
  error: false,
  autocomplete: "",
  as: "input",
  checkbox: false,
  readOnly: false,
  rows: "1",
  inputClassName: "",
  inpt: "",
  clicked: null,
  labelClassName: "",
  groupClassName: "",
};

InputField.propTypes = {
  label: PropTypes.string,
  alertText: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["lg", "sm", ""]),
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "date",
    "name",
    "checkbox",
  ]),
  autocomplete: PropTypes.string,
  as: PropTypes.string,
  checkbox: PropTypes.bool,
  readOnly: PropTypes.bool,
  rows: PropTypes.string,
  inputClassName: PropTypes.string,
  inpt: PropTypes.string,
  clicked: PropTypes.bool,
  labelClassName: PropTypes.string,
  groupClassName: PropTypes.string,
};

export default InputField;
