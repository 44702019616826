import { convertSeasonsObjToArray, getInitialSeason } from "@Utils/common";
import { MediaType, ITrack, Season } from "@Utils/commonTypes";
import constants from "@Utils/constants";
import { useMemo, useState, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ISeasonMap } from "src/alballam-api-types";


function useSelectedSeason(seasons: ISeasonMap, initialSeasonId: string | undefined, id: string | undefined, mediaType: MediaType, track?: ITrack) {
    const seasonsArray = useMemo(() => convertSeasonsObjToArray(seasons), [seasons]);
    const initialSelectedSeason = getInitialSeason(seasonsArray, initialSeasonId, track?.seasonId);
    const [currentSeason, setCurrentSeason] = useState(initialSelectedSeason);
    const navigate = useNavigate();
    const location = useLocation();

    useLayoutEffect(() => {
        setCurrentSeason(initialSelectedSeason);
    }, [initialSelectedSeason]);


    const updateCurrentSeason = (season: Season) => {
        setCurrentSeason(season);
        if (mediaType === MediaType.Series) {
            navigate({
                pathname: constants.newScreens.details.series(id).season(season.id).as,
                search: location.search
            }, { replace: true });
        }
        if (mediaType === MediaType.Show) {
            navigate({
                pathname: constants.newScreens.details.show(id).season(season.id).as,
                search: location.search
            }, { replace: true });
        }
    }

    return { currentSeason, setCurrentSeason: updateCurrentSeason, seasonsArray };
}

export default useSelectedSeason;