/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-empty */
/* eslint-disable camelcase */
/* eslint-disable  react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { isMobile, isSafari } from "react-device-detect";
import ErrorBoundary from "../../Atoms/ErrorBoundary";
import VideoPlayer from "../../Atoms/VideoPlayer";
import { isIpad } from "../../../Utils/deviceDetect";
import "./index.scss";
import AdvertisementPlayerControls from "./AdvertisementPlayerControls/AdvertisementPlayerControls";
import { useDispatch } from "react-redux";
import { advertismentWatched } from "../../../Store/Actions/AdvertisementAnalyticsActions";

let endingAdInProgress = false;
const AdvertisementPlayer = ({ result, onAdEnd, onBack }) => {
  let added = 0;

  const initialState = useMemo(
    () => ({
      initialVideoReady: false,
      playing: false,
      urlToPlay: "",
      buffering: false,
      currentTime: 0,
      bufferedTime: 0,
      duration: 0,
      volume: 1,
      muted: true,
      playerReady: false,
      adNumber: 0,
    }),
    []
  );

  const videoPlayerRef = useRef(null);
  const videoRef = useRef(null);

  const [status, setStatus] = useState({
    ...initialState,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus((prevState) => ({
      ...prevState,
      urlToPlay: `${result[status.adNumber]?.baseVideoURL}${
        result[status.adNumber]?.videoLinks?.stream
      }`,
      currentTime: 0,
      playing: status.playerReady,
    }));
    dispatch(advertismentWatched(result[status.adNumber].id));
  }, [status.adNumber, dispatch]);

  const onVideoEnd = useCallback(() => {
    try {
      endingAdInProgress = true;
      if (result?.length === 1) onAdEnd();
      else if (result?.length > 1) {
        if (status.adNumber === result?.length - 1) {
          onAdEnd();
        } else {
          setStatus((prevState) => ({
            ...prevState,
            adNumber: prevState.adNumber + 1,
            urlToPlay: "",
            playing: false,
          }));
        }
      }
    } catch {}
  }, [result, onAdEnd, status.adNumber]);

  useEffect(() => {
    if (isMobile && isSafari) {
      if (document.getElementsByTagName("video")[0] && status.playing) {
        document.getElementsByTagName("video")[0].play();
      }
      if (document.getElementsByTagName("video")[0] && !status.playing) {
        document.getElementsByTagName("video")[0].pause();
      }
    }
  }, [status.playing]);

  const onSafariMobileReady = useCallback(() => {
    endingAdInProgress = false;
    const updatedStatus = { ...status };
    updatedStatus.playing = true;
    updatedStatus.duration = result[status.adNumber]?.duration;
    updatedStatus.currentTime = 0;
    updatedStatus.playerReady = true;
    updatedStatus.muted = true;
    updatedStatus.volume = 0;
    setStatus(updatedStatus);
  }, [status, result]);

  const onReadyHandler = useCallback(() => {
    endingAdInProgress = false;
    const updatedStatus = { ...status };
    updatedStatus.playerReady = true;
    updatedStatus.playing = true;
    updatedStatus.muted = false;
    setStatus(updatedStatus);
  }, [status]);

  const onStartHandler = useCallback(() => {
    setStatus((prevState) => ({ ...prevState, playerReady: true }));
  }, [status]);

  const onBufferHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      buffering: true,
    }));
  }, []);

  const onBufferEndHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      buffering: false,
    }));
  }, []);

  const onProgressHandler = useCallback(
    (data) => {
      try {
        const newState = { ...status };
        newState.bufferedTime = data?.loadedSeconds || 0;
        newState.currentTime =
          isMobile && isSafari
            ? document.querySelector("video")?.currentTime
            : data.playedSeconds;

        // on iPad the video continues to play but it won't enter the buffer end callback
        // so we need to remove the buffer state here
        if (isIpad() || isSafari) {
          newState.buffering = false;
        }
        if (!endingAdInProgress) setStatus(newState);
      } catch {
        setStatus((prevState) => ({
          ...prevState,
          buffering: false,
          // playing: false,
        }));
      }
    },
    [status, result]
  );

  useEffect(() => {
    if (isMobile && isSafari && status.playerReady) {
      document.querySelector("video").onended = onVideoEnd;
      if (status.playing) {
        if (added === 0) {
          // document.getElementsByTagName("video")[0].play();
          added = 1;
          document.querySelector("video").onprogress = onProgressHandler;
          document.querySelector("video").ontimeupdate = onProgressHandler;
        }
      }
      if (document.getElementsByTagName("video")[0] && !status.playing) {
        added = 0;
        document.querySelector("video").onprogress = null;
        document.querySelector("video").ontimeupdate = null;
        // document.getElementsByTagName("video")[0].pause();
      }
    }
  }, [status, onProgressHandler]);

  const onPlayHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  }, []);

  const onVolumeClickHandler = useCallback(
    (volume = null) => {
      try {
        let newVolume = volume;
        if (newVolume === null) {
          newVolume = status.volume ? 0 : 1;
        }
        if (isMobile && isSafari && newVolume === 0) {
          videoRef.current.muted = true;
        } else if (isMobile && isSafari) {
          videoRef.current.muted = false;
        }
        if (isIpad() && newVolume === 0) {
          setStatus((prevState) => ({
            ...prevState,
            muted: true,
            volume: 0,
          }));
          return;
        }

        setStatus((prevState) => ({
          ...prevState,
          volume: +newVolume,
          muted: false,
        }));
      } catch {}
    },
    [status.volume]
  );

  const onDurationHandler = useCallback((data) => {
    setStatus((prevState) => ({
      ...prevState,
      duration: data,
    }));
  }, []);

  // const handleOnPlaying = () => {
  //   videoRef.current.muted = true;
  //   setStatus((prevState) => ({
  //     ...prevState,
  //     muted: true,
  //   }));
  // };

  const renderVideoPlayer = () => (
    <VideoPlayer
      ref={videoPlayerRef}
      url={status.urlToPlay}
      playing={status.playing}
      volume={status.volume}
      muted={status.muted}
      onDurationHandler={onDurationHandler}
      onBuffer={onBufferHandler}
      onBufferEnd={onBufferEndHandler}
      onProgress={onProgressHandler}
      onEnded={onVideoEnd}
      onReady={onReadyHandler}
      onStart={onStartHandler}
      onError={(e) => console.log("errorrrr", e)}
    />
  );

  return (
    <ErrorBoundary>
      <>
        {result && status.urlToPlay && (
          <div className="watch-container">
            <div className="video-player-wrapper">
              <AdvertisementPlayerControls
                id={result[status.adNumber].id}
                redirectUrl={result[status.adNumber]?.redirectUrl}
                isSkippable={result[status.adNumber].isSkip}
                skipDuration={result[status.adNumber]?.skipDuration}
                onSkipAd={onVideoEnd}
                playing={status.playing}
                duration={status.duration}
                currentTime={status.currentTime}
                bufferedTime={status.bufferedTime}
                onPlay={onPlayHandler}
                buffering={status.buffering}
                volume={status.volume}
                onVolumeClick={onVolumeClickHandler}
                tracksLoaded={status.playerReady}
                onBack={onBack}
              >
                {isMobile && isSafari ? (
                  <video
                    className="safariVideo"
                    ref={videoRef}
                    src={status.urlToPlay}
                    onLoadedMetadata={onSafariMobileReady}
                    id="myPlayer_html5_api_ad"
                    preload="auto"
                    // onPlaying={handleOnPlaying}
                    muted
                    playsInline
                    width="100%"
                    autoPlay
                    height="100%"
                    onError={onVolumeClickHandler}
                  />
                ) : (
                  renderVideoPlayer()
                )}
              </AdvertisementPlayerControls>
            </div>
          </div>
        )}
      </>
    </ErrorBoundary>
  );
};

export default AdvertisementPlayer;

AdvertisementPlayer.propTypes = {
  result: PropTypes.array.isRequired,
  onAdEnd: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
