/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import Onesignal, { useOneSignalSetup } from 'react-onesignal';
import useGeoLocation from 'react-ipgeolocation';
import config from '../Utils/config';
import { redirect } from 'react-router-dom';
import constants from '@Utils/constants';

const useOneSignal = () => {
  const OneSignal = window.OneSignal || [];

  const { country } = useGeoLocation();

  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!config.isLocalEnv) {
      const options = {
        autoRegister: false,
        autoResubscribe: true,
        notifyButton: {
          enable: true,
          showCredit: false,
        },
        allowLocalhostAsSecureOrigin: config.env === config.environments.dev,
        safari_web_id: config.oneSignalSafariWebID,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
      };

      const events = [
        // {
        //   listener: 'on',
        //   event: 'notificationDisplay',
        //   callback: (event) => {
        //     console.log('oneSignal', 'notificationDisplay', event);
        //   },
        // },
        // {
        //   listener: 'on',
        //   event: 'getNotificationPermission',
        //   callback: (event) => {
        //     console.log('oneSignal', 'getNotificationPermission', event);
        //   },
        // },
        // {
        //   listener: 'on',
        //   event: 'isPushNotificationsSupported',
        //   callback: (event) => {
        //     console.log('oneSignal', 'isPushNotificationsSupported', event);
        //   },
        // },
        // {
        //   listener: 'on',
        //   event: 'isPushNotificationsEnabled',
        //   callback: (event) => {
        //     console.log('oneSignal', 'isPushNotificationsEnabled', event);
        //   },
        // },
        // {
        //   listener: 'on',
        //   event: 'subscriptionChange',
        //   callback: (event) => {
        //     console.log('oneSignal', 'subscriptionChange', event);
        //   },
        // },
        // {
        //   listener: 'on',
        //   event: 'notificationPermissionChange',
        //   callback: (event) => {
        //     console.log('oneSignal', 'notificationPermissionChange', event);
        //   },
        // },
      ];

      Onesignal.initialize(config.oneSignalAppId, options, events);
    }
  }, []);

  useOneSignalSetup(() => {
    // console.log('FINISHED INITIALIZING ONE SIGNAL', 'useOneSignalSetup');

    Onesignal.sendTag('global', '1');
    setInitialized(true);
  });

  useEffect(() => {
    if (country && isInitialized && OneSignal?.sendTag) {
      Onesignal.sendTag('country', country);
    }
  }, [country, OneSignal, isInitialized]);

  const notifListen = () => {
    const notificationClickedHandler = (data) => {
      const { type, id, showId } = data?.data;

      switch (type) {
        case 'movie':
          redirect(constants.newScreens.details.movie(id).as);
          break;

        case 'show':
          redirect(constants.newScreens.details.series(id).as);
          break;

        case 'season':
          console.warn('[useOneSignal] Scenario yet to be implemented');
          redirect(constants.newScreens.details.series(id).season(showId).as);
          break;

        case 'episode':
          console.warn('[useOneSignal] Scenario yet to be implemented');
          redirect(constants.newScreens.details.series(id).as);
          break;
        case 'general':
          break;
        default:
          break;
      }

      notifListen();
    };

    if (!OneSignal) {
      return;
    }

    OneSignal.push(['addListenerForNotificationOpened', notificationClickedHandler]);
  };

  notifListen();
};

export default useOneSignal;
