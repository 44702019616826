import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import VideoPlayerLoader from '../VideoPlayerLoader/VideoPlayerLoader';
import ClickableItem from '../ClickableItem/ClickableItem';
import CloseButton from '../../../Assets/Images/exit.svg';
import styles from './ThumbnailLoader.module.scss';
import AutoRenew from '../autoRenew/AutoRenew';
import { useThrottledCallback } from 'use-debounce';

const ThumbnailLoader = ({
  thumbnail,
  onClose,
  title,
  seasonTitle,
  episodeNbr,
  episodeTitle,
  isKillSession,
  onKillSessionClick,
  epiName,
}) => {
  const throttleOnClose = useThrottledCallback(onClose, 1000);
  return (
    <div
      className={classNames(styles.thumbnail, isKillSession ? styles.isKillSession : '')}
      style={{ backgroundImage: !isKillSession ? `url(${thumbnail})` : null }}
    >
      {!isKillSession && <VideoPlayerLoader />}
      {isKillSession && <div className={styles.AutoRenew}><AutoRenew isKillSession onKillSessionClick={onKillSessionClick} /></div>}
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          {!isKillSession && <div className={styles.title}>{title}</div>}
          {seasonTitle && episodeNbr && episodeTitle && !isKillSession && (
            <div className={styles.seasonDetails}>
              <div className={styles.episode}>
                {seasonTitle}
                :
                {epiName}
                {episodeNbr}
              </div>
              {' '}
              <span className={styles.episode}>
                {`"${episodeTitle}"`}
              </span>
            </div>
          )}
        </div>
        <div className={classNames(styles.closeButtonContainer, isMobile ? styles.closeButtonContainerMobile : '')}>
          <ClickableItem classes={styles.closeButton} onClick={throttleOnClose}>
            <img
              src={CloseButton}
              className={styles.closeButtonIcon}
              alt="Close Button"
            />
          </ClickableItem>
        </div>
      </div>
    </div>
  );
};

ThumbnailLoader.propTypes = {
  thumbnail: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
  seasonTitle: PropTypes.number,
  episodeNbr: PropTypes.number,
  episodeTitle: PropTypes.string,
  isKillSession: PropTypes.bool,
  onKillSessionClick: PropTypes.func,
};

ThumbnailLoader.defaultProps = {
  thumbnail: undefined,
  onClose: undefined,
  title: undefined,
  seasonTitle: undefined,
  episodeNbr: undefined,
  episodeTitle: undefined,
  isKillSession: null,
  onKillSessionClick: null,
};

export default ThumbnailLoader;
