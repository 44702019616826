import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "./SubscribePackage.module.scss";
import Bullet from "../../../Assets/Images/bullet-icon.svg";
import ClickableItem from "../../Atoms/ClickableItem/ClickableItem";
import { LazyLoadImage } from "react-lazy-load-image-component";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const SubscribePackage = ({
  active,
  price,
  onClick,
  currency,
  subscriptions,
  countryPrice,
  language,
  isExpiredPackage,
  description,
  descriptionTwo,
  descriptionThree,
  date,
  isCoupon,
  isOwned,
  duration,
  imageUrl,
}) => {
  const { t } = useTranslation();
  return (
    <ClickableItem
      classes={classNames(
        styles.container,
        subscriptions
          ? styles.subscriptionsActive
          : active
          ? styles.active
          : isOwned
          ? styles.ownedPackage
          : "",
        isExpiredPackage && styles.isExpiredPackage
      )}
      onClick={onClick}
      style={isOwned ? { cursor: "default" } : null}
    >
      <div className={styles.bodyContainer}>
        <div>
          <div className={styles.imageContainer}>
            {imageUrl && (
              <LazyLoadImage
                src={imageUrl}
                className={styles.packageImage}
                alt="Package"
              />
            )}
          </div>
          <div
            className={classNames(
              styles.topContainer,
              !descriptionTwo && !descriptionThree
                ? styles.topContainerNoBorder
                : ""
            )}
          >
            <div className={styles.durationContainer}>
              <span
                dir="auto"
                className={classNames(styles.durationText, {
                  [styles.isCoupon]: isCoupon,
                })}
              >
                {isCoupon ? t("globals.enjoyCoupon") : description}
              </span>
              {date && isOwned && (
                <span className={styles.headerLight}>
                  {`${t("globals.validFor")}  ${new Date(
                    moment(date)
                  ).toLocaleDateString(
                    language === "ar" ? "ar" : "en",
                    options
                  )}`}
                </span>
              )}
            </div>

            <div className={styles.priceContainer}>
              <div dir="auto" className={styles.priceAmt}>
                {isCoupon
                  ? ""
                  : currency === "KWD" || currency === "SR"
                  ? countryPrice
                  : price}
              </div>
              <div dir="auto" className={styles.priceCur}>
                {isCoupon
                  ? ""
                  : currency === "KWD"
                  ? "KWD"
                  : currency === "SR"
                  ? "SR"
                  : "USD"}
              </div>
              {duration && (
                <span dir="auto" className={styles.months}>
                  /
                  {duration / 30 === 1
                    ? t("globals.month")
                    : `${Math.round(duration / 30)} ${t(
                        "globals.months"
                      )}`}{" "}
                </span>
              )}
            </div>
          </div>
        </div>
        {(descriptionTwo || descriptionThree) && (
          <div className={styles.offre}>
            {descriptionTwo && (
              <div
                dir="auto"
                className={language === "ar" ? styles.line : styles.lineEng}
              >
                <img src={Bullet} alt="Check" className={styles.check} />
                <span>{descriptionTwo}</span>
              </div>
            )}
            {descriptionThree && (
              <div
                dir="auto"
                className={language === "ar" ? styles.line : styles.lineEng}
              >
                <img src={Bullet} alt="Check" className={styles.check} />
                <span>{descriptionThree}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </ClickableItem>
  );
};

SubscribePackage.propTypes = {
  active: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  subscriptions: PropTypes.bool.isRequired,
  countryPrice: PropTypes.number,
  isExpiredPackage: PropTypes.bool,
  description: PropTypes.string,
  descriptionTwo: PropTypes.string,
  descriptionThree: PropTypes.string,
  date: PropTypes.string,
  isCoupon: PropTypes.bool,
  imageUrl: PropTypes.string,
};

SubscribePackage.defaultProps = {
  onClick: () => null,
  countryPrice: null,
  isExpiredPackage: null,
  description: null,
  descriptionTwo: null,
  descriptionThree: null,
  date: "",
  isCoupon: false,
  imageUrl: "",
};

export default SubscribePackage;
