import { CLEAR_FORGOT_PASSWORD_DATA, FORGOT_PASSWORD } from '../Constants/ForgotPasswordConstants';

const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD,
  payload: {
    request: {
      url: 'api/user/sendResetOtp',
      method: 'put',
      authenticated: false,
      data: { email },
    },
  },
});

const clearForgotPasswordData = () => ({
  type: CLEAR_FORGOT_PASSWORD_DATA,
  payload: null,
});

export { forgotPassword, clearForgotPasswordData };
