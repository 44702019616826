/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleError } from '../../Utils/httpClient';
import {
  authenticate,
  loginUserUsingFacebook,
  loginUserUsingGoogle,
  loginUserUsingApple,
} from '../../Services/Authentication';
import LoginForm from '../../Components/Organisms/LoginForm';
import { saveAuthentication } from '../../Store/Actions/AuthenticationActions';
import { checkSubscription } from '../../Store/Actions/SubscriptionValidationActions';
import NonAuthenticatedLayout from '../../Components/Organisms/NonAuthenticatedLayout/NonAuthenticatedLayout';
import constants from '../../Utils/constants';
import useQuery from '../../Hooks/useQuery';
import queryString from 'query-string';
import { getLiveChannelById } from '@Utils/common';
import { cancelPaymentFlowCookies } from '@Molecules/PaymentModalNouveau/helpers';

function getPaymentFlowDestinationUrl(currentPathRedirectUrl, titleRed, qrId) {
  const titleRedParam = titleRed ? `&title=${titleRed}` : '';
  const qrIdParam = qrId ? `&qrId=${qrId}` : '';
  const optionalSlash = currentPathRedirectUrl.startsWith('?') ? '/' : '';

  return `${optionalSlash}${currentPathRedirectUrl}${titleRedParam}${qrIdParam}`;
}

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [googleData, setGoogleData] = useState(null);
  const [facebookData, setFacebookData] = useState(null);
  const location = useLocation();
  const LocationRedirectUrl = location?.state?.redirect_url;
  const currentPathRedirectUrl = useQuery()?.get('redirect_url');
  const { selected } = useSelector((state) => state.videoSubscription);
  const { checkSubscription: { error: checkError, result: checkResult } } = useSelector((state) => state.subscriptionValidation);
  const { language } = useSelector((state) => state.authentication);
  const [captchaValidated, setCaptchaValidated] = useState(false);

  const titleRed = useQuery()?.get('title');
  const qrId = useQuery()?.get('qrId');

  const onCaptchaChange = useCallback((value) => {
    if (value) {
      setCaptchaValidated(true);
    }
  }, []);

  const cancelPaymentFlowCookiesCallback = useCallback(() => cancelPaymentFlowCookies(), []);
  useEffect(() => {
    window.addEventListener('popstate', cancelPaymentFlowCookiesCallback);
    return () => {
      window.removeEventListener('popstate', cancelPaymentFlowCookiesCallback);
    }
  }, [cancelPaymentFlowCookiesCallback]);

  const redirectUser = useCallback(async (isPaymentFlow = false) => {
    setLoading(false);
    if (!LocationRedirectUrl) {
      dispatch(saveAuthentication({ isSignedIn: true, isGuest: null }));
      navigate({ pathname: constants.newScreens.profiles.view, search: location.search }, { state: location.state });
      return;
    }
    let destinationUrl = LocationRedirectUrl;
    if (isPaymentFlow && currentPathRedirectUrl) {
      destinationUrl = getPaymentFlowDestinationUrl(currentPathRedirectUrl, titleRed, qrId);
    }
    if (!isPaymentFlow) {
      destinationUrl += location?.search;
    }

    if (location?.state?.isLiveFlow === true) {
      const selectedChannel = await getLiveChannelById(dispatch, location?.state?.channelId);
      const isDestinationProfile = destinationUrl.includes(constants.screens.profile);

      // If owned, redirect to live
      if (selectedChannel?.owned === true && isDestinationProfile) {
        // Extract destination URL and add search params
        destinationUrl = LocationRedirectUrl.split('redirect_url=')[1];
        destinationUrl += location?.search;
        location.state.channel = selectedChannel;
      }

      dispatch(saveAuthentication({ isSignedIn: true, isGuest: null }));
      navigate(
        {
          pathname: constants.screens.profiles,
          search: `redirect_url=${destinationUrl}`,
        },
        {
          state: {
            requireProfile: true,
            ...location?.state,
          },
          replace: true,
        }
      );
      return;
    }

    dispatch(saveAuthentication({ isSignedIn: true, isGuest: null }));
    navigate({ pathname: constants.newScreens.profiles.view, search: queryString.stringify({ redirect_url: destinationUrl }) }, { state: { payment: true, LocationRedirectUrl } });
  }, [dispatch, LocationRedirectUrl, location, navigate, currentPathRedirectUrl, qrId, titleRed]);

  const checkPayment = (authData) => {
    dispatch(saveAuthentication({ ...authData, isGuest: null }));
    if (selected) {
      dispatch(
        checkSubscription(
          /* eslint-disable-next-line no-underscore-dangle */
          selected?.id || selected?.package?._id || selected?.package?.id,
          selected?.type,
        ),
      );
    } else {
      redirectUser();
    }
  };

  useEffect(() => {
    if (!(selected && (checkResult || checkError))) {
      return;
    }
    redirectUser(checkResult?.owned === true);
  }, [selected, checkResult, checkError, redirectUser]);

  const submitLogin = async (user) => {
    try {
      setLoading(true);
      setError('');
      const authenticateUser = await authenticate(user.username, user.password, language);
      if (!authenticateUser.data.result.isOtp) {
        checkPayment(authenticateUser.data.result);
      } else {
        navigate(`${constants.screens.signUp}${location.screens ?? ''}`, { state: { isOtp: true, username: user.username, redirect_url: LocationRedirectUrl }, replace: true })
      }
    } catch (e) {
      setLoading(false);
      if (e.data.data) {
        setError(e.data.data.message);
      } else setError(t('errors.somethingWentWrong'));
    }
  };

  const signInUsingGoogle = async (data) => {
    try {
      setLoading(true);
      setError(false);
      setGoogleData(null);
      const result = await loginUserUsingGoogle(data);
      setGoogleData(data);
      checkPayment(result.data.data.result);
    } catch (e) {
      setLoading(false);
      setError(handleError(e, t));
    }
  };

  const handleGoogleFailure = async (data) => {
    if (data === 'User already connected') {
      await signInUsingGoogle(googleData);
    }
  };

  const signInUsingFacebook = async (data) => {
    try {
      setLoading(true);
      setError(false);
      setFacebookData(null);
      const result = await loginUserUsingFacebook(data);
      setFacebookData(data);
      checkPayment(result.data.data.result);
    } catch (e) {
      setLoading(false);
      setError(handleError(e, t));
    }
  };

  const handleFacebookFailure = async (data) => {
    if (data === 'User already connected') {
      await signInUsingFacebook(facebookData);
    }
  };

  const signInUsingApple = async (data) => {
    if (data?.error) {
      return;
    }
    try {
      setLoading(true);
      setError(false);
      await loginUserUsingApple(data).then((res) => checkPayment(res?.data?.data?.result));
    } catch (e) {
      setLoading(false);
      setError(handleError(e, t));
    }
  };

  useEffect(() => {
    if (document.getElementById('route-content')) {
      document.getElementById('route-content').classList.remove('hidden');
    }
  }, []);
  return (
    <NonAuthenticatedLayout title={t('globals.login')} showLogo={false}>
      <LoginForm
        onSubmit={(user) => submitLogin(user)}
        loginError={error}
        onGoogleLogin={signInUsingGoogle}
        onGoogleFailure={handleGoogleFailure}
        onFacebookFailure={handleFacebookFailure}
        onFacebookLogin={signInUsingFacebook}
        onAppleLogin={signInUsingApple}
        loading={loading}
        redirectUrl={LocationRedirectUrl}
        captchaValidated={captchaValidated}
        onCaptchaChange={onCaptchaChange}
      />
    </NonAuthenticatedLayout>
  );
};

export default Login;
