import {
  GET_TOP_SEARCHES,
  CLEAR_TOP_SEARCHES,
} from '../Constants/TopSearchesConstants';

export const getTopSearches = () => ({
  type: GET_TOP_SEARCHES,
  payload: {
    request: {
      url: '/api/search/top',
      method: 'get',
    },
  },
});

export const clearTopSearches = () => ({
  type: CLEAR_TOP_SEARCHES,
  payload: null,
});
