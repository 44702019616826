import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { setCoupon } from '../../Store/Actions/CouponActions';
import constants from '../../Utils/constants';
import SubscribePackage from '../../Components/Molecules/SubscribePackage/SubscribePackage';
import styles from './Packages.module.scss';
import { getSubscriptionPackages } from '../../Store/Actions/SubscriptionPackagesActions';
import { saveSubscriptionSource } from '../../Store/Actions/subscriptionsSourceActions';
import { setSelectedSubscription } from '../../Store/Actions/VideoSubscriptionActions';
import { clearUserPurchases, getUserPurchases } from '../../Store/Actions/UserPurchasesActions';
import useQuery from '../../Hooks/useQuery';
import queryString from 'query-string';
import { useAppSelector } from '@Store/hooks';

const Packages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.subscriptionPackages);
  const { language, userAuthType } = useSelector((state) => state.authentication);
  const { sourceType } = useAppSelector((state) => state.subscriptionSource);
  const { result } = useSelector((state) => state.userPurchases);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const location = useLocation();
  const coupon = useQuery().get('coupon');

  useEffect(() => {
    dispatch(getSubscriptionPackages());
    if (userAuthType === 'user') {
      dispatch(getUserPurchases());
    } else {
      dispatch(clearUserPurchases());
    }
  }, [dispatch, userAuthType]);

  const availablePackages = useMemo(() => packages?.result?.data ?? [], [packages]);
  const isSubscribed = userAuthType === 'user' ? result?.subscriptions?.length > 0 : false;

  const handleSubscribeClick = () => {
    const destination = location.state?.redirect_url ?? constants.screens.profile;
    if (coupon) {
      dispatch(setCoupon(coupon));
    }

    if (sourceType === null) {
      dispatch(saveSubscriptionSource({
        sourceType: 'route',
        routeName: 'packages',
      }));
    }

    dispatch(setSelectedSubscription({ package: selectedPackage, type: 'subscription' }));
    if (userAuthType !== 'user') {
      navigate(constants.screens.login, {
        state: {
          redirect_url: `${constants.newScreens.pay.subscription.selectMethod(selectedPackage._id).as}?origin=${destination}`
        },
      });
      return;
    }

    const parsedLocation = queryString.parse(location.search);
    navigate({
      pathname: constants.newScreens.pay.subscription.selectMethod(selectedPackage._id).as,
      search: queryString.stringify({ origin: destination, ...parsedLocation }),
    });
  };

  return (
    <div className={styles.profileCon}>
      <div className={styles.headerContainer}>
        <div className={styles.subsTitle}>{t('payment.vipBtn')}</div>
        <Button
          disabled={!selectedPackage || isSubscribed}
          onClick={handleSubscribeClick}
          className={styles.subscribeNow}
        >
          <span className="subscribe">{t('globals.subscribeNow')}</span>
        </Button>
      </div>
      <div className={styles.profileDetailsContainer}>
        <div className={styles.packagesContainer}>
          {availablePackages.map((pkg) => {
            const isPackageOwned = result?.subscriptions.length > 0 && pkg.price === result?.subscriptions.at(0).price;
            const isActive = isPackageOwned ? false : selectedPackage?._id === pkg._id;
            return (
              <SubscribePackage
                key={pkg._id}
                language={language}
                countryPrice={pkg.countryPrice}
                price={pkg.price}
                isOwned={isPackageOwned}
                active={isActive}
                duration={pkg.duration}
                currency={pkg.currency}
                onClick={() => setSelectedPackage(pkg)}
                description={pkg.description}
                descriptionTwo={pkg.descriptionTwo}
                descriptionThree={pkg.descriptionThree}
                imageUrl={pkg.image}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Packages;
