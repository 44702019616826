import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import propTypes from 'prop-types';
import { isShowOrSeries } from '../../../Utils/common';
import MovieCard from '../../../Components/Molecules/MovieCard/MovieCard';

function ResultCard({ searchItem, index }) {
  const { type, paid, video, containsFreeEpisodes, isContinueWatching } = searchItem;
  const searchSelector = useParams();
  const isFree = useMemo(() => {
    if (!isShowOrSeries(type || video?.type)) {
      if (isContinueWatching) return video?.paid === false;

      return paid === false;
    }

    return containsFreeEpisodes;
  }, [containsFreeEpisodes, isContinueWatching, paid, type, video?.paid, video?.type]);

  return (
    <MovieCard
      key={index}
      title={searchItem.title}
      sectionTitle=""
      trailer={searchItem.trailer}
      id={searchItem.type === 'show' ? searchItem.showId : searchItem.id}
      type={searchItem.type}
      thumbnail={searchItem.medium}
      rating={searchItem.rating}
      isInSearch
      searchSelector={searchSelector.search}
      duration={searchItem.duration}
      genres={searchItem.genres}
      date={searchItem.date}
      numberOfSeasons={searchItem.numberOfSeasons}
      isFree={isFree}
      isComingSoon={searchItem.comingSoon}
      titleUrl={window.decodeURI(searchItem.slug)}
    />
  );
}

ResultCard.propTypes = {
  searchItem: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
};

export default ResultCard;
