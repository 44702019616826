const CLEAR_ACTORS = 'CLEAR_ACTORS';
const GET_ACTORS = 'GET_ACTORS';
const GET_ACTORS_FAIL = 'GET_ACTORS_FAIL';
const GET_ACTORS_SUCCESS = 'GET_ACTORS_SUCCESS';

const CLEAR_AWARDS = 'CLEAR_AWARDS';
const GET_AWARDS = 'GET_AWARDS';
const GET_AWARDS_FAIL = 'GET_AWARDS_FAIL';
const GET_AWARDS_SUCCESS = 'GET_AWARDS_SUCCESS';

export {
  CLEAR_ACTORS, GET_ACTORS, GET_ACTORS_FAIL, GET_ACTORS_SUCCESS,
  CLEAR_AWARDS, GET_AWARDS, GET_AWARDS_SUCCESS, GET_AWARDS_FAIL,
};
