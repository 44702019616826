import {
    CLEAR_LIVE_DETAILS,
    GET_LIVE_DETAILS,
    GET_LIVE_DETAILS_SUCCESS,
    GET_LIVE_DETAILS_FAIL,
  } from '../Constants/LiveChannelConstants';
  
  const initialState = {
    loading: null,
    result: null,
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIVE_DETAILS: {
            return {
              ...state,
              error: null,
              loading: true,
              result: null,
            };
          }
          case GET_LIVE_DETAILS_SUCCESS: {
            const { result } = action.payload.data.data;
            result.showSubscription = !(
              (result.paidArticle && result.subscribed)
              || !result.paidArticle
            );
            if (!result.showSubscription) {
              if (result.link && !result.link.startsWith('http')) {
                result.link = `https://player.vimeo.com/video/${result.link}`;
              } else {
                result.link = result.vimeoId && result.vimeoId !== ''
                  ? `https://player.vimeo.com/video/${result.vimeoId}`
                  : result.link;
              }
            }

            return {
              ...state,
              loading: false,
              result,
            };
          }
          case GET_LIVE_DETAILS_FAIL: {
            return {
              ...state,
              loading: false,
              error: action.error,
            };
          }
          case CLEAR_LIVE_DETAILS: {
            return initialState;
          }
      
      default:
        return state;
    }
  };
  
  export default reducer;
  