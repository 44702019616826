import {
  SELECTED_SUBSCRIPTION,
  CLEAR_SELECTED_SUBSCRIPTION,
  VIDEO_SUBSCRIPTION,
  CLEAR_VIDEO_SUBSCRIPTION_DATA,
  CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA,
} from '../Constants/VideoSubscriptionConstants';

export const setSelectedSubscription = (data) => ({
  type: SELECTED_SUBSCRIPTION,
  payload: {
    data,
  },
});

export const clearSelectedSubscription = () => ({
  type: CLEAR_SELECTED_SUBSCRIPTION,
  payload: null,
});

export const videoSubscription = (data, origin, isMyFatoorah, paymentType) => {
  let url = data.action;
  let payId = data.id;

  if (data?.id?.toString()?.includes('?')) {
    payId = data.id.split('?')?.[0];
  }

  let subData = {
    paymentType: data.paymentType,
    id: payId,
    type: data.type,
    origin: origin || '',
  };

  if (data.type === 'subscription') {
    if (!isMyFatoorah) {
      url = `subscription?type=knet${origin ? `&origin=${origin}` : ''}`;
      subData = {
        /* eslint-disable-next-line no-underscore-dangle */
        package: data.package._id || data.package.id,
        source: data.source,
      };
    } else {
      url = `subscription?type=myFatoorah&myFatoorahPaymentMethod=${paymentType}${origin ? `&origin=${origin}` : ''}`;
      subData = {
        /* eslint-disable-next-line no-underscore-dangle */
        package: data.package._id || data.package.id,
        source: data.source,
      };
    }
  }
  if (data.coupon && data?.action !== 'purchase') {
    subData.coupon = data.coupon;
  }

  return {
    type: VIDEO_SUBSCRIPTION,
    payload: {
      request: {
        url: `api/${url}`,
        method: 'POST',
        authenticated: true,
        data: subData,
      },
    },
  };
};

export const clearVideoSubscriptionData = () => ({
  type: CLEAR_VIDEO_SUBSCRIPTION_DATA,
  payload: null,
});

export const clearVideoSubscriptionInitialData = () => ({
  type: CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA,
  payload: null,
});
