import React, { useCallback, useEffect, useState } from 'react';
import constants from 'src/Utils/constants';
import './LiveBottomSheet.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LiveStreamIcon from '../../../Assets/Images/liveIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
// import { setSelectedEticket } from '../../../Store/Actions/VideoEticketActions';
import { handleLiveChannelLogic } from '../../../Utils/common';

const LiveBottomSheet = ({ showBottomSheet, setShowBottomSheet, videoStream }) => {
  const [pageRoute, setPageRoute] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userAuthType } = useSelector((state) => state.authentication);

  const onLiveStreamClick = useCallback(
    (element) => (e) => {
      e.stopPropagation();
      // if(element?.package){
      //   dispatch(setSelectedEticket({...element,action:'e-ticket'}));
      // }
      return handleLiveChannelLogic(element, userAuthType, navigate, constants.newScreens.live(element?._id).as);
    },
    [navigate, userAuthType]
  );

  useEffect(() => {
    if (!pageRoute) {
      setPageRoute(location?.pathname);
    }
    if (pageRoute && location?.pathname !== pageRoute && showBottomSheet) {
      setShowBottomSheet(false);
    }
  }, [location]);

  return (
    <>
      <div className="LiveBottomSheetContainer">
        <div className="streams-content">
          <div className="stream-title">
            <img src={LiveStreamIcon} alt="liveIcon" />
            <div>{t('globals.liveChannels')}</div>
          </div>
          <div className="streams">
            {videoStream.map((stream, index) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
              <div
                onClick={onLiveStreamClick(stream)}
                className={`stream ${index !== videoStream?.length - 1 ? 'borderBottom' : ''}`}
                key={index} // Add a key for unique identification
              >
                {stream?.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveBottomSheet;