import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { showNotification } from "@Utils/notifications";
import { useAppSelector } from "@Store/hooks";

/**
 * Custom hook that displays a warning notification when a payment attempt is made with a different user account.
 * 
 * @remarks
 * This hook checks if the user is signed in and if the user ID on the TV is different from the user ID on the web. 
 * If the conditions are met, a notification is shown with a warning message.
 * 
 * @example
 * ```typescript
 * useAccountWarningOnPaymentAttempt();
 * ```
 */
function useAccountWarningOnPaymentAttempt() {
  const { _id, isSignedIn, language } = useAppSelector((state) => state.authentication);
  const [params] = useSearchParams();
  const userId = params.get('userId');

  // used for when making purchases on web, if id of user on TV is different from web, this notifications appears
  useEffect(() => {
    if (!_id || !userId || !isSignedIn) return;
    if (_id !== userId) {
      const message =
        language === "en"
          ? "Please login with the same account"
          : "الرجاء تسجيل الدخول بنفس الحساب";
      const position = language === "en" ? "top-left" : "top-right";
      showNotification(message, "danger", "", position);
    }
  }, [_id, isSignedIn, language, userId]);
}

export default useAccountWarningOnPaymentAttempt;
