import React from 'react'
import { Navigate } from 'react-router-dom'
import constants from '@Utils/constants'
import SignUp from '@Pages/SignUp/SignUp'

function SignUpRouters({ isSignedIn }) {
    if (isSignedIn) {
        return <Navigate to={constants.newScreens.profiles.view} />
    }
    return <SignUp />;
}

export default SignUpRouters