import React from 'react';
import { Navigate } from 'react-router-dom';
import constants from '@Utils/constants';
import ChoosePayment from '@Pages/ChoosePayment/ChoosePayment';

function ChoosePaymentRouter({ isSignedIn, selected }) {
    if (!isSignedIn) {
        return <Navigate to={constants.screens.login} />
    }
    if (isSignedIn && !selected) {
        return <Navigate to={constants.screens.browse} />
    }
    return <ChoosePayment />;
}

export default ChoosePaymentRouter