import {
  CLEAR_GENRE_TYPE,
  GET_LATEST_LIST,
  GET_LATEST_LIST_SUCCESS,
  GET_LATEST_LIST_FAIL,
} from '../Constants/GenreTypeConstants';
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-plusplus */

const initialState = {
  type: null,
  result: null,
  loading: false,
  error: null,
};

const genreTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LATEST_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_LATEST_LIST_SUCCESS:
      return {
        ...state,
        result: action.payload.data.data.result,
        loading: false,
      };
    case GET_LATEST_LIST_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_GENRE_TYPE:
      return initialState;
    default:
      return state;
  }
};

export default genreTypeReducer;
