import React from 'react';

import ReactCountryFlag from 'react-country-flag';
import styles from './CodeFlag.module.scss';

const CountryRow = ({
  data, label, innerProps, innerRef,
}) => (
  <div className={styles.codeFlagContainer} ref={innerRef} {...innerProps}>
    <ReactCountryFlag className={styles.flag} countryCode={data.code} svg />
    <p className={styles.label}>{label}</p>
  </div>
);
export default CountryRow;
