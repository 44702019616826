import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import constants from '../../Utils/constants';
import getComingSoonList from '../../Store/Actions/ComingSoonActions';
import Genre from '../../Components/Organisms/Genre/Genre';
import ErrorComponent from '../../HOC/ErrorComponent';

let arr = [];
let limit = 5;

const ComingSoon = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result, loading, error } = useSelector((state) => state.comingSoon);
  const [array, setArray] = useState([]);

  useEffect(() => {
    arr = [];
    setArray([]);
    for (let i = 0; i < Math.min(result?.data?.length, 24); i += 1) {
      arr.push(result?.data[i]);
    }
    setArray([...arr]);
  }, [result]);

  const showLess = useCallback(() => {
    if (!result || !result?.data) {
      return;
    }
    let i = arr.length;
    limit += 24;
    if (
      array.length < result?.data?.length
      && result?.data?.length - array.length > limit
    ) {
      for (i; i < limit; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
    if (result?.data?.length - array.length < limit) {
      for (i; i < result?.data?.length; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
  }, [result, array.length]);

  useEffect(() => {
    dispatch(getComingSoonList());
  }, [dispatch]);

  if (error) {
    return (
      <ErrorComponent error={error} pageType={constants.screens.comingSoon} />
    );
  }

  return (
    <Genre
      loading={loading}
      pageTitle={result?.title || t('globals.comingSoon')}
      array={array}
      showLess={showLess}
      result={result}
      isComingSoonPage
    />
  );
};
export default ComingSoon;
