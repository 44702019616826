/* eslint-disable no-underscore-dangle */
import i18n from "i18next";
import { v4 as uuidv4 } from "uuid";
import { loginAsGuest } from "../Services/Authentication";
import { saveAuthentication } from "../Store/Actions/AuthenticationActions";
import { store } from "../Store/store";
import { AxiosRequestHeaders } from "axios";
import Episode, { IPackage } from "@Atoms/Episode/Episode";
import { getAllPackages } from "src/alballam-api";
import { ISeasonMap, Movie, Series } from "src/alballam-api-types";
import { MediaType, Season, TagType } from "./commonTypes";
import { NavigateFunction } from "react-router-dom";
import constants from "./constants";
import { getLiveChannels } from "@Store/Actions/LiveChannelsActions";
import { Dispatch } from "@reduxjs/toolkit";
import { Channel } from "@Pages/Home/Home";
import { IHoveredCard } from "@Organisms/ExpandedCard/ExpandedCard.utils";
import { createSelector } from "reselect";

/**
 * Determines if the current environment is a webview.
 *
 * This function checks if the code is running in a webview by examining the user agent string
 * and other properties of the `navigator` object. It specifically checks for iOS devices and
 * webview indicators in the user agent string.
 *
 * @returns {boolean} `true` if the environment is a webview, `false` otherwise.
 */
export const isWebview = () => {
  if (typeof window === 'undefined') { return false }

  const navigator = window.navigator;

  //@ts-expect-error does exist
  const standalone = navigator.standalone;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return ios ? !standalone && !safari : /\bwv\b/.test(userAgent);
}


/**
 * Retrieves the initial season from an array of seasons based on the provided initial season ID or tracked season ID.
 *
 * @param seasonsArray - An array of Season objects.
 * @param initialSeasonId - The ID of the initial season to retrieve. If not found, the first season in the array is returned.
 * @param trackedSeasonId - (Optional) The ID of the tracked season. If provided and found, this season is returned.
 * @returns The Season object corresponding to the tracked season ID if provided and found, otherwise the initial season ID if found, or the first season in the array.
 */
export const getInitialSeason = (
  seasonsArray: Array<Season>,
  initialSeasonId: string | undefined,
  trackedSeasonId?: string
) => {
  // if there is a season with a track always update it to be the tracked season Id;
  if (trackedSeasonId) {
    const trackedSeason = getSeasonById(seasonsArray, trackedSeasonId);
    if (trackedSeason) {
      return trackedSeason;
    }
  }
  if (initialSeasonId) {
    const initialSeason = getSeasonById(seasonsArray, initialSeasonId);
    if (!initialSeason) {
      return seasonsArray.at(0);
    }
    return seasonsArray.find((season) => season.id === initialSeasonId);
  }
  return seasonsArray.at(0);
};

type HiddenSectionsArgs =
  | { media: Movie; mediaType: MediaType.Movie; season: undefined }
  | { media: Series; mediaType: MediaType.Series; season: Season };

/**
 * Determines which sections should be hidden based on the provided media and season data.
 *
 * @param {HiddenSectionsArgs} args - The arguments object containing media, mediaType, and season.
 * @param {Media} args.media - The media object containing trailers and related media.
 * @param {MediaType} args.mediaType - The type of media (e.g., Movie, TV Show).
 * @param {Season} [args.season] - The season object containing trailers (optional, only for TV Shows).
 * @returns {string[]} An array of section names that should be hidden.
 */
export function getHiddenSections({
  media,
  mediaType,
  season,
}: HiddenSectionsArgs) {
  const hiddenSections = [];

  if (mediaType === MediaType.Movie) {
    if (!media?.trailers?.length || media?.trailers?.length < 1)
      hiddenSections.push("trailers");
    if (!media?.related?.length || media?.related?.length < 1)
      hiddenSections.push("more-like-this");
  } else {
    if (!season?.trailers?.length || season?.trailers?.length < 1)
      hiddenSections.push("trailers");
    if (!media?.related?.length || media?.related?.length < 1)
      hiddenSections.push("more-like-this");
  }

  return hiddenSections;
}

export function processDuration(duration?: number) {
  let out = undefined;
  if (duration) {
    const temp = Math.floor(duration / 60);
    if (temp > 0) {
      out = temp;
    }
  }

  return out;
}

export const getMuteButtonState = (
  isPlayerActive: boolean,
  isPlayerMuted: boolean
) => {
  switch (true) {
    case !isPlayerActive:
      return "replay";
    case !isPlayerMuted:
      return "unmuted";

    default:
      return "muted";
  }
};

/**
 * Redirects to the details page based on the provided media type.
 *
 * @param navigate - The navigation function to use for redirection.
 * @param id - The unique identifier of the media item.
 * @param mediaType - The type of media (Series, Show, or Movie).
 */
export function redirectToDetails(
  navigate: NavigateFunction,
  id: string,
  mediaType: MediaType
) {
  switch (mediaType) {
    case MediaType.Series:
      navigate(constants.newScreens.details.series(id).as, {
        unstable_viewTransition: true,
      });
      break;

    case MediaType.Show:
      navigate(constants.newScreens.details.show(id).as, {
        unstable_viewTransition: true,
      });
      break;

    case MediaType.Movie:
      navigate(constants.newScreens.details.movie(id).as, {
        unstable_viewTransition: true,
      });
      break;

    default:
      break;
  }
}

/**
 * Generates image tag options based on the provided parameters.
 *
 * @param paid - Indicates if the item is paid.
 * @param comingSoon - Indicates if the item is coming soon.
 * @param owned - Indicates if the item is owned by the user.
 * @param paymentPackage - Optional payment package details.
 * @returns An object containing the tag type and additional details if applicable, or `undefined` if no conditions are met.
 */
export const getImageTagOptions = (
  paid: boolean,
  comingSoon: boolean,
  owned: boolean,
  paymentPackage?: IPackage
) => {
  if (comingSoon === true) {
    return { type: TagType.ComingSoon };
  }

  if (paid === false) {
    return { type: TagType.Free };
  }

  if (paymentPackage && owned === false) {
    return {
      type: TagType.Priced,
      price: paymentPackage.price.toString(),
      currency: paymentPackage.currency,
    };
  }

  return undefined;
};

/**
 * Retrieves an episode from a series by its episode ID and season ID.
 *
 * @param series - The series object containing seasons and episodes.
 * @param episodeId - The ID of the episode to retrieve.
 * @param seasonId - The ID of the season containing the episode.
 * @returns The episode object if found, otherwise undefined.
 */
export function getEpisodeByIdFromSeries(
  series: Series,
  episodeId: string,
  seasonId: string
) {
  const seasons = convertSeasonsObjToArray(series.seasons);
  const season = seasons.find((season) => season.id.toString() === seasonId);
  const episode = season?.episodes.find(
    (episode) => episode.id.toString() === episodeId
  );
  return episode;
}

export async function getPackageById(id: number) {
  try {
    const packages = await getAllPackages();
    const foundPackage = packages?.result?.data?.find((bundle) => bundle?._id === id);
    return foundPackage;
  } catch (e) {
    console.warn("[getPackageById] error: ", e);
    return null;
  }
}

/**
 * Retrieves a season object from a seasons map based on the provided season ID.
 * @param seasonsMap - The seasons map containing the season objects.
 * @param seasonId - The ID of the season to retrieve.
 * @returns The season object with the matching ID, or undefined if not found.
 */
export function getSeasonById(
  seasonsMap: ISeasonMap,
  seasonId: string | undefined
) {
  const seasons = convertSeasonsObjToArray(seasonsMap);
  return seasons.find((season) => season?.id === seasonId);
}

/**
 * Finds the latest watchable episode from the given seasons.
 *
 * @param seasons - The seasons object containing the episodes.
 * @returns The latest watchable episode, or undefined if none are found.
 */
export const findLatestWatchableEpisode = (
  seasons: ISeasonMap
): Episode | undefined => {
  // algorithm: start from latest season, find a watchable episode using validateCanWatchEpisode
  // if none are found in the latest season, go back one season and start the process again
  // repeat until at the first season and no episode is found. in that scenario, return undefined
  const seasonsArray = convertSeasonsObjToArray(seasons);
  for (let i = seasonsArray.length - 1; i >= 0; i--) {
    const season = seasonsArray[i];
    const episodes = season.episodes;
    const latestWatchableEpisode = episodes.find((episode) =>
      validateCanWatchEpisode(episode)
    );
    if (latestWatchableEpisode) return latestWatchableEpisode;
  }
};

/**
 * Validates if a user can watch a given episode.
 *
 * @param episode - The episode to be validated.
 * @returns A boolean indicating if the user can watch the episode.
 */
export const validateCanWatchEpisode = (episode: Episode): boolean => {
  if (episode.comingSoon) {
    console.warn(
      "[validateCanWatchEpisode] cannot watch this episode, episode is comingSoon"
    );
    return false;
  }
  if (episode.paid) {
    if (episode.owned === true) {
      return true;
    } else {
      console.warn(
        "[validateCanWatchEpisode] cannot watch this episode, episode is paid and not owned"
      );
      return false;
    }
  }

  return true;
};

/**
 * Validates if a movie can be watched based on its properties.
 *
 * @param {Movie} movie - The movie object to validate.
 * @returns {boolean} - Returns `true` if the movie can be watched, otherwise `false`.
 *
 * The function checks the following conditions:
 * - If the movie is marked as `comingSoon`, it logs a warning and returns `false`.
 * - If the movie is `paid`, it checks if the movie is `owned`. If owned, it returns `true`; otherwise, it logs a warning and returns `false`.
 * - If none of the above conditions are met, it returns `true`.
 */
export const validateCanWatchMovie = (movie: Movie) => {
  if (movie.comingSoon) {
    console.warn(
      "[validateCanWatchMovie] cannot watch this movie, movie is comingSoon"
    );
    return false;
  }
  if (movie.paid) {
    if (movie.owned === true) {
      return true;
    } else {
      console.warn(
        "[validateCanWatchMovie] cannot watch this movie, movie is paid and not owned"
      );
      return false;
    }
  }

  return true;
};

/**
 * Converts a seasons object to an array.
 *
 * @param obj - The seasons object to convert.
 * @returns An array containing the values of the seasons object.
 */
export function convertSeasonsObjToArray(obj: ISeasonMap) {
  try {
    return Object.keys(obj).map((key) => obj[parseInt(key)]);
  } catch (error) {
    console.error("Error converting seasons object to array: ", error);
    return [];
  }
}

/**
 * Extracts the default loader headers for making API requests.
 *
 * @returns The default loader headers as an object with the following properties:
 *   - Authorization: The access token with 'Bearer' markup.
 *   - Content-Type: The content type of the request (application/json).
 *   - Accept-Language: The language for the request.
 *   - profile: The user profile.
 * @returns {} if the authentication information is not found in the local storage.
 */
export function extractDefaultLoaderHeaders(): AxiosRequestHeaders | {} {
  const localStoragePersistedReducer = localStorage?.getItem("persist:root");
  if (!localStoragePersistedReducer) {
    return {};
  }
  const store = JSON.parse(localStoragePersistedReducer ?? "{}");
  if (!store?.authentication) {
    return {};
  }

  const authentication = JSON.parse(store.authentication);
  const { access_token, profile, language } = authentication;
  return {
    Authorization: `${access_token}`, // access_token includes the 'Bearer' markup
    "Content-Type": "application/json",
    "Accept-Language": language,
    profile: profile,
  };
}

/**
 * Checks if the user is logged in by verifying the presence of an access token in the persisted authentication state.
 *
 * @returns {boolean} `true` if the user is logged in, `false` otherwise.
 */
export function checkUserLoggedIn() {
  const localStoragePersistedReducer = localStorage.getItem("persist:root");
  const store = JSON.parse(localStoragePersistedReducer ?? "{}");
  if (!store?.authentication) {
    return false;
  }

  const authentication = JSON.parse(store.authentication);
  return (
    !!authentication.access_token && authentication.userAuthType === "user"
  );
}

const generateNewGuestId = () => {
  const newGuestId = uuidv4();
  localStorage.setItem("guestId", newGuestId);
  return newGuestId;
};

export const handleGuestLogin = async () => {
  let guestId = localStorage.getItem("guestId");

  if (!guestId) {
    guestId = generateNewGuestId();
  }

  const { dispatch } = store;
  const { language } = i18n;
  const loggedGuest = await loginAsGuest(guestId, language);

  dispatch(
    saveAuthentication({
      ...loggedGuest.data.result,
      isGuest: guestId,
      profile: 0,
    })
  );
};

/**
 * @param {number} amount
 * @param {number} limit
 * @param {number} minAmount
 */
export const getPercentage = (amount: number, limit: number, minAmount = 0) => {
  try {
    //@ts-expect-error msimatch types number and string
    const number = parseFloat(amount);
    //@ts-expect-error msimatch types Float and string
    const limitNumber = parseFloat(limit);
    if (number <= 0) {
      return minAmount;
    }
    if (number >= limitNumber) {
      return 100;
    }
    const percentage = (number / limitNumber) * 100;
    return percentage > minAmount ? percentage : minAmount;
  } catch {
    return 0;
  }
};

/**
 * @param {number} timestamp
 */
export const getVideoTime = (timestamp: any) => {
  try {
    const number = parseInt(timestamp, 10);
    if (number <= 0) {
      return "0:00";
    }

    const hours = Math.floor(timestamp / 60 / 60);
    const minutes = Math.floor(timestamp / 60) - hours * 60;
    //@ts-expect-error msimatch types number and string
    const seconds = parseInt(timestamp % 60, 10);

    let returnValue = minutes.toString();
    if (hours > 0) {
      returnValue = `${hours.toString()}:${returnValue
        .toString()
        .padStart(2, "0")}`;
    }

    return `${returnValue}:${seconds.toString().padStart(2, "0")}`;
  } catch {
    return "0:00";
  }
};

export const getEpisodeTime = (timestamp: any) => {
  try {
    const number = parseInt(timestamp, 10);
    if (number <= 0) {
      return "0:00";
    }

    const hours = Math.floor(timestamp / 60 / 60);
    const minutes = Math.round(timestamp / 60) - hours * 60;

    const returnValue = minutes.toString();
    if (hours > 0) {
      return `${hours.toString()}h ${returnValue.toString().padStart(2, "0")}m`;
    }
    //@ts-expect-error weird
    return `${returnValue > 0 ? `${returnValue}m` : ""}`;
  } catch {
    return "0:00";
  }
};

export const getEpisodeTimeRemaning = (timestamp: number, track: number) => {
  try {
    //@ts-expect-error msimatch types number and string
    const number = parseInt(timestamp - track, 10);

    if (number <= 0) {
      return null;
    }

    const hours = Math.floor((timestamp - track) / 60 / 60);
    const minutes = Math.floor((timestamp - track) / 60) - hours * 60;
    //@ts-expect-error msimatch types number and string
    const seconds = parseInt((timestamp - track) % 60, 10);

    let returnValue = minutes.toString();
    if (hours > 0) {
      returnValue = `${hours.toString()}h ${returnValue
        .toString()
        .padStart(2, "0")}min`;
    }
    if (returnValue) {
      returnValue += "m";
    }

    return `${returnValue} ${seconds.toString().padStart(2, "0")}s`;
  } catch {
    return "0:00";
  }
};

const trimChar = (s: string, c: string) => {
  let char = c;
  if (c === "]") char = "\\]";
  if (c === "^") char = "\\^";
  if (c === "\\") char = "\\\\";
  return s.replace(new RegExp(`^[${char}]+|[${char}]+$`, "g"), "");
};

export const getFormatedStringFromDays = (
  numberOfDays: number,
  language: string
) => {
  if (!numberOfDays || !Number.isInteger(numberOfDays)) return "";

  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  const yearsDisplay =
    years > 0
      ? years +
      (language === "en"
        ? years === 1
          ? " year, "
          : " years, "
        : years === 1
          ? " سنة، "
          : " سنوات ")
      : "";
  const monthsDisplay =
    months > 0
      ? months +
      (language === "en"
        ? months === 1
          ? " month, "
          : " months, "
        : months === 1
          ? " شهر، "
          : " أشهر، ")
      : "";
  const daysDisplay =
    days > 0
      ? days +
      (language === "en"
        ? days === 1
          ? " day "
          : " days "
        : days === 1
          ? " يوم"
          : " أيام")
      : "";

  return trimChar((yearsDisplay + monthsDisplay + daysDisplay).trim(), "،");
};

export function debounce(
  func: (...arg0: any[]) => any,
  wait: number,
  immediate: unknown
) {
  let timeout: any;
  return (...args: any) => {
    //@ts-expect-error legacy code
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export type UserAuthType = "user" | "guest" | null;

/**
 * Retrieves all live channels of type 'video'.
 *
 * @param {Dispatch} dispatch - The dispatch function from Redux.
 * @returns {Promise<Channel[]>} - A promise that resolves to an array of live channels.
 */
export const getAllLiveChannels = (dispatch: Dispatch): Promise<Channel[]> => {
  return new Promise((resolve, reject) => {
    dispatch(getLiveChannels())
      //@ts-expect-error legacy code
      .then((data: any) => {
        // You may need to adjust 'any' based on the actual response type
        const liveChannels = data?.payload?.data?.data?.result?.filter(
          (channel: Channel) => channel?.type === "video"
        );
        resolve(liveChannels);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
};

/**
 * Retrieves a live channel by its ID.
 *
 * @param {Dispatch} dispatch - The dispatch function from Redux.
 * @param {string} id - The ID of the live channel to retrieve.
 * @returns {Promise<Channel | undefined>} - A promise that resolves to the live channel object if found, or undefined if not found.
 */
export const getLiveChannelById = async (
  dispatch: Dispatch,
  id: string
): Promise<Channel | undefined> => {
  const allVideoStreams = await getAllLiveChannels(dispatch);
  return allVideoStreams.find((channel: Channel) => channel?._id === id);
};

/**
 * Handles the logic for live channels.
 *
 * @param {Dispatch} dispatch - The dispatch function.
 * @param {Channel} channel - The channel object.
 * @param {NavigateFunction} navigate - The navigate function from react-router-dom.
 * @param {string} destinationUrl - The destination URL.
 * @returns {void}
 */
export const handleLiveChannelLogic = (
  // dispatch: Dispatch,
  channel: Channel,
  userAuthType: UserAuthType,
  navigate: NavigateFunction, // 'navigate' can be typed more specifically based on your usage, or use 'useNavigate'
  destinationUrl: string
): void => {
  const fallBackUrl = constants.newScreens.live().as;
  const finalDestinationUrl = destinationUrl || fallBackUrl;

  if (!channel?.paid) {
    if (userAuthType === null) {
      handleGuestLogin().then(() =>
        navigate(constants.newScreens.live(channel?._id).as)
      );
      return;
    }

    navigate(constants.newScreens.live(channel?._id).as);
    return;
  }

  // if is Paid, check if user is subscribed and isSignedIn
  if (userAuthType === "user") {
    if (channel?.owned === true) {
      navigate(constants.newScreens.live(channel?._id).as);
      return;
    }
    // if (channel?.package) {
    // navigate(constants.screens.eticketPayment, {
    //   state: {
    //     redirect_url: finalDestinationUrl,
    //     isLiveFlow: true,
    //     isETicket: true,
    //     channelId: channel?._id,
    //   },
    // });
    // return;
    // }
    navigate(constants.screens.profile, {
      state: {
        redirect_url: finalDestinationUrl,
        myAccountSelectedSection: "subs",
        isLiveFlow: true,
        channelId: channel?._id,
      },
    });
    return;
  }

  navigate(constants.screens.login, {
    state: {
      redirect_url: `${constants.screens.profile}?redirect_url=${finalDestinationUrl}`,
      isLiveFlow: true,
      myAccountSelectedSection: "subs",
      channelId: channel?._id,
    },
  });
};

export const justNumber = (value: string) => value.match(/^[0-9\b]+$/);

export const isShowOrSeries = (type: string) =>
  type === "show" || type === "series" || type === "serie";


export const hoveredIdSelector = createSelector(
  (state) => state.hoveredCard as IHoveredCard,
  (_, id) => id,
  (hoveredCard: IHoveredCard, id) => (hoveredCard?.id === id ? hoveredCard : null)
);
