//TODO: Remove @ts-nocheck
import React, { useEffect, useState, useCallback, useRef, SyntheticEvent, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dots from '../../../Assets/Images/icons-dots.svg';
import styles from './ContinueWatchingCard.module.scss';
import ClickableItem from '@Atoms/ClickableItem/ClickableItem';
import close from '../../../Assets/Images/VideoControls/icons-close-black.svg';
import { removeVideoFromContinueWatching } from '../../../Store/Actions/videoDashboard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Placeholder from '@Assets/Images/placeholders/16x9.svg';
import CircularPlayButton from '@Assets/Images/nouveau/play-circle.svg';
import { ProgressBar } from 'react-bootstrap';
import { IContinueWatchingProps } from './ContinueWatchingCard.types';
import { useAppSelector } from '@Store/hooks';
import constants from '@Utils/constants';
import { useNavigate } from 'react-router-dom';

const getTimeRemaining = (totalDuration: number, trackedTime: number) => {
  const remainingTime = totalDuration - trackedTime;
  const remainingMinutes = Math.floor(remainingTime / 60);

  if (remainingMinutes === 0) {
    return { time: remainingTime, unit: 'seconds' };
  }

  if (remainingMinutes === 1) {
    return { time: remainingMinutes, unit: 'minute' };
  }

  return { time: remainingMinutes, unit: 'minutes' };
}

const ContinueWatching: React.FC<IContinueWatchingProps> = ({ playerId, thumbnail, title, season, duration, episode, railLength, time }) => {
  const dispatch = useDispatch();
  const threeDotsRef = useRef<HTMLImageElement>(null);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { language } = useAppSelector((state) => state.authentication);
  const navigate = useNavigate();

  const removeContinueWatchingHandler = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setShow(false);
    if (railLength === 1) window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch(removeVideoFromContinueWatching(playerId));
  }, [dispatch, playerId, railLength]);

  const onClose = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setShow(false);
  }, []);

  const onBodyClick = useCallback((e: unknown) => {
    //@ts-expect-error old code - no idea what it does, won't bother fixing it
    if (!show || threeDotsRef.current === null || threeDotsRef.current.contains(e.target)) {
      return;
    }
    setShow(false);
  }, [show, threeDotsRef]);

  const remainingTime = getTimeRemaining(duration, time);
  const hours = remainingTime.time ? Math.floor(remainingTime.time / 60) : 0;
  const minutes = remainingTime.time ? Math.floor((remainingTime.time % 60)) : 0;
  const progressPercentage = (time / duration) * 100;
  const progress = Math.min(progressPercentage, 100);
  const resumeWatching = async () => navigate(constants.newScreens.watch(playerId).as);
  const onDotsClick = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.blur();
    setShow((prevState) => !prevState);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [onBodyClick]);


  function onImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    const target = event.target as HTMLImageElement;
    target.src = Placeholder;
    target.style.objectFit = 'cover';
  }

  return (
    <div className={styles.root}>
      <div onClick={resumeWatching} className={styles.imageContainer}>
        <LazyLoadImage className={styles.img} src={thumbnail} alt={title} onError={onImageError} />
        <div className={styles.overlay}>
          <img src={CircularPlayButton} className={styles.playButton} alt='play-button' />
          <div className={styles.bottomInfoGroup}>
            <span className={styles.timeRemaining}>
              {remainingTime.time !== 0 ? (
                <Fragment>
                  {(hours > 0 || minutes > 0) && (
                    <div className={styles.minutesNumber}>
                        {hours > 0 && (
                            <span>
                                {hours} {t(`details.${hours === 1 ? 'hour' : 'hours'}`)}
                            </span>
                        )}
                        {minutes > 0 && (
                            <span>
                                &nbsp;{minutes} {t(`details.${minutes === 1 ? 'minute' : 'minutes'}`)}
                            </span>
                        )}
                    </div>
                )}
                </Fragment>
              ) : <Fragment />}
            </span>
            <ProgressBar variant='secondary' className={styles.progressBar}>
              <ProgressBar now={progress} className={styles.progressBarInternal} variant='light' />
            </ProgressBar>
          </div>
        </div>
      </div>
      <div className={styles.bottomCaption}>
        <div className={styles.bottomCaptionText}>
          <span className={styles.captionTitle}>{title}</span>
          {season && episode && <span className={styles.captionEpisodeIdentifier}>{`S${season}, E${episode}`}</span>}
        </div>
        <ClickableItem classes={styles.dotsButton} onClick={onDotsClick}>
          <img src={dots} className={styles.tripleDotsImg} width={20} height={20} alt="triple-dots" ref={threeDotsRef} />
        </ClickableItem>
        {show && (
          <ClickableItem classes={language === 'en' ? styles.popupENG : styles.popup} onClick={removeContinueWatchingHandler}>
            <ClickableItem onClick={onClose}>
              <img width={12} height={12} src={close} alt="close" />
            </ClickableItem>
            <div className={styles.text}>
              {t('globals.removeFromContinueWatching')}
            </div>
          </ClickableItem>
        )}
      </div>
    </div>
  );
};

export default ContinueWatching;