import { useOutletContext } from "react-router-dom";
import Episode from "@Atoms/Episode/Episode";
import styles from './MobileLayout.module.scss';
import { useTranslation } from "react-i18next";
import { IMediaDetailsContext } from "../DesktopLayout/DesktopLayout";

function MobileLayout() {
    const context = useOutletContext<IMediaDetailsContext>();
    const episodes = context?.currentSeason!.episodes;
    const isEpisodesSingular = episodes?.length === 1;
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.currentSeasonDescription}>
                <div className={styles.seasonText}>
                    <span>{t('details.alSeason')}</span>
                    <span className={styles.latinNumber}>{`${context?.currentSeason?.seasonNumber} :`}&nbsp;</span>
                </div>
                <span className={styles.latinNumber}>{context?.currentSeason?.episodes.length}</span>
                <span>{isEpisodesSingular ? t('details.episode') : t('details.episodes')}</span>
            </div>
            <div className={styles.episodesRail}>
                {episodes.map((episode) => (<Episode key={episode.id} episode={episode} />))}
            </div>
        </div>
    )
}

export default MobileLayout;