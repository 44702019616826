import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLatestList } from "../../Store/Actions/GetGenreTypeAction";
import Genre from "../../Components/Organisms/Genre/Genre";
import ErrorComponent from "../../HOC/ErrorComponent";
import constants from "../../Utils/constants";
import ErrorBoundary from "@Atoms/ErrorBoundary";

let arr = [];
let limit = 5;

const Genres = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { result, loading, error } = useSelector((state) => state.genreType);
  const [array, setArray] = useState([]);

  useEffect(() => {
    arr = [];
    setArray([]);
    for (let i = 0; i < Math.min(result?.data?.length, 24); i += 1) {
      arr.push(result?.data[i]);
    }
    setArray([...arr]);
  }, [result]);

  const showLess = useCallback(() => {
    if (!result || !result?.data) {
      return;
    }
    let i = arr.length;
    limit += 24;
    if (
      array.length < result?.data?.length &&
      result?.data?.length - array.length > limit
    ) {
      for (i; i < limit; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
    if (result?.data?.length - array.length < limit) {
      for (i; i < result?.data?.length; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
  }, [result, array.length]);

  useEffect(() => {
    dispatch(getLatestList(id, "category"));
  }, [dispatch, id]);

  if (error) return <ErrorComponent error={error} pageType={constants.screens.category().href} />;
  return (
    <ErrorBoundary>
      <Genre
        loading={loading}
        pageTitle={result?.pageTitle}
        result={result}
        array={array}
        showLess={showLess}
      />
      <Fragment />
    </ErrorBoundary>
  );
};
export default Genres;
