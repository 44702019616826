/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../../Utils/config';
import constants from '../../../Utils/constants';
import styles from './index.module.scss';
import logo from '@Assets/Images/nouveau/ballam-logo.svg';
import SocialBox from '../SocialBox/SocialBox';
import FooterTabs from '../FooterTabs/FooterTabs';
import LineSeparator from '../LineSeparator/LineSeparator';

const Footer = () => {
  const { result, server_date } = useSelector((state) => state.videoSettings);
  const { language } = useSelector((state) => state.authentication);
  const { t } = useTranslation();
  const { dashboard } = useSelector((state) => state.videosDashboard);
  const location = useLocation();

  return (
    <div
      className={classNames('display-footer', styles.root, `${dashboard?.filter(
        (el) => el.type !== 'header' && el.type !== 'featuredVideo')?.length > 0 ? '' : location.pathname === '/' && styles.footerMarginTop}`)}
    >
      <div className={styles.topRow}>
        <Link to={constants.screens.browse} className={styles.logo} aria-label="FooterLogo">
          <LazyLoadImage height={60} alt='footer logo' src={result?.header?.logo?.footer || result?.header?.logo?.logoWeb || logo}
            onError={(e) => {
              e.target.src = logo;
            }}
          />
        </Link>
        <FooterTabs />
        <SocialBox />
      </div>
      <div className={styles.separator}>
        <LineSeparator />
      </div>
      <div className={styles.bottomRow}>
        <span className={styles.editionText}>
          <span>{t('footer.edition')} </span>
          <span dir='ltr'>{server_date ? moment(server_date).format('YYYY') : ''}</span>
        </span>
        <span className={`${language === 'ar' ? styles.allRightsTextAr : ''} ${styles.allRightsText}`}>
          {t('footer.rights')}
        </span>
        <span className={`${language === 'ar' ? styles.versionAr : ''} ${styles.version}`}>
          <span>{t('footer.version')} </span>
          <span dir='ltr'>{config.version}</span>
        </span>
        <span className={styles.poweredBy}>{t('footer.poweredBy')} <a target='_blank' dir='auto' className={styles.euriskoLink} href='https://eurisko.net/'> Eurisko</a></span>
      </div>
    </div>
  );
};

export default Footer;
