import constants from "@Utils/constants";

export const getDropdownItemUrl = (elementType: string, elementId: string) => {
  switch (elementType) {
    case "bookmark":
      return constants.screens.bookmarks;
    case "shows":
      return constants.screens.shows;
    case "movies":
      return constants.screens.movies;
    case "series":
      return constants.screens.series;
    case 'movie':
      return constants.newScreens.details.movie(elementId).as;
    case 'show':
      return constants.newScreens.details.show(elementId).as;
    case "genre":
      return constants.screens.genre(elementId).as;
    case "category":
      return constants.screens.category(elementId).as;
    case "landing":
    default:
      return constants.screens.browse;
  }
};
