import React from 'react';
import styles from './LineSeparator.module.scss';
import classNames from 'classnames';

interface ILineSeparatorProps {
  wrapperClassName?: string;
  className?: string;
}

const LineSeparator: React.FC<ILineSeparatorProps> = ({ className, wrapperClassName }) => (
  <div className={classNames("d-flex align-items-center", wrapperClassName)}>
    <hr className={classNames(styles.lineSeparator, "flex-grow-1", className)} />
  </div>
);

export default LineSeparator;
