import {
  SET_RADIO_ON,
} from '../Constants/StreamConstants';

const initialState = {
  activeRadio: false,
};

const streamReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RADIO_ON: {
      return {
        ...initialState,
        activeRadio: action.payload.active,
      };
    }

    default:
      return state;
  }
};

export default streamReducer;
