import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import classNames from 'classnames';
import { getPercentage } from '../../../Utils/common';
import styles from './ProgressBar.module.scss';

const MyProgressBar = ({
  time, duration, color, notCropped,
}) => (
  <div className={styles.progressBarContainer}>
    <ProgressBar className={classNames(styles.progressBar, notCropped ? styles.notCropped : '')}>
      <ProgressBar
        className={styles.progress}
        style={{ backgroundColor: color }}
        now={time > duration ? 0 : getPercentage(time, duration, 0)}
        animated={false}
      />
    </ProgressBar>
  </div>
);

MyProgressBar.propTypes = {
  time: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  color: PropTypes.string,
  notCropped: PropTypes.bool,
};

MyProgressBar.defaultProps = {
  color: '#fff',
  notCropped: false,
};

export default MyProgressBar;
