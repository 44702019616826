import React from 'react';

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <path d="M0 0L24 0 24 24 0 24z" />
        <g fill="white" className="animation" fillRule="nonzero">
          <g>
            <path
              d="M8 0c.552 0 1 .448 1 1l-.001 6H15c.552 0 1
                 .448 1 1s-.448 1-1 1H8.999L9 15c0 .552-.448 1-1 1s-1-.448-1-1l-.001-6H1c-.552
                  0-1-.448-1-1s.448-1 1-1h5.999L7 1c0-.552.448-1 1-1z"
              transform="rotate(-45 14.828 5.172)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Close;
