import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import StandardHeroComponent from '../../HOC/StandardHeroComponent/StandardHeroComponent';
import styles from './NotFound.module.scss';
import constants from '../../Utils/constants';

function NotFound({ pageType = constants.screens.browse }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    if (matchPath(constants.screens.browse, location.pathname)) window.location.reload();
    else navigate(constants.screens.browse, { replace: true });
  };

  switch (pageType) {
    case constants.screens.comingSoon:
    case constants.screens.aboutUs:
    case constants.screens.bookmarks:
    case constants.screens.contactUs:
    case constants.screens.termsAndConditions:
    case constants.screens.actor('').href:
    case constants.newScreens.profiles.view:
    case constants.screens.search:
    case constants.screens.editPic().href:
    case constants.screens.genre().href:
    case constants.screens.category().href:
    case constants.screens.myPurchases:
    case constants.screens.series:
    case constants.screens.shows:
    case constants.screens.browse:
      return (
        <div className={styles.notFoundPageContainerNoParent}>
          <div className={styles.pageNotFoundTextContainer}>
            <p className={styles.pageNotFound}>
              {t('errors.pageNotFoundTitle')}
            </p>
            <p className={styles.pageNotFoundDescription}>
              {t('errors.pageNotFoundDescription')}
            </p>
          </div>
          <div onClick={onClick} className={styles.homepageButton}>
            <span className={styles.homepageButtonContent}>
              {t('globals.backToHomePage')}
            </span>
          </div>
        </div>
      );

    default:
      return (
        <StandardHeroComponent className={styles.topParentOverride}>
          <div className={styles.notFoundPageContainer}>
            <div className={styles.pageNotFoundTextContainer}>
              <p className={styles.pageNotFound}>
                {t('errors.pageNotFoundTitle')}
              </p>
              <p className={styles.pageNotFoundDescription}>
                {t('errors.pageNotFoundDescription')}
              </p>
            </div>
            <div onClick={onClick} className={styles.homepageButton}>
              <span className={styles.homepageButtonContent}>
                {t('globals.backToHomePage')}
              </span>
            </div>
          </div>
        </StandardHeroComponent>
      );
  }
}

export default NotFound;
