import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';
import { addSearchHistory, getSearchHistory } from '../../../Store/Actions/SearchHistoryActions';
import { loginAsGuest } from '../../../Services/Authentication';
import MovieCardInfo from '../MovieCardInfo/MovieCardInfo';
import placeholder from '../../../Assets/Images/placeholders/16x9.svg';
import portraitPlaceholder from '../../../Assets/Images/placeholders/2x3.svg';
import Volume from '../../../Assets/Images/VideoControls/Volume.svg';
import Muted from '../../../Assets/Images/VideoControls/Muted.svg';
import { saveAuthentication } from '../../../Store/Actions/AuthenticationActions';
import { getPlayerStatus } from 'src/alballam-api';
import { getImageTagOptions } from '../../../Utils/common';
import {
  hoveredCardAction,
  onHoverCard,
  clearHoveredCard,
} from '../../../Store/Actions/HoveredMovie';
import VideoPlayer from '../../Atoms/VideoPlayer';
import constants from '../../../Utils/constants';
import MovieCardPortrait from '../MovieCardPortrait/MovieCardPortrait';

import Number1 from '../../../Assets/Images/topten/TopTen-1.svg';
import Number2 from '../../../Assets/Images/topten/TopTen-2.svg';
import Number3 from '../../../Assets/Images/topten/TopTen-3.svg';
import Number4 from '../../../Assets/Images/topten/TopTen-4.svg';
import Number5 from '../../../Assets/Images/topten/TopTen-5.svg';
import Number6 from '../../../Assets/Images/topten/TopTen-6.svg';
import Number7 from '../../../Assets/Images/topten/TopTen-7.svg';
import Number8 from '../../../Assets/Images/topten/TopTen-8.svg';
import Number9 from '../../../Assets/Images/topten/TopTen-9.svg';
import Number10 from '../../../Assets/Images/topten/TopTen-10.svg';
import { setCookie } from '@Organisms/MarketingPopup/MarketingPopup.utils';
import { setPaymentModalData } from '@Store/Actions/PaymentModalActions';
import { PurchasableMedia } from '@Utils/commonTypes';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import ImageTag from '@Atoms/ImageTag/ImageTag';
import { MediaType } from '@Utils/commonTypes';
import './MovieCard.scss';

const images = [
  { id: 1, src: Number1 },
  { id: 2, src: Number2 },
  { id: 3, src: Number3 },
  { id: 4, src: Number4 },
  { id: 5, src: Number5 },
  { id: 6, src: Number6 },
  { id: 7, src: Number7 },
  { id: 8, src: Number8 },
  { id: 9, src: Number9 },
  { id: 10, src: Number10 },
];

const hoveredIdSelector = createSelector(
  (state) => state.hoveredCard,
  (_, id) => id,
  (hoveredCard, id) => (hoveredCard.id === id ? hoveredCard : null),
);


const MovieCard = ({
  className,
  title,
  thumbnail,
  id,
  playerId,
  rating,
  trailer,
  isActive,
  setActiveIndex,
  index,
  type,
  isContinueWatching,
  isSeason,
  duration,
  tag,
  isFree,
  isComingSoon,
  isPortrait,
  portraitImage,
  description,
  quality,
  date,
  numberOfSeasons,
  comingSoon,
  genres,
  endTransition,
  isLast5elmntsIndex,
  show,
  seasonId,
  topTen,
  titleUrl,
  isComingSoonPage,
  isSecondSlider,
  generalText,
  showName,
  seasonName,
  isInComingSoonRail,
  secondSliderTrailer,
  isInSearch,
  showId,
  activeSeasonId,
  video,
  order,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hoveredMovie = useSelector((state) => hoveredIdSelector(state, id));
  const { isSignedIn, isGuest, language } = useSelector((state) => state.authentication);
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const imageTagOptions = useMemo(() => getImageTagOptions(!isFree, comingSoon, false, undefined), [comingSoon, isFree]);

  const [playerStatus, setPlayerStatus] = useState({
    muted: true,
    loaded: false,
    showPlayer: false,
    hasEnded: false,
  });

  const ref = useRef();
  const videoRef = useRef();
  const cardInfo = useRef();
  const top10Ref = useRef();
  const portraitImageRef = useRef();
  const imageContainer = useRef();

  useEffect(() => {
    if (!endTransition) {
      setIsHovered(false);
      dispatch(clearHoveredCard());
    }
  }, [endTransition, dispatch]);

  useEffect(() => {
    if (isActive) {
      setActiveIndex(index);
    }
  }, [isActive, setActiveIndex, index]);

  const onMouseEnter = useCallback(() => {
    if (isMobile || !endTransition) return;

    setIsHovered(true);
    dispatch(onHoverCard(id));

    setPlayerStatus((prev) => ({ ...prev, showPlayer: true }));

    if (portraitImageRef.current?.style) {
      portraitImageRef.current.style.display = 'none';
    }

    if (imageContainer.current?.style) {
      imageContainer.current.style.backgroundImage = `url(${thumbnail || placeholder
        })`;
      imageContainer.current.style.backgroundPosition = 'center';
      imageContainer.current.style.backgroundRepeat = 'no-repeat';
      imageContainer.current.style.backgroundSize = 'cover';
    }

    if (top10Ref.current?.style) {
      top10Ref.current.style.display = 'none';
    }

    // adding z-index to current hovered swiper-container to come over others
    ref.current.parentElement.parentElement.parentElement.style.zIndex = 100;
    // adding z-index to current hovered card to come over others current slider car
    ref.current.style['z-index'] = 10;

    cardInfo.current.style.display = 'block';
    if (!isSecondSlider) {
      cardInfo.current.style.animation = 'fadeIn forwards 0.3s';
    }
  }, [dispatch, id, thumbnail, endTransition, isSecondSlider]);

  const onMouseLeave = useCallback(() => {
    if (isMobile || !endTransition) return;
    setIsHovered(false);

    setPlayerStatus((prev) => ({
      ...prev,
      showPlayer: false,
      loaded: false,
    }));
    if (top10Ref.current?.style) {
      top10Ref.current.style.display = 'block';
    }

    if (portraitImageRef.current?.style) {
      portraitImageRef.current.style.display = 'block';
    }
    if (imageContainer.current?.style) {
      imageContainer.current.style.backgroundImage = '';
      imageContainer.current.style.backgroundPosition = 'center';
      imageContainer.current.style.backgroundRepeat = 'no-repeat';
      imageContainer.current.style.backgroundSize = 'cover';
    }

    // readjusting z-index of current hovered card;
    ref.current.style.zIndex = 0;
    // readjusting z-index to current hovered swiper-container
    ref.current.parentElement.parentElement.parentElement.style.zIndex = 0;
    if (!isSecondSlider) {
      cardInfo.current.style.animation = 'fadeOut forwards 0.3s';
    }

    if (videoRef.current) {
      dispatch(hoveredCardAction(id, videoRef.current?.getCurrentTime() || 0));
    }
  }, [dispatch, id, endTransition, isSecondSlider]);

  let thumbnailOrTrailer = thumbnail;
  if (trailer?.length > 0) {
    thumbnailOrTrailer = isPortrait ? trailer[trailer.length - 1].portraitLink : trailer[trailer.length - 1].link;
  }

  const redirectToPage = useCallback(() => {
    if (type !== 'episode') {
      if (trailer?.length > 0) {
        console.warn('[redirectToPage] Trailer Exists, Scenario yet to be implemented');
        // dispatch(
        //   selectedMovie({
        //     id,
        //     title,
        //     thumbnail,
        //     trailer,
        //     type,
        //     titleUrl,
        //   }),
        // );
      } else {
        console.warn('[redirectToPage] Scenario yet to be implemented');
      }
    }

    switch (type) {
      case 'episode':
        navigate(constants.newScreens.details.series(show || showId).season(seasonId).episode(id).as);
        break;
      case 'serie':
      case 'series':
        navigate(constants.newScreens.details.series(id).as);
        break;
      case 'show':
        navigate(constants.newScreens.details.show(id).as);
        break;
      default:
        navigate(constants.newScreens.details.movie(id).as);
        break;
    }
  }, [type, trailer?.length, navigate, show, showId, seasonId, id]);

  const handleGuestLogin = useCallback(async () => {
    const guestId = uuidv4();
    const loggedGuest = await loginAsGuest(guestId, language);
    dispatch(
      saveAuthentication({
        ...loggedGuest.data.result,
        isGuest: guestId,
        profile: 0,
      }),
    );
  }, [dispatch, language]);

  const onThumbnailClickHandler = async (e) => {
    if (isContinueWatching) return;
    e.stopPropagation();
    if (comingSoon) {
      return redirectToPage();
    }

    if (!isGuest && !isSignedIn) {
      await handleGuestLogin();
    }

    const res = await getPlayerStatus(id, type);
    const data = res.result;
    if ((data?.owned || !data?.paid) && !data?.comingSoon) {
      navigate(constants.newScreens.watch(data?.id).as);
      return;
    }

    let destination = '';
    if (data.comingSoon) {
      return redirectToPage();
    }

    switch (type) {
      case MediaType.Show:
        destination = constants.newScreens.details.show(id).season(data?.season).as;
        dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Episode }));
        break;
      case MediaType.Movie:
        destination = constants.newScreens.details.movie(id).as;
        dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Movie }));
        break;

      case MediaType.Series:
        destination = constants.newScreens.details.series(id).season(data?.season).as;
        dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Episode }));
        break;
      default:
        break;
    }

    setCookie(constants.locationState.openPayment, 'true', 1);
    if (isSignedIn) navigate(destination);
    else {
      navigate({
        pathname: constants.screens.login,
        search: queryString.stringify({ redirect_url: destination }),
      });
    }
  }

  const onClick = (e) => {
    e.stopPropagation();

    if (isContinueWatching) {
      if (isFree && !isGuest && !isSignedIn) {
        handleGuestLogin().then(() => navigate(
          constants.newScreens.watch(playerId, window.decodeURI(titleUrl)).as,
        ));
      } else {
        navigate(
          constants.newScreens.watch(playerId, window.decodeURI(titleUrl)).as,
        );
      }
    } else if (isSeason) {
      if (comingSoon || !isFree) {
        navigate(constants.newScreens.details.series(show).season(seasonId).as);
      } else if (isFree) {
        if (!isGuest && !isSignedIn) {
          handleGuestLogin().then(() => navigate(
            `${constants.newScreens.watch(id, window.decodeURI(titleUrl)).as
            }?isSeason=${true}`,
          ));
        } else {
          navigate(
            `${constants.newScreens.watch(id, window.decodeURI(titleUrl)).as
            }?isSeason=${true}`,
          );
        }
      } else if (!isSignedIn) {
        navigate(constants.screens.login, {
          state: {
            redirect_url: window.location.pathname,
          }
        });
      } else {
        navigate(
          `${constants.newScreens.watch(id, window.decodeURI(titleUrl)).as
          }?isSeason=${true}`,
        );
      }
    } else if (isInSearch) {
      try {
        const { pathname } = window.location;
        const searchString = pathname.substring(pathname.indexOf('/search/') + '/search/'.length);
        if (!isGuest && !isSignedIn) {
          handleGuestLogin().then(() => {
            dispatch(addSearchHistory(searchString, type, id)).then(() => {
              dispatch(getSearchHistory());
            });
          });
        } else {
          dispatch(addSearchHistory(searchString, type, id)).then(() => {
            dispatch(getSearchHistory());
          });
        }
      } finally {
        redirectToPage();
      }
    } else {
      redirectToPage();
    }
  };


  const onInfoCardAnimationEndHandler = useCallback(() => {
    if (!playerStatus.showPlayer) {
      cardInfo.current.style.display = 'none';
    }
  }, [playerStatus.showPlayer]);

  const onInfoClick = (e) => {
    e.stopPropagation();

    redirectToPage();
  };

  const onFocus = useCallback(() => {
    if (isMobile) return;

    setPlayerStatus((prev) => ({
      ...prev,
      hasEnded: false,
    }));
  }, []);


  const renderCardTags = useCallback(() => {
    if (playerStatus.loaded || isSeason || isSecondSlider) {
      return null;
    }



    return <ImageTag {...imageTagOptions} />
  }, [playerStatus.loaded, isSeason, isSecondSlider, imageTagOptions]);

  useEffect(() => {
    if (!hoveredMovie && !isHovered) {
      setPlayerStatus((prev) => ({ ...prev, hasEnded: false }));
      dispatch(clearHoveredCard());
    }
  }, [hoveredMovie, dispatch, isHovered]);

  const renderPlayer = useCallback(() => {
    if (playerStatus.hasEnded || !playerStatus.showPlayer || isMobile) {
      return null;
    }

    return (
      <div
        className={classNames(`trailer-video ${!playerStatus.loaded && 'd-none'} ${isSecondSlider && 'd-flex justify-content-end'}`)}
      >
        <VideoPlayer
          url={isSecondSlider ? secondSliderTrailer?.link : thumbnailOrTrailer}
          onBufferEnd={() => {
            setPlayerStatus((prev) => ({
              ...prev,
              loaded: true,
            }));
          }}
          onStart={() => {
            if (hoveredMovie && videoRef.current) {
              videoRef.current.seekTo(hoveredMovie.duration || 0, 'seconds');
            }
          }}
          onEnded={() => {
            setPlayerStatus((prev) => ({
              ...prev,
              hasEnded: true,
            }));
          }}
          muted={playerStatus.muted}
          volume={1}
          ref={videoRef}
        />
        <Button
          className={classNames(`mute-button ${isSecondSlider && 'muteButtonSecondSlider'}`)}
          onClick={(e) => {
            e.stopPropagation();
            setPlayerStatus((prev) => ({
              ...prev,
              muted: !prev.muted,
            }));
          }}
        >
          <LazyLoadImage
            src={playerStatus.muted ? Muted : Volume}
            alt=""
            width="20px"
            height="20px"
          />
        </Button>
      </div>
    );
  }, [playerStatus, thumbnailOrTrailer, hoveredMovie, isSecondSlider, secondSliderTrailer]);

  return (
    <>
      <div
        className={classNames(
          'movie-card',
          isHovered ? 'active' : '',
          isPortrait ? 'movie-card-portrait' : '',
          type === 'episode' && isMobile ? 'movie-mobile-episode' : '',
          className,
          isLast5elmntsIndex && isHovered ?
            language === 'ar' ? 'isLastAr'
              : 'isLast'
            : '',
          isSecondSlider ? 'movie-card-isSecondSlider' : '',
          topTen && index === 9 ? 'isNumberTen' : '',
        )}
        tabIndex={0}
        role="button"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {isSecondSlider && !playerStatus.loaded && (
          <div className="GradientSecondSlider" />
        )}
        {isPortrait ? (
          <MovieCardPortrait
            thumbnail={portraitImage}
            renderPlayer={renderPlayer}
            isHovered={isHovered}
          >
            {renderCardTags()}
            <MovieCardInfo
              isSeason={isSeason}
              ref={cardInfo}
              id={playerId || id}
              title={title}
              rating={rating}
              onInfoClick={onInfoClick}
              onWatchNowClick={onThumbnailClickHandler}
              isContinueWatching={isContinueWatching}
              onAnimationEnd={onInfoCardAnimationEndHandler}
              quality={quality}
              date={date}
              numberOfSeasons={numberOfSeasons}
              isComingSoon={comingSoon}
              genres={genres}
              duration={duration}
              isSecondSlider={isSecondSlider}
              generalText={generalText}
              seasonName={seasonName}
              showName={showName}
            />
          </MovieCardPortrait>
        ) : (
          <div
            className={`${tag === 'topTen' ? 'aspect-ratio-7x10' : 'aspect-ratio-16x9'
              } 
          `}
          >
            <div
              className="card-content shadow-sm"
              onFocus={onFocus}
            >
              <div
                className={`${tag === 'topTen' ? 'top10-card' : 'top-card'} ${isHovered ? 'active' : ''
                  }`}
              >
                {tag === 'topTen' ? (
                  <div
                    className="d-flex justify-content-between h-100"
                    ref={imageContainer}
                  >
                    {renderCardTags()}
                    {!isHovered && (
                      <LazyLoadImage
                        alt={title}
                        ref={top10Ref}
                        className={`${language === 'ar' ? 'topTenIcons' : 'topTenIconsEng'
                          } topTenIconsNumbering-${index}`}
                        src={images[index]?.src || portraitPlaceholder}
                        onError={(e) => {
                          e.target.src = portraitPlaceholder;
                          e.target.style.objectFit = 'cover';
                        }}
                      />
                    )}
                    {!isHovered && (
                      <LazyLoadImage
                        ref={portraitImageRef}
                        className={
                          language === 'ar'
                            ? 'topTen-movie-card-img'
                            : 'topTen-movie-card-img-Eng'
                        }
                        src={portraitImage || portraitPlaceholder}
                        alt={title}
                        onError={(e) => {
                          e.target.src = portraitPlaceholder;
                          e.target.style.objectFit = 'cover';
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {renderCardTags()}
                    {isContinueWatching && (video?.type === 'show' || video?.type === 'series') && (
                      <span className="continue-watching-season-episode">{`${video?.seasonName} - E${video?.episode}`}</span>
                    )}
                    <LazyLoadImage
                      className="movie-card-img"
                      src={thumbnail}
                      alt={title}
                      onError={(e) => {
                        e.target.src = placeholder;
                        e.target.style.objectFit = 'cover';
                      }}
                    />
                    {(type === 'episode' && order && (
                      <span className={classNames("season-rail-episode-number", isHovered ? 'hidden' : undefined)}>
                        {t('details.episode')} {order}
                      </span>
                    ))}
                  </>
                )}
                {trailer?.length > 0 && renderPlayer()}
              </div>
              <MovieCardInfo
                isSeason={isSeason}
                ref={cardInfo}
                id={playerId || id}
                title={title}
                rating={rating}
                onInfoClick={onInfoClick}
                onWatchNowClick={onThumbnailClickHandler}
                isContinueWatching={isContinueWatching}
                onAnimationEnd={onInfoCardAnimationEndHandler}
                description={description}
                date={date}
                quality={quality}
                numberOfSeasons={numberOfSeasons}
                isComingSoon={comingSoon || isComingSoonPage}
                genres={genres}
                duration={duration}
                isSecondSlider={isSecondSlider}
                generalText={generalText}
                seasonName={seasonName}
                showName={showName}
              />
            </div>
          </div>
        )}
      </div>

      {/* {isContinueWatching && (
        <ContinueWatching
          railLength={railLength}
          time={time}
          duration={duration}
          id={playerId || id}
        />
      )} */}
    </>
  );
};

MovieCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  rating: PropTypes.string,
  trailer: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  index: PropTypes.number,
  setActiveIndex: PropTypes.func,
  isActive: PropTypes.bool,
  isFree: PropTypes.bool,
  isComingSoon: PropTypes.bool,
  isPortrait: PropTypes.bool,
  endTransition: PropTypes.bool,
  isSeason: PropTypes.bool,
  titleUrl: PropTypes.string,
  railLength: PropTypes.number,
  isComingSoonPage: PropTypes.bool,
  isSecondSlider: PropTypes.bool,
  generalText: PropTypes.string,
  isInComingSoonRail: PropTypes.bool,
  secondSliderTrailer: PropTypes.object,
};

MovieCard.defaultProps = {
  thumbnail: undefined,
  title: undefined,
  rating: '',
  trailer: undefined,
  isFree: false,
  isComingSoon: false,
  isPortrait: false,
  setActiveIndex: () => null,
  isActive: false,
  index: null,
  endTransition: true,
  isSeason: null,
  titleUrl: null,
  railLength: 0,
  isComingSoonPage: false,
  isSecondSlider: false,
  generalText: '',
  isInComingSoonRail: false,
  secondSliderTrailer: {},
};

export default React.memo(MovieCard);
