import {
  MUTE_UNMUTE,
} from '../Constants/MutedHeaderConstants';
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-plusplus */

const initialState = {
  muted: true,
};

const mutedHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case MUTE_UNMUTE:
      return {
        ...state,
        muted: action.payload.muted,
      };
    default:
      return state;
  }
};

export default mutedHeaderReducer;
