import React from 'react';
import constants from '@Utils/constants';
import { Navigate } from 'react-router-dom';
import PaymentRedirection from '@Pages/PaymentRedirection/PaymentRedirection';
import { useSelector } from 'react-redux';

function PaymentRedirectionRouter() {
    const { isSignedIn } = useSelector((state) => state.authentication);
    if (!isSignedIn) {
        return <Navigate to={constants.newScreens.profiles.view} />
    }
    return <PaymentRedirection />;
}

export default PaymentRedirectionRouter