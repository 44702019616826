import {
  CLEAR_SERIES_FILTERED,
  GET_SERIES_FILTERED,
} from '../Constants/SeriesConstants';

const getSeriesFiltered = (genreId, page = 1) => ({
  type: GET_SERIES_FILTERED,
  payload: {
    request: {
      url: 'api/video/shows?',
      method: 'get',
      authenticated: true,
      params: {
        filter: genreId,
        page,
        limit: 24,
        isSeries: true,
      },
    },
  },
});

const clearSeriesFiltered = () => ({
  type: CLEAR_SERIES_FILTERED,
  payload: null,
});

export { getSeriesFiltered, clearSeriesFiltered };
