import classNames from 'classnames';
import styles from './ImageTag.module.scss';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import { TagType } from '@Utils/commonTypes';

export type ImageTagProps = undefined | {
    type?: TagType | undefined;
    currency?: string;
    price?: string;
    containerClassName?: string;
};

const ImageTag: React.FC<ImageTagProps> = (props) => {
    const { t } = useTranslation();

    let tagContent;
    switch (props?.type) {
        case TagType.ComingSoon:
            tagContent = <span className={classNames(styles.tagText, styles.gradientBackground)}>{t('tags.comingSoon')}</span>;
            break;

        case TagType.Free:
            tagContent = <span className={classNames(styles.tagText, styles.free)}>{t('tags.free')}</span>;
            break;
        case TagType.Priced:
            tagContent = (
                <span className={classNames(styles.tagText, styles.gradientBackground)}>
                    {props!.currency} {props!.price}
                </span>
            );
            break;
        default:
            return null;
    }

    if (props === undefined) return <Fragment />;
    return (
        <div dir='auto' className={classNames(styles.tagContainer, props.containerClassName)}>
            {tagContent}
        </div>
    );
}

export default ImageTag