import {
  ADD_BOOKMARK,
  GET_BOOKMARKS,
  REMOVE_BOOKMARK,
} from '../Constants/BookmarkConstants';

const addBookmark = (id, type) => ({
  type: ADD_BOOKMARK,
  payload: {
    request: {
      url: 'api/videos/bookmarks',
      method: 'post',
      authenticated: true,
      params: {
        id,
        type,
      },
    },
  },
});

const getBookmarks = () => ({
  type: GET_BOOKMARKS,
  payload: {
    request: {
      url: 'api/videos/bookmarks',
      method: 'get',
      authenticated: true,
    },
  },
});

const removeBookmark = (id, type) => ({
  type: REMOVE_BOOKMARK,
  payload: {
    request: {
      url: 'api/videos/bookmarks',
      method: 'delete',
      authenticated: true,
      params: {
        id,
        type,
      },
    },
  },
});

export { addBookmark, removeBookmark, getBookmarks };
