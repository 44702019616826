// @ts-nocheck
// TODO: Remove noCheck
import { Fragment, useEffect, useMemo } from 'react';
import { useAppSelector } from '@Store/hooks.ts';
import { getVideosDashboard, getVideosTracking } from '../../Store/Actions/videoDashboard.js';
import WatchedVideosHorizontalList from '../../Components/Molecules/WatchedVideosHorizontalList/watchedVideosHorizontalList.jsx';
import MovieList from '../../Components/Organisms/MoviesList/MoviesList.jsx';
import PageWrapper from '../PageWrapper/PageWrapper.tsx';
import ErrorComponent from '../../HOC/ErrorComponent.jsx';
import constants from '../../Utils/constants.js';
import FeaturedMediaCard from '@Organisms/FeaturedMediaCard/FeaturedMediaCard';
import { useDispatch } from 'react-redux';
import { ScrollRestoration, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MarketingPopup from '@Organisms/MarketingPopup/MarketingPopup.tsx';
import styles from './Home.module.scss';

export type Channel = {
  active: boolean;
  comingSoon: boolean;
  description: string;
  general: string;
  landscapeImage: string;
  landscapeImageMobile: string;
  showSlider: boolean;
  type: 'video' | 'audio';
  url: string;
  _id: string;
  title: string;
  titleImage: string;
  titleImageMobile: string;
  owned: boolean;
  paid: boolean;
  placeHolder: boolean;
}

// interface IDashboardRail<T> {
//   data: Array<T>;
//   displayType: string;
//   order: number;
//   title: string;
//   type: string;
// }


function useReroutePayment() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const idRaw = params.get('id');
    const temp = idRaw?.charAt(0); // if first character of id is s, it is a series/show
    let id;
    if (!idRaw) return;
    if (temp === 's') {
      id = idRaw.slice(1);
      navigate({
        pathname: `${constants.newScreens.details.series(id).as}`,
        search: location.search,
      }, { replace: true });
    } else {
      id = idRaw;
      navigate({
        pathname: `${constants.newScreens.details.movie(id).as}`,
        search: location.search,
      }, { replace: true });
    }
  }, [location, navigate, params]);
}

export default function Home() {
  const dispatch = useDispatch();
  const { result, dashboard, dashboardLoading, loading, error } = useAppSelector((state) => state.videosDashboard);
  const { isSignedIn, isGuest } = useAppSelector((state) => state.authentication);
  // const settings = useAppSelector((state) => state.videoSettings.result);

  useReroutePayment();

  useEffect(() => {
    if (isGuest || isSignedIn) {
      dispatch(getVideosTracking());
    }

    dispatch(getVideosDashboard());
  }, [dispatch, isSignedIn, isGuest]);

  const filteredDashboardData = useMemo(() => {
    // expensive computation but is needed for deep cloning and immutability
    const temp = JSON.parse(JSON.stringify(dashboard));
    return temp?.filter((el) => el.type !== 'header' && el.type !== 'featuredVideo');
  }, [dashboard]);

  const featuredMedia = useMemo(() => {
    // expensive computation but is needed for deep cloning and immutability
    const temp = JSON.parse(JSON.stringify(dashboard));
    return temp?.find((el) => el.type === 'header')?.data.at(0);
  }, [dashboard]);


  if (error) return <ErrorComponent error={error} pageType={constants.screens.browse} />;
  return (
    <Fragment>
      <PageWrapper loading={dashboardLoading || loading} featuredVideoData={featuredMedia}>
        {featuredMedia && <FeaturedMediaCard playing {...featuredMedia} isBookmarked={false} />}
        <div className={styles.wrapper}>
          <WatchedVideosHorizontalList data={result} />
          {filteredDashboardData?.map((element, index) => {
            if (element?.type === 'topTen') {
              element.data = element.data.slice(0, 10);
            }
            return (
              <MovieList
                key={index}
                movies={element.data}
                sectionTitle={element.title}
                tag={element.type}
                types={element.type}
                section={element.section}
                homePage
                displayType={element.displayType}
                isSeason={element.type === 'season'}
                show={element.show}
                seasonOrder={element.seasonOrder}
                seasonId={element.season}
                slug={window.decodeURI(element?.slug ?? '')}
              />
            );
          })}
        </div>
      </PageWrapper>
      {/* <ScrollRestoration /> */}
      <MarketingPopup />
    </Fragment>
  );
}
