import React from 'react';
import { useTranslation } from 'react-i18next';
import StandardHeroComponent from '../../HOC/StandardHeroComponent/StandardHeroComponent';
import { useNavigate } from 'react-router-dom/';
import constants from '../../Utils/constants';
import styles from './SomethingWentWrong.module.scss';

function SomethingWentWrong({ pageType = constants.screens.browse, destination }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => {
    if(destination) {
      return navigate(destination);
    }
    if (pageType === constants.screens.browse) window.location.reload();
    else navigate(-1);
  };

  switch (pageType) {
    case constants.screens.comingSoon:
    case constants.screens.aboutUs:
    case constants.screens.bookmarks:
    case constants.screens.contactUs:
    case constants.screens.termsAndConditions:
    case constants.screens.actor('').href:
    case constants.newScreens.profiles.view:
    case constants.screens.search:
    case constants.screens.editPic().href:
    case constants.screens.genre().href:
    case constants.screens.category().href:
    case constants.screens.myPurchases:
    case constants.screens.series:
    case constants.screens.shows:
    case constants.screens.browse:
      return (
        <div className={styles.somethingWentWrongPageContainerNoParent}>
          <div className={styles.somethingWentWrongTextContainer}>
            <p className={styles.somethingWentWrong}>
              {t('errors.somethingWentWrong')}
            </p>
            <p className={styles.somethingWentWrongDescription}>
              {t('errors.troubles')}
            </p>
          </div>
          <div onClick={onClick} className={styles.homepageButton}>
            <span className={styles.homepageButtonContent}>
              {t('globals.backToHomePage')}
            </span>
          </div>
        </div>
      );

    case 'modalWeb':
      return (
        <div className={styles.somethingWentWrongPageContainerModal}>
          <div className={styles.somethingWentWrongTextContainer}>
            <p className={styles.somethingWentWrong}>
              {t('errors.somethingWentWrong')}
            </p>
            <p className={styles.somethingWentWrongDescription}>
              {t('errors.troubles')}
            </p>
          </div>
          <div onClick={onClick} className={styles.homepageButton}>
            <span className={styles.homepageButtonContent}>
              {t('globals.backToHomePage')}
            </span>
          </div>
        </div>
      );

    default:
      return (
        <StandardHeroComponent className={styles.parentOverride}>
          <div className={styles.somethingWentWrongPageContainer}>
            <div className={styles.somethingWentWrongTextContainer}>
              <p className={styles.somethingWentWrong}>
                {t('errors.somethingWentWrong')}
              </p>
              <p className={styles.somethingWentWrongDescription}>
                {t('errors.troubles')}
              </p>
            </div>
            <div onClick={onClick} className={styles.homepageButton}>
              <span className={styles.homepageButtonContent}>
                {t('globals.backToHomePage')}
              </span>
            </div>
          </div>
        </StandardHeroComponent>
      );
  }
}

export default SomethingWentWrong;
