import firebase from 'firebase/app';
import 'firebase/analytics';
import config from './config';

const {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
} = config.firebase;

const clientCredentials = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

if (
  !firebase.apps.length
  && config.env !== config.environments.dev
  && typeof window !== 'undefined'
) {
  firebase.initializeApp(clientCredentials);
  if ('measurementId' in clientCredentials && 'projectId' in clientCredentials) {
    firebase.analytics();
  }
}

export default firebase;
