import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import constants from '../../../Utils/constants';
import styles from './FooterTabs.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const screenToNavigate = (key) => {
  switch (key) {
    case 'termsAndConditions':
      return constants.screens.termsAndConditions;
    case 'privacyPolicy':
      return constants.screens.privacyPolicy;
    case 'contactUs':
      return constants.screens.contactUs;
    case 'profile':
      return constants.screens.profile;
    case 'aboutUs':
      return constants.screens.aboutUs;
    default:
      return '';
  }
};

const FooterTabs = () => {
  const { isSignedIn } = useSelector((state) => state.authentication);
  const { t } = useTranslation();
  const footerTabs = [
    {
      key: 'aboutUs',
      name: t('globals.aboutUs'),
    },
    {
      key: 'profile',
      name: t('globals.myAccount'),
      isSignedIn: true,
    },
    {
      key: 'contactUs',
      name: t('globals.contactUs'),
    },
    {
      key: 'privacyPolicy',
      name: t('globals.privacyPolicy'),
    },
    {
      key: 'termsAndConditions',
      name: t('globals.termsAndConditions'),
    },
  ];
  return (
    <div className={`${styles.tabsContainer} align-items-center flex-grow-0 `} >
      {footerTabs.map((tab, idx) => {
        if (tab.isSignedIn && tab.isSignedIn !== isSignedIn) {
          return <Fragment key={idx} />;
        }
        return (
          <Link key={idx} to={screenToNavigate(tab.key)} className={classNames(styles.footerTab, "text-decoration-none")}>
            {tab.name}
          </Link>
        );
      })}
    </div>
  );
};
export default FooterTabs;
