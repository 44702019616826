import constants from '@Utils/constants'
import { Link } from 'react-router-dom'
import styles from './ProfileLink.module.scss';
import { useAppSelector } from '@Store/hooks';
import PlaceholderImage from '@Assets/Images/user.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import utilStyles from '@Utils/utils.module.scss';
import classNames from 'classnames';
import i18n from 'src/i18n';

function ProfileLink() {
    const { profile: profileId } = useAppSelector((state) => state.authentication);
    const { result: profiles } = useAppSelector((state) => state.ProfilesPerUser);
    const { profile } = useAppSelector((state) => state.user);
    //@ts-expect-error need to implement types for this object
    const currentProfile = profiles?.data?.find((profile) => profile?.id === profileId);
    const currentProfileImage = currentProfile?.avatar ?? PlaceholderImage;
    const dir = i18n.dir();

    return (
        <Link to={constants.newScreens.profiles.view} className={classNames(utilStyles.noDecoration, styles.profileGroup, styles[`profileGroup_${dir}`])}>
            <LazyLoadImage className={styles.profileImage} src={currentProfileImage} alt='current-user-profile-image' />
            <div className={classNames(styles.subtitle, styles[`subtitle_${dir}`])}>
                <span>{currentProfile?.name}</span>
                {/* @ts-expect-error need to implement types for this object */}
                <span className={styles.email}>{profile?.email}</span>
            </div>
        </Link>
    )
}

export default ProfileLink