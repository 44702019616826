import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PaymentButton.module.scss';
import ClickableItem from '../ClickableItem/ClickableItem';

const PaymentButton = ({
  text,
  images,
  className,
  isActive,
  onClick,
  disabled,
}) => (
  <Col
    className={classNames(styles.buttonContainer)}
  >
    <ClickableItem
      classes={classNames(
        styles.container,
        isActive ? styles.active : '',
        className,
      )}
      onClick={disabled ? () => null : onClick}
    >
      <div className={styles.textContainer}>{text}</div>

      {images && (
        images.map((img, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <img key={idx} className={styles.image} src={img} alt="payment" />
        ))
      )}
    </ClickableItem>
  </Col>
);

PaymentButton.propTypes = {
  text: PropTypes.string.isRequired,
  images: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.string, PropTypes.node]),
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

PaymentButton.defaultProps = {
  className: '',
  disabled: false,
};

export default PaymentButton;
