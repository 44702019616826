import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { isMobile } from 'react-device-detect';
import MobileLayout from './MobileLayout/MobileLayout';
import DesktopLayout from './DesktopLayout/DesktopLayout';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { Movie } from "src/alballam-api-types";
import constants from '@Utils/constants';
import PlayButtonContent from './PlayButton/PlayButton';
import { IBasicPlayer } from '../Hero.types';
import { getMuteButtonState, validateCanWatchMovie } from '@Utils/common';
import { useAppSelector } from '@Store/hooks';
import { deleteFromBookmarks, addToBookmarks } from 'src/alballam-api';
import BookmarkButtonContent from '@Pages/ShowDetails/Hero/Details/BookmarkButton/BookmarkButton';
import { playerInitialState } from '../Hero';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { setPaymentModalData, togglePaymentModal } from '@Store/Actions/PaymentModalActions';
import { PurchasableMedia } from '@Utils/commonTypes';

interface IDetailsProps {
    playerStatus?: IBasicPlayer;
    setPlayerStatus?: Dispatch<SetStateAction<IBasicPlayer>>
    muted: boolean;
    isPlaying: boolean;
    movie: Movie;
    id: string;
}

const Details: React.FC<IDetailsProps> = (props) => {
    const { userAuthType } = useAppSelector(state => state.authentication);
    const isPlayButtonDisabled = props.movie?.comingSoon || false;
    const { isBookmarked, type, } = props.movie;
    const isAddToBookmarksButtonDisabled = false;
    const revalidator = useRevalidator();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onClickSecondaryButton = async () => {
        if (userAuthType !== 'user') {
            navigate({ pathname: constants.screens.login, search: queryString.stringify({ redirect_url: window.location.pathname }) });
            return;
        }
        if (isBookmarked) {
            await deleteFromBookmarks(props.id, type);
        } else {
            await addToBookmarks(props.id, type)
        }
        revalidator.revalidate();
    };

    const onClickPrimaryButton = () => {
        // coming soon is handled because the button will be disabled
        const movie = props.movie;
        if (movie.comingSoon) {
            return;
        }

        const canWatchMovie = validateCanWatchMovie(movie);
        if (canWatchMovie) {
            navigate(constants.newScreens.watch(props.movie.id).as);
        }

        dispatch(togglePaymentModal(true));
        dispatch(setPaymentModalData({purchaseType: PurchasableMedia.Movie, purchaseId: props.id}));
        return;
    };
    const copyPathToClipboard = async () => navigator.clipboard.writeText(window.location.origin + window.location.pathname);
    const isPlayerActive = !!(props?.playerStatus?.IsVisible && props?.playerStatus?.hasLoaded);
    const shouldRenderMuteButton = props.movie?.trailer !== null && props.playerStatus?.hasPlayedOnce === true;
    const muteButtonStatus = useMemo(() => getMuteButtonState(isPlayerActive, props.playerStatus?.isMuted ?? true), [isPlayerActive, props.playerStatus?.isMuted]);

    const hasToPay = props.movie.paid === true && props.movie.owned === false;
    const purchasePackage = props.movie?.packages?.find((pck) => pck.type === 'purchase') ?? undefined;

    const onMediaButtonClick = () => {
        if (!props.setPlayerStatus) {
            return;
        }

        if (isPlayerActive && shouldRenderMuteButton) {
            props.setPlayerStatus((prevState) => ({
                ...prevState,
                isMuted: !prevState.isMuted,
            }));
            return;
        }

        props.setPlayerStatus((prev) => ({
            ...playerInitialState,
            isMuted: prev.isMuted
        }));
    }

    const layoutProps = {
        copyPathToClipboard,
        isPlayButtonDisabled,
        onClickPrimaryButton,
        showMuteButton: shouldRenderMuteButton,
        onClickMute: onMediaButtonClick,
        muteButtonStatus: muteButtonStatus,
        isPlaying: props.muted,
        releaseDate: props.movie.date,
        PlayButtonContent: <PlayButtonContent hasToPay={hasToPay} purchasePackage={purchasePackage} track={props.movie.track} comingSoon={props?.movie?.comingSoon} />,
        onClickBookmarksButton: onClickSecondaryButton,
        isBookmarksButtonDisabled: isAddToBookmarksButtonDisabled,
        BookmarkButtonContent: <BookmarkButtonContent isBookmarked={isBookmarked ?? false} />,
        ...props.movie
    }

    if (isMobile) return <MobileLayout {...layoutProps} />
    return <DesktopLayout  {...layoutProps} />
}

export default Details