import Home from '@Pages/Home/Home';
import constants from '@Utils/constants'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function HomePageRouter() {
    const { profile, isSignedIn } = useSelector((state) => state.authentication);
    const { selected } = useSelector((state) => state.videoSubscription);
    if (!profile && isSignedIn && !selected) return <Navigate to={constants.newScreens.profiles.view} />
    return <Home />;
}

export default HomePageRouter