import { useMemo } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import styles from './SelectMethod.module.scss';
import { Button } from 'react-bootstrap';
import { useAppSelector } from '@Store/hooks';
import { useTranslation } from 'react-i18next';
import LandscapeImage from '@Atoms/LandscapeImage/LandscapeImage';
import Placeholder from '@Assets/Images/placeholders/16x9.svg';
import { purchaseWithMyFatoorah, subscribeWithMyFatoorah } from 'src/alballam-api';
import { isIPackage, isPackage, Platform, PurchasableMedia, PurchaseType } from '@Utils/commonTypes';
import { ISubscriptionRouteLoaderData } from "src/Routers/PaymentRouter/PaymentRouter.types";
import constants from '@Utils/constants';
import { preparePurchaseSource, prepareSubscriptionSource, parseTVSource } from "@Utils/sourceUtils";
import { getSubscriptionSource, getSubtext } from './SelectMethod.utils';

function SelectMethod() {
    const { paymentMethods, selectedPackage, paymentInfo } = useLoaderData() as ISubscriptionRouteLoaderData;
    const { language } = useAppSelector((state) => state.authentication);
    const webSubscriptionSource = useAppSelector((state) => state.subscriptionSource);
    const { t } = useTranslation();

    const [params] = useSearchParams();
    const qrId = params.get(constants.searchParams.qrId);
    const origin = params.get(constants.searchParams.origin);

    // this is NOT the source, this represents the platform for which the payment is being made
    const tvSource = params.get(constants.searchParams.source);
    // tvSource format is as follows: platform-mediaType-episodeId/movieId-language
    const { platform: paramPlatform, mediaType: paramMediaType, id: paramMediaId, language: paramLanguage } = parseTVSource(tvSource);
    const isValidParamPlatform = paramPlatform && Object.values(Platform).includes(paramPlatform as Platform);
    const platform = isValidParamPlatform ? paramPlatform as Platform : Platform.WEB;


    const subtext = useMemo(() => getSubtext(paymentInfo, t), [paymentInfo, t]);
    async function onPaymentMethodClick(paymentMethodId: number) {
        if (!selectedPackage) {
            console.error('No package selected');
            return;
        }
        if (paymentInfo.purchaseType === PurchaseType.Media && isIPackage(selectedPackage)) {
            const source = preparePurchaseSource({
                platform: platform,
                language: language,
                paymentData: {
                    purchaseType: PurchaseType.Media,
                    mediaId: paymentInfo?.mediaId ?? '0000000',
                    type: paymentInfo?.mediaType ?? PurchasableMedia.Episode,
                    title: paymentInfo.title,
                    seasonId: paymentInfo?.seasonId,
                }
            });


            const response = await purchaseWithMyFatoorah(
                origin ?? constants.screens.browse,
                selectedPackage?.id, // uses IPackage
                paymentMethodId,
                source,
                paymentInfo?.mediaType ?? PurchasableMedia.Episode,
                paymentInfo?.mediaId ?? '0000000',
                qrId ?? undefined
            );

            if (!response) {
                console.error('No response');
                return
            }

            window.location.href = response.result;
        } else {
            if (!isPackage(selectedPackage)) {
                console.error('[onPaymentMethodClick] selectedPackage is not of type Package but rather IPackage');
                return;
            }

            const inputSource = await getSubscriptionSource(platform, paramMediaType, paramMediaId, paramLanguage, webSubscriptionSource);
            const source = prepareSubscriptionSource({
                platform: platform,
                pckg: { id: selectedPackage._id.toString(), name: selectedPackage.name },
                source: inputSource
            })

            const response = await subscribeWithMyFatoorah(
                origin ?? constants.screens.browse,
                selectedPackage?._id, // uses Package
                paymentMethodId,
                source,
                qrId ?? undefined
            );


            if (!response) {
                console.error('No response');
                return;
            }

            window.location.href = response.result;
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.selectMethodContainer}>
                <span className={styles.title}>{t('payment.choosePayment')}</span>
                <div className={styles.currentPackageGroup}>
                    {paymentInfo?.img && <LandscapeImage rootWrapperProps={{ className: styles.imgWrapper }} imageProps={{ src: paymentInfo?.img ?? Placeholder, className: styles.img }} />}
                    <div className={styles.currentPackageDescription}>
                        <span>{t('payment.subscribeNow')}</span>
                        <span>
                            <strong dir='ltr'>{`${selectedPackage?.currency ?? '$'}${selectedPackage?.countryPrice ?? selectedPackage?.price}`}</strong>
                            <span> - {subtext}</span>
                        </span>
                    </div>
                </div>
                <div className={styles.methodContainer}>
                    {paymentMethods.result.map((method) => (
                        <Button variant='outline-light' size='lg' onClick={() => onPaymentMethodClick(method?.PaymentMethodId)} className={styles.paymentMethod} key={method.PaymentMethodId}>
                            <span className={styles.methodTitle}>{language === 'en' ? method.PaymentMethodEn : method.PaymentMethodAr}</span>
                            <img src={method.ImageUrl} height={42} alt={method.PaymentMethodEn} />
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SelectMethod;