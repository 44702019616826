import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import PortraitPlaceholder from '@Assets/Images/placeholders/2x3.svg';
import LandscapePlaceholder from '@Assets/Images/placeholders/16x9.svg';
import styles from './TopTenCard.module.scss';
import { MediaType, PurchasableMedia } from '@Utils/commonTypes';
import LandscapeImage from '@Atoms/LandscapeImage/LandscapeImage';
import classNames from 'classnames';
import { getImageTagOptions, redirectToDetails } from '@Utils/common';
import { ITopTenCardProps } from './TopTenCard.types';
import { useNavigate } from 'react-router-dom';
import { useMediaCardPlayer } from '@Molecules/MediaCardPlayer/MediaCardPlayer.utils';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import MediaCardPlayer from '@Molecules/MediaCardPlayer/MediaCardPlayer';
import { getThumbnailOrTrailer } from '@Organisms/ExpandedCard/ExpandedCard.utils';
import MovieCardInfo from '@Molecules/MovieCardInfo/MovieCardInfo';
import { getPlayerStatus } from 'src/alballam-api';
import constants from '@Utils/constants';
import { setCookie } from '@Organisms/MarketingPopup/MarketingPopup.utils';
import { setPaymentModalData } from '@Store/Actions/PaymentModalActions';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@Store/hooks';
import { ImageTagProps } from '@Atoms/ImageTag/ImageTag';

const TopTenCard: React.FC<ITopTenCardProps> = ({
    portraitImage,
    portraitMedium,
    landscapeImage,
    title,
    type,
    comingSoon,
    paid,
    id,
    trailers,
    cardInfoProps,
    index
}) => {
    // prevents top ten digit from appearing out of box when still in loading stage
    const [hasLoaded, setHasLoaded] = useState(false);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>();
    const cardInfoRef = useRef<HTMLDivElement>();
    const playerRef = useRef<ReactPlayer | undefined>();
    const playerIdRef = useRef();
    const dispatch = useDispatch();
    const { isSignedIn } = useAppSelector((state) => state.authentication);

    const onImageClick = useCallback(() => redirectToDetails(navigate, id, type as MediaType), [id, navigate, type]);
    function onHoverCardError(e: SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = LandscapePlaceholder;
        e.currentTarget.style.objectFit = 'cover';
    }
    function onError(e: SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = PortraitPlaceholder;
        e.currentTarget.style.objectFit = 'cover';
        setHasLoaded(true);
    }

    const shouldRenderPlayer = trailers && trailers?.length > 0 && !isMobile;
    const thumbnailOrTrailer = getThumbnailOrTrailer(landscapeImage, trailers, false);
    const onWatchNowClick = useCallback(async () => {
        if (comingSoon) {
            return onImageClick();
        }

        const res = await getPlayerStatus(id, type as MediaType);
        const data = res.result;
        if ((data.owned || !data.paid) && !data.comingSoon) {
            navigate(constants.newScreens.watch(data?.id).as);
            return;
        }

        let destination = '';
        if (data.comingSoon) {
            return onImageClick();
        }

        switch (type) {
            case MediaType.Show:
                destination = constants.newScreens.details.show(id).season(data?.season).as;
                dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Episode }));
                break;
            case MediaType.Movie:
                destination = constants.newScreens.details.movie(id).as;
                dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Movie }));
                break;

            case MediaType.Series:
                destination = constants.newScreens.details.series(id).season(data?.season).as;
                dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Episode }));
                break;
            default:
                break;
        }

        setCookie(constants.locationState.openPayment, 'true', 1);
        if (isSignedIn) navigate(destination);
        else {
            navigate({
                pathname: constants.screens.login,
                search: queryString.stringify({ redirect_url: destination }),
            });
        }

        return;
    }, [comingSoon, dispatch, id, isSignedIn, navigate, onImageClick, type]);


    const imageTagOptions = useCallback((isHoverCard: boolean) => {
        const tagOutput = getImageTagOptions(paid, comingSoon, false, undefined);
        if (isHoverCard) {
            return {
                ...tagOutput,
                containerClassName: styles.hoverCardTagsWrapper,
            } as ImageTagProps;
        }

        return tagOutput;
    }, [comingSoon, paid])
    const {
        isHovered,
        onMouseEnter,
        onMouseLeave,
        playerStatus,
        setPlayerStatus
    } = useMediaCardPlayer(id, ref, cardInfoRef, playerRef);
    const shouldHideImageTags = playerStatus.showPlayer && shouldRenderPlayer;

    return (
        <div
            className={styles.wrapper}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <LandscapeImage
                imageProps={{
                    className: styles.image,
                    placeholderSrc: PortraitPlaceholder,
                    src: portraitMedium ?? portraitImage,
                    alt: title,
                    wrapperClassName: styles.landscapeImageWrapper,
                    fetchPriority: 'high',
                    onLoad: () => setHasLoaded(true),
                    onError,
                }}
                tagProps={imageTagOptions(false)}
                rootWrapperProps={{
                    onClick: onImageClick,
                    className: classNames(styles.landscapeImageWrapper, styles.topMostImageWrapper)
                }}
            >
            </LandscapeImage>
            {hasLoaded && (
                <div className={styles.topTenDigitWrapper}>
                    <span className={classNames(styles.topTenDigit)}>{index + 1}</span>
                </div>
            )}

            <div className={classNames(
                styles.absoluteWrapper,
                isHovered ? styles.absoluteWrapperHovered : styles.hidden
            )}>
                <div
                    className={styles.hoverMediaWrapper}
                    onClick={onImageClick}
                >
                    {shouldRenderPlayer && (
                        <MediaCardPlayer
                            mediaId={id}
                            playerClassName={styles.playerWrapper}
                            muteButtonClassName={styles.muteButtonWrapper}
                            playerStatus={playerStatus}
                            setPlayerStatus={setPlayerStatus}
                            ref={playerRef}
                            url={thumbnailOrTrailer}
                        />
                    )}
                    <LandscapeImage
                        rootWrapperProps={{ className: classNames(styles.hoverImageWrapper, styles.landscape) }}
                        tagProps={shouldHideImageTags ? undefined : imageTagOptions(true)}
                        imageProps={{
                            className: classNames(styles.image, styles.imageHovered),
                            placeholderSrc: LandscapePlaceholder,
                            src: landscapeImage,
                            wrapperClassName: styles.landscapeImageWrapper,
                            alt: title,
                            onError: onHoverCardError,
                        }}
                    />
                </div>
                <MovieCardInfo
                    //@ts-expect-error legacy coode
                    isSeason={false}
                    ref={cardInfoRef}
                    id={playerIdRef || id}
                    title={title}
                    rating={cardInfoProps.rating}
                    onInfoClick={onImageClick}
                    onWatchNowClick={onWatchNowClick}
                    isContinueWatching={false}
                    // onAnimationEnd={onInfoCardAnimationEndHandler}
                    onAnimationEnd={() => { }}
                    quality={cardInfoProps.quality}
                    date={cardInfoProps.date}
                    numberOfSeasons={cardInfoProps.numberOfSeasons}
                    isComingSoon={comingSoon}
                    genres={cardInfoProps.genres}
                    duration={cardInfoProps.duration}
                    isSecondSlider={false}
                    generalText={cardInfoProps.generalText}
                    seasonName={cardInfoProps.seasonName}
                    showName={cardInfoProps.showName}
                />
            </div>
        </div>
    )
}

export default TopTenCard;
