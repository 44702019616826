import {
  SUBSCRIPTION_PACKAGES,
  SUBSCRIPTION_PACKAGES_FAIL,
  SUBSCRIPTION_PACKAGES_SUCCESS,
  CLEAR_SUBSCRIPTION_PACKAGES,
  GET_PACKAGE_SENTENCE_SUCCESS,
  GET_PACKAGE_SENTENCE,
} from '../Constants/SubscriptionPackagesConstants';

const initialState = {
  error: null,
  loading: false,
  result: null,
  sentence: '...',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGE_SENTENCE: {
      return {
        ...state,
        loading: true,
        sentence: '...',
      };
    }
    case GET_PACKAGE_SENTENCE_SUCCESS: {
      return {
        ...state,
        sentence: action.payload.data?.data?.result,
      };
    }
    case SUBSCRIPTION_PACKAGES: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case SUBSCRIPTION_PACKAGES_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case SUBSCRIPTION_PACKAGES_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_SUBSCRIPTION_PACKAGES: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
