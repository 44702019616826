import {
  CLEAR_AUTHENTICATION,
  SAVE_AUTHENTICATION,
  LOGOUT_USER,
  SWITCH_LANGUAGE,
  SAVE_LANGUAGE,
  DELETE_ACCOUNT,
  // SEND_DELETE_OTP
} from '../Constants/AuthenticationConstants';

const saveAuthentication = (data) => ({
  type: SAVE_AUTHENTICATION,
  payload: data,
});

const clearAuthentication = () => ({
  type: CLEAR_AUTHENTICATION,
  payload: null,
});

const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: {
    request: {
      url: 'api/users/logout',
      method: 'put',
      authenticated: true,
    },
  },
});

const deleteAccount = () =>
  // otp
// eslint-disable-next-line implicit-arrow-linebreak
  ({
    type: DELETE_ACCOUNT,
    payload: {
      request: {
        url: 'api/user/delete',
        method: 'delete',
        authenticated: true,
        // data: {
        //   otp,
        // },
      },
    },
  });

// const sendDeleteAccountOtp = (username) => ({
//   type: SEND_DELETE_OTP,
//   payload: {
//     request: {
//       url: 'api/user/deleteOtp',
//       method: 'post',
//       authenticated: true,
//       data: {
//         username,
//       },
//     },
//   },
// });

const switchLanguages = () => ({
  type: SWITCH_LANGUAGE,
  payload: null,
});

const saveLanguague = (language) => ({
  type: SAVE_LANGUAGE,
  payload: language,
});
export {
  clearAuthentication,
  saveAuthentication,
  logoutUser,
  switchLanguages,
  saveLanguague,
  deleteAccount,
  // sendDeleteAccountOtp,
};
