import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SubmitInput } from '../../Atoms';
import MessageBox from '../../Atoms/MessageBox';
import successIcon from '../../../Assets/Images/icons-success@3x.png';
import failedIcon from '../../../Assets/Images/icons-failed@3x.png';
import styles from './index.module.scss';
import Loader from '../../Atoms/Loader/Loader';

const SuccessFailTemp = ({
  isError, message, onDone, loading, profile,
}) => {
  const { t } = useTranslation();
  return loading ? (
    <div className={`${styles.loaderContainer} my-5 d-flex justify-content-center align-items-center`}>
      <Loader size={24} color='white'/>
    </div>
  ) : (
    <div>
      {!profile && (
        <div className="justify-content-center align-items-center d-flex flex-column">
          <img src={isError ? failedIcon : successIcon} alt="" width="106" height="109" className="mt-4 mb-4" />
        </div>
      )}
      <div className={profile ? 'p-3' : `${styles.messageBox}`}>
        <MessageBox message={message} isError={isError} />
      </div>
      <div className="mb-4">
        <SubmitInput
          value={t('globals.ok')}
          type="primary"
          size={24}
          onClick={onDone}
        />
      </div>
    </div>
  );
};

SuccessFailTemp.propTypes = {
  onDone: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  profile: PropTypes.bool,
};

SuccessFailTemp.defaultProps = {
  profile: false,
};

export default SuccessFailTemp;
