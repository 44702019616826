import {
  GET_MY_PURCHASES_DATA, GET_MY_PURCHASES_DATA_SUCCESS, GET_MY_PURCHASES_DATA_FAIL,
} from '../Constants/MyPurchasesConstants';

const initialState = {
  result: null,
  loading: false,
  error: null,
};

const myPurchasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_PURCHASES_DATA:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_MY_PURCHASES_DATA_SUCCESS:
      return {
        ...state,
        result: action.payload.data.data.result,
        loading: false,
      };
    case GET_MY_PURCHASES_DATA_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default myPurchasesReducer;
