import React from 'react';
import { Store } from 'react-notifications-component';
import CustomAlert from '../Components/Atoms/CustomAlert/CustomAlert';

const notificationConfig = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
    // showIcon: true,
  },
};

const showNotification = (message, type = 'success', title, container = 'top-right') => {
  Store.addNotification({
    // title,
    message: <CustomAlert message={message} type={type} title={title} />,
    type,
    ...notificationConfig,
    container,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { showNotification };
