import React from 'react';
import {
  Modal,
  ModalBody, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

const DeleteAccountModal = ({
  isDeleteAccountFormVisible, setIsDeleteAccountFormVisible, deleteAccount, cancelSubscriptionLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { language } = useSelector((state) => state.authentication);

  return (
    <Modal
      show={isDeleteAccountFormVisible}
      backdrop
      onHide={() => {
        setIsDeleteAccountFormVisible(false);
      }}
      dialogClassName="modalEditProfile"
    >
      <ModalBody
        className={
      language === 'ar'
        ? 'modalBodyEditProfile'
        : 'modalBodyEng'
    }
      >
        <div className="d-flex titleContainer justify-content-center myAccountTitleCancel">
          <div className="d-flex justify-content-center">
            <span
              className={classNames(
                language === 'ar'
                  ? 'myAccountTitle'
                  : 'myAccountTitleEn',
                'myAccountTitleCancel',
              )}
            >
              {t('globals.deleteAccount')}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <span className="areYouSureCancelSub">
            {t('globals.areYouSureDeleteAccount')}
          </span>
        </div>
        <div className="d-flex justify-content-center mt-1">
          <Button
            disabled={cancelSubscriptionLoading}
            onClick={() => {
              dispatch(deleteAccount());
            }}
            className="FullWatchButtonCancelSub"
          >
            <span className="subscribe">{t('globals.yes')}</span>
          </Button>
          <Button
            onClick={() => {
              setIsDeleteAccountFormVisible(false);
            }}
            className="FullWatchButtonCancelSubNo"
          >
            <span className="subscribe">{t('globals.no')}</span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAccountModal;
