const GET_LIVE_CHANNELS = 'GET_LIVE_CHANNELS';
const GET_LIVE_CHANNELS_SUCCESS = 'GET_LIVE_CHANNELS_SUCCESS';
const GET_LIVE_CHANNELS_FAIL = 'GET_LIVE_CHANNELS_FAIL';
const CLEAR_LIVE_CHANNELS_DATA = 'CLEAR_LIVE_CHANNELS_DATA';

export {
  GET_LIVE_CHANNELS,
  GET_LIVE_CHANNELS_SUCCESS,
  GET_LIVE_CHANNELS_FAIL,
  CLEAR_LIVE_CHANNELS_DATA,
};
