import { Outlet, useLoaderData, useParams } from 'react-router-dom';
import styles from './MovieDetails.module.scss';
import { BrowserView, MobileView } from 'react-device-detect';
import BackButton from '@Atoms/BackButton/BackButton';
import Hero from './Hero/Hero';
import Details from './Hero/Details/Details';
import { Fragment } from 'react/jsx-runtime';
import { IDetailsPageLoaderData, MediaType } from '@Utils/commonTypes';
import { useMemo } from 'react';
import MediaDetailsTabs from '@Molecules/MediaDetailsTabs/MediaDetailsTabs';
import PaymentModal from '@Molecules/PaymentModalNouveau/PaymentModal';
import { getHiddenSections } from '@Utils/common';

function MovieDetails() {
    const { movie } = useLoaderData() as IDetailsPageLoaderData;
    const movieContent = movie!.result;

    const { movieId } = useParams();
    const memoizedOutletProps = useMemo(() => ({ media: movieContent }), [movieContent]);
    const hiddenSections = useMemo(() => getHiddenSections({ media: movieContent, mediaType: MediaType.Movie, season: undefined }), [movieContent]);

    return (
        <Fragment>
            <div className={styles.root}>
                <BrowserView>
                    <Hero {...movieContent} image={movieContent?.featuredImageLandscape} playing>
                        {({ playerStatus, setPlayerStatus }) => {
                            return (
                                <Details
                                    id={movieId!}
                                    movie={movieContent}
                                    isPlaying={playerStatus.isPlaying}
                                    muted={playerStatus.isMuted}
                                    playerStatus={playerStatus}
                                    setPlayerStatus={setPlayerStatus}
                                />
                            )
                        }}
                    </Hero>
                </BrowserView>
                <MobileView>
                    <BackButton />
                    <Hero {...movieContent} playing={false} />
                    <Details id={movieId!} movie={movieContent} muted={true} isPlaying={true} />
                </MobileView>
                <div className={styles.bottomSectionContainer}>
                    <MediaDetailsTabs mediaType={MediaType.Movie} mediaId={movieId!} hiddenSections={hiddenSections} />
                    <Outlet context={memoizedOutletProps} />
                </div>
            </div>
            <PaymentModal />
        </Fragment>
    )
}

export default MovieDetails;