import Episode, { IPackage } from "@Atoms/Episode/Episode";
import { Package } from "src/alballam-api-types";
import { WithApiMetaData, Show, Movie, Series } from "src/alballam-api-types";

export enum MediaType {
  Show = "show",
  Series = "series",
  Movie = "movie",
}

export interface ITrackedMedia {
  date: string;
  duration: number;
  episode: number;
  episodeTitle: string;
  id: number;
  image: string;
  medium: string;
  paid: boolean;
  playerId: number;
  portraitImage: string;
  portraitMedium: string;
  portraitThumbnail: string;
  rating: string;
  season: number;
  seasonName: string;
  slug: string;
  thumbnail: string;
  title: string;
  type: MediaType;
}

export interface IGenericTrailer {
  duration?: number;
  id: number;
  large: string;
  link: string;
  medium: string;
  order: number;
  portraitDuration: number;
  portraitLarge: string;
  portraitLink: string;
  portraitMedium: string;
  portraitThumbnail: string;
  thumbnail: string;
  title: string;
}

export interface ISeriesTrailer extends IGenericTrailer {
  seasonNumber?: number;
  seasonTitle?: string;
}

export interface IMainSliderTrailer {
  duration: number;
  id: number;
  link: string;
  order: number;
  portraitDuration: number;
  portraitThumbnail?: string;
  thumbnail?: string;
  portraitLink: string;
  qtitle: {
    ar: string;
    en: string;
  };
}

export interface Genre {
  name: string;
  id: number;
}

export interface IIndividual {
  name: string;
  id: number;
}

export type SeriesSeason = Omit<Season, "trailers"> & {
  trailers: Array<ISeriesTrailer>;
};

export interface Season {
  authors: Array<IIndividual>;
  crew: Array<IIndividual>;
  directors: Array<IIndividual>;
  episodes: Array<Episode>;
  highlighted: boolean;
  hosts: Array<IIndividual>;
  id: string;
  owned: boolean;
  packages: Array<IPackage>;
  paid: boolean;
  purchase: any | null; // no idea what this prop type is
  season: boolean;
  seasonNumber: number;
  sourceTitle: string;
  title: string;
  titles: { en: string; ar: string };
  trailers: Array<IMainSliderTrailer>;
}

export interface ITrack {
  comingSoon: boolean;
  episodeNumber: number;
  owned: boolean;
  packages: Array<IPackage>;
  seasonId: string;
  seasonNumber: number;
  seasonPackages: null | unknown;
  track: number;
  id: string;
  paid: boolean;
}

export interface IFeaturedMedia {
  comingSoon: boolean;
  containsFreeEpisodes: boolean;
  date: string;
  description: string;
  featuredImage: string;
  featuredImageLandscape: string;
  featuredImageLarge: string;
  generalText: string;
  genres: Array<Genre>;
  hasLatestTrailer: boolean;
  id: number;
  image: string;
  isComingSoon: boolean;
  medium: string;
  nbrOfSeasons: number;
  player?: ITrack;
  portraitImage: string;
  portraitMedium: string;
  portraitThumbnail: string;
  publishDate: string;
  rating: string;
  slug: string;
  thumbnail: string;
  title: string;
  titleImage: string;
  trailer?: IMainSliderTrailer;
  trailers: Array<IMainSliderTrailer>;
  type: string;
}

export function isIPackage(obj: IPackage | Package): obj is IPackage {
  return obj && typeof obj === "object" && "id" in obj;
}

export function isPackage(obj: IPackage | Package): obj is Package {
  return obj && typeof obj === "object" && "_id" in obj;
}

export function isMovie(obj: Movie | Show | Series): obj is Movie {
  return obj && typeof obj === "object" && obj.type === 'movie';
}

export enum TagType {
  Free = "free",
  Priced = "priced",
  ComingSoon = "comingSoon",
}

export enum PurchaseType {
  Media = "media",
  Subscribe = "subscribe",
}

export enum PurchasableMedia {
  Movie = "movie",
  Episode = "episode",
  Season = "season",
}
export interface IDetailsPageLoaderData {
  show?: WithApiMetaData<Show>;
  movie?: WithApiMetaData<Movie>;
  series?: WithApiMetaData<Series>;
}
export enum Platform {
  WEB = "web",
  SAMSUNGTV = "samsungtv",
  LGTV = "lgtv",
  ANDROIDTV = "androidtv"
}
export type Source = ({ purchaseType: PurchaseType.Media; } & IMediaSource) | ({ purchaseType: PurchaseType.Subscribe; } & ISubscriptionSource);
export interface IMediaSource {
  // type specifies what type the purchase item is,
  // if movie or series, then there is no need for further details
  // if episode, then seasonId and episodeId are required
  type: PurchasableMedia;
  mediaId: string;
  seasonId?: string;
  title: string;
}
export interface ISubscriptionSource {
  length: string | number;
  price: string | number;
  title: string; // title of the subscription
  chosenItemType: PurchasableMedia | 'auto-renewal' | null;
  chosenItemName: string;
  chosenItemId: string;
  mediaName?: string | null;
  mediaId?: string | null;
  seasonName?: string | null;
  seasonId?: string | null;
}
export interface IPlayerStatus {
  comingSoon: boolean;
  id: string;
  owned: boolean;
  packages: Array<IPackage>;
  paid: boolean;
  track: string;
  season?: string;
  seasonPackages?: Array<IPackage>;
}
export enum Language {
  EN = 'EN',
  AR = 'AR'
}

// used to  pass needed params to the loader function
// helps distinguish between the different types of purchases
export interface IPaymentInfo {
  title: string;
  purchaseType: PurchaseType;
  seasonId?: string;
  mediaType?: PurchasableMedia;
  mediaId?: string;
  img?: string;
}

