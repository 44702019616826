import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPopup } from '@Store/Actions/MarketingPopupActions';
import styles from './MarketingPopup.module.scss';
import marketingPopupClose from '@Assets/Images/Icons/marketing-popup-close.svg';
import { useAppSelector } from '@Store/hooks';
import { getCookie, setCookie } from './MarketingPopup.utils';


const MarketingPopup = () => {
    const dispatch = useDispatch();
    const popup = useAppSelector((state) => state.marketingPopup);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const lastDisplayDate = getCookie('lastPopupDate');
        const currentDate = new Date().toISOString().split('T')[0];

        if (lastDisplayDate !== currentDate) {
            dispatch(getPopup());
        }
    }, [dispatch]);

    useEffect(() => {
        const lastDisplayDate = getCookie('lastPopupDate');
        const currentDate = new Date().toISOString().split('T')[0];

        if (lastDisplayDate !== currentDate && popup.popUp?.showPopUp) {
            setModalShow(true);
        }
    }, [popup.popUp.showPopUp]);

    const handleClose = () => {
        const currentDate = new Date().toISOString().split('T')[0];
        setCookie('lastPopupDate', currentDate, 1);
        setModalShow(false);
    };

    const handleRedirect = () => {
        if (!popup.popUp.redirection || popup.popUp.url === undefined) {
            return;
        }
        const currentDate = new Date().toISOString().split('T')[0];
        setCookie('lastPopupDate', currentDate, 1);
        navigate(popup.popUp.url);
        setModalShow(false);
    };

    return (
        <Modal
            show={modalShow}
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styles.marketingPopupContent}
            dialogClassName={styles.marketingPopupDialog}
            centered
        >
            <Modal.Body className={styles.marketingPopupBody}>
                <div className={styles.marketingPopupContainer}>
                    <span className={styles.closeButton} onClick={handleClose}>
                        <img src={marketingPopupClose} alt="close-button" />
                    </span>
                    <div onClick={handleRedirect}>
                        <img src={popup?.popUp.image} alt="marketing-popup" className={styles.marketingPopupImg} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MarketingPopup;
