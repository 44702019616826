import {
  GET_AVATARS,
} from '../Constants/AvatarsConstants';

/* eslint-disable  import/prefer-default-export */

const getAvatars = () => ({
  type: GET_AVATARS,
  payload: {
    request: {
      url: 'api/avatar/web',
      method: 'get',
      authenticated: true,
    },
  },
});

export { getAvatars };
