import constants from "@Utils/constants";
import { useMemo } from "react"
import CloseIcon from '@Assets/Images/nouveau/close.svg';
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from './PaymentModal.module.scss';
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import PurchaseButton from "@Atoms/PurchaseButton/PurchaseButton";
import { Series } from "src/alballam-api-types";
import { isMovie, MediaType, PurchasableMedia } from "@Utils/commonTypes";
import { IPurchaseButtonProps } from "@Atoms/PurchaseButton/PurchaseButton.types";
import { getEpisodePackages, getSeasonPurchasePackage, useCurrentMediaData, useHandleModalVisibility } from "./helpers";
import OrSeparator from "@Molecules/PaymentModal/OrSeparator/OrSeparator";
import SubscriptionPackageButton from "@Molecules/PaymentModal/SubscriptionPackageButton/SubscriptionPackageButton";
import { ErrorBoundary } from "@sentry/react";
import { useDispatch } from "react-redux";
import { parseSubscriptionSource } from "@Utils/sourceUtils";
import { saveSubscriptionSource } from "@Store/Actions/subscriptionsSourceActions";
import { useAppSelector } from "@Store/hooks";
import classNames from "classnames";


function PaymentModal() {
    const { seasonId, movieId, seriesId, showId } = useParams();
    const { shouldAppear, closeModal } = useHandleModalVisibility();
    const { currentMedia, currentMediaType, purchaseId, purchaseType, purchasableMediaType, currentMediaSeasonId, currentMediaSeasonName } = useCurrentMediaData();
    const { language } = useAppSelector((state) => state.authentication);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = queryString.parse(location.search);
    const dispatch = useDispatch();

    const redirectToSubscriptionsPage = () => {
        const source = parseSubscriptionSource(
            currentMedia?.result,
            { id: currentMediaSeasonId!, name: currentMediaSeasonName ?? 'could-not-find-season-name' },
            { type: purchasableMediaType, id: purchaseId! },
            { seasonableMediaId: seriesId ?? showId },
            language
        );
        dispatch(saveSubscriptionSource(source));


        const origin = movieId || purchaseId ? constants.screens.watch(movieId ?? purchaseId).as : location.pathname;
        navigate({
            pathname: constants.screens.subscribeAll,
            search: queryString.stringify({
                [constants.searchParams.origin]: origin,
                ...params
            })
        }, { unstable_viewTransition: true })
    };

    const purchaseSeasonButtonProps = useMemo(() => {
        const out: IPurchaseButtonProps = {
            type: 'purchase',
            isEpisode: false,
            onClick: () => { },
            price: undefined,
            currency: '',
        };

        const seasonedMedia = currentMedia?.result as Series;
        const purchasePackage = getSeasonPurchasePackage(currentMediaSeasonId!, seasonedMedia);
        if (!purchasePackage) {
            // console.warn('PaymentModal: purchasePackage is not defined');
            return {
                shouldRender: false,
                content: out
            };
        }


        if (currentMediaType === MediaType.Show) {
            out.onClick = () => navigate({
                pathname: constants.newScreens.pay.purchase.show(showId).season(currentMediaSeasonId).as,
                search: queryString.stringify({ origin: location.pathname, ...params })
            }, { unstable_viewTransition: true });
        }
        if (currentMediaType === MediaType.Series) {
            out.onClick = () => navigate({
                pathname: constants.newScreens.pay.purchase.series(seriesId).season(currentMediaSeasonId).as,
                search: queryString.stringify({ origin: location.pathname, ...params })
            }, { unstable_viewTransition: true });
        }

        out.isEpisode = false;
        out.price = purchasePackage?.countryPrice ?? purchasePackage?.price;
        out.currency = purchasePackage?.currency ?? '$';
        out.type = 'season';


        return { shouldRender: true, content: out };
    }, [currentMedia?.result, currentMediaSeasonId, currentMediaType, location.pathname, navigate, params, seriesId, showId]);


    const purchaseButtonProps = useMemo(() => {
        const out: IPurchaseButtonProps = {
            type: 'purchase',
            isEpisode: false,
            onClick: () => { },
            price: undefined,
            currency: '',
        };

        let purchasePackage;
        let seasonedMedia;
        // first figure out if this is a series or a movie
        // if series, figure out if it is a season or an episode
        switch (purchaseType) {
            case PurchasableMedia.Movie:
                if (!isMovie(currentMedia!.result)) {
                    console.warn('PaymentModal: currentMedia is not a movie');
                    return out;
                }


                out.onClick = () => navigate({
                    pathname: constants.newScreens.pay.purchase.movie(movieId).as,
                    search: queryString.stringify({
                        [constants.searchParams.origin]: constants.screens.watch(movieId).as,
                        ...params,
                    })
                }, { unstable_viewTransition: true });
                out.isEpisode = false;
                out.type = 'purchase';


                purchasePackage = currentMedia?.result.packages.find((p) => p.type === 'purchase');
                if (purchasePackage === undefined) {
                    out.shouldRender = false;
                }
                out.price = purchasePackage?.countryPrice ?? purchasePackage?.price;
                out.currency = purchasePackage?.currency ?? '$';
                break;

            case PurchasableMedia.Episode:
                // get the episode
                if (currentMediaType === MediaType.Show) {
                    out.onClick = () => navigate({
                        pathname: constants.newScreens.pay.purchase.show(showId).season(seasonId).episode(purchaseId).as,
                        search: queryString.stringify({
                            [constants.searchParams.origin]: constants.screens.watch(purchaseId).as,
                            ...params,
                        })
                    }, { unstable_viewTransition: true });
                }
                if (currentMediaType === MediaType.Series) {
                    out.onClick = () => navigate({
                        pathname: constants.newScreens.pay.purchase.series(seriesId).season(seasonId).episode(purchaseId).as,
                        search: queryString.stringify({
                            [constants.searchParams.origin]: constants.screens.watch(purchaseId).as,
                            ...params,
                        })
                    }, { unstable_viewTransition: true });
                }

                seasonedMedia = currentMedia!.result as Series;
                purchasePackage = getEpisodePackages(purchaseId!, seasonedMedia);
                if (purchasePackage === undefined) {
                    out.shouldRender = false;
                }
                // do not pass a type for it to be an episode
                //@ts-expect-error no time to type check this
                out.price = purchasePackage?.countryPrice ?? purchasePackage?.price;
                //@ts-expect-error no time to type check this
                out.currency = purchasePackage?.currency ?? '$';
                out.isEpisode = true;
                out.type = 'purchase';


                break;
            case PurchasableMedia.Season:
                // get the season
                seasonedMedia = currentMedia!.result as Series;
                purchasePackage = getSeasonPurchasePackage(purchaseId!, seasonedMedia);
                if (purchasePackage === undefined) {
                    out.shouldRender = false;
                }

                if (currentMediaType === MediaType.Show) {
                    out.onClick = () => navigate({
                        pathname: constants.newScreens.pay.purchase.show(showId).season(purchaseId).as,
                        search: queryString.stringify({ origin: location.pathname, ...params })
                    }, { unstable_viewTransition: true });
                }
                if (currentMediaType === MediaType.Series) {
                    out.onClick = () => navigate({
                        pathname: constants.newScreens.pay.purchase.series(seriesId).season(purchaseId).as,
                        search: queryString.stringify({ origin: location.pathname, ...params })
                    }, { unstable_viewTransition: true });
                }

                out.isEpisode = false;
                out.price = purchasePackage?.countryPrice ?? purchasePackage?.price;
                out.currency = purchasePackage?.currency ?? '$';
                out.type = 'season';
                break;

            default:
                break;
        }

        return out;
    }, [currentMedia, currentMediaType, location.pathname, movieId, navigate, params, purchaseId, purchaseType, seasonId, seriesId, showId]);


    return (
        <Modal centered show={shouldAppear} onHide={closeModal}>
            <Modal.Header dir="auto" className={styles.header}>
                <div className={styles.titleContainer}>
                    <span className={styles.title}>{t('payment.toWatchThisContent')}</span>
                </div>
                <button onClick={closeModal} className={styles.closeButton}>
                    <img src={CloseIcon} alt='close' height={24} width={24} />
                </button>
            </Modal.Header>
            <Modal.Body dir="auto" className={styles.modalBody}>
                <div className={classNames(styles.purchaseButtonGroup, !purchaseSeasonButtonProps.shouldRender && styles.purchaseButtonGroupRow)}>
                    {purchaseSeasonButtonProps.shouldRender && <PurchaseButton {...purchaseSeasonButtonProps.content} paymentModal />}
                    <PurchaseButton {...purchaseButtonProps} paymentModal />
                    {purchasableMediaType === PurchasableMedia.Season && (
                        <div className={styles.subtitle}>
                            <span>{t('payment.seasonSubTextLineOne')} {t('payment.seasonSubTextLineTwo')}</span>
                        </div>
                    )}
                </div>
                {(purchaseButtonProps.shouldRender !== false || purchaseSeasonButtonProps.shouldRender) && <OrSeparator />}
                <div className={styles.subscriptionGroup}>
                    <SubscriptionPackageButton onClick={redirectToSubscriptionsPage} subtitle1={t('payment.lineOne')} subtitle2={t('payment.lineTwo')} />
                    <div className={styles.subtitle}>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const ErrorBoundaryWrapper = () => <ErrorBoundary><PaymentModal /></ErrorBoundary>
export default ErrorBoundaryWrapper;
