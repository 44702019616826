import {
  GET_SERIES_LANDING,
  GET_SERIES_LANDING_FAIL,
  GET_SERIES_LANDING_SUCCESS,
  CLEAR_SERIES_LANDING,
} from '../../Constants/SeriesConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERIES_LANDING: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_SERIES_LANDING_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_SERIES_LANDING_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_SERIES_LANDING: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default reducer;
