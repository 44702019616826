import {
  CLEAR_VIDEO_DETAILS,
  GET_VIDEO_DETAILS,
  GET_VIDEO_DETAILS_FAIL,
  GET_VIDEO_DETAILS_SUCCESS,
  CLEAR_SHOW_DETAILS,
  GET_SHOW_DETAILS,
  GET_SHOW_DETAILS_FAIL,
  GET_SHOW_DETAILS_SUCCESS,
} from '../Constants/VideoDetailsConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

// const VIDEO_QUALITIES = [
//   { name: 'Auto', url: '' },
//   { name: 'Low', url: '' },
//   { name: 'High', url: '' },
// ];
// const VIDEO_SUBTITLES = [
//   { src: '', srcLang: '', name: 'Off' },
//   { src: '', srcLang: 'en', name: 'English' },
//   { src: '', srcLang: 'ar', name: 'Arabic' },
//   { src: '', srcLang: 'fr', name: 'French' },
// ];
// const SERIES_EPISODES = [
//   {
//     id: 1,
//     episodeNbr: 1,
//     title: 'مسلسل سما عالية',
//     watched: 0,
//     duration: 100,
//     rent: true,
//     buy: false,
//   },
//   {
//     id: 2,
//     episodeNbr: 2,
//     title: 'الحلقة «22» من سلسلة حلقات الأمير تركي الفيصل في «الصندوق الأسود»',
//     watched: 10,
//     duration: 100,
//     rent: false,
//     buy: true,
//   },
//   {
//     id: 3,
//     episodeNbr: 3,
//     title: 'مسرحية عزوبي السالمية',
//     watched: 20,
//     duration: 100,
//     rent: true,
//     buy: false,
//   },
//   {
//     id: 4,
//     episodeNbr: 4,
//     title: 'قرقاشة ٢',
//     watched: 30,
//     duration: 100,
//     rent: false,
//     buy: true,
//   },
//   {
//     id: 5,
//     episodeNbr: 5,
//     title: 'سوشيال نيديا',
//     watched: 40,
//     duration: 100,
//     rent: false,
//     buy: false,
//   },
//   {
//     id: 6,
//     episodeNbr: 6,
//     title: ' أما بعد الموسم الثاني',
//     watched: 100,
//     duration: 100,
//     rent: true,
//     buy: false,
//   },
// ];
// const NEXT_EPISODE = {
//   id: 20,
//   number: 2,
//   title: 'الحلقة «22» من سلسلة حلقات الأمير تركي الفيصل في «الصندوق الأسود»',
//   description:
//     'الحلقة «22» من سلسلة حلقات الأمير تركي الفيصل في «الصندوق الأسود»',
//   img: '',
// };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_DETAILS: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_VIDEO_DETAILS_SUCCESS: {
      const { result } = action.payload.data.data;
      result.showSubscription = !(
        (result.paidArticle && result.subscribed)
        || !result.paidArticle
      );
      if (!result.showSubscription) {
        if (result.link && !result.link.startsWith('http')) {
          result.link = `https://player.vimeo.com/video/${result.link}`;
        } else {
          result.link = result.vimeoId && result.vimeoId !== ''
            ? `https://player.vimeo.com/video/${result.vimeoId}`
            : result.link;
        }
      }

      // result.qualities = VIDEO_QUALITIES;
      // result.subtitles = VIDEO_SUBTITLES;
      // result.episodes = SERIES_EPISODES;
      // result.nextEpisode = NEXT_EPISODE;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_VIDEO_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_VIDEO_DETAILS: {
      return initialState;
    }

    case GET_SHOW_DETAILS: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_SHOW_DETAILS_SUCCESS: {
      const { result } = action.payload.data.data;
      result.showSubscription = !(
        (result.paidArticle && result.subscribed)
        || !result.paidArticle
      );
      if (!result.showSubscription) {
        if (result.link && !result.link.startsWith('http')) {
          result.link = `https://player.vimeo.com/video/${result.link}`;
        } else {
          result.link = result.vimeoId && result.vimeoId !== ''
            ? `https://player.vimeo.com/video/${result.vimeoId}`
            : result.link;
        }
      }

      // result.qualities = VIDEO_QUALITIES;
      // result.subtitles = VIDEO_SUBTITLES;
      // result.episodes = SERIES_EPISODES;
      // result.nextEpisode = NEXT_EPISODE;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_SHOW_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_SHOW_DETAILS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
