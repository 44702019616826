import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isShowOrSeries } from '../../Utils/common';
import MovieCard from '../../Components/Molecules/MovieCard/MovieCard';
import PageWrapper from '../PageWrapper/PageWrapper.tsx';
import { getAwardsMoviesShows } from '../../Store/Actions/GetCastAndCrewActions';
import constants from '../../Utils/constants';
import LoadMore from '../../Components/Molecules/LoadMoreButton/LoadMoreButton';
import { clearVideoDetails } from '../../Store/Actions/VideoDetailsAction';
import './awards.scss';

let arr = [];
let limit = 5;

const Awards = () => {
  const dispatch = useDispatch();
  const idSelector = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { result, loading, error } = useSelector((state) => state.castAndCrew);
  const { language } = useSelector(
    (state) => state.authentication,
  );
  const [array, setArray] = useState([]);

  useEffect(() => {
    arr = [];
    setArray([]);
    for (
      let i = 0;
      i
      < Math.min(
        result?.data?.length,
        24,
      );
      i += 1
    ) {
      arr.push(result?.data[i]);
    }
    setArray([...arr]);
  }, [result]);

  const showLess = useCallback(() => {
    if (!result || !result?.data) {
      return;
    }
    let i = arr.length;
    limit += 24;
    if (array.length < result?.data?.length && ((result?.data?.length - array.length) > limit)) {
      for (
        i;
        i < limit;
        i += 1
      ) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
    if (result?.data?.length - array.length < limit) {
      for (
        i;
        i < result?.data?.length;
        i += 1
      ) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
  }, [result, array.length]);

  useEffect(() => {
    dispatch(getAwardsMoviesShows(idSelector.id));
  }, [dispatch, idSelector.id]);

  useEffect(() => {
    const idURL = new window.URLSearchParams(location?.search).get(
      'id',
    );

    if (!idURL) {
      dispatch(clearVideoDetails());
    } else if (idURL) {
      if (idURL.includes('s')) {
        console.warn('[Awards] scenario yet to be implemented');
      } else {
        console.warn('[Awards] scenario yet to be implemented');
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (error) {
      navigate(constants.screens.notFound, { replace: true });
    }
  }, [error, navigate]);

  return (
    <div className="root">
      <PageWrapper loading={loading}>
        <div className="searchHeader">
          <Row>
            <Col>
              <div className="d-flex">
                <div className={`awardsTitle ${language === 'ar' ? 'mr-5' : 'ml-5'} pb-3`}>{result?.pageTitle}</div>
              </div>
              <div className="searchContainer ml-5 mr-5">
                <Row>
                  {result
                    && array.map(
                      ({
                        title, medium, id, trailers, type, rating, paid,
                        video, isContinueWatching, comingSoon, containsFreeEpisodes,
                        date, duration, genres, numberOfSeasons,
                      }) => {
                        const isFree = isShowOrSeries(type || video?.type)
                          ? containsFreeEpisodes
                          : isContinueWatching
                            ? video?.paid === false
                            : paid === false;
                        return (
                          <Col
                            lg={3}
                            md={4}
                            sm={6}
                            className="mb-4"
                            key={`${type}${id}`}
                          >
                            <MovieCard
                              title={title}
                              trailer={trailers}
                              id={id}
                              type={type}
                              thumbnail={medium}
                              rating={rating}
                              isFree={isFree}
                              isComingSoon={comingSoon}
                              actors
                              date={date}
                              duration={duration}
                              numberOfSeasons={numberOfSeasons}
                              genres={genres}
                            />
                          </Col>
                        );
                      },
                    )}
                </Row>
              </div>
              {array.length !== result?.data?.length && (
                <div className="d-flex justify-content-center mt-2 mb-4">
                  <LoadMore
                    onClick={() => {
                      showLess();
                    }}
                    isRendered
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </PageWrapper>
    </div>
  );
};
export default Awards;
