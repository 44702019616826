/* eslint-disable react/destructuring-assignment */

import { HOVERED_CARD, ON_HOVER_CARD, CLEAR_HOVERED_CARD } from '../Constants/HoveredCardConstants';

const initialState = {
  id: null,
  duration: 0,
  isHovering: false,
};

const HoveredCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOVERED_CARD: {
      return {
        ...state,
        id: action.payload.id,
        duration: action.payload.duration,
        isHovering: false,
      };
    }
    case ON_HOVER_CARD: {
      return {
        ...state,
        id: action.payload.id,
        isHovering: true,
      };
    }
    case CLEAR_HOVERED_CARD: {
      return {
        ...state,
        id: null,
        duration: 0,
      };
    }
    default:
      return state;
  }
};

export default HoveredCardReducer;
