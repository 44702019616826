import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { SubmitInput } from '../../Atoms';
import MessageBox from '../../Atoms/MessageBox';
// import successIcon from '../../../Assets/Images/icons-success@3x.png';
// import failedIcon from '../../../Assets/Images/icons-failed@3x.png';
import OTP from '../../Atoms/otp/otp';
import styles from './index.module.scss';

const OTPForm = ({
  onSubmit,
  signUpError,
  loading,
  resendOtp,
  loadingOTP,
  coolDownOTP,
  errorOTP,
  email,
}) => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    otp: yup
      .string()
      .required(t('validation.required'))
      .test(
        'match',
        t('validation.required'),
        (val) => val && val.length === 4,
      ),
  });

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ otp: '' }}
        onSubmit={(props) => onSubmit(props)}
      >
        {({
          values, handleSubmit, setFieldValue, errors,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => handleSubmit(e)}
          >
            <div>
              <div className="justify-content-center align-items-center d-flex flex-column">
                {/* <img src={signUpError ? failedIcon : successIcon} alt="" width="106" height="109" className="mt-4 mb-4" /> */}
                <div className="mt-4">
                  <MessageBox
                    message={`${t(
                      'globals.optSentPleaseCheckYourEmail',
                    )} ${email}. ${t('globals.otpEnterOtp')}`}
                    isError={false}
                    isForgotPassword
                  />
                </div>
                {/* <div dir="ltr"> */}
                <OTP
                  name="otp"
                  id="otp"
                  handleChange={(val) => setFieldValue('otp', val)}
                  value={values.otp}
                />
                {/* </div> */}
              </div>
              {signUpError && (
              <div className="mt-4">
                <MessageBox message={signUpError} isError setFieldValue={setFieldValue} />
              </div>
              )}
              <div className="mt-4 d-flex mx-auto" style={{ width: '200px' }}>
                <div className={styles.otpbutton}>
                <SubmitInput
                  value={t('globals.verify')}
                  type="primary"
                  loading={loading}
                  size={24}
                  isDisabled={errors.otp || !values.otp}
                />
                </div>

              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center pt-5">
        {errorOTP && <MessageBox message={errorOTP} isError />}
        <Button
          onClick={() => resendOtp()}
          // className={styles.resendOtpBtn}
          disabled={loadingOTP || coolDownOTP}
        >
          {t('globals.resendOtp')}
          {' '}
          {coolDownOTP ? `(${coolDownOTP})` : ''}
        </Button>
      </div>
    </>
  );
};

OTPForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  signUpError: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loadingOTP: PropTypes.bool.isRequired,
  coolDownOTP: PropTypes.number.isRequired,
  errorOTP: PropTypes.string,
  email: PropTypes.string.isRequired,
};

OTPForm.defaultProps = {
  errorOTP: null,
  signUpError: null
}

export default OTPForm;
