/* eslint-disable  react-hooks/exhaustive-deps */
import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TimeSeekSlider } from 'react-time-seek-slider';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import VolumeTrigger from '../../Atoms/VolumeTrigger/VolumeTrigger';
import { setRadioOn } from '../../../Store/Actions/StreamActions';
// import mux from 'mux-embed';

import PlayerControlButton from '../../Molecules/PlayerControlButton/PlayerControlButton';
import Play from '../../../Assets/Images/VideoControls/Play.svg';
import Pause from '../../../Assets/Images/VideoControls/Pause.svg';
import exit from '../../../Assets/Images/exit.svg';
import config from '../../../Utils/config';
import firebase from '../../../Utils/firebase';
import styles from './radioTab.module.scss';

const initialState = {
  playing: true,
  urlToPlay: '',
  buffering: false,
  volume: 1,
  muted: true,
};

const RadioTab = () => {
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [status, setStatus] = useState(initialState);
  const settings = useSelector((state) => state.videoSettings.result);
  const [audioToUse, setAudioToUse] = useState(null);
  const { result: channels } = useSelector((state) => state.liveChannels);
  useEffect(() => {
    if (channels?.length > 0) {
      const audioChannel = channels.find(
        (channel) => channel?.type === 'audio',
      );
      setAudioToUse(audioChannel);
    }
  }, [channels]);

  useEffect(() => {
    const audio = document.getElementsByTagName('video')[0];
    if (audio === audioRef.current && audioToUse) {
      // const initTime = mux.utils.now();
      // mux.monitor(audio, {
      //   debug: false,
      //   data: {
      //     env_key: config.muxKey, // required
      //     // Metadata fields
      //     player_name: 'Website Audio Player',
      //     player_init_time: initTime,
      //     video_title: audioToUse?.title,
      //   },
      // });
    }
  }, [audioRef, audioToUse]);

  useEffect(() => {
    if (config.env !== config.environments.dev) {
      firebase.analytics().logEvent('screen_view', {
        firebase_screen: 'liveStreamAudio ',
      });
    }
  }, []);

  const onVolumeClickHandler = useCallback(
    (volume = null) => {
      try {
        let newVolume = volume;
        if (newVolume === null) {
          newVolume = status.volume ? 0 : 1;
        }

        setStatus((prevState) => {
          audioRef.current.volume = +newVolume;
          return {
            ...prevState,
            volume: +newVolume,
          };
        });
        /* eslint-disable-next-line no-empty */
      } catch {}
    },
    [status.volume],
  );

  const onPlayHandler = () => {
    setStatus((prevState) => {
      if (prevState.playing) audioRef.current.pause();
      if (!prevState.playing) audioRef.current.play();

      return {
        ...prevState,
        playing: !prevState.playing,
      };
    });
  };

  const onStopRadio = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      playing: false,
    }));
    dispatch(setRadioOn(false));
  }, [dispatch]);

  useEffect(() => {
    if (audioToUse?.url) {
      setStatus((prevState) => ({
        ...prevState,
        urlToPlay: audioToUse?.url,
      }));
    }
  }, [settings, audioToUse]);

  return (
    <div className={styles.radioStream} dir="ltr">
      <div className={styles.videoPlayerControlsContainer}>
        <div className={styles.leftControlsContainer}>
          <PlayerControlButton
            onClick={onPlayHandler}
            icon={status.playing ? Pause : Play}
          />
          <VolumeTrigger
            onClick={onVolumeClickHandler}
            containerClasses={styles.volumeButton}
            volume={status.volume}
          />
        </div>
      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.trackTitle}>{audioToUse?.title}</div>
        <TimeSeekSlider
          max={1}
          currentTime={1}
          secondsPrefix="0:"
          onSeeking={() => null}
        />
      </div>

      <div className={styles.rightContainer}>
        <span className={styles.liveIcon}>{t('globals.radioLiveIcon')}</span>
        <Button className={styles.stopRadioBtn} onClick={onStopRadio}>
          <img
            className={styles.exitImg}
            src={exit}
            alt="stop radio"
            width="10"
            height="10"
          />
        </Button>
      </div>
    </div>
  );
};

RadioTab.prototype = {};

export default RadioTab;
