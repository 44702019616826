import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap';
import Muted from '@Assets/Images/nouveau/volume-low.svg';
import Volume from '@Assets/Images/nouveau/volume-high.svg';
import Replay from '@Assets/Images/replay.svg';
import styles from './MuteButton.module.scss';
import { IMuteButtonProps } from './MuteButton.types';
import classNames from 'classnames';

const getButtonIcon = (status: string) => {
    switch(status) {
        case 'replay':
            return Replay;
        case 'muted':
            return Muted;
        case 'unmuted':
            return Volume;
        default:
            return Volume;
    }
}

const MuteButton: React.FC<IMuteButtonProps> = ({ shouldRender, status, onClick, className: overrideClassName }) => {
    const buttonIcon = getButtonIcon(status);
    if (!shouldRender) return <Fragment />;
    return (
        <Button
            variant='background'
            className={classNames(styles.muteButton, overrideClassName)}
            onClick={onClick}
        >
            <img src={buttonIcon} className={styles.mediaButtonImg} alt="volume-button" />
        </Button>)
};

export default MuteButton