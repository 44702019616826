import React, {
  Fragment,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
// import { isMobile } from 'react-device-detect';
import { isIpad as getIsIpad } from '../../../Utils/deviceDetect';
import Loader from '../Loader/Loader';
import { getLiveChannels } from '../../../Store/Actions/LiveChannelsActions';
import constants from '../../../Utils/constants';
import SubLiveButton from './SubLiveButton';
import { handleLiveChannelLogic } from '../../../Utils/common';
import styles from './LiveStreamButton.module.scss';
import LiveIcon from '../../../Assets/Images/liveIconWhite.svg';
import classNames from 'classnames';
// import {setSelectedEticket} from '../../../Store/Actions/VideoEticketActions'

const LiveStreamButton = ({
  setShowBottomSheet,
  videoStream,
  // audioStream,
  // setAudioStream,
  setVideoStream,
  navBarPosition,
  isMobile,
}) => {
  const navigate = useNavigate(); // Replacing useHistory
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const videoStreamRef = useRef();

  const { result } = useSelector((state) => state.videoSettings);
  const { language, userAuthType } = useSelector((state) => state.authentication);
  const [isLoading, setIsLoading] = useState(false);
  const menuDropdownRef = useRef();
  const isIpad = getIsIpad();

  const onLiveStreamClick = useCallback((e, element) => {
    e.stopPropagation();
    // if(element?.package){
    //   dispatch(setSelectedEticket({...element,action:'e-ticket'}));
    // }
    return handleLiveChannelLogic(element, userAuthType, navigate, constants.newScreens.live(element?._id).as);
  }, [navigate, userAuthType]); // Replaced history with navigate

  // const onRadioStreamClick = useCallback((e) => {
  //   e.stopPropagation();
  //   dispatch(setRadioOn(true));
  // }, [dispatch]);

  const handleHoverOut = () => {
    if (videoStreamRef.current?.showSubHandler) videoStreamRef.current?.showSubHandler(false);
  };

  const handleBottomSheet = useCallback(() => {
    setShowBottomSheet(true);
  }, [setShowBottomSheet]);

  const liveStream = useMemo(() => result?.header?.liveStream, [result]);
  const handleSingleStreamClick = useCallback((element) => (e) => {
    if (element?.type === 'video') onLiveStreamClick(e, element);
    // if (element?.type === 'audio') onRadioStreamClick(e);
  }, [onLiveStreamClick]);

  const onLiveClick = (e) => {
      setVideoStream([]);
      setIsLoading(true);
      dispatch(getLiveChannels()).then((data) => {
        const streams = data?.payload?.data?.data?.result;

        if (streams?.length === 1) {
          if (streams[0]?.type === 'video') onLiveStreamClick(e, streams[0]);
          // if (streams[0]?.type === 'audio') onRadioStreamClick(e);
          setIsLoading(false);
          if (menuDropdownRef?.current?.classList) {
            menuDropdownRef.current.classList.remove('show');
          }

          return;
        }
        const videoStreams = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < streams?.length; i++) {
          // if (streams[i]?.type === 'audio') {
          //   setAudioStream(liveStream.channels[i]);
          // }
          if (streams[i]?.type === 'video') {
            videoStreams.push(streams[i]);
          }
        }
        setVideoStream(videoStreams);

        // const audioStreamsPresent = streams.some((stream) => stream?.type === 'audio');

        if (videoStreams.length === 1) {
          onLiveStreamClick(e, videoStreams[0]);
        }

        setIsLoading(false);
      });
  };

  const dropdownClassNames = useMemo(() => {
    const classNames = [];

    if (language === 'en') {
      classNames.push(styles.streamDropEng);
      if (isIpad) {
        classNames.push(styles.streamDropEngIpadView);
      }
    }

    if (navBarPosition === 'start') classNames.push(styles.navBarStart);

    if (language === 'ar') {
      classNames.push(styles.streamDrop);
      if (isIpad) {
        classNames.push(styles.streamDropIpadView);
      }
    }

    return classNames;
  }, [isIpad, language, navBarPosition]);
  // const hasAudioStream = useMemo(() => liveStream?.channels?.some((stream) => stream?.type === 'audio'), [liveStream]);

  if (!liveStream || liveStream?.live === false) {
    return <></>;
  }
  return (
    <Dropdown className={dropdownClassNames.join(' ')}>
      {isLoading && (
        <div className={classNames(styles.loader, language === 'ar' ? styles.loaderAr : styles.loaderEn)}>
          <Loader color="#fff" />
        </div>
      )}
      <Dropdown.Toggle 
        aria-label="livestreamdropdown"
        className={`${styles.streamDropdownBasic}`} 
        style={language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }}
      >
        {liveStream && (
          <div className={styles.liveContainer} onClick={onLiveClick}>
            <img src={LiveIcon} alt='liveIcon' className={styles.liveIcon} />
            <div className="dropdown-text">{t('globals.liveNow')}</div>
          </div>
        )}
      </Dropdown.Toggle>
      {!isLoading && (
        <>
          <Dropdown.Menu
            className={styles.streamDropdownMenuBasic}
            ref={menuDropdownRef}
          >
            {videoStream?.length >= 1 && (
              <>
                {videoStream?.length === 1 ? (
                  <Dropdown.Item
                    className={styles.itemMenu}
                    onClick={handleSingleStreamClick(videoStream[0])}
                  >
                    {t('globals.liveStream')}
                  </Dropdown.Item>
                ) : (
                  <>
                    {!isMobile ? (
                      <SubLiveButton
                        ref={videoStreamRef}
                        subChannels={videoStream}
                        handleSingleStreamClick={handleSingleStreamClick}
                      />
                    ) : (
                      <Dropdown.Item
                        className={styles.itemMenu}
                        onClick={handleBottomSheet}
                      >
                        {t('globals.liveStream')}
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </>
            )}

            {/* {audioStream && Object.keys(audioStream).length > 0 && (
              <>
                <div className={styles.divider} />
                <Dropdown.Item
                  className={styles.itemMenu}
                  onClick={onRadioStreamClick}
                  onMouseEnter={handleHoverOut}
                >
                  {t('globals.radio')}
                </Dropdown.Item>
              </>
            )} */}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
};

export default React.memo(LiveStreamButton);