import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;
    if (hasError) {
      return <h6 style={{ color: 'white', textAlign: 'center' }}>{t('errors.somethingWentWrong')}</h6>;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ErrorBoundary);
