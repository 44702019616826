import { SAVE_MESSAGE, CLEAR_MESSAGE } from '../Constants/MessageConstants';

const initialState = {
  message: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case CLEAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
