import VideoPlayer from '@Atoms/VideoPlayer';
import { useAppSelector } from '@Store/hooks';
import { hoveredIdSelector } from '@Utils/common';
import classNames from 'classnames';
import { forwardRef, Fragment, SyntheticEvent } from 'react'
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Volume from '@Assets/Images/VideoControls/Volume.svg';
import Muted from '@Assets/Images/VideoControls/Muted.svg';
import { IMediaCardPlayerProps } from './MediaCardPlayer.types';
import ReactPlayer from 'react-player';
import styles from './MediaCardPlayer.module.scss';

const MediaCardPlayer = forwardRef<ReactPlayer | undefined, IMediaCardPlayerProps>((
    {
        playerStatus,
        setPlayerStatus,
        mediaId,
        url,
        playerClassName,
        muteButtonClassName,
    },
    playerRef
) => {
    const hoveredMovie = useAppSelector((state) => hoveredIdSelector(state, mediaId));

    if (playerStatus.hasEnded || !playerStatus.showPlayer || isMobile) {
        return <Fragment />;
    }


    const onPlayerStart = () => {
        if (playerRef && hoveredMovie) {
            try {
                //@ts-expect-error issue with typing forwarded ref
                playerRef.current.seekTo(hoveredMovie?.duration || 0, 'seconds');
            } catch { /* empty */ }
        }
    }

    const onBufferEnd = () => {
        setPlayerStatus((prev) => ({
            ...prev,
            loaded: true,
        }));
    }

    const onPlayerEnd = () => {
        setPlayerStatus((prev) => ({
            ...prev,
            hasEnded: true,
        }));
    }


    const toggleMuteButton = (e: SyntheticEvent) => {
        e.stopPropagation();
        setPlayerStatus((prev) => ({
            ...prev,
            muted: !prev.muted,
        }));
    }

    return (
        <Fragment>
            <Button variant='none' className={classNames(styles.muteButton, muteButtonClassName)} onClick={toggleMuteButton}>
                <LazyLoadImage src={playerStatus.muted ? Muted : Volume} alt="mute-button" width="20px" height="20px" />
            </Button>
            <div className={classNames(styles.player, !playerStatus.loaded && 'd-none', playerClassName)}>
                <VideoPlayer
                    //@ts-expect-error legacy coode
                    url={url}
                    onBufferEnd={onBufferEnd}
                    onStart={onPlayerStart}
                    onEnded={onPlayerEnd}
                    muted={playerStatus.muted}
                    volume={1}
                    ref={playerRef}
                />
            </div>
        </Fragment>
    )
});

export default MediaCardPlayer;