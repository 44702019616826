import { Suspense } from 'react';
import Cookie from './Components/Atoms/Cookie/Cookie';
import AppProvider from '@Contexts/Provider';
import { RouterProvider } from 'react-router-dom';
import LanguageWrapper from '@Contexts/LanguageWrapper';
import ProlongedAuthenticationWrapper from '@Contexts/ProlongedAuthenticationWrapper';
import router from './Routers/RootRouter';
import { ReactNotifications } from 'react-notifications-component';
import useOneSignal from '@Hooks/useOneSignal'
import './Utils/firebase';
import './App.scss';

import 'swiper/scss';
import 'swiper/scss/a11y';
import 'swiper/scss/navigation';
import 'swiper/scss/scrollbar';
import 'swiper/scss/pagination';



function App() {
  useOneSignal();
  return (
    <AppProvider>
      <Suspense fallback={null}>
        <LanguageWrapper>
          <ProlongedAuthenticationWrapper>
            <ReactNotifications />
            <RouterProvider router={router} />
            <Cookie />
          </ProlongedAuthenticationWrapper>
        </LanguageWrapper>
      </Suspense>
    </AppProvider>
  );
}

export default App;
