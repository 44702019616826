import {
  CLEAR_VIDEO_DETAILS,
  GET_VIDEO_DETAILS,
  GET_SHOW_DETAILS,
  CLEAR_SHOW_DETAILS,
} from '../Constants/VideoDetailsConstants';

export const getVideoDetails = (id, type) => {
  const typeFlag = type && (type === 'show' || type === 'series') ? 's' : '';
  return {
    type: GET_VIDEO_DETAILS,
    payload: {
      request: {
        url: `api/video/detailsElastic/${typeFlag}${id}`,
        method: 'get',
        authenticated: true,
      },
    },
  };
};

export const clearVideoDetails = () => ({
  type: CLEAR_VIDEO_DETAILS,
  payload: null,
});

export const getShowDetails = (id) => ({
  type: GET_SHOW_DETAILS,
  payload: {
    request: {
      url: `api/video/detailsElastic/${id}`,
      method: 'get',
      authenticated: true,
    },
  },
});

export const clearShowDetails = () => ({
  type: CLEAR_SHOW_DETAILS,
  payload: null,
});
