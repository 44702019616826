import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../Utils/constants';
import getPurchasesList from '../../Store/Actions/MyPurchasesActions';
import Genre from '../../Components/Organisms/Genre/Genre';
import ErrorComponent from '../../HOC/ErrorComponent';
import NoData from '../../Components/Molecules/NoData/NoData';
import styles from './MyPurchases.module.scss';

const MyPurchases = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result, loading, error } = useSelector((state) => state.myPurchases);

  const handleDataShape = useMemo(() => result?.map((el) => ({
    ...el,
    medium: el.image,
    type: el?.type?.toLowerCase(),
  })), [result]);

  useEffect(() => {
    dispatch(getPurchasesList());
  }, [dispatch]);

  if (error) return <ErrorComponent error={error} pageType={constants.screens.myPurchases} />;
  if (result?.length === 0) return (
    <div className={styles.root}>
      <NoData source="purchases" addMarginTop />
    </div>
  );
  return (
    <div className={styles.root}>
      <Genre
        loading={loading}
        pageTitle={t('globals.myPurchases')}
        array={handleDataShape}
        result={result}
        removeLoadMore
      />
    </div>
  );
};

export default MyPurchases;
