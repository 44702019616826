const POST_SUBSCRIPTION = 'POST_SUBSCRIPTION';
const POST_SUBSCRIPTION_FAIL = 'POST_SUBSCRIPTION_FAIL';
const POST_SUBSCRIPTION_SUCCESS = 'POST_SUBSCRIPTION_SUCCESS';

const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

const CLEAR_SUBSCRIPTION = 'CLEAR_SUBSCRIPTION';

export {
  CLEAR_SUBSCRIPTION,
  POST_SUBSCRIPTION,
  POST_SUBSCRIPTION_FAIL,
  POST_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
};
