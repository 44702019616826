import { useTranslation } from 'react-i18next';
import styles from './InformationGroup.module.scss';
import { Genre } from '@Utils/commonTypes';

interface IInformationGroup {
    rating: string;
    releaseDate?: string;
    numberOfSeasons?: number;
    genres: Array<Genre>;
}

const InformationGroup: React.FC<IInformationGroup> = ({ rating, releaseDate, numberOfSeasons, genres }) => {
    const { t } = useTranslation();
    const releaseYear = releaseDate ? parseInt(releaseDate, 10) : '';
    const stringifiedGenres = genres.map((genre) => genre.name).join(', ');
    const isSingular = numberOfSeasons === 1;
    return (
        <div className={styles.informationGroup}>
            {rating.length >= 1 && <span dir='auto' className={styles.rating}>{rating}</span>}
            <span dir='ltr' className={styles.releaseDate}>{releaseYear}</span>
            {numberOfSeasons && (
                <span className={styles.seasons}>
                    <span dir='ltr'>{numberOfSeasons}</span>
                    <span>{t(isSingular ? 'details.season' : 'details.alSeason')}</span>
                </span>
            )}
            <span className={styles.genres}>{stringifiedGenres}</span>
        </div>
    )
}

export default InformationGroup