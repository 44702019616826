import React from 'react';
import PropTypes from 'prop-types';
import Approve from '../../../Assets/Images/Icons/Approve';
import Close from '../../../Assets/Images/Icons/Close';
import styles from './CustomAlert.module.scss';

const CustomAlert = ({ message, type, title }) => {
  const Icon = type === 'danger' ? <Close /> : <Approve />;

  return (
    <div className={styles.action}>
      {title && (
      <div>
        {Icon}
        {title}
      </div>
      )}
      <span>
        {!title && Icon}
        {message}
      </span>
    </div>
  );
};

CustomAlert.defaultProps = {
  title: undefined,
};

CustomAlert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default CustomAlert;
