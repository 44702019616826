const GET_PURCHASES = 'GET_PURCHASES';
const GET_PURCHASES_FAIL = 'GET_PURCHASES_FAIL';
const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
const CLEAR_PURCHASES = 'CLEAR_PURCHASES';

export {
  GET_PURCHASES,
  GET_PURCHASES_FAIL,
  GET_PURCHASES_SUCCESS,
  CLEAR_PURCHASES,
};
