import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";
import classNames from "classnames";
import { Link, useNavigate, useLocation } from "react-router-dom";
import constants from "../../../Utils/constants";
import { InputField, SubmitInput, TextSeparator } from "../../Atoms";
import SocialMediaLogin from "../../Molecules/SocialMediaLogin";
import config from "../../../Utils/config";
import MessageBox from "../../Atoms/MessageBox";
import styles from "./index.module.scss";
import Captcha from "@Atoms/Captcha/Captcha";

const validationSchema = yup.object({
  username: yup
    .string()
    .required("validation.required")
    .email("validation.invalidEmail")
    .trim(/ +/),
  password: yup.string().required("validation.required"),
});

const LoginForm = ({
  onSubmit,
  loginError,
  onGoogleLogin,
  onGoogleFailure,
  onFacebookLogin,
  onFacebookFailure,
  loading,
  redirectUrl,
  onAppleLogin,
  onCaptchaChange,
  captchaValidated,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ username: "", password: "" }}
        onSubmit={(props) => onSubmit(props)}
      >
        {({ handleSubmit, handleChange, errors, values }) => (
          <Form noValidate onSubmit={(e) => handleSubmit(e)}>
            <div>
              <SocialMediaLogin
                facebookAppId={config.facebookAppId}
                onGoogleLogin={onGoogleLogin}
                googleAppId={config.googleAppId}
                onGoogleFailure={onGoogleFailure}
                onFacebookLogin={onFacebookLogin}
                onFacebookFailure={onFacebookFailure}
                onAppleLogin={onAppleLogin}
                appleAppId={config.appleAppId}
                appleAppRedirectUri={config.appleAppRedirectUri}
                ColNumber={3}
              />
              <div className="pt-4 pb-4">
                <TextSeparator text={t("globals.or")} />
              </div>
              <div>
                <InputField
                  name="username"
                  label={t("globals.emailAddress")}
                  type="email"
                  placeholder={t("globals.emailAddress")}
                  onChange={(e) => handleChange(e)}
                  alertText={errors.username}
                  error={!!errors.username}
                  autocomplete="off"
                />
              </div>
              <div>
                <InputField
                  name="password"
                  label={t("globals.password")}
                  type="password"
                  placeholder={t("globals.password")}
                  onChange={(e) => handleChange(e)}
                  alertText={errors.password}
                  error={!!errors.password}
                />
              </div>
              <div className="d-flex flex-row-reverse">
                <Link
                  className={classNames(styles.infoLink, "clickable")}
                  to={{
                    pathname: constants.screens.forgotPassword,
                    search: `?email=${values.username}`,
                  }}
                >
                  {t("globals.forgotPassword")}
                </Link>
              </div>
              {loginError && <MessageBox message={loginError} isError />}
              <div className={styles.captchaContainer}>
                <Captcha onCaptchaChange={onCaptchaChange} />
              </div>
              <div className="d-flex justify-content-around">
                <SubmitInput
                  isDisabled={!captchaValidated}
                  value={t("globals.submitLogin")}
                  type="SignUp"
                  loading={loading}
                  variant="primary"
                  size={24}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className={`${styles.dontHaveAcc} text-center`}>
        <span
          role="button"
          onClick={() =>
            navigate(`${constants.screens.signUp}${location.search}`, {
              state: { redirect_url: redirectUrl },
            })
          }
          className={`${styles.link} label bold-text clickable`}
        >
          {t("globals.registerNow")}
        </span>{" "}
        <span className="label">
          {`${t("globals.dontHaveAccountQuestion")} `}
        </span>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loginError: PropTypes.string.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
  onGoogleFailure: PropTypes.func.isRequired,
  onFacebookLogin: PropTypes.func.isRequired,
  onFacebookFailure: PropTypes.func.isRequired,
  onAppleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string,
};

LoginForm.defaultProps = {
  redirectUrl: "",
};

export default LoginForm;
