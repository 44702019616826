import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./Reducers";
import httpMiddleware from "../Utils/Http";
import { configureStore } from "@reduxjs/toolkit";
import config from "@Utils/config";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
// import { logger } from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "authentication",
    "videoSubscription",
    "subscriptionSource",
    "ShowPaymentAlert",
  ],
};

const middlewares = [httpMiddleware];
if (config.isLocalEnv) {
  // middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, appReducer as any);
const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

const persistor = persistStore(store);
export { persistor, store };
