import { GET_COMING_SOON_LIST } from '../Constants/ComingSoonConstants';

const getComingSoonList = () => ({
  type: GET_COMING_SOON_LIST,
  payload: {
    request: {
      url: '/api/videoLanding/comingSoon?type=comingSoon',
      method: 'get',
    },
  },
});

export default getComingSoonList;
