import { useAppSelector } from "@Store/hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function useFlipPageBasedOnLanguage() {
  const { language } = useAppSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    const root = document.getElementById("root");
    if (!root) return;
    switch (language) {
      case "en":
        //@ts-ignore
        root.dir = i18n.changeLanguage("en").then(() => {
          root.dir = i18n.dir("en");
          root.style.direction = "ltr";
          document.body.dir = "ltr"; // To always show the scrollbar on the left
        });
        break;

      case "ar":
      default:
        //@ts-ignore
        root.dir = i18n.changeLanguage("ar").then(() => {
          root.dir = i18n.dir("ar");
          root.style.direction = "rtl";
          document.body.dir = "ltr"; // To always show the scrollbar on the left
        });
        break;
    }
  }, [language, i18n, dispatch]);
}

export default useFlipPageBasedOnLanguage;
