import { PurchasableMedia } from '@Utils/commonTypes';
import { CLEAR_PAYMENT_MODAL_DATA, SET_PAYMENT_MODAL_DATA, TOGGLE_PAYMENT_MODAL } from '../Constants/PaymentModalConstants';


export interface PaymentModalState {
    purchaseType: PurchasableMedia | null;
    purchaseId: string | null;
    shouldRender?: boolean;
}

interface Action {
    type: string;
    payload?: {
        purchaseType?: PurchasableMedia;
        purchaseId?: string;
        override?: boolean;
        shouldRender?: boolean;
    };
}

const initialState: PaymentModalState = {
    purchaseType: null,
    purchaseId: null,
    shouldRender: false,
};

const paymentModalReducer = (state: PaymentModalState = initialState, action: Action): PaymentModalState => {
    switch (action.type) {
        case SET_PAYMENT_MODAL_DATA:
            return {
                ...state,
                purchaseType: action.payload?.purchaseType || null,
                purchaseId: action.payload?.purchaseId || null,
            };

        case TOGGLE_PAYMENT_MODAL:
            return {
                ...state,
                shouldRender: action.payload?.override || !state.shouldRender,
            }
        case CLEAR_PAYMENT_MODAL_DATA:
            return initialState;
        default:
            return state;
    }
};

export default paymentModalReducer;