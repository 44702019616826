/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  Modal,
  ModalBody,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ProfileForm from '../../Organisms/ProfileForm/ProfileForm';

import arrowRight from '../../../Assets/Images/back/icons-arrows-arrow-right.svg';

const EditProfileModal = ({
  isEditFormVisible,
  hasTouched,
  setIsEditFormVisible,
  setHasTouched,
  canChangePassword,
  setIsFormVisible,
}) => {
  const { t } = useTranslation();

  const {
    profile, loading, error, success,
  } = useSelector(
    (state) => state.user,
  );
  const { language } = useSelector((state) => state.authentication);

  return (
    <Modal
      show={isEditFormVisible}
      backdrop
      onHide={() => {
        setIsEditFormVisible(false);
        setHasTouched(true);
      }}
      dialogClassName="modalEditProfile"
    >
      <ModalBody
        className={
      language === 'ar'
        ? 'modalBodyEditProfile'
        : 'modalBodyEng'
    }
      >
        <div className="d-flex titleContainer">
          <div
            onClick={() => {
              setIsEditFormVisible(false);
              setHasTouched(true);
            }}
            className={
          language === 'ar' ? 'arrowRight' : 'arrowLeftEng'
        }
          >
            <img src={arrowRight} alt="" className="d-md-block" />
          </div>
          <div className="d-flex justify-content-center">
            <span
              className={
            language === 'ar'
              ? 'myAccountTitle'
              : 'myAccountTitleEn'
          }
            >
              {t('profiles.editProfile')}
            </span>
          </div>
        </div>
        <ProfileForm
          language={language}
          profile={profile}
          loading={loading}
          success={success}
          error={error}
          setIsFormVisible={setIsFormVisible}
          canChangePassword={canChangePassword}
          hasTouched={hasTouched}
          setHasTouched={setHasTouched}
        />
      </ModalBody>
    </Modal>
  );
};

export default EditProfileModal;
