import Login from '@Pages/Login/Login';
import constants from '@Utils/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

function LoginRouter() {
    const { isSignedIn } = useSelector((state) => state.authentication);
    const location = useLocation();
    if (isSignedIn) {
        return <Navigate to={{ pathname: constants.newScreens.profiles.view, search: location.search }} state={location.state} />;
    }
    return <Login />;
}

export default LoginRouter