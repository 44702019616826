import React, { Fragment } from 'react';
import ClickableItem from '../ClickableItem/ClickableItem';
import Back from '../../../Assets/Images/VideoControls/Back.svg';
import styles from './PlayerSeason.module.scss';

const PlayerSeason = ({ onClick, title, showSeparator, language }) => (
  <Fragment>
    <ClickableItem onClick={onClick} classes={styles.seasonRow} style={language === 'ar' ? null : { direction: 'ltr' }}>
      <div className={language === 'ar' ? styles.back : styles.backEng}>
        <img src={Back} alt="Go Back" className={styles.rotate180} />
      </div>
      <h5 className={styles.title}>{title}</h5>
    </ClickableItem>
    {showSeparator && <div className={styles.separator} />}
  </Fragment>
);

export default PlayerSeason;
