import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useQuery from '../../Hooks/useQuery';
import Loader from '../../Components/Atoms/Loader/Loader';
import constants from '../../Utils/constants';
import styles from './Profiles.module.scss';
import AddIcon from '@Assets/Images/nouveau/plus.svg';
import { getUserPerProfile } from '../../Store/Actions/UserProfilesActions';
import { saveProfile } from '../../Store/Actions/AuthProfileAction';
import { getAvatars } from '../../Store/Actions/AvatarActions';
import classNames from 'classnames';
import queryString from 'query-string';

const Profiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const redirectUrl = useQuery()?.get('redirect_url');
  const checkPay = useQuery()?.get('check');
  const { result } = useSelector((state) => state.ProfilesPerUser);
  const avatars = useSelector((state) => state.avatar.result);
  const { isSignedIn, profile } = useSelector((state) => state.authentication);
  const isPayment = location?.state?.payment;
  const requireProfile = location?.state?.requireProfile ?? false;
  const canRenderEditButton = !isPayment && requireProfile === false;

  useEffect(() => {
    if (!avatars) {
      dispatch(getAvatars());
    }
  }, [avatars, dispatch]);

  useEffect(() => {
    dispatch(getUserPerProfile());
  }, [dispatch]);

  const fallback = useCallback(() => {
    if (isSignedIn && !profile) {
      navigate(constants.newScreens.profiles.view, { unstable_viewTransition: true });
    }
  }, [isSignedIn, navigate, profile]);

  useEffect(() => {
    window.addEventListener('popstate', fallback);

    return () => {
      window.removeEventListener('popstate', fallback);
    };
  }, [fallback]);

  function getDestination(destination) {
    if (!destination || destination === 'null') {
      return constants.screens.browse;
    }
    return destination;
  }

  const onProfileClick = useCallback((profileId) => () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { redirect_url, ...locationState } = location.state ?? { redirect_url: null, requireProfile: null };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { redirect_search, redirect_url: _, ...locationSearch } = queryString.parse(location.search);
    const updatedRedirectUrl = redirectUrl?.split('?redirect_url=').at(1);
    const searchParams = queryString.parse(location.search);

    let destinationSearchParams = {};
    if (searchParams.redirect_search !== undefined) {
      destinationSearchParams = queryString.parse(searchParams.redirect_search);
    }
    const destination = getDestination(redirectUrl);

    dispatch(saveProfile(profileId));

    let finalSearchParams = queryString.stringify({ ...locationSearch });
    if (destination !== constants.screens.browse) {
      finalSearchParams = queryString.stringify({ ...locationSearch, ...destinationSearchParams }, { skipEmptyString: true, skipNull: true });
    }

    navigate({ pathname: destination, search: finalSearchParams }, {
      state: {
        check: checkPay || '',
        redirect_url: updatedRedirectUrl, ...locationState,
        requireProfile: true,
      }, unstable_viewTransition: true
    });
  }, [location.state, location.search, redirectUrl, dispatch, navigate, checkPay]);

  return (
    <div className={styles.root}>
      <div className={styles.headingGroup}>
        <span dir='auto' className={styles.profilesTitle}>
          {t('globals.whoIsWatching')}
        </span>
        <span dir='auto' className={styles.addText}>
          {t('globals.youCanADD')}
        </span>
      </div>
      {result ? (
        <div className={styles.profilesGroup}>
          {result?.data?.map((profile) => (
            <div key={profile.id} className={styles.profileGroup}>
              <div className={styles.profileInfo}>
                <span dir='auto' className={styles.profileName} onClick={onProfileClick(profile.id)}>
                  {profile.name}
                </span>
                <div className={classNames(styles.rectangle, profile.avatar !== '' ? styles.hiddenBorder : '')}
                  style={{ backgroundImage: `url(${profile.avatar})` }}
                  onClick={onProfileClick(profile.id)}
                />
              </div>
              {canRenderEditButton && (
                <Button
                  as={Link}
                  unstable_viewTransition
                  dir='auto'
                  to={constants.newScreens.profiles.edit(profile.id).as}
                  className={styles.editButton}
                  variant='secondary'>
                  <span>{t('profiles.editProfileUser')}</span>
                </Button>
              )}
            </div>
          ))}
          {result?.profilesCount < 5 && !isPayment && requireProfile === false && (
            <Link unstable_viewTransition to={constants.newScreens.profiles.add} className={styles.AddRectangle}>
              <span dir='auto' className={styles.addProfileText}>
                {t('profiles.addProfile')}
              </span>
              <div className={styles.rectangle}>
                <img className={styles.addIcon} src={AddIcon} alt="" />
              </div>
            </Link>
          )}
        </div>
      ) : (
        <div className="loader mt-5 d-flex justify-content-center align-items-center w-100">
          <Loader size={34} />
        </div>
      )}
    </div>
  );
};

export default Profiles;
