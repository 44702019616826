import {
  CLEAR_SELECTED_SUBTITLE,
  SET_SELECTED_SUBTITLE,
} from '../Constants/SelectedSubtitleConstants';

const setSelectedSubtitle = (sub) => ({
  type: SET_SELECTED_SUBTITLE,
  payload: sub,
});

const clearSelectedSubtitle = () => ({
  type: CLEAR_SELECTED_SUBTITLE,
  payload: null,
});

export { clearSelectedSubtitle, setSelectedSubtitle };
