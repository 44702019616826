import classNames from 'classnames'
import { Dropdown, DropdownMenu, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import styles from './SeasonSelector.module.scss';
import { PurchasableMedia, Season } from '@Utils/commonTypes';
import { Fragment } from 'react/jsx-runtime';
import { useDispatch } from 'react-redux';
import { setPaymentModalData, togglePaymentModal } from '@Store/Actions/PaymentModalActions';
interface ITabBarProps {
    currentSeason: Season | undefined;
    seasonsArray: Array<Season>;
    setCurrentSeason: (season: Season) => void
}

const SeasonSelector: React.FC<ITabBarProps> = ({ seasonsArray, currentSeason, setCurrentSeason }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const onSubscriptionButtonClick = () => {
        dispatch(togglePaymentModal(true));
        dispatch(setPaymentModalData({ purchaseType: PurchasableMedia.Season, purchaseId: `${currentSeason?.id}` }));
    }

    function onSelectSeason(selectedSeason: Season) {
        if (currentSeason?.id === selectedSeason.id) {
            return;
        }
        setCurrentSeason(selectedSeason);
    }

    return (
        <div className={styles.tabBarButtonGroup}>
            <Dropdown>
                <Dropdown.Toggle
                    size='lg'
                    dir='auto'
                    variant="secondary"
                    className={classNames(styles.dropdownToggle, { [styles.noArrow]: seasonsArray.length <= 1 })}
                    id="current-season-dropdown"
                    disabled={seasonsArray.length <= 1}
                >
                    {currentSeason?.title}
                </Dropdown.Toggle>
                <DropdownMenu>
                    {seasonsArray?.map((season) => (
                        <Dropdown.Item
                            className={styles.dropdownItem}
                            onClick={() => onSelectSeason(season)}
                            key={season.id}
                        >
                            <span dir='auto' className={styles.constrictedSize}>{season.title}</span>
                            <span className={classNames(styles.constrictedSize, styles.subtitle)}>{`${season.episodes.length} ${t('details.episodes')}`}</span>
                        </Dropdown.Item>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {currentSeason?.paid === true && currentSeason?.purchase == null && (
                <Fragment>
                    {currentSeason.packages && (
                        <Button variant="secondary" onClick={onSubscriptionButtonClick} className={styles.purchaseSeasonButton}>
                            <span>{t('details.purchaseSeason')}</span>
                            <span className={styles.price}>{`${currentSeason?.packages?.at(0)?.price} ${currentSeason?.packages?.at(0)?.currency}`}</span>
                        </Button>
                    )}
                </Fragment>
            )}
        </div>
    )
}

export default SeasonSelector