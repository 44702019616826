const GET_SERIES_LANDING = 'GET_MOVIES_LANDING';
const GET_SERIES_LANDING_FAIL = 'GET_MOVIES_LANDING_FAIL';
const GET_SERIES_LANDING_SUCCESS = 'GET_MOVIES_LANDING_SUCCESS';
const CLEAR_SERIES_LANDING = 'CLEAR_MOVIES_LANDING';

const GET_SERIES_FILTERED = 'GET_MOVIES_FILTERED';
const GET_SERIES_FILTERED_FAIL = 'GET_MOVIES_FILTERED_FAIL';
const GET_SERIES_FILTERED_SUCCESS = 'GET_MOVIES_FILTERED_SUCCESS';
const CLEAR_SERIES_FILTERED = 'CLEAR_MOVIES_FILTERED';

export {
  GET_SERIES_LANDING,
  GET_SERIES_LANDING_FAIL,
  GET_SERIES_LANDING_SUCCESS,
  CLEAR_SERIES_LANDING,
  GET_SERIES_FILTERED,
  GET_SERIES_FILTERED_FAIL,
  GET_SERIES_FILTERED_SUCCESS,
  CLEAR_SERIES_FILTERED,
};
