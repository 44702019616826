import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './SideBarItem.module.scss';
import utilStyles from '@Utils/utils.module.scss';

const SideBarItem = ({ children, url, classes, onClick }) => (
  <NavLink dir='auto' className={classNames(styles.navLink, utilStyles.noDecoration, classes)} to={url} onClick={onClick}>
    {children}
  </NavLink>
);

SideBarItem.propTypes = {
  url: PropTypes.string.isRequired,
  classes: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

SideBarItem.defaultProps = {
  classes: '',
};

export default SideBarItem;
