import {
  GET_LIVE_CHANNELS,
  CLEAR_LIVE_CHANNELS_DATA,
} from '../Constants/LiveChannelsConstants';

const getLiveChannels = () => ({
  type: GET_LIVE_CHANNELS,
  payload: {
    request: {
      url: 'api/liveStreaming/channels/dropdown',
      method: 'get',
      authenticated: true,
    },
  },
});

const clearLiveChannels = () => ({
  type: CLEAR_LIVE_CHANNELS_DATA,
  payload: null,
});

export { getLiveChannels, clearLiveChannels };
