import {
  CLEAR_SUBSCRIPTION,
  POST_SUBSCRIPTION,
  POST_SUBSCRIPTION_FAIL,
  POST_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_SUCCESS,
} from '../Constants/SubsciptionsConstants';

const initialState = {
  error: null,
  loading: false,
  result: null,
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SUBSCRIPTION: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case CANCEL_SUBSCRIPTION: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case POST_SUBSCRIPTION_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      const { message } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result: message,
      };
    }
    case POST_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error?.response?.data?.data?.message,
      };
    }
    case CANCEL_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error?.response?.data?.data?.message,
      };
    }

    case CLEAR_SUBSCRIPTION: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
