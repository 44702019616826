import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import Logo from '../../../Assets/Images/nouveau/ballam-logo.svg';
import styles from './StillWatching.module.scss';

const StillWatching = ({ onContinue, onCancel, pausePlayer }) => {
  const { result } = useSelector((state) => state.videoSettings);
  const { t } = useTranslation();

  useEffect(() => {
    pausePlayer();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <LazyLoadImage
            src={Logo || result?.header?.logo?.logoWeb}
            alt="Logo"
            onError={(e) => {
              e.target.src = Logo;
            }}
            className={styles.logoImg}
          />
        </div>

        <div className={styles.bottom}>
          <div className={styles.title}>{t('globals.areYouStillWatching')}</div>
          <div className={styles.buttons}>
            <Button
              className={classNames(styles.button, styles.continueBtn)}
              onClick={onContinue}
            >
              {t('globals.continueWatching')}
            </Button>
            <Button
              className={classNames(styles.button, styles.cancelBtn)}
              onClick={onCancel}
            >
              {t('globals.cancel')}
            </Button>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default StillWatching;
