import { isMobile } from "react-device-detect";
import MobileLayout from "./MobileLayout/MobileLayout";
import DesktopLayout from "./DesktopLayout/DesktopLayout";
import { useOutletContext } from "react-router-dom";
import { IMediaDetailsContext } from "@Molecules/EpisodesRail/DesktopLayout/DesktopLayout";
import NoData from "./NoData/NoData";
import { MediaType } from "@Utils/commonTypes";
import { Movie, Series, Show } from "src/alballam-api-types";

export const useMediaDetailsContext = () => {
    const context = useOutletContext<IMediaDetailsContext>();
    switch (context?.mediaType) {
        case MediaType.Movie:
            return { media: context?.media as Movie };
        case MediaType.Series:
            return { currentSeason: context?.currentSeason, media: context?.media as Series };
        default:
            return { currentSeason: context?.currentSeason, media: context?.media as Show };
    }
}


function TrailersSection() {
    const context = useMediaDetailsContext();
    const trailersLength = context?.currentSeason?.trailers?.length ?? context?.media?.trailers?.length ?? 0;

    if (trailersLength === 0) return <NoData />;
    if (isMobile) return <MobileLayout />;
    return <DesktopLayout />;
}

export default TrailersSection