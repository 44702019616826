import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from "@sentry/react";
import parse from "html-react-parser";
import StaticLayout from "../../Components/Organisms/StaticLayout";
import { getStatic } from "../../Store/Actions/StaticScreenActions";
import reduxConstants from "../../Store/constants";
import "./index.scss";
import ErrorComponent from "../../HOC/ErrorComponent";
import constants from "../../Utils/constants";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const { result, loading, error } = useSelector(
    (state) => state.staticScreens.termsAndConditions
  );

  useEffect(() => {
    dispatch(getStatic(reduxConstants.staticApis.termsAndConditions));
  }, [dispatch]);

  const renderContent = useCallback(() => {
    let html = "";
    try {
      if (!result) return <Fragment />;
      html = result.data.data.result[0].block[0].text;
    } catch { /* empty */ }


    return (
      <Fragment>
        <p className="termsTitle">
          {result?.data?.data?.result[0].block[0].title}
        </p>
        <p className="pt-3 text">{parse(html)}</p>
      </Fragment>
    );
  }, [result]);

  if (error) return <ErrorComponent error={error} pageType={constants.screens.termsAndConditions} />
  return (
    <StaticLayout loading={loading}>
      <ErrorBoundary>{renderContent()}</ErrorBoundary>
    </StaticLayout>
  );
};
export default TermsAndConditions;
