import React from 'react';

const Approve = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g>
        <path d="M0 0L16 0 16 16 0 16z" />
        <g fill="none" fillRule="nonzero">
          <path
            className="animate"
            d="M15.068 0.028L6.148 9.99 0.862 4.964 0 5.871 6.221 11.784 16 0.863z"
            transform="translate(0 2)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Approve;
