import { useTranslation } from "react-i18next"
import NavigationItem from "@Organisms/Navigation/NavigationItem/NavigationItem"
import styles from './MediaDetailsTabs.module.scss';
import { MediaType } from "@Utils/commonTypes"
import { prepareSections } from "./helpers"

interface IMediaDetailsTabs {
    mediaId: string;
    seasonId?: string;
    mediaType: MediaType;
    hiddenSections: Array<string>;
}

const MediaDetailsTabs: React.FC<IMediaDetailsTabs> = ({ mediaId, seasonId, mediaType, hiddenSections }) => {
    const { t } = useTranslation();
    const sections = prepareSections(t, mediaId, seasonId!, mediaType, hiddenSections);
    const navButtonVariant = (isActive: boolean) => isActive ? 'light' : 'secondary';
    return (
        <div className={styles.root}>
            {sections.map((section, index) => <NavigationItem shouldReplace={true} overrideVariant={navButtonVariant} preventScrollReset overrideClassName={styles.seasonTab} key={index} {...section} />)}
        </div>
    )
}

export default MediaDetailsTabs