import Play from '@Assets/Images/nouveau/play-black.svg'
import { ITrack } from "@Utils/commonTypes";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";


const DefaultPlayButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('details.showMedia')}</span>
        </Fragment>
    )
}

const ComingSoonPlayButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('details.comingSoon')}</span>
        </Fragment>
    )
}

const ContinueWatchingButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('details.continueWatching')}</span>
        </Fragment>
    )
}

export const processTrackedMediaLink = (track?: ITrack) => {
    if (!track) {
        return undefined
    }
    if (track.id === undefined) {
        return undefined;
    }

    if (track.comingSoon) {
        return undefined;
    }

    if (track.paid && track.owned === false) {
        return undefined;
    }

    if (track.track === 0) {
        return undefined;
    }

    return track.id;
}


const PlayButtonContent: React.FC<{ trackedVideo?: ITrack }> = ({ trackedVideo }) => {
    switch (true) {
        case trackedVideo?.comingSoon:
            return <ComingSoonPlayButtonContent />;
        case processTrackedMediaLink(trackedVideo) !== undefined:
            return <ContinueWatchingButtonContent />;
        default:
            return <DefaultPlayButtonContent />;
    }
}

export default PlayButtonContent;