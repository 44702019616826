const VALIDATE_SUBSCRIPTION = 'VALIDATE_SUBSCRIPTION';
const VALIDATE_SUBSCRIPTION_FAIL = 'VALIDATE_SUBSCRIPTION_FAIL';
const VALIDATE_SUBSCRIPTION_SUCCESS = 'VALIDATE_SUBSCRIPTION_SUCCESS';
const CLEAR_VALIDATE_SUBSCRIPTION = 'CLEAR_VALIDATE_SUBSCRIPTION';
const CHECK_SUBSCRIPTION = 'CHECK_SUBSCRIPTION';
const CHECK_SUBSCRIPTION_FAIL = 'CHECK_SUBSCRIPTION_FAIL';
const CHECK_SUBSCRIPTION_SUCCESS = 'CHECK_SUBSCRIPTION_SUCCESS';
const CLEAR_CHECK_SUBSCRIPTION = 'CLEAR_CHECK_SUBSCRIPTION';
const VALIDATE_SUBSCRIPTION_KNET = 'VALIDATE_SUBSCRIPTION_KNET';
const VALIDATE_SUBSCRIPTION_KNET_SUCCESS = 'VALIDATE_SUBSCRIPTION_KNET_SUCCESS';
const VALIDATE_SUBSCRIPTION_KNET_FAIL = 'VALIDATE_SUBSCRIPTION_KNET_FAIL';

const VALIDATE_SUBSCRIPTION_MYFATOORAH = 'VALIDATE_SUBSCRIPTION_MYFATOORAH';
const VALIDATE_SUBSCRIPTION_MYFATOORAH_SUCCESS = 'VALIDATE_SUBSCRIPTION_MYFATOORAH_SUCCESS';
const VALIDATE_SUBSCRIPTION_MYFATOORAH_FAIL = 'VALIDATE_SUBSCRIPTION_MYFATOORAH_FAIL';

export {
  VALIDATE_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_FAIL,
  VALIDATE_SUBSCRIPTION_SUCCESS,
  CLEAR_VALIDATE_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_FAIL,
  CHECK_SUBSCRIPTION_SUCCESS,
  CLEAR_CHECK_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_KNET,
  VALIDATE_SUBSCRIPTION_KNET_SUCCESS,
  VALIDATE_SUBSCRIPTION_KNET_FAIL,
  VALIDATE_SUBSCRIPTION_MYFATOORAH,
  VALIDATE_SUBSCRIPTION_MYFATOORAH_SUCCESS,
  VALIDATE_SUBSCRIPTION_MYFATOORAH_FAIL,
};
