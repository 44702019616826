const endpoints = {
    getSeriesDetails: 'api/video/detailsElastic/s',
    getMovieDetails: 'api/video/detailsElastic/',
    getPlayerStatus: 'api/video/dashboard/slider/player/',
    bookmarks: 'api/videos/bookmarks',
    myFatoorahSubscriptionPaymentMethods: 'api/subscription/myFatoorah/methods?type=myFatoorah',
    myFatoorahSubscriptionPayment: 'api/subscription?type=myFatoorah',
    myFatoorahPurchasePaymentMethods: 'api/purchase/myFatoorah/methods?type=myFatoorah',
    myFatoorahPurchasePayment: 'api/purchase?type=myFatoorah',
    getAllPackages: 'api/packagesWeb',
    checkUserSubscription: 'api/checkUserSubscription',
    getPurchases: 'api/purchases',
    getEpisodeInfoById: 'api/episode/info/', // used to fetch the source for TVs
}

export default endpoints;