import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './OrSeparator.module.scss';

function OrSeparator({ shouldRender }) {
  const { t } = useTranslation();

  if (!shouldRender) return <></>;
  return (
    <div dir='auto' className={styles.root}>
      <div className={styles.separator} />
      {t('globals.or')}
      <div className={styles.separator} />
    </div>
  );
}

OrSeparator.propTypes = {
  shouldRender: PropTypes.bool,
};

OrSeparator.defaultProps = {
  shouldRender: true,
};

export default OrSeparator;
