const GET_SHOWS_LANDING = 'GET_SHOWS_LANDING';
const GET_SHOWS_LANDING_FAIL = 'GET_SHOWS_LANDING_FAIL';
const GET_SHOWS_LANDING_SUCCESS = 'GET_SHOWS_LANDING_SUCCESS';
const CLEAR_SHOWS_LANDING = 'CLEAR_SHOWS_LANDING';

const GET_SHOWS_FILTERED = 'GET_SHOWS_FILTERED';
const GET_SHOWS_FILTERED_FAIL = 'GET_SHOWS_FILTERED_FAIL';
const GET_SHOWS_FILTERED_SUCCESS = 'GET_SHOWS_FILTERED_SUCCESS';
const CLEAR_SHOWS_FILTERED = 'CLEAR_SHOWS_FILTERED';

export {
  GET_SHOWS_LANDING,
  GET_SHOWS_LANDING_FAIL,
  GET_SHOWS_LANDING_SUCCESS,
  CLEAR_SHOWS_LANDING,
  GET_SHOWS_FILTERED,
  GET_SHOWS_FILTERED_FAIL,
  GET_SHOWS_FILTERED_SUCCESS,
  CLEAR_SHOWS_FILTERED,
};
