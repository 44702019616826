import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, SubmitInput } from '../../Atoms';
import MessageBox from '../../Atoms/MessageBox';
import { addProfilePerUser, updateUserPerProfile } from '../../../Store/Actions/UserProfilesActions';
import constants from '../../../Utils/constants';

const EditProfileName = forwardRef(({ AddProfile, avatar, setInpt, inpt, }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { result, success, loading, error } = useSelector((state) => state.ProfilesPerUser);
  const { id } = useParams();
  const [hastouched, setHasTouched] = useState(false);
  const [pic, setPic] = useState(false);
  const [customError, setCustomError] = useState('');

  const existingProfileNames = result?.data?.map((profile) => profile.name) || [];
  const currentProfileName = result?.data?.find((e) => e.id === parseInt(id))?.name;

  const validationSchema = yup.object({
    name: yup
      .string()
      .transform((value) => value.trim())
      .required(t('validation.required'))
      .test(
        'unique-name',
        t('validation.nameExistsError'),
        (value) => {
          if (AddProfile) {
            return !existingProfileNames.includes(value);
          }
          return value === currentProfileName || !existingProfileNames.includes(value);
        }
      ),
  });

  const onSubmit = (user) => {
    setCustomError('');
    if (AddProfile && avatar) {
      dispatch(addProfilePerUser(user.name, avatar?.file, avatar?.ext));
    }
    if (!AddProfile) {
      dispatch(updateUserPerProfile(id, user.name)).then(() => {
        navigate(constants.newScreens.profiles.view);
      });
    }
  };

  return (
    <Formik
      innerRef={ref}
      validationSchema={validationSchema}
      initialValues={{
       name: AddProfile ? inpt || '' : currentProfileName || '',
      }}
      onSubmit={(props) => onSubmit(props)}
    >
      {({ handleSubmit, handleChange, errors }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            handleSubmit(e);
            setHasTouched(false);
            setPic(true);
          }}
        >
          <div>
            <div>
              <InputField
                name="name"
                label={t('globals.name')}
                type="name"
                placeholder={t('globals.name')}
                onChange={(e) => {
                  handleChange(e);
                  setHasTouched(true);
                  if (AddProfile) {
                    setInpt(e.target.value);
                  }
                }}
                alertText={errors.name || customError}
                error={!!errors.name | !!customError}
                autocomplete="off"
                inpt={inpt}
              />
            </div>
            {error && pic && AddProfile && !hastouched && (
              <MessageBox message={error} isError />
            )}
            {!AddProfile
              && success === true
              && !!errors.name === false
              && !hastouched && (
                <MessageBox
                  message={t('globals.operationSuccess')}
                  isError={false}
                />
              )}
            {!AddProfile
              && success === false
              && !!errors.name
              && !hastouched && (
                <MessageBox
                  message={t('errors.somethingWentWrong')}
                  isError
                />
              )}
            {' '}
            <div className="d-flex justify-content-around">
              <SubmitInput
                variant='secondary'
                value={
                  AddProfile
                    ? t('profiles.addProfile')
                    : t('profiles.saveEditProfile')
                }
                type="editProfile"
                size={24}
                loading={loading}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
},
);

EditProfileName.propTypes = {
  AddProfile: PropTypes.bool,
};

EditProfileName.defaultProps = {
  AddProfile: false,
};

export default EditProfileName;
