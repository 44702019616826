import axios from 'axios';
import config from './config';

const baseURL = config.apiUrl;
const timeout = config.apiTimeout;

export const publicHttpClient = axios.create({
  baseURL,
  timeout,
});

export const handleError = (e, t) => {
  try {
    if (e.response) {
      if (e.response.data.data && e.response.data.data.message) {
        return e.response.data.data.message;
      }
      return e.response.data.error
        ? { errorMsg: e.response.data.data.message.message, errorStatus: e.response.data.status }
        : t('globals.somethingWentWrong');
    }
    return e.response.data.error
      ? { errorMsg: e.response.data.data.message.message, errorStatus: e.response.data.status }
      : t('globals.somethingWentWrong');
  } catch {
    return t('globals.somethingWentWrong');
  }
};
