import ChevronRight from '@Assets/Images/nouveau/chevron-right.svg';
import ChevronLeft from '@Assets/Images/nouveau/chevron-left.svg';
import styles from './MobileLayout.module.scss';
import classNames from 'classnames';
import i18n from 'src/i18n';

function DesktopLayout({ goBack }: { goBack: () => void }) {
    const dir = i18n.dir();
    const icon = dir === 'ltr' ? ChevronLeft : ChevronRight;

    return (
        <div className={styles.wrapper}>
            <button className={classNames(styles.backButton, styles[dir])} onClick={goBack}>
                <img src={icon} alt="back" />
            </button>
        </div>
    )
}

export default DesktopLayout