import {
  GET_FILTERED_LANDING_VIDEOS, GET_FILTERED_LANDING_VIDEOS_SUCCESS, GET_FILTERED_LANDING_VIDEOS_FAIL, CLEAR_FILTERED_LANDING_VIDEOS_DATA,
} from '../Constants/FilteredLandingVideosConstants';

const initialState = {
  filteredLoading: null,
  filteredResult: null,
  filteredData: [],
  filteredError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTERED_LANDING_VIDEOS: {
      const {
        params: { page },
      } = action.payload.request;
      return {
        ...state,
        filteredError: null,
        filteredLoading: true,
        filteredResult: null,
        filteredData: page === 1 ? [] : state.filteredData,
      };
    }
    case GET_FILTERED_LANDING_VIDEOS_SUCCESS: {
      return {
        ...state,
        filteredLoading: false,
        filteredResult: action.payload.data.data.result,
        filteredData: state.filteredData.concat([action.payload.data.data.result.data]),
      };
    }
    case GET_FILTERED_LANDING_VIDEOS_FAIL: {
      return {
        ...state,
        filteredLoading: false,
        filteredError: action.error,
      };
    }
    case CLEAR_FILTERED_LANDING_VIDEOS_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
