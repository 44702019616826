import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { ILoaderProps } from './Loader.types';

const Loader: React.FC<ILoaderProps> = ({ size = 35, color = 'var(--color-white)', className }) => (
  <Spinner
    animation="border"
    className={className}
    style={{
      height: `${size}px`,
      width: `${size}px`,
      color,
    }}
  />
);

export default Loader;
