import {
  CLEAR_SERIES_LANDING,
  GET_SERIES_LANDING,
} from '../Constants/SeriesConstants';

const getSeriesLanding = () => ({
  type: GET_SERIES_LANDING,
  payload: {
    request: {
      url: 'api/shows/landing',
      method: 'get',
      authenticated: true,
      params: {
        isSeries: true,
      },
    },
  },
});

const clearSeriesLanding = () => ({
  type: CLEAR_SERIES_LANDING,
  payload: null,
});

export { getSeriesLanding, clearSeriesLanding };
