/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveSubscriptionSource } from "../../Store/Actions/subscriptionsSourceActions";
import NonAuthenticatedLayout from "../../Components/Organisms/NonAuthenticatedLayout/NonAuthenticatedLayout";
import SubscribePackage from "../../Components/Molecules/SubscribePackage/SubscribePackage";
import MessageBox from "../../Components/Atoms/MessageBox";
import Loader from "../../Components/Atoms/Loader/Loader";
import { getSubscriptionPackages } from "../../Store/Actions/SubscriptionPackagesActions";
import constants from "../../Utils/constants";
import styles from "./SubscribePackages.module.scss";
import { clearUserPurchases, getUserPurchases } from "../../Store/Actions/UserPurchasesActions";
import FullWatchHeader from "../../Components/Atoms/FullWatchHeader/FullWatchHeader";
import queryString from "query-string";
import { useAppSelector } from "@Store/hooks";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const SubscribePackages = () => {
  const { t } = useTranslation();
  const fullWatchButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptions = useSelector((state) => state.userPurchases.result);
  const { loading, error, result } = useSelector((state) => state.subscriptionPackages);
  const { isSignedIn } = useSelector((state) => state.authentication);
  const { language } = useSelector((state) => state.authentication);
  const { sourceType } = useAppSelector((state) => state.subscriptionSource);
  const [params] = useSearchParams();
  const qrId = params.get(constants.searchParams.qrId);
  const origin = params.get(constants.searchParams.origin);
  const redirect_url = params.get(constants.searchParams.redirect_url);
  const redirectTitle = params.get("redirectTitle");
  const source = params.get(constants.searchParams.source);
  const [activePackage, setActivePackage] = useState(0);

  useEffect(() => {
    if (result?.data?.length === 1) {
      setActivePackage(result?.data[0]);
    }
  }, [result]);

  useEffect(() => {
    dispatch(getSubscriptionPackages());
  }, [dispatch]);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(getUserPurchases());
    } else {
      dispatch(clearUserPurchases());
    }
  }, [dispatch, isSignedIn]);

  const onPayClickHandler = useCallback(() => {
    if (!activePackage) {
      return;
    }
    if (sourceType === null) {
      dispatch(
        saveSubscriptionSource({
          sourceType: "route",
          routeName: "subscribeAll",
        })
      );
    }

    const queryObj = {
      origin: origin ?? redirect_url,
      title: redirectTitle,
      source,
    };
    if (qrId) {
      queryObj.qrId = qrId;
    }

    navigate(
      {
        pathname: constants.newScreens.pay.subscription.selectMethod(
          activePackage._id
        ).as,
        search: queryString.stringify(queryObj, {
          skipEmptyString: true,
          skipNull: true,
        }),
      },
      { unstable_viewTransition: true }
    );
  }, [
    activePackage,
    sourceType,
    origin,
    redirect_url,
    redirectTitle,
    source,
    qrId,
    navigate,
    dispatch,
  ]);

  return (
    <NonAuthenticatedLayout
      title={t("payment.subscribeAllTitle")}
      contentClassName={styles.content}
      contentContainerClassName={styles.content}
    >
      <div className={styles.subText}>
        {subscriptions?.subscriptions.length > 0
          ? ""
          : t("payment.subscribeAllSubText")}
      </div>
      <div className={styles.packagesGrid}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 mt-5">
            <Loader />
          </div>
        ) : (
          result?.data?.map((el, indx) => (
            /* eslint-disable-next-line no-underscore-dangle */
            <Fragment key={el._id}>
              {subscriptions?.subscriptions.length > 0 &&
                el.price ===
                subscriptions?.subscriptions.map((e) => e.price)[indx] &&
                subscriptions?.subscriptions.map((e) => (
                  <FullWatchHeader
                    duration={new Date(e.expiryDate).toLocaleDateString(
                      "ar",
                      options
                    )}
                  />
                ))}
              <SubscribePackage
                description={el?.description}
                descriptionTwo={el?.descriptionTwo}
                descriptionThree={el?.descriptionThree}
                duration={el.duration}
                price={el.price}
                active={
                  subscriptions?.subscriptions.length > 0
                    ? el.price ===
                    subscriptions?.subscriptions.map((e) => e.price)[indx]
                    : /* eslint-disable-next-line no-underscore-dangle */
                    activePackage._id === el._id
                }
                onClick={() => {
                  /* eslint-disable-next-line no-underscore-dangle */
                  setActivePackage(el);
                }}
                subscriptions={
                  subscriptions?.subscriptions.length > 0
                    ? el.price ===
                    subscriptions?.subscriptions.map((e) => e.price)[indx]
                    : false
                }
                currency={el.currency}
                countryPrice={el.countryPrice}
                language={language}
                isCoupon={el?.type === "coupon"}
              />
            </Fragment>
          ))
        )}
      </div>
      <Row className="d-flex justify-content-center mt-5">
        {error && (
          <MessageBox message={t("errors.somethingWentWrong")} isError />
        )}
        {!subscriptions?.subscriptions.length > 0 && (
          <Button
            ref={fullWatchButtonRef}
            className={styles.payButton}
            onClick={onPayClickHandler}
            disabled={!activePackage}
          >
            {t("payment.goToPayment")}
          </Button>
        )}
      </Row>
    </NonAuthenticatedLayout>
  );
};

export default SubscribePackages;
