import {
  GET_DRM_TOKEN, GET_DRM_TOKEN_FAIL, GET_DRM_TOKEN_SUCCESS, CLEAR_DRM_TOKEN,
} from '../Constants/drmTokenConstants';

const initialState = {
  drmLoading: false,
  drmToken: null,
  drmError: null,
};

const drmTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRM_TOKEN: {
      return {
        ...state,
        drmError: null,
        drmLoading: true,
      };
    }
    case GET_DRM_TOKEN_SUCCESS: {
      return {
        ...state,
        drmError: null,
        drmLoading: false,
        drmToken: action.payload?.data?.token,

      };
    }
    case GET_DRM_TOKEN_FAIL: {
      return {
        ...state,
        loader: false,
        error: action.error,
        success: false,
      };
    }

    case CLEAR_DRM_TOKEN: {
      return initialState;
    }
    default:
      return state;
  }
};

export default drmTokenReducer;
