const POST_CLICKED_AD = "POST_CLICKED_AD";
const POST_SKIPED_AD = "POST_SKIPED_AD";
const POST_WATCHED_AD = "POST_WATCHED_AD";

const POST_CLICKED_AD_SUCCESS = "POST_CLICKED_AD_SUCCESS";
const POST_SKIPED_AD_SUCCESS = "POST_SKIPED_AD_SUCCESS";
const POST_WATCHED_AD_SUCCESS = "POST_WATCHED_AD_SUCCESS";

const POST_CLICKED_AD_FAIL = "POST_CLICKED_AD_FAIL";
const POST_SKIPED_AD_FAIL = "POST_SKIPED_AD_FAIL";
const POST_WATCHED_AD_FAIL = "POST_WATCHED_AD_FAIL";

export {
  POST_CLICKED_AD,
  POST_SKIPED_AD,
  POST_WATCHED_AD,
  POST_CLICKED_AD_SUCCESS,
  POST_SKIPED_AD_SUCCESS,
  POST_WATCHED_AD_SUCCESS,
  POST_CLICKED_AD_FAIL,
  POST_SKIPED_AD_FAIL,
  POST_WATCHED_AD_FAIL,
};
