import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getVideosLanding, clearVideosLanding } from "../../Store/Actions/LandingVideosActions";
import { getLandingVideosFiltered, clearLandingVideosFiltered } from "../../Store/Actions/FilteredLandingVideosActions";
import { getGenres } from "../../Store/Actions/GenresActions";
import Genre from "../../Components/Organisms/FilteredGenre/FilteredGenre.jsx";
import ErrorBoundary from "../../Components/Atoms/ErrorBoundary";
import ErrorComponent from "../../HOC/ErrorComponent";

const Videos = () => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { loading, result, error } = useSelector((state) => state.LandingVideos);
  const { loading: genresLoading, result: genresResult, error: genresError } = useSelector((state) => state.genres);
  const { filteredLoading, filteredResult, filteredData, error: filteredError } = useSelector((state) => state.FilteredLandingVideos);

  useEffect(() => {
    dispatch(getGenres());
    dispatch(getVideosLanding());

    return () => {
      dispatch(clearVideosLanding());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedGenre) {
      dispatch(getLandingVideosFiltered(selectedGenre));
    } else {
      dispatch(clearLandingVideosFiltered());
    }
  }, [dispatch, selectedGenre]);

  if (error || genresError || filteredError) return <ErrorComponent error={error || genresError || filteredError} />;
  return (
    <ErrorBoundary>
      <Genre
        title={t("landing.movies")}
        type="movie"
        loading={loading || filteredLoading}
        genresLoading={genresLoading}
        genres={genresResult}
        selectedGenre={genresResult ? genresResult.find((genre) => genre.id === selectedGenre)?.name : null}
        onGenreChange={(evtKey) => setSelectedGenre(evtKey)}
        data={selectedGenre ? filteredData : result}
        showMoreBtn={!!selectedGenre && filteredResult?.currentPage < filteredResult?.totalPages}
        onSelectMore={() => dispatch(getLandingVideosFiltered(selectedGenre, filteredResult.currentPage + 1))}
        filtered={!!selectedGenre}
      />
    </ErrorBoundary>
  );
};

export default Videos;
