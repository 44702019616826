import {
  GET_COMING_SOON_LIST,
  GET_COMING_SOON_LIST_SUCCESS,
  GET_COMING_SOON_LIST_FAIL,
} from '../Constants/ComingSoonConstants';
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-plusplus */

const initialState = {
  type: null,
  result: null,
  loading: false,
  error: null,
};

const comingSoonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMING_SOON_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_COMING_SOON_LIST_SUCCESS:
      return {
        ...state,
        result: action.payload.data.data.result,
        loading: false,
      };
    case GET_COMING_SOON_LIST_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default comingSoonReducer;
