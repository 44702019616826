import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLatestList } from "../../Store/Actions/GetGenreTypeAction";
import Genre from "../../Components/Organisms/Genre/Genre";
import ErrorComponent from "../../HOC/ErrorComponent";
import constants from "../../Utils/constants";

let arr = [];
let limit = 5;

const Genres = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { result, loading, error } = useSelector((state) => state.genreType);
  const [array, setArray] = useState([]);

  useEffect(() => {
    arr = [];
    setArray([]);
    for (let i = 0; i < Math.min(result?.data?.length, 24); i += 1) {
      arr.push(result?.data[i]);
    }
    setArray([...arr]);
  }, [result]);

  const showLess = useCallback(() => {
    if (!result || !result?.data) {
      return;
    }
    let i = arr.length;
    limit += 24;
    if (
      array.length < result?.data?.length &&
      result?.data?.length - array.length > limit
    ) {
      for (i; i < limit; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
    if (result?.data?.length - array.length < limit) {
      for (i; i < result?.data?.length; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
  }, [result, array.length]);

  useEffect(() => {
    dispatch(getLatestList(id, "genre"));
  }, [dispatch, id]);

  if (error) {
    return (
      <ErrorComponent error={error} pageType={constants.screens.genre().href} />
    );
  }

  return (
    <Genre
      loading={loading}
      pageTitle={result?.pageTitle}
      data={result?.data}
      array={array}
      showLess={showLess}
      result={result}
    />
  );
};
export default Genres;
