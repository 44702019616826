/* eslint-disable camelcase */
import {
  VALIDATE_SUBSCRIPTION,
  CLEAR_VALIDATE_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  CLEAR_CHECK_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_KNET,
  VALIDATE_SUBSCRIPTION_MYFATOORAH,
} from '../Constants/SubscriptionValidationConstants';

/* eslint-disable-next-line camelcase */
export const validateSubscription = (tap_id, qrId) => ({
  type: VALIDATE_SUBSCRIPTION,
  payload: {
    request: {
      url: '/api/checkSubscription',
      method: 'POST',
      authenticated: true,
      data: {
        trackingId: tap_id?.trim(),
        qrId,
      },
    },
  },
});

export const validatePurchaseSubscription = (tap_id, qrId) => ({
  type: VALIDATE_SUBSCRIPTION,
  payload: {
    request: {
      url: '/api/purchase/validate',
      method: 'POST',
      authenticated: true,
      params: {
        tap_id,
        qrId,
      },
    },
  },
});

export const validateKnetSubscription = (tap_id, qrId) => ({
  type: VALIDATE_SUBSCRIPTION_KNET,
  payload: {
    request: {
      url: '/api/checkKNET',
      method: 'POST',
      authenticated: true,
      params: {
        qrId,
        tap: tap_id,
      },
    },
  },
});

export const validateMyFatoorahSubscription = (myFatoorahID, qrId) => ({
  type: VALIDATE_SUBSCRIPTION_MYFATOORAH,
  payload: {
    request: {
      url: '/api/myFatoorah/validate',
      method: 'POST',
      authenticated: true,
      params: {
        qrId,
      },
      data: {
        paymentId: myFatoorahID,
      },
    },
  },
});

export const clearSubscriptionValidation = () => ({
  type: CLEAR_VALIDATE_SUBSCRIPTION,
  payload: null,
});

export const checkSubscription = (id, type) => ({
  type: CHECK_SUBSCRIPTION,
  payload: {
    request: {
      url: `/api/videoLanding/checkSubscription/${id}`,
      method: 'get',
      authenticated: true,
      params: {
        type,
      },
    },
  },
});

export const clearSubscription = () => ({
  type: CLEAR_CHECK_SUBSCRIPTION,
  payload: null,
});
