import { useAppSelector } from "@Store/hooks";
import constants from "@Utils/constants";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function useHandlePaymentRedirection() {
    const { qrId, check: checkPay, title: titleRed, redirect_url: paymentRedirection } = useParams();
    const { isSignedIn, profile } = useAppSelector((state) => state.authentication);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSignedIn && !profile) {
            const isNotLiveFlow = location.state?.isLiveFlow === false;
            if (paymentRedirection && isNotLiveFlow) {
                const checkPayParam = checkPay ? `&check=${checkPay}` : '';
                const titleRedParam = titleRed ? `&title=${titleRed}` : '';
                const qrIdParam = qrId ? `&qrId=${qrId}` : '';
                navigate(`${constants.newScreens.profiles.view}${paymentRedirection ? `?redirect_url=${paymentRedirection}${titleRedParam}${qrIdParam}${checkPayParam}` : ''}`, { state: { payment: true } });
            }
        }
    }, [isSignedIn, profile, paymentRedirection, qrId, location.state, checkPay, titleRed, navigate]);
}

export default useHandlePaymentRedirection;