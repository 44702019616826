import React, { Fragment } from 'react'
import { Dropdown, DropdownMenu } from 'react-bootstrap';
import { getDropdownItemUrl } from '../helpers';
import { useTranslation } from 'react-i18next';
import { ISidebarNavigationElement } from '../SidebarNavigationItem/SidebarNavigationItem.types';
import styles from './NavigationItemDropdown.module.scss';
import CustomToggle from '@Atoms/CustomToggle/CustomToggle';
import { Link } from 'react-router-dom';

interface INavigationItemDropdownProps {
    categories: Array<ISidebarNavigationElement>;
}

const NavigationItemDropdown: React.FC<INavigationItemDropdownProps> = ({ categories }) => {
    const { t } = useTranslation();

    if (categories.length < 1) return <Fragment />;
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} dir='auto' className={styles.toggle} variant='outline-none'>
                {t('showDetails.more')}
            </Dropdown.Toggle>
            <DropdownMenu>
                {categories.map((elm, index) => {
                    const url = getDropdownItemUrl(elm.type, elm.id);
                    return (
                        <Dropdown.Item as={Link} className={styles.item} to={url} key={index}>
                            {elm.name}
                        </Dropdown.Item>
                    )
                })}
            </DropdownMenu>
        </Dropdown>
    )
}

export default NavigationItemDropdown