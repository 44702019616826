import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CLEAR_FORGOT_PASSWORD_DATA,
} from '../Constants/ForgotPasswordConstants';

const initialState = {
  loading: false,
  result: null,
  error: null,
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    }
    case FORGOT_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_FORGOT_PASSWORD_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default forgotPasswordReducer;
