import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import parse from 'html-react-parser';
import StaticLayout from '../../Components/Organisms/StaticLayout';
import { getStatic } from '../../Store/Actions/StaticScreenActions';
import reduxConstants from '../../Store/constants';
import './index.scss';
import ErrorComponent from '../../HOC/ErrorComponent';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { result, loading, error } = useSelector(
    (state) => state.staticScreens.privacyPolicy,
  );

  useEffect(() => {
    dispatch(getStatic(reduxConstants.staticApis.privacyPolicy));
  }, [dispatch]);

  const renderContent = useCallback(() => {
    let html = '';
    let title = '';
    try {
      if (!result) {
        return null;
      }

      title = result.data.data.result[0].block[0].title;
      html = result.data.data.result[0].block[0].text;
      // eslint-disable-next-line no-empty
    } catch {}
    return (
      <div>
        <p className="termsTitle">{title}</p>
        <p className="pt-3 text">{parse(html)}</p>
      </div>
    );
  }, [result]);

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <StaticLayout loading={loading}>
      <ErrorBoundary>{renderContent()}</ErrorBoundary>
    </StaticLayout>
  );
};

export default PrivacyPolicy;
