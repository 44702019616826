import { useTranslation } from "react-i18next";
import Check from '@Assets/Images/nouveau/check.svg';
import Plus from '@Assets/Images/nouveau/plus.svg';

import { Fragment } from "react/jsx-runtime";

const DefaultBookmarkButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Plus} alt="add bookmarks" />
            <span>{t('details.addToBookmarks')}</span>
        </Fragment>
    )
}


const AlreadyBookmarkedButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Check} alt="remove from bookmarks" />
            <span>{t('details.removeFromBookmarks')}</span>
        </Fragment>
    )
}

const BookmarkButtonContent: React.FC<{ isBookmarked: boolean }> = ({ isBookmarked }) => {
    switch (true) {
        case isBookmarked:
            return <AlreadyBookmarkedButtonContent />;
        default:
            return <DefaultBookmarkButtonContent />;
    }
}

export default BookmarkButtonContent;