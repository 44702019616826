const ADD_BOOKMARK = 'ADD_BOOKMARK';
const ADD_BOOKMARK_SUCCESS = 'ADD_BOOKMARK_SUCCESS';
const ADD_BOOKMARK_FAIL = 'ADD_BOOKMARK_FAIL';

const GET_BOOKMARKS = 'GET_BOOKMARKS';
const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
const GET_BOOKMARKS_FAIL = 'GET_BOOKMARKS_FAIL';

const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
const REMOVE_BOOKMARK_SUCCESS = 'REMOVE_BOOKMARK_SUCCESS';
const REMOVE_BOOKMARK_FAIL = 'REMOVE_BOOKMARK_FAIL';

const REMOVE_BOOKMARK_ITEM = 'REMOVE_BOOKMARK_ITEM';

export {
  REMOVE_BOOKMARK, REMOVE_BOOKMARK_SUCCESS, REMOVE_BOOKMARK_FAIL,
  GET_BOOKMARKS, GET_BOOKMARKS_SUCCESS, GET_BOOKMARKS_FAIL,
  ADD_BOOKMARK, ADD_BOOKMARK_SUCCESS, ADD_BOOKMARK_FAIL,
  REMOVE_BOOKMARK_ITEM,
};
