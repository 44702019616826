import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import Loader from '../Loader/Loader';
import './SubmitInput.scss';

const SubmitInput = ({
  variant = 'primary',
  value,
  type,
  loading,
  size,
  onClick,
  isDisabled,
  id,
  className,
}) => (
  <Button
    dir='auto'
    type="submit"
    onClick={onClick}
    variant={variant}
    disabled={loading || isDisabled}
    className={classNames(className, id === 'ContactUs' ? 'contactUsButton' : '', type, 'submit')}
  >
    {loading ? <Loader size={size} color={type === 'SignUp' ? 'var(--color-black)' : ''} /> : value}
  </Button>
);

SubmitInput.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'SignUp', 'editProfile', 'editUserProfile']).isRequired,
  loading: PropTypes.bool,
  size: PropTypes.number,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

SubmitInput.defaultProps = {
  loading: false,
  size: 35,
  onClick: null,
  isDisabled: false,
  className: '',
};

export default SubmitInput;
