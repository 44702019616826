import React, { useCallback, useMemo } from 'react';
import Placeholder from '@Assets/Images/placeholders/16x9.svg';
import styles from './Episode.module.scss';
import LandscapeImage from '@Atoms/LandscapeImage/LandscapeImage';
import { useTranslation } from 'react-i18next';
import { ITrackedMedia, PurchasableMedia } from '@Utils/commonTypes';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import utilStyles from '@Utils/utils.module.scss';
import constants from '@Utils/constants';
import { getImageTagOptions } from '@Utils/common';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { setPaymentModalData, togglePaymentModal } from '@Store/Actions/PaymentModalActions';

interface IEpisodeProps {
    episode: Episode;
    wrapperClassName?: string;
    imageClassName?: string;
}

type Episode = ITrackedMedia & {
    description: string;
    quality?: string;
    duration?: number;
    owned: boolean;
    packages: Array<IPackage>,
    comingSoon: boolean;
    episodeId: string;
    track: number;
}

export interface IPackage {
    id: number,
    type: 'purchase' | 'subscription',
    price: number,
    countryPrice: number,
    currency: string,
    bundle: string,
    isKnet: boolean
}

const getTrackPercentage = (track?: number, duration?: number) => {
    if (!duration || duration === 0 || !track) {
        return undefined;
    }

    return track * 100 / duration;
}

const Episode: React.FC<IEpisodeProps> = ({ episode, wrapperClassName, imageClassName }) => {
    const { duration, paid, packages, owned, comingSoon, id, image, quality, title, description } = episode;
    const hours = duration ? Math.floor(duration / 3600) : 0;
    const minutes = duration ? Math.floor((duration % 3600) / 60) : 0;
    const trackPercentage = getTrackPercentage(episode.track, duration);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const imageTagOptions = useMemo(() => getImageTagOptions(paid, comingSoon, owned, packages[0]), [comingSoon, owned, paid, packages]);

    const onEpisodeClick = useCallback(() => {
        // if is not paid, take to watch page
        if (comingSoon) return;
        if (paid === false || owned) {
            navigate(constants.screens.watch(id).as, { unstable_viewTransition: true });
        }

        // should make him purchase
        dispatch(togglePaymentModal(true));
        dispatch(setPaymentModalData({ purchaseType: PurchasableMedia.Episode, purchaseId: `${id}` }));
    }, [comingSoon, dispatch, id, navigate, owned, paid]);

    return (
        <button onClick={onEpisodeClick} className={classNames(styles.wrapper, utilStyles.noDecoration, wrapperClassName)}>
            <LandscapeImage
                tagProps={imageTagOptions}
                progressBarPercentage={trackPercentage}
                imageProps={{ src: image, placeholderSrc: Placeholder, className: classNames(styles.image, imageClassName) }}
                rootWrapperProps={{ className: styles.imageWrapper }}
            />
            <div className={styles.typographyGroup}>
                {/* <span className={styles.title}>{order}. {title}</span> */}
                <span className={styles.title}>{title}</span>
                <p className={styles.description}>{parse(description)}</p>
            </div>
            <span className={styles.episodeFooter}>
                {quality && <div className={styles.qualityContainer}>{quality}</div>}
                {(hours > 0 || minutes > 0) && (
                    <div className={styles.durationGroup}>
                        {hours > 0 && (
                            <span className={styles.duration}>
                                {hours} {t(`details.${hours === 1 ? 'hour' : 'hours'}`)}
                            </span>
                        )}
                        {minutes > 0 && (
                            <span className={styles.duration}>
                                &nbsp;{minutes} {t(`details.${minutes === 1 ? 'minute' : 'minutes'}`)}
                            </span>
                        )}
                    </div>
                )}
            </span>
        </button>
    )
}

export default Episode;