import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../../Utils/constants';

const Icon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={() => window.open(constants.socialMedia.links.tiktok)}
    width={width || 'inherit'}
    height={height || 'inherit'}
    viewBox="0 0 30 33"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <path
            /* eslint-disable-next-line max-len */
            d="M448.544 6.599c-1.6-1.207-2.75-2.977-3.157-5.017-.1-.504-.154-1.024-.16-1.557h-5.38v14.7l-.006 8.053c0 2.152-1.402 3.978-3.345 4.62-.564.186-1.173.274-1.807.24-.81-.045-1.568-.29-2.228-.684-1.403-.839-2.354-2.361-2.38-4.103-.04-2.722 2.16-4.94 4.88-4.94.537 0 1.052.087 1.535.246v-5.462c-.51-.076-1.027-.115-1.551-.115-2.977 0-5.762 1.237-7.752 3.467-1.504 1.685-2.407 3.834-2.546 6.088-.182 2.961.901 5.776 3.002 7.852.309.305.633.588.972.85 1.802 1.386 4.004 2.137 6.324 2.137.524 0 1.042-.038 1.551-.114 2.167-.32 4.166-1.313 5.744-2.873 1.939-1.916 3.01-4.46 3.022-7.167l-.028-12.025c.925.714 1.936 1.304 3.022 1.762 1.688.713 3.477 1.074 5.32 1.073V8.273c0 .002-.015.002-.016.002-1.883 0-3.62-.624-5.016-1.676z"
            transform="translate(-917 -625) translate(493 625)"
          />
        </g>
      </g>
    </g>
  </svg>
);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 30,
  height: 33,
};

export default Icon;
