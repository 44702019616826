import {
  CLEAR_VIDEOS_TRACKING, GET_VIDEOS_TRACKING, GET_VIDEO_DASHBOARD, REMOVE_CONTINUE_WATCHING,
} from '../Constants/videosDashboardConstants';

const getVideosTracking = () => ({
  type: GET_VIDEOS_TRACKING,
  payload: {
    request: {
      url: 'api/video/track',
      method: 'get',
      authenticated: true,
    },
  },
});

const getVideosDashboard = () => ({
  type: GET_VIDEO_DASHBOARD,
  payload: {
    request: {
      url: 'api/video/dashboard',
      method: 'get',
      authenticated: true,
    },
  },
});

const removeVideoFromContinueWatching = (id) => ({
  type: REMOVE_CONTINUE_WATCHING,
  payload: {
    id,
    request: {
      url: 'api/video/end',
      method: 'post',
      authenticated: true,
      data: { video: parseInt(id, 10) },
      skipAuthRefresh: true,
    },
  },
});

const clearVideosTracking = () => ({
  type: CLEAR_VIDEOS_TRACKING,
  payload: null,
});

export {
  getVideosTracking, clearVideosTracking, getVideosDashboard, removeVideoFromContinueWatching,
};
