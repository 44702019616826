import React, { Fragment, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper/modules';
import PropTypes from 'prop-types';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import MovieCard from '../../Molecules/MovieCard/MovieCard';
import constants from '../../../Utils/constants';
import { isShowOrSeries } from '../../../Utils/common';
import ContinueWatchingCard from '@Molecules/ContinueWatchingCard/ContinueWatchingCard';
import ListHeader from './ListHeader/ListHeader';
import ExpandedCard from '@Organisms/ExpandedCard/ExpandedCard';
import classNames from 'classnames';
import TopTenCard from '@Molecules/TopTenCard/TopTenCard';
import './MoviesList.scss';

const getBreakPoints = (type, displayType) => {
  if (type === 'secondSlider') {
    return constants.breakpoints.secondSlider
  }
  if (type === 'topTen') {
    return constants.breakpoints.topTen;
  }

  if (displayType === 'portrait') {
    return constants.breakpoints.portrait;
  }

  return constants.breakpoints.landscape;
}

const MovieList = ({
  movies,
  sectionTitle,
  isContinueWatching,
  listType,
  types,
  section,
  tag,
  homePage,
  displayType,
  isSeason,
  show,
  seasonOrder,
  seasonId,
  listHeaderWrapperClassName,
  swiperClassName
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);
  const [endTransition, setEndTransition] = useState(true);
  const { language } = useSelector((state) => state.authentication);
  const railBreakPoints = getBreakPoints(types, displayType);

  useEffect(() => {
    if (
      language === 'en'
      && document.getElementsByClassName('swiper-button-next')
    ) {
      for (
        let i = 0;
        i < document.getElementsByClassName('swiper-button-next').length;
        i += 1
      ) {
        document
          .getElementsByClassName('swiper-button-next')[i].classList.add('swiper-button-next-EN');
      }
      for (
        let i = 0;
        i < document.getElementsByClassName('swiper-button-prev').length;
        i += 1
      ) {
        document
          .getElementsByClassName('swiper-button-prev')[i].classList.add('swiper-button-prev-EN');
      }
    }
    if (
      language === 'ar'
      && document.getElementsByClassName('swiper-button-next')
    ) {
      for (
        let i = 0;
        i < document.getElementsByClassName('swiper-button-next').length;
        i += 1
      ) {
        document
          .getElementsByClassName('swiper-button-next')[i].classList.add('swiper-button-next-AR');
      }
      for (
        let i = 0;
        i < document.getElementsByClassName('swiper-button-prev').length;
        i += 1
      ) {
        document
          .getElementsByClassName('swiper-button-prev')[i].classList.add('swiper-button-prev-AR');
      }
    }
  }, [language]);

  useEffect(() => {
    const onResize = () => {
      const w = window.innerWidth;
      setInnerWidth(w);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  if (!movies || movies.length <= 0) {
    return null;
  }

  return (
    <div>
      <ListHeader
        sectionTitle={sectionTitle}
        type={types}
        isHomePage={homePage}
        isSeason={isSeason}
        seasonId={seasonId}
        seasonOrder={seasonOrder}
        section={section}
        show={show}
        wrapperClassName={listHeaderWrapperClassName}
      />

      <Swiper
        modules={[Navigation, A11y]}
        className={classNames(
          "movies-list-container",
          types === 'topTen' || types === 'season' ? 'movies-list-container-vertical-spacing' : '',
          types === 'topTen' && isMobile ? 'movies-list-container-top-ten' : '',
          swiperClassName,
        )}
        breakpoints={railBreakPoints}
        spaceBetween={types === 'topTen' ? 40: 20}
        navigation={!isMobile}
        slidesOffsetAfter={types === 'topTen' && (isDesktop || isTablet) ? 100 : undefined}
        grabCursor={false}
        pagination={false}
        draggable
        allowTouchMove
        a11y
        freeMode
        preventClicksPropagation
        touchStartForcePreventDefault
        scrollbar={false}
        slideToClickedSlide={false}
        watchSlidesProgress
        onTransitionStart={() => setEndTransition(false)}
        onTransitionEnd={() => setEndTransition(true)}
      >
        {movies.map((
          {
            video,
            time,
            medium,
            date,
            title,
            id,
            trailers,
            type,
            rating,
            paid,
            comingSoon,
            portraitMedium,
            description,
            quality,
            numberOfSeasons,
            containsFreeEpisodes,
            genres,
            duration,
            image,
            portraitImage,
            slug,
            generalText,
            showName,
            seasonName,
            secondSliderTrailer,
            showId,
            seasonId: activeSeasonId,
            order, // present in episode items, typically array of type "season"
          },
          idx,) => {
          if (!id && !video?.id) {
            return null;
          }

          const isFree = isShowOrSeries(type || video?.type || listType)
            ? containsFreeEpisodes
            || (isContinueWatching ? video?.paid === false : paid === false)
            : paid === false;

          const props = {
            video,
            time,
            medium,
            date,
            title,
            id,
            trailers,
            type,
            rating,
            paid,
            comingSoon,
            portraitMedium,
            description,
            quality,
            numberOfSeasons,
            containsFreeEpisodes,
            genres,
            duration,
            image,
            portraitImage,
            slug,
            generalText,
            showName,
            seasonName,
            secondSliderTrailer,
            showId,
            seasonId: activeSeasonId,
          };

          const cardInfoProps = {
            date,
            rating,
            isSecondSlider: types === 'secondSlider',
            genres,
            title,
            showName,
            seasonName,
          }

          if (types === 'topTen') {
            return (
              <SwiperSlide key={`${type || video?.type || listType}${id || video?.id}`}>
                <TopTenCard
                  cardInfoProps={cardInfoProps}
                  index={idx}
                  landscapeImage={props.medium || props.image}
                  {...props}
                />
              </SwiperSlide>
            )
          }

          //TODO: Remove constriction to latest
          if (types === 'latest') {
            return (
              <SwiperSlide id={idx} key={`${type || video?.type || listType}${id || video?.id}`}>
                <ExpandedCard
                  displayType={displayType}
                  portraitImage={portraitMedium || portraitImage}
                  comingSoon={comingSoon}
                  paid={paid}
                  image={image}
                  trailers={trailers}
                  id={id}
                  type={type}
                  description={description}
                  title={title}
                  cardInfoProps={cardInfoProps}
                />
              </SwiperSlide>
            )
          }
          if (isContinueWatching) {
            return (
              <SwiperSlide id={idx} key={`${type || video?.type || listType}${id || video?.id}`}>
                <ContinueWatchingCard railLength={movies?.length} time={time} {...video} />
              </SwiperSlide>
            )
          }

          return (
            <SwiperSlide
              id={idx}
              key={`${type || video?.type || listType}${id || video?.id}`}
            >
              {({ isActive }) => (
                <Fragment>
                  <MovieCard
                    className={
                      !isMobile
                        ? innerWidth < 998 && innerWidth >= 625
                          ? idx === activeIndex + 2
                            ? `${language === 'ar' ? 'movie' : 'movieEng'
                            } last-slide`
                            : `${language === 'ar' ? 'movie' : 'movieEng'}`
                          : innerWidth < 625
                            ? idx === activeIndex + 1
                              ? `${language === 'ar' ? 'movie' : 'movieEng'
                              } slide-last`
                              : `${language === 'ar' ? 'movie' : 'movieEng'}`
                            : innerWidth > 998
                              && innerWidth < 1378
                              && endTransition
                              ? idx === activeIndex + 4
                                ? `${language === 'ar' ? 'movie' : 'movieEng'
                                } last-element`
                                : `${language === 'ar' ? 'movie' : 'movieEng'}`
                              : innerWidth >= 1378 && endTransition
                                ? idx === activeIndex + 4
                                  ? `${language === 'ar' ? 'movie' : 'movieEng'
                                  } last-5elements`
                                  : `${language === 'ar' ? 'movie' : 'movieEng'}`
                                : `${language === 'ar' ? 'movie' : 'movieEng'}`
                        : 'movie-mobile'
                    }
                    thumbnail={isContinueWatching ? video?.medium : (medium || image)}
                    portraitImage={portraitMedium || portraitImage}
                    title={
                      isContinueWatching
                        ? video?.title?.ar || video?.title
                        : title
                    }
                    date={date || video?.date}
                    isActive={isActive}
                    index={idx}
                    setActiveIndex={setActiveIndex}
                    trailer={trailers}
                    id={isContinueWatching ? video?.id : id}
                    playerId={isContinueWatching ? video?.playerId || id : id}
                    type={type || video?.type || listType}
                    isContinueWatching={isContinueWatching}
                    railLength={movies?.length}
                    time={time}
                    duration={video?.duration || duration}
                    rating={rating || video?.rating}
                    tag={tag}
                    isFree={types !== 'comingSoon' && isFree}
                    isComingSoon={comingSoon && types !== 'comingSoon'}
                    comingSoon={comingSoon}
                    isPortrait={displayType === 'portrait'}
                    description={description}
                    quality={quality || video?.quality}
                    numberOfSeasons={numberOfSeasons || video?.numberOfSeasons}
                    genres={genres || video?.genres}
                    image={image}
                    endTransition={endTransition}
                    isLast5elmntsIndex={
                      innerWidth >= 1378 && idx === activeIndex + 4
                    }
                    isSeason={isSeason}
                    show={show}
                    showId={showId}
                    seasonOrder={seasonOrder}
                    seasonId={seasonId}
                    activeSeasonId={activeSeasonId}
                    topTen={types === 'topTen'}
                    titleUrl={window.decodeURI(slug ?? video?.slug)}
                    isSecondSlider={types === 'secondSlider'}
                    generalText={generalText}
                    showName={showName}
                    seasonName={seasonName}
                    video={video}
                    isInComingSoonRail={types === 'comingSoon'}
                    secondSliderTrailer={secondSliderTrailer}
                    isLive={types === 'channel'}
                    order={order}
                  />
                </Fragment>
              )}
            </SwiperSlide>
          );
        },
        )}
      </Swiper>
    </div>
  );
};

MovieList.propTypes = {
  movies: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
    }),
  ).isRequired,
  sectionTitle: PropTypes.string.isRequired,
  isContinueWatching: PropTypes.bool,
  listType: PropTypes.string,
  homePage: PropTypes.bool,
  isSeason: PropTypes.bool,
  show: PropTypes.number,
  seasonOrder: PropTypes.number,
  seasonId: PropTypes.number,
};

MovieList.defaultProps = {
  isContinueWatching: false,
  listType: '',
  homePage: false,
  isSeason: null,
  show: null,
  seasonOrder: null,
  seasonId: null,
};

export default MovieList;
