import {
  POST_CLICKED_AD,
  POST_SKIPED_AD,
  POST_WATCHED_AD,
  POST_CLICKED_AD_SUCCESS,
  POST_SKIPED_AD_SUCCESS,
  POST_WATCHED_AD_SUCCESS,
  POST_CLICKED_AD_FAIL,
  POST_SKIPED_AD_FAIL,
  POST_WATCHED_AD_FAIL,
} from "../Constants/AdvertisementAnalyticsConstants";

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CLICKED_AD:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case POST_CLICKED_AD_SUCCESS: {
      return { ...state, loading: false, error: null, success: true };
    }
    case POST_CLICKED_AD_FAIL:
      return { ...state, loading: false, error: action.error, success: false };

    case POST_SKIPED_AD:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case POST_SKIPED_AD_SUCCESS: {
      return { ...state, loading: false, error: null, success: true };
    }
    case POST_SKIPED_AD_FAIL:
      return { ...state, loading: false, error: action.error, success: false };

    case POST_WATCHED_AD:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case POST_WATCHED_AD_SUCCESS: {
      return { ...state, loading: false, error: null, success: true };
    }
    case POST_WATCHED_AD_FAIL:
      return { ...state, loading: false, error: action.error, success: false };
    default:
      return state;
  }
};

export default reducer;
