const VALIDATE_COUPON = "VALIDATE_COUPON";
const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS";
const VALIDATE_COUPON_FAIL = "VALIDATE_COUPON_FAIL";
const CLEAR_COUPON = "CLEAR_COUPON";
const VERIFY_COUPON = "VERIFY_COUPON";
const VERIFY_COUPON_SUCCESS = "VERIFY_COUPON_SUCCESS";
const VERIFY_COUPON_FAIL = "VERIFY_COUPON_FAIL";
const SET_COUPON_NAME = "SET_COUPON_NAME";

export {
  VALIDATE_COUPON,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  CLEAR_COUPON,
  VERIFY_COUPON,
  VERIFY_COUPON_SUCCESS,
  VERIFY_COUPON_FAIL,
  SET_COUPON_NAME,
};
