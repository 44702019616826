import {
  CLEAR_USER_SESSION,
  GET_USER_SESSION,
  UPDATE_CONTINUE_WATCHING,
  END_CONTINUE_WATCHING,
} from '../Constants/UserSessionConstants';

/* eslint-disable  import/prefer-default-export */

export const getUserSession = () => ({
  type: GET_USER_SESSION,
  payload: {
    request: {
      url: 'session',
      method: 'POST',
      authenticated: true,
    },
  },
});

export const clearUserSession = (uniqueID) => ({
  type: CLEAR_USER_SESSION,
  payload: {
    request: {
      url: 'session',
      method: 'DELETE',
      authenticated: true,
      data: {
        uniqueID,
      },
    },
  },
});

export const updateContinueWatching = (uniqueID, videoId, time) => ({
  type: UPDATE_CONTINUE_WATCHING,
  payload: {
    request: {
      url: 'session/track',
      method: 'POST',
      authenticated: true,
      data: {
        uniqueID,
        videoId,
        time,
      },
    },
  },
});

export const endContinueWatching = (uniqueID, videoId) => ({
  type: END_CONTINUE_WATCHING,
  payload: {
    request: {
      url: 'session/end',
      method: 'POST',
      authenticated: true,
      data: {
        uniqueID,
        videoId,
      },
    },
  },
});
