import Play from '@Assets/Images/nouveau/play-black.svg'
import { IPackage } from '@Atoms/Episode/Episode';
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";


const WatchStartingFrom: React.FC<{ purchasePackage: IPackage | undefined }> = ({ purchasePackage }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('showDetails.watchFrom')} {purchasePackage?.countryPrice ?? purchasePackage?.price}{purchasePackage?.currency ?? '$'}</span>
        </Fragment>
    )
};

const DefaultPlayButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('details.showMedia')}</span>
        </Fragment>
    )
}

const ComingSoonPlayButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('details.comingSoon')}</span>
        </Fragment>
    )
}

const ContinueWatchingButtonContent = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <img src={Play} alt="show media button" />
            <span>{t('details.continueWatching')}</span>
        </Fragment>
    )
}


const PlayButtonContent: React.FC<{ comingSoon: boolean, track?: number, hasToPay: boolean, purchasePackage: IPackage | undefined }> = ({ comingSoon, track, hasToPay, purchasePackage }) => {
    switch (true) {
        case comingSoon:
            return <ComingSoonPlayButtonContent />;
        case track !== undefined:
            return <ContinueWatchingButtonContent />;
        case hasToPay && purchasePackage !== undefined:
            return <WatchStartingFrom purchasePackage={purchasePackage} />;
        default:
            return <DefaultPlayButtonContent />;
    }
}

export default PlayButtonContent;