import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isFirefox, isMobile, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import videojs from "video.js";
import "videojs-contrib-eme";
import classNames from "classnames";
import { setSelectedSubtitle } from "../../Store/Actions/SelectedSubtitleActions";
import { clearBreaks, getBreaks } from "../../Store/Actions/BreaksActions";
import { clearDRMToken, getDRMToken } from "../../Store/Actions/drmTokenActions";
import { clearHoveredCard, hoveredCardAction } from "../../Store/Actions/HoveredMovie";
import { saveAuthentication } from "../../Store/Actions/AuthenticationActions";
import {
  clearMovie,
  getMovie,
  getTrailer,
  startTracking,
  clearMovieDetails,
  getMovieDetails,
} from "../../Store/Actions/MovieActions";
import { setRadioOn } from "../../Store/Actions/StreamActions";
import ErrorBoundary from "../../Components/Atoms/ErrorBoundary";
import VideoPlayer from "../../Components/Atoms/VideoPlayer";
import VideoPlayerControls from "../../Components/Molecules/VideoPlayerControls";
import VideoPlayerLoader from "../../Components/Atoms/VideoPlayerLoader/VideoPlayerLoader";
import StillWatching from "../../Components/Atoms/StillWatching/StillWatching";
import constants from "../../Utils/constants";
import ThumbnailLoader from "../../Components/Atoms/ThumbnailLoader/ThumbnailLoader";
import PaymentModal from "../../Components/Molecules/PaymentModal/PaymentModal";
import SomethingWentWrong from "../SomethingWentWrong/SomethingWentWrong";
import { isIpad } from "../../Utils/deviceDetect";
import { clearUserPurchases, getUserPurchases } from "../../Store/Actions/UserPurchasesActions";
import "./index.scss";
import useQuery from "../../Hooks/useQuery";
import firebase from "../../Utils/firebase";
import config from "../../Utils/config";
import {
  clearUserSession,
  endContinueWatching,
  getUserSession,
  updateContinueWatching,
} from "../../Store/Actions/UserSessionActions";
import { loginAsGuest } from "../../Services/Authentication";
import AdvertisementPlayer from "../../Components/Organisms/AdvertisementPlayer/AdvertisementPlayer";
import placeholder from '../../Assets/Images/placeholders/16x9.svg';
import { handleViewsLogin, resetViewsTimer, useDenyWebview } from "./helpers";
import { isWebview } from "@Utils/common";
import { useSessionStorage } from "usehooks-ts";

function arrayToString(array) {
  const uint16array = new Uint16Array(array.buffer);
  return String.fromCharCode.apply(null, uint16array);
}
function base64EncodeUint8Array(input) {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  let output = "";
  let chr1;
  let chr2;
  let chr3;
  let enc1;
  let enc2;
  let enc3;
  let enc4;
  let i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN;
    chr3 = i < input.length ? input[i++] : Number.NaN;

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (Number.isNaN(chr2)) {
      // eslint-disable-next-line no-multi-assign
      enc3 = enc4 = 64;
    } else if (Number.isNaN(chr3)) {
      enc4 = 64;
    }
    output
      += keyStr.charAt(enc1)
      + keyStr.charAt(enc2)
      + keyStr.charAt(enc3)
      + keyStr.charAt(enc4);
  }
  return output;
}
function base64DecodeUint8Array(input) {
  const raw = window.atob(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
}

function Watch() {
  let added = 0;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSeason = useQuery()?.get("isSeason");
  const seekTimeRef = useRef(-1);
  const viewsTimer = useRef(0);
  const {
    isSignedIn, language, access_token, isGuest,
  } = useSelector(
    (state) => state.authentication,
  );
  const { isKillSession, uniqueID } = useSelector((state) => state.userSession);
  const { result: breaksResult } = useSelector((state) => state.Breaks);
  const initialState = useMemo(
    () => ({
      initialVideoReady: false,
      loading: true,
      playing: false,
      urlToPlay: "",
      recapPlaying: false,
      buffering: false,
      currentTime: 0,
      bufferedTime: 0,
      duration: 0,
      volume: 1,
      muted: true,
      selectedQuality: "Auto",
      selectedSubtitle: { display: language === "ar" ? "إيقاف" : "Off" },
      playerReady: false,
      showedCredits: false,
      wasPlaying: true,
      removedCreditsTimer: false,
      breakActive: false,
      breakNumber: 0,
      seenBreaks: [],
      adsToPlay: [],
      isCounted: true,
    }),
    [language],
  );

  const { error, result, loading, details } = useSelector((state) => state.movie);
  const { drmToken, drmError } = useSelector((state) => state.drmToken);
  const { result: socketThresholdResult } = useSelector((state) => state.videoSettings);
  const { socketThreshold, recapThreshold } = socketThresholdResult?.header ?? { socketThreshold: constants.videoPlayer.updateTrackInterval, recapThreshold: constants.videoPlayer.minTimeToShowRecap };
  const { selectedSubtitle } = useSelector((state) => state.selectedSubtitle);
  const siteId = config.env === config.environments.prod ? config.pallycon.siteIdProd : config.pallycon.siteIdDev;

  const navigate = useNavigate();
  const { id } = useParams();
  const videoPlayerRef = useRef(null);
  const videoRef = useRef(null);
  const creditsNextEpisodeTimer = useRef(null);
  const [isSafariReady, setIsSafariReady] = useState(false);
  const [isSafariMobileReady, setIsSafariMobileReady] = useState(false);
  const isWebView = isWebview();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldGoToHomepageSession, _, removeShouldGoToHomepageSession] = useSessionStorage(constants.sessionStorageKeys.shouldGoToHomepage);

  useDenyWebview();

  useEffect(() => {
    if (config.env !== config.environments.dev) {
      firebase.analytics().logEvent("screen_view", {
        firebase_screen: "/watch",
        firebase_screen_class: id,
      });
    }
  }, [id]);

  const finished = (result?.startCredits === 0
    && result?.track
    >= result?.duration - constants.videoPlayer.remainingSecsToMarkAsEnded)
    || (result?.startCredits > 0 && result?.track >= result?.startCredits);

  const [status, setStatus] = useState({
    ...initialState,
    id,
    isTrailer: id[0] === "t",
  });
  const [isIntro, setIsIntro] = useState(false);
  const [isCredits, setIsCredits] = useState(false);
  const [subtitles, setSubtitles] = useState(null);
  const [inactiveNbrEpisodes, setInactiveNbrEpisodes] = useState(constants.videoPlayer.defaultNbrMovies);
  const [areYouStillWatching, setAreYouStillWatching] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(loading);
  const [showPayment, setShowPayment] = useState(false);
  const [clickedEpisode, setClickedEpisode] = useState(null);
  const [paymentSeason, setPaymentSeason] = useState(null);
  const [recapPlayed, setRecapPlayed] = useState(false);

  useEffect(() => {
    if (!result?.paid) {
      if (!isSignedIn) {
        dispatch(getBreaks(status.id));
      } else {
        dispatch(getUserPurchases()).then((res) => {
          if (!res?.payload?.data?.data?.result?.subscriptions?.length > 0) {
            dispatch(getBreaks(status.id));
          }
        });
      }
    }
  }, [dispatch, result, isSignedIn, status.id]);

  const breaksStartPoints = useMemo(() => {
    const startPoints = [];
    if (breaksResult?.length > 0) {
      breaksResult.map(({ start }) => startPoints.push(Math.trunc(start)));
    }
    return startPoints;
  }, [breaksResult]);

  const handleGuestLogin = useCallback(async () => {
    const guestId = uuidv4();
    const loggedGuest = await loginAsGuest(guestId, language);
    dispatch(
      saveAuthentication({
        ...loggedGuest.data.result,
        isGuest: guestId,
        profile: 0,
      }),
    );
  }, [dispatch, loginAsGuest, saveAuthentication, language]);

  const checkEpisode = useCallback(
    (episode, season) => {
      if (!episode?.id) {
        return false;
      }

      if (!episode?.paid) {
        return true;
      }

      const returnValue = episode?.paid && episode?.owned;
      if (!returnValue) {
        setClickedEpisode(episode);
        setPaymentSeason({
          packages: season?.packages,
          thumbnail: result?.showImage,
          show: result?.title,
          title: season?.title,
          id: season?.id,
          sourceTitle: season?.sourceTitle,
        });
        setShowPayment(true);
        setStatus((prevState) => ({
          ...prevState,
          wasPlaying: prevState.playing,
          playing: false,
        }));
      }

      return returnValue;
    },
    [result],
  );

  const checkForUnseenBreaks = useCallback(
    (breaksResult, seenBreaks, currentTime, breaksStartPoints) => {
      if (!status.playerReady) return;
      let stackedBreaks = [];
      breaksResult?.forEach((element, index) => {
        if (
          Math.trunc(element.start) <= currentTime
          && !seenBreaks.includes(Math.trunc(element.start))
          && breaksStartPoints.includes(Math.trunc(element.start))
        ) {
          stackedBreaks = stackedBreaks.concat(element.campaigns);
          setStatus((prev) => ({
            ...prev,
            breakNumber: prev.breakNumber + 1,
            seenBreaks: [...prev.seenBreaks, breaksStartPoints[index]],
          }));
        }
      });
      const unique = stackedBreaks.filter(
        (obj, index) => stackedBreaks.findIndex((item) => item.id === obj.id) === index,
      );
      if (unique?.length > 0) {
        setStatus((prev) => ({
          ...prev,
          breakActive: true,
          // playing: false,
          // muted: true,
          adsToPlay: unique,
        }));
      }
    },
    [status.playerReady],
  );

  useEffect(() => {
    if (status.breakActive && !showThumbnail && status.playerReady) {
      setStatus((prev) => ({
        ...prev,
        playing: false,
        muted: true,
      }));
    }
  }, [showThumbnail, status.breakActive, status.playerReady]);

  const onAdEnd = useCallback(() => {
    setTimeout(() => {
      setStatus((prev) => ({
        ...prev,
        breakActive: false,
        playing: true,
        muted: false,
      }));
      if (isSafari && isMobile) {
        document.getElementsByTagName("video")[0].play();
      }
    }, 0);
  }, []);

  useEffect(() => {
    if (!access_token) {
      handleGuestLogin();
    }
  }, [handleGuestLogin]);

  useEffect(() => {
    if (!access_token) { /* empty */ } else if (!status.isTrailer && !isWebView) {
      dispatch(getDRMToken(status.id));
    }
  }, [dispatch, status.id, status.isTrailer, isWebView]);

  useEffect(() => {
    if (!access_token) { /* empty */ } else if (!isSignedIn && !status.isTrailer && isGuest === "") {
      navigate(constants.screens.browse, { replace: true });
    }
  }, [navigate, isSignedIn, status.isTrailer, isGuest]);

  useEffect(() => {
    dispatch(hoveredCardAction(null, null));
  }, [dispatch]);

  useEffect(() => {
    if (!access_token) { /* empty */ } else if (status.id) {
      if (status.isTrailer) {
        dispatch(getTrailer(status.id.slice(1)));
      } else {
        dispatch(setRadioOn(false));
        dispatch(getMovie(status.id));
      }
    }
  }, [dispatch, status.id, status.isTrailer]);

  useEffect(() => {
    if (!access_token) { /* empty */ } else {
      if (!result) {
        return;
      }
      // TODO: Adjust, open popup
      if ((result.paid && result.owned === false) || result.owned === false) {
        if (result.showId) {
          if (!isSeason) {
            navigate(constants.newScreens.details.series(result.showId), { replace: true });
          } else {
            setShowThumbnail(true);
            setClickedEpisode(result);
            setPaymentSeason({
              packages: result?.seasonPackages,
              thumbnail: result?.showImage,
              show: result?.showImage,
              title: result?.title,
              id: result?.seasonId,
            });
            setShowPayment(true);
            setStatus((prevState) => ({
              ...prevState,
              wasPlaying: prevState.playing,
              playing: false,
            }));
          }
        } else {
          console.warn('redirection yet to be implemented');
          navigate(`${constants.screens.browse}?id=${status.id}`, { replace: true });
        }
      } else if (result && !result?.links && !result?.link) {
        navigate(-1);
      }
    }
  }, [dispatch, navigate, result, status.id, isSeason, checkEpisode]);

  useEffect(() => {
    if (!access_token) {
    } else if (result?.showId && !status.isTrailer) {
      dispatch(getMovieDetails(result?.showId));
    }
  }, [dispatch, result, status.isTrailer]);

  useEffect(() => {
    if (error?.response?.status === 404) {
      navigate(constants.screens.notFound, { replace: true });
    } else if (result || error) {
      setStatus((prevState) => ({ ...prevState, loading: false }));
    }
  }, [dispatch, result, error, navigate]);

  useEffect(() => {
    if (!access_token) {
    } else if (inactiveNbrEpisodes >= constants.videoPlayer.maxNbrMovies) {
      setStatus((prevState) => ({ ...prevState, playing: false }));
      setAreYouStillWatching(true);
    }
  }, [inactiveNbrEpisodes]);

  useEffect(() => {
    if (!selectedSubtitle) {
      dispatch(
        setSelectedSubtitle({ display: language === "ar" ? "إيقاف" : "Off" }),
      );
    }
  }, [selectedSubtitle, dispatch, language]);

  useEffect(() => {
    if (result?.newSubtitles?.length > 0) {
      const newSubs = [
        {
          kind: "subtitles",
          src: "",
          label: language === "ar" ? "إيقاف" : "Off",
          display: language === "ar" ? "إيقاف" : "Off",
        },
      ];
      // .flat();

      result?.newSubtitles?.map((el) => newSubs.push({
        kind: "subtitles",
        src: el.source,
        label: el.langName,
        srcLang: el.lang,
        display: el.label,
      }));

      setSubtitles(newSubs);
    }
  }, [result, language]);

  useEffect(() => {
    if (!access_token) return;
    if (result) {
      if (
        result?.recap
        && result?.recap !== result?.link
        && result?.track < recapThreshold
        && result?.recapStreamLink
      ) {
        setStatus((prevState) => ({
          ...prevState,
          urlToPlay: result?.baseVideoURL + result?.recapStreamLink,
          recapPlaying: true,
          isCounted: result?.isCounted,
        }));
      } else {
        setStatus((prevState) => ({
          ...prevState,
          urlToPlay: status?.isTrailer
            ? result?.link
            : result?.baseVideoURL
            + (isWebView ? result?.streamLink : result?.links?.fairplay),
          recapPlaying: false,
          playing: false,
          isCounted: result?.isCounted,
        }));
      }
    }
  }, [result, isWebView]);

  useEffect(() => () => {
    if (!access_token) return;

    dispatch(clearBreaks());
    if (uniqueID) dispatch(clearUserSession(uniqueID));
    dispatch(clearMovie());
    dispatch(clearMovieDetails());
    if (!isWebView) {
      dispatch(clearDRMToken());
      if (isSafari && isMobile) {
        videojs("myPlayer_html5_api").dispose();
      } else videojs("myPlayer").dispose();
    } else if (videoRef?.current) {
      videoRef?.current?.remove();
    }

    setIsSafariReady(false);
    setSubtitles(null);
    setRecapPlayed(false);
    dispatch(clearUserPurchases());
  }, [dispatch, isWebView]);

  const playNextVideo = useCallback(
    (newVideoId, newEpisodeTitle) => {
      try {
        if (!newVideoId || newVideoId === status.id) return;

        window.history.replaceState(
          window.history.state,
          null,
          `/watch/${newVideoId}&title=${newEpisodeTitle.replace(' ', '-')}`,
        );
        setRecapPlayed(false);
        if (!isWebView) {
          if (isMobile && isSafari) {
            videojs("myPlayer_html5_api").dispose();
          } else videojs("myPlayer").dispose();
        } else if (videoRef?.current) videoRef?.current?.remove();
        setSubtitles(null);
        setStatus({ ...initialState, id: newVideoId });
        setShowThumbnail(true);
        setIsCredits(false);
        setIsSafariReady(false);
        resetViewsTimer(viewsTimer, false, status?.breakActive, true);
      } catch { }
    },
    [status, initialState, dispatch, isWebView],
  );

  const onPaymentCloseHandler = useCallback(() => {
    setShowPayment(false);
  }, []);

  const onIsSeasonClose = useCallback(() => {
    setShowPayment(false);
    navigate(-1);
  }, [navigate]);

  const onPaymentTransitionEnd = useCallback(() => {
    setClickedEpisode(null);
    setPaymentSeason(null);
    setStatus((prevState) => ({
      ...prevState,
      playing: prevState.wasPlaying,
    }));
  }, []);

  const runNextEpisode = useCallback(() => {
    try {
      if (checkEpisode(result?.nextEpisode, result?.nextEpisode?.season)) {
        playNextVideo(result.nextEpisode.id, result?.nextEpisode?.title);
        resetViewsTimer(viewsTimer, false, status?.breakActive, true);
      }
    } catch { }
  }, [result, checkEpisode, playNextVideo, status?.breakActive]);

  const resetActivity = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      removedCreditsTimer: isCredits,
    }));
    clearTimeout(creditsNextEpisodeTimer.current);
    setInactiveNbrEpisodes(constants.videoPlayer.defaultNbrMovies);
    setAreYouStillWatching(false);
  }, [isCredits]);

  const onEpisodeClickHandler = useCallback(
    (episode, seasonPackages) => {
      resetActivity();

      if (checkEpisode(episode, seasonPackages)) {
        playNextVideo(episode.id, episode.title);
      }
    },
    [resetActivity, playNextVideo, checkEpisode],
  );

  const onNextEpisodeClickHandler = useCallback(() => {
    resetActivity();
    runNextEpisode();
    resetViewsTimer(viewsTimer, false, status?.breakActive, true);
  }, [resetActivity, runNextEpisode, status?.breakActive]);

  const endVideoTime = useCallback((isEnd) => {
    if (status.isTrailer) return;
    dispatch(endContinueWatching(uniqueID, status.id)).then(() => {
      if ((result.showId && isEnd
        && (!result.nextEpisode
          || Object.keys(result?.nextEpisode)?.length === 0))
        || status.isTrailer
        || !result.showId
      ) {
        navigate(-1);
      }
    });
  }, [status, dispatch, navigate]);

  const updateVideoTime = useCallback((time) => {
    try {
      if (time === 0 || status.isTrailer) {
        return;
      }
      dispatch(updateContinueWatching(uniqueID, parseInt(status.id, 10), time));
    } catch { }
  }, [status?.isTrailer, status?.id, dispatch, uniqueID]);

  const onVideoEnd = useCallback(() => {
    try {
      if (status.recapPlaying) {
        setRecapPlayed(!isSafari);
        setStatus((prevState) => ({
          ...prevState,
          urlToPlay:
            result?.baseVideoURL
            + (isWebView ? result?.streamLink : result?.links?.fairplay),
          playing: true,
          recapPlaying: false,
          playerReady: false,
        }));
      } else {
        setIsCredits(false);
      }

      if (
        !status.recapPlaying
        && result?.nextEpisode?.id
        && result?.nextEpisode?.id !== status.id
      ) {
        setInactiveNbrEpisodes((prevState) => +prevState + 1);
        runNextEpisode();
      }

      resetViewsTimer(viewsTimer, false, status?.breakActive, true);
      if (!status.recapPlaying && !result.startCredits) {
        endVideoTime(true);
      } else if (
        (result.showId
          && (!result.nextEpisode
            || Object.keys(result?.nextEpisode)?.length === 0))
        || status.isTrailer
        || !result.showId
      ) {
        navigate(-1)
      }
    } catch { }
  }, [result, runNextEpisode, status, endVideoTime, navigate, dispatch, isWebView]);

  useEffect(() => {
    dispatch(clearHoveredCard());
  }, [dispatch]);

  useEffect(() => {
    if (isSafariMobileReady) {
      // onErPlay()
      if (!isWebView) videojs("myPlayer_html5_api").load();
      setIsSafariMobileReady(false);
    }
  }, [isSafariMobileReady, isWebView]);

  useEffect(() => {
    if (isMobile && isSafari) {
      if (status.playing) {
        document.getElementsByTagName("video")[0].play();
      }
      if (document.getElementsByTagName("video")[0] && !status.playing) {
        document.getElementsByTagName("video")[0].pause();
      }
    }
  }, [status.playing]);

  const onSafariMobileReady = useCallback(() => {
    const updatedStatus = { ...status };

    if (!recapPlayed && !isWebView) {
      const player = videojs(
        "myPlayer_html5_api",
        {
          muted: true,
          controlBar: {
            playToggle: false,
            captionsButton: false,
            chaptersButton: false,
            subtitlesButton: false,
            remainingTimeDisplay: false,
            progressControl: {
              seekBar: true,
            },
            fullscreenToggle: false,
            playbackRateMenuButton: false,
          },
          // controls: true,
        },
        function onPlayerReady() {
          // eme hasn't been initialised yet, is a function
          if (typeof this.eme !== "object") {
            this.eme();
            setIsSafariMobileReady(true);
          }
          // eme has been initialised, eme is an object
          if (typeof player.eme !== "object") {
            player.eme();
          }
          this.src({
            src: status.urlToPlay,
            type: "application/x-mpegURL",
            keySystems: {
              "com.apple.fps.1_0": {
                getCertificate(emeOptions, callback) {
                  videojs.xhr(
                    {
                      url: `https://license.pallycon.com/ri/fpsKeyManager.do?siteId=${siteId}`,
                      method: "GET",
                    },
                    (err, response, responseBody) => {
                      if (err) {
                        callback(err);
                        return;
                      }
                      callback(null, base64DecodeUint8Array(responseBody));
                    },
                  );
                },
                getContentId(emeOptions, initData) {
                  const contentId = arrayToString(initData);
                  return contentId.substring(contentId.indexOf("skd://") + 6);
                },

                // return content ID
                getLicense(emeOptions, contentId, keyMessage, callback) {
                  // if (contentId)
                  videojs.xhr(
                    {
                      url: "https://license.pallycon.com/ri/licenseManager.do",
                      method: "POST",
                      responseType: "text",
                      body: `spc=${base64EncodeUint8Array(
                        keyMessage,
                      )}&${contentId}`,
                      headers: {
                        // 'Content-type': 'application/x-www-form-urlencoded',
                        "pallycon-customdata-v2": drmToken,
                      },
                    },
                    (err, response, responseBody) => {
                      if (err) {
                        callback(err);
                        return;
                      }
                      const seekToSeconds = finished ? 0 : result?.track || 0;
                      const duration = document.querySelectorAll("video")[0].duration
                        || result?.duration;
                      if (
                        !status.initialVideoReady
                        && !status.recapPlaying
                        && seekToSeconds > 1
                        && !(
                          result.startCredits
                          && seekToSeconds >= result.startCredits
                        )
                        && seekToSeconds < duration
                        && isSafari
                        && isMobile
                      ) {
                        document.querySelector("video").currentTime = seekToSeconds;
                        updatedStatus.currentTime = seekToSeconds;
                      }

                      updatedStatus.playing = true;
                      updatedStatus.duration = duration;
                      updatedStatus.playerReady = true;
                      updatedStatus.muted = true;
                      updatedStatus.volume = 0;
                      setStatus(updatedStatus);
                      setShowThumbnail(false);
                      return callback(
                        null,
                        base64DecodeUint8Array(responseBody),
                      );
                    },
                  );
                },
              },
            },
          });
        },
      );
    }

    if (isWebView) {
      setIsSafariMobileReady(true);
      const seekToSeconds = finished ? 0 : result?.track || 0;
      const duration = document.querySelectorAll("video")[0].duration || result?.duration;
      if (
        !status.initialVideoReady
        && !status.recapPlaying
        && seekToSeconds > 1
        && !(result.startCredits && seekToSeconds >= result.startCredits)
        && seekToSeconds < duration
        && isSafari
        && isMobile
      ) {
        document.querySelector("video").currentTime = seekToSeconds;
        updatedStatus.currentTime = seekToSeconds;
      }

      updatedStatus.playing = true;
      updatedStatus.duration = duration;
      updatedStatus.playerReady = true;
      updatedStatus.muted = true;
      updatedStatus.volume = 0;
      setStatus(updatedStatus);
      setShowThumbnail(false);
    }

    if (subtitles && !updatedStatus.playerReady) {
      const video = document.getElementsByTagName("video")[0];
      video.crossOrigin = "anonymous";

      subtitles.map((el) => {
        if (!el.src) return el;

        const track = document.createElement("track");
        track.kind = "subtitles";
        track.label = el.label;
        track.srclang = el.srcLang;
        track.src = el.src || "";

        if (!status?.recapPlaying) {
          video.appendChild(track);
        }

        return el;
      });
    }

    const seekToSeconds = finished ? 0 : result?.track || 0;
    const duration = result?.duration;
    if (
      !status.initialVideoReady
      && !status.recapPlaying
      && seekToSeconds > 1
      && !(result.startCredits && seekToSeconds >= result.startCredits)
      && seekToSeconds < duration
      && isSafari
      && isMobile
    ) {
      document.querySelector("video").currentTime = seekToSeconds;
      updatedStatus.currentTime = seekToSeconds;
    }

    updatedStatus.duration = duration;
    updatedStatus.playerReady = true;
    updatedStatus.muted = true;
    updatedStatus.volume = 0;
    setStatus(updatedStatus);
  }, [drmToken, status, recapPlayed, result, subtitles]);

  const onSubChangeHandler = useCallback(
    (sub) => {
      dispatch(
        setSelectedSubtitle({ display: language === "ar" ? "إيقاف" : "Off" }),
      );
      dispatch(setSelectedSubtitle(sub));

      const tracks = isMobile && isSafari
        ? isWebView
          ? document?.querySelector("video")?.textTracks
          : videojs("myPlayer_html5_api").textTracks()
        : document.getElementsByTagName("track");

      for (let i = 0; i < tracks.length; i += 1) {
        const track = tracks[i];
        if (track.label !== "segment-metadata") {
          if (isMobile && isSafari) {
            track.mode = sub?.label?.toLocaleLowerCase()
              === track.label.toLocaleLowerCase()
              ? "showing"
              : "disabled";
          } else {
            track.track.mode = sub?.label?.toLocaleLowerCase()
              === track.label.toLocaleLowerCase()
              ? "showing"
              : "disabled";
          }
        }
      }
    },
    [dispatch],
  );

  const onReadyHandler = useCallback(() => {
    const updatedStatus = { ...status };
    if (
      videoPlayerRef?.current
      && document.getElementsByTagName("video")[0]
      && !status.playerReady
      && !drmError
      && status?.urlToPlay?.endsWith(".m3u8")
      && !isWebView
    ) {
      document.getElementsByTagName("video")[0].id = "myPlayer";
      if (!recapPlayed) {
        const player = videojs(
          "myPlayer",
          {
            textTrackSettings: false,
            controlBar: { subsCapsButton: false },
            html5: { nativeTextTracks: false },
          },
          function onPlayerReady() {
            // eme hasn't been initialised yet, is a function
            if (typeof this.eme !== "object") {
              this.eme();
              console.log('present', this?.eme);
            }

            // eme has been initialised, eme is an object
            if (typeof player?.eme !== "object") {
              player.eme();
            }

            this.src({
              hls: {
                overrideNative: false,
              },
              html5: {
                nativeAudioTracks: true,
                nativeVideoTracks: true,
                nativeTextTracks: false,
                textTrackSettings: false,
              },
              src: isFirefox
                ? status.urlToPlay
                  .replace("CMAF", "DASH")
                  .replace("m3u8", "mpd")
                : status.urlToPlay,
              type: isFirefox
                ? "application/dash+xml"
                : "application/x-mpegURL",
              keySystems: isSafari
                ? {
                  "com.apple.fps.1_0": {
                    getCertificate(emeOptions, callback) {
                      videojs.xhr(
                        {
                          url: `https://license.pallycon.com/ri/fpsKeyManager.do?siteId=${siteId}`,
                          method: "GET",
                        },
                        (err, response, responseBody) => {
                          if (err) {
                            callback(err);
                            return;
                          }
                          callback(
                            null,
                            base64DecodeUint8Array(responseBody),
                          );
                        },
                      );
                    },
                    getContentId(emeOptions, initData) {
                      const contentId = arrayToString(initData);
                      return contentId
                        .substring(contentId.indexOf("skd://") + 6)
                        .replace(/^.*:\/\//, "");
                    },

                    // return content ID
                    getLicense(emeOptions, contentId, keyMessage, callback) {
                      if (contentId) {
                        videojs.xhr(
                          {
                            url: "https://license.pallycon.com/ri/licenseManager.do",
                            method: "POST",
                            responseType: "text",
                            body: `spc=${base64EncodeUint8Array(
                              keyMessage,
                            )}&${contentId}`,
                            headers: {
                              "pallycon-customdata-v2": drmToken,
                            },
                          },
                          (err, response, responseBody) => {
                            if (err) {
                              callback(err);
                              return;
                            }
                            setIsSafariReady(true);
                            return callback(
                              null,
                              base64DecodeUint8Array(responseBody),
                            );
                          },
                        );
                      }
                    },
                  },
                }
                : {
                  "com.widevine.alpha": {
                    audioRobustness: "SW_SECURE_CRYPTO",
                    videoRobustness: "SW_SECURE_CRYPTO",
                    url: "https://license.pallycon.com/ri/licenseManager.do",
                    licenseHeaders: {
                      "pallycon-customdata-v2": drmToken,
                    },
                  },
                },
            });
            if (!isSafari) {
              updatedStatus.playing = true;
            }
          },
        );
      }
    }
    if (!isSafari && isWebView) {
      updatedStatus.playing = true;
    }
    if (isSafariReady && isSafari) {
      updatedStatus.playing = true;
    }
    const seekToSeconds = finished ? 0 : result?.track || 0;

    const duration = videoPlayerRef.current?.getDuration() || result?.duration;

    if (
      !status.initialVideoReady
      && !status.recapPlaying
      && seekToSeconds > 1
      && !(result.startCredits && seekToSeconds >= result.startCredits)
      && seekToSeconds < duration
      && !isSafari
    ) {
      videoPlayerRef.current.seekTo(seekToSeconds, "seconds");
    }

    if (!status.recapPlaying && !status.initialVideoReady) {
      if (seekToSeconds === 0) {
        updateVideoTime(1);
      }
      updatedStatus.initialVideoReady = true;
    }

    if (subtitles && !updatedStatus.playerReady) {
      const video = document.getElementsByTagName("video")[0];
      video.crossOrigin = "anonymous";

      subtitles.map((el) => {
        if (!el.src) return el;

        const track = document.createElement("track");
        track.kind = "subtitles";
        track.label = el.label;
        track.srclang = el.srcLang;
        track.src = el.src || "";

        if (!status?.recapPlaying) {
          video.appendChild(track);
        }

        return el;
      });
    }

    updatedStatus.muted = false;
    setStatus(updatedStatus);
    const foundSelectedSubtitleInVideo = subtitles?.find(
      (sub) => sub.label === selectedSubtitle?.label,
    );

    if (!foundSelectedSubtitleInVideo) {
      return dispatch(setSelectedSubtitle({ display: language === "ar" ? "إيقاف" : "Off" }));
    }

    if (selectedSubtitle && selectedSubtitle !== foundSelectedSubtitleInVideo) {
      onSubChangeHandler(foundSelectedSubtitleInVideo);
      return dispatch(setSelectedSubtitle(foundSelectedSubtitleInVideo));
    }
  }, [
    result,
    subtitles,
    status,
    updateVideoTime,
    drmToken,
    drmError,
    recapPlayed,
    isSafariReady,
    selectedSubtitle,
    dispatch,
    language,
    isWebView,
  ]);

  useEffect(() => {
    if (!access_token) return;
    if (isSafari && isSafariReady && !status.playing) {
      setStatus((prevState) => ({
        ...prevState,
        playing: true,
      }));
    }
  }, [isSafariReady, status.playing]);

  useEffect(() => {
    if (access_token && !status.isTrailer) {
      dispatch(getUserSession());
    }
  }, []);

  useEffect(() => {
    if (!access_token) return;
    if (isSafari && isSafariReady && status.playing) {
      const seekToSeconds = finished ? 0 : result?.track || 0;
      const duration = videoPlayerRef.current?.getDuration() || result?.duration;

      if (
        !status.recapPlaying
        && seekToSeconds > 1
        && !(result.startCredits && seekToSeconds >= result.startCredits)
        && seekToSeconds < duration
      ) {
        setIsSafariReady(false);
        videoPlayerRef.current.seekTo(seekToSeconds, "seconds");
      }
    }
  }, [isSafariReady, status.playing, result, status.recapPlaying]);

  const onStartHandler = useCallback(() => {
    if (!status.isTrailer && !status.recapPlaying) {
      dispatch(startTracking(status.id));
    }
    setStatus((prevState) => ({ ...prevState, playerReady: true }));

    if (!status.isTrailer) {
      if (!isWebView) {
        if (videojs("myPlayer").play() !== undefined && drmToken) {
          videojs("myPlayer").play();
        }
      }
    }

    if (!status.isTrailer && !status.recapPlaying) {
      onSubChangeHandler(selectedSubtitle);
    }
  }, [dispatch, status, drmToken, isWebView]);

  useEffect(() => {
    if (result?.paid && !result.owned) return;
    if ("mediaSession" in navigator && result) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: result?.title,
        artwork: [
          { src: result?.image ?? placeholder, sizes: "96x96", type: "image/png" },
          { src: result?.image ?? placeholder, sizes: "128x128", type: "image/png" },
          { src: result?.image ?? placeholder, sizes: "192x192", type: "image/png" },
          { src: result?.image ?? placeholder, sizes: "256x256", type: "image/png" },
          { src: result?.image ?? placeholder, sizes: "384x384", type: "image/png" },
          { src: result?.image ?? placeholder, sizes: "512x512", type: "image/png" },
        ],
      });
    }
  }, [result]);

  const callTrackApiOnBack = useCallback(() => {
    if (!videoPlayerRef?.current?.getCurrentTime) return;

    const time = Math.floor(document.querySelector('video')?.currentTime);
    if (
      !status?.recapPlaying
      && ((result?.startCredits && time < result?.startCredits) || result?.startCredits === 0)
      && time >= recapThreshold
      && !status.breakActive
    ) {
      dispatch(updateContinueWatching(uniqueID, status?.id, time));
    }
  }, [dispatch, recapThreshold, result?.startCredits, uniqueID, status.breakActive, status?.id, status?.recapPlaying]);

  const onPlayerSeekHandler = useCallback((seekTime) => {
    setStatus((prevState) => ({
      ...prevState,
      currentTime: seekTime,
    }));
  }, []);

  const onBufferHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      buffering: true,
    }));
  }, []);

  const onBufferEndHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      buffering: false,
    }));
    setShowThumbnail(false);
  }, []);

  const onForwardHandler = useCallback(() => {
    try {
      if (isMobile && isSafari) {
        if (document.querySelector("video").currentTime === null) return;

        return (document.querySelector("video").currentTime = document.querySelector("video").currentTime
          + constants.videoPlayer.jumpTime);
      }
      if (videoPlayerRef.current?.getCurrentTime() === null) {
        return;
      }

      const seekTo = Math.min(
        videoPlayerRef.current.getCurrentTime()
        + constants.videoPlayer.jumpTime,
        videoPlayerRef.current.getDuration(),
      );

      if (
        seekTo === videoPlayerRef.current.getDuration()
        && status.playing
        && !status.recapPlaying
      ) {
        setStatus((prevState) => ({
          ...prevState,
          playing: false,
        }));
      }
      if (status.isTrailer && document.querySelector('video')) {
        document.querySelector('video').currentTime = seekTo;
      }
      videoPlayerRef.current.seekTo(seekTo, "seconds");
    } catch { }
  }, [status]);

  const onBackwardHandler = useCallback(() => {
    try {
      if (isMobile && isSafari) {
        if (document.querySelector("video").currentTime === null) return;

        return (document.querySelector("video").currentTime = document.querySelector("video").currentTime
          - constants.videoPlayer.jumpTime);
      }
      if (videoPlayerRef.current.getCurrentTime() === null) {
        return;
      }
      const seekTo = Math.max(
        videoPlayerRef.current.getCurrentTime()
        - constants.videoPlayer.jumpTime,
        0,
      );
      if (status.isTrailer && document.querySelector('video')) {
        document.querySelector('video').currentTime = seekTo;
      }
      videoPlayerRef.current.seekTo(seekTo, "seconds");
    } catch { }
  }, [status?.isTrailer]);

  const seekThePlayer = useCallback((time) => {
    try {
      if (isSafari && isMobile) {
        document.querySelector("video").currentTime = Math.floor(time);
      } else videoPlayerRef.current.seekTo(Math.floor(time), "seconds");
    } catch { }
  }, []);

  const onSeekHandler = useCallback((time) => {
    try {
      seekTimeRef.current = time;
      setStatus((prevState) => ({
        ...prevState,
        currentTime: time,
        playing: false,
        seekWasPlaying: prevState.playing,
      }));
    } catch { }
  }, []);

  const onSeekEndHandler = useCallback(() => {
    try {
      if (seekTimeRef.current >= 0) {
        seekThePlayer(Math.floor(seekTimeRef.current));
        setStatus((prevState) => ({
          ...prevState,
          playing: prevState.seekWasPlaying,
        }));
        seekTimeRef.current = -1;
      }
    } catch { }
  }, [seekThePlayer]);

  const onProgressHandler = useCallback(
    (data) => {
      viewsTimer.current = handleViewsLogin(viewsTimer, status?.breakActive, status?.id, status?.isCounted);
      try {
        if (!status?.urlToPlay?.endsWith(".m3u8")) {
          setShowThumbnail(false);
        }

        const newState = { ...status };
        newState.bufferedTime = data?.loadedSeconds || 0;
        newState.currentTime = isMobile && isSafari
          ? document.querySelector("video")?.currentTime
          : data.playedSeconds;

        // on iPad the video continues to play but it won't enter the buffer end callback
        // so we need to remove the buffer state here
        if (isIpad() || isSafari) {
          newState.buffering = false;
        }

        setStatus(newState);

        if (!status.breakActive && status.playerReady) {
          if (
            status.playerReady
            && breaksResult?.length > 0
            && !status.seenBreaks.includes(Math.trunc(status.currentTime))
            && breaksStartPoints.includes(Math.trunc(status.currentTime))
          ) {
            // Selecting which ads to play by comparing the start time of each ad with the track time we have
            let breaksToShow = [];
            breaksResult?.forEach(({ campaigns, start }) => {
              if (start <= result?.track) {
                // eslint-disable-next-line no-return-assign
                return (breaksToShow = breaksToShow.concat(campaigns));
              }
            });
            // Selecting the unique ads to play when we get in from continue watching
            if (
              breaksToShow?.length > 0
              && status.currentTime === 0
              && result?.track > 1
              && !result?.finished
            ) {
              const unique = breaksToShow.filter(
                (obj, index) => breaksToShow.findIndex((item) => item.id === obj.id) === index,
              );
              // Selecting the break times to save them as seen
              const smallerThanCurrentTime = breaksStartPoints.filter(
                (breakTime) => breakTime < result?.track,
              );

              setStatus((prev) => ({
                ...prev,
                breakActive: true,
                adsToPlay: unique,
                breakNumber: smallerThanCurrentTime?.length,
                seenBreaks: smallerThanCurrentTime,
              }));
            } else {
              setStatus((prev) => ({
                ...prev,
                breakActive: true,
                adsToPlay: breaksResult[status.breakNumber]?.campaigns,
                breakNumber: prev.breakNumber + 1,
                seenBreaks: [
                  ...prev.seenBreaks,
                  breaksStartPoints[status.breakNumber],
                ],
              }));
            }
          } else {
            checkForUnseenBreaks(
              breaksResult,
              status.seenBreaks,
              status.currentTime,
              breaksStartPoints,
            );
          }
        }

        const playedSeconds = Math.floor(isMobile && isSafari ? document.querySelector("video")?.currentTime : data?.playedSeconds);
        if (!status.recapPlaying) {
          if (
            playedSeconds > 0
            && playedSeconds % (socketThreshold ?? constants.videoPlayer.updateTrackInterval) === 0
            && (playedSeconds < result.startCredits || result?.startCredits === 0 || !result?.startCredits)
          ) {
            updateVideoTime(playedSeconds);
          }

          if (result?.recap) {
            if (playedSeconds <= recapThreshold) {
              updateVideoTime(1);
            }
            if (playedSeconds === recapThreshold + 1) {
              updateVideoTime(recapThreshold + 1);
            }
          }
        }

        if (!status.recapPlaying && result.endIntro) {
          setIsIntro(
            playedSeconds >= result.startIntro
            && playedSeconds < result.endIntro,
          );
        }

        if (
          !status.recapPlaying
          && !status.showedCredits
          && result.startCredits
          && !isCredits
        ) {
          // if reached credits
          setIsCredits(playedSeconds >= result.startCredits);
          if (playedSeconds >= result.startCredits) {
            if (status.duration === 0 || status.isTrailer) {
              return;
            }
            endVideoTime(false);
          }
        }

        if (
          status.duration
          && (isSafari && isMobile
            ? document.querySelector("video")?.currentTime
            : data.playedSeconds) >= status.duration
        ) {
          setIsCredits(false);
        }
      } catch {
        setStatus((prevState) => ({
          ...prevState,
          buffering: false,
        }));
      }
    },
    [
      status,
      updateVideoTime,
      endVideoTime,
      result,
      isCredits,
      dispatch,
      socketThreshold,
      breaksStartPoints,
      breaksResult,
      checkForUnseenBreaks,
    ],
  );

  useEffect(() => {
    if (
      isMobile
      && isSafari
      && document.querySelector("video")
      && !showThumbnail
    ) {
      document.querySelector("video").onended = onVideoEnd;
      if (status.playing) {
        if (added === 0) {
          added = 1;
          document.querySelector("video").ontimeupdate = onProgressHandler;
        }
      }
      if (document.getElementsByTagName("video")[0] && !status.playing) {
        added = 0;
        document.querySelector("video").ontimeupdate = null;
      }
    }
  }, [status.playing, onProgressHandler, showThumbnail]);

  useEffect(() => {
    if ((access_token) && (
      isCredits
      && !status.removedCreditsTimer
      && !creditsNextEpisodeTimer.current
    )) {
      creditsNextEpisodeTimer.current = setTimeout(() => {
        runNextEpisode();
      }, constants.videoPlayer.creditsNextEpisode * 1000);
    }
  }, [isCredits, runNextEpisode, status]);

  const onPlayHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  }, [status]);

  const onBackHandler = useCallback(() => {
    try {
      callTrackApiOnBack();
      if (uniqueID) dispatch(clearUserSession(uniqueID));
    } catch { }
    const doesAnyHistoryEntryExist = location.key !== 'default';
    if (doesAnyHistoryEntryExist && shouldGoToHomepageSession === false) {
      navigate(-1);
    } else {
      removeShouldGoToHomepageSession();
      navigate(constants.screens.browse);
    }
  }, [callTrackApiOnBack, dispatch, navigate, removeShouldGoToHomepageSession, shouldGoToHomepageSession, uniqueID]);

  const onVolumeClickHandler = useCallback(
    (volume = null) => {
      try {
        let newVolume = volume;
        if (newVolume === null) {
          newVolume = status.volume ? 0 : 1;
        }
        if (!isWebView) {
          if (isMobile && isSafari && newVolume === 0) {
            videojs("myPlayer_html5_api").player().muted(true);
          } else if (isMobile && isSafari) {
            videojs("myPlayer_html5_api").player().muted(false);
          }
        } else if (isMobile && isSafari && newVolume === 0) {
          videoRef.current.muted = true;
        } else if (isMobile && isSafari) {
          videoRef.current.muted = false;
        }
        if (isIpad() && newVolume === 0) {
          setStatus((prevState) => ({
            ...prevState,
            muted: true,
            volume: 0,
          }));
          return;
        }

        setStatus((prevState) => ({
          ...prevState,
          volume: +newVolume,
          muted: false,
        }));
      } catch { }
    },
    [status.volume, isWebView],
  );

  const onQualityChangeHandler = useCallback((quality) => setStatus((prevState) => ({
    ...prevState,
    selectedQuality: quality,
  })), []);

  const onDurationHandler = useCallback((data) => {
    setStatus((prevState) => ({
      ...prevState,
      duration: data,
    }));
  }, []);

  const onSkipIntroHandler = useCallback(() => {
    if (result.endIntro) {
      seekThePlayer(Math.ceil(result.endIntro));
      setIsIntro(false);
    }
  }, [result, seekThePlayer]);

  const onWatchCreditsHandler = useCallback(() => {
    clearTimeout(creditsNextEpisodeTimer.current);
    setIsCredits(false);
    setStatus((prevState) => ({ ...prevState, showedCredits: true }));
  }, []);

  const onContinueWatching = useCallback(() => {
    setStatus((prevState) => ({ ...prevState, playing: true }));
    setAreYouStillWatching(false);
    resetActivity();
  }, [resetActivity]);

  const onSkipRecapHandler = useCallback(() => {
    setRecapPlayed(!isSafari);
    setStatus((prevState) => ({
      ...prevState,
      recapPlaying: false,
      urlToPlay:
        result?.baseVideoURL
        + (isWebView ? result?.streamLink : result?.links?.fairplay),
      playing: true,
      playerReady: false,
    }));
  }, [result]);

  const onPlayerError = useCallback((e) => {
    if (!isWebView) videojs("myPlayer").pause();

    let wasPlayerReady;
    setStatus((prevState) => {
      wasPlayerReady = prevState.playerReady;
      return { ...prevState, playing: false, playerReady: isFirefox };
    });

    if (!wasPlayerReady && typeof e === "object") {
      if (
        (e.code !== undefined && e.code !== 0)
        || (e.method !== undefined && e.method !== "play")
      ) {
        if (!isWebView) videojs("myPlayer").play();
        return;
      }
    }

    if (!wasPlayerReady && typeof e === "string" && e === "hlsError") {
      setStatus((prevState) => ({ ...prevState, playing: true }));
      return;
    }

    setShowThumbnail(false);
  }, [isWebView]);

  function pausePlayer() {
    setStatus((prevState) => ({ ...prevState, playing: false }));
  }

  const renderAreYouStillWatching = useCallback(() => {
    if (!areYouStillWatching || showThumbnail) {
      return null;
    }

    return (
      <StillWatching onContinue={onContinueWatching} onCancel={onBackHandler} pausePlayer={pausePlayer} />
    );
  }, [areYouStillWatching, showThumbnail, onContinueWatching, onBackHandler]);

  const onKillSessionClick = useCallback(() => {
    if (isKillSession) {
      window.location.reload();
    }
  }, [isKillSession]);

  const renderThumbnail = useCallback(() => {
    if (!showThumbnail && !error && !isKillSession) return null;

    return (
      <ThumbnailLoader
        title={result?.title?.ar || result?.title}
        // thumbnail={result?.medium}
        seasonTitle={result?.seasonTitle}
        episodeNbr={result?.episode}
        episodeTitle={result?.episodeTitle}
        onClose={onBackHandler}
        isKillSession={isKillSession}
        onKillSessionClick={onKillSessionClick}
        epiName={t('globals.theEpisode')}
      />
    );
  }, [
    showThumbnail,
    result,
    onBackHandler,
    error,
    isKillSession,
    onKillSessionClick,
  ]);

  const renderLoader = useCallback(() => {
    if (!status.loading || showThumbnail || error) return null;

    return <VideoPlayerLoader />;
  }, [status.loading, showThumbnail, error]);

  const renderError = useCallback(() => {
    if (!error || error?.response?.status === 404) return null;

    return <SomethingWentWrong />;
  }, [error]);

  useEffect(() => {
    if (isMobile) {
      if (result?.paid && !isSignedIn) {
        setShowPayment(true);
      } else if (result?.paid && isSignedIn && !result?.owned) {
        setShowPayment(true);
      }
    }
  }, [isMobile, result, isSignedIn]);

  if (isKillSession && typeof isKillSession === "boolean") {
    return <>{renderThumbnail()}</>;
  }

  const renderVideoPlayer = () => (
    <VideoPlayer
      ref={videoPlayerRef}
      url={status.urlToPlay}
      playing={status.playing}
      volume={status.volume}
      muted={status.muted}
      onDurationHandler={onDurationHandler}
      onSeekHandler={onPlayerSeekHandler}
      onBuffer={onBufferHandler}
      onBufferEnd={onBufferEndHandler}
      onProgress={onProgressHandler}
      onEnded={onVideoEnd}
      onReady={onReadyHandler}
      onStart={onStartHandler}
      onError={onPlayerError}
    />
  );

  const handlePlayer = () => {
    if (isMobile && isSafari) {
      return (
        <video
          ref={videoRef}
          src={status.urlToPlay}
          controls={!isWebView}
          onLoadedMetadata={onSafariMobileReady}
          onErrorCapture={onVolumeClickHandler}
          id="myPlayer_html5_api"
          preload={isWebView ? "metadata" : "auto"}
          muted
          playsInline
          width="100%"
          autoPlay
          height="100%"
          allowFullScreen
          onError={onVolumeClickHandler}
        />
      );
    }

    return renderVideoPlayer();
  };

  return (
    <ErrorBoundary>
      <>
        {renderError()}
        {renderAreYouStillWatching()}
        {renderThumbnail()}
        {renderLoader()}

        {result?.showId && (
          <PaymentModal
            show={showPayment}
            data={clickedEpisode}
            onClose={
              isSeason
                && ((result.paid && result.owned === false)
                  || result.owned === false)
                ? onIsSeasonClose
                : onPaymentCloseHandler
            }
            showTitle={result?.title}
            sourceTitle={result?.sourceTitle}
            season={paymentSeason}
            type="episode"
            redirectUrl={constants.screens.watch(clickedEpisode?.id || id, window.decodeURI(clickedEpisode?.title)).as}
            onExited={onPaymentTransitionEnd}
          />
        )}
        {result && status.urlToPlay && !isKillSession && (
          <>
            {status.breakActive && !showThumbnail && (
              <AdvertisementPlayer
                result={status.adsToPlay}
                onAdEnd={onAdEnd}
                onBack={onBackHandler}
              />
            )}
            <div
              className={classNames("watch-container", {
                hidePlayer: status.breakActive,
              })}
            >
              <div className="video-player-wrapper">
                <VideoPlayerControls
                  isWebView={isWebView}
                  disableKeys={showThumbnail || areYouStillWatching || status.breakActive}
                  playing={status.playing}
                  title={result.title?.ar || result.title}
                  episodeNbr={result.episode}
                  duration={status.duration}
                  currentTime={status.currentTime}
                  bufferedTime={status.bufferedTime}
                  onPlay={onPlayHandler}
                  onBack={onBackHandler}
                  buffering={status.buffering}
                  onForward={onForwardHandler}
                  onBackward={onBackwardHandler}
                  onSeek={onSeekHandler}
                  onSeekEnd={onSeekEndHandler}
                  volume={status.volume}
                  onVolumeClick={onVolumeClickHandler}
                  quality={result.qualities}
                  selectedQuality={status.selectedQuality}
                  onQualityChange={onQualityChangeHandler}
                  subtitles={subtitles}
                  selectedSub={selectedSubtitle}
                  onSubChange={onSubChangeHandler}
                  episodes={details?.result}
                  season={result.season}
                  seasonTitle={result.seasonTitle}
                  episodeId={status.id}
                  onEpisodeClick={onEpisodeClickHandler}
                  nextEpisode={result.nextEpisode}
                  onNextEpisodeClick={onNextEpisodeClickHandler}
                  seekThePlayer={seekThePlayer}
                  onActivity={resetActivity}
                  tracksLoaded={status.playerReady}
                  rating={result.rating}
                  genres={result.genres}
                  isIntro={isIntro}
                  onSkipIntro={onSkipIntroHandler}
                  isCredits={isCredits && result?.nextEpisode}
                  onWatchCredits={onWatchCreditsHandler}
                  isRecap={status.recapPlaying}
                  onSkipRecap={onSkipRecapHandler}
                  isTrailer={status?.isTrailer}
                  canShowYoureWatching={
                    !status.isTrailer
                    && !showThumbnail
                    && !areYouStillWatching
                    && !isCredits
                    && !showPayment
                    && !status.buffering
                  }
                >
                  {handlePlayer()}
                </VideoPlayerControls>
              </div>
            </div>
          </>
        )}
      </>
    </ErrorBoundary>
  );
}

export default Watch;
