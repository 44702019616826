/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-quotes */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './LiveStreamButton.module.scss';

const SubLiveButton = forwardRef(
  ({ subChannels, handleSingleStreamClick }, ref) => {
    const { language } = useSelector((state) => state.authentication);
    const { t } = useTranslation();
    const [showSub, setShowSub] = useState(false);

    const handleShowSub = (value) => () => {
      setShowSub(value);
    };

    useImperativeHandle(ref, () => ({
      showSubHandler: (value) => {
        setShowSub(value);
      },
    }));


    return (
      <Dropdown
        className={`${language === 'en' ? styles.streamDropEngSub : styles.streamDropSub} `}
        show={showSub}
        onClick={handleShowSub(true)}
      >
        <Dropdown.Toggle
          aria-label='liveStreamDropDown'
          variant='none'
          className={styles.streamDropdownBasicSub}
          style={language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }}
        >
        <span className={styles.dropdownText}>
          {`${t('globals.liveStream')}`}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu align={'start'}
          className={`${language === 'ar'
            ? styles.streamDropdownMenuBasicSub
            : styles.streamDropdownMenuBasicSubEng
          } ${subChannels.length > 10 ? styles.scrollableDropdown : ''}`}
          onMouseLeave={handleShowSub(false)}
        >
          {subChannels.map((channel, index) => (
            <React.Fragment key={channel?._id}>
              <Dropdown.Item className={styles.videoChannel} onClick={handleSingleStreamClick(channel)}>
                {channel?.title}
              </Dropdown.Item>
              {subChannels.length - 1 === index ? null : <Dropdown.Divider  />}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export default SubLiveButton;
