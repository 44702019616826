const GET_BREAKS = 'GET_BREAKS';
const GET_BREAKS_SUCCESS = 'GET_BREAKS_SUCCESS';
const GET_BREAKS_FAIL = 'GET_BREAKS_FAIL';
const CLEAR_BREAKS = 'CLEAR_BREAKS';

export {
  GET_BREAKS,
  GET_BREAKS_SUCCESS,
  GET_BREAKS_FAIL,
  CLEAR_BREAKS,
};
