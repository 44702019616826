import React from 'react';
import PropTypes from 'prop-types';
import placeholder from '../../../Assets/Images/placeholders/2x3.svg';

const MovieCardPortrait = ({
  thumbnail,
  renderPlayer,
  isHovered,
  children,
}) => (
  <div className="aspect-ratio-2x3">
    <div className="card-content shadow-sm">
      <div className={`top-card ${isHovered ? 'active' : ''}`}>
        <img
          className="movie-card-img"
          src={thumbnail}
          alt=""
          onError={(e) => {
            e.target.src = placeholder;
            e.target.style.objectFit = 'cover';
          }}
        />

        {renderPlayer()}
      </div>

      {children}
    </div>
  </div>
);

MovieCardPortrait.propTypes = {
  thumbnail: PropTypes.string,
  renderPlayer: PropTypes.func,
  isHovered: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

MovieCardPortrait.defaultProps = {
  thumbnail: '',
  renderPlayer: () => null,
};

export default MovieCardPortrait;
