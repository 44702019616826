import { CLEAR_GENRES, GET_GENRES } from '../Constants/GenresConstants';

const getGenres = () => ({
  type: GET_GENRES,
  payload: {
    request: {
      url: 'api/video/genres',
      method: 'get',
    },
  },
});

const clearGenres = () => ({
  type: CLEAR_GENRES,
  payload: null,
});

export { getGenres, clearGenres };
