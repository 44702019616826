import {
  GET_EPISODES_PER_SEASON,
  CLEAR_EPISODES_PER_SEASON,
  GET_EPISODES_PER_SEASON_SUCCESS,
  GET_EPISODES_PER_SEASON_FAIL,
} from '../Constants/EpisodesConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const episodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EPISODES_PER_SEASON: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EPISODES_PER_SEASON_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_EPISODES_PER_SEASON_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        result: null,
      };
    }
    case CLEAR_EPISODES_PER_SEASON: {
      return initialState;
    }
    default:
      return state;
  }
};

export default episodesReducer;
