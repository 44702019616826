import { useEffect } from "react";
import AutoRenew from "@Atoms/autoRenew/AutoRenew";
import Footer from "@Atoms/Footer";
import Navigation, { NavbarPosition } from "@Organisms/Navigation/Navigation";
import RadioTab from "@Organisms/RadioTab/RadioTab";
import { getVideoSettings, clearVideoSettings } from "@Store/Actions/VideoSettingsActions";
import { getUserProfile } from "@Store/Actions/UserActions";
import { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@Store/hooks";
import useAccountWarningOnPaymentAttempt from "@Hooks/useAccountWarningOnPaymentAttempt";
import useHandlePaymentRedirection from "@Hooks/useHandlePaymentRedirection";
import { Outlet, ScrollRestoration } from "react-router-dom";

interface IDefaultLayoutProps {
    shouldDisableFooter?: boolean;
    navbarPosition?: NavbarPosition;
    navigationClassName?: string;
}

const DefaultLayout: React.FC<PropsWithChildren<IDefaultLayoutProps>> = ({ children, shouldDisableFooter, navbarPosition, navigationClassName }) => {
    //TODO: Figure out if Radio Tab is needed, must have been used for audio stream, but Ballam currently does not have audio stream
    const { activeRadio } = useAppSelector((state) => state.Stream);
    const { profile: userProfile } = useAppSelector((state) => state.user);
    const { isHiddenPaymentAlert } = useAppSelector((state) => state.ShowPaymentAlert);
    const { isSignedIn, loading, profile } = useAppSelector((state) => state.authentication);
    //@ts-expect-error need to implement types for this object
    const shouldRenderAutoRenew = userProfile?.showPaymentAlert && !isHiddenPaymentAlert && !loading && isSignedIn && profile;
    const dispatch = useDispatch();

    useAccountWarningOnPaymentAttempt();
    useHandlePaymentRedirection();
    useEffect(() => {
        dispatch(getVideoSettings());

        return () => {
            dispatch(clearVideoSettings());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!isSignedIn) return;


        dispatch(getUserProfile());
    }, [dispatch, isSignedIn]);

    return (
        <div className="route-container" id="route-content">
            <div className="route-content">
                <Navigation className={navigationClassName} navbarPosition={navbarPosition} />
                {children || <Outlet />}
                {activeRadio && <RadioTab />}
                {shouldRenderAutoRenew && <AutoRenew />}
                {!shouldDisableFooter && <Footer />}
                <ScrollRestoration />
            </div>
        </div>
    );
}

export default DefaultLayout;