import { useState, Fragment, Dispatch, SetStateAction, useEffect } from 'react';
import styles from './Hero.module.scss';
import placeholder from '@Assets/Images/placeholders/16x9.svg';
import { IBasicPlayer } from './Hero.types';
import { IMainSliderTrailer } from '@Utils/commonTypes';
import BackgroundPlayer from '@Atoms/BackgroundPlayer/BackgroundPlayer';
import useDisablePlayerWhenOffViewPort from '@Hooks/useDisablePlayerWhenOffViewPort';
import constants from '@Utils/constants';

export const playerInitialState: IBasicPlayer = {
    IsVisible: true,
    isPlaying: false,
    hasEnded: false,
    hasLoaded: false,
    isMuted: true,
    hasPlayedOnce: false,
};

export interface IHeroProps {
    playing: boolean;
    image: string;
    trailer?: IMainSliderTrailer;
    children?: (props: { setPlayerStatus: Dispatch<SetStateAction<IBasicPlayer>>; playerStatus: IBasicPlayer }) => React.ReactNode;
}



const Hero: React.FC<IHeroProps> = ({ playing = false, image, trailer, children }) => {
    const [playerStatus, setPlayerStatus] = useState<IBasicPlayer>(playerInitialState);
    const containerRef = useDisablePlayerWhenOffViewPort(playerStatus, setPlayerStatus);
    const shouldRenderPlayer = playing === true && playerStatus.IsVisible === true && trailer?.link !== undefined;

    useEffect(() => {
        if (shouldRenderPlayer && playerStatus.hasLoaded === true) {
            setPlayerStatus((prev) => ({ ...prev, hasPlayedOnce: true }));
        }
    }, [playerStatus?.hasLoaded, shouldRenderPlayer]);
    const delayPlayer = () => setTimeout(() => setPlayerStatus((prevState) => ({ ...prevState, hasLoaded: true, isPlaying: true })), constants.delayToReplayTrailer);

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.trailer}>
                <div className={styles.aspectRatioBox}>
                    <div className={styles.gradient} />
                    <div className={`${playerStatus.hasLoaded ? 'd-none' : 'h-100'}`}>
                        <img
                            src={image || placeholder}
                            alt="Trailer Thumbnail"
                            className={styles.trailerThumbnail}
                        />
                    </div>
                    {shouldRenderPlayer && <BackgroundPlayer url={trailer?.link} updatePlayerStatus={setPlayerStatus} playerOverrides={{ onReady: delayPlayer }}  {...playerStatus} />}
                </div>
            </div>

            {children ? children({ setPlayerStatus, playerStatus }) : <Fragment />}
        </div>
    );
};

export default Hero;