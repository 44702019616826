import {
  GET_TOP_SEARCHES,
  CLEAR_TOP_SEARCHES,
  GET_TOP_SEARCHES_FAIL,
  GET_TOP_SEARCHES_SUCCESS,
} from '../Constants/TopSearchesConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const topSearchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_SEARCHES: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_TOP_SEARCHES_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_TOP_SEARCHES_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CLEAR_TOP_SEARCHES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default topSearchesReducer;
