const SUBSCRIPTION_PACKAGES = 'SUBSCRIPTION_PACKAGES';
const SUBSCRIPTION_PACKAGES_FAIL = 'SUBSCRIPTION_PACKAGES_FAIL';
const SUBSCRIPTION_PACKAGES_SUCCESS = 'SUBSCRIPTION_PACKAGES_SUCCESS';
const CLEAR_SUBSCRIPTION_PACKAGES = 'CLEAR_SUBSCRIPTION_PACKAGES';
const GET_PACKAGE_SENTENCE = 'GET_PACKAGE_SENTENCE';
const GET_PACKAGE_SENTENCE_SUCCESS = 'GET_PACKAGE_SENTENCE_SUCCESS';

export {
  SUBSCRIPTION_PACKAGES,
  SUBSCRIPTION_PACKAGES_FAIL,
  SUBSCRIPTION_PACKAGES_SUCCESS,
  CLEAR_SUBSCRIPTION_PACKAGES,
  GET_PACKAGE_SENTENCE,
  GET_PACKAGE_SENTENCE_SUCCESS,
};
