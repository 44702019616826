import {
  VALIDATE_COUPON,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  CLEAR_COUPON,
  VERIFY_COUPON,
  VERIFY_COUPON_SUCCESS,
  VERIFY_COUPON_FAIL,
  SET_COUPON_NAME,
} from "../Constants/CouponConstants";

const initialState = {
  loading: false,
  result: null,
  error: null,
  verificationResult: null,
  verificationError: null,
  discountedPrice: null,
  coupon: null,
};

const CouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_COUPON: {
      return {
        ...state,
        verificationError: null,
        loading: true,
        verificationResult: null,
      };
    }
    case VERIFY_COUPON_SUCCESS: {
      const { result } = action.payload.data.data;
      return {
        ...state,
        loading: false,
        verificationResult: result,
      };
    }
    case VERIFY_COUPON_FAIL: {
      return {
        ...state,
        loading: false,
        verificationError: action.error?.response?.data?.data?.message,
      };
    }
    case VALIDATE_COUPON: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case VALIDATE_COUPON_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case VALIDATE_COUPON_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error?.response?.data?.data?.message,
      };
    }
    case CLEAR_COUPON: {
      return {
        ...initialState,
        coupon: null, 
        verificationResult: null
      };
    }
    case SET_COUPON_NAME:
      return {
        ...state,
        coupon: action.payload,
      };
    default:
      return state;
  }
};

export default CouponReducer;
