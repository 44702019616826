import { useNavigate, useOutletContext } from "react-router-dom";
import styles from './DetailsSection.module.scss';
import { IIndividual, isMovie } from "@Utils/commonTypes";
import LineSeparator from "@Atoms/LineSeparator/LineSeparator";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { IMediaDetailsContext } from "@Molecules/EpisodesRail/DesktopLayout/DesktopLayout";
import parse from 'html-react-parser';
import constants from "@Utils/constants";


const prepareInformation = (
    description: string,
    genres: Array<IIndividual>,
    awards: Array<IIndividual>,
    directors: Array<IIndividual>,
    crew: Array<IIndividual>,
    hosts: Array<IIndividual>,
    authors: Array<IIndividual>,
    t: TFunction
) => {
    const out = [];

    if (description.length >= 1) {
        out.push({ name: t('details.description'), value: [description] });
    }

    const array = [
        { key: 'alGenres', value: genres },
        { key: 'alAwards', value: awards },
        { key: 'alDirectors', value: directors },
        { key: 'alStars', value: crew },
        { key: 'alHosts', value: hosts },
        { key: 'alAuthors', value: authors },
    ];

    try {
        for (const element of array) {
            if (element.value.length >= 1) {
                if (element.key) {
                    out.push({
                        name: t(`details.${element.key}`), value: element.value.map((item) => ({
                            name: item.name,
                            id: item.id,
                        }))
                    })
                } else {
                    out.push({ name: t(`details.${element.key}`), value: element.value.map((item) => item.name) })
                }
            }
        }
    } catch (e) {
        console.error('[prepareInformation] Error:', e);
        return [];
    }

    return out;
}

const DetailsSection: React.FC = () => {
    const context = useOutletContext<IMediaDetailsContext>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    let informationList = [];

    const media = context?.media;
    const currentSeason = context?.currentSeason;
    if (isMovie(media)) {
        informationList = prepareInformation(
            media?.description ?? '',
            media?.genres ?? [],
            // @ts-expect-error issue with type Movie
            media?.awards ?? [],
            media?.directors ?? currentSeason?.directors ?? [],
            media?.crew ?? currentSeason?.crew ?? [],
            media?.hosts ?? currentSeason?.hosts ?? [],
            media?.authors ?? currentSeason?.authors ?? [],
            t
        )
    } else {
        informationList = prepareInformation(
            media?.description ?? '',
            media?.genres ?? [],
            media?.awards ?? [],
            currentSeason?.directors ?? [],
            currentSeason?.crew ?? [],
            currentSeason?.hosts ?? [],
            currentSeason?.authors ?? [],
            t
        )
    }

    const handleInfoClick = (name: string, id: number) => {
        switch (name) {
            case t('details.alGenres'):
                navigate(constants.screens.genre(id).as);
                break;
            case t('details.alAwards'):
                navigate(constants.screens.awards(id).as);
                break;
            case t('details.alStars'):
            case t('details.alHosts'):
            case t('details.alAuthors'):
            case t('details.alDirectors'):
                navigate(constants.screens.actor(id).as);
                break;
            default:
                break;
        }
    };


    if (informationList.length === 0) return <Fragment />;
    return (
        <div className={styles.root}>
            {informationList.map((info, index) => (
                <Fragment key={index}>
                    {info.value.length >= 1 &&
                        (<div className={styles.informationGroup}>
                            <div>
                                <span className={styles.sectionName}>{info.name}</span>
                                <div className={info.name !== t('details.description') ? styles.sectionContent : ''}>
                                    {info.value.map((item, index) => {
                                        if (typeof item === 'string') {
                                            return (
                                                <div className={styles.descriptionText}>
                                                    <span key={index}>
                                                        {info.name !== t('details.description') ? item : parse(item)}
                                                    </span>
                                                </div>
                                            );
                                        } else if ('name' in item && 'id' in item) {
                                            return (
                                                <span
                                                    key={index}
                                                    onClick={() => handleInfoClick(info.name, item.id)}
                                                    className={styles.pointer}
                                                >
                                                    {item.name}
                                                </span>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                            {index !== informationList.length - 1 && <LineSeparator className={styles.separator} />}
                        </div>)
                    }
                </Fragment>
            ))}
        </div>
    )
}

export default DetailsSection
