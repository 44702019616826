import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TextSeparator.module.scss';

const TextSeparator = ({ text, color }) => (
  <div className={styles.root}>
    <div className={classNames(styles.lineSeparator, "flex-grow-1")} />
    <div className={classNames(styles.separatorText, "flex-grow-2")} style={{ color }}>{text}</div>
    <div className={classNames(styles.lineSeparator, "flex-grow-1")} />
  </div>
);

TextSeparator.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

TextSeparator.defaultProps = {
  color: 'white',
};

export default TextSeparator;
