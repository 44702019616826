import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@Store/hooks';
import constants from '../Utils/constants';
import AboutUs from '../Pages/AboutUs';
import ContactUs from '../Pages/ContactUs';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import TermsAndConditions from '../Pages/TermsAndConditions';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import PrivacyPolicyKorean from '../Pages/PrivacyPolicyKorean';
import Shows from '../Pages/Shows';
import Series from '../Pages/Series';
import Videos from '../Pages/Videos';
import Search from '../Pages/Search/Search';
import NotFound from '../Pages/NotFound/NotFound';
import Genre from '../Pages/Genre/index';
import Category from '../Pages/Category/index';
import SubscribePackages from '../Pages/SubscribePackages/SubscribePackages';
import Actors from '../Pages/Actors/Actors';
import { getUserProfile } from '../Store/Actions/UserActions';
import useScrollToTop from '../Hooks/useScrollToTop';
import Awards from '../Pages/Awards/Awards';
import ComingSoon from '../Pages/ComingSoon';
import Packages from '../Pages/Packages/Packages';
import BookmarksRouter from './BookmarksRouter';
import LoginRouter from './LoginRouter';
import SignUpRouters from './SignUpRouters';
import PaymentRedirectionRouter from './PaymentRedirectionRouter';
import MyPurchasesRouter from './MyPurchasesRouter';
import ChoosePaymentRouter from './ChoosePaymentRouter';
import SomethingWentWrong from '../Pages/SomethingWentWrong/SomethingWentWrong';
import DefaultLayout from '@Templates/DefaultLayout';
import useFlipPageBasedOnLanguage from '@Hooks/useFlipPageBasedOnLanguage';
import Profile from '@Pages/Profile/Profile';

function LegacyRoutes() {
  const { isSignedIn } = useAppSelector((state) => state.authentication);
  const { selected } = useAppSelector((state) => state.videoSubscription);
  const dispatch = useDispatch();
  useFlipPageBasedOnLanguage();
  useScrollToTop();

  useEffect(() => {
    if (isSignedIn) {
      dispatch(getUserProfile());
    }
  }, [dispatch, isSignedIn]);

  return (
    <div className="route-container" id="route-content">
      <div className="route-content">
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path={constants.screens.aboutUs} element={<AboutUs />} />
            <Route path={constants.screens.contactUs} element={<ContactUs />} />
            <Route path={constants.screens.termsAndConditions} element={<TermsAndConditions />} />
            <Route path={constants.screens.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={constants.screens.privacyPolicyKorean} element={<PrivacyPolicyKorean />} />
            <Route path={constants.screens.packages} element={<Packages />} />
            <Route path={constants.screens.shows} element={<Shows />} />
            <Route path={constants.screens.series} element={<Series />} />
            <Route path={constants.screens.movies} element={<Videos />} />
            <Route path={constants.screens.search} element={<Search />} />
            <Route path={constants.screens.myPurchases} element={<MyPurchasesRouter />} />
            <Route path={constants.screens.bookmarks} element={<BookmarksRouter />} />
            <Route path={constants.screens.choosePayment} element={<ChoosePaymentRouter isSignedIn={isSignedIn} selected={selected} />} />
            <Route path={constants.screens.paymentRedirection} element={<PaymentRedirectionRouter />} />
            <Route path={constants.screens.subscribeAll} element={<SubscribePackages />} />
            <Route path={constants.screens.profile} element={<Profile />} />
            <Route path={constants.screens.actor().href} element={<Actors />} />
            <Route path={constants.screens.awards().href} element={<Awards />} />
            <Route path={constants.screens.genre().href} element={<Genre />} />
            <Route path={constants.screens.category().href} element={<Category />} />
            <Route path={constants.screens.comingSoon} element={<ComingSoon />} />
            <Route path={constants.screens.notFound} element={<NotFound />} />
            <Route path={constants.screens.somethingWentWrong} element={<SomethingWentWrong destination={undefined} />} />
            <Route path='*' element={<NotFound />} />
          </Route>
          {/* <Route path={constants.screens.chromeCast} onEnter={() => window.location.reload()} /> */}
          <Route path={constants.screens.login} element={<LoginRouter />} />
          <Route path={constants.screens.signUp} element={<SignUpRouters isSignedIn={isSignedIn} />} />
          <Route path={constants.screens.forgotPassword} element={<ForgotPassword />} />
          <Route path={constants.screens.resetPassword} element={<ResetPassword />} />
        </Routes>
      </div>
    </div>
  );
}

export default LegacyRoutes;