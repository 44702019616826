import React from "react";
import PropTypes from "prop-types";
import NotFound from "../Pages/NotFound/NotFound";
import SomethingWentWrong from "../Pages/SomethingWentWrong/SomethingWentWrong";

const ErrorComponent = ({ error, pageType, destination }) => {
  if (error?.response?.status === 404) {
    return <NotFound pageType={pageType} />;
  }

  if (error?.response?.status !== 401) {
    return <SomethingWentWrong pageType={pageType} destination={destination} />;
  }
  return null;
};

ErrorComponent.propTypes = {
  error: PropTypes.object,
  pageType: PropTypes.string,
  destination: PropTypes.string
};

export default ErrorComponent;
