import React from "react";
import PropTypes from "prop-types";
import { Row, Image } from "react-bootstrap";
// import classNames from "classnames";
import AppleLogin from "react-apple-login";
import SocialMediaLoginButton from "../../Atoms/SocialMediaLoginButton";
// import Facebook from "../../../Assets/Images/social-icons-facebook-alballam.svg";
import Google from "../../../Assets/Images/google-icon.svg";
import Apple from "../../../Assets/Images/apple-icon.svg";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const SocialMediaLogin = ({
  onGoogleLogin,
  onGoogleFailure,
  // onFacebookLogin,
  // onFacebookFailure,
  // facebookAppId,
  googleAppId,
  appleAppId,
  appleAppRedirectUri,
  onAppleLogin,
  // ColNumber,
}) => {
  const { t } = useTranslation();
  return (
    <Row className="w-100 m-0 p-0 gap-2">
      {/* <SocialMediaLoginButton
        provider="facebook"
        appId={facebookAppId}
        onLoginSuccess={onFacebookLogin}
        onLoginFailure={onFacebookFailure}
      >
        <div className={styles.socialMediaLogin}>
          <div>
            <Image src={Facebook} alt="" width={24} height={24} />
          </div>
          {t("globals.loginWithFacebook")}
        </div>
      </SocialMediaLoginButton> */}
      <SocialMediaLoginButton
        provider="google"
        appId={googleAppId}
        onLoginSuccess={onGoogleLogin}
        onLoginFailure={onGoogleFailure}
      >
        <div className={styles.socialMediaLogin}>
          <div>
            <Image src={Google} alt="" />
          </div>
          {t("globals.loginWithGoogle")}
        </div>
      </SocialMediaLoginButton>
      <AppleLogin
        clientId={appleAppId}
        usePopup
        redirectURI={appleAppRedirectUri}
        callback={onAppleLogin} // Catch the response
        responseMode="query"
        scope="name email"
        render={(
          renderProps // Custom Apple Sign in Button
        ) => (
          <div
            className={styles.socialMediaLogin}
            onClick={renderProps.onClick}
          >
            <img src={Apple} alt="apple" />
            {t("globals.loginWithApple")}
          </div>
        )}
      />
    </Row>
  );
};

SocialMediaLogin.propTypes = {
  onGoogleLogin: PropTypes.func.isRequired,
  onGoogleFailure: PropTypes.func.isRequired,
  onFacebookLogin: PropTypes.func.isRequired,
  onFacebookFailure: PropTypes.func.isRequired,
  facebookAppId: PropTypes.string.isRequired,
  googleAppId: PropTypes.string.isRequired,
  ColNumber: PropTypes.number,
};

SocialMediaLogin.defaultProps = {
  ColNumber: 2,
};

const MemoizedSocialMediaLogin = React.memo(SocialMediaLogin);
export default MemoizedSocialMediaLogin;
