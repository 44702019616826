const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY';
const GET_SEARCH_HISTORY_FAIL = 'GET_SEARCH_HISTORY_FAIL';
const GET_SEARCH_HISTORY_SUCCESS = 'GET_SEARCH_HISTORY_SUCCESS';
const ADD_SEARCH_HISTORY = 'ADD_SEARCH_HISTORY';
const ADD_SEARCH_HISTORY_SUCCESS = 'ADD_SEARCH_HISTORY_SUCCESS';
const ADD_SEARCH_HISTORY_FAIL = 'ADD_SEARCH_HISTORY_FAIL';
const DELETE_SEARCH_HISTORY = 'DELETE_SEARCH_HISTORY';
const DELETE_SEARCH_HISTORY_SUCCESS = 'DELETE_SEARCH_HISTORY_SUCCESS';
const DELETE_SEARCH_HISTORY_FAIL = 'DELETE_SEARCH_HISTORY_FAIL';

export {
  GET_SEARCH_HISTORY,
  GET_SEARCH_HISTORY_FAIL,
  GET_SEARCH_HISTORY_SUCCESS,
  ADD_SEARCH_HISTORY,
  ADD_SEARCH_HISTORY_SUCCESS,
  ADD_SEARCH_HISTORY_FAIL,
  DELETE_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY_SUCCESS,
  DELETE_SEARCH_HISTORY_FAIL,
};
