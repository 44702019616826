import { combineReducers } from 'redux';
import authenticationReducer from './AuthenticationReducer';
import messageReducer from './MessageReducer';
import ForgotPasswordReducer from './ForgotPasswordReducer';
import ResetPasswordReducer from './ResetPasswordReducer';
import StaticScreenReducer from './StaticScreenReducer';
import VideoSettingsReducer from './VideoSettingsReducer';
import VideoDetailsReducer from './VideoDetailsReducer';
import VideosDashboardReducer from './VideosDashboardReducer';
import LiveChannelReducer from './LiveChannelReducer';
import UserReducer from './UserReducer';
import BookmarkReducer from './BookmarkReducer';
import moviesReducer from './Movies';
import contactUsReducer from './ContactUsReducer';
import showsReducer from './Shows';
import genresReducer from './GenresReducer';
import GlobalSearchReducer from './GlobalSearchReducer';
import LandingVideosReducer from './LandingVideosReducer';
import FilteredLandingVideosReducer from './FilteredLandingVideosReducer';
import HoveredCardReducer from './HoveredCardReducer';
import NotificationsReducer from './NotificationsReducer';
import MovieReducer from './MovieReducer';
import seasonsReducer from './SeasonsPerShowReducer';
import episodesReducer from './EpisodesReducers';
import VideoSubscriptionReducer from './VideoSubscriptionReducer';
import SubscriptionValidationReducer from './SubscriptionValidationReducer';
import SubscriptionPackagesReducer from './SubscriptionPackagesReducer';
import genreTypeReducer from './GetGenreTypeReducer';
import UserPurchasesReducer from './UserPurchasesReducer';
import CastAndCrewReducer from './CastAndCrewReducer';
import ProfilesPerUserReducer from './ProfilesPerUserReducer';
import authProfileReducer from './AuthProfileReducer';
import searchHistoryReducer from './SearchHistoryReducer';
import AvatarReducer from './AvatarsReducer';
import mutedHeaderReducer from './MutedHeaderReducer';
import subscriptionReducer from './SubscriptionReducer';
import subscriptionSourceReducer from './subscriptionsSourceReducer';
import ShowPaymentReducer from './ShowPaymentReducer';
import drmTokenReducer from './DRMTokenReducer';
import userSessionReducer from './UserSessionReducer';
import StreamReducer from './StreamReducer';
import BreaksReducer from './BreaksReducer';
import AdvertisementAnalyticsReducer from './AdvertisementAnalyticsReducer';
import CouponReducer from './CouponReducer';
import ComingSoonReducer from './ComingSoonReducer';
import LiveChannelsReducer from './LiveChannelsReducer';
import selectedSubtitleReducer from './SelectedSubtitleReducer';
import MyPurchasesReducer from './MyPurchasesReducer';
import TopSearchesReducer from './TopSearchesReducer';
import PlayerStatusReducer from './PlayerStatusReducer';
import MarketingPopupReducer from './MarketingPopupReducer';
import PaymentModalReducer from './PaymentModalReducer';

export default combineReducers({
  authentication: authenticationReducer,
  message: messageReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  staticScreens: StaticScreenReducer,
  videoSettings: VideoSettingsReducer,
  videoDetails: VideoDetailsReducer,
  liveDetails: LiveChannelReducer,
  videosDashboard: VideosDashboardReducer,
  user: UserReducer,
  bookmarks: BookmarkReducer,
  shows: showsReducer,
  movies: moviesReducer,
  genres: genresReducer,
  contactUs: contactUsReducer,
  searchData: GlobalSearchReducer,
  LandingVideos: LandingVideosReducer,
  FilteredLandingVideos: FilteredLandingVideosReducer,
  hoveredCard: HoveredCardReducer,
  notifications: NotificationsReducer,
  movie: MovieReducer,
  seasons: seasonsReducer,
  episodes: episodesReducer,
  videoSubscription: VideoSubscriptionReducer,
  subscriptionValidation: SubscriptionValidationReducer,
  subscriptionPackages: SubscriptionPackagesReducer,
  genreType: genreTypeReducer,
  userPurchases: UserPurchasesReducer,
  castAndCrew: CastAndCrewReducer,
  ProfilesPerUser: ProfilesPerUserReducer,
  saveAuth: authProfileReducer,
  searchHistory: searchHistoryReducer,
  avatar: AvatarReducer,
  mutedHeader: mutedHeaderReducer,
  subscription: subscriptionReducer,
  subscriptionSource: subscriptionSourceReducer,
  ShowPaymentAlert: ShowPaymentReducer,
  drmToken: drmTokenReducer,
  userSession: userSessionReducer,
  Stream: StreamReducer,
  Breaks: BreaksReducer,
  adAnalytics: AdvertisementAnalyticsReducer,
  couponReducer: CouponReducer,
  comingSoon: ComingSoonReducer,
  liveChannels: LiveChannelsReducer,
  selectedSubtitle: selectedSubtitleReducer,
  myPurchases: MyPurchasesReducer,
  topSearches: TopSearchesReducer,
  playerStatus: PlayerStatusReducer,
  marketingPopup: MarketingPopupReducer,
  paymentModal: PaymentModalReducer,
});
