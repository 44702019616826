import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
// import parse from 'html-react-parser';
import StaticLayout from '../../Components/Organisms/StaticLayout';
import { getStatic } from '../../Store/Actions/StaticScreenActions';
import reduxConstants from '../../Store/constants';
import './index.scss';
import ErrorComponent from '../../HOC/ErrorComponent';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { result, loading, error } = useSelector(
    (state) => state.staticScreens.privacyPolicy,
  );

  useEffect(() => {
    dispatch(getStatic(reduxConstants.staticApis.privacyPolicy));
  }, [dispatch]);

  const renderContent = useCallback(() => {
    try {
      if (!result) {
        return null;
      }
      // eslint-disable-next-line no-empty
    } catch {}
    return (
      <>
        <p className="termsTitle">개인 정보 정책</p>
        <p className="pt-3 text">AlBallam에서는 개인 정보 보호에 대한 귀하의 관심과 귀하와의 관계 가치를 존중하므로 다음을 포함하는 개인 정보 보호 정책을 만들었습니다.

이 개인 정보 보호 정책은 우리가 고객에 대해 수집하는 개인 정보의 유형, 정보 사용 방법, 공유 대상 및 정보 사용과 관련하여 고객이 선택할 수 있는 사항에 대해 설명합니다.

우리는 또한 정보 보안을 보호하기 위해 취하는 조치와 우리 자신의 행동과 관련하여 고객이 우리와 소통하는 방법에 대해서도 설명합니다.

당사에 귀하의 개인 정보를 제공함으로써 귀하는 개인 정보 보호 정책에 동의하고 당사가 개인 정보 보호 정책에 따라 개인 정보를 수집, 사용 및 공개할 수 있다는 데 동의합니다. 귀하는 당사가 귀하에게 제공하도록 요청한 정보를 제공할 의무가 없습니다. 그러나 당사가 귀하에게 요청한 정보를 제공하지 않으면 그러한 정보 수집을 기반으로 상품이나 서비스를 제공하지 못할 수 있습니다.

이 개인정보 보호정책에 동의하지 않거나 웹사이트 사용이 금지된 국가 또는 지역에서 웹사이트를 방문하는 경우 즉시 웹사이트를 종료하십시오.

화면을 방문할 때마다 브라우저에서 개인 서버 로그 파일로 정보가 전송되어 저장됩니다. 정보에는 사이트 방문 시간 및 날짜, 귀하가 본 페이지, IP 주소, 귀하를 com으로 연결한 URL, 저장된 정보 및 사용된 브라우저 유형이 포함됩니다.

이 모든 정보를 수집하면 모든 IP 주소가 삭제되거나 익명 주소로 변환됩니다. 주소 익명화는 원래 사용자와 연결할 수 없거나 광범위한 조사를 통해서만 연결할 수 있도록 IP 주소를 변경하는 것을 의미합니다.

서버 로그 파일에 저장된 익명 정보는 AlBallam 플랫폼에서 시리즈 및 프로그램 시청 경험을 개선하여 사이트를 더 쉽게 탐색하고 오류를 빠르게 찾고 필요에 따라 개발을 위한 서버 기능을 지시하는 데 사용됩니다. 예를 들어, 이 정보를 통해 트래픽의 피크 시간을 계산할 수 있으므로 사이트를 최대한 빠르게 실행하기에 충분한 서버 용량을 추정할 계획을 세울 수 있습니다.

이 사이트에 등록하는 과정에서 이름, 배송 주소, 이메일 주소, 전화 번호 및 기타 유사한 정보와 같은 특정 개인 정보와 날짜와 같은 추가 정보를 제공해야 합니다. 출생 또는 기타 개인 정보. 입력한 정보는 데이터베이스 양식에 저장됩니다.

또한 당사는 당사가 보유한 정보의 무단 액세스, 무단 변경, 공개 또는 취소로부터 사용자 정보를 보호할 권리가 있습니다.

당사는 귀하의 개인 정보를 보유하는 동안 귀하의 개인 정보가 최신 상태인지 확인하기 위해 귀하에게 연락할 수 있습니다. 우리는 귀하가 완전한 개인 데이터를 제공할 것을 권장하며 당사가 가능한 최상의 서비스를 제공할 수 있도록 정확하지 않거나 오래된 세부 정보가 있는 경우 언제든지 당사에 알려줄 수 있습니다.

우리는 SSL을 사용하여 많은 서비스를 암호화하고 신용 카드 및/또는 은행 계좌 번호와 같은 금융 정보는 SSL 프로토콜을 사용하여 전송되어 전송되기 전에 암호화됩니다. 우리는 신용 카드 정보를 저장하지 않지만 암호화된 연결을 통해 처리할 수 있도록 해당 금융 기관에 직접 제공합니다.

때때로 우리는 귀하가 관심을 가질 것으로 생각되는 판촉, 마케팅 정보 또는 이벤트와 관련된 정보를 보낼 수 있습니다. 원하는 경우 언제든지 이 서비스를 중지할 수 있는 옵션이 있으며 당사에서 보내는 이메일에 있는 구독 취소 링크를 클릭하여 언제든지 마케팅 목록에서 구독을 취소할 수 있습니다. 당사는 귀하의 이메일 주소를 제3자에게 절대 배포하지 않으며 당사가 선택하는 경우 귀하가 당사에 보내는 이메일의 내용을 유지할 수 있습니다.

귀하가 당사를 위해 자원한 모든 정보는 제3자에게 공개되지 않으며 당사의 고객 서비스 목적으로만 사용됩니다. 고객으로서 우리는 보안, 신뢰성 및 기밀성을 보장합니다.

귀하는 이메일로 당사가 보유한 귀하의 개인 데이터 사본을 요청할 수 있습니다. 귀하의 보호를 위해 정보를 제공하기 전에 귀하의 신원을 확인하도록 요청할 수 있으며, 신원이 만족스럽게 제공되지 않을 경우 요청한 정보 제공을 거부할 권리가 있습니다.

당사는 이 개인정보 보호정책을 언제든지 변경할 수 있는 권리를 보유합니다. 당사는 귀하의 명시적 동의 없이 본 개인정보 보호정책에 따른 귀하의 권리를 축소하지 않습니다. 개인 정보 보호 정책에 대한 변경 사항을 이 페이지에 게시하고 변경 사항이 중요한 경우 더 명확한 통지(개인 정보 보호 정책 변경에 대한 이메일 알림 포함)를 제공할 것입니다. 우리는 또한 귀하가 참조할 수 있도록 이 개인정보 보호정책의 이전 버전을 아카이브에 보관할 것입니다. 개인 정보 보호 정책의 변경 사항을 알리기 위해 사이트를 정기적으로 검토하는 것이 좋습니다.

귀하가 당사 또는 당사 대리인에게 데이터를 제출하거나 사이트를 사용할 때 귀하는 본 개인정보 보호정책에 명시된 대로 필요한 절차를 위해 해당 데이터를 당사에서 보유, 공유 및 사용하는 데 동의하는 것입니다.

본인은 AlBallam 플랫폼의 성능을 개발하고 개선하기 위한 프로토콜 데이터의 분석 및 사용에 동의합니다.

귀하의 데이터가 우려되는 경우 귀하는 개인 데이터의 오류를 수정하도록 당사에 요청할 권리가 있으며 이는 무료입니다. 귀하는 언제든지 당사에 직접 마케팅 목적으로 귀하의 개인 데이터 사용을 중지하도록 요청할 권리가 있습니다.

개인 정보 보호 정책에 대한 문의 사항은 info@shasha.com으로 이메일을 보내주십시오.</p>
      </>
    );
  }, [result]);

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <div>
      <StaticLayout loading={loading}>
        <ErrorBoundary>{renderContent()}</ErrorBoundary>
      </StaticLayout>
    </div>
  );
};

export default PrivacyPolicy;
