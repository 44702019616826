import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  CLEAR_NOTIFIACTIONS,
} from '../Constants/NotificationsConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case GET_NOTIFICATIONS_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_NOTIFICATIONS_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case CLEAR_NOTIFIACTIONS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default resetPasswordReducer;
