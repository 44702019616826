import { persistor, store } from '@Store/store'
import React, { Fragment, PropsWithChildren } from 'react'
// import { ReactNotifications } from 'react-notifications-component'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Fragment>
            {/* <ReactNotifications /> */}
            <Provider store={store}>
                <PersistGate loading={<Fragment />} persistor={persistor}>
                    {children}
                </PersistGate>
            </Provider>
        </Fragment>
    )
}

export default AppProvider