import {
  SET_RADIO_ON,
} from '../Constants/StreamConstants';

const setRadioOn = (value) => ({
  type: SET_RADIO_ON,
  payload: { active: value },
});

export { setRadioOn };
