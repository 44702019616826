import Loader from "@Atoms/Loader/Loader";
import classNames from "classnames";
import { forwardRef, PropsWithChildren, SyntheticEvent } from "react";
import DropDrownArrow from '@Assets/Images/dropdrown-arrow.svg';
import { Button, ButtonProps } from "react-bootstrap";
import styles from './CustomToggle.module.scss';

interface ICustomToggle {
    onClick: (e: SyntheticEvent<HTMLElement>) => void;
    isLoading?: boolean;
    variant?: ButtonProps['variant']
}

const CustomToggle = forwardRef<HTMLButtonElement, PropsWithChildren<ICustomToggle>>((props, ref) => {
    const { isLoading, onClick, children, variant } = props;
    const actuate = (e: SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        onClick(e);
    }

    if (isLoading) return (
        <div className={classNames(styles.loader, "d-flex justify-content-center align-items-center w-100")}>
            <Loader size={24} />
        </div>
    );
    return (
        <Button variant={variant ?? 'secondary'} className={styles.customToggleRoot} ref={ref} onClick={actuate}>
            {children}
            <img src={DropDrownArrow} width={15} height={8} alt="Arrow" />
        </Button>
    );
});

export default CustomToggle;