import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { getAvatars } from "../../Store/Actions/AvatarActions";
import constants from "../../Utils/constants";
import styles from "./EditPic.module.scss";
import { getUserPerProfile, updateUserPerProfile } from "../../Store/Actions/UserProfilesActions";
import arrowRight from "../../Assets/Images/back/icons-arrows-arrow-right.svg";
import Loader from "../../Components/Atoms/Loader/Loader";
import MessageBox from "../../Components/Atoms/MessageBox/index";

const EditPic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { result, loading } = useSelector((state) => state.ProfilesPerUser);
  const avatar = useSelector((state) => state.avatar.result);
  const { language } = useSelector((state) => state.authentication);
  const [imageSelectedError, setImageSelectedError] = useState(null);

  const editProfilePicture = async (file, ext) => {
    dispatch(
      updateUserPerProfile(
        id,
        result?.data?.find((e) => e.id === parseInt(id)).name,
        file[1],
        ext
      )
    ).then(() => {
      navigate(-1);
    });
  };

  const fileSelectHandler = async (event) => {
    const file = event.target.files[0];
    const { type, size } = file;
    const imgData = file.name.split(".");
    const ext = imgData.pop();
    const name = imgData[0];
    const reader = new FileReader();
    if (!type.startsWith("image")) {
      setImageSelectedError(t("fileInput.notSupported"));
      return;
    }
    if (size > constants.fileInput.imageSize) {
      setImageSelectedError(`${t("fileInput.imageSize")} 6MBs`);
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => {
      editProfilePicture(reader.result.split(","), ext, name);
      setImageSelectedError(null);
    };
  };

  useEffect(() => {
    dispatch(getUserPerProfile());
    dispatch(getAvatars());
  }, [dispatch]);

  return (
    <div className={styles.nonAuthenticatedPage}>
      <div className={styles.editRectangle}>
        <div className={styles.titleContainer}>
          <div
            onClick={() => navigate(-1)}
            className={language === "ar" ? styles.arrowRight : styles.arrowEng}
          >
            <img src={arrowRight} alt="" className="d-md-block" />
          </div>
          <div className={language === "ar" ? styles.title : styles.titleEng}>
            {t("profiles.editImage")}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className={styles.blueButton}
            variant="light"
            size="lg"
            onClick={() => document.getElementById("selectImage").click()}
          >
            {loading ? (
              <div className="loader d-flex justify-content-center align-items-center w-100">
                <Loader size={14} color="black" />
              </div>
            ) : (
              <>
                <span className={styles.blueBtnText}>
                  {t("profiles.uploadNewImage")}
                </span>
                <div className="pb-1 editImageContainer">
                  <input
                    id="selectImage"
                    hidden
                    type="file"
                    onChange={(event) => fileSelectHandler(event)}
                    accept={constants.fileInput.acceptImagesOnly}
                  />
                </div>
              </>
            )}
          </Button>
        </div>
        {imageSelectedError && (
          <div className={styles.messageBox}>
            <MessageBox isError message={imageSelectedError} />
          </div>
        )}

        {avatar?.length > 0 && (
          <div className="d-flex justify-content-center">
            <div className={styles.horizontalLine} />
            <span className={styles.options}>
              {t("profiles.orYouCanChoose")}
            </span>
            <div className={styles.horizontalLine} />
          </div>
        )}
        <div className={styles.grid}>
          {avatar?.map((e) => (
            <div
              key={e.id}
              className={styles.rectangle}
              style={{ backgroundImage: `url(${e.link})`, }}
              onClick={() => {
                dispatch(updateUserPerProfile(id, result?.data?.find((el) => el.id === parseInt(id)).name, e.link))
                  .then(() => { navigate(-1); });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditPic;
