import React, { useMemo } from 'react'
import SeasonSelector from './SeasonSelector/SeasonSelector'
import { Outlet } from 'react-router-dom'
import { Series, Show } from "src/alballam-api-types";
import styles from './SeasonSection.module.scss';
import MediaDetailsTabs from '@Molecules/MediaDetailsTabs/MediaDetailsTabs';
import { MediaType, Season } from '@Utils/commonTypes';
import { getHiddenSections } from '@Utils/common';

interface ISeasonSectionProps {
    media: Show | Series;
    currentSeason: Season | undefined;
    setCurrentSeason: (season: Season) => void;
    seasonsArray: Array<Season>;
    mediaType: MediaType;
    mediaId: string;
}

const SeasonSection: React.FC<ISeasonSectionProps> = ({ media, currentSeason, seasonsArray, setCurrentSeason, mediaId, mediaType }) => {
    const memoizedOutletProps = useMemo(() => ({ currentSeason, media: media }), [currentSeason, media]);
    const hiddenSections = useMemo(() => getHiddenSections({ media: media, mediaType: MediaType.Series, season: currentSeason! }), [currentSeason, media]);
    return (
        <div className={styles.seasonsSectionContainer}>
            <SeasonSelector currentSeason={currentSeason} seasonsArray={seasonsArray} setCurrentSeason={setCurrentSeason} />
            <MediaDetailsTabs seasonId={currentSeason?.id} mediaType={mediaType} mediaId={mediaId} hiddenSections={hiddenSections} />
            <Outlet context={memoizedOutletProps} />
        </div>
    )
}

export default SeasonSection