import { deleteCookie, getCookie } from "@Organisms/MarketingPopup/MarketingPopup.utils";
import { clearPaymentModalData, togglePaymentModal } from "@Store/Actions/PaymentModalActions";
import { useAppSelector } from "@Store/hooks";
import { convertSeasonsObjToArray, getSeasonById } from "@Utils/common";
import { IDetailsPageLoaderData, MediaType, PurchasableMedia } from "@Utils/commonTypes";
import constants from "@Utils/constants";
import { useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData, useParams } from "react-router-dom";
import { Series } from "src/alballam-api-types";
import { useUnmount } from "usehooks-ts";

/**
 * Custom hook to retrieve and process current media data based on the purchase type.
 *
 * @returns {Object} An object containing the following properties:
 * - `purchaseId` {string}: The ID of the current purchase.
 * - `purchaseType` {PurchasableMedia}: The type of the current purchase (Movie, Episode, or Season).
 * - `currentMedia` {any}: The current media data (movie, series, or show) based on the purchase type.
 * - `currentMediaType` {MediaType}: The type of the current media (Movie, Series, or Show).
 * - `purchasableMediaType` {PurchasableMedia}: The type of media that can be purchased (Movie, Episode, or Season).
 * - `currentMediaSeasonId` {string | undefined}: The ID of the current media season, if applicable.
 * - `currentMediaSeasonName` {string | undefined}: The name of the current media season, if applicable.
 */
export const useCurrentMediaData = () => {
    const { series, movie, show } = useLoaderData() as IDetailsPageLoaderData;
    const { purchaseId, purchaseType } = useAppSelector((state) => state.paymentModal);
    const { seasonId } = useParams();
    let currentMedia, purchasableMediaType, currentMediaType, currentMediaSeasonId, currentMediaSeasonName;

    const activeSeasonedMedia = series ?? show;

    switch (purchaseType) {
        case PurchasableMedia.Movie:
            currentMedia = movie;
            purchasableMediaType = PurchasableMedia.Movie;
            currentMediaType = MediaType.Movie;
            break;

        case PurchasableMedia.Episode:
            currentMedia = activeSeasonedMedia;
            purchasableMediaType = PurchasableMedia.Episode;
            currentMediaType = series !== undefined ? MediaType.Series : MediaType.Show;
            // only used for when episodes is selected, allows fetching the series package, if needed
            currentMediaSeasonId = seasonId;

            // wasteful implementation
            if (activeSeasonedMedia) {
                const currentSeason = getSeasonById(activeSeasonedMedia?.result?.seasons, seasonId);
                currentMediaSeasonName = currentSeason?.titles.en; // solely used for subscription source
            }

            break;

        case PurchasableMedia.Season:
            currentMedia = activeSeasonedMedia;
            purchasableMediaType = PurchasableMedia.Season;
            currentMediaType = series !== undefined ? MediaType.Series : MediaType.Show;

            // wasteful implementation
            if (activeSeasonedMedia) {
                const currentSeason = getSeasonById(activeSeasonedMedia?.result?.seasons, seasonId);
                currentMediaSeasonName = currentSeason?.titles.en; // solely used for subscription source
            }
            break;
    }


    return { purchaseId, purchaseType, currentMedia, currentMediaType, purchasableMediaType, currentMediaSeasonId, currentMediaSeasonName };
}


/**
 * Custom hook to handle the visibility of the payment modal.
 *
 * This hook checks for a specific cookie on the initial render to determine if the payment modal should be opened.
 * If the cookie is found and its value is 'true', the modal is toggled open and the cookie is deleted.
 * The hook also provides a method to close the modal and ensures that the modal data is cleared when the component unmounts.
 *
 * @returns {Object} An object containing:
 * - `shouldAppear` (boolean): Indicates whether the payment modal should be rendered.
 * - `closeModal` (Function): A function to close the payment modal.
 */
export const useHandleModalVisibility = (): { shouldAppear: boolean, closeModal: () => void } => {
    const { shouldRender } = useAppSelector((state) => state.paymentModal);
    const isFirstRender = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        // on initial render, check if exists a cookie to open the payment modal, if so toggle the modal true
        if (isFirstRender.current === true) {
            const paymentModalCookie = getCookie(constants.locationState.openPayment);
            if (paymentModalCookie === 'true') {
                dispatch(togglePaymentModal(true));
                deleteCookie(constants.locationState.openPayment);
            }
            isFirstRender.current = false;
            return;
        }
    }, [dispatch, shouldRender]);

    const closeModal = useCallback(() => dispatch(togglePaymentModal(false)), [dispatch]);
    useUnmount(() => dispatch(clearPaymentModalData()));

    return { shouldAppear: shouldRender!, closeModal };
}


/**
 * Retrieves the purchase package for a specific season within a series.
 *
 * @param seasonId - The ID of the season to search for.
 * @param series - The series object containing seasons and their packages.
 * @returns The purchase package for the specified season, or undefined if not found.
 */
export function getSeasonPurchasePackage(seasonId: string, series: Series) {
    try {
        const seasons = convertSeasonsObjToArray(series.seasons);
        const season = seasons.find((season) => season.id.toString() === seasonId);
        return season?.packages?.find((item) => item.type === "purchase");
    } catch {
        // console.error(`[getSeasonPurchasePackage] Error: ${e}`);
        return undefined;
    }
}

/**
 * Retrieves the packages associated with a specific episode within a series.
 *
 * @param episodeId - The unique identifier of the episode.
 * @param series - The series object containing seasons and episodes.
 * @returns An array of packages associated with the specified episode, or undefined if the episode is not found.
 */
export function getEpisodePackages(episodeId: string, series: Series) {
    try {
        const seasons = convertSeasonsObjToArray(series.seasons);
        const season = seasons?.find((season) => season.episodes.find((episode) => episode.id.toString() === episodeId));
        const episode = season?.episodes.find((episode) => episode.id.toString() === episodeId);
        return episode?.packages.at(0);

    } catch {
        // console.error(`[getEpisodePackages] Error: ${e}`);
        return [];
    }
}

export function cancelPaymentFlowCookies() {
    const openPaymentCookie = getCookie(constants.locationState.openPayment);
    if (openPaymentCookie !== undefined) deleteCookie(constants.locationState.openPayment);
}

