import { extractDefaultLoaderHeaders } from "@Utils/common";
import {
  IPlayerStatus,
  MediaType,
  PurchasableMedia,
} from "@Utils/commonTypes";
import { appClient } from "@Utils/Http";
import endpoints from "./alballam-api-endpoints";
import {
  WithApiMetaData,
  Series,
  Movie,
  WithPagination,
  IMyFatoorahPaymentMethod,
  IMyFatoorahSubscriptionPaymentBody,
  Package,
  ICurrentUserSubscription,
  IUserPurchases,
  IMyFatoorahPurchasePaymentBody,
  ITVSource
} from "./alballam-api-types";

export async function getSeriesDetails(seriesId: string) {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.get(`${endpoints.getSeriesDetails}${seriesId}`, {
    headers,
  });

  return res.data.data as WithApiMetaData<Series>;
}

export async function addToBookmarks(id: string, mediaType: MediaType) {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.post(
    `${endpoints.bookmarks}?id=${id}&type=${mediaType}`,
    {},
    { headers }
  );

  return res.data.data as WithApiMetaData<boolean>;
}

export async function deleteFromBookmarks(id: string, mediaType: MediaType) {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.delete(
    `${endpoints.bookmarks}?id=${id}&type=${mediaType}`,
    { headers }
  );

  return res.data.data as WithApiMetaData<boolean>;
}

export async function getMovieDetails(movieId: string) {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.get(`${endpoints.getMovieDetails}${movieId}`, {
    headers,
  });

  return res.data.data as WithApiMetaData<Movie>;
}

export async function getMyFatoorahSubscriptionPaymentMethods(packageId: string) {
  const headers = extractDefaultLoaderHeaders();
  const body = {
    package: packageId,
  }


  const res = await appClient.post(`${endpoints.myFatoorahSubscriptionPaymentMethods}`, body, { headers });

  return res.data.data as WithApiMetaData<Array<IMyFatoorahPaymentMethod>>;
}

export async function getMyFatoorahPurchasePaymentMethods(mediaId: string, mediaType: PurchasableMedia) {
  const headers = extractDefaultLoaderHeaders();
  const toBePurchasedItemType = mediaType === 'movie' || mediaType === 'episode' ? 'video' : mediaType; // API reads movies as 'video'
  const body = {
    paymentType: 'myFatoorah',
    id: mediaId,
    type: toBePurchasedItemType,
  }

  try {

    const res = await appClient.post(`${endpoints.myFatoorahPurchasePaymentMethods}`, body, { headers });

    return res?.data?.data as WithApiMetaData<Array<IMyFatoorahPaymentMethod>>;
  } catch (e) {
    console.error('Error getting payment methods: ', e);
    return null;
  }
}

export async function subscribeWithMyFatoorah(
  origin: string,
  packageId: number,
  paymentMethodId: number,
  source: string,
  qrId?: string // links TV QRs to subscriptions
) {
  const headers = extractDefaultLoaderHeaders();
  const body: IMyFatoorahSubscriptionPaymentBody = {
    package: packageId,
    paymentMethod: paymentMethodId,
    source,
  }
  if (qrId) {
    body.qrId = qrId;
  }

  const res = await appClient.post(`${endpoints.myFatoorahSubscriptionPayment}&origin=${origin}`, body, { headers });

  return res.data.data as WithApiMetaData<string>;
}

//TODO: Find out if purchaseWithMyFatoorah takes source
export async function purchaseWithMyFatoorah(
  origin: string,
  packageId: number,
  paymentMethodId: number,
  source: string,
  mediaType: PurchasableMedia,
  mediaId: string,
  qrId?: string // links TV QRs to purchases
) {
  const headers = extractDefaultLoaderHeaders();
  // API reads movies and episodes as 'video'
  const toBePurchasedItemType = mediaType === 'movie' || mediaType === 'episode' ? 'video' : mediaType; // API reads movies as 'video'

  const body: IMyFatoorahPurchasePaymentBody = {
    paymentType: 'myFatoorah',
    id: mediaId,
    type: toBePurchasedItemType,
    packageId: packageId,
    paymentMethod: paymentMethodId,
    source,
    origin,
  }
  if (qrId) {
    body.qrId = qrId;
  }

  const res = await appClient.post(`${endpoints.myFatoorahPurchasePayment}`, body, { headers });

  return res.data.data as WithApiMetaData<string>;
}

export async function getUserSubscription() {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.get(`${endpoints.checkUserSubscription}`, { headers });

  return res.data.data as WithApiMetaData<ICurrentUserSubscription>;
}

export async function getUserPurchases() {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.get(`${endpoints.getPurchases}`, { headers });

  return res.data.data as WithApiMetaData<IUserPurchases>;
}

export async function getAllPackages() {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.get(`${endpoints.getAllPackages}`, { headers });

  return res.data.data as WithApiMetaData<WithPagination<Array<Package>>>;
}

export async function fetchTVSource(episodeId: string): Promise<WithApiMetaData<ITVSource>> {
  try {
    const res = await appClient.get(`${endpoints.getEpisodeInfoById}${episodeId}`);
    return res.data.data as WithApiMetaData<ITVSource>;
  } catch {
    const out = {
      message: 'Error fetching TV source',
      result: { episodeId: '', episodeTitle: '', seasonId: '', seasonTitle: '', seriesId: '', seriesTitle: '' },
      server_data: new Date(),
    }
    return out;
  }
}

export async function getPlayerStatus(mediaId: string, mediaType: MediaType) {
  const headers = extractDefaultLoaderHeaders();
  const res = await appClient.get(`${endpoints.getPlayerStatus}${mediaId}?type=${mediaType}`, { headers });

  return res.data.data as WithApiMetaData<IPlayerStatus>;
}