// @ts-nocheck
//TODO: Remove noCheck
// most likely we won't be using this component

import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { isAndroid, isIOS } from 'react-device-detect';
import config from '../../../Utils/config';
import CloseButton from '../../../Assets/Images/VideoControls/icons-close.svg';
import ClickableItem from '../../Atoms/ClickableItem/ClickableItem';
import PurchaseButton from '../../Atoms/PurchaseButton/PurchaseButton';
import constants from '../../../Utils/constants';
import styles from './PaymentModal.module.scss';
import { clearPostSubscription } from '../../../Store/Actions/SubscriptionsActions';
import { saveSubscriptionSource } from '../../../Store/Actions/subscriptionsSourceActions';
import { parseSubscriptionSource } from "@Utils/sourceUtils";
import SubscriptionPackageButton from './SubscriptionPackageButton/SubscriptionPackageButton';
import OrSeparator from './OrSeparator/OrSeparator';
import { IPurchaseButtonProps } from '@Atoms/PurchaseButton/PurchaseButton.types';
import { PurchasableMedia, Season } from '@Utils/commonTypes';
import queryString from 'query-string';
import { IPackage } from '@Atoms/Episode/Episode';
import './PaymentModal.scss';


interface IPaymentModalProps {
  shouldRender: boolean;
  data: IData;
  season: any;
  showTitle: string;
  type: 'movie' | 'season' | 'episode';
  onClose: () => void;
  redirectUrl: string;
  onExited: ([...args]: any) => void;
  sourceTitle: string;
}

interface IData {
  description: string;
  hasLink: boolean;
  id: string;
  image: string;
  owned: boolean;
  packages: IPackage[];
  paid: boolean;
  season: Season;
  sourceTitle: string;
  title: string;
}

const PaymentModal: React.FC<IPaymentModalProps> = ({
  show: shouldRender,
  data = null,
  season = null,
  type,
  onClose,
  redirectUrl,
  onExited,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const result = useAppSelector((state) => state.videoSettings);
  const { result: videoDetails } = useAppSelector((state) => state.movie);
  const { language } = useAppSelector((state) => state.authentication);

  //@ts-expect-error no time to make a type for this
  const seriesId = videoDetails!.showId as number;
  //@ts-expect-error add types to react redux
  const settings = result?.settings;

  const buyPackage = data?.packages?.find((el) => el.type === 'purchase');
  const seasonPackage = season?.packages?.length > 0 && season?.packages?.find((el: { type: string }) => el?.type === 'purchase');

  const purchaseEpisodeButtonProps = useMemo(() => {
    const out: IPurchaseButtonProps = {
      type: 'purchase',
      isEpisode: false,
      onClick: () => { },
      price: undefined,
      currency: '',
    };

    let seasonedMedia;
    switch (type) {
      case PurchasableMedia.Episode:
        out.onClick = () => navigate({
          pathname: constants.newScreens.pay.purchase.series(seriesId).season(data?.season.id).episode(data?.id).as,
          search: queryString.stringify({ [constants.searchParams.origin]: redirectUrl ?? location.pathname })
        }, { unstable_viewTransition: true });

        // do not pass a type for it to be an episode
        out.price = buyPackage?.price;
        out.currency = buyPackage?.currency ?? '';
        out.isEpisode = true;
        out.type = 'purchase';


        break;
      case PurchasableMedia.Season:
        // get the season
        seasonedMedia = data;
        out.onClick = () => navigate({
          pathname: constants.newScreens.pay.purchase.series(seriesId).season(data?.season.id).as,
          search: queryString.stringify({ origin: redirectUrl ?? location.pathname })
        }, { unstable_viewTransition: true });

        out.isEpisode = false;
        out.price = buyPackage?.price;
        out.currency = buyPackage?.currency ?? '';
        out.type = 'season';
        break;

      default:
        break;
    }

    return out;
  }, [buyPackage?.currency, buyPackage?.price, data, navigate, redirectUrl, seriesId, type]);


  const purchaseSeasonButtonProps = useMemo(() => {
    const out: IPurchaseButtonProps = {
      type: 'purchase',
      isEpisode: false,
      onClick: () => { },
      price: undefined,
      currency: '',
    };

    out.onClick = () => navigate({
      pathname: constants.newScreens.pay.purchase.series(seriesId).season(data?.season.id).as,
      search: queryString.stringify({ origin: location.pathname })
    }, { unstable_viewTransition: true });

    out.isEpisode = false;
    out.price = seasonPackage?.price;
    out.currency = seasonPackage?.currency ?? '';
    out.type = 'season';

    return out;
  }, [data?.season?.id, navigate, seasonPackage?.currency, seasonPackage?.price, seriesId]);


  const onSubscriptionClick = useCallback(() => {
    const source = parseSubscriptionSource(
      videoDetails,
      { id: season?.source, name: data?.title ?? 'could-not-find-season-name' },
      { type: type, id: data?.id },
      { seasonableMediaId: seriesId },
    );
    dispatch(saveSubscriptionSource(source));
    dispatch(clearPostSubscription());
    navigate({
      pathname: constants.screens.subscribeAll,
      search: queryString.stringify({ redirect_url: redirectUrl ?? location.pathname })
    })
  }, [videoDetails, season?.source, data?.title, data?.id, type, seriesId, dispatch, navigate, redirectUrl]);

  const shouldRenderSeparator = !!(settings?.header?.lockIpad
    && (data?.owned === true || data?.free === false))
    || !!(data && buyPackage)
    || !!(season && season.paid !== false && seasonPackage);

  return (
    <Modal
      centered
      show={shouldRender}
      onHide={onClose}
      animation
      backdrop
      backdropClassName={styles.episodeModalBackrop}
      className={classNames(styles.episodeModal, 'episode-modal')}
      onExited={onExited}
    >
      <Modal.Header dir="auto" className={styles.modalHeader}>
        <Modal.Title className={styles.titleContainer}>
          <div className={styles.titleTextContainer}>
            <span className={styles.titleText}>
              {`${t('payment.toWatch')} ${t('globals.theContent')}`}
            </span>
          </div>
          <ClickableItem onClick={onClose} classes={classNames(language === 'ar' ? styles.closeButtonAr : styles.closeButton)}>
            <img src={CloseButton} alt="close" />
          </ClickableItem>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body dir="auto" className={styles.modalBody}>
        <Container fluid className="mt-3">
          <Row className={styles.buttonsRow}>
            {data && buyPackage && (
              <Fragment>
                {buyPackage && (
                  <Col xs={12} className={`${styles.buttonContainer} d-flex justify-content-center`}>
                    <PurchaseButton paymentModal {...purchaseEpisodeButtonProps} />
                  </Col>
                )}
              </Fragment>
            )}
            {settings?.header?.lockIpad
              && (data?.owned === true || data?.free === false) ? (
              <Fragment>
                <div className={styles.descriptionContainer}>
                  <div className={classNames(styles.separator, 'my-3')} />
                  <span className={styles.modalDescription}>
                    {t('payment.toWatchThisContent')}
                  </span>
                </div>
                <Button
                  className={styles.downloadBtn}
                  onClick={() => {
                    if (isAndroid) { window.open(config.stores.android); }
                    if (isIOS) { window.open(config.stores.ios); }
                  }}
                >
                  {t('globals.download')}
                </Button>
              </Fragment>
            ) : (
              season?.paid !== false
              && seasonPackage && (
                <Fragment>
                  <Col xs={12} className={`${styles.buttonContainer} d-flex justify-content-center`}>
                    <PurchaseButton paymentModal {...purchaseSeasonButtonProps} />
                  </Col>
                  {type === 'episode' || type === 'season' ? (
                    <div className={styles.seasonSubtext}>
                      <div className={styles.subtext}>{t('payment.seasonSubTextLineOne')} {t('payment.seasonSubTextLineTwo')}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Fragment>
              )
            )}
          </Row>
          <OrSeparator shouldRender={shouldRenderSeparator} />
          {data?.modalType === 'episode'
            && settings?.header?.lockIpad
            && (data?.owned === true || data?.free === false) ? null : (
            <div className={styles.packagesContainer}>
              <SubscriptionPackageButton onClick={onSubscriptionClick} />
              <Row>
                <Col>
                  <div className={classNames(styles.modalBottomText, 'mt-4')}>
                    <div>{t('payment.lineOne')}</div>
                    <div>{t('payment.lineTwo')}</div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

const MemoizedPaymentModal = React.memo(PaymentModal);
export default MemoizedPaymentModal;
