import { useState, Fragment, Dispatch, SetStateAction, useEffect } from 'react';
import styles from './Hero.module.scss';
import placeholder from '@Assets/Images/placeholders/16x9.svg';
import { IGenericTrailer, Season } from '@Utils/commonTypes';
import BackgroundPlayer from '@Atoms/BackgroundPlayer/BackgroundPlayer';
import useDisablePlayerWhenOffViewPort from '@Hooks/useDisablePlayerWhenOffViewPort';
import { IBasicPlayer } from '@Pages/MovieDetails/Hero/Hero.types';
import { Series } from 'src/alballam-api-types';
import constants from '@Utils/constants';

export const playerInitialState: IBasicPlayer = {
    IsVisible: true,
    isPlaying: false,
    hasEnded: false,
    hasLoaded: false,
    isMuted: true,
    hasPlayedOnce: false,
};

interface IHeroProps extends Series {
    playing: boolean;
    image: string;
    trailer?: IGenericTrailer;
    currentSeason: Season | undefined;
    children?: (props: { setPlayerStatus: Dispatch<SetStateAction<IBasicPlayer>>; playerStatus: IBasicPlayer }) => React.ReactNode;
}



const Hero: React.FC<IHeroProps> = ({ playing = false, image, children, currentSeason }) => {
    const [playerStatus, setPlayerStatus] = useState<IBasicPlayer>(playerInitialState);
    const containerRef = useDisablePlayerWhenOffViewPort(playerStatus, setPlayerStatus);

    const selectedTrailer = currentSeason?.trailers.at(currentSeason?.trailers.length - 1);
    const shouldRenderPlayer = playing === true && playerStatus.IsVisible === true && selectedTrailer?.link !== undefined;

    const delayPlayer = () => setTimeout(() => setPlayerStatus((prevState) => ({ ...prevState, hasLoaded: true, isPlaying: true })), constants.delayToReplayTrailer);
    useEffect(() => {
        if (shouldRenderPlayer && playerStatus.hasLoaded === true) {
            setPlayerStatus((prev) => ({ ...prev, hasPlayedOnce: true }));
        }
    }, [playerStatus.hasLoaded, shouldRenderPlayer]);

    useEffect(() => {
        setPlayerStatus(playerInitialState);
    }, [currentSeason]);


    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.trailer}>
                <div className={styles.aspectRatioBox}>
                    <div className={styles.gradient} />
                    <div className={`${playerStatus.hasLoaded ? 'd-none' : 'h-100'}`}>
                        <img
                            src={image || placeholder}
                            alt="Trailer Thumbnail"
                            className={styles.trailerThumbnail}
                        />
                    </div>
                    {shouldRenderPlayer && <BackgroundPlayer
                        key={selectedTrailer?.link}
                        url={selectedTrailer?.link ?? undefined}
                        updatePlayerStatus={setPlayerStatus}
                        playerOverrides={{ onReady: delayPlayer }}
                        {...playerStatus}
                    />}
                </div>
            </div>

            {children ? children({ setPlayerStatus, playerStatus }) : <Fragment />}
        </div>
    );
};

export default Hero;
