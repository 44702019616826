import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import FeaturedMovieDetails from './Details/CardDetails';
import styles from './FeaturedMediaCard.module.scss';
import placeholder from '@Assets/Images/placeholders/16x9.svg';
import { muteUnmuteHeaderAction } from '../../../Store/Actions/MutedHeaderActions';
import BackgroundPlayer from '../../Atoms/BackgroundPlayer/BackgroundPlayer';
import { IFeaturedMediaCardProps } from './FeaturedMediaCard.types';
import { useAppSelector } from '@Store/hooks';
import { isMobile } from 'react-device-detect';
import useDisablePlayerWhenOffViewPort from '@Hooks/useDisablePlayerWhenOffViewPort';
import { IBasicPlayer } from '@Pages/MovieDetails/Hero/Hero.types';

const initialState: IBasicPlayer = {
  IsVisible: !isMobile,
  isPlaying: true,
  hasEnded: false,
  hasLoaded: false,
  isMuted: true,
  hasPlayedOnce: false,
};

const FeaturedMediaCard: React.FC<IFeaturedMediaCardProps> = ({
  playing = false,
  id,
  type,
  title,
  description,
  image,
  trailer,
  featuredImageLandscape,
  titleImage,
  generalText,
  isComingSoon = false,
  showSlider,
  slug,
  infoImage,
}) => {
  const { muted } = useAppSelector((state) => state.mutedHeader);
  const dispatch = useDispatch();
  const [playerStatus, setPlayerStatus] = useState<IBasicPlayer>(initialState);
  const containerRef = useDisablePlayerWhenOffViewPort(playerStatus, setPlayerStatus);
  const shouldRenderPlayer = playing === true && playerStatus.IsVisible === true && trailer?.link !== undefined;

  const onClickMuteHandler = useCallback(() => {
    dispatch(muteUnmuteHeaderAction(!muted));
    setPlayerStatus((prevState) => ({
      ...prevState,
      isMuted: !prevState.isMuted,
    }));
  }, [dispatch, muted]);


  const onPlayerEnd = () => {
    setPlayerStatus((prevState) => ({
      ...prevState,
      IsVisible: false,
      hasEnded: true,
      hasLoaded: false,
    }));

    setTimeout(() => setPlayerStatus((prevState) => ({ ...playerStatus, isMuted: prevState.isMuted })), 5000);

  }

  return (
    <div className={styles.featuredMovieContainer} ref={containerRef}>
      <div className={styles.trailer}>
        <div className={styles.aspectRatioBox}>
          <div className={styles.gradient} />
          <div className={styles.topGradient} />
          <div className={`${playerStatus.hasLoaded ? 'd-none' : 'h-100'}`}>
            <img src={featuredImageLandscape || placeholder} alt="featured-image" className={styles.trailerThumbnail} />
          </div>
          {shouldRenderPlayer && <BackgroundPlayer url={trailer?.link} updatePlayerStatus={setPlayerStatus} playerOverrides={{ onEnded: onPlayerEnd }}  {...playerStatus} />}
        </div>
      </div>

      <FeaturedMovieDetails
        id={id}
        image={image}
        description={description}
        showMuteButton={playerStatus.IsVisible && trailer && playerStatus.hasLoaded}
        muted={playerStatus.isMuted}
        onClickMute={onClickMuteHandler}
        playing={playerStatus.hasLoaded}
        hasVideoEnded={playerStatus.hasEnded}
        title={title}
        type={type}
        titleImage={titleImage}
        generalText={generalText}
        isComingSoon={isComingSoon}
        showSlider={showSlider}
        slug={slug}
        infoImage={infoImage}
      />
    </div>
  );
};

export default FeaturedMediaCard;
