const GET_SEARCHDATA = 'GET_SEARCHDATA';
const GET_SEARCHDATA_FAIL = 'GET_SEARCHDATA_FAIL';
const GET_SEARCHDATA_SUCCESS = 'GET_SEARCHDATA_SUCCESS';
const CLEAR_SEARCHDATA = 'CLEAR_SEARCHDATA';
const ADD_SEARCH_KEYWORD = 'ADD_SEARCH_KEYWORD';
const CLEAR_SEARCH_KEYWORD = 'CLEAR_SEARCH_KEYWORD';

export {
  GET_SEARCHDATA,
  GET_SEARCHDATA_FAIL,
  GET_SEARCHDATA_SUCCESS,
  CLEAR_SEARCHDATA,
  ADD_SEARCH_KEYWORD,
  CLEAR_SEARCH_KEYWORD,
};
