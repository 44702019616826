import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../../Utils/constants';

const Icon = ({ width, height }) => (
  <svg
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={() => window.open(constants.socialMedia.links.twitter)}
    width={width || 'inherit'}
    height={height || 'inherit'}
  >
    <path d="M0.4959 0.734131L3.60282 4.88831L0.476318 8.26586H1.18002L3.91734 5.3087L6.12893 8.26586H8.5235L5.2417 3.87805L8.15186 0.734131H7.44816L4.92732 3.45753L2.89047 0.734131H0.4959ZM1.53073 1.25242H2.63079L7.48853 7.74756H6.38848L1.53073 1.25242Z" fill="white" />
  </svg>

);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 34,
  height: 27,
};

export default Icon;
