import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
import constants from '../../../Utils/constants';
import Loader from '../../Atoms/Loader/Loader';
import CancelSubModal from '../../Molecules/CancelSubModal/CancelSubModal';
import MessageBox from '../../Atoms/MessageBox';
import SubscribePackage from '../../Molecules/SubscribePackage/SubscribePackage';
import { cancelSubscription } from '../../../Store/Actions/SubscriptionsActions';
import styles from './SubscriptionPackages.module.scss';
import queryString from 'query-string';

const SubscriptionPackages = ({ btnContainerFullWatch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const packages = useSelector((state) => state.subscriptionPackages);
  const { result } = useSelector((state) => state.userPurchases);
  const { language } = useSelector((state) => state.authentication);
  const { loading: cancelSubscriptionLoading, error: cancelSubscriptionError, result: cancelSubscriptionSuccess } = useSelector((state) => state.subscription);
  const [isActive, setIsActive] = useState(false);
  const [isCancelSubFormVisible, setIsCancelSubFormVisible] = useState(false);
  const location = useLocation();
  const [params] = useSearchParams();
  const qrId = params.get('qrId');

  const onSubscribeButtonClick = useCallback(() => {
    const destination = location.state?.redirect_url ?? constants.screens.profile;
    navigate({
      pathname: constants.newScreens.pay.subscription.selectMethod(isActive?._id).as,
      search: queryString.stringify({ qrId, origin: destination })
    }, { unstable_viewTransition: true });
  }, [location.state?.redirect_url, qrId, isActive, navigate]);

  const getCancelSubscriptions = useCallback(() => {
    function displayUnsubModal() {
      setIsCancelSubFormVisible(true);
    }
    if (result?.subscriptions[0]?.type === 'coupon') return null;
    if (
      cancelSubscriptionSuccess
      || (result?.subscriptions?.length > 0
        && result?.subscriptions[0]?.canceled === true)
    ) {
      return (
        <div className="cancelSubscriptionSuccessContainer">
          <span className="cancelSubscriptionSuccess">
            {t('globals.cancelSubscriptionSuccess')}
          </span>
        </div>
      );
    }

    if (cancelSubscriptionLoading) {
      return (
        <div className="profileLoading m-5 d-flex justify-content-center align-items-center">
          <Loader size={34} />
        </div>
      );
    }

    if (cancelSubscriptionError) {
      return (
        <div className="messageBox mt-3">
          <MessageBox message={cancelSubscriptionError} isError />
        </div>
      );
    }
    return (
      <div className="btnContainerFullWatch">
        <span
          onClick={displayUnsubModal}
          className="unsubscribe"
        >
          {t('globals.cancelSubscription')}
        </span>

        <CancelSubModal
          cancelSubscription={cancelSubscription}
          setIsCancelSubFormVisible={setIsCancelSubFormVisible}
          isCancelSubFormVisible={isCancelSubFormVisible}
          cancelSubscriptionLoading={cancelSubscriptionLoading}
        />
      </div>
    );
  }, [cancelSubscriptionLoading, cancelSubscriptionSuccess, t, cancelSubscriptionError, result, isCancelSubFormVisible]);

  const toBeRenderedPackages = useMemo(() => {
    if (result?.subscriptions?.length > 0) return result?.subscriptions;
    return packages?.result?.data;
  }, [result, packages]);

  return (
    <div className={styles.profileCon}>
      <div className={styles.subsTitle}>{t('payment.packages')}</div>
      <div className={styles.profileDetailsContainer}>
        <div className={styles.subsPackagesContainer}>
          {toBeRenderedPackages?.map((e, indx) => (
            <Col key={e._id} sm={12} md={6} className="py-3 w-100">
              <SubscribePackage
                language={language}
                countryPrice={e.countryPrice}
                result={result?.subscriptions.length > 0}
                isOwned={result?.subscriptions.length > 0 && e.price === result?.subscriptions[0].price}
                price={e.price}
                active={
                  result?.subscriptions.length > 0 ? e.price === result?.subscriptions.map((elm) => elm.price)[indx] : isActive._id === e._id
                }
                duration={e.duration}
                currency={e.currency}
                onClick={() => setIsActive(packages.result.data.find((item) => item._id === e._id))}
                date={result?.subscriptions.length > 0 && result?.subscriptions[0].date}
                description={e?.description}
                descriptionTwo={e?.descriptionTwo}
                descriptionThree={e?.descriptionThree}
                subscriptions={result?.subscriptions.length > 0 ? e.price === result?.subscriptions.map((elm) => elm.price)[indx] : false}
                isCoupon={e?.type === 'coupon'}
                imageUrl={e?.image}
              />
            </Col>
          ))}
        </div>

        {result?.subscriptions.length <= 0 ? (
          <div className="btnContainerFullWatch" ref={btnContainerFullWatch}>
            <Button disabled={!isActive} onClick={onSubscribeButtonClick} className={styles.subscribeNow}>
              <span className="subscribe">
                {t('globals.subscribeNow')}
              </span>
            </Button>
          </div>
        ) : (
          getCancelSubscriptions()
        )}

        {result?.expiredSubscriptions?.length > 0 && (
          <>
            <div className="Seperate mb-5 mt-5" />
            <div className="rentalTitle">
              {t('globals.expiredSubscriptions')}
            </div>
            <Row>
              {result?.expiredSubscriptions?.map(
                (e) => e?.type !== 'coupon' && (
                  <Col key={e._id} sm={12} md={6}>
                    <SubscribePackage
                      countryPrice={e.countryPrice}
                      language={language}
                      price={e.price}
                      duration={e?.package?.duration}
                      currency={e?.package?.currency}
                      isExpiredPackage
                      description={e?.description}
                      descriptionTwo={e?.descriptionTwo}
                      descriptionThree={e?.descriptionThree}
                    />
                  </Col>
                ),
              )}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPackages;
