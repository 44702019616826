/* eslint-disable react/destructuring-assignment */

import {
  CLEAR_PURCHASES,
  GET_PURCHASES,
  GET_PURCHASES_FAIL,
  GET_PURCHASES_SUCCESS,
} from '../Constants/userPurchasesConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const UserPurchasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PURCHASES: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_PURCHASES_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_PURCHASES_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_PURCHASES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default UserPurchasesReducer;
