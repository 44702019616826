import { initialPlayerState } from "@Organisms/ExpandedCard/ExpandedCard.utils";
import { clearHoveredCard, onHoverCard, hoveredCardAction } from "@Store/Actions/HoveredMovie";
import { useAppSelector } from "@Store/hooks";
import { hoveredIdSelector } from "@Utils/common";
import { RefObject, useState, useEffect, useCallback } from "react";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";

/**
 * A custom hook that manages hover and player states for a media card component.
 * 
 * @NOTE
 * This hook is designed to be used with the PARENT of MediaCardPlayer component.
 * 
 * @param mediaId - The unique identifier for the media.
 * @param ref - A React ref to the main container (e.g., a card element).
 * @param cardInfoRef - A React ref to the content container that holds card info.
 * @param playerRef - A React ref to the player instance (e.g., ReactPlayer).
 * @returns An object containing event handlers and state:
 * - onMouseEnter: Called when the card is hovered.
 * - onMouseLeave: Called when the mouse leaves the card.
 * - setPlayerStatus: A state updater function for the player status.
 * - playerStatus: An object containing player-related states, including visibility flags.
 * - isHovered: A boolean indicating whether the card is currently hovered.
 */
export function useMediaCardPlayer(
    mediaId: string,
    ref: RefObject<HTMLDivElement | undefined>,
    cardInfoRef: RefObject<HTMLDivElement | undefined>,
    playerRef: RefObject<ReactPlayer | undefined>
) {
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const [playerStatus, setPlayerStatus] = useState(initialPlayerState);
    const hoveredMovie = useAppSelector((state) => hoveredIdSelector(state, mediaId));

    useEffect(() => {
        if (!hoveredMovie && !isHovered) {
            setPlayerStatus((prev) => ({ ...prev, hasEnded: false }));
            dispatch(clearHoveredCard());
        }
    }, [hoveredMovie, dispatch, isHovered]);

    const onMouseEnter = useCallback(() => {
        // if (isMobile || !endTransition) return;
        if (isMobile) return;

        setIsHovered(true);
        dispatch(onHoverCard(mediaId));

        setPlayerStatus((prev) => ({ ...prev, showPlayer: true }));

        if (ref.current) {
            ref.current.style.zIndex = '10';
        }

        if (cardInfoRef.current) {
            cardInfoRef.current.style.display = 'block';
            cardInfoRef.current.style.animation = 'fadeIn forwards 0.3s';
        }
    }, [cardInfoRef, dispatch, mediaId, ref]);

    const onMouseLeave = useCallback(() => {
        // if (isMobile || !endTransition) return;
        if (isMobile) return;
        setIsHovered(false);

        setPlayerStatus((prev) => ({
            ...prev,
            showPlayer: false,
            loaded: false,
        }));

        if (ref.current) {
            ref.current.style.zIndex = '0';
        }

        if (cardInfoRef.current) {
            cardInfoRef.current.style.animation = 'fadeOut forwards 0.3s';
        }

        if (playerRef.current) {
            dispatch(hoveredCardAction(mediaId, playerRef.current?.getCurrentTime() || 0));
        }
    }, [cardInfoRef, dispatch, mediaId, playerRef, ref]);

    return { onMouseEnter, onMouseLeave, setPlayerStatus, playerStatus, isHovered };
}