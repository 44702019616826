import { useLoaderData, useParams } from 'react-router-dom';
// import Hero from './Hero/Hero';
import Hero from '@Pages/ShowDetails/Hero/Hero';
import Details from './Hero/Details/Details';
import styles from './ShowDetails.module.scss';
import SeasonSection from '../../Components/Organisms/SeasonSection/SeasonSection';
import { BrowserView, MobileView } from 'react-device-detect';
import BackButton from '@Atoms/BackButton/BackButton';
import { Fragment } from 'react/jsx-runtime';
import PaymentModal from '@Molecules/PaymentModalNouveau/PaymentModal';
import { IDetailsPageLoaderData, MediaType } from '@Utils/commonTypes';
import useSelectedSeason from '@Hooks/useSelectedSeason';

function ShowDetails() {
    const { show } = useLoaderData() as IDetailsPageLoaderData;
    const showContent = show!.result;
    const { showId, seasonId } = useParams();
    const { currentSeason, seasonsArray, setCurrentSeason } = useSelectedSeason(showContent?.seasons, seasonId, showId!, MediaType.Show, showContent?.player);

    return (
        <Fragment>
            <div className={styles.root}>
                <BrowserView>
                    <Hero {...showContent} currentSeason={currentSeason} image={showContent.featuredImageLandscape} playing>
                        {({ playerStatus, setPlayerStatus }) => {
                            return (
                                <Details
                                    id={showId!}
                                    series={showContent}
                                    isPlaying={playerStatus.isPlaying}
                                    muted={playerStatus.isMuted}
                                    playerStatus={playerStatus}
                                    setPlayerStatus={setPlayerStatus}
                                />
                            )
                        }}
                    </Hero>
                </BrowserView>
                <MobileView>
                    <BackButton />
                    <Hero {...showContent} currentSeason={currentSeason} playing={false} />
                    <Details id={showId!} series={showContent} muted={true} isPlaying={true} />
                </MobileView>
                <SeasonSection
                    key={seasonId}
                    media={showContent}
                    currentSeason={currentSeason}
                    mediaId={showId!}
                    mediaType={MediaType.Show}
                    seasonsArray={seasonsArray}
                    setCurrentSeason={setCurrentSeason}
                />
            </div>
            <PaymentModal />
        </Fragment>
    )
}

export default ShowDetails