import { HOVERED_CARD, ON_HOVER_CARD, CLEAR_HOVERED_CARD } from '../Constants/HoveredCardConstants';

const hoveredCardAction = (id, duration) => ({
  type: HOVERED_CARD,
  payload: { id, duration },
});

const onHoverCard = (id) => ({
  type: ON_HOVER_CARD,
  payload: { id },
});

const clearHoveredCard = () => ({
  type: CLEAR_HOVERED_CARD,
  payload: null,
});

export { hoveredCardAction, onHoverCard, clearHoveredCard };
