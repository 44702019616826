import {
  CLEAR_SHOWS_FILTERED,
  GET_SHOWS_FILTERED,
} from '../Constants/ShowsConstants';

const getShowsFiltered = (genreId, page = 1) => ({
  type: GET_SHOWS_FILTERED,
  payload: {
    request: {
      url: 'api/video/shows',
      method: 'get',
      authenticated: true,
      params: {
        filter: genreId,
        page,
        limit: 24,
        isSeries: false,
      },
    },
  },
});

const clearShowsFiltered = () => ({
  type: CLEAR_SHOWS_FILTERED,
  payload: null,
});

export { getShowsFiltered, clearShowsFiltered };
