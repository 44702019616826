import {
  GET_FILTERED_LANDING_VIDEOS, CLEAR_FILTERED_LANDING_VIDEOS_DATA,
} from '../Constants/FilteredLandingVideosConstants';

const getLandingVideosFiltered = (genreId, page = 1) => ({
  type: GET_FILTERED_LANDING_VIDEOS,
  payload: {
    request: {
      url: 'api/videoLanding/movies?',
      method: 'get',
      authenticated: true,
      params: {
        filter: genreId,
        page,
        limit: 24,
      },
    },
  },
});

const clearLandingVideosFiltered = () => ({
  type: CLEAR_FILTERED_LANDING_VIDEOS_DATA,
  payload: null,
});

export { getLandingVideosFiltered, clearLandingVideosFiltered };
