import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getStatic } from "../../Store/Actions/StaticScreenActions";
import reduxConstants from "../../Store/constants";
import ContactUsForm from "../../Components/Organisms/ContactUsForm/index";
import { sendContactUsForm } from "../../Store/Actions/ContactUsActions";
import Loader from "../../Components/Atoms/Loader/Loader";
import ContactUsButton from "../../Components/Atoms/ContactUsButton/ContactUsButton";
import EmailIcon from "../../Assets/Images/icons-contact-email.svg";
import PhoneIcon from "../../Assets/Images/icons-contact-phone.svg";
import WhatsappIcon from "../../Assets/Images/icons-contact-whats-app.svg";
import constants from "../../Utils/constants";
import styles from "./index.module.scss";
import LineSeparator from "../../Components/Atoms/LineSeparator/LineSeparator";
import ErrorComponent from "../../HOC/ErrorComponent";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { result, error } = useSelector(
    (state) => state.staticScreens.contactUs
  );
  const isSignedIn = useSelector((state) => state.authentication.isSignedIn);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const { t } = useTranslation();
  const submitCount = useRef(0);
  const form = useRef();

  const { language } = useSelector((state) => state.authentication);

  const onSubmit = (data) => {
    dispatch(sendContactUsForm(data)).then(() => {
      setCaptchaValidated(false);
    });

    submitCount.current += 1;
    form.current.resetForm();
  };

  const onCaptchaChange = useCallback((value) => {
    if (value) {
      setCaptchaValidated(true);
    }
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      setShowCaptcha(false);
      setCaptchaValidated(true);
    } else {
      setShowCaptcha(true);
    }
  }, [isSignedIn]);

  useEffect(() => {
    dispatch(getStatic(reduxConstants.staticApis.contactUs));
  }, [dispatch]);

  const shouldRenderPhoneNumberSection = useMemo(() => {
    try {
      const text = result?.data?.data?.result?.[0]?.block[1]?.text;
      if (text !== undefined && text.replace("<br>", "").replace("<p>", "").replace("</p>","") !== "") {
        return true;
      }

      return false;
    } catch {
      return false;
    }
  }, [result?.data?.data?.result]);

  const shouldRenderWhatsappSection = useMemo(() => {
    try {
      const text = result?.data?.data?.result?.[0]?.block[2]?.text;
      if (text !== undefined && text.replace("<br>", "").replace("<p>", "").replace("</p>","") !== "") {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }, [result?.data?.data?.result]);

  const renderContent = useCallback(() => {
    let html = "";

    try {
      if (!result) {
        return (
          <div className={classNames(styles.homeLoader, "m-5")}>
            <Loader size={24} />
          </div>
        );
      }
      html = result.data.data.result[0].block[3].text;
      // eslint-disable-next-line no-empty
    } catch { }

    return (
      <div className={`${language === "ar" ? null : styles.htmlContentEng} ${styles.htmlContent}`}>
        {parse(html)}
      </div>
    );
  }, [result, language]);

  if (error) {
    return (
      <ErrorComponent error={error} pageType={constants.screens.contactUs} />
    );
  }
  return (
    <Container fluid className="mb-5 flex-grow-1">
      <Row className={styles.contactUs}>
        {!result ? (
          <div className={classNames(styles.homeLoader, styles.loader)}>
            <Loader size={44} />
          </div>
        ) : (
          <>
            <Col
              lg={7}
              md={6}
              className={classNames(styles.contactUsForm, "px-5")}
            >
              <Row className={styles.contactUsTitle}>
                {t("globals.contactUs")}
              </Row>
              <ContactUsForm
                onSubmit={(data, reset) => onSubmit(data, reset)}
                isSignedIn={isSignedIn}
                onCaptchaChange={onCaptchaChange}
                showCaptcha={showCaptcha}
                captchaValidated={captchaValidated}
                submitCount={submitCount}
                form={form}
              />
            </Col>
            <Col
              className={classNames(styles.staticContent, "px-5 pt-0")}
              lg={5}
              md={6}
            >
              <Row className={styles.stayInTouch}>
                {t("globals.stayInTouch")}
              </Row>

              <Row className="mb-5">
                <ContactUsButton
                  image={EmailIcon}
                  title={`${t("globals.email")}:`}
                  details={parse(result.data.data.result[0].block[0].text)}
                  detailsStyles="text-break"
                  onClick={() => {
                    const gmailComposeUrl = "https://mail.google.com/mail/?view=cm&fs=1&to=mhammad.n@eurisko.net";
                    window.open(gmailComposeUrl, '_blank');
                  }}
                />
                <Col>
                  <div
                    className={`lineSeperator ${language === "ar" ? "pr-4 mr-3 pl-3" : "pl-4 ml-3 pr-3"
                      }  pt-0 mt-0`}
                  >
                    <LineSeparator />
                  </div>
                </Col>
                {shouldRenderPhoneNumberSection && <ContactUsButton
                  image={PhoneIcon}
                  title={`${t("globals.phoneNumber")}:`}
                  details={parse(result.data.data.result[0].block[1].text)}
                  detailsStyles="text-right direction-left"
                />}
                <Col>
                  <div className={`lineSeperator ${language === "ar" ? "pr-4 mr-3 pl-3" : "pl-4 ml-3 pr-3"}  pt-0 mt-0`}>
                    <LineSeparator />
                  </div>
                </Col>
                {shouldRenderWhatsappSection && <ContactUsButton
                  image={WhatsappIcon}
                  title={t("globals.whatsappTitle")}
                  details={t("globals.pressToWhatsapp")}
                  detailsStyles={styles.whatsappDetails}
                  onClick={() => {
                    window.open(
                      constants.socialMedia.links.whatsapp(
                        `${parse(
                          result?.data?.data?.result[0]?.block[2]?.text
                        )}`
                      )
                    );
                  }}
                />}
              </Row>

              <Row className={styles.stayInTouch}>
                {t("globals.ourLocations")}
              </Row>

              <Row >{renderContent()}</Row>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default ContactUs;
