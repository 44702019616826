import {
  CLEAR_PLAYER_STATUS,
  GET_PLAYER_STATUS,
} from '../Constants/PlayerStatusConstants';

const getPlayerStatus = (id, type) => ({
  type: GET_PLAYER_STATUS,
  payload: {
    request: {
      url: `/api/video/dashboard/slider/player/${id}?type=${type}`,
      method: 'get',
      authenticated: true,
    },
  },
});

const clearPlayerStatus = () => ({
  type: CLEAR_PLAYER_STATUS,
  payload: null,
});

export { clearPlayerStatus, getPlayerStatus };
