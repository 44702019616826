import {
  GET_MOVIE,
  GET_TRAILER,
  START_TRACKING,
  FINISH_TRACKING,
  CLEAR_MOVIE,
  GET_MOVIE_DETAILS,
  CLEAR_MOVIE_DETAILS,
} from '../Constants/MovieConstants';

export const getMovie = (id) => ({
  type: GET_MOVIE,
  payload: {
    request: {
      url: `api/video/player/${id}?isChromecast=true`,
      method: 'get',
      authenticated: true,
    },
  },
});

export const getTrailer = (id) => ({
  type: GET_TRAILER,
  payload: {
    request: {
      url: `api/trailer/player/${id}`,
      method: 'get',
    },
  },
});

export const startTracking = (id) => ({
  type: START_TRACKING,
  payload: {
    request: {
      url: `api/video/track/start/${id}`,
      method: 'post',
      authenticated: true,
    },
  },
});

export const finishTracking = (id) => ({
  type: FINISH_TRACKING,
  payload: {
    request: {
      url: `api/video/track/finish/${id}`,
      method: 'post',
      authenticated: true,
    },
  },
});

export const clearMovie = () => ({
  type: CLEAR_MOVIE,
  payload: null,
});

export const getMovieDetails = (id) => ({
  type: GET_MOVIE_DETAILS,
  payload: {
    request: {
      url: `/api/video/player/details/${id}`,
      method: 'get',
      authenticated: true,
    },
  },
});

export const clearMovieDetails = () => ({
  type: CLEAR_MOVIE_DETAILS,
  payload: null,
});
