import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import propTypes from "prop-types";
import {
  getSubscriptionPackages,
  getPackageSentence,
} from "../../../../Store/Actions/SubscriptionPackagesActions";
import styles from "./SubscriptionPackageButton.module.scss";

function SubscriptionPackageButton({ onClick, subtitle1, subtitle2 }) {
  const { loading, error, result, sentence } = useSelector(
    (state) => state.subscriptionPackages
  );
  const { language } = useSelector((state) => state.authentication);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscriptionPackages());
    dispatch(getPackageSentence());

    return () => {
      dispatch(getSubscriptionPackages());
    };
  }, [dispatch]);

  const SubscriptionButtonContent = useMemo(() => {
    try {
      if (loading) return <span>...</span>;
      if (error) return <span>{t("globals.subscribe")}</span>;
      if (result?.data?.length > 0) {
        return (
          <div>
            <div className={styles.textsContainer}>
              <p>{sentence} </p>
              <span className={styles.priceText} dir="ltr">
                {result?.data[0]?.countryPrice ?? result?.data[0]?.price}
                {result?.data[0]?.currency}
              </span>
            </div>
            <div className={styles.subtitlesContainer}>
              <p>{subtitle1}</p>
              <p>{subtitle2}</p>
            </div>
          </div>
        );
      }

      return <span>{t("globals.subscribe")}</span>;
    } catch {
      return <span>{t("globals.subscribe")}</span>;
    }
  }, [loading, error, t, result?.data, sentence, subtitle1, subtitle2]);

  return (
    <Button
      style={{ direction: language === "ar" ? "rtl" : "ltr" }}
      className={styles.subscribeButton}
      onClick={onClick}
      disabled={loading}
    >
      {SubscriptionButtonContent}
    </Button>
  );
}

SubscriptionPackageButton.propTypes = {
  onClick: propTypes.func,
  subtitle1: propTypes.string,
  subtitle2: propTypes.string,
};

SubscriptionPackageButton.defaultProps = {
  onClick: () => {},
};

export default SubscriptionPackageButton;
