import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { InputField, SubmitInput } from '../../Atoms';
import './index.scss';
import Loader from '../../Atoms/Loader/Loader';
import MessageBox from '../../Atoms/MessageBox';
import Captcha from '../../Atoms/Captcha/Captcha';

const ContactUsForm = ({
  onSubmit,
  loading,
  isSignedIn,
  onCaptchaChange,
  showCaptcha,
  captchaValidated,
  submitCount,
  form,
}) => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    fullName: yup.string().trim().required('validation.required'),
    email: yup
      .string()
      .trim()
      .required('validation.required')
      .email('validation.invalidEmail'),
    address: yup.string().trim().required('validation.required'),
    message: yup.string().trim().required('validation.required'),
  });
  const { loader, success } = useSelector((state) => state.contactUs);
  const { profile } = useSelector((state) => state.user);
  const [userValues, setUserValues] = useState({ name: '', email: '' });
  const [hasTouched, setHasTouched] = useState(false);

  useEffect(() => {
    if (isSignedIn && profile) {
      setUserValues({ name: profile.name, email: profile.email });
    }
  }, [isSignedIn, profile]);

  const trimValues = (values) => {
    return {
      fullName: values.fullName.trim(),
      email: values.email.trim(),
      address: values.address.trim(),
      message: values.message.trim(),
    };
  };

  return (
    <>
      {(!isSignedIn || (userValues.name && userValues.email)) && (
        <div className="contactUsContainer">
          <Formik
            innerRef={form}
            validationSchema={validationSchema}
            initialValues={{
              fullName: userValues.name,
              email: userValues.email,
              address: '',
              message: '',
            }}
            onSubmit={(values) => {
              const trimmedValues = trimValues(values);
              onSubmit(trimmedValues);
            }}
          >
            {({ handleSubmit, handleChange, errors }) => (
              <Form
                className="contactUsForm"
                noValidate
                onSubmit={(e) => {
                  handleSubmit(e);
                  setHasTouched(false);
                }}
              >
                <Row>
                  <Col lg={12}>
                    <InputField
                      name="fullName"
                      label={t('globals.name')}
                      type="text"
                      placeholder={t('globals.name')}
                      onChange={(e) => {
                        handleChange(e);
                        setHasTouched(true);
                      }}
                      alertText={errors.fullName}
                      error={!!errors.fullName}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputField
                      name="email"
                      label={t('globals.emailAddress')}
                      type="text"
                      placeholder={t('globals.emailAddress')}
                      onChange={(e) => {
                        handleChange(e);
                        setHasTouched(true);
                      }}
                      alertText={errors.email}
                      error={!!errors.email}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputField
                      autocomplete="off"
                      name="address"
                      label={t('globals.address')}
                      type="text"
                      placeholder={t('globals.address')}
                      onChange={(e) => {
                        handleChange(e);
                        setHasTouched(true);
                      }}
                      alertText={errors.address}
                      error={!!errors.address}
                    />
                  </Col>
                  <Col lg={12}>
                    <InputField
                      autocomplete="off"
                      name="message"
                      label={t('globals.message')}
                      type="text"
                      placeholder={t('globals.enterYourText')}
                      onChange={(e) => {
                        handleChange(e);
                        setHasTouched(true);
                      }}
                      alertText={errors.message}
                      error={!!errors.message}
                      as="textarea"
                      inputClassName="messageInput"
                    />
                  </Col>
                  {showCaptcha && (
                    <Col className="captchaContainer" lg={12}>
                      <Captcha
                        onCaptchaChange={onCaptchaChange}
                        submitCount={submitCount?.current}
                      />
                    </Col>
                  )}
                  <Col className="mt-5" lg={12}>
                    {success === true
                      && !!errors.address === false
                      && !!errors.email === false
                      && !!errors.fullName === false
                      && !!errors.message === false
                      && !hasTouched && (
                        <MessageBox
                          message={t('globals.operationSuccess')}
                          isError={false}
                        />
                    )}
                    {success === false
                      && !!errors.address
                      && !!errors.email
                      && !!errors.message
                      && !!errors.fullName
                      && !hasTouched && (
                        <MessageBox
                          message={t('errors.somethingWentWrong')}
                          isError
                        />
                    )}
                    <SubmitInput
                      isDisabled={!captchaValidated && !isSignedIn}
                      value={loader ? <Loader size={16} color="var(--color-black)" /> : t('globals.send')}
                      type="primary"
                      loading={loading}
                      size={10}
                      id="ContactUs"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

ContactUsForm.defaultProps = {
  loading: false,
  isSignedIn: false,
};

ContactUsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isSignedIn: PropTypes.bool,
};

export default ContactUsForm;
