import { GET_LANDING_VIDEOS, CLEAR_LANDING_VIDEOS_DATA } from '../Constants/LandingVideosConstants';

const getVideosLanding = () => ({
  type: GET_LANDING_VIDEOS,
  payload: {
    request: {
      url: 'api/videoLanding/landing',
      method: 'get',
      authenticated: true,
    },
  },
});

const clearVideosLanding = () => ({
  type: CLEAR_LANDING_VIDEOS_DATA,
  payload: null,
});

export { getVideosLanding, clearVideosLanding };
