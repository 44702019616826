import React, {
  memo, useRef, useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import constants from '../../../../Utils/constants';
import styles from './YoureWatching.module.scss';

const YoureWatching = ({ playing }) => {
  const { t } = useTranslation();

  const timerRef = useRef(null);

  const { result } = useSelector((state) => state.movie);

  const title = result?.title;
  const description = result?.episode ? result?.episodeDescription : result?.description;
  const season = result?.seasonTitle;
  const episode = result?.episode;
  // const episodeTitle = result?.episodeTitle;

  const [showOverlay, setShowOverlay] = useState(false);

  const hideOverlay = useCallback(() => {
    clearTimeout(timerRef.current);
    setShowOverlay(false);
  }, []);

  const startOverlayTimer = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setShowOverlay(true);
    }, [constants.videoPlayer.youreWatchingOverlayDelay]);
  }, []);

  const onMouseMoveHandler = useCallback(() => {
    hideOverlay();
    startOverlayTimer();
  }, [hideOverlay, startOverlayTimer]);

  useEffect(() => {
    hideOverlay();
    if (!playing) {
      startOverlayTimer();
    }
  }, [playing, hideOverlay, startOverlayTimer]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMoveHandler);

    return () => {
      document.removeEventListener('mousemove', onMouseMoveHandler);
    };
  }, [onMouseMoveHandler]);

  if (!showOverlay || playing) {
    return null;
  }

  return (
    <div
      className={styles.container}
    >
      <div className={styles.contentContainer}>
        <div />
        <div className={styles.contentWrapper}>
          <div className={styles.title}>{t('videoPlayer.youreWatching')}</div>
          <div className={styles.videoTitle}>{title}</div>
          <div className={styles.videoInfoHolder}>
            {season && (
            <div className={styles.season}>
              {season}
            </div>
            )}
            {!season || !episode ? '' : '-'}
            {episode && (
            <div className={styles.episode}>
              <span className={styles.episodeNbr}>
                {`${t('videoPlayer.episodeAbbv')} ${episode}`}
              </span>
            </div>
            )}
          </div>
          {(!episode || !season) && (
          <div className={styles.description}>{parse(description)}</div>
          )}
        </div>
        <div className={styles.paused}>{t('videoPlayer.paused')}</div>
      </div>
    </div>
  );
};

export default memo(YoureWatching);
