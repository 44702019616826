import {
  POST_CLICKED_AD,
  POST_SKIPED_AD,
  POST_WATCHED_AD,
} from "../Constants/AdvertisementAnalyticsConstants";

export const advertismentClicked = (id) => ({
  type: POST_CLICKED_AD,
  payload: {
    request: {
      url: `api/analytics/campaigns/${id}`,
      method: "POST",
      authenticated: true,
      data: { event: "click" },
    },
  },
});

export const advertismentSkiped = (id) => ({
  type: POST_SKIPED_AD,
  payload: {
    request: {
      url: `api/analytics/campaigns/${id}`,
      method: "POST",
      authenticated: true,
      data: { event: "skip" },
    },
  },
});

export const advertismentWatched = (id) => ({
  type: POST_WATCHED_AD,
  payload: {
    request: {
      url: `api/analytics/campaigns/${id}`,
      method: "POST",
      authenticated: true,
      data: { event: "watch" },
    },
  },
});
