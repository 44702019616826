import { combineReducers } from 'redux';
import {
  GET_ABOUT_US, GET_STATIC_PRIVACY, GET_TERMS_AND_CONDITIONS, GET_CONTACT_US,
} from '../Constants/StaticScreenConstants';

const initialState = {
  loading: false,
  result: null,
  error: null,
};

const commonReducer = (state = initialState, action, actionPrefix) => {
  if (action.type.startsWith(actionPrefix)) {
    switch (action.type) {
      case actionPrefix: {
        return {
          ...state,
          error: null,
          loading: true,
        };
      }
      case `${actionPrefix}_SUCCESS`: {
        return {
          ...state,
          loading: false,
          result: action.payload,
        };
      }
      case `${actionPrefix}_FAIL`: {
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      }
      case `CLEAR_${actionPrefix}`: {
        return initialState;
      }
      default:
        return state;
    }
  } else {
    return state;
  }
};

function createFilteredReducer(reducerFunction, reducerPredicate, actionPrefix) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerFunction(state, action, actionPrefix) : state;
  };
}

export default combineReducers({
  contactUs: createFilteredReducer(commonReducer, (action) => action.type.startsWith(GET_CONTACT_US), GET_CONTACT_US),
  privacyPolicy: createFilteredReducer(commonReducer, (action) => action.type.startsWith(GET_STATIC_PRIVACY), GET_STATIC_PRIVACY),
  aboutUs: createFilteredReducer(commonReducer, (action) => action.type.startsWith(GET_ABOUT_US), GET_ABOUT_US),
  termsAndConditions:
    createFilteredReducer(commonReducer, (action) => action.type.startsWith(GET_TERMS_AND_CONDITIONS), GET_TERMS_AND_CONDITIONS),
});
