import { isSafari } from 'react-device-detect';
import {
  GET_DRM_TOKEN, CLEAR_DRM_TOKEN,
} from '../Constants/drmTokenConstants';

/* eslint-disable  import/prefer-default-export */

export const getDRMToken = (video) => ({
  type: GET_DRM_TOKEN,
  payload: {
    request: {
      url: 'drm/token',
      method: 'POST',
      authenticated: true,
      data: {
        drmType: isSafari ? 'Fairplay' : 'Widevine',
        videoId: video,
      },
    },
  },
});

export const clearDRMToken = () => ({
  type: CLEAR_DRM_TOKEN,
  payload: null,
});
