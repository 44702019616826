import { SubscriptionSource } from "@Store/Reducers/subscriptionsSourceReducer";
import { Platform, PurchasableMedia, Language, IPaymentInfo, PurchaseType } from "@Utils/commonTypes";
import { TFunction } from "i18next";
import { getMovieDetails, fetchTVSource } from "src/alballam-api";


/**
 * Retrieves the subscription source based on the provided platform, media type, media ID, language, and web subscription source.
 * 
 * @param platform - The platform from which the request is made (e.g., WEB, TV).
 * @param paramMediaType - The type of media being purchased (e.g., Movie, Episode).
 * @param paramMediaId - The ID of the media being purchased.
 * @param paramLanguage - The language preference for the subscription source.
 * @param webSubscriptionSource - The subscription source information for the web platform.
 * 
 * @returns A promise that resolves to the subscription source information.
 */
export async function getSubscriptionSource(
    platform: Platform,
    paramMediaType: PurchasableMedia,
    paramMediaId: string,
    paramLanguage: Language,
    webSubscriptionSource: SubscriptionSource
): Promise<SubscriptionSource> {
    let inputSource = webSubscriptionSource;

    if (platform !== Platform.WEB) {
        let tvSubscriptionSource: SubscriptionSource = webSubscriptionSource;

        if (paramMediaType === PurchasableMedia.Movie) {
            const tvSourceData = await getMovieDetails(paramMediaId);
            tvSubscriptionSource = {
                language: paramLanguage,
                sourceType: PurchasableMedia.Movie,
                movieName: tvSourceData?.result?.titles?.en ?? 'could-not-find-movie-name',
                movieId: paramMediaId,
            };
            inputSource = tvSubscriptionSource;
        } else {
            const tvSourceData = await fetchTVSource(paramMediaId);
            tvSubscriptionSource = {
                language: paramLanguage,
                sourceType: PurchasableMedia.Episode,
                episodeId: tvSourceData.result.episodeId.toString(),
                episodeName: tvSourceData.result.episodeTitle,
                seasonId: tvSourceData.result.seasonId.toString(),
                seasonName: tvSourceData.result.seasonTitle,
                seriesId: tvSourceData.result.seriesId.toString(),
                seriesName: tvSourceData.result.seriesTitle,
            };
            inputSource = tvSubscriptionSource;
        }
    }

    return inputSource;
}


/**
 * Retrieves the appropriate subtext based on the payment information and purchase type.
 *
 * @param {IPaymentInfo} paymentInfo - The payment information object containing details about the purchase.
 * @param {TFunction} t - The translation function used to get the localized subtext.
 * @returns {string} The localized subtext corresponding to the purchase type and media type.
 *
 * @remarks
 * - If the purchase type is media, the function checks the media type to determine the subtext.
 * - For episodes, it returns the localized string for episode subtext.
 * - For seasons, it returns the localized string for season subtext.
 * - For movies (indicated by media type 'video'), it returns the localized string for movie subtext.
 * - If the purchase type is not media, it assumes a subscription and returns the localized string for subscribing to all content.
 */
export function getSubtext(paymentInfo: IPaymentInfo, t: TFunction) {
    if (paymentInfo?.purchaseType === PurchaseType.Media) {
        if (paymentInfo.mediaType === PurchasableMedia.Episode) {
            return t('payment.episodeSubText');
        }
        if (paymentInfo.mediaType === PurchasableMedia.Season) {
            return t('payment.seasonSubText');
        }

        //@ts-expect-error media type for movies is video
        if (paymentInfo.mediaType === 'video') {
            return t('payment.movieSubText');
        }

        // assume subscription
    } else {
        return t('payment.subscribeAllSubText');
    }
}

