import {
  GET_ABOUT_US, GET_STATIC_PRIVACY, GET_TERMS_AND_CONDITIONS, GET_CONTACT_US,
} from '../Constants/StaticScreenConstants';
import constants from '../constants';

const generateAction = (actionType) => {
  const data = {
    type: '',
    payload: {
      request: {
        url: '',
        method: 'get',
        authenticated: false,
      },
    },
  };
  try {
    switch (actionType) {
      case constants.staticApis.privacyPolicy:
        data.type = GET_STATIC_PRIVACY;
        data.payload.request.url = 'api/privacyPolicy';
        break;
      case constants.staticApis.aboutUs:
        data.type = GET_ABOUT_US;
        data.payload.request.url = 'api/aboutUs';
        break;
      case constants.staticApis.termsAndConditions:
        data.type = GET_TERMS_AND_CONDITIONS;
        data.payload.request.url = 'api/termsAndConditions';
        break;
      case constants.staticApis.contactUs:
        data.type = GET_CONTACT_US;
        data.payload.request.url = 'api/contactUs';
        break;
      default:
        break;
    }
    return data;
  } catch {
    return data;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getStatic = (actionType) => generateAction(actionType);
