const GET_USER_SESSION = 'GET_USER_SESSION';
const GET_USER_SESSION_SUCCESS = 'GET_USER_SESSION_SUCCESS';
const GET_USER_SESSION_FAIL = 'GET_USER_SESSION_FAIL';
const CLEAR_USER_SESSION = 'CLEAR_USER_SESSION';

const UPDATE_CONTINUE_WATCHING = 'UPDATE_CONTINUE_WATCHING';
const END_CONTINUE_WATCHING = 'END_CONTINUE_WATCHING';

export {
  CLEAR_USER_SESSION,
  GET_USER_SESSION_SUCCESS,
  GET_USER_SESSION_FAIL,
  GET_USER_SESSION,
  END_CONTINUE_WATCHING,
  UPDATE_CONTINUE_WATCHING,
};
