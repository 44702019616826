import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axiosMiddleware from 'redux-axios-middleware';
import { showPaymentAlert } from '../Store/Actions/ShowPaymentAlertActions';
import { clearAuthentication, saveAuthentication } from '../Store/Actions/AuthenticationActions';
import { clearUserProfile } from '../Store/Actions/UserActions';
import { clearVideosTracking } from '../Store/Actions/videoDashboard';
import config from './config';
import { store } from '@Store/store';
import i18n from '../i18n';

const appClient = axios.create({
  baseURL: config.apiUrl,
  timeout: config.apiTimeout,
});

const refreshToken = () => {
  const body = { refresh_token: '' };
  return appClient.post('/api/refreshToken', body);
};

createAuthRefreshInterceptor(appClient, refreshToken, { pauseInstanceWhileRefreshing: false, statusCodes: [401] });
const middlewareConfig = {
  interceptors: {
    request: [
      {
        success({ getState }, req) {
          const { authentication } = getState();
          req.headers['Accept-Language'] = authentication.language;
          if (req.authenticated) {
            req.headers.authorization = `${authentication.access_token}`;
            req.headers.profile = `${authentication.profile}`;
          }
          if (req.url === '/api/refreshToken') {
            req.headers.authorization = authentication.refresh_token_header;
            req.data.refresh_token = authentication.refresh_token;
          }
          return req;
        },
      },
    ],
    response: [
      {
        success({ dispatch, getState }, res) {
          const { authentication } = getState();
          if (res.config.url === 'api/users/logout') {
            dispatch(clearAuthentication());
            dispatch(clearUserProfile());
            dispatch(clearVideosTracking());
            dispatch(showPaymentAlert(false));
          }
          if (res.config.url === '/api/refreshToken') {
            dispatch(saveAuthentication({
              ...res.data.data.result,
              isSignedIn: authentication.isGuest ? null : true,
              isGuest: authentication.isGuest,
            }));
            dispatch(showPaymentAlert(false));
          }
          return Promise.resolve(res);
        },
        error({ dispatch }, res) {
          if (res.config.url === '/api/refreshToken') {
            dispatch(clearAuthentication());
            dispatch(showPaymentAlert(false));
            setTimeout(() => {
              window.location.reload();
            }, [100]);
            return;
          }
          return Promise.reject(res);
        },
      },
    ],
  },
};
export { appClient };
export default axiosMiddleware(appClient, middlewareConfig);
