/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSession, updateContinueWatching } from '../../Store/Actions/UserSessionActions';
import constants from '../../Utils/constants';

/**
 * Custom hook for managing session state.
 * @returns {{
 *   onKillSessionClick: Function,
 *   sessionState: Object,
 *   isKillSession: boolean,
 *   sessionId: string
 * }}
 */
export function useSession() {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.userSession);
  const [sessionState, setSessionState] = useState({ hasEnteredSession: false, isLoading: false });

  useEffect(() => {
    dispatch(getUserSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session?.isKillSession === false) setSessionState((prev) => ({ ...prev, hasEnteredSession: true }));
    else setSessionState((prev) => ({ ...prev, hasEnteredSession: false }));
  }, [session?.isKillSession]);

  useEffect(() => {
    if (!session || session?.isKillSession === true) return;
    const timer = setInterval(() => dispatch(updateContinueWatching(session?.uniqueID)), constants.videoPlayer.updateTrackInterval * 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const onKillSessionClick = useCallback(async () => {
    if (session.isKillSession && typeof session.isKillSession === 'boolean') {
      setSessionState((prev) => ({ ...prev, isLoading: true }));

      try {
        await dispatch(getUserSession());
      } finally {
        setSessionState((prev) => ({ ...prev, isLoading: false }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, session]);

  return {
    onKillSessionClick,
    sessionState,
    isKillSession: session?.isKillSession,
    sessionId: session?.uniqueID,
  };
}
