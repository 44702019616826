import {
  CLEAR_ACTORS, GET_ACTORS, CLEAR_AWARDS, GET_AWARDS,
} from '../Constants/CastAndCrewConstants';

export const clearActors = () => ({
  type: CLEAR_ACTORS,
  payload: null,
});

export const clearAwards = () => ({
  type: CLEAR_AWARDS,
  payload: null,
});

export const getActorsMoviesShows = (id) => ({
  type: GET_ACTORS,
  payload: {
    request: {
      url: `api/videoLanding/actor/${id}`,
      method: 'get',
    },
  },
});

export const getAwardsMoviesShows = (id) => ({
  type: GET_AWARDS,
  payload: {
    request: {
      url: `api/videoLanding/award/${id}`,
      method: 'get',
    },
  },
});
