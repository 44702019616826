import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import ClickableItem from '../../Atoms/ClickableItem/ClickableItem';
import arrowRight from '../../../Assets/Images/back/icons-arrows-arrow-right.svg';
import styles from './PaymentContainer.module.scss';

const PaymentContainer = ({
  children,
  title,
  onClick,
  contentClassName,
  isPaymentRedirection,
}) => {
  const { language } = useSelector((state) => state.authentication);
  return (
    <Container fluid className={styles.paymentContainer}>
      <div className="w-100">
        <div className={classNames(styles.content, contentClassName)}>
          <div className={styles.formContent}>
            <div className={styles.titleContainer}>
              {!isPaymentRedirection && (
                <ClickableItem
                  onClick={onClick}
                  classes={
                    language === 'ar' ? styles.arrowRight : styles.arrowEN
                  }
                >
                  <img src={arrowRight} alt="" className="d-md-block" />
                </ClickableItem>
              )}
              <div className={styles.title}>{title}</div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </Container>
  );
};

PaymentContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isPaymentRedirection: PropTypes.bool,
};

PaymentContainer.defaultProps = {
  children: <></>,
  isPaymentRedirection: null,
};

export default PaymentContainer;
