import React from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import './otp.scss';
import Form from 'react-bootstrap/Form';

const OTP = ({
  value, handleChange, error,
}) => (
  <>
    <OtpInput
      value={value}
      onChange={handleChange}
      numInputs={4}
      renderSeparator={() => <span className="otp-input-separator" />}
      renderInput={(inputProps) => (<input {...inputProps} />)}
      containerStyle="otp-container"
      inputStyle="otp-input"
      focusStyle="otp-focus"
    />
    {error && (
      <Form.Text className="error-message">
        {error}
      </Form.Text>
    )}
  </>
);

OTP.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

OTP.defaultProps = {
  error: '',
};

export default OTP;
