import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickableItem from '../ClickableItem/ClickableItem';
import styles from './PlayerButton.module.scss';

const PlayerButton = ({
  title,
  onClick,
  filled,
  animated,
  animationDuration,
  stopAnimation,
  className,
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <ClickableItem
      classes={classNames(
        styles.container,
        filled ? styles.filled : !animated ? styles.notFilled : styles.animated,
        animated && mounted ? styles.animatedTrue : '',
        className,
      )}
      style={{
        transition:
          animationDuration && !stopAnimation
            ? `all ${animationDuration}s`
            : '',
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <span className={styles.title}>{title}</span>
    </ClickableItem>
  );
};

PlayerButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  filled: PropTypes.bool,
  animated: PropTypes.bool,
  animationDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stopAnimation: PropTypes.bool,
  className: PropTypes.string,
};

PlayerButton.defaultProps = {
  filled: false,
  animated: false,
  animationDuration: null,
  stopAnimation: false,
  className: '',
};

export default React.memo(PlayerButton);
