import { isMobile } from 'react-device-detect';
import DesktopLayout from './DesktopLayout/DesktopLayout';
import MobileLayout from './MobileLayout/MobileLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from '@Utils/constants';

function BackButton() {
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        const doesAnyHistoryEntryExist = location.key !== 'default';
        const previousPageWasProfileSelection = location.state?.[constants.locationState.requireProfile] === true;
        if (!doesAnyHistoryEntryExist || previousPageWasProfileSelection || location?.state?.prevPage === undefined) {
            navigate(constants.screens.browse);
        } else {
            navigate(-1);
        }
    }



    if (isMobile) return <MobileLayout goBack={goBack} />;
    return <DesktopLayout goBack={goBack} />;
}

export default BackButton