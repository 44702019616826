import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const MessageBox = ({ message, isError, isForgotPassword, setFieldValue, className }) => {
  useEffect(() => {
    if (setFieldValue) {
      setFieldValue('otp', '');
    }
  }, [setFieldValue]);

  return (
    <div
      className={classNames(
        styles.generalContainer,
        { [styles.isForgotPassword]: isForgotPassword },
        isError ? styles.errorContainer : styles.successContainer, 'text-center', className)}
    >
      <p dir='auto' className={isError ? styles.errorMsg : styles.successMsg}>
        {message}
      </p>
    </div>
  );
};

MessageBox.propTypes = {
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  isForgotPassword: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

MessageBox.defaultProps = {
  isError: true,
  isForgotPassword: false,
  setFieldValue: null,
};

export default MessageBox;
