import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isMobile, isSafari } from 'react-device-detect';
// import mux from 'mux-embed';
import ErrorBoundary from '../../Components/Atoms/ErrorBoundary';
import VideoPlayerControls from '../../Components/Molecules/VideoPlayerControls';
import VideoPlayer from '../../Components/Atoms/VideoPlayer';
import VideoPlayerLoader from '../../Components/Atoms/VideoPlayerLoader/VideoPlayerLoader';
import ThumbnailLoader from '../../Components/Atoms/ThumbnailLoader/ThumbnailLoader';
import { setRadioOn } from '../../Store/Actions/StreamActions';
import { clearUserSession } from '../../Store/Actions/UserSessionActions';
import config from '../../Utils/config';
import firebase from '../../Utils/firebase';
import { useSession } from './helpers';
import AutoRenew from '../../Components/Atoms/autoRenew/AutoRenew';
import styles from './Live.module.scss';
import { getLiveChannelById } from '../../Utils/common';
import constants from '../../Utils/constants';
import '../Watch/index.scss';

const initialState = {
  playing: !(isMobile && isSafari),
  urlToPlay: '',
  buffering: false,
  volume: 1,
  muted: true,
  safariMobileVolume: 0,
};

function useLoadLiveChannel() {
  const [channel, setChannel] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchChannel = async () => setChannel(await getLiveChannelById(dispatch, id));

    fetchChannel();
  }, [dispatch, id]);

  return { channel };
}

const Live = () => {
  const { result } = useSelector((state) => state.videoSettings);
  const [status, setStatus] = useState(initialState);
  const videoPlayerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasEntered = useRef(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const {
    sessionState,
    isKillSession,
    onKillSessionClick,
    sessionId,
  } = useSession();
  const hasRun = useRef(false);
  const { channel } = useLoadLiveChannel();
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    if (config.env !== config.environments.dev) {
      firebase.analytics().logEvent('screen_view', {
        firebase_screen: 'liveStreamVideo',
      });
    }
  }, []);

  useEffect(() => {
    if (!hasRun.current) {
      const videoSelector = document.getElementsByTagName('video')[0];

      setTimeout(() => {
        if (
          videoSelector
          && videoSelector?.paused
          && result
          && status
          && !status?.muted
        ) {
          hasRun.current = true;
          setStatus((prevState) => ({
            ...prevState,
            playing: false,
          }));
        }
      }, 0);
    }
  }, [result, status]);

  useEffect(() => {
    const videoSelector = document.getElementsByTagName('video')[0];

    if (videoSelector && !hasEntered?.current) {
      hasEntered.current = true;
      // const initTime = mux.utils.now();
      // mux.monitor(videoSelector, {
      //   debug: false,
      //   data: {
      //     env_key: config.muxKey,
      //     player_name: 'Website Video Player',
      //     player_init_time: initTime,
      //     video_title: channel?.title,
      //   },
      // });
    }
  }, [result, channel, status]);

  const onBackHandler = useCallback(() => {
    if (sessionId) dispatch(clearUserSession(sessionId));
    if (state?.isLiveFlow === true || window.history.length <= 1) {
      navigate(constants.screens.browse);
      return;
    }
    navigate(-1);
  }, [sessionId, dispatch, state?.isLiveFlow, navigate]);

  useEffect(() => {
    dispatch(setRadioOn(false));
    if (channel === undefined) {
      onBackHandler();
    }
    if (channel === null) {
      return;
    }
    const unauthorizedUser = channel?.paid === true && channel?.owned !== true;
    const channelDoesNotExist = channel?.url === null || channel?.url?.trim().length <= 1;
    if (channelDoesNotExist || unauthorizedUser || channel?.comingSoon === true) {
      onBackHandler();
      return;
    }
    setStatus((prevState) => ({
      ...prevState,
      urlToPlay: channel?.url,
    }));
  }, [onBackHandler, dispatch, channel]);

  useEffect(() => {
    if ('mediaSession' in navigator && result?.header?.liveStream?.titleImage) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: channel?.title,
        artwork: [
          {
            src: channel?.titleImage ?? ' ',
            sizes: '96x96',
            type: 'image/png',
          },
          {
            src: channel?.titleImage ?? ' ',
            sizes: '128x128',
            type: 'image/png',
          },
          {
            src: channel?.titleImage ?? ' ',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: channel?.titleImage ?? ' ',
            sizes: '256x256',
            type: 'image/png',
          },
          {
            src: channel?.titleImage ?? ' ',
            sizes: '384x384',
            type: 'image/png',
          },
          {
            src: channel?.titleImage ?? ' ',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      });
    }
  }, [result, channel]);

  const onPlayHandler = useCallback(() => {
    if (isSafari && isMobile) {
      if (status.playing) {
        document.getElementsByTagName('video')[0].pause();
      } else document.getElementsByTagName('video')[0].play();
    }
    return setStatus((prevState) => ({
      ...prevState,
      playing: !prevState.playing,
    }));
  }, [status.playing]);

  const onReadyHandler = useCallback(() => {
    setStatus((prevState) => ({ ...prevState, muted: false }));
  }, []);

  const onBufferHandler = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      buffering: true,
    }));
  }, []);

  const onBufferEndHandler = useCallback(() => {
    if (isMobile && isSafari) {
      setStatus((prevState) => ({
        ...prevState,
        playing: true,
      }));
      try {
        document.getElementsByTagName('video')[0].webkitEnterFullScreen('show');
      } catch (error) {
        if (error) {
          document.getElementsByTagName('video')[0].muted = true;
          setStatus((prevState) => ({
            ...prevState,
            safariMobileVolume: true,
          }));
          document.getElementsByTagName('video')[0].play();
          document.getElementsByTagName('video')[0].autoplay = false;
        }
      }
    }
    setStatus((prevState) => ({
      ...prevState,
      buffering: false,
    }));
    setShowThumbnail(false);
  }, []);

  const onVolumeClickHandler = useCallback(
    (volume = null) => {
      if (isSafari && isMobile) {
        setStatus((prevState) => ({
          ...prevState,
          safariMobileVolume: !prevState.safariMobileVolume,
        }));
      } else {
        try {
          let newVolume = volume;
          if (newVolume === null) {
            newVolume = status.volume ? 0 : 1;
          }
          if (isMobile && !isSafari && newVolume === 1) {
            document.getElementsByTagName('video')[0].muted = false;
          }
          setStatus((prevState) => ({
            ...prevState,
            volume: +newVolume,
          }));
        } catch { }
      }
    },
    [status.volume],
  );

  const onPlayerError = useCallback(() => {
    setShowThumbnail(false);
    if (isMobile) {
      try {
        document.getElementsByTagName('video')[0].muted = true;
        setStatus((prevState) => ({
          ...prevState,
          volume: 0,
        }));
        document.getElementsByTagName('video')[0].play();
        document.getElementsByTagName('video')[0].autoplay = false;
      } catch { }
    }
  }, []);

  const renderLoader = useCallback(() => {
    if (!status.buffering || isMobile) return null;

    return <VideoPlayerLoader />;
  }, [status]);

  const renderThumbnail = useCallback(() => {
    if (!showThumbnail) return null;

    return <ThumbnailLoader title={channel?.title} onClose={onBackHandler} />;
  }, [showThumbnail, onBackHandler, channel]);

  const onFullscreenChange = useCallback(() => {
    setStatus((prevState) => ({
      ...prevState,
      playing: false,
    }));
  }, []);

  useEffect(() => {
    if (
      isMobile
      && isSafari
      && videoPlayerRef?.current
      && document.querySelectorAll('video')[0]
    ) {
      document
        .querySelectorAll('video')[0]
        .addEventListener('webkitendfullscreen', onFullscreenChange);
    }
  }, [onFullscreenChange, status]);

  return (
    <ErrorBoundary>
      <>
        {renderLoader()}
        {renderThumbnail()}
        {isKillSession && (
          <div className={styles.AutoRenew}>
            <AutoRenew isKillSession onKillSessionClick={onKillSessionClick} />
          </div>
        )}
        {status.urlToPlay && sessionState?.hasEnteredSession && (
          <div className="watch-container">
            <div className="video-player-wrapper">
              <VideoPlayerControls
                isLive
                disableKeys={showThumbnail}
                playing={status.playing}
                title={channel?.title}
                currentTime={1}
                duration={1}
                onPlay={onPlayHandler}
                onBack={onBackHandler}
                buffering={status.buffering}
                volume={
                  isSafari && isMobile
                    ? !status.safariMobileVolume
                    : status.volume
                }
                onVolumeClick={onVolumeClickHandler}
              >
                {isMobile && isSafari ? (
                  <video
                    ref={videoPlayerRef}
                    src={channel?.url}
                    playsInline
                    autoPlay
                    autoFocus
                    onLoadedMetadata={onBufferEndHandler}
                    width="100%"
                    height="100%"
                    preload="metadata"
                    muted={status.safariMobileVolume}
                  />
                ) : (
                  <VideoPlayer
                    ref={videoPlayerRef}
                    url={channel?.url}
                    playing={status.playing}
                    volume={status.volume}
                    muted={status.muted}
                    onBuffer={onBufferHandler}
                    onBufferEnd={onBufferEndHandler}
                    onEnded={onBackHandler}
                    onReady={onReadyHandler}
                    onError={onPlayerError}
                  />
                )}
              </VideoPlayerControls>
            </div>
          </div>
        )}
      </>
    </ErrorBoundary>
  );
};

export default Live;