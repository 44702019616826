import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
} from 'react-bootstrap';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CancelSubModal = ({
  cancelSubscription,
  isCancelSubFormVisible,
  setIsCancelSubFormVisible,
  cancelSubscriptionLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.authentication);

  return (
    <Modal
      show={isCancelSubFormVisible}
      backdrop
      onHide={() => {
        setIsCancelSubFormVisible(false);
      }}
      dialogClassName="modalEditProfile"
    >
      <ModalBody
        className={
        language === 'ar' ? 'modalBodyEditProfile' : 'modalBodyEng'
      }
      >
        <div className="d-flex titleContainer justify-content-center myAccountTitleCancel">
          <div className="d-flex justify-content-center">
            <span
              className={classNames(
                language === 'ar' ? 'myAccountTitle' : 'myAccountTitleEn',
                'myAccountTitleCancel',
              )}
            >
              {t('globals.cancelSubscription')}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <span className="areYouSureCancelSub">
            {t('globals.areYouSureCancelSub')}
          </span>
        </div>
        <div className="d-flex justify-content-center mt-1">
          <Button
            disabled={cancelSubscriptionLoading}
            onClick={() => {
              dispatch(cancelSubscription()).then(() => {
                setIsCancelSubFormVisible(false);
              });
            }}
            className="FullWatchButtonCancelSub"
          >
            <span className="subscribe">{t('globals.yes')}</span>
          </Button>
          <Button
            onClick={() => {
              setIsCancelSubFormVisible(false);
            }}
            className="FullWatchButtonCancelSubNo"
          >
            <span className="subscribe">{t('globals.no')}</span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancelSubModal;
