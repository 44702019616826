import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClickableItem from '../../Atoms/ClickableItem/ClickableItem';
import styles from './ClickableList.module.scss';

const ClickableList = ({
  title, list, selectedElm, onClick,
}) => (
  <>
    <h5 className={styles.listTitle}>{title}</h5>
    <div className={styles.list}>
      {list.map((elm) => (
        <ClickableItem
          key={elm}
          classes={classNames(
            styles.noOutline,
            styles.element,
            elm === selectedElm ? styles.selected : '',
          )}
          onClick={(event) => onClick(event, elm)}
        >
          <span
            className={classNames(
              styles.noOutline,
              styles.elementName,
              elm === selectedElm ? styles.selected : '',
            )}
          >
            {elm}
          </span>
        </ClickableItem>
      ))}
    </div>
  </>
);

ClickableList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedElm: PropTypes.string,
  onClick: PropTypes.func,
};

ClickableList.defaultProps = {
  title: '',
  selectedElm: '',
  onClick: () => {},
};

export default memo(ClickableList);
