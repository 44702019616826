import SideBarItem from "@Atoms/SideBarItem/SideBarItem";
import { useTranslation } from "react-i18next";
import { ISidebarNavigationItemProps } from "./SidebarNavigationItem.types";

const SidebarNavigationItem: React.FC<ISidebarNavigationItemProps> = ({ elm, url, setSideBarData }) => {
    const { t } = useTranslation();
    const content = elm.type === 'bookmark' ? t('landing.preferences') : elm.name;
    return (
        <SideBarItem onClick={() => setSideBarData(false)} key={elm.id} url={url}>
            {content}
        </SideBarItem>
    );
};

export default SidebarNavigationItem;