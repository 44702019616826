const GET_MOVIE = 'GET_MOVIE';
const GET_MOVIE_SUCCESS = 'GET_MOVIE_SUCCESS';
const GET_MOVIE_FAIL = 'GET_MOVIE_FAIL';
const GET_TRAILER = 'GET_TRAILER';
const GET_TRAILER_SUCCESS = 'GET_TRAILER_SUCCESS';
const GET_TRAILER_FAIL = 'GET_TRAILER_FAIL';
const START_TRACKING = 'START_TRACKING';
const FINISH_TRACKING = 'FINISH_TRACKING';
const CLEAR_MOVIE = 'CLEAR_MOVIE';
const GET_MOVIE_DETAILS = 'GET_MOVIE_DETAILS';
const GET_MOVIE_DETAILS_SUCCESS = 'GET_MOVIE_DETAILS_SUCCESS';
const GET_MOVIE_DETAILS_FAIL = 'GET_MOVIE_DETAILS_FAIL';
const CLEAR_MOVIE_DETAILS = 'CLEAR_MOVIE_DETAILS';

export {
  GET_MOVIE,
  GET_MOVIE_SUCCESS,
  GET_MOVIE_FAIL,
  GET_TRAILER,
  GET_TRAILER_SUCCESS,
  GET_TRAILER_FAIL,
  START_TRACKING,
  FINISH_TRACKING,
  CLEAR_MOVIE,
  GET_MOVIE_DETAILS,
  GET_MOVIE_DETAILS_SUCCESS,
  GET_MOVIE_DETAILS_FAIL,
  CLEAR_MOVIE_DETAILS,
};
