/* eslint-disable camelcase */
import {
  GET_VIDEOS_TRACKING,
  GET_VIDEOS_TRACKING_FAIL,
  GET_VIDEOS_TRACKING_SUCCESS,
  CLEAR_VIDEOS_TRACKING,
  GET_VIDEO_DASHBOARD,
  GET_VIDEO_DASHBOARD_SUCCESS,
  GET_VIDEO_DASHBOARD_FAIL,
  REMOVE_CONTINUE_WATCHING,
  REMOVE_CONTINUE_WATCHING_SUCCESS,
  REMOVE_CONTINUE_WATCHING_FAIL,
} from '../Constants/videosDashboardConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
  dashboard: null,
  server_date: null,
  dashboardLoading: true,
  removeVideo: {
    loading: null,
    success: false,
    error: null,
  },
  videoData: null,
  featuredMovie: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEOS_TRACKING: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_VIDEOS_TRACKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_VIDEOS_TRACKING_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case GET_VIDEO_DASHBOARD: {
      return {
        ...state,
        error: null,
        dashboardLoading: true,
        dashboard: null,
      };
    }
    case GET_VIDEO_DASHBOARD_SUCCESS: {
      const server_date = action.payload.data.data?.server_date;
      const feat = action.payload.data.data.result
        .map((el) => el.data)
        .slice(0, 1);
      const featMovie = action.payload.data.data.result.find(
        (e) => e.type === 'featuredVideo',
      );
      return {
        ...state,
        dashboardLoading: false,
        dashboard: action.payload.data.data.result,
        videoData: feat,
        featuredMovie: featMovie,
        server_date,
      };
    }
    case GET_VIDEO_DASHBOARD_FAIL: {
      return {
        ...state,
        dashboardLoading: false,
        error: action.error,
      };
    }
    case CLEAR_VIDEOS_TRACKING: {
      return {
        ...state,
        loading: false,
        error: action.error,
        result: null,
      };
    }
    case REMOVE_CONTINUE_WATCHING: {
      return {
        ...state,
        removeVideo: {
          loading: action.payload.id,
          success: false,
          error: null,
        },
      };
    }
    case REMOVE_CONTINUE_WATCHING_SUCCESS: {
      return {
        ...state,
        removeVideo: {
          loading: action.payload.id,
          success: true,
          error: null,
        },
        result: state.result.filter(
          (e) => e.video.playerId !== state.removeVideo.loading,
        ),
      };
    }
    case REMOVE_CONTINUE_WATCHING_FAIL: {
      return {
        ...state,
        removeVideo: {
          loading: null,
          success: false,
          error: action.error,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
