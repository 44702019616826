import { useLoaderData, useParams } from 'react-router-dom';
import Hero from './Hero/Hero';
import Details from './Hero/Details/Details';
import styles from './SeriesDetails.module.scss';
import SeasonSection from '../../Components/Organisms/SeasonSection/SeasonSection';
import { BrowserView, MobileView } from 'react-device-detect';
import BackButton from '@Atoms/BackButton/BackButton';
import { Fragment } from 'react/jsx-runtime';
import PaymentModal from '@Molecules/PaymentModalNouveau/PaymentModal';
import { IDetailsPageLoaderData, MediaType } from '@Utils/commonTypes';
import useSelectedSeason from '@Hooks/useSelectedSeason';

function SeriesDetails() {
    const { series } = useLoaderData() as IDetailsPageLoaderData;
    const seriesContent = series!.result;
    const { seriesId, seasonId } = useParams();
    const { currentSeason, seasonsArray, setCurrentSeason } = useSelectedSeason(seriesContent?.seasons, seasonId, seriesId!, MediaType.Series, seriesContent?.player);

    return (
        <Fragment>
            <div className={styles.root}>
                <BrowserView>
                    <Hero {...seriesContent} currentSeason={currentSeason} image={seriesContent.featuredImageLandscape} playing>
                        {({ playerStatus, setPlayerStatus }) => {
                            return (
                                <Details
                                    id={seriesId!}
                                    series={seriesContent}
                                    isPlaying={playerStatus.isPlaying}
                                    muted={playerStatus.isMuted}
                                    playerStatus={playerStatus}
                                    setPlayerStatus={setPlayerStatus}
                                />
                            )
                        }}
                    </Hero>
                </BrowserView>
                <MobileView>
                    <BackButton />
                    <Hero {...seriesContent} currentSeason={currentSeason} playing={false} />
                    <Details id={seriesId!} series={seriesContent} muted={true} isPlaying={true} />
                </MobileView>
                <SeasonSection
                    key={seasonId}
                    media={seriesContent}
                    currentSeason={currentSeason}
                    mediaId={seriesId!}
                    seasonsArray={seasonsArray}
                    mediaType={MediaType.Series}
                    setCurrentSeason={setCurrentSeason}
                />
            </div>
            <PaymentModal />
        </Fragment>
    )
}

export default SeriesDetails