import {
  GET_SERIES_FILTERED,
  GET_SERIES_FILTERED_FAIL,
  GET_SERIES_FILTERED_SUCCESS,
  CLEAR_SERIES_FILTERED,
} from '../../Constants/SeriesConstants';

const initialState = {
  loading: null,
  result: null,
  data: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERIES_FILTERED: {
      const {
        params: { page },
      } = action.payload.request;
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
        data: page === 1 ? [] : state.data,
      };
    }
    case GET_SERIES_FILTERED_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
        data: state.data.concat([action.payload.data.data.result.data]),
      };
    }
    case GET_SERIES_FILTERED_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_SERIES_FILTERED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default reducer;
