/* eslint-disable */
import React, { forwardRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import constants from '../../../Utils/constants';
import './index.scss';

const config = {
  file: {
    // forceDASH: true,
    attributes: {
      controlsList: 'nodownload',
      disablePictureInPicture: true,
      autoPlay: false,
    },
  },
};

const VideoPlayer = forwardRef(
  (
    {
      url,
      onPlay,
      onProgress,
      onEnded,
      onStart,
      style,
      onReady,
      playing,
      onSeekHandler,
      onDurationHandler,
      onBuffer,
      onBufferEnd,
      volume,
      muted,
      onError,
      detailsScreen,
    },
    ref,
  ) => (
  <ReactPlayer
    className={'watch-video-player'}
    ref={ref}
    url={url}
    style={style}
    volume={volume}
    onPlay={onPlay}
    onStart={onStart}
    onProgress={onProgress}
    onDuration={onDurationHandler}
    onSeek={onSeekHandler}
    onBuffer={onBuffer}
    onBufferEnd={onBufferEnd}
    progressInterval={constants.videoProgressDuration}
    width="100%"
    height="100%"
    config={config}
    onEnded={onEnded}
    onReady={onReady}
    playing={playing}
    muted={muted}
    onError={onError}
    // data-setup="{}"
  />
  ),
);
VideoPlayer.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onPlay: PropTypes.func,
  onProgress: PropTypes.func,
  onEnded: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onReady: PropTypes.func,
  onStart: PropTypes.func,
  playing: PropTypes.bool,
  onSeekHandler: PropTypes.func,
  onDurationHandler: PropTypes.func,
  onBuffer: PropTypes.func,
  onBufferEnd: PropTypes.func,
  volume: PropTypes.number,
  muted: PropTypes.bool,
  onError: PropTypes.func,
};
VideoPlayer.defaultProps = {
  style: { display: 'block', width: 'auto', margin: '0 auto' },
  onReady: () => null,
  onStart: () => null,
  onPlay: () => null,
  onProgress: () => null,
  onEnded: () => null,
  playing: true,
  onSeekHandler: () => null,
  onDurationHandler: () => null,
  onBuffer: () => null,
  onBufferEnd: () => null,
  volume: 0,
  muted: true,
  onError: () => null,
};
export default VideoPlayer;
