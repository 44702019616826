

export const setCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return null;
};


export const deleteCookie = (name: string) => {
    const date = new Date();
    date.setTime(date.getTime() - (24 * 60 * 60 * 1000)); // Set the date to one day in the past
    document.cookie = `${name}=;expires=${date.toUTCString()};path=/`;
};