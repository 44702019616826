import { IGenericTrailer } from "@Utils/commonTypes";

export interface IHoveredCard {
    id: string;
    duration: number;
}

export const getThumbnailOrTrailer = (thumbnail: string, trailers: IGenericTrailer[] | undefined, isPortrait: boolean) => {
    let thumbnailOrTrailer = thumbnail;
    if (trailers && trailers?.length > 0) {
        thumbnailOrTrailer = isPortrait ? trailers[trailers.length - 1].portraitLink : trailers[trailers.length - 1].link;
    }

    return thumbnailOrTrailer;
}

export const initialPlayerState = {
    muted: true,
    loaded: false,
    showPlayer: false,
    hasEnded: false,
}