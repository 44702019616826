import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import Loader from '@Atoms/Loader/Loader';
import styles from './PageWrapper.module.scss';
import { IPageWrapperProps } from './PageWrapper.types';
import { useAppSelector } from '@Store/hooks';

const PageWrapper: React.FC<PropsWithChildren<IPageWrapperProps>> = ({ children, featuredVideoData, loading }) => {
  const { loading: playerStatusLoading } = useAppSelector<{ loading: boolean; }>((state) => state.playerStatus);
  const hasFeaturedVideo = false;

  if (loading || playerStatusLoading) {
    return (
      <div className={styles.pageWrapperLoader}>
        <Loader size={44} />
      </div>
    );
  }
  return (
    <div className={classNames(
      'qnetwork-container',
      isMobile ? styles.overflowMobile : '',
      featuredVideoData && hasFeaturedVideo ? 'clipTop' : !featuredVideoData && hasFeaturedVideo ? 'downTop' : '',
    )}
    >
      {children}
    </div>
  );
};

export default PageWrapper;