import {
  GET_USER_PER_PROFILE,
  UPDATE_USER_PER_PROFILE,
  ADD_PROFILE_PER_USER,
  DELETE_PROFILE_PER_USER,
  CLEAR_USER_PER_PROFILE,
} from '../Constants/ProfilesPerUserConstants';

const getUserPerProfile = () => ({
  type: GET_USER_PER_PROFILE,
  payload: {
    request: {
      url: 'api/profiles',
      method: 'get',
      authenticated: true,
    },
  },
});

const addProfilePerUser = (name, reader, ext) => ({
  type: ADD_PROFILE_PER_USER,
  payload: {
    request: {
      url: 'api/profiles',
      method: 'post',
      authenticated: true,
      data: ext ? { name, avatar: { data: reader[1], ext } } : { name, avatar: reader },
    },
  },
});

const updateUserPerProfile = (id, name, reader, ext) => ({
  type: UPDATE_USER_PER_PROFILE,
  payload: {
    request: {
      url: `api/profiles/${id}`,
      method: 'put',
      authenticated: true,
      data: ext ? { name, avatar: { data: reader, ext } } : { name, avatar: reader },
    },
  },
});

const deleteProfilePerUser = (id) => ({
  type: DELETE_PROFILE_PER_USER,
  payload: {
    request: {
      url: `api/profiles/${id}`,
      method: 'delete',
      authenticated: true,
    },
  },
});

const clearProfilesPerUser = () => ({
  type: CLEAR_USER_PER_PROFILE,
  payload: null,
});

// const updateUserProfile = (userName, phonecode, number) => ({
//   type: UPDATE_USER_PROFILE,
//   payload: {
//     request: {
//       url: 'api/user/profile',
//       method: 'put',
//       authenticated: true,
//       data: {
//         name: userName,
//         phoneCode: phonecode,
//         phoneNumber: number,
//       },
//     },
//   },
// });

// export const editUserImage = (reader, ext, name) => ({
//   type: EDIT_USER_IMAGE,
//   payload: {
//     request: {
//       url: '/api/user/profilePicture',
//       method: 'put',
//       authenticated: true,
//       data: { image: { data: reader[1], ext }, name },
//     },
//   },
// });

// export const deleteUserProfile = () => ({
//   type: REMOVE_USER_IMAGE,
//   payload: {
//     request: {
//       url: '/api/user/profilePicture',
//       method: 'delete',
//       authenticated: true,
//       data: {},
//     },
//   },
// });

// eslint-disable-next-line import/prefer-default-export
export {
  getUserPerProfile,
  updateUserPerProfile,
  addProfilePerUser,
  deleteProfilePerUser,
  clearProfilesPerUser,
};
