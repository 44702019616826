import React from 'react';
import { Image, Dropdown, NavItem, DropdownMenu } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import constants from '../../../Utils/constants';
import { useAppSelector } from '@Store/hooks';
import logoutIcon from '../../../Assets/Images/Icons/icons-log-out.svg';
import EditIcon from '@Assets/Images/nouveau/edit.svg';
import ProfileIcon from '@Assets/Images/nouveau/account.svg';
import CartIcon from '@Assets/Images/nouveau/cart.svg';
import { NavLink, useRevalidator } from 'react-router-dom';
import styles from './AuthDropDown.module.scss';
import PlaceholderUserImage from '@Assets/Images/user.png';
import { logoutUser } from '@Store/Actions/AuthenticationActions';
import { showPaymentAlert } from '@Store/Actions/ShowPaymentAlertActions';
import classNames from 'classnames';
import { clearSubscriptionPackages } from '@Store/Actions/SubscriptionPackagesActions';
import { clearUserPurchases } from '@Store/Actions/UserPurchasesActions';

const AuthDropDown = ({ handleSidebar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language, isSignedIn, profile: idProfile } = useAppSelector((state) => state.authentication);
  const { result: profiles } = useAppSelector((state) => state.ProfilesPerUser);
  const revalidator = useRevalidator();

  const dropdownAlignment = language === 'ar' ? 'start' : 'end';
  const onLogout = () => {
    dispatch(logoutUser()).then(() => dispatch(showPaymentAlert(false)));
    dispatch(clearSubscriptionPackages());
    dispatch(clearUserPurchases());
    handleSidebar(false);
    revalidator.revalidate();
  };

  return (
    <Dropdown as={NavItem} id="basic-nav-dropdown">
      <Dropdown.Toggle as={'div'} className={styles.toggle}>
        <LazyLoadImage
          src={profiles?.data?.find((e) => e.id === idProfile)?.avatar || PlaceholderUserImage}
          alt="user pic"
          onError={(e) => { e.target.src = PlaceholderUserImage; }}
          className={`thumbnail-image ${language === 'ar' ? 'profileMarginAr' : 'profileMarginEn'}`}
        />
      </Dropdown.Toggle>
      <DropdownMenu align={dropdownAlignment} variant='secondary'>
        <Dropdown.Item as={NavLink} data-rr-ui-dropdown-item to={constants.newScreens.profiles.view} className={styles.link}>
          <Image src={EditIcon} alt="edit-profile" width="20" height="20" />
          <span>{t('profiles.editProfileUser')}</span>
        </Dropdown.Item>
        <Dropdown.Item as={NavLink} data-rr-ui-dropdown-item to={constants.screens.profile} className={styles.link}>
          <Image src={ProfileIcon} alt="user-account" width="20" height="20" />
          <span>{t('globals.myAccount')}</span>
        </Dropdown.Item>
        <Dropdown.Item as={NavLink} data-rr-ui-dropdown-item to={constants.screens.myPurchases} className={styles.link}>
          <Image src={CartIcon} alt="user-cart" width="20" height="20" />
          <span>{t('globals.myPurchases')}</span>
        </Dropdown.Item>
        <div className='divider' />
        <Dropdown.Item as={NavLink} to={'#'} onClick={onLogout} className={classNames(styles.link, styles.logoutItem)}>
          <Image src={logoutIcon} alt="user-logout" width="20" height="20" />
          <span>{isSignedIn ? t('globals.logout') : t('globals.login')}</span>
        </Dropdown.Item>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AuthDropDown;
