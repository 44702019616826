import React, { useState } from 'react'
import styles from './DesktopLayout.module.scss';
import { Button } from 'react-bootstrap';
import MuteButton from '@Organisms/FeaturedMediaCard/Details/MuteButton/MuteButton';
import Share from '@Assets/Images/nouveau/share-variant.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import TextTrimmer from '@Atoms/TextTrimmer/TextTrimmer';
import BackButton from '../../../../../Components/Atoms/BackButton/BackButton';
import InformationGroup from '../../../../../Components/Atoms/InformationGroup/InformationGroup';
import { IDetailsProps } from '../Details.types';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const DesktopLayout: React.FC<IDetailsProps> = ({
    generalText,
    muteButtonStatus,
    onClickMute,
    showMuteButton,
    description,
    rating,
    releaseDate,
    numberOfSeasons,
    genres,
    onClickPrimaryButton,
    isBookmarksButtonDisabled,
    isPlayButtonDisabled,
    onClickBookmarksButton,
    copyPathToClipboard,
    PlayButtonContent,
    BookmarkButtonContent,
    titleImage,
}) => {
    const { t } = useTranslation();
    const [showNotification, setShowNotification] = useState(false);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

    const handleToggleDescription = () => {
        setIsDescriptionExpanded(!isDescriptionExpanded);
    };

    const handleCopyLink = () => {
        copyPathToClipboard();
        setShowNotification(true);

        setTimeout(() => setShowNotification(false), 2000);
    };

    return (
        <div className={styles.container}>
            <BackButton />
            <div className={styles.overlayInterface}>
                {titleImage && <LazyLoadImage className={styles.mediaLogo} src={titleImage} />}
                <InformationGroup genres={genres} numberOfSeasons={numberOfSeasons} rating={rating} releaseDate={releaseDate} />
                {description && (
                    <div dir='auto' className={classNames(styles.description, isDescriptionExpanded && styles.expandedDescription)}>
                        <TextTrimmer text={description} more={t('globals.showMore')} less={t('globals.showLess')} lines={3} expanded={false} onClick={handleToggleDescription} />
                    </div>
                )}
                <div className={styles.actionsContainer}>
                    <div className={styles.buttonsGroup}>
                        <Button
                            onClick={onClickPrimaryButton}
                            disabled={isPlayButtonDisabled}
                            className={classNames(styles.button, styles.flexedButton)}
                            variant='primary'
                            size='lg'
                        >
                            {PlayButtonContent}
                        </Button>
                        <Button
                            onClick={onClickBookmarksButton}
                            className={classNames(styles.button, styles.flexedButton)}
                            disabled={isBookmarksButtonDisabled}
                            variant='secondary'
                            size='lg'
                        >
                            {BookmarkButtonContent}
                        </Button>
                        <Button variant='secondary' onClick={handleCopyLink} size='lg' className={classNames(styles.button, styles.shareButton)}>
                            <img src={Share} alt='share button' />
                        </Button>
                        {showNotification && (
                            <div className={styles.notification}>
                                <span>{t('globals.linkCopied')}</span>
                            </div>
                        )}
                    </div>
                    <MuteButton className={styles.muteButton} status={muteButtonStatus} shouldRender={showMuteButton} onClick={onClickMute} />
                </div>
                {generalText && <div className={styles.tag}>{generalText}</div>}
            </div>
            <div className={styles.topGradient} />
            <div className={styles.startGradient} />
        </div>
    )
}

export default DesktopLayout