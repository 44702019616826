import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Row, Col } from "react-bootstrap";
import constants from "../../../Utils/constants";
import { InputField, SubmitInput, TextSeparator } from "../../Atoms";
import SocialMediaLogin from "../../Molecules/SocialMediaLogin";
import config from "../../../Utils/config";
import MessageBox from "../../Atoms/MessageBox";
import styles from "./index.module.scss";
import TermsAndConditionsModal from "../termsandConditions/termsandConditions";
import { Link } from "react-router-dom";
import Captcha from "@Atoms/Captcha/Captcha";

const SignUpForm = ({
  onSubmit,
  signUpError,
  onGoogleLogin,
  onGoogleFailure,
  onFacebookLogin,
  onFacebookFailure,
  onAppleLogin,
  loading,
  onCaptchaChange,
  captchaValidated,
}) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const validationSchema = yup.object({
    name: yup
      .string()
      .required("validation.required")
      .matches(/^(?!\s+$).*/, "validation.required"),
    username: yup
      .string()
      .required("validation.required")
      .trim(/ +/)
      .email("validation.invalidEmail"),
    password: yup
      .string()
      .required("validation.required")
      .min(
        constants.validation.passwordMinLength,
        `${t("validation.fieldShouldBeAtLeast")} ${
          constants.validation.passwordMinLength
        } ${t("validation.characters")}`
      ),
    confirmPassword: yup
      .string()
      .required("validation.required")
      .test(
        "match",
        t("validation.passwordsDoNotMatch"),
        (value) => value === password
      ),
  });

  useEffect(() => {
    if (clicked) {
      setShowTermsError(false);
    }
  }, [clicked]);

  const showTermsAndConditionsModal = () => setShowTermsAndConditions(true);

  return (
    <Fragment>
      <div>
        <TermsAndConditionsModal
          showModal={showTermsAndConditions}
          onAgreeButtonClick={() => {
            setShowTermsAndConditions(true);
          }}
          onCloseButtonClick={() => setShowTermsAndConditions(false)}
        />
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: "",
          username: "",
          password: "",
          confirmPassword: "",
          checkBox: false,
        }}
        onSubmit={(props) => {
          if (clicked) {
            onSubmit(props);
          } else {
            setShowTermsError(true);
          }
        }}
      >
        {({ handleSubmit, handleChange, errors }) => (
          <Form noValidate onSubmit={(e) => handleSubmit(e)}>
            <div>
              <SocialMediaLogin
                facebookAppId={config.facebookAppId}
                onGoogleLogin={onGoogleLogin}
                googleAppId={config.googleAppId}
                onGoogleFailure={onGoogleFailure}
                onFacebookLogin={onFacebookLogin}
                onFacebookFailure={onFacebookFailure}
                onAppleLogin={onAppleLogin}
                appleAppId={config.appleAppId}
                appleAppRedirectUri={config.appleAppRedirectUri}
                ColNumber={3}
              />
              <div className="pt-4 pb-4">
                <TextSeparator text={t("globals.or")} />
              </div>
              <div>
                <InputField
                  name="name"
                  label={t("globals.name")}
                  type="text"
                  placeholder={t("globals.name")}
                  onChange={(e) => handleChange(e)}
                  alertText={errors.name}
                  error={!!errors.name}
                  autocomplete="off"
                />
              </div>
              <Row>
                <Col>
                  <div>
                    <InputField
                      name="username"
                      label={t("globals.emailAddress")}
                      type="email"
                      placeholder={t("globals.emailAddress")}
                      onChange={(e) => handleChange(e)}
                      alertText={errors.username}
                      error={!!errors.username}
                      autocomplete="off"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <div>
                    <InputField
                      name="password"
                      label={t("globals.password")}
                      autocomplete="new-password"
                      type="password"
                      placeholder={t("globals.password")}
                      onChange={(e) => [
                        handleChange(e),
                        setPassword(e.target.value),
                      ]}
                      alertText={errors.password}
                      error={!!errors.password}
                    />
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div>
                    <InputField
                      name="confirmPassword"
                      label={t("globals.confirmPassword")}
                      type="password"
                      placeholder={t("globals.confirmPassword")}
                      onChange={(e) => handleChange(e)}
                      alertText={errors.confirmPassword}
                      error={!!errors.confirmPassword}
                    />
                  </div>
                </Col>
              </Row>
              <span className={styles.checkboxContainer}>
                <InputField
                  checkbox
                  name="checkBox"
                  label={t("globals.agreed")}
                  as="checkbox"
                  type="checkbox"
                  onChange={() => setClicked(!clicked)}
                  alertText={errors.checkBox}
                  error={!!errors.checkBox}
                  clicked={clicked}
                />
                <span
                  className={styles.clickableLink}
                  onClick={showTermsAndConditionsModal}
                >
                  {t("globals.termsAndConditions")}
                </span>
              </span>
              <div className={styles.captchaContainer}>
                <Captcha onCaptchaChange={onCaptchaChange} />
              </div>
              {showTermsError && (
                <MessageBox message={t("signUp.termsError")} isError />
              )}
              {signUpError && <MessageBox message={signUpError} isError />}
              <div className="mt-3 d-flex justify-content-around">
                <SubmitInput
                  isDisabled={!captchaValidated}
                  value={t("globals.signUp")}
                  loading={loading}
                  size={24}
                  type="SignUp"
                  className={styles.signUpButton}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className={`${styles.dontHaveAcc} text-center pt-3`}>
        <span className="label">
          {`${t("globals.alreadHasAccountQuestion")} `}
        </span>
        <Link
          to={constants.screens.login}
          className={`${styles.link} ${styles.clickableLinkColor} label bold-text`}
        >
          {t("globals.loginNow")}
        </Link>
      </div>
    </Fragment>
  );
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  signUpError: PropTypes.string.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
  onGoogleFailure: PropTypes.func.isRequired,
  onFacebookLogin: PropTypes.func.isRequired,
  onFacebookFailure: PropTypes.func.isRequired,
  onAppleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SignUpForm;
