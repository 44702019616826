import { useEffect } from 'react';

const useScrollToTop = () => {
  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({ behavior: 'smooth' });
  }, []);
};

export default useScrollToTop;
