import React, { createRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ onCaptchaChange, submitCount }) => {
  const captchaRef = createRef();
  const smallCaptchaRef = createRef();

  const captchaSiteKey = import.meta.env.VITE_CAPTCHA_KEY;

  useEffect(() => {
    if (submitCount !== 0) {
      captchaRef.current.reset();
      smallCaptchaRef.current.reset();
    }
  }, [captchaRef, smallCaptchaRef, submitCount]);

  return (
    <>
      <div className="d-none d-md-block">
        <ReCAPTCHA ref={captchaRef} sitekey={captchaSiteKey} onChange={onCaptchaChange} size="normal" />
      </div>
      <div className="d-sm-block d-md-none">
        <ReCAPTCHA ref={smallCaptchaRef} sitekey={captchaSiteKey} onChange={onCaptchaChange} size="compact" />
      </div>
    </>
  );
};

Captcha.propTypes = {
  onCaptchaChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default React.memo(Captcha);
