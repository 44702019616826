import React from 'react';
import { isShowOrSeries } from '@Utils/common';
import MovieCard from '@Molecules/MovieCard/MovieCard';
import LoadMore from '@Molecules/LoadMoreButton/LoadMoreButton';
import NoData from '@Molecules/NoData/NoData';
import classNames from 'classnames';
import styles from './Genre.module.scss';
import Loading from '@Pages/Search/Loading';

const Genre = ({ loading, pageTitle, array, result, showLess, isComingSoonPage, removeLoadMore }) => {
  if (loading) return <Loading />;
  if (array?.length <= 0) return <NoData addMarginTop />;
  return (
    <div className={styles.root}>
      <div className={"searchHeader"}>
        <div className="d-flex ml-5 mr-5">
          <h2 className={classNames(styles.title, "mb-4")}>{pageTitle}</h2>
        </div>
        <div className={classNames(styles.contentGrid, "searchContainer grid ml-5 mr-5")}>
          {result && array.map(
            (categoryItem, index) => {
              const isFree = isShowOrSeries(categoryItem?.type || categoryItem?.video?.type) ?
                categoryItem?.containsFreeEpisodes
                : categoryItem?.isContinueWatching
                  ? categoryItem?.video?.paid === false
                  : categoryItem?.paid === false;
              return (
                <MovieCard
                  {...categoryItem}
                  key={index}
                  isFree={!isComingSoonPage && isFree}
                  titleUrl={window.decodeURI(categoryItem?.slug)}
                  isComingSoonPage={isComingSoonPage}
                  thumbnail={categoryItem?.thumbnail ?? categoryItem?.image ?? ''}
                  comingSoon={categoryItem?.comingSoon}
                  isComingSoon={categoryItem?.comingSoon}
                  type={categoryItem?.type}
                />
              );
            },
          )}
        </div>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <LoadMore isRendered={array?.length !== result?.data?.length && !removeLoadMore} onClick={showLess} />
        </div>
      </div>
    </div>
  );
};

export default Genre;
