import {
  GET_SEASONS_PER_SHOW,
  CLEAR_SEASONS_PER_SHOW,
  GET_SEASONS_PER_SHOW_SUCCESS,
  GET_SEASONS_PER_SHOW_FAIL,
} from '../Constants/SeasonsConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const seasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEASONS_PER_SHOW: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SEASONS_PER_SHOW_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_SEASONS_PER_SHOW_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_SEASONS_PER_SHOW: {
      return initialState;
    }
    default:
      return state;
  }
};

export default seasonsReducer;
