import React from 'react';
import styles from './VideoPlayerLoader.module.scss';

const VideoPlayerLoader = () => (
  <div className={styles.bufferContainer}>
    <div className={styles.bufferWrapper}>
      <div className={styles.loadingSpinnerEclipse}>
        <div className={styles.innerLoader}>
          <div className={styles.spinner} />
        </div>
      </div>
    </div>
  </div>
);

export default VideoPlayerLoader;
