/* eslint-disable react/destructuring-assignment */

import {
  CLEAR_USER_PER_PROFILE,
  GET_USER_PER_PROFILE,
  GET_USER_PER_PROFILE_FAIL,
  GET_USER_PER_PROFILE_SUCCESS,
  UPDATE_USER_PER_PROFILE,
  UPDATE_USER_PER_PROFILE_SUCCESS,
  UPDATE_USER_PER_PROFILE_FAIL,
  ADD_PROFILE_PER_USER,
  ADD_PROFILE_PER_USER_FAIL,
  ADD_PROFILE_PER_USER_SUCCESS,
  DELETE_PROFILE_PER_USER,
  DELETE_PROFILE_PER_USER_FAIL,
  DELETE_PROFILE_PER_USER_SUCCESS,
} from '../Constants/ProfilesPerUserConstants';
import { LOGOUT_USER } from '../Constants/AuthenticationConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
  success: null,
};

const ProfilesPerUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER: {
      return initialState;
    }
    case DELETE_PROFILE_PER_USER: {
      return {
        ...state,
        error: null,
        loading: true,
        success: null,
      };
    }
    case DELETE_PROFILE_PER_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
        success: true,
      };
    }
    case DELETE_PROFILE_PER_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    }

    case ADD_PROFILE_PER_USER: {
      return {
        ...state,
        error: null,
        loading: true,
        success: null,
      };
    }
    case ADD_PROFILE_PER_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
        success: true,
      };
    }
    case ADD_PROFILE_PER_USER_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error.response.data.data.message,
        success: false,
      };
    }
    case GET_USER_PER_PROFILE: {
      return {
        ...state,
        error: null,
        loading: true,
        success: null,
      };
    }
    case GET_USER_PER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_USER_PER_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_USER_PER_PROFILE: {
      return {
        ...state,
        error: null,
        loading: true,
        success: null,
      };
    }
    case UPDATE_USER_PER_PROFILE_SUCCESS: {
      const profile = { ...action.payload.data.data.result };
      delete profile.image;
      return {
        ...state,
        loading: false,
        profile: {
          ...state.profile,
          ...profile,
        },
        success: true,
      };
    }
    case UPDATE_USER_PER_PROFILE_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
        success: false,
      };
    }
    case CLEAR_USER_PER_PROFILE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default ProfilesPerUserReducer;
