import { POST_CONTACTUS_FORM_FAIL, POST_CONTACTUS_FORM, POST_CONTACTUS_FORM_SUCCESS } from '../Constants/ContactUsConstants';

const initialState = {
  loader: false,
  result: null,
  error: null,
  success: null,
};

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CONTACTUS_FORM: {
      return {
        ...state,
        error: null,
        result: action.payload.request.data,
        loader: true,
        success: null,

      };
    }
    case POST_CONTACTUS_FORM_SUCCESS: {
      return {
        ...state,
        error: null,
        loader: false,
        success: true,

      };
    }
    case POST_CONTACTUS_FORM_FAIL: {
      return {
        ...state,
        loader: false,
        error: action.error,
        success: false,
      };
    }
    default:
      return state;
  }
};

export default contactUsReducer;
