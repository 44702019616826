import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import constants from '../../../Utils/constants';
import styles from './NoData.module.scss';

function NoData({ hideBackToHomePage, addMarginTop, source }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => navigate(constants.screens.browse);
  const renderTextComponent = useCallback(() => {
    if (source === 'purchases') return <p className={styles.noData}>{t('errors.noPurchases')}</p>;
    if (source === 'bookmarks') return <p className={styles.noData}>{t('errors.noBookmarks')}</p>;

    return (
      <>
        <p className={styles.noData}>{t('errors.noDataTitle')}</p>
        <p className={styles.noDataDescription}>{t('errors.noDataDescription')}</p>
      </>
    );
  }, [t, source]);

  return (
    <div className={styles.noDataPageContainer}>
      <div className={classNames(styles.noDataTextContainer, { [styles.addMarginTop]: addMarginTop })}>
        {renderTextComponent()}
      </div>
      {!hideBackToHomePage && (
        <div onClick={onClick} className={styles.homepageButton}>
          <span className={styles.homepageButtonContent}>
            {t('globals.backToHomePage')}
          </span>
        </div>
      )}
    </div>
  );
}

NoData.propTypes = {
  hideBackToHomePage: PropTypes.bool,
  addMarginTop: PropTypes.bool,
  source: PropTypes.string,
};

NoData.defaultProps = {
  source: '',
};

export default NoData;
