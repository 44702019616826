const CLEAR_PLAYER_STATUS = 'CLEAR_PLAYER_STATUS';
const GET_PLAYER_STATUS = 'GET_PLAYER_STATUS';
const GET_PLAYER_STATUS_SUCCESS = 'GET_PLAYER_STATUS_SUCCESS';
const GET_PLAYER_STATUS_FAIL = 'GET_PLAYER_STATUS_FAIL';

export {
  CLEAR_PLAYER_STATUS,
  GET_PLAYER_STATUS,
  GET_PLAYER_STATUS_SUCCESS,
  GET_PLAYER_STATUS_FAIL,
};
