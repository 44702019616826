import { CLEAR_AUTHENTICATION } from '../Constants/AuthenticationConstants';
import {
  SELECTED_SUBSCRIPTION,
  CLEAR_SELECTED_SUBSCRIPTION,
  VIDEO_SUBSCRIPTION,
  VIDEO_SUBSCRIPTION_SUCCESS,
  VIDEO_SUBSCRIPTION_FAIL,
  CLEAR_VIDEO_SUBSCRIPTION_DATA,
  CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA,
} from '../Constants/VideoSubscriptionConstants';

const initialState = {
  loading: false,
  success: false,
  error: null,
  data: null,
  selected: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_SUBSCRIPTION: {
      return {
        ...state,
        selected: action.payload.data,
      };
    }
    case CLEAR_SELECTED_SUBSCRIPTION:
      return {
        ...state,
        selected: initialState.selected,
      };
    case VIDEO_SUBSCRIPTION: {
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    }
    case VIDEO_SUBSCRIPTION_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        data: result,
      };
    }
    case VIDEO_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    }
    case CLEAR_VIDEO_SUBSCRIPTION_DATA: {
      return { ...initialState, data: state.data };
    }
    case CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA: {
      return {
        ...initialState,
        selected: state.selected,
        data: initialState.data,
      };
    }
    case CLEAR_AUTHENTICATION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
