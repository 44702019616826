import React, { useState, useCallback } from "react";
import classNames from "classnames";
import ClickableItem from "../ClickableItem/ClickableItem";
import { isIpad } from "../../../Utils/deviceDetect";

import VolumeHigh from "../../../Assets/Images/nouveau/volume-high.svg";
import VolumeMuted from "../../../Assets/Images/nouveau/volume-low.svg";

import volumeStyles from "./VolumeSlider.module.scss";
import styles from "../../Molecules/PlayerControlButton/PlayerControlButton.module.scss";
import { isMobile } from "react-device-detect";
import * as RadixSlider from '@radix-ui/react-slider';
import useInterruptibleTimer from "@Hooks/useInterruptibleTimer";


// this allows the buttons to be overladed by the volume slider
const fixButtonsIndex = () => {
  try {
    const rightButtonsElement = document.getElementById('right-buttons');
    if (rightButtonsElement) {
      rightButtonsElement.style.zIndex = "1";
    }
    /* eslint-disable-next-line no-empty */
  } catch { }
};

// this allows the buttons to be clickable again, after the volume slider is removed.
const restoreButtonsIndex = () => {
  try {
    const rightButtonsElement = document.getElementById('right-buttons');
    if (rightButtonsElement) {
      rightButtonsElement.style.zIndex = "10";
    }
    /* eslint-disable-next-line no-empty */
  } catch { }
};

const renderVolumeButton = (volume: number) => {
  if (volume === null || volume >= 0.1) return VolumeHigh;
  if (volume === 0) return VolumeMuted;

  return VolumeMuted;
};

interface IVolumeSliderProps {
  onClick: (y?: number) => void;
  containerClasses?: string;
  onMouseMove: (e: React.MouseEvent) => void;
  volume: number;
  isDragging: (isDragging: boolean) => void;
}

const VolumeSlider = ({
  onClick,
  containerClasses,
  onMouseMove,
  volume,
  isDragging,
}: IVolumeSliderProps) => {
  const [show, setShow] = useState(false);
  const [dragging, setDragging] = useState(false);

  const onTimerRestart = () => setShow(true);
  function onTimerEnd() {
    setShow(false);
    restoreButtonsIndex();
  }

  const { startCountdown, setShouldRestart, stopCountdown } = useInterruptibleTimer(onTimerEnd, onTimerRestart, { countStart: 1, countStop: 0, intervalMs: 2000 });

  const onMouseEnterHandler = useCallback(() => {
    stopCountdown();
    fixButtonsIndex();
    setShow(true);
  }, [stopCountdown]);

  const onMouseLeaveHandler = useCallback(() => {
    if (dragging) {
      stopCountdown();
      return;
    }
    setShouldRestart(true);
    startCountdown();
  }, [dragging, setShouldRestart, startCountdown, stopCountdown]);

  //@ts-expect-error legacy code
  const onClickHandler = useCallback((event) => {
    event.stopPropagation();
    event.currentTarget.blur();

    onClick();
  }, [onClick]);

  const onChangeHandler = useCallback(({ y }: { y: number }) => onClick(y), [onClick]);

  const onDragStartHandler = useCallback(() => {
    setDragging(true);
    isDragging(true);
  }, [isDragging]);

  const onDragEndHandler = useCallback((e: React.DragEvent<HTMLSpanElement>) => {
    setDragging(false);
    setTimeout(() => {
      isDragging(false);
      try {
        // check where the mouse has landed. if it has landed inside our slider, we don't remove our component
        if (
          !(
            //@ts-expect-error legacy code
            e?.target?.className?.includes("Slider") ||
            //@ts-expect-error legacy code
            (e?.target?.children?.length &&
              //@ts-expect-error legacy code
              Object.values(e?.target?.children).find((dom) =>
                //@ts-expect-error legacy code
                dom?.className?.includes("Slider")
              ))
          )
        ) {
          setShow(false);
        }
        // eslint-disable-next-line no-empty
      } catch { }
    }, 100);
  }, [isDragging]);

  return (
    <div
      className={classNames(styles.controlButton, containerClasses, show ? styles.scale : null)}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onMouseMove={onMouseMove}
    >
      <ClickableItem classes={classNames(styles.iconButton, styles.noOutline)} onClick={onClickHandler}>
        <img src={renderVolumeButton(volume)} className={styles.icon} alt="Icon" />
      </ClickableItem>

      {show && !isIpad() && (
        <div className={volumeStyles.volumeSliderWrapper}>
          {!isMobile && (
            <div className={volumeStyles.volumeSlider}>
              <RadixSlider.Root
                onValueCommit={(value) => onChangeHandler({ y: value[0] * 0.01 })}
                defaultValue={[volume * 100]}
                max={100}
                step={1}
                className={volumeStyles.sliderRoot}
                orientation="vertical"
              >
                <RadixSlider.SliderTrack className={volumeStyles.sliderTrack}>
                  <RadixSlider.Range className={volumeStyles.sliderRange} />
                </RadixSlider.SliderTrack>
                <RadixSlider.Thumb
                  onDragEnter={onDragStartHandler}
                  onDragEnd={onDragEndHandler}
                  aria-orientation="vertical"
                  style={{ transform: 'translate(10px, 10px)' }}
                  className={volumeStyles.sliderThumb}
                  aria-label="Volume"
                />
              </RadixSlider.Root>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


const MemoizedVolumeSlider = React.memo(VolumeSlider);
export default MemoizedVolumeSlider;
