/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import styles from "./MyProfileDetails.module.scss";
import { showPaymentAlert } from "../../../Store/Actions/ShowPaymentAlertActions";
import { deleteAccount } from "../../../Store/Actions/AuthenticationActions";
import DeleteAccountModal from "../../Molecules/DeleteAccountModal/DeleteAccountModal";
import EditProfileModal from "../../Molecules/EditProfileModal/EditProfileModal";

const MyProfileDetails = ({ setIsFormVisible, cancelSubscriptionLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [isDeleteAccountFormVisible, setIsDeleteAccountFormVisible] =
    useState(false);
  const [hasTouched, setHasTouched] = useState(false);

  const { profile } = useSelector((state) => state.user);

  const authenticationInfo = useSelector((state) => state.authentication);

  const canChangePassword = authenticationInfo.loginType?.some(
    (item) => item === "app"
  );

  return (
    <div className={styles.profileCon}>
      <div className={styles.myAccountTitle}>{t("globals.myAccount")}</div>
      <div className={styles.profileDetailsContainer}>
        {profile?.subscription?.description && (
          <div className={styles.lineInfo}>
            <div className={styles.content}>
              {t("profile.currentPackage")}:
              <div className={styles.infoDetail}>
                {profile?.subscription?.description ? (
                  <span dir="auto">
                    {profile?.subscription?.description}
                    {" - "}
                    {`${profile?.subscription?.price.toString()} ${
                      profile?.subscription?.currency
                    }`}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            </div>
          </div>
        )}
        {profile?.subscription?.date && (
          <div className={styles.lineInfo}>
            <div className={styles.content}>
              {t("profile.validationDate")}:
              <div className={styles.infoDetail}>
                {profile?.subscription?.date
                  ? moment(profile?.subscription?.date).format("DD-MM-YYYY")
                  : "--"}
              </div>
            </div>
          </div>
        )}

        {/* <div className={styles.profileSeparator} /> */}
        <div className={styles.lineInfo}>
          <div className={styles.content}>
            {t("profile.emailAddress")}
            <div className={styles.infoDetail}> {profile?.email ?? "--"}</div>
          </div>
          {canChangePassword ? (
            <div className={styles.link} onClick={() => setIsFormVisible(true)}>
              {t("profile.changePassword")}
            </div>
          ) : null}
        </div>

        <div className={styles.lineInfo}>
          <div className={styles.content}>
            <span>{`${t("profile.phoneNumber")} `}</span>

            <div className={styles.infoDetail}>
              {profile?.phoneNumber
                ? `${profile?.phoneCode} ${profile?.phoneNumber}`
                : t("profile.phoneNumber")}
            </div>
          </div>
          <div
            className={styles.link}
            onClick={() => {
              setIsEditFormVisible(true);
              setHasTouched(true);
            }}
          >
            {t("profile.modifyInfo")}
          </div>
        </div>
        {canChangePassword ? (
          <div
            className={styles.linkBottom}
            onClick={() => setIsFormVisible(true)}
          >
            {t("profile.changePassword")}
          </div>
        ) : null}
        <div
          className={styles.linkBottom}
          onClick={() => {
            setIsEditFormVisible(true);
            setHasTouched(true);
          }}
        >
          {t("profile.modifyInfo")}
        </div>
        <div className={styles.deleteContainer}>
          <div
            className={styles.deleteBtn}
            onClick={() => {
              setIsDeleteAccountFormVisible(true);
              dispatch(showPaymentAlert(false));
            }}
          >
            {t("profile.deleteAccount")}
          </div>
        </div>
      </div>
      <DeleteAccountModal
        isDeleteAccountFormVisible={isDeleteAccountFormVisible}
        setIsDeleteAccountFormVisible={setIsDeleteAccountFormVisible}
        cancelSubscriptionLoading={cancelSubscriptionLoading}
        deleteAccount={deleteAccount}
      />
      <EditProfileModal
        isEditFormVisible={isEditFormVisible}
        hasTouched={hasTouched}
        setHasTouched={setHasTouched}
        setIsEditFormVisible={setIsEditFormVisible}
        setIsFormVisible={setIsFormVisible}
        canChangePassword={canChangePassword}
      />
    </div>
  );
};

export default MyProfileDetails;
