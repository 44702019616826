import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getShowsLanding, clearShowsLanding } from "../../Store/Actions/ShowsLandingActions";
import { getShowsFiltered, clearShowsFiltered } from "../../Store/Actions/ShowsFilteredActions";
import { getGenres } from "../../Store/Actions/GenresActions";
import Genre from "../../Components/Organisms/FilteredGenre/FilteredGenre.jsx";
import ErrorBoundary from "../../Components/Atoms/ErrorBoundary";
import ErrorComponent from "../../HOC/ErrorComponent";
import constants from "../../Utils/constants";

const Shows = () => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const { loading, result, error } = useSelector((state) => state.shows.landing);
  const { loading: genresLoading, result: genresResult, error: genresError } = useSelector((state) => state.genres);
  const { loading: filteredLoading, result: filteredResult, data: filteredData, error: filteredError } = useSelector((state) => state.shows.filtered);
  const { isSignedIn } = useSelector((state) => state.authentication);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGenres());
    dispatch(getShowsLanding());

    return () => {
      dispatch(clearShowsLanding());
    };
  }, [dispatch, isSignedIn]);

  useEffect(() => {
    if (selectedGenre) {
      dispatch(getShowsFiltered(selectedGenre));
    } else {
      dispatch(clearShowsFiltered());
    }
  }, [dispatch, selectedGenre]);

  if (error || genresError || filteredError) {
    return (
      <ErrorComponent
        error={error || genresError || filteredError}
        pageType={constants.screens.shows}
      />
    );
  }
  return (
    <ErrorBoundary>
      <Genre
        title={t("landing.programs")}
        type="show"
        genresLoading={genresLoading}
        genres={genresResult}
        selectedGenre={genresResult ? genresResult.find((genre) => genre.id === selectedGenre)?.name : null}
        onGenreChange={(evtKey) => setSelectedGenre(evtKey)}
        loading={filteredLoading || loading}
        data={selectedGenre ? filteredData : result}
        showMoreBtn={!!selectedGenre && filteredResult?.currentPage < filteredResult?.totalPages}
        onSelectMore={() => dispatch(getShowsFiltered(selectedGenre, filteredResult.currentPage + 1))}
        filtered={!!selectedGenre}
      />
    </ErrorBoundary>
  );
};

export default Shows;
