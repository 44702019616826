import { INavigationItemProps } from "@Organisms/Navigation/NavigationItem/NavigationItem.types";
import { MediaType } from "@Utils/commonTypes";
import constants from "@Utils/constants";
import { TFunction } from "i18next";

const MovieSections = (
  t: TFunction,
  movieId: string,
  hiddenSections: Array<string>
): Array<INavigationItemProps> => {
  let out = [
    {
      url: constants.newScreens.details.movie(movieId).as,
      elm: { name: t("details.info"), type: "info", id: "1" },
    },
    {
      url: constants.newScreens.details.movie(movieId).moreLikeThis.as,
      elm: { name: t("details.moreLikeThis"), type: "more-like-this", id: "2" },
    },
    {
      url: constants.newScreens.details.movie(movieId).trailers.as,
      elm: { name: t("details.trailers"), type: "trailers", id: "3" },
    },
  ];

  for (let i = 0; i < hiddenSections.length; i++) {
    switch (hiddenSections[i]) {
      case 'trailers':
        out = out.filter((section) => section?.elm?.id !== "3");
        break;

      case 'more-like-this':
        out = out.filter((section) => section?.elm?.id !== "2");
        break;

      default:
        break;
    }
  }

  return out;
}

const ShowSections = (
  t: TFunction,
  showId: string,
  seasonId: string,
  hiddenSections: Array<string>
): Array<INavigationItemProps> => {
  let out = [
    {
      url: constants.newScreens.details.show(showId).season(seasonId).as,
      elm: { name: t("details.episodes"), type: "episodes", id: "1" },
    },
    {
      url: constants.newScreens.details.show(showId).season(seasonId).info.as,
      elm: { name: t("details.info"), type: "info", id: "2" },
    },
    {
      url: constants.newScreens.details.show(showId).season(seasonId).moreLikeThis
        .as,
      elm: { name: t("details.moreLikeThis"), type: "more-like-this", id: "3" },
    },
    {
      url: constants.newScreens.details.show(showId).season(seasonId).trailers.as,
      elm: { name: t("details.trailers"), type: "trailers", id: "4" },
    },
  ];

  for (let i = 0; i < hiddenSections.length; i++) {
    switch (hiddenSections[i]) {
      case 'trailers':
        out = out.filter((section) => section?.elm?.id !== "4");
        break;

      case 'more-like-this':
        out = out.filter((section) => section?.elm?.id !== "3");
        break;

      default:
        break;
    }
  }

  return out;
}

const SeriesSections = (
  t: TFunction,
  seriesId: string,
  seasonId: string,
  hiddenSections: Array<string>
): Array<INavigationItemProps> => {
  let out = [
    {
      url: constants.newScreens.details.series(seriesId).season(seasonId).as,
      elm: { name: t("details.episodes"), type: "episodes", id: "1" },
    },
    {
      url: constants.newScreens.details.series(seriesId).season(seasonId).info.as,
      elm: { name: t("details.info"), type: "info", id: "2" },
    },
    {
      url: constants.newScreens.details.series(seriesId).season(seasonId).moreLikeThis.as,
      elm: { name: t("details.moreLikeThis"), type: "more-like-this", id: "3" },
    },
    {
      url: constants.newScreens.details.series(seriesId).season(seasonId).trailers.as,
      elm: { name: t("details.trailers"), type: "trailers", id: "4" },
    },
  ];


  for (let i = 0; i < hiddenSections.length; i++) {
    switch (hiddenSections[i]) {
      case 'trailers':
        out = out.filter((section) => section?.elm?.id !== "4");
        break;

      case 'more-like-this':
        out = out.filter((section) => section?.elm?.id !== "3");
        break;

      default:
        break;
    }
  }

  return out;
}

export const prepareSections = (
  t: TFunction,
  mediaId: string,
  seasonId: string,
  mediaType: MediaType,
  hiddenSections: Array<string>
): Array<INavigationItemProps> => {
  switch (mediaType) {
    case MediaType.Series:
      return SeriesSections(t, mediaId, seasonId, hiddenSections);
    case MediaType.Show:
      return ShowSections(t, mediaId, seasonId, hiddenSections);
    case MediaType.Movie:
      return MovieSections(t, mediaId, hiddenSections);
    default:
      return [];
  }
};
