import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../Utils/constants';
import NonAuthenticatedLayout from '../../Components/Organisms/NonAuthenticatedLayout/NonAuthenticatedLayout';
import ResetPasswordForm from '../../Components/Organisms/ResetPasswordForm';
import { resetPassword } from '../../Services/Authentication';
import MessageBox from '../../Components/Atoms/MessageBox';
import { SubmitInput } from '../../Components/Atoms';
import { logoutUser } from '../../Store/Actions/AuthenticationActions';
import { showPaymentAlert } from '../../Store/Actions/ShowPaymentAlertActions';
import { setRadioOn } from '../../Store/Actions/StreamActions';
import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const isSignedIn = useSelector((state) => state.authentication.isSignedIn);
  const { language } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(constants.screens.browse);
    }
  }, [token, navigate]);

  const submitResetPassword = async (password) => {
    try {
      setLoading(true);
      setError('');
      await resetPassword(password, token, language);
      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setLoading(false);
      if (e.data.data) {
        setError(e.data.data.message);
      } else setError(t('errors.somethingWentWrong'));
    }
  };

  const onSubmitInput = () => {
    if (isSignedIn) {
      dispatch(logoutUser()).then(() => {
        dispatch(showPaymentAlert(false));
        dispatch(setRadioOn(false));
        navigate(constants.screens.login)
      });
      return;
    }

    navigate(constants.screens.login);
  };
  return (
    <NonAuthenticatedLayout
      title={t('globals.saveNewPassword')}
      withBackButton={false}
    >
      {!(success || error) ? (
        <ResetPasswordForm
          onSubmit={({ password }) => submitResetPassword(password)}
          signUpError={error}
          loading={loading}
        />
      ) : (
        <div className={styles.saveNewPasswordForm}>
          <MessageBox message={success ? t('globals.passwordChangedSuccessfully') : error} isError={!success} />
          <SubmitInput
            onClick={onSubmitInput}
            className={styles.backToLoginButton}
            value={t('globals.backToLogin')}
            type="primary"
            loading={loading}
            size={24}
          />
        </div>
      )}
    </NonAuthenticatedLayout>
  );
};

export default ResetPassword;
