import {
  VALIDATE_COUPON,
  VERIFY_COUPON,
  SET_COUPON_NAME,
  CLEAR_COUPON,
} from '../Constants/CouponConstants';

export const validateCoupon = (coupon) => ({
  type: VALIDATE_COUPON,
  payload: {
    request: {
      url: '/api/coupons/apply',
      method: 'POST',
      authenticated: true,
      data: {
        code: coupon,
      },
    },
  },
});

export const verifyCoupon = (coupon) => ({
  type: VERIFY_COUPON,
  payload: {
    request: {
      url: '/api/coupons/verify',
      method: 'GET',
      authenticated: true,
      params: {
        coupon,
      },
    },
  },
});

export const clearCoupon = () => ({
  type: CLEAR_COUPON,
});

export const setCoupon = (coupon) => ({
  type: SET_COUPON_NAME,
  payload: coupon,
});
