import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Play from '../../../Assets/Images/nouveau/play-circle.svg';
import styles from './PlayerEpisodeImage.module.scss';
import Logo from '../../../Assets/Images/nouveau/ballam-logo.svg';

const PlayerEpisodeImage = ({ image }) => (
  <div className={styles.imgContainer}>
    <LazyLoadImage
      src={image}
      alt=""
      onError={(e) => {
        e.target.src = Logo;
      }}
      className={styles.img}
    />
    <img src={Play} className={styles.playBtn} alt="" width="50" height="50" />
  </div>
);

export default PlayerEpisodeImage;
