import sha256 from 'sha256';
import {
  CHANGE_PASSWORD,
  CLEAR_ERROR,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  CLEAR_USER_PROFILE,
  EDIT_USER_IMAGE,
  CLEAR_EDIT_USER_IMAGE,
  REMOVE_USER_IMAGE,
} from '../Constants/UserConstants';

const getUserProfile = () => ({
  type: GET_USER_PROFILE,
  payload: {
    request: {
      url: 'api/user/profile',
      method: 'get',
      authenticated: true,
    },
  },
});

const updateUserProfile = (userName, phonecode, number) => ({
  type: UPDATE_USER_PROFILE,
  payload: {
    request: {
      url: 'api/user/profile',
      method: 'put',
      authenticated: true,
      data: {
        name: userName,
        phoneCode: phonecode,
        phoneNumber: number,
      },
    },
  },
});

export const editUserImage = (reader, ext, name) => ({
  type: EDIT_USER_IMAGE,
  payload: {
    request: {
      url: '/api/user/profilePicture',
      method: 'put',
      authenticated: true,
      data: { image: { data: reader[1], ext }, name },
    },
  },
});

export const deleteUserProfile = () => ({
  type: REMOVE_USER_IMAGE,
  payload: {
    request: {
      url: '/api/user/profilePicture',
      method: 'delete',
      authenticated: true,
      data: {},
    },
  },
});

export const clearEditUserImage = () => ({
  type: CLEAR_EDIT_USER_IMAGE,
  payload: null,
});

const clearUserProfile = () => ({
  type: CLEAR_USER_PROFILE,
  payload: null,
});

const changePassword = (oldPassword, password) => ({
  type: CHANGE_PASSWORD,
  payload: {
    request: {
      url: 'api/user/password/change',
      method: 'put',
      data: { password: sha256(password), oldPassword: sha256(oldPassword) },
      authenticated: true,
    },
  },
});

const clearError = () => ({
  type: CLEAR_ERROR,
});
// eslint-disable-next-line import/prefer-default-export
export {
  getUserProfile,
  changePassword,
  clearError,
  updateUserProfile,
  clearUserProfile,
};
