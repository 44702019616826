import { GET_POPUP_SUCCESS } from '../Constants/MarketingPopupConstants';

export interface IPopUp {
  duration: number;
  enableRedirection: boolean;
  id: string;
  image: string;
  redirection: boolean;
  showPopUp: boolean;
  type: string;
  url?: string
}

interface Action {
  type: string;
  payload?: {
    data?: {
      data?: {
        result?: {
          popUp: IPopUp;
        };
      };
    };
  };
}

export interface IMarketingPopupState {
  active: boolean;
  popUp: IPopUp;
}

const initialState: IMarketingPopupState = {
  active: false,
  popUp: {
    duration: 0,
    enableRedirection: false,
    id: '',
    image: '',
    redirection: false,
    showPopUp: false,
    type: '',
    url: undefined,
  }
};

const popupReducer = (state: IMarketingPopupState = initialState, action: Action): IMarketingPopupState => {
  const data = action.payload?.data?.data?.result?.popUp;
  switch (action.type) {
    case GET_POPUP_SUCCESS:
      return data ? { ...state, popUp: data } : state;
    default:
      return state;
  }
};

export default popupReducer;
