import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, SubmitInput } from '../../Atoms';
import styles from './index.module.scss';
import MessageBox from '../../Atoms/MessageBox';
import ErrorBoundary from '../../Atoms/ErrorBoundary';
import constants from '../../../Utils/constants';
import { clearForgotPasswordData } from '../../../Store/Actions/ForgotPasswordActions';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('validation.invalidEmail')
    .required('validation.required'),
});

const ZAIN_ACCOUNT_ERROR_MESSAGE_AR = 'للحصول على كلمة سر جديدة، يرجى التواصل مع فريق البلام للدعم الفني عبر الواتساب او الاتصال +96522395555';
const ZAIN_ACCOUNT_ERROR_MESSAGE_EN = 'To perform this action, please contact ALBALLAM support team the WhatsApp or call +96522395555';

const ForgotPasswordForm = ({
  onSubmit, error, loading, message, email,
}) => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearForgotPasswordData());
  }, [dispatch]);

  const zainErrorMessage = useMemo(() => {
    if (language === 'ar') {
      return t('globals.arForgotPasswordMessage');
    }
    return t('globals.enForgotPasswordMessage');
  }, [language, t]);

  const getMessage = () => {
    if (message === ZAIN_ACCOUNT_ERROR_MESSAGE_AR || message === ZAIN_ACCOUNT_ERROR_MESSAGE_EN) {
      return `${zainErrorMessage}`;
    }
    return `${message.replace(
      'المسموح به OTP لقد تجاوزت بعدد ال',
      '  لقد تجاوزت بعدد ال OTP المسموح به',
    )}`;
  };

  return (
    <ErrorBoundary>
      <div className="pb-5">
        <div className={classNames(styles.subtitle, 'text-center pt-3 pb-4')}>
          {t('globals.pleaseEnterEmail')}
        </div>

        <Formik
          validationSchema={validationSchema}
          initialValues={{ email }}
          onSubmit={onSubmit}
        >
          {({ handleChange, errors, values }) => (
            <Form>
              <div className="form-container">
                <div>
                  {message !== t('globals.emailSentSuccess') ? (
                    <InputField
                      name="email"
                      label={t('globals.emailAddress')}
                      type="text"
                      placeholder={t('globals.emailAddress')}
                      onChange={(e) => {
                        if (message) {
                          dispatch(clearForgotPasswordData());
                        }
                        handleChange(e);
                      }}
                      alertText={errors.email}
                      error={!!errors.email}
                      value={values.email}
                      autocomplete="off"
                    />
                  ) : (
                    <a
                      href={constants.screens.login}
                      className={`${styles.link} label bold-text`}
                    >
                      {t('globals.backToLogin')}
                    </a>
                  )}
                </div>
                {message && (
                  <div className="pt-4">
                    <MessageBox
                      message={getMessage()}
                      isError={!!error}
                      isForgotPassword
                    />
                  </div>
                )}
                {!message && (
                  <div className="pt-4 message d-flex justify-content-around">
                    <SubmitInput
                      value={t('globals.sendSecurityCode')}
                      type="SignUp"
                      loading={loading}
                      size={24}
                    />
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ErrorBoundary>
  );
};
/* eslint-disable  react/forbid-prop-types */
ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  email: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
  message: null,
  error: null,
  email: '',
};

export default ForgotPasswordForm;
