const GET_TOP_SEARCHES = 'GET_TOP_SEARCHES';
const GET_TOP_SEARCHES_FAIL = 'GET_TOP_SEARCHES_FAIL';
const GET_TOP_SEARCHES_SUCCESS = 'GET_TOP_SEARCHES_SUCCESS';
const CLEAR_TOP_SEARCHES = 'CLEAR_TOP_SEARCHES';

export {
  GET_TOP_SEARCHES,
  GET_TOP_SEARCHES_FAIL,
  GET_TOP_SEARCHES_SUCCESS,
  CLEAR_TOP_SEARCHES,
};
