import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { isMobile } from 'react-device-detect';
import MobileLayout from './MobileLayout/MobileLayout';
import DesktopLayout from './DesktopLayout/DesktopLayout';
import { useAppSelector } from '@Store/hooks';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { deleteFromBookmarks, addToBookmarks } from 'src/alballam-api';
import { Series } from "src/alballam-api-types";
import constants from '@Utils/constants';
import { getMuteButtonState } from '@Utils/common';
import PlayButtonContent from './PlayButton/PlayButton';
import BookmarkButtonContent from './BookmarkButton/BookmarkButton';
import { IBasicPlayer } from '@Pages/MovieDetails/Hero/Hero.types';
import { playerInitialState } from '../Hero';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { setPaymentModalData, togglePaymentModal } from '@Store/Actions/PaymentModalActions';
import { PurchasableMedia } from '@Utils/commonTypes';

interface IDetailsProps {
    playerStatus?: IBasicPlayer;
    setPlayerStatus?: Dispatch<SetStateAction<IBasicPlayer>>
    muted: boolean;
    isPlaying: boolean;
    series: Series;
    id: string;
}

const Details: React.FC<IDetailsProps> = (props) => {
    const { userAuthType } = useAppSelector((state) => state.authentication);
    const { isBookmarked, comingSoon, type, } = props.series;
    const isPlayButtonDisabled = comingSoon;
    const isAddToBookmarksButtonDisabled = false;
    const revalidator = useRevalidator();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onClickSecondaryButton = async () => {
        if (userAuthType !== 'user') {
            navigate({ pathname: constants.screens.login, search: queryString.stringify({ redirect_url: window.location.pathname }) });
            return;
        }
        if (isBookmarked) {
            await deleteFromBookmarks(props.id, type);
        } else {
            await addToBookmarks(props.id, type)
        }
        revalidator.revalidate();
    };

    const onClickPrimaryButton = () => {
        // if has previous playable track, play that video
        const series = props.series;
        const playerEpisode = series.player;
        if (playerEpisode?.comingSoon) return;
        if (playerEpisode?.owned || playerEpisode?.paid === false) {
            navigate(constants.newScreens.watch(playerEpisode.id).as);
            return;
        } else {
            dispatch(setPaymentModalData({ purchaseType: PurchasableMedia.Episode, purchaseId: playerEpisode!.id }));
            dispatch(togglePaymentModal(true));
            return;
        }
        // const trackedEpisodeLink = processTrackedMediaLink(series.player);
        // if (trackedEpisodeLink) {
        //     navigate(constants.newScreens.watch(trackedEpisodeLink).as);
        //     return;
        // }

        // // otherwise play first episode of latest watchable season
        // const latestWatchableEpisode = findLatestWatchableEpisode(series.seasons);
        // if (!latestWatchableEpisode) return;
        // navigate(constants.newScreens.watch(latestWatchableEpisode?.id).as);
    }

    const copyPathToClipboard = async () => navigator.clipboard.writeText(window.location.origin + window.location.pathname);
    const isPlayerActive = !!(props?.playerStatus?.IsVisible && props?.playerStatus?.hasLoaded);
    const shouldRenderMuteButton = props.series?.trailer !== null && props.playerStatus?.hasPlayedOnce === true;
    const muteButtonStatus = useMemo(() => getMuteButtonState(isPlayerActive, props.playerStatus?.isMuted ?? true), [isPlayerActive, props.playerStatus?.isMuted]);

    const onMediaButtonClick = () => {
        if (!props.setPlayerStatus) {
            return;
        }

        if (shouldRenderMuteButton && isPlayerActive) {
            props.setPlayerStatus((prevState) => ({
                ...prevState,
                isMuted: !prevState.isMuted,
            }));
            return;
        }

        props.setPlayerStatus((prev) => ({
            ...playerInitialState,
            isMuted: prev.isMuted
        }));
    }

    const layoutProps = {
        copyPathToClipboard,
        onClickBookmarksButton: onClickSecondaryButton,
        isPlayButtonDisabled,
        isBookmarksButtonDisabled: isAddToBookmarksButtonDisabled,
        onClickPrimaryButton,
        showMuteButton: shouldRenderMuteButton,
        onClickMute: onMediaButtonClick,
        muteButtonStatus: muteButtonStatus,
        isPlaying: props.muted,
        releaseDate: props.series.date,
        numberOfSeasons: props.series.nbrOfSeasons,
        PlayButtonContent: <PlayButtonContent trackedVideo={props.series.player} />,
        BookmarkButtonContent: <BookmarkButtonContent isBookmarked={isBookmarked ?? false} />,
        isComingSoon: props.series.comingSoon,
        title: props.series.name,
        id: props.id,
        ...props.series
    }

    if (isMobile) return <MobileLayout {...layoutProps} />
    return <DesktopLayout  {...layoutProps} />
}

export default Details