import {
  SUBSCRIPTION_PACKAGES,
  CLEAR_SUBSCRIPTION_PACKAGES,
  GET_PACKAGE_SENTENCE,
} from '../Constants/SubscriptionPackagesConstants';

export const getSubscriptionPackages = () => ({
  type: SUBSCRIPTION_PACKAGES,
  payload: {
    request: {
      url: '/api/packagesWeb',
      method: 'GET',
    },
  },
});

export const getPackageSentence = () => ({
  type: GET_PACKAGE_SENTENCE,
  payload: {
    request: {
      url: '/api/packages/packagesTitle/app',
      method: 'GET',
    },
  },
});

export const clearSubscriptionPackages = () => ({
  type: CLEAR_SUBSCRIPTION_PACKAGES,
  payload: null,
});
