import MyPurchases from '@Pages/MyPurchases/MyPurchases'
import constants from '@Utils/constants'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

function MyPurchasesRouter() {
    const { isSignedIn } = useSelector((state) => state.authentication);
    if (!isSignedIn) {
        return <Navigate to={constants.screens.login} />
    }
    return <MyPurchases />;
}

export default MyPurchasesRouter