import pjson from '../../package.json';

const local = 'LOCAL';

export default {
  version: pjson.version,
  env: import.meta.env.VITE_ENV,
  environments: {
    local,
    dev: 'DEV',
    uat: 'UAT',
    prod: 'PROD',
  },
  firebase: {
    apiKey: import.meta.env.VITE_PUBLIC_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_PUBLIC_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_PUBLIC_FIREBASE_MESUREMENT_ID,
  },
  isLocalEnv: import.meta.env.VITE_ENV === local,
  apiUrl: import.meta.env.VITE_API_URL,
  apiTimeout: import.meta.env.VITE_API_TIMEOUT,
  appUrl: import.meta.env.VITE_APP_URL,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  googleAppId: import.meta.env.VITE_GOOGLE_APP_ID,
  facebookAppId: import.meta.env.VITE_FACEBOOK_APP_ID,
  appleAppId: import.meta.env.VITE_APPLE_APP_ID,
  appleAppRedirectUri: import.meta.env.VITE_APPLE_REDIRRECT_URI,
  oneSignalAppId: import.meta.env.VITE_ONESIGNAL_APP_ID,
  oneSignalSafariWebID: import.meta.env.VITE_ONE_SIGNAL_SAFARI_WEB_ID,
  tapKey: import.meta.env.VITE_PUBLIC_TAP_KEY,
  bitbucketBuildNumber: import.meta.env.VITE_BITBUCKET_BUILD_NUMBER,
  pallycon: {
    siteIdDev: 'CVUG',
    siteIdProd: 'LZT5',
  },
  acceptLanguages: {
    ar: 'ar',
  },
  stores: {
    android:
      'https://play.google.com/store/apps/details?id=com.alballam.group&hl=en',
    ios:
      'https://apps.apple.com/us/app/%D9%82%D8%B1%D9%88%D8%A8-%D8%A7%D9%84%D8%A8%D9%84%D8%A7%D9%85/id1365636039',
  },
};
