import {
  GET_LIVE_CHANNELS,
  GET_LIVE_CHANNELS_SUCCESS,
  GET_LIVE_CHANNELS_FAIL,
  CLEAR_LIVE_CHANNELS_DATA,
} from '../Constants/LiveChannelsConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIVE_CHANNELS: {
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
      };
    }
    case GET_LIVE_CHANNELS_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
        error: null,
      };
    }
    case GET_LIVE_CHANNELS_FAIL: {
      return {
        ...state,
        loading: false,
        result: null,
        error: action.error,
      };
    }
    case CLEAR_LIVE_CHANNELS_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
