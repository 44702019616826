import {
  POST_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
} from '../Constants/SubsciptionsConstants';

const postSubscription = ({
  packageId, source, token, cardToken, qrId, coupon,
}) => {
  const params = {
    type: 'tap',
    qrId,
  };

  const data = {
    package: packageId,
    source,
    token,
    cardToken,
  };
  if (coupon) {
    data.coupon = coupon;
  }

  return {
    type: POST_SUBSCRIPTION,
    payload: {
      request: {
        url: 'api/subscription',
        method: 'POST',
        authenticated: true,
        params,
        data,
      },

    },
  };
};

const cancelSubscription = () => ({
  type: CANCEL_SUBSCRIPTION,
  payload: {
    request: {
      url: 'api/subscription/cancel',
      method: 'POST',
      authenticated: true,
    },

  },
});

const clearPostSubscription = () => ({
  type: CLEAR_SUBSCRIPTION,
  payload: null,
});

export { postSubscription, clearPostSubscription, cancelSubscription };
