import React, { SyntheticEvent, useCallback, useMemo, useRef } from 'react';
import Placeholder from '@Assets/Images/placeholders/16x9.svg';
import PlaceholderPortrait from '@Assets/Images/placeholders/2x3.svg';
import styles from './ExpandedCard.module.scss';
import { useTranslation } from 'react-i18next';
import { IExpandedCard } from './ExpandedCard.types';
import { useNavigate } from 'react-router-dom';
import { MediaType, PurchasableMedia } from '@Utils/commonTypes';
import LandscapeImage from '@Atoms/LandscapeImage/LandscapeImage';
import { getImageTagOptions, redirectToDetails } from '@Utils/common';
import { isMobile } from 'react-device-detect';
import parse from 'html-react-parser';
import classNames from 'classnames';
import MovieCardInfo from '@Molecules/MovieCardInfo/MovieCardInfo';
import ReactPlayer from 'react-player';
import { getThumbnailOrTrailer } from './ExpandedCard.utils';
import MediaCardPlayer from '@Molecules/MediaCardPlayer/MediaCardPlayer';
import { useMediaCardPlayer } from '@Molecules/MediaCardPlayer/MediaCardPlayer.utils';
import { useAppSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';
import constants from '@Utils/constants';
import { getPlayerStatus } from 'src/alballam-api';
import { setCookie } from '@Organisms/MarketingPopup/MarketingPopup.utils';
import { setPaymentModalData } from '@Store/Actions/PaymentModalActions';
import queryString from 'query-string';

const ExpandedCard: React.FC<IExpandedCard> = ({
    image,
    title,
    type,
    description,
    comingSoon,
    paid,
    id,
    displayType = 'landscape',
    portraitImage,
    cardInfoProps,
    trailers,
}) => {
    const dispatch = useDispatch();
    const playerIdRef = useRef();
    const playerRef = useRef<ReactPlayer | undefined>(undefined);
    const { isSignedIn } = useAppSelector((state) => state.authentication);
    const ref = useRef<HTMLDivElement>();
    const cardInfoRef = useRef<HTMLDivElement>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        onMouseEnter,
        onMouseLeave,
        playerStatus,
        setPlayerStatus,
        isHovered,
    } = useMediaCardPlayer(id, ref, cardInfoRef, playerRef);


    const shouldRenderPlayer = trailers && trailers?.length > 0 && !isMobile;
    const onImageClick = useCallback(() => redirectToDetails(navigate, id, type as MediaType), [id, navigate, type]);
    const isPortrait = displayType === 'portrait';
    const thumbnail = isPortrait ? portraitImage : image;
    const thumbnailOrTrailer = getThumbnailOrTrailer(thumbnail, trailers, isPortrait);
    const placeholderImage = isPortrait ? PlaceholderPortrait : Placeholder;
    function onError(e: SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = placeholderImage;
        e.currentTarget.style.objectFit = 'cover';
    }

    const imageTagOptions = useMemo(() => getImageTagOptions(paid, comingSoon, false, undefined), [comingSoon, paid]);
    const shouldHideImageTags = playerStatus?.showPlayer && shouldRenderPlayer;
    const onWatchNowClick = useCallback(async () => {
        if (comingSoon) {
            return onImageClick();
        }

        const res = await getPlayerStatus(id, type as MediaType);
        const data = res.result;
        if ((data.owned || !data.paid) && !data.comingSoon) {
            navigate(constants.newScreens.watch(data?.id).as);
            return;
        }

        let destination = '';
        if (data.comingSoon) {
            return onImageClick();
        }

        switch (type) {
            case MediaType.Show:
                destination = constants.newScreens.details.show(id).season(data?.season).as;
                dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Episode }));
                break;
            case MediaType.Movie:
                destination = constants.newScreens.details.movie(id).as;
                dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Movie }));
                break;

            case MediaType.Series:
                destination = constants.newScreens.details.series(id).season(data?.season).as;
                dispatch(setPaymentModalData({ purchaseId: data?.id, purchaseType: PurchasableMedia.Episode }));
                break;
            default:
                break;
        }

        setCookie(constants.locationState.openPayment, 'true', 1);
        if (isSignedIn) navigate(destination);
        else {
            navigate({
                pathname: constants.screens.login,
                search: queryString.stringify({ redirect_url: destination }),
            });
        }

        return;
    }, [comingSoon, dispatch, id, isSignedIn, navigate, onImageClick, type]);

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.imageGroup, isPortrait ? styles.portrait : styles.landscape, isHovered ? styles.imageGroupHovered : '')}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onImageClick}
            >
                <div className={styles.absoluteWrapper}>
                    <LandscapeImage
                        rootWrapperProps={{ className: classNames(styles.landscapeImageWrapper, isPortrait ? styles.portraitImage : styles.landscape) }}
                        tagProps={shouldHideImageTags ? undefined : imageTagOptions}
                        imageProps={{
                            className: classNames(styles.image, isHovered ? styles.imageHovered : ''),
                            placeholderSrc: placeholderImage,
                            src: thumbnail,
                            wrapperClassName: styles.landscapeImageWrapper,
                            alt: title,
                            onError,
                        }}
                    />
                    {shouldRenderPlayer && (
                        <MediaCardPlayer
                            mediaId={id}
                            playerStatus={playerStatus}
                            setPlayerStatus={setPlayerStatus}
                            playerClassName={styles.playerWrapper}
                            ref={playerRef}
                            url={thumbnailOrTrailer}
                        />
                    )}
                    <MovieCardInfo
                        //@ts-expect-error legacy coode
                        isSeason={false}
                        ref={cardInfoRef}
                        id={playerIdRef || id}
                        title={title}
                        rating={cardInfoProps.rating}
                        onInfoClick={onImageClick}
                        onWatchNowClick={onWatchNowClick}
                        isContinueWatching={false}
                        // onAnimationEnd={onInfoCardAnimationEndHandler}
                        onAnimationEnd={() => { }}
                        quality={cardInfoProps.quality}
                        date={cardInfoProps.date}
                        numberOfSeasons={cardInfoProps.numberOfSeasons}
                        isComingSoon={comingSoon}
                        genres={cardInfoProps.genres}
                        duration={cardInfoProps.duration}
                        isSecondSlider={false}
                        generalText={cardInfoProps.generalText}
                        seasonName={cardInfoProps.seasonName}
                        showName={cardInfoProps.showName}
                    />
                </div>
            </div>
            <div className={styles.typographyGroup}>
                <div className={styles.headerGroup}>
                    <span className={styles.type}>{t(`globals.${type}`)}</span>
                    <span className={styles.title}>{title}</span>
                </div>
                <p className={styles.description}>{parse(description)}</p>
            </div>
        </div >
    )
}

export default ExpandedCard;