import useKeepUserAuthenticated from '@Hooks/useKeepUserAuthenticated';
import React, { Fragment, PropsWithChildren } from 'react'


const ProlongedAuthenticationWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    useKeepUserAuthenticated();
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default ProlongedAuthenticationWrapper