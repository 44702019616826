import { SET_SELECTED_SUBTITLE, CLEAR_SELECTED_SUBTITLE } from '../Constants/SelectedSubtitleConstants';

const initialState = {
  selectedSubtitle: '',
};

const SelectedSubtitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SUBTITLE: {
      return {
        ...state,
        selectedSubtitle: action.payload,
      };
    }
    case CLEAR_SELECTED_SUBTITLE: {
      return {
        initialState,
      };
    }
    default:
      return state;
  }
};

export default SelectedSubtitleReducer;
