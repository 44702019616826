import { useEffect, useRef, useState, Fragment, useMemo, useCallback } from 'react';
import { Button, Image, Nav, Navbar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@Store/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import AuthDropDown from '../../Molecules/AuthDropDown/AuthDropDown';
import { getUserPerProfile } from '../../../Store/Actions/UserProfilesActions';
import { switchLanguages } from '../../../Store/Actions/AuthenticationActions';
import { getSearchHistory } from '../../../Store/Actions/SearchHistoryActions';
import constants from '../../../Utils/constants';
import SearchInput from '../../Atoms/SearchIcon/SearchInput';
import Globe from '@Assets/Images/nouveau/globe.svg';
import Logo from '@Assets/Images/nouveau/ballam-logo.svg'
import menuIcon from '@Assets/Images/Icons/icons-menu@3x.png';
import styles from './Navigation.module.scss';
import NavigationLinks from './NavigationLinks/NavigationLinks';
import { ISidebarData } from './Navigation.types';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import SideBar from './SideBar/SideBar';
import LiveStreamButton from '@Atoms/LiveStreamButton/LiveStreamButton';
import LiveBottomSheet from '@Organisms/LiveBottomSheet/LiveBottomSheet';
import { useScreen } from 'usehooks-ts';
import { isIpad } from '@Utils/deviceDetect';
import './Navigation.scss';

const initialSidebarState = {
  open: false,
  view: false,
};

export type NavbarPosition = { sticky: "top" | "bottom" | undefined } | { fixed: "top" | "bottom" | undefined };
interface INavigationProps {
  navbarPosition?: NavbarPosition;
  className?: string;
}

const Navigation: React.FC<INavigationProps> = ({ navbarPosition: overrideNavbarPosition, className }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  const { result } = useAppSelector((state) => state.videoSettings);
  const { isSignedIn, language } = useAppSelector((state) => state.authentication);
  const [sideBarData, setSideBarData] = useState<ISidebarData>(initialSidebarState);
  const [showLoader, setShowLoader] = useState(false);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [videoStream, setVideoStream] = useState([]);

  const screen = useScreen({ debounceDelay: 500 });
  const isOrientationLandscape = screen.orientation.type.includes('landscape');
  const isNavbarMinimized = screen.availWidth < 1000;
  const isMobileOrTablet = isMobile || isIpad();

  //@ts-expect-error - legacy code
  const liveStream = useMemo(() => result?.header?.liveStream, [result]);

  const handleSideBar = (open: boolean) => {
    if (open) {
      setSideBarData({ view: true, open: false });
      setTimeout(() => {
        setSideBarData({ view: true, open: true });
      }, 1);
    } else {
      setSideBarData({ view: true, open: false });
      setTimeout(() => {
        setSideBarData({ view: false, open: false });
      }, 1);
    }
  };

  useEffect(() => {
    const routeContent = document.getElementById('route-content');
    if (!routeContent) return;
    if (sideBarData.view) {
      routeContent.classList.add('hidden');
    } else {
      routeContent.classList.remove('hidden');
    }
  }, [sideBarData.view]);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(getUserPerProfile());
      dispatch(getSearchHistory());
    }
  }, [dispatch, isSignedIn]);

  useEffect(() => {
    setSideBarData({ view: false, open: false });
  }, [location]);

  const handleNavbarScroll = useDebouncedCallback((event) => {
    if (!navRef.current || event?.target?.nodeName === 'DIV') {
      // workaround for the notifications scroll
      // so that the below wont fire
      return;
    }

    if (window.scrollY > navRef.current.clientHeight) {
      navRef.current.style.backgroundColor = 'var(--surface-background)';
    } else {
      navRef.current.style.backgroundColor = 'transparent';
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarScroll, true);

    return () => {
      window.removeEventListener('scroll', handleNavbarScroll, true);
    };
  }, [handleNavbarScroll]);


  function onToggleLanguage() {
    setShowLoader(true);
    dispatch(switchLanguages());
    setTimeout(() => window.location.reload(), 0);
  }

  const navbarPosition: NavbarPosition = useMemo(() => {
    if (overrideNavbarPosition) return overrideNavbarPosition;
    if (location.pathname === constants.screens.browse || location.pathname === constants.newScreens.details.base) {
      return { fixed: 'top' }
    }

    return { sticky: 'top' }
  }, [location.pathname, overrideNavbarPosition]);
  const shouldRenderLogo = !isMobile || !isSearchBarOpen;
  const handleBottomSheet = () => setShowBottomSheet((prev) => !prev);

  const renderLiveStream = useCallback((type: 'desktop' | 'mobile') => {
    if (!liveStream || liveStream?.channels?.length <= 0) return <Fragment />;

    // handles scenario when the ipad screen is big enough to show the navbar and the desktop live stream button
    const shouldRenderMobile = isMobileOrTablet && isSearchBarOpen === false && ((isNavbarMinimized === true && isOrientationLandscape === true) || isOrientationLandscape === false);
    const shouldRenderDesktop = (isMobileOrTablet === false || isOrientationLandscape === true);
    const shouldRender = type === 'desktop' ? shouldRenderDesktop : shouldRenderMobile;

    if (!shouldRender) return <Fragment />;
    return (
      <Navbar.Brand className="livestreamBrand d-flex">
        <LiveStreamButton
          navBarPosition={navbarPosition}
          isMobile={isMobileOrTablet}
          setShowBottomSheet={setShowBottomSheet}
          videoStream={videoStream}
          // audioStream={audioStream}
          // setAudioStream={setAudioStream}
          setVideoStream={setVideoStream}
        />
      </Navbar.Brand>
    );
  }, [isMobileOrTablet, isNavbarMinimized, isOrientationLandscape, isSearchBarOpen, liveStream, navbarPosition, videoStream]);

  if (!result) return <Fragment />;
  if (showLoader) {
    return (
      <div className="LanguageChanger">
        {/* @ts-expect-error - legacy code */}
        <img src={result?.header?.logo?.logoWeb ?? Logo} alt="Logo" className="logoLoader" />
      </div>
    );
  }
  return (
    <Fragment>
      {showBottomSheet && videoStream
        ? (
          <>
            <div className="bottomSheetBackground" aria-label="handleBottomSheet" role="button" onClick={handleBottomSheet} />
            <LiveBottomSheet showBottomSheet={showBottomSheet} setShowBottomSheet={setShowBottomSheet} videoStream={videoStream} />
          </>
        ) : null}
      <Navbar className={classNames("main-navigation sidebar-nav relative", className)} ref={navRef} expand="lg" {...navbarPosition}>
        {/* BURGER MENU */}
        {!isSearchBarOpen && (
          <Navbar.Brand className="d-lg-none mx-2" onClick={() => handleSideBar(true)}>
            <Image src={menuIcon} alt="" width="21" height="16" />
          </Navbar.Brand>
        )}

        {renderLiveStream('mobile')}
        {/* LOGO */}
        {shouldRenderLogo &&
          (<Navbar.Brand aria-label="logo-header" className={isMobile ? styles.mobileNavbarBrand : styles.logo} >
            <NavLink to={constants.screens.browse}>
              {/* @ts-expect-error - legacy code */}
              <LazyLoadImage src={result?.header?.logo?.logoWeb ?? Logo} height={80} alt="alballam-logo" />
            </NavLink>
          </Navbar.Brand>)
        }

        {/* Mobile Search */}
        {isMobile && (
          <Navbar.Brand className={classNames("d-lg-none", isMobile && isSearchBarOpen ? 'flex-grow-1 me-0' : '')}>
            <SearchInput active={isSearchBarOpen} setActive={setIsSearchBarOpen} />
          </Navbar.Brand>
        )}

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between align-items-center d-none d-lg-flex h-100">
          <Nav className={classNames("text-md-center text-left h-100", styles.navbarItemGroup)}>
            <NavigationLinks setSideBarData={handleSideBar} />
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex justify-content-end">
          <Nav className={classNames("align-items-center", styles.utilityCollapse)} ref={ref}>
            <SearchInput active={isSearchBarOpen} setActive={setIsSearchBarOpen} />
            <Button
              onClick={onToggleLanguage}
              className={styles.languageToggle}
              variant='secondary'
            >
              <Image src={Globe} alt="language-toggle" width={20} height={20} />
              <span dir='auto'>{language === 'ar' ? 'En' : 'Ar'}</span>
            </Button>
            {renderLiveStream('desktop')}
            {isSignedIn ? <AuthDropDown handleSidebar={handleSideBar} /> : (
              <Button className={styles.loginButton} onClick={() => navigate(constants.screens.login)}>
                {t('globals.login')}
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* SIDEBAR */}
      <SideBar setShouldRender={handleSideBar} shouldRender={sideBarData.view} />
    </Fragment>
  );
}

export default Navigation;
