import sha256 from 'sha256';
import { publicHttpClient } from '../Utils/httpClient';

/**
 * @param {string} username
 * @param {string} password
 */
export const authenticate = async (appEmail, appPasscode, language) => {
  try {
    const body = {
      loginType: 'app',
      appEmail: appEmail.trim(/ +/),
      appPasscode: sha256(appPasscode),
    };
    const { data } = await publicHttpClient.post('api/authenticate', body,
      {
        headers: { 'Accept-Language': language },
      });
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const signUp = async (name, email, password, language) => {
  try {
    const body = {
      email,
      password: sha256(password),
      name,
    };
    const { data } = await publicHttpClient.post('api/users', body,
      {
        headers: { 'Accept-Language': language },
      });
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const checkOTP = async (username, otp, language) => {
  try {
    const body = {
      username,
      otp,
    };
    const { data } = await publicHttpClient.post('api/checkAppOTP', body,
      {
        headers: { 'Accept-Language': language },
      });
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const resetPassword = async (password, token, language) => {
  try {
    const body = {
      token,
      password: sha256(password),
    };
    const { data } = await publicHttpClient.put('api/user/resetPassword', body,
      {
        headers: { 'Accept-Language': language },
      });
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const resendOTP = async (username, language) => {
  try {
    const body = {
      username,
    };
    const { data } = await publicHttpClient.post('api/resendOTP', body,
      {
        headers: { 'Accept-Language': language },
      });
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const loginUserUsingGoogle = async (data) => publicHttpClient.post('api/authenticate/google', {
  // eslint-disable-next-line no-underscore-dangle
  token: data._token.accessToken,
});

export const loginUserUsingFacebook = async (data) => publicHttpClient.post('api/authenticate/facebook', {
  // eslint-disable-next-line no-underscore-dangle
  token: data._token.accessToken,
});

export const loginUserUsingApple = async (data) => publicHttpClient.post('api/authenticate/apple', {
  // eslint-disable-next-line no-underscore-dangle
  token: data?.authorization?.code,
  name: data?.user?.name ? `${data?.user?.name?.firstName} ${data?.user?.name?.lastName}` : '',
  isWeb: true,
});

export const loginAsGuest = async (deviceId, language) => {
  try {
    const body = {
      deviceId,
    };
    const { data } = await publicHttpClient.post('api/authenticate/guest', body,
      {
        headers: { 'Accept-Language': language },
      });
    return data;
  } catch (e) {
    throw e.response;
  }
};
