import { CLEAR_GENRE_TYPE, GET_LATEST_LIST } from '../Constants/GenreTypeConstants';

export const clearGenreType = () => ({
  type: CLEAR_GENRE_TYPE,
  payload: null,
});

export const getLatestList = (id, genre) => ({
  type: GET_LATEST_LIST,
  payload: {
    request: {
      url: `/api/videoLanding/hyperlink/${id}?type=${genre}`,
      method: 'get',
    },
  },
});
