import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { InputField, SubmitInput } from '../../Atoms';
import MessageBox from '../../Atoms/MessageBox';
import styles from './index.module.scss';
import { createValidationSchema, initialValues } from './ChangePasswordForm.utils';

const ChangePasswordForm = ({ onSubmit, error, loading, onForgotPassword }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const validationSchema = createValidationSchema(t, password);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(props) => onSubmit(props)}
      >
        {({ handleSubmit, handleChange, errors }) => {
          return (
            <Form noValidate onSubmit={(e) => handleSubmit(e)}>
              <div>
                <div>
                  <InputField
                    name="oldPassword"
                    label={t('globals.oldPassword')}
                    type="password"
                    placeholder={t('globals.oldPassword')}
                    onChange={(e) => handleChange(e)}
                    alertText={errors.oldPassword}
                    error={!!errors.oldPassword}
                  />
                </div>
                <div className="d-flex flex-row-reverse">
                  <Button
                    className={classNames(styles.infoLink, 'linkBtn')}
                    onClick={onForgotPassword}
                  >
                    {t('globals.forgotPassword')}
                  </Button>
                </div>
                <div>
                  <InputField
                    name="password"
                    label={t('globals.password')}
                    type="password"
                    placeholder={t('globals.password')}
                    onChange={(e) => [handleChange(e), setPassword(e.target.value)]}
                    alertText={errors.password}
                    error={!!errors.password}
                  />
                </div>
                <div>
                  <InputField
                    name="confirmPassword"
                    label={t('globals.confirmPassword')}
                    type="password"
                    placeholder={t('globals.confirmPassword')}
                    onChange={(e) => handleChange(e)}
                    alertText={errors.confirmPassword}
                    error={!!errors.confirmPassword}
                  />
                </div>
                {error && (typeof error === 'string') && <MessageBox message={error} isError />}
                <div className="my-5 d-flex justify-content-center">
                  <SubmitInput value={t('globals.changePassword')} type="SignUp" loading={loading} size={24} />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onForgotPassword: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {
  error: null,
  loading: null,
};

export default ChangePasswordForm;
