import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './PlayerRating.module.scss';

const PlayerRating = ({ rating, genres }) => {
  const { t } = useTranslation();
  if (!rating || !genres) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.rating}>
        {`${t('videoPlayer.rated')} ${rating}`}
      </div>
      <div className={styles.genres}>{genres.join(', ').trim()}</div>
    </div>
  );
};

PlayerRating.propTypes = {
  rating: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
};

PlayerRating.defaultProps = {
  rating: null,
  genres: null,
};

export default PlayerRating;
