import {
  CLEAR_PLAYER_STATUS,
  GET_PLAYER_STATUS,
  GET_PLAYER_STATUS_FAIL,
  GET_PLAYER_STATUS_SUCCESS,
} from '../Constants/PlayerStatusConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const PlayerStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLAYER_STATUS: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case GET_PLAYER_STATUS_SUCCESS: {
      const { result } = action.payload.data.data;
      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_PLAYER_STATUS_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case CLEAR_PLAYER_STATUS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default PlayerStatusReducer;
