import {
  GET_LANDING_VIDEOS, GET_LANDING_VIDEOS_SUCCESS, GET_LANDING_VIDEOS_FAIL, CLEAR_LANDING_VIDEOS_DATA,
} from '../Constants/LandingVideosConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANDING_VIDEOS: {
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
      };
    }
    case GET_LANDING_VIDEOS_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
        error: null,
      };
    }
    case GET_LANDING_VIDEOS_FAIL: {
      return {
        ...state,
        loading: false,
        result: null,
        error: action.error,

      };
    }
    case CLEAR_LANDING_VIDEOS_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
