import Spinner from 'react-bootstrap/Spinner';
import { IAppLoadingProps } from './AppLoading.types';

const AppLoading: React.FC<IAppLoadingProps> = ({ size = 'sm', variant = 'primary', classNames }) => (
  <div className={`text-center ${classNames}`}>
    <Spinner animation="border" variant={variant} size={size} />
  </div>
);

export default AppLoading;
