import { GET_POPUP } from '../Constants/MarketingPopupConstants';

export const getPopup = () => ({
  type: GET_POPUP,
  payload: {
    request: {
      url: 'api/marketingPopUp/web',
      method: 'GET',
    },
  },
});
