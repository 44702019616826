import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import screenfull from "screenfull";
import { TimeSeekSlider } from "react-time-seek-slider";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile, isSafari, withOrientationChange } from "react-device-detect";
import constants from "../../../Utils/constants";
import { getVideoTime } from "../../../Utils/common";
import PlayerControlButton from "../PlayerControlButton/PlayerControlButton";
import ClickableItem from "../../Atoms/ClickableItem/ClickableItem";
import ClickableList from "../ClickableList/ClickableList";
import VideoPlayerLoader from "../../Atoms/VideoPlayerLoader/VideoPlayerLoader";
import Episodes from "../PlayerEpisodes/PlayerEpisodes";
import NextEpisode from "../NextEpisode/NextEpisode";
import Back from "../../../Assets/Images/nouveau/chevron-left.svg";
import PlayIcon from "@Assets/Images/nouveau/play.svg";
import PauseIcon from "@Assets/Images/nouveau/pause.svg";
import Backward from "../../../Assets/Images/nouveau/backward.svg";
import Forward from "../../../Assets/Images/nouveau/forward.svg";
import Subtitles from "../../../Assets/Images/nouveau/subtitles.svg";
import Fullscreen from "../../../Assets/Images/VideoControls/Fullscreen.svg";
import ExitFullscreen from "../../../Assets/Images/VideoControls/exit-full-screen.svg";
import SkipIcon from "../../../Assets/Images/nouveau/episodes.svg";
import Skip from "../../../Assets/Images/nouveau/skip.svg";
import Replay from "../../../Assets/Images/VideoControls/Replay.svg";
import styles from "./index.module.scss";
import PlayerRating from "../../Atoms/PlayerRating/PlayerRating";
import PlayerButton from "../../Atoms/PlayerButton/PlayerButton";
import VolumeSlider from "../../Atoms/VolumeSlider/VolumeSlider";
import YoureWatching from "./YoureWatching/YoureWatching";
import "./seekBar.scss";

const SPACE_BAR = 32;
const LEFT_ARROW = 37;
const UP_ARROW = 38;
const RIGHT_ARROW = 39;
const DOWN_ARROW = 40;
const BUTTON_ESC = 27;
const BUTTON_F = 70;
const BUTTON_M = 77;

const toggleFullScreen = async () => {
  if (isMobile && isSafari) {
    document.getElementsByTagName("video")[0].webkitEnterFullScreen();
  } else {
    try {
      await screenfull.toggle();
      /* eslint-disable-next-line no-empty */
    } catch {}
  }
};

const fixButtonsIndex = () => {
  try {
    document.getElementsByClassName(styles.rightButtons)[0].style.zIndex = 1;
    /* eslint-disable-next-line no-empty */
  } catch {}
};

const restoreButtonsIndex = () => {
  try {
    document.getElementsByClassName(styles.rightButtons)[0].style.zIndex = 10;
    /* eslint-disable-next-line no-empty */
  } catch {}
};
const stopPropagation = (e) => {
  e.stopPropagation();
};

function VideoPlayerControls({
  isLive,
  disableKeys,
  title,
  episodeNbr,
  playing,
  buffering,
  duration,
  currentTime,
  bufferedTime,
  onPlay,
  onBack,
  onForward,
  onBackward,
  onSeek,
  onSeekEnd,
  volume,
  onVolumeClick,
  subtitles,
  selectedSub,
  onSubChange,
  // episodes,
  season,
  // episodeId,
  // onEpisodeClick,
  nextEpisode,
  onNextEpisodeClick,
  onActivity,
  rating,
  genres,
  isIntro,
  onSkipIntro,
  isCredits,
  onWatchCredits,
  isRecap,
  onSkipRecap,
  canShowYoureWatching,
  children,
  isLandscape,
  isWebView,
  seekThePlayer,
  seasonTitle,
}) {
  const { t } = useTranslation();

  const showControlsTimer = useRef(null);
  const playerBtnRef = useRef(null);
  const [status, setStatus] = useState({
    showControls: true,
    fullscreen: screenfull.isFullscreen,
    showVolume: false,
    showNextEpisode: false,
    showEpisodes: false,
    showSubtitle: false,
    showedRating: false,
  });
  const [removeControls, setRemoveControls] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  const [stopAnimation, setStopAnimation] = useState(false);
  const [isDraggingVolume, setIsDraggingVolume] = useState(false);
  const { language } = useSelector((state) => state.authentication);

  const clearControlsTimer = useCallback(() => {
    clearTimeout(showControlsTimer.current);
    setRemoveControls(false);
  }, []);

  useEffect(() => {
    if (removeControls) {
      restoreButtonsIndex();
    }
  }, [removeControls]);

  const onButtonMouseMove = useCallback(
    (e) => {
      stopPropagation(e);
      clearControlsTimer();
    },
    [clearControlsTimer]
  );

  const onKeyDownHandler = useCallback(
    (e) => {
      if (disableKeys || isCredits) {
        return;
      }

      switch (e.which) {
        case SPACE_BAR:
          onActivity();
          onPlay();
          break;
        case LEFT_ARROW:
          onActivity();
          onBackward();
          break;
        case RIGHT_ARROW:
          onActivity();
          onForward();
          break;
        case UP_ARROW:
          onActivity();
          onVolumeClick(Math.min(volume + 0.1, 1));
          break;
        case DOWN_ARROW:
          onActivity();
          onVolumeClick(Math.max(volume - 0.1, 0));
          break;
        case BUTTON_M:
          onActivity();
          onVolumeClick();
          break;
        case BUTTON_F:
          onActivity();
          toggleFullScreen();
          break;
        case BUTTON_ESC:
          onActivity();
          onBack();
          break;
        default:
          break;
      }
    },
    [
      disableKeys,
      isCredits,
      onPlay,
      onBackward,
      onForward,
      onVolumeClick,
      onBack,
      volume,
      onActivity,
    ]
  );

  useEffect(
    () => () => {
      clearControlsTimer();
    },
    [clearControlsTimer]
  );

  useEffect(() => {
    setSubtitle("");
    const tracks = document.getElementsByTagName("track");
    if (!tracks) return;

    Object.values(tracks).map((track) => {
      /* eslint-disable-next-line no-param-reassign */
      track.track.oncuechange = null;
      return track;
    });
    if (selectedSub?.display === "Off" || selectedSub?.display === "إيقاف") {
      return;
    }

    const activeTrack = Object.values(tracks).find(
      (track) =>
        track.track.label.toLowerCase() === selectedSub?.label?.toLowerCase()
    );
    if (!activeTrack) return;

    const onCueChange = (event) => {
      if (event.target?.track?.activeCues?.length > 0) {
        event.target.track.activeCues[0].line = -4;
        setSubtitle(event.target.track.activeCues[0].text);
      } else {
        setSubtitle("");
      }
    };
    activeTrack.oncuechange = onCueChange;
  }, [selectedSub]);

  useEffect(() => () => !isMobile && screenfull.exit(), []);

  useEffect(() => {
    const onFullscreen = () => {
      setStatus((prevState) => ({
        ...prevState,
        fullscreen: screenfull.isFullscreen,
      }));
    };

    document.body.addEventListener("keydown", onKeyDownHandler);
    if (!isMobile && !isSafari) {
      screenfull.on("change", onFullscreen);
    }

    return () => {
      document.body.removeEventListener("keydown", onKeyDownHandler);
      if (!isMobile && !isSafari) {
        screenfull.off("change", onFullscreen);
      }
    };
  }, [onKeyDownHandler]);

  useEffect(() => {
    if (isCredits) {
      clearControlsTimer();

      setStatus((prevState) => ({ ...prevState, showControls: false }));
      const controlsWrapper = document.getElementsByClassName(
        styles.controlsWrapper
      )[0];
      if (controlsWrapper) {
        controlsWrapper.style.cursor = "default";
      }
    }
  }, [isCredits, clearControlsTimer]);

  const onMouseMoveHandler = useCallback(
    (e) => {
      onActivity();
      if (isCredits) {
        setStopAnimation(true);
        return;
      }
      const { currentTarget } = e;
      clearControlsTimer();

      currentTarget.style.cursor = "default";
      setStatus((prevState) => ({
        ...prevState,
        showControls: true,
      }));

      showControlsTimer.current = setTimeout(() => {
        setStatus((prevState) => ({
          ...prevState,
          showControls: false,
        }));

        setTimeout(() => {
          currentTarget.style.cursor = "none";
        }, 600);
      }, 3000);
    },
    [isCredits, showControlsTimer, onActivity, clearControlsTimer]
  );

  const onBackHandler = useCallback(() => {
    if (isCredits) {
      return;
    }

    onBack();
  }, [onBack, isCredits]);

  const onSubtitleClick = useCallback(
    (event, sub) => {
      event.stopPropagation();
      event.currentTarget.blur();
      const selectedSubtitle = subtitles.find((elm) => elm.display === sub);
      onSubChange(selectedSubtitle);
    },
    [onSubChange, subtitles]
  );

  const onPlayFromStartClick = useCallback(() => {
    seekThePlayer(0);
  }, [seekThePlayer]);

  const renderPlayerRating = useCallback(() => {
    if (status.showControls || status.showedRating) return null;

    setTimeout(() => {
      setStatus((prevStatus) => ({ ...prevStatus, showedRating: true }));
    }, [5000]);

    return <PlayerRating rating={rating} genres={genres} />;
  }, [status.showControls, status.showedRating, rating, genres]);

  const renderSkipIntro = useCallback(() => {
    if (!isIntro) return null;

    return (
      <PlayerButton
        title={t("videoPlayer.skipIntro")}
        onClick={onSkipIntro}
        filled
      />
    );
  }, [t, isIntro, onSkipIntro]);

  const renderCreditsButtons = useCallback(() => {
    if (!isCredits) return null;

    return (
      <>
        {nextEpisode && Object.keys(nextEpisode)?.length > 0 && (
          <PlayerButton
            title={t("videoPlayer.nextEpisode")}
            onClick={onNextEpisodeClick}
            animated
            animationDuration={constants.videoPlayer.creditsNextEpisode}
            stopAnimation={stopAnimation}
          />
        )}
        <PlayerButton
          title={t("videoPlayer.watchCredits")}
          onClick={onWatchCredits}
          filled
        />
      </>
    );
  }, [
    t,
    isCredits,
    onWatchCredits,
    nextEpisode,
    onNextEpisodeClick,
    stopAnimation,
  ]);

  const renderSkipRecap = useCallback(() => {
    if (!isRecap) return null;

    return (
      <PlayerButton
        title={t("videoPlayer.skipRecap")}
        onClick={onSkipRecap}
        filled
      />
    );
  }, [t, isRecap, onSkipRecap]);

  return (
    <>
      {buffering && <VideoPlayerLoader />}

      {renderPlayerRating()}
      {canShowYoureWatching && <YoureWatching playing={playing} />}

      <div
        className={
          isMobile && !isLandscape ? styles.rightMidBtn : styles.rightButtons
        }
        id="right-buttons"
      >
        {renderSkipIntro()}
        {renderCreditsButtons()}
        {renderSkipRecap()}
      </div>

      <div
        className={classNames(
          styles.controlsWrapper,
          status.showControls ? styles.active : ""
        )}
        onClick={(event) => {
          event.stopPropagation();
          if (!isCredits && !isDraggingVolume) {
            onPlay();
          }
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          e.currentTarget.blur();
        }}
        onDoubleClick={(event) => {
          event.stopPropagation();
          if (!isCredits) {
            toggleFullScreen();
          }
        }}
        onMouseMove={onMouseMoveHandler}
        role="button"
        tabIndex="0"
        onTransitionEnd={(e) => {
          e.stopPropagation();
          if (!status.showControls) {
            setRemoveControls(true);
          }
        }}
      >
        {!buffering && (
          <div className={styles.middleControlsContainer}>
            {((!isLive && !isMobile) || (!!isLandscape && !isLive)) && (
              <PlayerControlButton
                onClick={onBackward}
                icon={Backward}
                onMouseMove={onButtonMouseMove}
                resetActivity={onActivity}
                containerClasses={styles.fastForwardButton}
              />
            )}

            {!isMobile ? (
              <PlayerControlButton
                onClick={onPlay}
                icon={playing ? PauseIcon : PlayIcon}
                onMouseMove={onButtonMouseMove}
                containerClasses={styles.playButton}
                resetActivity={onActivity}
              />
            ) : null}
            {((!isLive && !isMobile) || (!!isLandscape && !isLive)) && (
              <PlayerControlButton
                onClick={onForward}
                icon={Forward}
                onMouseMove={onButtonMouseMove}
                resetActivity={onActivity}
                containerClasses={styles.fastForwardButton}
              />
            )}
          </div>
        )}

        {!removeControls && (
          <>
            <div className={styles.gradientTop} />
            <div className={styles.gradientBottom} />

            <div className={styles.topControlsContainer}>
              <PlayerControlButton
                onClick={onBackHandler}
                icon={Back}
                containerClasses={styles.backButton}
                onMouseMove={onButtonMouseMove}
                resetActivity={onActivity}
              />
              <div className={styles.titleContainer}>
                <div className="d-flex flex-column align-items-center">
                  <div className={styles.title}>{title?.ar || title}</div>
                  {episodeNbr && season && (
                    <div className="d-flex align-items-center justify-content-center">
                      {language === "en" ? (
                        <Fragment>
                          <span className={styles.seasonTitle}>
                            {seasonTitle}
                          </span>
                          <span className={styles.seperate}>-</span>

                          <span className={styles.seperate}>
                            {t("globals.theEpisode")}
                          </span>
                          <span className={styles.episodeTitle}>
                            {episodeNbr}
                          </span>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className={styles.episodeTitle}>
                            {episodeNbr}
                          </span>
                          <span className={styles.seperate}>
                            {t("globals.theEpisode")}
                          </span>
                          <span className={styles.seperate}>-</span>

                          <span className={styles.seasonTitle}>
                            {seasonTitle}
                          </span>
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <ClickableItem
              classes={classNames(
                styles.bottomControlsContainer,
                styles.noOutline,
                isMobile && !isLandscape && styles.highBottom
              )}
              onClick={stopPropagation}
            >
              <div
                className={styles.seekTimeBarContainer}
                onMouseMove={onButtonMouseMove}
              >
                {!isLive && (
                  <span className={styles.timeCurrent}>
                    {getVideoTime(currentTime)}
                  </span>
                )}

                <TimeSeekSlider
                  max={duration || 1}
                  currentTime={currentTime}
                  progress={bufferedTime}
                  onSeeking={onSeek}
                  secondsPrefix="0:"
                  onSeekend={onSeekEnd}
                />
                {!isLive && (
                  <span className={styles.timeDuration}>
                    {getVideoTime(duration - currentTime)}
                  </span>
                )}
              </div>

              <div
                className={styles.videoPlayerControlsContainer}
                onDoubleClick={stopPropagation}
              >
                <div className={styles.leftControlsContainer}>
                  {isMobile ? (
                    <PlayerControlButton
                      onClick={onPlay}
                      icon={playing ? PauseIcon : PlayIcon}
                      onMouseMove={onButtonMouseMove}
                      resetActivity={onActivity}
                      containerClasses={"mobilePlayButton"}
                    />
                  ) : null}
                  {/* {((!isLive && !isMobile) || (!!isLandscape && !isLive)) &&
                    episodes && (
                      <PlayerControlButton
                        icon={SkipIcon}
                        containerClasses={styles.episodesButton}
                        onMouseEnter={fixButtonsIndex}
                        onMouseLeave={restoreButtonsIndex}
                        onMouseMove={onButtonMouseMove}
                        resetActivity={onActivity}
                      >
                        <div className={styles.episodesContainer}>
                          <div className={styles.episodesWrapper}>
                            <Episodes
                              episodes={episodes}
                              season={season}
                              episodeId={episodeId}
                              onClick={onEpisodeClick}
                            />
                          </div>
                        </div>
                      </PlayerControlButton>
                    )} */}

                  {!isLive && subtitles && !isRecap && !isMobile && (
                    <PlayerControlButton
                      icon={Subtitles}
                      containerClasses={styles.subtitlesButton}
                      onMouseMove={onButtonMouseMove}
                      onMouseEnter={fixButtonsIndex}
                      onMouseLeave={restoreButtonsIndex}
                      resetActivity={onActivity}
                    >
                      <div className={styles.subtitlesOptionsContainer}>
                        <div className={styles.subtitlesOptionsWrapper}>
                          <ClickableList
                            list={subtitles.map((el) => el.display)}
                            title={language === "ar" ? "ترجمات" : "Subtitles"}
                            selectedElm={selectedSub?.display}
                            onClick={onSubtitleClick}
                          />
                        </div>
                      </div>
                    </PlayerControlButton>
                  )}

                  <VolumeSlider
                    onClick={onVolumeClick}
                    containerClasses={styles.volumeButton}
                    onMouseMove={onButtonMouseMove}
                    volume={volume}
                    isDragging={setIsDraggingVolume}
                  />
                </div>

                <div className={styles.rightControlsContainer}>
                  {!isMobile && !isLive ? (
                    <PlayerControlButton
                      onClick={onPlayFromStartClick}
                      icon={Replay}
                      onMouseMove={onButtonMouseMove}
                      resetActivity={onActivity}
                    />
                  ) : null}

                  {nextEpisode && Object.keys(nextEpisode)?.length > 0 && (
                    <>
                      {isMobile ? <div className={styles.separator} /> : null}
                      <PlayerControlButton
                        onClick={onNextEpisodeClick}
                        icon={Skip}
                        containerClasses={classNames(
                          styles.nextEpisodeButton,
                          "mobileNextEpisodeButton"
                        )}
                        onMouseEnter={fixButtonsIndex}
                        onMouseLeave={restoreButtonsIndex}
                        onMouseMove={onButtonMouseMove}
                        resetActivity={onActivity}
                      >
                        {!isMobile && (
                          <div className={styles.nextEpisodeContainer}>
                            <div className={styles.nextEpisodeWrapper}>
                              <NextEpisode
                                title={nextEpisode.title}
                                image={nextEpisode.image}
                                onClick={onNextEpisodeClick}
                              />
                            </div>
                          </div>
                        )}
                      </PlayerControlButton>
                      <div className={styles.separator} />
                    </>
                  )}

                  {!isLive && subtitles && !isRecap && isMobile && (
                    <PlayerControlButton
                      icon={Subtitles}
                      containerClasses={styles.subtitlesButton}
                      onMouseMove={onButtonMouseMove}
                      onMouseEnter={fixButtonsIndex}
                      onMouseLeave={restoreButtonsIndex}
                      resetActivity={onActivity}
                    >
                      <div className={styles.subtitlesOptionsContainer}>
                        <div className={styles.subtitlesOptionsWrapper}>
                          <ClickableList
                            list={subtitles.map((el) => el.display)}
                            title={language === "ar" ? "ترجمات" : "Subtitles"}
                            selectedElm={selectedSub?.display}
                            onClick={onSubtitleClick}
                          />
                        </div>
                      </div>
                    </PlayerControlButton>
                  )}

                  {!isWebView && (
                    <PlayerControlButton
                      clickItemRef={playerBtnRef}
                      onClick={toggleFullScreen}
                      icon={status.fullscreen ? ExitFullscreen : Fullscreen}
                      onMouseMove={onButtonMouseMove}
                      resetActivity={onActivity}
                    />
                  )}
                </div>
              </div>
            </ClickableItem>
          </>
        )}
      </div>
      {!(isMobile && isSafari) && (
        <div
          className={styles.subtitleText}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      {children}
    </>
  );
}

VideoPlayerControls.propTypes = {
  isLive: PropTypes.bool,
  disableKeys: PropTypes.bool,
  playing: PropTypes.bool.isRequired,
  buffering: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  bufferedTime: PropTypes.number,
  onPlay: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onForward: PropTypes.func,
  onBackward: PropTypes.func,
  onSeek: PropTypes.func,
  volume: PropTypes.number.isRequired,
  onVolumeClick: PropTypes.func.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.object),
  selectedSub: PropTypes.object,
  onSubChange: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  episodes: PropTypes.object,
  season: PropTypes.number,
  episodeId: PropTypes.string,
  onEpisodeClick: PropTypes.func,
  nextEpisode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  onNextEpisodeClick: PropTypes.func,
  onActivity: PropTypes.func,
  rating: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  isIntro: PropTypes.bool,
  onSkipIntro: PropTypes.func,
  isCredits: PropTypes.bool,
  onWatchCredits: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isWebView: PropTypes.bool,
};

VideoPlayerControls.defaultProps = {
  isLive: false,
  disableKeys: false,
  bufferedTime: undefined,
  onForward: () => null,
  onBackward: () => null,
  onSeek: () => null,
  subtitles: null,
  selectedSub: null,
  onSubChange: null,
  episodes: null,
  season: null,
  episodeId: null,
  onEpisodeClick: () => null,
  nextEpisode: null,
  onNextEpisodeClick: () => null,
  onActivity: () => null,
  rating: null,
  genres: null,
  isIntro: false,
  onSkipIntro: () => null,
  isCredits: false,
  onWatchCredits: () => null,
  children: null,
  isWebView: false,
};

export default withOrientationChange(VideoPlayerControls);
