import { MediaType } from "./commonTypes";

const constants = {
  apiUrl: import.meta.env.VITE_API_URL,
  delayToReplayTrailer: 5000,
  searchParams: {
    purchaseType: "purchase",
    purchaseId: "purchaseId",
    origin: "origin",
    qrId: "qrId",
    source: "source",
    openPayment: "openPayment",
    redirect_url: "redirect_url",
  },
  locationState: {
    requireProfile: "requireProfile",
    openPayment: "openPayment",
  },
  sessionStorageKeys: {
    shouldGoToHomepage: "shouldGoToHomepage",
  },
  fileInput: {
    acceptImagesOnly: "image/x-png,image/jpeg",
    imageSize: 6e6, // 6 Mbs.
  },
  errors: {
    sessionTimeout: 3000,
  },
  otp: {
    resendTimer: 60,
  },
  newScreens: {
    profiles: {
      base: "/profiles",
      view: "/profiles/view",
      add: "/profiles/add",
      edit: (id) => ({
        href: "/profiles/edit/:id",
        as: `/profiles/edit/${id}`,
        picture: {
          href: "/profiles/edit/:id/picture",
          as: `/profiles/edit/${id}/picture`,
        },
      }),
    },
    pay: {
      base: "/pay",
      purchase: {
        movie: (movieId) => ({
          href: "/pay/purchase/:mediaType/:purchaseId",
          as: `/pay/purchase/video/${movieId}`,
        }),
        series: (seriesId) => ({
          href: "/pay/purchase/:mediaType/:mediaId",
          as: `/pay/purchase/${MediaType.Series}/${seriesId}`,
          season: (seasonId) => ({
            href: "/pay/purchase/:mediaType/:mediaId/:purchaseType/:purchaseId",
            as: `/pay/purchase/${MediaType.Series}/${seriesId}/season/${seasonId}`,
            episode: (episodeId) => ({
              href: "/pay/purchase/:mediaType/:mediaId/:purchaseType/:purchaseId",
              as: `/pay/purchase/${MediaType.Series}/${seriesId}/episode/${episodeId}`,
            }),
          }),
        }),
        show: (showId) => ({
          href: "/pay/purchase/:mediaType/:mediaId",
          as: `/pay/purchase/${MediaType.Show}/${showId}`,
          season: (seasonId) => ({
            href: "/pay/purchase/:mediaType/:mediaId/:purchaseType/:purchaseId",
            as: `/pay/purchase/${MediaType.Show}/${showId}/season/${seasonId}`,
            episode: (episodeId) => ({
              href: "/pay/purchase/:mediaType/:mediaId/:purchaseType/:purchaseId",
              as: `/pay/purchase/${MediaType.Show}/${showId}/episode/${episodeId}`,
            }),
          }),
        }),
      },
      subscription: {
        select: {
          href: "/pay/subscription/select",
          as: "/pay/subscription/select",
        },
        selectMethod: (packageId) => ({
          href: "/pay/subscription/:packageId/select-method",
          as: `/pay/subscription/${packageId}/select-method`,
        }),
      },
    },
    paymentSuccess: "/payment-success",
    paymentFailure: "/payment-failure",
    details: {
      base: "/details",
      movie: (id) => {
        return {
          href: "/details/movie/:movieId",
          as: `/details/movie/${id}`,
          trailers: {
            href: "/details/movie/:movieId/trailers",
            as: `/details/movie/${id}/trailers`,
          },
          moreLikeThis: {
            href: "/details/movie/:movieId/more-like-this",
            as: `/details/movie/${id}/more-like-this`,
          },
        };
      },
      series: (seriesId) => {
        return {
          href: "/details/series/:seriesId",
          as: `/details/series/${seriesId}`,
          season: (seasonId) => {
            return {
              href: "/details/series/:seriesId/season/:seasonId",
              as: `/details/series/${seriesId}/season/${seasonId}`,
              moreLikeThis: {
                href: "/details/series/:seriesId/season/:seasonId/more-like-this",
                as: `/details/series/${seriesId}/season/${seasonId}/more-like-this`,
              },
              trailers: {
                href: "/details/series/:seriesId/season/:seasonId/trailers",
                as: `/details/series/${seriesId}/season/${seasonId}/trailers`,
              },
              info: {
                href: "/details/series/:seriesId/season/:seasonId/info",
                as: `/details/series/${seriesId}/season/${seasonId}/info`,
              },
            };
          },
        };
      },
      show: (showId) => {
        return {
          href: "/details/show/:showId",
          as: `/details/show/${showId}`,
          season: (seasonId) => {
            return {
              href: "/details/show/:showId/season/:seasonId",
              as: `/details/show/${showId}/season/${seasonId}`,
              moreLikeThis: {
                href: "/details/show/:showId/season/:seasonId/more-like-this",
                as: `/details/show/${showId}/season/${seasonId}/more-like-this`,
              },
              trailers: {
                href: "/details/show/:showId/season/:seasonId/trailers",
                as: `/details/show/${showId}/season/${seasonId}/trailers`,
              },
              info: {
                href: "/details/show/:showId/season/:seasonId/info",
                as: `/details/show/${showId}/season/${seasonId}/info`,
              },
            };
          },
        };
      },
    },
    watch: (id, slug) => {
      if (!slug) {
        return {
          href: "/watch/:id",
          as: `/watch/${id}`,
        };
      }

      return {
        href: "/watch/:id",
        as: `/watch/${id}?title=${slug}`,
      };
    },
    trailer: (id) => {
      return {
        href: "/trailer/:id",
        as: `/trailer/${id}`,
      };
    },
    live: (id) => ({
      as: `/live/channel/${id}`,
      href: "/live/channel/:id",
    }),
    notFound: "/404",
  },
  screens: {
    chromeCast: "/chromecast/chromecast.html",
    login: "/login",
    signUp: "/signUp",
    browse: "/",
    forgotPassword: "/forgotPassword",
    resetPassword: "/resetPassword/:token",
    termsAndConditions: "/termsAndConditions",
    privacyPolicy: "/privacyPolicy",
    privacyPolicyKorean: "/privacyPolicyKorean",
    contactUs: "/contactUs",
    subscriptions: "/subscriptions",
    aboutUs: "/aboutUs",
    profile: "/myAccount",
    bookmarks: "/bookmarks",
    movies: "/movies",
    series: "/series",
    profiles: "/profiles",
    myPurchases: "/myPurchases",
    packages: "/packages",
    shows: "/shows",
    addProfile: "/addProfile",
    comingSoon: "/comingSoon",
    editPic: (id) => ({
      as: `/editPic/${id}`,
      href: "/editPic/:id",
    }),
    editProfile: (id) => ({
      as: `/editProfile/${id}`,
      href: "/editProfile/:id",
    }),
    genre: (id) => ({
      as: `/genre/${id}`,
      href: "/genre/:id",
    }),
    awards: (id) => ({
      as: `/awards/${id}`,
      href: "/awards/:id",
    }),
    category: (id) => ({
      as: `/category/${id}`,
      href: "/category/:id",
    }),
    search: "/search/:search",
    choosePayment: "/choosePayment",
    paymentRedirection: "/paymentRedirection",
    subscribeAll: "/subscribeAll",
    watch: (id) => ({
      as: `/watch/${id}`,
      href: "/watch/:id",
    }),
    videoDetails: (id) => ({
      as: `/videoDetails/${id}`,
      href: "/videoDetails/:id",
    }),
    notFound: "/404",
    somethingWentWrong: "/somethingWentWrong",
    actor: (id) => ({
      as: `/actor/${id}`,
      href: "/actor/:id",
    }),
  },
  validation: {
    passwordMinLength: 6,
    containsOneLetter: /[a-zA-Z]/,
    containsOneNumberOrSpecialCharacter: /[0-9!@#$%^&*)(+=._-]+/,
  },
  videoPlayer: {
    jumpTime: 15,
    defaultNbrMovies: 1,
    maxNbrMovies: 4,
    updateTrackInterval: 20,
    creditsNextEpisode: 3,
    youreWatchingOverlayDelay: 5000,
    remainingSecsToMarkAsEnded: 5,
    secondsToCountView: 30,
    minTimeToShowRecap: 2,
  },
  details: {
    maxEpisodesNbr: 10,
  },
  socialMedia: {
    links: {
      tiktok: "https://www.tiktok.com/@hasan.alballam?_t=8rM0VB6cAgk&_r=1",
      // facebook: 'https://m.facebook.com/shashavod',
      insta:
        "https://www.instagram.com/hasanalballam/profilecard/?igsh=MWczczM5cDczZTNxMA==",
      // snapchat: 'https://snapchat.com/add/shashavod',
      youtube: "https://youtube.com/@hasanalballam?si=HbMMMllHF3SE0Qj0",
      // threads: 'https://www.threads.net/@shashavod',
      twitter: "https://x.com/Hasan_alballam",
      whatsapp: (number) => `https://wa.me/${number}`,
    },
  },
  breakpoints: {
    landscape: {
      1378: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      970: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      625: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      500: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      0: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
    },
    portrait: {
      1378: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
      1200: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
      970: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      625: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      500: {
        slidesPerView: 2.5,
        slidesPerGroup: 2,
      },
      0: {
        slidesPerView: 2.5,
        slidesPerGroup: 2,
      },
    },
    secondSlider: {
      0: {
        slidesPerView: 1.05,
        slidesPerGroup: 1,
      },
      625: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
    },
    topTen: {
      1920: {
        slidesPerView: 4.75,
        slidesPerGroup: 1,
      },
      1378: {
        slidesPerView: 4.75,
        slidesPerGroup: 1,
      },
      1200: {
        slidesPerView: 3.75,
        slidesPerGroup: 1,
      },
      970: {
        slidesPerView: 3.75,
        slidesPerGroup: 1,
      },
      625: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      500: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      0: {
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
    },
  },
};

export default constants;
