import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import constants from '../../Utils/constants';
import ErrorBoundary from '../../Components/Atoms/ErrorBoundary';
import PaymentButton from '../../Components/Atoms/PaymentButton/PaymentButton';
// import useQuery from '../../Hooks/useQuery';
import Visa from '../../Assets/Images/payment/icons-visa.svg';
import MasterCard from '../../Assets/Images/payment/mastercard-payment.svg';
import amexPic from '../../Assets/Images/payment/amex-icon.svg';
import applePic from '../../Assets/Images/payment/applepay-icon.svg';
import madaPic from '../../Assets/Images/payment/mada-icon.svg';
import sadadPic from '../../Assets/Images/payment/sadad-icon.svg';
import stcPic from '../../Assets/Images/payment/stc-icon.svg';
import uaePic from '../../Assets/Images/payment/uae-pay.png';
import qatarPic from '../../Assets/Images/payment/qatar-pay.png';
import benefitPic from '../../Assets/Images/payment/benefit.png';
import KNet from '../../Assets/Images/payment/knet-payment.svg';
import {
  videoSubscription,
  clearVideoSubscriptionInitialData,
} from '../../Store/Actions/VideoSubscriptionActions';
import Loader from '../../Components/Atoms/Loader/Loader';
import PaymentContainer from '../../Components/Molecules/PaymentContainer/PaymentContainer';
import MessageBox from '../../Components/Atoms/MessageBox';
import placeholder from '../../Assets/Images/placeholders/16x9.svg';
import logo from '../../Assets/Images/nouveau/ballam-logo.svg';
import styles from './ChoosePayment.module.scss';
import CardForm from '../../Components/Molecules/cardForm/CardForm';
import CouponForm from '../../Components/Molecules/CouponForm/CouponForm';

const PAYMENT_TAP = 'tap';
const PAYMENT_KNET = 'knet';

const paymentImages = {
  amex: amexPic,
  sadad: sadadPic,
  benefit: benefitPic,
  mada: madaPic,
  qatar_debit_cards: qatarPic,
  uae_debit_cards: uaePic,
  apple_pay: applePic,
  stc_pay: stcPic,
};

const ChoosePayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [tapPayment, setTapPayment] = useState(false);
  // const [isFatoorah, setIsFatoorah] = useState(false);

  // eslint-disable-next-line camelcase
  const { language, isSignedIn } = useSelector((state) => state.authentication);
  const { profile } = useSelector((state) => state.user);
  const {
    selected,
    loading,
    success,
    error,
    data,
  } = useSelector((state) => state.videoSubscription);
  const { result } = useSelector((state) => state.videoSettings);
  const { source } = useSelector((state) => state.saveSourceSubscription);
  const { coupon } = useSelector((state) => state.couponReducer);
  const [urlLoading, setUrlLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  // useEffect(() => {
  //   location.listen(() => {
  //     setUrlLoading(false);
  //   });
  // }, [location]);

  useEffect(() => {
    if (isSignedIn) {
      if (profile?.subscription && selected?.type === 'subscription') {
        navigate(constants.screens.browse);
      }
    }
  }, [dispatch, isSignedIn, profile?.subscription, selected]);

  const onPayClickHandler = useCallback(
    (paymentActive) => {
      if (!selected) {
        return;
      }

      dispatch(
        videoSubscription(
          {
            ...selected, paymentType: paymentActive, source, coupon,
          },
          `${location.search.trim() || ''}${selected?.type !== 'subscription' ? '&isPurchase=purchase' : ''}`,
          paymentActive !== 'knet',
          paymentActive,
        ),
      );
      setShowError(true);
      setUrlLoading(true);
    },
    [dispatch, selected, location.search, source, coupon],
  );

  useEffect(() => {
    if (error) {
      setUrlLoading(!error);
    }
  }, [error]);

  useEffect(() => {
    if (success && data) {
      setUrlLoading(false);
      dispatch(clearVideoSubscriptionInitialData());
      window.location.href = data;
    }

    return () => {
      if (success && data) {
        dispatch(clearVideoSubscriptionInitialData());
      }
    };
  }, [success, data, dispatch]);

  useEffect(() => () => {
    if (success && data) {
      dispatch(clearVideoSubscriptionInitialData());
    }
  }, [
    dispatch, success, data,
  ]);

  const getMainText = useCallback(() => {
    if (selected.type === 'subscription') {
      return t('payment.subscribeNow');
    }

    let mainText = '';
    mainText += selected.title.trim();
    if (
      selected.type === 'season'
      || selected.episodeNbr
      || selected.isEpisode
    ) {
      mainText = `${mainText} ${language === 'ar' ? ' من ' : ' from '} «${selected.show}»`;
    }
    return mainText;
  }, [selected, t, language]);

  const getSubText = useCallback(() => {
    const price = `${selected.package
      ? `${selected.package.countryPrice || selected.package.price} `
      : ''
      }`;
    const currency = `${selected.package ? `${selected.package.currency || '$'}` : ''
      }`;

    let mainText = selected.action === 'rent' ? t('globals.rent') : t('globals.buy');

    if (selected.episodeNbr || selected.isEpisode) {
      mainText = `${mainText} ${t('globals.theEpisode')}`;
    } else if (selected.type === 'season') {
      mainText = `${mainText} ${t('globals.season')}`;
    } else if (selected.type === 'video') {
      mainText = `${mainText} ${t('globals.movie')}`;
    } else if (selected.type === 'subscription') {
      return `${currency} ${price} - ${t('payment.subscribeAllSubText')}`;
    }

    return `${mainText} - ${price} ${currency}`;
  }, [selected, t]);

  const isSubscription = selected.type === 'subscription';

  const getImage = () => {
    if (isSubscription) {
      return result?.header?.logo?.logoWeb;
    }

    return selected.thumbnail || placeholder;
  };

  const thumbnailColumn = isSubscription ? 3 : 4;
  const price = selected?.package?.countryPrice || selected?.package?.price;
  const currency = selected?.package?.currency;
  return (
    <ErrorBoundary>
      <PaymentContainer
        title={t('payment.choosePayment')}
        onClick={() => navigate(-1)}
        contentClassName={styles.choosePaymentContent}
      >
        <div className={styles.selectedContainer}>
          <Row className={styles.selectedRow}>
            <Col
              xs={isSubscription ? thumbnailColumn : 6}
              sm={thumbnailColumn}
              className={classNames(
                styles.thumbnailContainer,
                `${isSubscription ? 'px-0 px-sm-3' : ''}`,
              )}
            >
              <div className="aspect-ratio-16x9">
                <div className={styles.thumbnail}>
                  <img
                    alt="thumbnail"
                    src={getImage()}
                    onError={(e) => {
                      e.target.src = isSubscription ? logo : placeholder;
                      e.target.style.objectFit = 'cover';
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col
              xs={isSubscription ? 12 - thumbnailColumn : 6}
              sm={12 - thumbnailColumn}
              className={styles.textContainer}
            >
              <div className={styles.mainText}>{getMainText()}</div>
              <div className={styles.subText}>{getSubText()}</div>
            </Col>
          </Row>
        </div>

        {selected?.action !== 'purchase' && <CouponForm price={price} currency={currency} />}
        <Row
          className={classNames(styles.buttonsContainer, 'mt-3')}
          xs={tapPayment ? 1 : 1}
          sm={tapPayment ? 1 : 3}
          md={tapPayment ? 1 : 5}
        >
          {loading || urlLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader color="white" />
            </div>
          ) : (
            <>
              {tapPayment ? (
                <CardForm
                  onClick={() => onPayClickHandler(PAYMENT_TAP)}
                  redirectUrl={constants.screens.paymentRedirection}
                  currency={selected.package.currency}
                  id={selected.package._id}
                  coupon={coupon}
                />
              ) : (
                <>
                  <PaymentButton
                    images={[MasterCard, Visa]}
                    className={styles.whitePay}
                    onClick={() => {
                      if (selected.type === 'subscription') {
                        setTapPayment(true);
                      } else {
                        onPayClickHandler(PAYMENT_TAP);
                      }
                    }}
                  />
                  {selected?.package?.isKnet && (
                    <PaymentButton
                      className={styles.knetPay}
                      images={[KNet]}
                      onClick={() => {
                        onPayClickHandler(PAYMENT_KNET);
                      }}
                    />
                  )}
                  {selected?.package?.isMyFatoorah ? selected?.package?.myFatoorahMethods.map((e) => (
                    <PaymentButton
                      className={e.value === 'amex' ? styles.amexPay : styles.whitePay}
                      images={[paymentImages[e.value]]}
                      onClick={() => {
                        onPayClickHandler(e.value);
                      }}
                    />
                  )) : null}
                </>
              )}
            </>
          )}
        </Row>
        {showError && error && (
          <Row className="mt-3">
            <Col xs={12}>
              <MessageBox message={t('errors.somethingWentWrong')} isError />
            </Col>
          </Row>
        )}
      </PaymentContainer>
    </ErrorBoundary>
  );
};

export default ChoosePayment;
