import { useEffect } from 'react';
import { publicHttpClient } from '../../Utils/httpClient';
import { store } from '../../Store/store';
import constants from '../../Utils/constants';
import { isWebview } from '@Utils/common';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@Store/hooks';
import { useTranslation } from 'react-i18next';
import { showNotification } from '@Utils/notifications';



/**
 * Custom hook that denies access to the application if it is being accessed through a webview.
 * 
 * This hook checks if the application is being accessed through a webview and navigates the user
 * to the browse screen if true. It also displays a notification indicating that webview is not supported.
 * 
 * Dependencies:
 * - `isWebview`: Function that checks if the application is being accessed through a webview.
 * - `useNavigate`: Hook from react-router-dom to navigate to different routes.
 * - `useAppSelector`: Custom hook to access the Redux store state.
 * - `useTranslation`: Hook from react-i18next to handle translations.
 * - `useEffect`: React hook to perform side effects in function components.
 * 
 * @function useDenyWebview
 * @returns {void}
 */
export function useDenyWebview() {
  const isWebView = isWebview();
  const navigate = useNavigate();
  const { language, } = useAppSelector((state) => state.authentication);
  const { t } = useTranslation();

  useEffect(() => {
    // covers webkit and android webview - does NOT cover safari webview
    if (isWebView) {
      navigate(constants.screens.browse);
      const position = language === "en" ? "top-left" : "top-right";
      showNotification(t('errors.webViewNotSupported'), "danger", "", position)
    }
  }, [isWebView, language, navigate, t]);
}

// should NOT reset if, trailer, or ad
// should reset if, recap, credits, next episode, or end of video
export function resetViewsTimer(viewsTimerRef, isTrailer, isAd, isHardReset) {
  // used for transitioning between episodes
  if (viewsTimerRef && isHardReset) viewsTimerRef.current = 0;
  if (isTrailer || isAd || !viewsTimerRef || viewsTimerRef.current === null) return;
  viewsTimerRef.current = 0;
}

async function incrementTotalViews(videoId) {
  try {
    const { authentication } = store.getState();
    const headers = {
      Authorization: `${authentication.access_token}`,
      profile: `${authentication.profile}`,
    };

    await publicHttpClient.put(
      `api/video/viewed?id=${videoId}`,
      undefined,
      { headers },
    );
  } catch { }
}

/**
 * Handles the login for views.
 *
 * @param {React.RefObject} viewsTimerRef - The reference to the views timer.
 * @param {boolean} isAd - Indicates if the video is an ad.
 * @param {string} videoId - The ID of the video.
 * @param {boolean} isCounted - Indicates if the view is already counted.
 * @returns {number|null|string} - The updated views count or 'awaiting break' or null.
 */
export function handleViewsLogin(viewsTimerRef, isAd, videoId, isCounted) {
  if (!viewsTimerRef) return;
  if (isAd && viewsTimerRef.current !== null) {
    return 'awaiting break';
  }

  if (!isCounted) {
    return null;
  }

  if (viewsTimerRef.current === null) return null;
  if (viewsTimerRef.current === 'awaiting break') {
    return 0;
  }

  viewsTimerRef.current++;
  if (viewsTimerRef.current === constants.videoPlayer.secondsToCountView) {
    incrementTotalViews(videoId);
    return null;
  }

  return viewsTimerRef.current;
}
