import useFlipPageBasedOnLanguage from '@Hooks/useFlipPageBasedOnLanguage';
import React, { Fragment, PropsWithChildren } from 'react'

const LanguageWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    useFlipPageBasedOnLanguage();
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default LanguageWrapper