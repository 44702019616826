import {
  GET_MOVIE,
  GET_MOVIE_SUCCESS,
  GET_MOVIE_FAIL,
  GET_TRAILER,
  GET_TRAILER_SUCCESS,
  GET_TRAILER_FAIL,
  CLEAR_MOVIE,
  GET_MOVIE_DETAILS,
  GET_MOVIE_DETAILS_SUCCESS,
  GET_MOVIE_DETAILS_FAIL,
  CLEAR_MOVIE_DETAILS,
} from '../Constants/MovieConstants';

const initialState = {
  loading: true,
  result: null,
  error: null,
  details: {
    loading: false,
    result: null,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOVIE: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_MOVIE_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_MOVIE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case GET_TRAILER: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_TRAILER_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_TRAILER_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_MOVIE: {
      return {
        ...initialState,
        details: {
          ...state,
        },
      };
    }
    case GET_MOVIE_DETAILS: {
      return {
        ...state,
        details: {
          error: null,
          loading: true,
          result: null,
        },
      };
    }
    case GET_MOVIE_DETAILS_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        details: {
          loading: false,
          result,
        },
      };
    }
    case GET_MOVIE_DETAILS_FAIL: {
      return {
        ...state,
        details: {
          loading: false,
          error: action.error,
        },
      };
    }
    case CLEAR_MOVIE_DETAILS: {
      return {
        ...state,
        details: {
          ...initialState.details,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
