const SELECTED_SUBSCRIPTION = 'SELECTED_SUBSCRIPTION';
const CLEAR_SELECTED_SUBSCRIPTION = 'CLEAR_SELECTED_SUBSCRIPTION';
const VIDEO_SUBSCRIPTION = 'VIDEO_SUBSCRIPTION';
const VIDEO_SUBSCRIPTION_SUCCESS = 'VIDEO_SUBSCRIPTION_SUCCESS';
const VIDEO_SUBSCRIPTION_FAIL = 'VIDEO_SUBSCRIPTION_FAIL';
const CLEAR_VIDEO_SUBSCRIPTION_DATA = 'CLEAR_VIDEO_SUBSCRIPTION_DATA';
const CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA = 'CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA';

export {
  SELECTED_SUBSCRIPTION,
  CLEAR_SELECTED_SUBSCRIPTION,
  VIDEO_SUBSCRIPTION,
  VIDEO_SUBSCRIPTION_SUCCESS,
  VIDEO_SUBSCRIPTION_FAIL,
  CLEAR_VIDEO_SUBSCRIPTION_DATA,
  CLEAR_VIDEO_SUBSCRIPTION_INITIAL_DATA,
};
