import { useAppSelector } from '@Store/hooks';
import constants from '@Utils/constants';
import React, { Fragment, PropsWithChildren } from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
    const { isSignedIn } = useAppSelector((state) => state.authentication);
    if (!isSignedIn) {
        return <Navigate to={constants.screens.browse} />;
    }

    return <Fragment>{children || <Outlet />}</Fragment>;
}

export default ProtectedRoute