import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import classNames from 'classnames';
import parse from 'html-react-parser';
import styles from './PlayerEpisode.module.scss';

import PlayerEpisodeImage from '../PlayerEpisodeImage/PlayerEpisodeImage';
import ProgressBar from '../ProgressBar/PorgressBar';

const PlayerEpisode = ({
  id,
  episodeNbr,
  title,
  track,
  duration,
  image,
  description,
  isOpen,
  onClick,
  language,
}) => (
  <Accordion.Item eventKey={id} className={styles.card} style={language === 'ar' ? {} : { direction: 'ltr' }}>
    <Accordion.Header
      as={Card.Header}
      eventKey={id}
      className={classNames(styles.cardHeader, isOpen ? styles.isOpen : null)}
    >
      {episodeNbr}
      <span className={styles.episodeName}>
        <span>{title}</span>
      </span>
      <div className={styles.progressBarWrapper}>
        <ProgressBar
          time={track}
          duration={duration}
          color="var(--color-white)"
          notCropped
        />
      </div>
    </Accordion.Header>
    <Accordion.Body eventKey={id}>
      <Card.Body className={styles.cardBody} onClick={onClick}>
        <div className={styles.episodeData}>
          <div className={styles.thumbnailContainer}>
            <PlayerEpisodeImage image={image} />
          </div>
          <div className={styles.description}>{parse(description)}</div>
        </div>
      </Card.Body>
    </Accordion.Body>
  </Accordion.Item>
);

PlayerEpisode.propTypes = {
  id: PropTypes.string.isRequired,
  episodeNbr: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  track: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PlayerEpisode;
