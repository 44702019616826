import {
  POST_CONTACTUS_FORM,
} from '../Constants/ContactUsConstants';

/* eslint-disable  import/prefer-default-export */

export const sendContactUsForm = (data) => ({
  type: POST_CONTACTUS_FORM,
  payload: {
    request: {
      url: 'api/contactWebPage/form',
      method: 'post',
      authenticated: true,
      data,
    },
  },
});
