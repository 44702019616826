import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './NextEpisode.module.scss';
import ClickableItem from '../../Atoms/ClickableItem/ClickableItem';
import PlayerEpisodeImage from '../../Atoms/PlayerEpisodeImage/PlayerEpisodeImage';

const NextEpisode = ({ title, image, onClick }) => {
  const { t } = useTranslation();
  const { language } = useSelector(
    (state) => state.authentication,
  );

  return (
    <ClickableItem classes={styles.container} onClick={onClick}>
      <div className={styles.header} style={language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }}>
        <span>{t('globals.nextEpisode')}</span>
      </div>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <PlayerEpisodeImage image={image} />
        </div>
        <div className={styles.textContainer} style={language === 'ar' ? { direction: 'rtl' } : { direction: 'ltr' }}>
          <div className={styles.title}>{`${title}`}</div>
        </div>
      </div>
    </ClickableItem>
  );
};

NextEpisode.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func,
};

NextEpisode.defaultProps = {
  onClick: () => {},
};

export default NextEpisode;
