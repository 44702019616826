import { GET_MY_PURCHASES_DATA } from '../Constants/MyPurchasesConstants';

const getPurchasesList = () => ({
  type: GET_MY_PURCHASES_DATA,
  payload: {
    request: {
      url: '/api/purchase/myPurchases',
      method: 'get',
      authenticated: true,
    },
  },
});

export default getPurchasesList;
