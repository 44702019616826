import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileSettings from "../../Components/Organisms/ProfileSettings/ProfileSettings";
import { clearPostSubscription } from "../../Store/Actions/SubscriptionsActions";
import useQuery from "../../Hooks/useQuery";
import { clearError, changePassword } from "../../Store/Actions/UserActions";
import { forgotPassword } from "../../Store/Actions/ForgotPasswordActions";
import Loader from "../../Components/Atoms/Loader/Loader";
import { getUserPurchases } from "../../Store/Actions/UserPurchasesActions";
import ChangePasswordModal from "../../Components/Molecules/ChangePasswordModal/ChangePasswordModal";
import { getSubscriptionPackages } from "../../Store/Actions/SubscriptionPackagesActions";
import PageWrapper from "../PageWrapper/PageWrapper.tsx";
import MyProfileDetails from "../../Components/Organisms/MyProfileDetails/MyProfileDetails";
import SubscriptionPackages from "../../Components/Organisms/SubscriptionPackages/SubscriptionPackages";
import constants from "../../Utils/constants";
import "./Profile.scss";
import classNames from "classnames";

function getCurrentSelectedSection(
  packageId,
  subsId,
  myAccountSelectedSection
) {
  if (packageId != undefined || subsId != undefined) return "subs";
  if (myAccountSelectedSection) return myAccountSelectedSection;
  return "account";
}

export default function Profile() {
  const dispatch = useDispatch();
  const { profile, loading, error } = useSelector((state) => state.user);
  const { isSignedIn } = useSelector((state) => state.authentication);
  const { result } = useSelector((state) => state.userPurchases);
  const packages = useSelector((state) => state.subscriptionPackages);
  const { language } = useSelector((state) => state.authentication);
  const { loading: cancelSubscriptionLoading } = useSelector(
    (state) => state.subscription
  );

  const { t } = useTranslation();
  const packageId = useQuery()?.get("package");
  const subsId = useQuery()?.get("subscriptions");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedForgotPass, setIsSubmittedForgotPass] = useState(false);
  const [active, setIsActive] = useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    getCurrentSelectedSection(
      packageId,
      subsId,
      location.state?.myAccountSelectedSection
    )
  );
  const btnContainerFullWatch = useRef(null);
  const navigate = useNavigate();
  const showMore = [];

  const closeLayout = () => {
    if (error) {
      setIsFormVisible(true);
    } else setIsFormVisible(false);
    setIsSubmitted(false);
    setIsSubmittedForgotPass(false);
    dispatch(clearError());
  };

  const submitChangePassword = (data) => {
    setIsSubmitted(true);
    dispatch(changePassword(data.oldPassword, data.password));
  };

  const submitForgotPassword = () => {
    setIsSubmittedForgotPass(true);
    dispatch(forgotPassword(profile.email));
  };

  const onSelectMore = () => {
    if (result) {
      for (
        let i = 0;
        result?.purchase?.length > 5 ? i < 5 : i < result?.purchase?.length;
        i += 1
      ) {
        showMore.push(result?.purchase[i]);
      }
    } else {
      for (let i = 0; i < result?.purchase?.length; i += 1) {
        showMore.push(result?.purchase[i]);
      }
    }
  };

  useEffect(() => {
    if (packages?.result?.data?.length === 1) {
      setIsActive(packages?.result.data[0]);
    }
  }, [packages]);

  useEffect(() => {
    if (packageId && packages?.result?.data) {
      const activePackageQr = packages.result.data.find(
        (item) => item?._id === +packageId
      );
      if (activePackageQr) {
        setIsActive(activePackageQr);
      }
    }
  }, [packageId, packages]);

  useEffect(() => {
    if (document.getElementById("watchFull")) {
      document.getElementById("watchFull").classList.add("active");
    }
  }, [loading, result]);

  useEffect(() => {
    if (active) {
      window.scroll({
        top: btnContainerFullWatch?.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [active, loading, result]);

  useEffect(() => {
    if (!isSignedIn) {
      // eslint-disable-next-line eqeqeq
      if (subsId != undefined || packageId != undefined) {
        navigate(
          { pathname: constants.screens.packages, search: location.search },
          { replace: true }
        );
        return;
      }
      navigate(
        { pathname: constants.screens.login, search: location.search },
        { replace: true }
      );
      return;
    }

    dispatch(getSubscriptionPackages());
    dispatch(clearPostSubscription());
    dispatch(getUserPurchases());
  }, [location, dispatch, isSignedIn, subsId, packageId, navigate]);

  const renderContent = () => {
    if (activeTab === "account") {
      return (
        <MyProfileDetails
          setIsFormVisible={setIsFormVisible}
          cancelSubscriptionLoading={cancelSubscriptionLoading}
        />
      );
    }
    if (activeTab === "subs") {
      return (
        <SubscriptionPackages btnContainerFullWatch={btnContainerFullWatch} />
      );
    }

    return null;
  };

  if (!profile || (!result && !result?.subscriptions?.length > 0)) {
    return (
      <div className="profileLoading m-5 d-flex flex-grow-1 justify-content-center align-items-center">
        <Loader size={34} color="white" />
      </div>
    );
  }

  return (
    <PageWrapper>
      <div className="profileContainer position-relative ">
        {onSelectMore()}
        <Container fluid className="p-0">
          <Row
            className={`${
              language === "ar" ? "direction-right " : "direction-left"
            }`}
          >
            <Col
              lg={3}
              xs={12}
              className={`${
                language === "en" ? "direction-left " : "direction-right"
              } profileFormContainer`}
            >
              <div className={classNames("settingsTitleContainer")}>
                <span className="settingsTitle">{t("globals.settings")}</span>
              </div>
              {profile ? (
                <ProfileSettings
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ) : (
                <div className="profileLoader m-5 d-flex flex-grow-1 justify-content-center align-items-center">
                  <Loader size={34} />
                </div>
              )}
            </Col>
            <Col
              lg={9}
              xs={12}
              className={`${
                language === "ar" ? "direction-right" : "direction-left"
              } navBarResponsive`}
            >
              {renderContent()}
            </Col>
          </Row>
        </Container>
        <ChangePasswordModal
          closeLayout={closeLayout}
          isFormVisible={isFormVisible}
          setIsFormVisible={setIsFormVisible}
          submitChangePassword={submitChangePassword}
          isSubmitted={isSubmitted}
          isSubmittedForgotPass={isSubmittedForgotPass}
          submitForgotPassword={submitForgotPassword}
        />
      </div>
    </PageWrapper>
  );
}
