import {
  CLEAR_PURCHASES,
  GET_PURCHASES,
} from '../Constants/userPurchasesConstants';

export const getUserPurchases = () => ({
  type: GET_PURCHASES,
  payload: {
    request: {
      url: 'api/purchases',
      method: 'get',
      authenticated: true,
    },
  },
});

export const clearUserPurchases = () => ({
  type: CLEAR_PURCHASES,
  payload: null,
});
