import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../../Utils/constants';

const Icon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={() => window.open(constants.socialMedia.links.youtube)}
    width={width || 'inherit'}
    height={height || 'inherit'}
    viewBox="0 0 34 26"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <path
            /* eslint-disable-next-line max-len */
            d="M16.82-.002c1.766.007 10.279.074 12.59.706 1.413.381 2.527 1.495 2.908 2.91.608 2.22.673 6.516.68 7.671V11.71c-.006.84-.064 5.463-.68 7.805-.38 1.413-1.495 2.528-2.909 2.908-2.31.609-10.823.673-12.59.68h-.643c-1.762-.008-10.256-.074-12.59-.707-1.414-.38-2.528-1.495-2.909-2.908-.6-2.258-.67-6.724-.678-7.732v-.41c.008-1.004.078-5.451.678-7.733C1.057 2.2 2.2 1.058 3.586.677 4.582.415 6.733.254 8.998.155l.62-.025c2.796-.108 5.622-.128 6.557-.132zm-3.612 6.605v9.895l8.59-4.948-8.59-4.947z"
            transform="translate(-585 -631) translate(493 625) translate(92.404 6.99)"
          />
        </g>
      </g>
    </g>
  </svg>
);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 34,
  height: 26,
};

export default Icon;
