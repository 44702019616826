import { SubscriptionSource } from '../Reducers/subscriptionsSourceReducer';
import {
  SAVE_SOURCE,
  CLEAR_SOURCE,
} from '../Constants/subscriptionsSourceConstants';

const saveSubscriptionSource = (source: SubscriptionSource) => ({
  type: SAVE_SOURCE,
  payload: source,
});

const clearSubscriptionSource = () => ({
  type: CLEAR_SOURCE,
  payload: null,
});

export { saveSubscriptionSource, clearSubscriptionSource };