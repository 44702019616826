import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ChangePasswordForm from '../../Organisms/ChangePasswordFrom';
import SuccessFailTemp from '../../Organisms/SuccessFailTemp';

import arrowRight from '../../../Assets/Images/back/icons-arrows-arrow-right.svg';

const ChangePasswordModal = ({
  isFormVisible,
  setIsFormVisible,
  closeLayout,
  submitChangePassword,
  isSubmittedForgotPass,
  isSubmitted,
  submitForgotPassword,
}) => {
  const { t } = useTranslation();

  const { profile, loading, error } = useSelector((state) => state.user,);

  const { language } = useSelector((state) => state.authentication);
  const forgotPass = useSelector((state) => state.forgotPassword);

  return (
    <Modal
      show={isFormVisible}
      backdrop
      onHide={() => {
        closeLayout();
      }}
      onExited={() => {
        closeLayout();
      }}
      dialogClassName="modalEditProfile"
    >
      <ModalBody
        className={
          language === 'ar' ? 'modalBodyEditProfile' : 'modalBodyEng'
        }
      >
        <div className="d-flex titleContainer">
          <div
            onClick={() => setIsFormVisible(false)}
            className={language === 'ar' ? 'arrowRight' : 'arrowLeftEng'}
          >
            <img src={arrowRight} alt="" className="d-md-block" />
          </div>
          <div className="d-flex justify-content-center mr-4">
            <span className="myAccountTitle">
              {t('globals.changePassword')}
            </span>
          </div>
        </div>
        <div className="editContainer" style={{ paddingBottom: '10px' }}>
          {!isSubmittedForgotPass ? (
            !isSubmitted || loading ? (
              <>
                <ChangePasswordForm
                  onSubmit={(data) => submitChangePassword(data)}
                  error={
                    error
                    && (typeof error === 'string'
                      ? error
                      : t('errors.somethingWentWrong'))
                  }
                  onForgotPassword={submitForgotPassword}
                  loading={loading}
                />
              </>
            ) : (
              <SuccessFailTemp
                profile
                onDone={() => closeLayout()}
                isError={error}
                message={
                  error
                    ? typeof error === 'string'
                      ? error
                      : t('errors.somethingWentWrong')
                    : t('globals.passwordChangedSuccessfully')
                }
              />
            )
          ) : (
            <SuccessFailTemp
              profile
              onDone={() => closeLayout()}
              loading={forgotPass.loading}
              isError={forgotPass.error}
              message={
                forgotPass.error
                  ? typeof forgotPass.error === 'string'
                    ? forgotPass.error
                    : t('errors.somethingWentWrong')
                  : `${t('globals.emailSentSuccess')} ${profile.email}`
              }
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChangePasswordModal;
