import { GET_BREAKS, CLEAR_BREAKS } from "../Constants/BreaksConstants";

export const getBreaks = (id) => ({
  type: GET_BREAKS,
  payload: {
    request: {
      url: `api/video/player/breaks/${id}`,
      method: "get",
      authenticated: true,
    },
  },
});

export const clearBreaks = () => ({
  type: CLEAR_BREAKS,
  payload: null,
});
