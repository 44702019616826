import { PurchasableMedia } from '@Utils/commonTypes';
import { CLEAR_SOURCE, SAVE_SOURCE } from '../Constants/subscriptionsSourceConstants';

export interface SubscriptionSource {
  sourceType: PurchasableMedia | null | 'route';

  routeName?: string;

  movieName?: string;
  movieId?: string;

  seriesName?: string;
  seriesId?: string;

  seasonName?: string | null;
  seasonId?: string | null;

  episodeName?: string | null;
  episodeId?: string | null;

  language: 'EN' | 'AR';
}

interface Action {
  type: string;
  payload?: SubscriptionSource;
}

const initialState: SubscriptionSource = {
  sourceType: null,
  movieName: '',
  movieId: '',
  episodeName: null,
  episodeId: null,
  seasonName: null,
  seasonId: null,
  language: 'EN'
};

const subscriptionSourceReducer = (state: SubscriptionSource = initialState, action: Action): SubscriptionSource => {
  switch (action.type) {
    case SAVE_SOURCE: {
      return {
        ...state,
        ...action?.payload
      };
    }
    case CLEAR_SOURCE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default subscriptionSourceReducer;