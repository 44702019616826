import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { logoutUser } from "../../../Store/Actions/AuthenticationActions";
import { showPaymentAlert } from "../../../Store/Actions/ShowPaymentAlertActions";
import { setRadioOn } from "../../../Store/Actions/StreamActions";

import styles from "./ProfileSettings.module.scss";
import logoutIcon from "../../../Assets/Images/Icons/icons-log-out.svg";
import profileIcon from "../../../Assets/Images/Icons/profile.svg";
import packageIcon from "../../../Assets/Images/Icons/package.svg";

const ProfileSettings = ({ activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex position-relative">
        <Button
          onClick={() => {
            setActiveTab("account");
          }}
          className={classNames(
            "profileSideBtn",
            activeTab === "account" ? "active" : ""
          )}
        >
          <img alt="" src={profileIcon} className="accountImage" />
          <span className={styles.typography}>{t("globals.myAccount")}</span>
        </Button>
      </div>
      <div className="d-flex position-relative">
        <Button
          onClick={() => {
            setActiveTab("subs");
          }}
          className={classNames(
            "profileSideBtn",
            activeTab === "subs" ? "active" : ""
          )}
        >
          <img alt="" src={packageIcon} className="accountImage" />
          <span className={styles.typography}>{t("payment.packages")}</span>
        </Button>
      </div>
      <div className="d-flex position-relative">
        <Button
          onClick={() => {
            dispatch(logoutUser());
            dispatch(showPaymentAlert(false));
            dispatch(setRadioOn(false));
          }}
          className="profileSideBtn"
          style={{ color: "red" }}
        >
          <img alt="" src={logoutIcon} className="accountImage" />
          {t("globals.logout")}
        </Button>
      </div>
    </>
  );
};

export default ProfileSettings;
