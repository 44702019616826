import React, {
  useRef,
  useMemo,
  useEffect,
  useCallback,
  Fragment,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@Assets/Images/nouveau/search.svg';
import CancelIcon from '../../../Assets/Images/icons-erase.svg';
import constants from '../../../Utils/constants';
import { debounce } from '../../../Utils/common';
import { deleteSearchHistory, addSearchHistory } from '../../../Store/Actions/SearchHistoryActions';
import CloseIcon from '../../../Assets/Images/icons-video-player-close.svg';
import styles from './SearchInput.module.scss';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import './SearchIcon.scss';

const SearchInput = ({ active, setActive }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result } = useSelector((state) => state.searchHistory);
  const isSignedIn = useSelector((state) => state.authentication.isSignedIn);
  const { language } = useSelector((state) => state.authentication);
  const ref = useRef();
  const searchHistoryRef = useRef();
  const inputRef = useRef();

  const getSearchHistoryWidth = useCallback(() => {
    if (searchHistoryRef?.current?.style && ref?.current.clientWidth) {
      searchHistoryRef.current.style.width = `${ref.current.clientWidth + 2}px !important`;
      searchHistoryRef.current.style.left = `${ref.current.offsetLeft}px`;
    }
  }, []);

  const onSearchHandler = useCallback((word) => {
    if (word === '') {
      navigate(constants.screens.browse);
      inputRef.current.blur();
    }

    dispatch(addSearchHistory(word));
    if (location.pathname.indexOf('/search') === -1 && word !== '') {
      navigate(`/search/${word}`);
      if (ref.current) {
        ref.current.classList.add('active');
      }
    }
    if (location.pathname.indexOf('/search') === 0 && word !== '') {
      navigate(`/search/${word}`, { replace: true });
      if (ref.current) {
        ref.current.classList.add('active');
      }
    }
  }, [dispatch, location.pathname, navigate]);

  const debounceSearch = useMemo(() => debounce(onSearchHandler, 300), [onSearchHandler]);

  useEffect(() => {
    window.addEventListener('resize', getSearchHistoryWidth);
    return () => window.removeEventListener('resize', getSearchHistoryWidth);
  }, [getSearchHistoryWidth]);

  const onButtonClick = (e) => {
    e.preventDefault();
    if (!ref.current.classList.contains('active')) {
      setActive(true);
      ref.current.classList.add('active');
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  }

  return (
    <div
      onClick={onButtonClick}
      className={classNames('btn btn-secondary', styles.root, isMobile ? styles.mobileView : '')}
    >
      <div
        className='search'
        style={result?.length > 0 && active ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, } : {}}
        ref={ref}
        onTransitionEnd={() => {
          if (inputRef.current && ref?.current?.classList?.contains('active')) {
            inputRef.current.focus();
          }
          if (ref?.current && searchHistoryRef?.current?.style) {
            searchHistoryRef.current.style.left = `${ref.current.offsetLeft}px`;
          }
        }}
      >
        <Image
          height="20"
          className={styles.searchIcon}
          src={SearchIcon}
          alt=""
        />
        <form
          className={isMobile ? 'flex-grow-1' : ''}
          onSubmit={(e) => {
            e.preventDefault();
            inputRef.current.blur();
          }}
          onFocus={() => {
            setActive(true);
          }}
        >
          <input
            aria-label="SearchInputLabel"
            id="SearchInput"
            autoComplete="off"
            name="search"
            onKeyDown={(event) => {
              setActive(true);
              if (event.which === 13) {
                debounceSearch(event.target.value);
              }
            }}
            ref={inputRef}
            className={language === 'ar' ? 'pr-2' : 'pl-2'}
          />
        </form>

        <button
          className="search-cancel"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setActive(false);
            inputRef.current.value = '';
            ref.current.classList.remove('active');
          }}
        >
          <Image
            src={CancelIcon}
            alt="search button"
            onContextMenu={(e) => e.preventDefault()}
            height="12"
            onClick={() => {
              setActive(false);
            }}
          />
        </button>
      </div>
      {active
        && isSignedIn
        && result?.length > 0
        && ref?.current?.classList?.contains('active') && (
          <div
            style={{
              width: `${ref?.current?.clientWidth + 2}px !important`,
              left: `${ref?.current?.offsetLeft}px`,
            }}
            className="search-form"
          >
            <div ref={searchHistoryRef} className={styles.searchHistory}>
              {result?.length > 0 ? (
                <Fragment>
                  {result?.map((el) => (
                    <div className={styles.recentItem} key={el.id}>
                      <span role="button" className={styles.text}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/search/${el.text}`);
                          setActive(false);
                        }}
                      >
                        {decodeURI(el?.text ?? '')}
                      </span>
                      <img alt="delete-icon" className="deleteIcon" src={CloseIcon} onClick={() => dispatch(deleteSearchHistory(el.id))} />
                    </div>
                  ))}
                </Fragment>
              ) : (
                <div className="d-flex justify-content-center  mt-3">
                  <span className="titleHistory">
                    {t('globals.noSearchesFound')}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default SearchInput;
