/* eslint-disable camelcase */
import {
  CLEAR_VIDEO_SETTINGS,
  GET_VIDEO_SETTINGS,
  GET_VIDEO_SETTINGS_FAIL,
  GET_VIDEO_SETTINGS_SUCCESS,
} from '../Constants/VideoSettingsConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
  server_date: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_SETTINGS: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_VIDEO_SETTINGS_SUCCESS: {
      const server_date = action.payload.data.data?.server_date;
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
        server_date,
      };
    }
    case GET_VIDEO_SETTINGS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_VIDEO_SETTINGS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
