import EpisodesRail from "@Molecules/EpisodesRail/EpisodesRail";
import TrailersSection from "@Organisms/SeasonSection/TrailersSection/TrailersSection";
import DetailsSection from "@Organisms/SeasonSection/DetailsSection/DetailsSection";
import RelatedSection from "@Organisms/SeasonSection/RelatedSection/RelatedSection";
import MovieDetails from "@Pages/MovieDetails/MovieDetails";
import SeriesDetails from "@Pages/SeriesDetails/SeriesDetails";
import ShowDetails from "@Pages/ShowDetails/ShowDetails";
import DefaultLayout from "@Templates/DefaultLayout";
import constants from "@Utils/constants";
import ErrorComponent from "src/HOC/ErrorComponent";
import { getSeriesDetails, getMovieDetails } from "src/alballam-api";
import { LoaderFunctionArgs, redirect, RouteObject } from "react-router-dom";
import styles from '@Utils/utils.module.scss';
import { convertSeasonsObjToArray } from "@Utils/common";
import { Series, Show, WithApiMetaData } from "src/alballam-api-types";
import { getInitialSeason } from "@Utils/common";
import queryString from "query-string";

const showLoader = async ({ params, request }: LoaderFunctionArgs) => {
    const show: WithApiMetaData<Show> = await getSeriesDetails(params.showId!);
    const seasonsArray = convertSeasonsObjToArray(show?.result?.seasons);
    const initialSelectedSeason = getInitialSeason(seasonsArray, params?.seasonId, show?.result?.player?.seasonId);

    if (initialSelectedSeason !== undefined && initialSelectedSeason.id !== params?.seasonId) {
        const queryParams = queryString.extract(request.url);
        const parsedQueryString = queryString.parse(queryParams);
        const stringifiedQueryString = queryString.stringify(parsedQueryString);

        return redirect(`${constants.newScreens.details.show(params.showId!).season(initialSelectedSeason?.id).as}${stringifiedQueryString.length > 0 ? `?${stringifiedQueryString}` : ''}`);
    }

    return { show };
}


const seriesLoader = async ({ params, request }: LoaderFunctionArgs) => {
    const series: WithApiMetaData<Series> = await getSeriesDetails(params.seriesId!);
    const seasonsArray = convertSeasonsObjToArray(series?.result?.seasons);
    const initialSelectedSeason = getInitialSeason(seasonsArray, params?.seasonId, series?.result?.player?.seasonId);

    if (initialSelectedSeason !== undefined && initialSelectedSeason.id !== params?.seasonId) {
        const queryParams = queryString.extract(request.url);
        const parsedQueryString = queryString.parse(queryParams);
        const stringifiedQueryString = queryString.stringify(parsedQueryString);

        const destination = `${constants.newScreens.details.series(params.seriesId!).season(initialSelectedSeason?.id).as}${stringifiedQueryString.length > 0 ? `?${stringifiedQueryString}` : ''}`;
        return redirect(destination);
    }

    return { series };
}

const DetailsRouter: RouteObject = {
    path: constants.newScreens.details.base,
    element: <DefaultLayout shouldDisableFooter navigationClassName={styles.hiddenOnResponsive} navbarPosition={{ fixed: 'top' }} />,
    children: [
        {
            path: constants.newScreens.details.series().href,
            element: <SeriesDetails />,
            errorElement: <ErrorComponent pageType={constants.screens.series} />,
            loader: seriesLoader,
            children: [
                {
                    index: true,
                    path: constants.newScreens.details.series().season().href,
                    element: <EpisodesRail />,
                },
                {
                    path: constants.newScreens.details.series().season().info.href,
                    element: <DetailsSection />,
                },
                {
                    path: constants.newScreens.details.series().season().moreLikeThis.href,
                    element: <RelatedSection />,
                },
                {
                    path: constants.newScreens.details.series().season().trailers.href,
                    element: <TrailersSection />,
                }
            ]
        },
        {
            path: constants.newScreens.details.show().href,
            element: <ShowDetails />,
            errorElement: <ErrorComponent pageType={constants.screens.shows} />,
            loader: showLoader,
            children: [
                {
                    index: true,
                    path: constants.newScreens.details.show().season().href,
                    element: <EpisodesRail />,
                },
                {
                    path: constants.newScreens.details.show().season().info.href,
                    element: <DetailsSection />,
                },
                {
                    path: constants.newScreens.details.show().season().moreLikeThis.href,
                    element: <RelatedSection />,
                },
                {
                    path: constants.newScreens.details.show().season().trailers.href,
                    element: <TrailersSection />,
                }
            ]
        },
        {
            path: constants.newScreens.details.movie().href,
            element: <MovieDetails />,
            errorElement: <ErrorComponent pageType={constants.screens.movies} />,
            loader: async ({ params }) => { const movie = await getMovieDetails(params.movieId!); return { movie } },
            children: [
                {
                    path: constants.newScreens.details.movie().href,
                    element: <DetailsSection />,
                    index: true,
                },
                {
                    path: constants.newScreens.details.movie().moreLikeThis.href,
                    element: <RelatedSection />,
                },
                {
                    path: constants.newScreens.details.movie().trailers.href,
                    element: <TrailersSection />,
                }
            ],
        }
    ]
};

export default DetailsRouter;