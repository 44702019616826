import { createContext, useContext } from "react";
import { ScrollPosition } from "react-lazy-load-image-component";

export const ScrollContext = createContext<ScrollPosition | null>(null);
export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    // throw new Error('useScrollContext must be used within a ScrollContext');
    return undefined;
  }
  return context;
};
