import {
  CLEAR_AUTHENTICATION,
  SAVE_AUTHENTICATION,
  LOGOUT_USER,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  SWITCH_LANGUAGE,
  SAVE_LANGUAGE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAIL,
  DELETE_ACCOUNT_SUCCESS,
} from '../Constants/AuthenticationConstants';
import { SAVE_PROFILE } from '../Constants/AuthProfileConstants';

/**
 * Calculates the user authentication type based on the provided parameters.
 *
 * @param {boolean} isSignedIn - Indicates whether the user is signed in.
 * @param {boolean} isGuest - Indicates whether the user is a guest.
 * @returns {string|null} The user authentication type. Possible values are "user", "guest", "none", or null.
 */
export function calculateUserAuthType(isSignedIn, isGuest) {
  if (isSignedIn) {
    return 'user';
  }
  if (isGuest) {
    return 'guest';
  }

  return null;
}

const initialState = {
  access_token: null,
  expires_in: null,
  token_type: null,
  scope: null,
  refresh_token: null,
  refresh_token_header: null,
  isSignedIn: null,
  isGuest: null,
  userAuthType: null,
  socketJwt: null,
  logout: {
    loading: false,
    error: null,
    success: false,
  },
  profile: null,
  language: 'ar',
};

const reducer = (state = initialState, action) => {
  let data;
  switch (action.type) {
    case SAVE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SAVE_AUTHENTICATION:
      data = action.payload;
      return {
        ...state,
        ...action?.payload,
        ...action?.payload?.data?.result,
        userAuthType: calculateUserAuthType(data?.isSignedIn, data?.isGuest),
      };
    case (LOGOUT_USER, DELETE_ACCOUNT): {
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
        isSignedIn: null,
        userAuthType: null,
      };
    }
    // case DELETE_ACCOUNT: {
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //     success: false,
    //   };
    // }
    case (LOGOUT_USER_SUCCESS, DELETE_ACCOUNT_SUCCESS): {
      return {
        ...state,
        loading: false,
        success: true,
        isSignedIn: null,
      };
    }
    case (LOGOUT_USER_FAIL, DELETE_ACCOUNT_FAIL): {
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    }
    // case SEND_DELETE_OTP: {
    //   return {
    //     ...state,
    //     otpLoading: true,
    //     otpSuccess: false,
    //     otpFail: null,
    //     loading: false
    //   };
    // }
    // case SEND_DELETE_OTP_SUCCESS: {
    //   return {
    //     ...state,
    //     otpLoading: false,
    //     otpSuccess: true,
    //     otpFail: null
    //   };
    // }
    // case SEND_DELETE_OTP_FAIL: {
    //   return {
    //     ...state,
    //     otpLoading: false,
    //     otpFail: action.error?.response?.data?.data?.message,
    //     success: false,
    //   };
    // }
    case SAVE_PROFILE: {
      return {
        ...state,
        profile: action.payload.id,
      };
    }
    case SWITCH_LANGUAGE: {
      return {
        ...state,
        language: state.language === 'ar' ? 'en' : 'ar',
      };
    }
    case CLEAR_AUTHENTICATION: {
      return {
        ...state,
        access_token: null,
        expires_in: null,
        token_type: null,
        scope: null,
        refresh_token: null,
        refresh_token_header: null,
        isSignedIn: null,
        socketJwt: null,
        logout: {
          loading: false,
          error: null,
          success: false,
        },
        profile: null,
        isGuest: null,
        userAuthType: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
