import { CLEAR_PAYMENT_ALERT, HIDE_PAYMENT_ALERT } from '../Constants/ShowPaymentAlertConstants';

const initialState = {
  isHiddenPaymentAlert: false,
};

const ShowPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_PAYMENT_ALERT: {
      return {
        ...state,
        isHiddenPaymentAlert: action.payload.bool,
      };
    }
    case CLEAR_PAYMENT_ALERT: {
      return {
        initialState,
      };
    }
    default:
      return state;
  }
};

export default ShowPaymentReducer;
