import React, { Fragment, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './PurchaseButton.module.scss';
import { useAppSelector } from '@Store/hooks';
import { IPurchaseButtonProps } from './PurchaseButton.types';

const PurchaseButton: React.FC<IPurchaseButtonProps> = ({
  filled = false,
  type,
  isEpisode = true,
  price = null,
  currency,
  onClick = () => { },
  paymentModal = false,
  shouldRender = true
}) => {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.authentication);

  const buttonStyles = useMemo(() => {
    const stylesArray = [styles.button];
    if (filled) {
      stylesArray.push(styles.filled);
    } else if (paymentModal) {
      stylesArray.push(styles.paymentModal);
    } else {
      stylesArray.push(styles.bordered);
    }

    if (language === 'ar') {
      stylesArray.push(styles.right);
    }

    return stylesArray.join(' ');
  }, [language, filled, paymentModal]);

  const buttonText = useMemo(() => {
    let out = '';
    if (type === 'purchase' || type === 'season') {
      out += `${t('globals.buy')}`;
    } else {
      out += `${t('globals.rent')}`;
    }

    if (type === 'season') {
      out += ` ${t('globals.season')}`;
    } else if (isEpisode) out += ` ${t('globals.theEpisode')}`;
    else out += ` ${t('globals.movie')}`;

    return out;
  }, [isEpisode, type, t]);

  const priceText = useMemo(() => {
    if (price && currency) {
      return `${price}${currency}`;
    }
    return '';
  }, [price, currency]);

  if (shouldRender === false) return <Fragment />;
  return (
    <Button className={buttonStyles} dir='auto' onClick={onClick}>
      <div dir='auto' className={styles.text}>
        {/* <div className={styles.textsContainer}> */}
        <span>{buttonText} </span>
        <span className={styles.priceText} dir='ltr'>{priceText}</span>
        {/* </div> */}
      </div>
    </Button>
  );
}

const MemoizedPurchaseButton = React.memo(PurchaseButton);
export default MemoizedPurchaseButton;
