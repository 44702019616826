import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../../Atoms/ErrorBoundary';
import logo from '../../../Assets/Images/nouveau/ballam-logo.svg';
import arrowRight from '../../../Assets/Images/back/icons-arrows-arrow-right.svg';
import styles from './NonAuthenticatedLayout.module.scss';
import constants from '../../../Utils/constants';

const NonAuthenticatedLayout = ({
  children,
  title,
  contentContainerClassName,
  contentClassName,
  showLogo,
  profiles,
  withBackButton,
}) => {
  const navigate = useNavigate();
  const { language } = useSelector((state) => state.authentication);

  return (
    <ErrorBoundary>
      <Container fluid className={styles.nonAuthenticatedPage}>
        <div className={classNames(styles.contentContainer, contentContainerClassName,)}>
          <div className={classNames(styles.content, contentClassName)}>
            {showLogo && (
              <Link to={constants.screens.browse} className={styles.logo}>
                <img src={logo} alt="" className="d-none d-md-block" />
              </Link>
            )}
            {!profiles && (
              <div className={styles.formContent}>
                {title && (
                  <div className={styles.titleContainer}>
                    {withBackButton && (
                      <div className={language === 'ar' ? styles.arrowRight : styles.arrowEn} onClick={() => navigate(-1)}>
                        <img src={arrowRight} alt="" className="d-md-block" />
                      </div>
                    )}
                    <div className={classNames(styles.title, !withBackButton ? styles.noBack : null,)}>
                      {title}
                    </div>
                  </div>
                )}
                {children}
              </div>
            )}
          </div>
        </div>
      </Container>
    </ErrorBoundary>
  );
};

NonAuthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  contentClassName: PropTypes.string,
  showLogo: PropTypes.bool,
  profiles: PropTypes.bool,
  withBackButton: PropTypes.bool,
  // isDeleteAccountOTP: PropTypes.bool
};

NonAuthenticatedLayout.defaultProps = {
  title: '',
  contentClassName: '',
  showLogo: true,
  profiles: false,
  withBackButton: true,
  // isDeleteAccountOTP: false
};

export default NonAuthenticatedLayout;
