import { useAppSelector } from '@Store/hooks';
import React, { Fragment } from 'react';
import SidebarNavigationItem from '../SidebarNavigationItem/SidebarNavigationItem';
import NavigationItem from '../NavigationItem/NavigationItem';
import { getDropdownItemUrl } from '../helpers';
import { INavigationLinksProps } from './NavigationLinks.types';
import { ISidebarNavigationElement } from '../SidebarNavigationItem/SidebarNavigationItem.types';
import NavigationItemDropdown from '../NavigationItemDropdown/NavigationItemDropdown';

const NavigationLinks: React.FC<INavigationLinksProps> = ({ isSidebar = false, setSideBarData }) => {
    const { result } = useAppSelector((state) => state.videoSettings);
    const { isSignedIn } = useAppSelector((state) => state.authentication);
    //@ts-expect-error might need to type result
    const categories = result?.header?.categories as Array<ISidebarNavigationElement>;

    if (!categories) return <Fragment />;
    const categoriesArray = categories.filter((elm) => (isSignedIn ? elm.type !== 'null' : elm.type !== 'bookmark')).slice(0, 4);
    const remainingCategories = categories.filter((elm) => (isSignedIn ? elm.type !== 'null' : elm.type !== 'bookmark')).slice(4);
    return (
        <Fragment>
            {
                categoriesArray.map((elm, index) => {
                    const url = getDropdownItemUrl(elm.type, elm.id);
                    if (isSidebar) {
                        return <SidebarNavigationItem key={index} elm={elm} url={url} setSideBarData={setSideBarData} />
                    }
                    return <NavigationItem key={index} elm={elm} url={url} />;
                })
            }
            <NavigationItemDropdown categories={remainingCategories} />
        </Fragment>
    );
}

export default NavigationLinks
