import { useTranslation } from 'react-i18next';
import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import styles from './LoadMoreButton.module.scss';
import Spinner from '../../../Assets/Images/Icons/Spinner.svg';
import { Button } from 'react-bootstrap';

function LoadMore({ onClick, disabled, className, isRendered, isLoading }) {
  const { t } = useTranslation();

  if (isRendered) {
    return (
      <Button
        variant='secondary'
        className={classNames(styles.showMore, className)}
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        <img className={classNames(styles.spinnerMargin, isLoading ? styles.spinning : '')} src={Spinner} alt="Spinner" />
        <span className={styles.text}>
          {isLoading ? t('globals.loadingMore') : t('globals.more')}
        </span>
      </Button>
    );
  }

  return <></>;
}

LoadMore.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  className: propTypes.string,
  isRendered: propTypes.bool,
  isLoading: propTypes.bool,
};

LoadMore.defaultProps = {
  disabled: false,
  isRendered: true,
  isLoading: false,
  className: '',
};

export default LoadMore;
