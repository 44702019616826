import {
  GET_AVATARS,
  GET_AVATARS_FAIL,
  GET_AVATARS_SUCCESS,
  CLEAR_AVATARS,
} from '../Constants/AvatarsConstants';
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-plusplus */

const initialState = {
  result: null,
  loading: false,
  error: null,
};

const AvatarReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AVATARS:
      return {
        ...state,
        loading: true,
      };
    case GET_AVATARS_SUCCESS:
      return {
        ...state,
        result: action.payload.data.data.result,
        loading: false,
      };
    case GET_AVATARS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_AVATARS:
      return initialState;
    default:
      return state;
  }
};

export default AvatarReducer;
