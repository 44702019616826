import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSeriesLanding, clearSeriesLanding } from "../../Store/Actions/SeriesLandingActions";
import { getSeriesFiltered, clearSeriesFiltered } from "../../Store/Actions/SeriesFilteredActions";
import { getGenres } from "../../Store/Actions/GenresActions";
import Genre from "../../Components/Organisms/FilteredGenre/FilteredGenre.jsx";
import ErrorBoundary from "../../Components/Atoms/ErrorBoundary";
import ErrorComponent from "../../HOC/ErrorComponent";
import constants from "../../Utils/constants";

const Series = () => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const { t } = useTranslation();
  const { loading, result, error } = useSelector((state) => state.movies.landing);
  const { loading: genresLoading, result: genresResult, error: genresError } = useSelector((state) => state.genres);
  const { loading: filteredLoading, result: filteredResult, data: filteredData, error: filteredError } = useSelector((state) => state.movies.filtered);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getGenres());
    dispatch(getSeriesLanding());


    return () => {
      dispatch(clearSeriesLanding());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedGenre) {
      dispatch(getSeriesFiltered(selectedGenre));
    } else {
      dispatch(clearSeriesFiltered());
    }
  }, [dispatch, selectedGenre]);

  if (error || genresError || filteredError) return <ErrorComponent error={error || genresError || filteredError} pageType={constants.screens.series} />;
  return (
    <ErrorBoundary>
      <Genre
        title={t("landing.series")}
        type="series"
        genresLoading={genresLoading}
        genres={genresResult}
        selectedGenre={genresResult ? genresResult.find((genre) => genre.id === selectedGenre)?.name : null}
        onGenreChange={(evtKey) => setSelectedGenre(evtKey)}
        loading={filteredLoading || loading}
        data={selectedGenre ? filteredData : result}
        showMoreBtn={!!selectedGenre && filteredResult?.currentPage < filteredResult?.totalPages}
        onSelectMore={() => dispatch(getSeriesFiltered(selectedGenre, filteredResult.currentPage + 1))}
        filtered={!!selectedGenre}
      />
    </ErrorBoundary>
  );
};

export default Series;
