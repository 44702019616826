import {
  GET_SEARCH_HISTORY,
  ADD_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY,
} from '../Constants/SearchHistoryConstants';

export const getSearchHistory = () => ({
  type: GET_SEARCH_HISTORY,
  payload: {
    request: {
      url: '/api/search/history',
      method: 'get',
      authenticated: true,
    },
  },
});
export const addSearchHistory = (keyword, mediaType, selectedMedia) => ({
  type: ADD_SEARCH_HISTORY,
  payload: {
    request: {
      url: `api/search/history?id=${selectedMedia}`,
      method: 'post',
      authenticated: true,
      data: {
        type: mediaType,
        text: keyword,
      },
    },
  },
});

export const deleteSearchHistory = (id) => ({
  type: DELETE_SEARCH_HISTORY,
  payload: {
    id,
    request: {
      url: `api/search/history/${id}`,
      method: 'delete',
      authenticated: true,
    },
  },
});
