import { SubscriptionSource } from "@Store/Reducers/subscriptionsSourceReducer";
import { IMediaSource, isMovie, ISubscriptionSource, Platform, PurchasableMedia, PurchaseType, Source } from "./commonTypes";
import { getEpisodeByIdFromSeries } from "./common";
import { Language } from "./commonTypes";
import { Movie, Series } from "src/alballam-api-types";


/**
 * Prepares a subscription source string based on the provided platform, package, and source details.
 *
 * @param {Object} params - The parameters for preparing the subscription source.
 * @param {Platform} params.platform - The platform for the subscription source (default is Platform.WEB).
 * @param {Object} params.pckg - The package details.
 * @param {string} params.pckg.name - The name of the package.
 * @param {string} params.pckg.id - The ID of the package.
 * @param {SubscriptionSource} params.source - The source details for the subscription.
 * @returns {string} The prepared subscription source string.
 */
export function prepareSubscriptionSource({
    platform = Platform.WEB, pckg, // package is a reserved word
    source,
}: {
    platform: Platform;
    pckg: { name: string; id: string; };
    source: SubscriptionSource;
}) {
    const temp = [], encodedPackageName = (pckg.name);
    temp.push(platform);

    switch (true) {
        case source.sourceType === PurchasableMedia.Movie:

            temp.push(source.movieName ?? '');
            temp.push(source.movieId ?? '');
            break;

        case source.sourceType === PurchasableMedia.Season:

            temp.push(source.seriesName ?? '');
            temp.push(source.seriesId ?? '');

            temp.push(source.seasonName ?? '');
            temp.push(source.seasonId ?? '');
            break;

        case source.sourceType === PurchasableMedia.Episode:

            temp.push(source.seriesName ?? '');
            temp.push(source.seriesId ?? '');

            temp.push(source.seasonName ?? '');
            temp.push(source.seasonId ?? '');

            temp.push(source.episodeName ?? '');
            temp.push(source.episodeId ?? '');
            break;

        case source.sourceType === 'route':
            temp.push(source.routeName ?? '');
            break;

        default:
            break;
    }

    temp.push(encodedPackageName);
    temp.push(pckg.id);
    temp.push(source?.language?.toLowerCase());

    return temp.join('-');
}

/**
 * Prepares a purchase source string based on the provided platform, payment data, and language.
 *
 * @param {Object} params - The parameters for preparing the purchase source.
 * @param {Platform} params.platform - The platform from which the purchase is made. Defaults to Platform.WEB.
 * @param {Source} params.paymentData - The payment data containing purchase information.
 * @param {Language} params.language - The language in which the purchase is made. Defaults to Language.EN.
 * 
 * @returns {string} A string representing the purchase source, formatted as a hyphen-separated list of values.
 * If an error occurs during processing, an empty string is returned.
 */
export const preparePurchaseSource = ({
    platform = Platform.WEB, paymentData, language = Language.EN
}: {
    platform: Platform;
    paymentData: Source;
    language: Language;
}) => {
    const out = [];
    try {

        out.push(platform);
        out.push(paymentData.purchaseType);

        if (paymentData.purchaseType === PurchaseType.Media) {
            const data = paymentData as IMediaSource;
            out.push(data.type);
            out.push(data.mediaId);

            if (data.type === PurchasableMedia.Episode) {
                out.push(data.seasonId ?? "");
            }

            out.push(data.title);
        } else {
            const data = paymentData as ISubscriptionSource;
            out.push(data.length?.toString());
            out.push(data.price?.toString());
            out.push(data.title);
        }


        out.push(language?.toLowerCase());
        return out.join('-');
    } catch {
        return "";
    }
};

/**
 * Parses a TV source string and returns an object containing platform, media type, and id.
 * The source string is expected to be in the format: platform_mediaType_id.
 *
 * @param source - The TV source string to parse.
 * @returns An object with the following properties:
 * - platform: The platform extracted from the source string.
 * - mediaType: The media type extracted from the source string.
 * - id: The id extracted from the source string.
 *
 * If parsing fails, returns a default object with platform set to `Platform.WEB`,
 * mediaType set to `PurchasableMedia.Movie`, and id set to "0000000".
 */

export function parseTVSource(source: string | null) {
    // format: platform-mediaType-id;
    try {
        if (!source) throw new Error('No source provided');
        const temp = source.split('_');
        return {
            platform: temp[0] as Platform,
            mediaType: temp[1] as PurchasableMedia,
            id: temp[2],
            language: temp[3] as Language
        };
    } catch {
        return {
            platform: Platform.WEB,
            mediaType: PurchasableMedia.Movie,
            id: "0000000",
            language: Language.EN
        };
    }
}

/**
 * Parses the subscription source based on the provided media and parameters.
 *
 * @param currentMedia - The current media object, which can be a Movie or Series, or undefined.
 * @param season - An object containing the season ID and name.
 * @param toBePurchasedMedia - An object containing the type and ID of the media to be purchased.
 * @param pageParams - An object containing the seasonable media ID.
 * @param language - The language of the subscription source.
 * @returns An object representing the subscription source.
 *
 * @remarks
 * The function determines the type of media (Movie, Season, or Episode) and constructs
 * the subscription source object accordingly. If the current media is not provided,
 * it returns a default subscription source object with the type set to Movie.
 *
 * @throws Will log an error to the console if an exception occurs during the parsing process.
 */
export function parseSubscriptionSource(
    currentMedia: Movie | Series | undefined,
    season: { id: string | null; name: string | null; },
    toBePurchasedMedia: { type: PurchasableMedia | undefined; id: string | undefined; },
    pageParams: { seasonableMediaId: string | undefined; },
    language: Language
) {
    const out: SubscriptionSource = { sourceType: PurchasableMedia.Movie, language };

    if (!currentMedia) return out;
    try {
        let media, selectedEpisode;



        switch (true) {
            case toBePurchasedMedia.type === PurchasableMedia.Movie && isMovie(currentMedia):
                media = currentMedia as Movie;
                out.movieId = media.id.toString(); // this is a param
                out.movieName = (media.sourceTitle);
                out.sourceType = PurchasableMedia.Movie;
                break;

            case toBePurchasedMedia.type === PurchasableMedia.Season && isMovie(currentMedia) === false:
                media = currentMedia as Series;


                out.seasonId = toBePurchasedMedia.id!;
                out.seasonName = (season.name!);
                out.sourceType = PurchasableMedia.Season;
                out.seriesId = pageParams?.seasonableMediaId; // show/series do not have id in their returned obj
                out.seriesName = (media.sourceName);
                break;

            case toBePurchasedMedia.type === PurchasableMedia.Episode && isMovie(currentMedia) === false:
                media = currentMedia as Series;
                selectedEpisode = getEpisodeByIdFromSeries(media, toBePurchasedMedia.id!, season.id!);

                out.episodeId = toBePurchasedMedia.id!;
                out.episodeName = (selectedEpisode?.episodeTitle ?? selectedEpisode?.title ?? '');
                out.sourceType = PurchasableMedia.Episode;
                out.seriesId = pageParams?.seasonableMediaId; // show/series do not have id in their returned obj
                out.seriesName = (media.sourceName);
                out.seasonId = season.id;
                out.seasonName = (season.name!);
                break;

            default:
                return out;
        }

        return out;
    } catch (e) {
        console.error('[updateSource] Error occurred: ', e);
        return out;
    }
}

