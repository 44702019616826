import {
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  CLEAR_PASSWORD_RESET_DATA,
} from '../Constants/ResetPasswordConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    }
    case RESET_PASSWORD_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case CLEAR_PASSWORD_RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default resetPasswordReducer;
