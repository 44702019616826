import React from 'react';
import { useTranslation } from 'react-i18next';
import './FullWatchHeader.scss';

const FullWatchHeader = ({ duration, isCoupon }) => {
  const { t } = useTranslation();
  return (
    <div className="fullWatchHeader">
      <span className="headerBold">
        {isCoupon ? t('globals.couponValidTill') : t('globals.Subscribed')}
      </span>
      <span className="headerLight">
        {`${t('globals.validFor')}  ${duration}`}
      </span>
    </div>
  );
};
export default FullWatchHeader;
