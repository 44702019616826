import constants from '@Utils/constants';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './ListHeader.module.scss';
import { IListHeaderProps } from './ListHeader.types';
import classNames from 'classnames';


const getLinkTo = (type: string, section: string | { [key: string]: string }) => {
    if (type === 'category') {
        return constants.screens.category(section).as;
    }
    if (type === 'comingSoon') {
        return constants.screens.comingSoon;
    }
    return constants.screens.genre(section).as;
};

const ListHeader: React.FC<IListHeaderProps> = ({ type, sectionTitle, section, wrapperClassName }) => {
    const { t, i18n } = useTranslation();
    const link = getLinkTo(type, section);
    const title = typeof sectionTitle === 'object' ? sectionTitle[i18n.language] : sectionTitle;
    const shouldRenderShowAll = type === 'category' || type === 'genre' || type === 'comingSoon';

    return (
        <div className={classNames(styles.root, wrapperClassName)}>
            {shouldRenderShowAll ? (
                <>
                    <Link className={styles.titleLink} to={link}>{title}</Link>
                    <Link className={styles.showMoreLink} to={link}>
                        {t('homepage.showAll')}
                    </Link>
                </>
            ) : (
                <h1 className={styles.title}>{title}</h1>
            )}
        </div>
    )
}

export default ListHeader