import {
  GET_BREAKS,
  GET_BREAKS_SUCCESS,
  GET_BREAKS_FAIL,
  CLEAR_BREAKS,
} from "../Constants/BreaksConstants";

const initialState = {
  loading: true,
  result: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BREAKS: {
      return {
        ...state,
        error: null,
        loading: true,
        result: null,
      };
    }
    case GET_BREAKS_SUCCESS: {
      const { result } = action.payload.data.data;

      return {
        ...state,
        loading: false,
        result,
      };
    }
    case GET_BREAKS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_BREAKS: {
      return {
        ...initialState,
        details: {
          ...state,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
