/* eslint-disable react/destructuring-assignment */
import {
  ADD_SEARCH_KEYWORD,
  CLEAR_SEARCH_KEYWORD,
  GET_SEARCHDATA,
  GET_SEARCHDATA_FAIL,
  GET_SEARCHDATA_SUCCESS,
  CLEAR_SEARCHDATA,
} from '../Constants/GlobalSearchConstants';

const initialState = {
  keyword: null,
  result: {
    currentPage: 0,
  },
  items: [],
  array: [],
  loading: true,
  error: null,
  hasMore: false,
};

const SearchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SEARCH_KEYWORD: {
      return {
        ...state,
        keyword: action.payload.keyword,
      };
    }
    case CLEAR_SEARCH_KEYWORD: {
      return initialState;
    }
    case GET_SEARCHDATA: {
      return {
        ...state,
        result: null,
        loading: action.payload?.page <= 1,
        error: null,
      };
    }
    case GET_SEARCHDATA_SUCCESS: {
      const {
        data: { result },
      } = action.payload.data;
      if (result.totalHits === 0) {
        return {
          ...state,
          result: null,
          items: [],
          array: [],
          loading: action.payload?.page,
          hasMore: false,
        };
      }
      return {
        ...state,
        result,
        items: result,
        array: state.array.concat([action.payload.data.data.result.data]),
        loading: action.payload?.page,
        hasMore: result.currentPage < result.totalPages,
      };
    }
    case GET_SEARCHDATA_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CLEAR_SEARCHDATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default SearchDataReducer;
