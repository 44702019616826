import React, { Fragment, SyntheticEvent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import styles from './PlayerControlButton.module.scss';
import ClickableItem from '../../Atoms/ClickableItem/ClickableItem';

interface IPlayerControlButton {
  icon: string;
  onClick: () => void;
  containerClasses?: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children?: React.ReactNode;
  onMouseMove: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  clickItemRef?: React.RefObject<HTMLDivElement>;
  resetActivity: () => void;
}

const PlayerControlButton: React.FC<IPlayerControlButton> = ({
  icon,
  onClick,
  containerClasses,
  onMouseEnter = () => { },
  onMouseLeave = () => { },
  onMouseMove = () => { },
  children,
  clickItemRef,
  resetActivity,
}) => {
  const [show, setShow] = useState(false);

  const onMouseEnterHandler = useCallback(() => {
    onMouseEnter();
    setShow(true);
  }, [onMouseEnter]);

  const onMouseLeaveHandler = useCallback(() => {
    onMouseLeave();
    setShow(false);
  }, [onMouseLeave]);

  const onClickHandler = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.currentTarget.blur();

    if (isMobile && children) {
      if (!show) {
        onMouseEnterHandler();
      } else {
        onMouseLeaveHandler();
      }
      return;
    }

    onClick();
    resetActivity();
  }, [children, onClick, resetActivity, show, onMouseEnterHandler, onMouseLeaveHandler]);

  return (
    <div
      role='button'
      className={classNames(styles.controlButton, show ? styles.scale : null, containerClasses)}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onMouseMove={onMouseMove}
    >
      <ClickableItem
        itemRef={clickItemRef}
        classes={classNames(styles.iconButton, styles.noOutline)}
        onClick={onClickHandler}
      >
        <img src={icon} className={styles.icon} alt="Icon" />
      </ClickableItem>

      {show ? children ?? <Fragment /> : <Fragment />}
    </div>
  );
};

const MemoizedPlayerControlButton = React.memo(PlayerControlButton);
export default MemoizedPlayerControlButton;
