import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { getStatic } from "../../../Store/Actions/StaticScreenActions";
import reduxConstants from "../../../Store/constants";
import closeIcon from "../../../Assets/Images/exit.svg";
import ErrorComponent from "../../../HOC/ErrorComponent";
import constants from "../../../Utils/constants";
import "./termsAndConditionsModal.scss";

const TermsAndConditionsModal = ({ showModal, onCloseButtonClick }) => {
  const dispatch = useDispatch();
  const { result, error } = useSelector(
    (state) => state.staticScreens.termsAndConditions
  );
  const { language } = useSelector((state) => state.authentication);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getStatic(reduxConstants.staticApis.termsAndConditions));
  }, [dispatch]);

  if (
    !result?.data?.data?.result[0]?.block[0]?.title ||
    !result?.data?.data?.result[0]?.block[0]?.text
  ) {
    return null;
  }

  if (error) {
    return (
      <ErrorComponent
        error={error}
        pageType={constants.screens.termsAndConditions}
      />
    );
  }

  if (!result) return <Fragment />;
  return (
    <Modal animation show={showModal} onEscapeKeyDown={() => onCloseButtonClick()} dialogClassName="modalTermsContainer flex-grow" onHide={() => onCloseButtonClick()}>
      <Modal.Body>
        <Button className="exitBtn" onClick={onCloseButtonClick}>
          <img
            onContextMenu={(e) => e.preventDefault()}
            className={language === "ar" ? "exitImg" : "exitImgAr"}
            src={closeIcon}
            alt=""
            width="10"
            height="10"
          />
        </Button>
        <div className="bodyContainer">
          <div className="titleContainer">
            <span className="title">
              {result?.data?.data?.result[0]?.title ||
                t("globals.termsAndConditions")}
            </span>
          </div>
          <div className="termsContainer">
            <span className={language === "ar" ? "terms" : "termsAr"}>
              {parse(result?.data?.data?.result[0]?.block[0]?.text || "")}
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

TermsAndConditionsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
};

export default TermsAndConditionsModal;
