import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import ClickableItem from '../ClickableItem/ClickableItem';

import VolumeHigh from '../../../Assets/Images/VideoControls/volume-high.svg';
import VolumeMuted from '../../../Assets/Images/VideoControls/volume-mute.svg';

import styles from '../../Molecules/PlayerControlButton/PlayerControlButton.module.scss';

const renderVolumeButton = (volume) => {
  if (volume === null || volume >= 0.7) return VolumeHigh;
  if (volume === 0) return VolumeMuted;

  return VolumeMuted;
};

const VolumeSlider = ({
  onClick,
  containerClasses,
  volume,
}) => {
  const [show, setShow] = useState(false);

  const onMouseEnterHandler = () => {
    setShow(true);
  };

  const onMouseLeaveHandler = () => {
    setShow(false);
  };

  const onClickHandler = useCallback(
    (event) => {
      event.stopPropagation();
      event.currentTarget.blur();

      onClick();
    },
    [onClick],
  );

  return (
    <div
      className={classNames(
        styles.controlButton,
        containerClasses,
        show ? styles.scale : null,
      )}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <ClickableItem
        classes={classNames(styles.iconButton, styles.noOutline)}
        onClick={onClickHandler}
      >
        <img
          src={renderVolumeButton(volume)}
          className={styles.icon}
          alt="Icon"
        />
      </ClickableItem>
    </div>
  );
};

export default React.memo(VolumeSlider);
