import React, { Fragment, useState } from 'react'
import { Navbar, Offcanvas, Image, Button } from 'react-bootstrap'
import BurgerIcon from '@Assets/Images/Icons/icons-menu@3x.png';
import Globe from '@Assets/Images/nouveau/globe.svg';
import { useAppSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';
import { switchLanguages } from '@Store/Actions/AuthenticationActions';
import styles from './SideBar.module.scss';
import i18n from 'src/i18n';
import { getDropdownItemUrl } from '../helpers';
import SidebarNavigationItem from '../SidebarNavigationItem/SidebarNavigationItem';
import { useTranslation } from 'react-i18next';
import Logout from '@Assets/Images/nouveau/logout.svg';
import SideBarItem from '@Atoms/SideBarItem/SideBarItem';
import { showPaymentAlert } from '@Store/Actions/ShowPaymentAlertActions';
import { Link } from 'react-router-dom';
import { logoutUser } from '@Store/Actions/AuthenticationActions';
import constants from '@Utils/constants';
import ProfileLink from './ProfileLink/ProfileLink';
import classNames from 'classnames';


interface ISideBarProps {
    shouldRender: boolean;
    setShouldRender: (value: boolean) => void;
}

function getLanguageButtonContent(language: string, isLoading: boolean) {
    if (isLoading) {
        return 'Loading...';
    }
    return language === 'ar' ? 'En' : 'Ar';
}

const SideBar: React.FC<ISideBarProps> = ({ shouldRender, setShouldRender }) => {
    const { result } = useAppSelector((state) => state.videoSettings);
    const { userAuthType } = useAppSelector((state) => state.authentication);
    const { language } = useAppSelector((state) => state.authentication);
    const dir = i18n.dir();
    const [isLoading, setIsLoading] = useState(false);
    const hideSidebar = () => setShouldRender(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    //@ts-expect-error might need to type result
    const categories = result?.header?.categories as Array<ISidebarNavigationElement>;
    const categoriesArray = categories.filter((elm) => (userAuthType === 'user' ? elm.type !== 'null' : elm.type !== 'bookmark'));

    const languageButtonContent = getLanguageButtonContent(language, isLoading);
    const canvasPlacement = dir == 'ltr' ? 'start' : 'end';
    const toggleLanguage = () => {
        setIsLoading(true);
        dispatch(switchLanguages());
        setTimeout(() => window.location.reload(), 0);
    }

    const onLogoutClick = () => {
        //@ts-expect-error dispatch might not be a thunk
        dispatch(logoutUser()).then(() => {
            dispatch(showPaymentAlert(false));
        });
        setShouldRender(false);
    };

    return (
        <Navbar.Offcanvas show={shouldRender} onHide={hideSidebar} placement={canvasPlacement}>
            <Offcanvas.Header className={classNames(styles.header, styles[`header_${dir}`])}>
                <Image src={BurgerIcon} alt="burger-icon" width="21" height="16" onClick={hideSidebar} />
                <button className={styles.languageButton} onClick={toggleLanguage}>
                    <Image src={Globe} alt="language-toggle" width={20} height={20} />
                    <span dir='auto'>{languageButtonContent}</span>
                </button>
            </Offcanvas.Header>
            {userAuthType === 'user' && <ProfileLink />}
            <Offcanvas.Body className={styles.body}>
                {categoriesArray.map((elm, index) => {
                    const url = getDropdownItemUrl(elm.type, elm.id);
                    return <SidebarNavigationItem setSideBarData={setShouldRender} key={index} elm={elm} url={url} />;
                })}
                {userAuthType === 'user' && (
                    <Fragment>
                        <SideBarItem onClick={() => {}} url={constants.screens.profile}>
                            {t('globals.myAccount')}
                        </SideBarItem>
                        <SideBarItem onClick={() => {}} url={constants.screens.myPurchases}>
                            {t('globals.myPurchases')}
                        </SideBarItem>
                        <SideBarItem url='#' onClick={onLogoutClick} classes={styles.logoutItem}>
                            <Fragment>
                                <img src={Logout} alt='logout-icon' />
                                {t('globals.logout')}
                            </Fragment>
                        </SideBarItem>
                    </Fragment>
                )}
            </Offcanvas.Body>
            {userAuthType !== 'user' && (
                <Link to={constants.screens.login} className={styles.loginButtonWrapper}>
                    <Button dir='auto' className={styles.loginButton}>{t('globals.login')}</Button>
                </Link>
            )}
        </Navbar.Offcanvas>
    )
}

export default SideBar