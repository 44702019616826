import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './AddProfile.module.scss';
import arrowRight from '../../Assets/Images/back/icons-arrows-arrow-right.svg';
import EditProfileName from '../../Components/Organisms/EditProfileNameForm/EditProfileName';
import AddPic from '../AddPic/AddPic';
import constants from '../../Utils/constants';
import { getAvatars } from '../../Store/Actions/AvatarActions';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

const AddProfile = () => {
  const navigate = useNavigate();
  const [addPic, setAddPic] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [inpt, setInpt] = useState('');
  const { success } = useSelector((state) => state.ProfilesPerUser);
  const { language } = useSelector((state) => state.authentication);
  const avatars = useSelector((state) => state.avatar.result);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!avatars) {
      dispatch(getAvatars());
    }
  }, [dispatch, avatars]);

  useEffect(() => {
    if (avatars && !addPic && !avatar) {
      setAvatar({ file: avatars?.find((e) => e.default === true)?.link, ext: '' });
    }
  }, [dispatch, avatars, addPic, avatar]);

  useEffect(() => {
    if (success) {
      navigate(constants.newScreens.profiles.view);
      setAddPic(false);
    }
  }, [success, navigate]);

  if (addPic) {
    return (
      <div className={styles.nonAuthenticatedPage}>
        <div className={classNames(styles.editRectangle, styles.expandedWidth)}>
          <div className={styles.titleContainer}>
            <div onClick={() => setAddPic(false)}
              className={language === 'ar' ? styles.arrowRight : styles.arrowEng}
            >
              <img src={arrowRight} alt="" className="d-md-block" />
            </div>
            <div dir='auto' className={language === 'ar' ? styles.title : styles.titleEng}>{t('profiles.addProfile')}</div>
          </div>
          <AddPic setAddPic={setAddPic} avatar={avatar} setAvatar={setAvatar} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.nonAuthenticatedPage}>
      <div className={styles.editRectangle}>
        <div className={styles.titleContainer}>
          <div onClick={() => navigate(-1)} className={language === 'ar' ? styles.arrowRight : styles.arrowEng}>
            <img src={arrowRight} alt="back-arrow" className="d-md-block" />
          </div>
          <div dir='auto' className={language === 'ar' ? styles.title : styles.titleEng}>{t('profiles.addProfile')}</div>
        </div>
        <div className={styles.addProfileGroup}>
          <div className={styles.rectangle} src={avatar?.file && `data:image/png;base64,${avatar?.file[1]}`}
            onClick={() => setAddPic(true)}
            style={avatar?.file && {
              border: (avatar?.file[0] && avatar?.file[1]) || (avatar?.ext === '' && avatar?.file) ? 'none' : 'solid 2px #ffffff',
            }}
          >
            {avatar?.file && (
              <Fragment>
                {avatar?.file[0] && avatar?.file[1] && (
                  <img alt="current-image" src={avatar?.ext === '' ? `${avatar?.file}` : `${avatar?.file[0]},${avatar?.file[1]}`}
                    className={styles.imageAvatar}
                  />
                )}
              </Fragment>
            )}
          </div>
          <Button dir='auto' variant='secondary' className={styles.editPic} onClick={() => setAddPic(true)}>
            {t('profiles.editImage')}
          </Button>
        </div>
        <EditProfileName AddProfile avatar={avatar} setInpt={setInpt} inpt={inpt} />
      </div>
    </div>
  );
};

export default AddProfile;
