const GET_VIDEOS_TRACKING = 'GET_VIDEOS_TRACKING';
const GET_VIDEOS_TRACKING_FAIL = 'GET_VIDEOS_TRACKING_FAIL';
const GET_VIDEOS_TRACKING_SUCCESS = 'GET_VIDEOS_TRACKING_SUCCESS';

const CLEAR_VIDEOS_TRACKING = 'CLEAR_VIDEOS_TRACKING';

const GET_VIDEO_DASHBOARD = 'GET_VIDEO_DASHBOARD';
const GET_VIDEO_DASHBOARD_FAIL = 'GET_VIDEO_DASHBOARD_FAIL';
const GET_VIDEO_DASHBOARD_SUCCESS = 'GET_VIDEO_DASHBOARD_SUCCESS';
const REMOVE_CONTINUE_WATCHING = 'REMOVE_CONTINUE_WATCHING';
const REMOVE_CONTINUE_WATCHING_SUCCESS = 'REMOVE_CONTINUE_WATCHING_SUCCESS';
const REMOVE_CONTINUE_WATCHING_FAIL = 'REMOVE_CONTINUE_WATCHING_FAIL';

export {
  GET_VIDEOS_TRACKING, GET_VIDEOS_TRACKING_SUCCESS, GET_VIDEOS_TRACKING_FAIL,
  GET_VIDEO_DASHBOARD, GET_VIDEO_DASHBOARD_SUCCESS, GET_VIDEO_DASHBOARD_FAIL,
  CLEAR_VIDEOS_TRACKING, REMOVE_CONTINUE_WATCHING, REMOVE_CONTINUE_WATCHING_SUCCESS, REMOVE_CONTINUE_WATCHING_FAIL,
};
