import {
  GET_USER_SESSION,
  GET_USER_SESSION_FAIL,
  GET_USER_SESSION_SUCCESS,
  CLEAR_USER_SESSION,
} from '../Constants/UserSessionConstants';

const initialState = {
  isKillSession: null,
  uniqueID: null,
};

const userSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SESSION: {
      return {
        ...state,
      };
    }
    case GET_USER_SESSION_SUCCESS: {
      return {
        ...state,
        isKillSession: action.payload?.data?.killSession,
        uniqueID: action.payload?.data?.uniqueID ?? null,
      };
    }
    case GET_USER_SESSION_FAIL: {
      return {
        ...state,
        isKillSession: true,
        uniqueID: null,
      };
    }

    case CLEAR_USER_SESSION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default userSessionReducer;
