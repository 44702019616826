//@ts-nocheck
//TODO: Fix the types

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import VideoPlayer from '../../Components/Atoms/VideoPlayer/index';
import config from '../../Utils/config';
import firebase from '../../Utils/firebase';
import { getTrailer } from '../../Store/Actions/MovieActions';
import ErrorBoundary from '../../Components/Atoms/ErrorBoundary/index';
import Loader from './Loader/Loader';
import VideoPlayerControls from '../../Components/Molecules/VideoPlayerControls/index';
import { isWebview } from '@Utils/common';
import { isIpad } from '../../Utils/deviceDetect';
import constants from '../../Utils/constants';
import SomethingWentWrong from '../SomethingWentWrong/SomethingWentWrong';
import { useAppSelector } from '@Store/hooks';
import { useDenyWebview } from '@Pages/Watch/helpers';
import './TrailerPlayer.scss';

const initialState: IPlayerStatus = {
    showPlayer: false,
    playing: true,
    loading: true,
    muted: false,
    volume: 1,
    loaded: false,
    hasEnded: false,
    currentTime: 0,
    bufferedTime: 0,
    duration: 0,
    selectedSubtitle: 'Off',
    urlToPlay: '',
    title: '',
    image: '',
};

interface IPlayerStatus {
    showPlayer: boolean;
    playing: boolean;
    loading: boolean;
    muted: boolean;
    volume: number;
    loaded: boolean;
    hasEnded: boolean;
    currentTime: number;
    bufferedTime: number;
    duration: number;
    selectedSubtitle: string;
    urlToPlay: string;
    title: string;
    image: string;
}

function useOnMount(id, setPlayerStatus) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (config.env !== config.environments.dev) {
            firebase.analytics().logEvent('screen_view', {
                firebase_screen: '/watch',
                firebase_screen_class: id,
            });
        }

        dispatch(getTrailer(id)).then((data) => {
            const responseData = data?.payload?.data?.data?.result;
            setPlayerStatus((prev) => ({
                ...prev,
                urlToPlay: responseData?.link,
                loaded: true,
                playing: true,
                showPlayer: true,
                loading: false,
                duration: responseData?.duration,
                title: responseData?.title,
                image: responseData?.image,
            }));
        });
    }, [dispatch, id, setPlayerStatus]);
}

const TrailerPlayer: React.FC = () => {
    const { result, error } = useAppSelector((state) => state.movie);
    const [playerStatus, setPlayerStatus] = useState(initialState);
    const isWebView = isWebview();
    const videoRef = useRef(null);
    const seekTimeRef = useRef(-1);
    const navigate = useNavigate();
    const { id } = useParams();

    useDenyWebview();


    useOnMount(id, setPlayerStatus);
    const goBack = () => navigate(-1);
    const onPlayHandler = useCallback(() => setPlayerStatus((prevState) => ({
        ...prevState,
        playing: !prevState.playing,
    })), []);

    const onVolumeClickHandler = useCallback((volume = null) => {
        try {
            let newVolume = volume;
            if (newVolume === null) {
                newVolume = playerStatus.volume ? 0 : 1;
            }
            if (!isWebView) {
                videoRef.current.muted = false;
            }

            if (isIpad() && newVolume === 0) {
                setPlayerStatus((prevState) => ({
                    ...prevState,
                    muted: true,
                    volume: 0,
                }));
                return;
            }

            setPlayerStatus((prevState) => ({
                ...prevState,
                volume: +newVolume,
                muted: false,
            }));
        } catch { }
    }, [playerStatus.volume, isWebView]);

    const onForwardHandler = useCallback(() => {
        try {
            if (videoRef.current?.getCurrentTime() === null) return;
            const seekTo = Math.min(videoRef.current.getCurrentTime() + constants.videoPlayer.jumpTime, videoRef.current.getDuration());
            if (seekTo === videoRef.current.getDuration() && videoRef.playing) {
                setPlayerStatus((prevState) => ({
                    ...prevState,
                    playing: false,
                }));
            }
            videoRef.current.seekTo(seekTo, 'seconds');
        } catch { }
    }, []);

    const onBackwardHandler = useCallback(() => {
        try {
            if (videoRef.current.getCurrentTime() === null) {
                return;
            }
            const seekTo = Math.max(videoRef.current.getCurrentTime() - constants.videoPlayer.jumpTime, 0);
            videoRef.current.seekTo(seekTo, 'seconds');
        } catch { }
    }, []);

    const onSeekHandler = useCallback((time: number) => {
        try {
            seekTimeRef.current = time;
            setPlayerStatus((prevState) => ({
                ...prevState,
                currentTime: time,
            }));
        } catch { }
    }, []);

    const onSeekEndHandler = useCallback(() => {
        try {
            if (seekTimeRef.current >= 0) {
                videoRef.current.seekTo(Math.floor(Math.floor(seekTimeRef.current)), 'seconds');
                seekTimeRef.current = -1;
            }
        } catch { }
    }, []);

    const onDurationHandler = useCallback((data) => {
        setPlayerStatus((prevState) => ({
            ...prevState,
            duration: data,
        }));
    }, []);

    const onPlayerSeekHandler = useCallback((seekTime: number) => {
        setPlayerStatus((prevState) => ({
            ...prevState,
            currentTime: seekTime,
        }));
    }, []);

    const onBufferHandler = useCallback(() => {
        setPlayerStatus((prevState) => ({
            ...prevState,
            buffering: true,
        }));
    }, []);

    const onBufferEndHandler = useCallback(() => {
        setPlayerStatus((prevState) => ({
            ...prevState,
            buffering: false,
        }));
    }, []);

    const onProgressHandler = useCallback((data) => {
        try {
            const newState = { ...playerStatus };
            newState.bufferedTime = data?.loadedSeconds || 0;
            newState.currentTime = data.playedSeconds;
            setPlayerStatus(newState);
        } catch {
            setPlayerStatus((prevState) => ({
                ...prevState,
                buffering: false,
            }));
        }
    }, [playerStatus]);

    return (
        <ErrorBoundary>
            {error && error?.response?.status === 404 && <SomethingWentWrong />}
            <Loader shouldRender={playerStatus.loading} />
            <div className="watch-container">
                <div className="video-player-wrapper">
                    <VideoPlayerControls
                        playing={playerStatus.playing}
                        title={result?.data?.result?.title}
                        onVolumeClick={onVolumeClickHandler}
                        currentTime={playerStatus.currentTime}
                        bufferedTime={playerStatus.bufferedTime}
                        buffering={playerStatus.buffering}
                        duration={playerStatus.duration}
                        onForward={onForwardHandler}
                        onBackward={onBackwardHandler}
                        volume={playerStatus.volume}
                        onPlay={onPlayHandler}
                        onBack={goBack}
                        onSeek={onSeekHandler}
                        onSeekEnd={onSeekEndHandler}
                        seekThePlayer={(time) => videoRef.current.seekTo(Math.floor(time), 'seconds')}
                        isTrailer
                    >
                        <VideoPlayer
                            ref={videoRef}
                            url={playerStatus.urlToPlay}
                            playing={playerStatus.playing}
                            volume={playerStatus.volume}
                            muted={playerStatus.muted}
                            onDurationHandler={onDurationHandler}
                            onSeekHandler={onPlayerSeekHandler}
                            onBuffer={onBufferHandler}
                            onBufferEnd={onBufferEndHandler}
                            onProgress={onProgressHandler}
                            onEnded={goBack}
                        />
                    </VideoPlayerControls>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default TrailerPlayer;
