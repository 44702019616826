import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';
import ErrorBoundary from '../../Atoms/ErrorBoundary';
import styles from './index.module.scss';
import AppLoading from '../../Atoms/AppLoading/AppLoading';

const StaticLayout = ({ children, loading }) => (
  <ErrorBoundary>
    <div className='d-flex flex-column flex-grow-1 w-100'>
      <Row classNam={classNames(styles.header, 'pr-3 align-items-center')} />
      {loading ? <AppLoading classNames="p-3" /> : (
        <div className={classNames(styles.content)}>
          {children}
        </div>
      )}
    </div>
  </ErrorBoundary>
);

StaticLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StaticLayout;
