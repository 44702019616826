import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../../Utils/constants';

const Icon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={() => window.open(constants.socialMedia.links.insta)}
    width={width || 'inherit'}
    height={height || 'inherit'}
    viewBox="0 0 34 33"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M16.5 10.218c-3.464 0-6.282 2.818-6.282 6.282s2.818 6.282 6.282 6.282 6.282-2.818 6.282-6.282-2.818-6.282-6.282-6.282z"
              transform="translate(-831 -625) translate(493 625) translate(338.8)"
            />
            <path
              /* eslint-disable-next-line max-len */
              d="M24.267 0H8.733C3.918 0 0 3.918 0 8.733v15.534C0 29.082 3.918 33 8.733 33h15.534C29.082 33 33 29.082 33 24.267V8.733C33 3.918 29.082 0 24.267 0zM16.5 24.715c-4.53 0-8.215-3.685-8.215-8.215 0-4.53 3.685-8.215 8.215-8.215 4.53 0 8.216 3.685 8.216 8.215 0 4.53-3.686 8.215-8.216 8.215zm9.875-16.722c-.18.18-.43.283-.684.283-.254 0-.503-.103-.684-.283-.18-.18-.282-.43-.282-.684 0-.254.102-.503.282-.684.18-.18.43-.283.684-.283.255 0 .504.104.684.283.18.18.283.43.283.684 0 .255-.103.504-.283.684z"
              transform="translate(-831 -625) translate(493 625) translate(338.8)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

);

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Icon.defaultProps = {
  width: 33,
  height: 33,
};

export default Icon;
