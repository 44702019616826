const GET_USER_PROFILE = 'GET_USER_PROFILE';
const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';

const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_SUCCESS';

const CLEAR_ERROR = 'CLEAR_ERROR';

const EDIT_USER_IMAGE = 'EDIT_USER_IMAGE';
const EDIT_USER_IMAGE_SUCCESS = 'EDIT_USER_IMAGE_SUCCESS';
const EDIT_USER_IMAGE_FAIL = 'EDIT_USER_IMAGE_FAIL';
const CLEAR_EDIT_USER_IMAGE = 'CLEAR_EDIT_USER_IMAGE';
const REMOVE_USER_IMAGE = 'REMOVE_USER_IMAGE';
const REMOVE_USER_IMAGE_SUCCESS = 'REMOVE_USER_IMAGE_SUCCESS';

export {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  CLEAR_USER_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CLEAR_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  EDIT_USER_IMAGE,
  EDIT_USER_IMAGE_SUCCESS,
  EDIT_USER_IMAGE_FAIL,
  CLEAR_EDIT_USER_IMAGE,
  REMOVE_USER_IMAGE,
  REMOVE_USER_IMAGE_SUCCESS,
};
