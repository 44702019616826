import constants from "@Utils/constants";
import { createBrowserRouter } from "react-router-dom";
import HomePageRouter from "./HomePageRouter";
import DefaultLayout from "@Templates/DefaultLayout";
import ErrorComponent from "../HOC/ErrorComponent";
import LegacyRoutes from "./LegacyRoutes";
import ProfilesRouter from "./ProfilesRouter";
import Watch from "@Pages/Watch";
import TrailerPlayer from "@Pages/Trailer/TrailerPlayer";
import { isMobile } from "react-device-detect";
import DetailsRouter from "./DetailsRouter";
import PaymentRouter from "./PaymentRouter/PaymentRouter";
import * as Sentry from '@sentry/react';
import Live from "@Pages/Live/Live";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <DefaultLayout navbarPosition={isMobile ? { sticky: 'top' } : { fixed: 'top' }} />,
    errorElement: <ErrorComponent pageType={constants.screens.browse} />,
    hasErrorBoundary: true,
    children: [
      {
        path: constants.screens.browse,
        element: <HomePageRouter />,
        index: true,
      }
    ]
  },
  {
    path: constants.newScreens.watch().href,
    element: <Watch />,
    hasErrorBoundary: true,
  },
  {
    path: constants.newScreens.trailer().href,
    element: <TrailerPlayer />,
    hasErrorBoundary: true,
  },
  {
    path: constants.newScreens.live().href,
    element: <Live />,
    hasErrorBoundary: true,
  },
  ProfilesRouter,
  DetailsRouter,
  ...PaymentRouter,
  {
    path: "*",
    element: <LegacyRoutes />,
    errorElement: <ErrorComponent pageType={constants.screens.browse} />,
    hasErrorBoundary: true,
  },
]);

export default router;
