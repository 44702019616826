const GET_VIDEO_SETTINGS = 'GET_VIDEO_SETTINGS';
const GET_VIDEO_SETTINGS_FAIL = 'GET_VIDEO_SETTINGS_FAIL';
const GET_VIDEO_SETTINGS_SUCCESS = 'GET_VIDEO_SETTINGS_SUCCESS';
const CLEAR_VIDEO_SETTINGS = 'CLEAR_VIDEO_SETTINGS';

export {
  GET_VIDEO_SETTINGS,
  GET_VIDEO_SETTINGS_FAIL,
  GET_VIDEO_SETTINGS_SUCCESS,
  CLEAR_VIDEO_SETTINGS,
};
