import { useOutletContext } from "react-router-dom";
import { MediaType, SeriesSeason } from "@Utils/commonTypes";
import Episode from "@Atoms/Episode/Episode";
import styles from './DesktopLayout.module.scss';
import { useTranslation } from "react-i18next";
import { Movie, Series, Show } from "src/alballam-api-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { ScrollPosition, trackWindowScroll, } from "react-lazy-load-image-component";
import { ScrollContext } from "@Hooks/useScrollContext";

export interface IMediaDetailsContext {
    currentSeason?: SeriesSeason;
    media: Series | Show | Movie;
    mediaType: MediaType;
}

const breakpoints = {
    1920: {
        slidesPerView: 7.25,
    },
    1440: {
        slidesPerView: 5.25,
    },
    1336: {
        slidesPerView: 4.25,
    },
    1024: {
        slidesPerView: 3.25,
    },
    768: {
        slidesPerView: 3,
    },
    640: {
        slidesPerView: 2.25,
    }
}

const DesktopLayout: React.FC<{ scrollPosition: ScrollPosition }> = ({ scrollPosition }) => {
    const context = useOutletContext<IMediaDetailsContext>();
    const episodes = context?.currentSeason!.episodes;
    const isEpisodesSingular = episodes?.length === 1;
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <div className={styles.currentSeasonDescription}>
                <div className={styles.seasonText}>
                    <span>{t('details.alSeason')}</span>
                    <span className={styles.latinNumber}>{`${context?.currentSeason?.seasonNumber} :`}&nbsp;</span>
                </div>
                <span className={styles.latinNumber}>{context?.currentSeason?.episodes.length}</span>
                <span>{isEpisodesSingular ? t('details.episode') : t('details.episodes')}</span>
            </div>
            <div className={styles.episodesRail}>
                <ScrollContext.Provider value={scrollPosition}>
                    <Swiper spaceBetween={16} breakpoints={breakpoints}>
                        {episodes.map((episode) => (
                            <SwiperSlide key={episode.id}>
                                <Episode wrapperClassName={styles.episodeWrapper} imageClassName={styles.image} episode={episode} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </ScrollContext.Provider>
            </div>
        </div>
    )
}

const TrackedWindowScrollDesktopLayout = trackWindowScroll(DesktopLayout);
export default TrackedWindowScrollDesktopLayout;