import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../Hooks/useQuery';
import {
  validateSubscription,
  clearSubscriptionValidation,
  validateKnetSubscription,
  validatePurchaseSubscription,
  validateMyFatoorahSubscription,
} from '../../Store/Actions/SubscriptionValidationActions';
import ErrorBoundary from '../../Components/Atoms/ErrorBoundary';
import styles from '../ChoosePayment/ChoosePayment.module.scss';
import MessageBox from '../../Components/Atoms/MessageBox';
import constants from '../../Utils/constants';
import { clearVideoSubscriptionData } from '../../Store/Actions/VideoSubscriptionActions';
import PaymentContainer from '../../Components/Molecules/PaymentContainer/PaymentContainer';
import ClickableItem from '../../Components/Atoms/ClickableItem/ClickableItem';
import Loader from '../../Components/Atoms/Loader/Loader';

const PaymentRedirection = () => {
  const { error, loading, result } = useSelector((state) => state.subscriptionValidation);
  const KNETId = useQuery()?.get('tap_id');
  const myFatoorahId = useQuery()?.get('paymentId');
  const tapId = useQuery()?.get('tapId');
  const origin = useQuery()?.get('origin');
  const titleSlug = useQuery()?.get('title');
  const isQRCode = useQuery()?.get('qrId');
  const isPurchase = useQuery()?.get('isPurchase');
  const dispatch = useDispatch();


  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatchValidation = useCallback(() => {
    setTimeout(() => {
      if (isPurchase !== 'purchase') {
        if (tapId) {
          if (isQRCode) {
            return dispatch(
              validateSubscription(tapId?.trim(), isQRCode?.trim()),
            );
          }
          return dispatch(validateSubscription(tapId?.trim()));
        }
        if (KNETId) {
          if (isQRCode) {
            return dispatch(
              validateKnetSubscription(KNETId?.trim(), isQRCode?.trim()),
            );
          }
          return dispatch(validateKnetSubscription(KNETId?.trim()));
        }
        if (myFatoorahId) {
          return dispatch(
            validateMyFatoorahSubscription(myFatoorahId?.trim(), isQRCode ? isQRCode?.trim() : null),
          );
        }
      } else {
        if (isQRCode) {
          return dispatch(
            validatePurchaseSubscription(KNETId?.trim(), isQRCode?.trim()),
          );
        }
        return dispatch(validatePurchaseSubscription(KNETId?.trim()));
      }
      return null;
    }, 5000);
  }, [dispatch, KNETId, isQRCode, tapId, isPurchase, myFatoorahId]);

  useEffect(() => {
    if (isPurchase !== 'purchase') {
      if (tapId) {
        if (isQRCode) {
          return dispatch(
            validateSubscription(tapId?.trim(), isQRCode?.trim()),
          );
        }
        return dispatch(validateSubscription(tapId?.trim()));
      }
      if (KNETId) {
        if (isQRCode) {
          return dispatch(
            validateKnetSubscription(KNETId?.trim(), isQRCode?.trim()),
          );
        }
        return dispatch(validateKnetSubscription(KNETId?.trim()));
      }
      if (myFatoorahId) {
        return dispatch(
          validateMyFatoorahSubscription(myFatoorahId?.trim(), isQRCode ? isQRCode?.trim() : null),
        );
      }
    } else {
      if (isQRCode) {
        return dispatch(
          validatePurchaseSubscription(KNETId?.trim(), isQRCode?.trim()),
        );
      }
      return dispatch(validatePurchaseSubscription(KNETId?.trim()));
    }
    navigate(constants.screens.browse);
  }, [dispatch, tapId, isQRCode, KNETId, isPurchase, myFatoorahId]);

  useEffect(
    () => () => {
      if ((result !== 'processing' && result) || error) {
        dispatch(clearSubscriptionValidation());
      }
    },
    [dispatch, result, error],
  );

  useEffect(() => {
    if (result === 'processing') {
      const intervalCall = setInterval(() => {
        dispatchValidation().then(() => {
          if (isQRCode) {
            navigate('/');
          }
        });
      }, 10000);

      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
    return null;
  }, [dispatchValidation, result]);

  useEffect(
    () => () => {
      if ((result !== 'processing' && result) || error) {
        dispatch(clearVideoSubscriptionData());
      }
    },
    [dispatch, error, result],
  );

  const onClick = async () => {
    if (result === 'failed') {
      navigate(constants.screens.browse);
    }
    if (location?.state?.redirect_url) {
      navigate(location.state.redirect_url, { replace: true })
    } else if (origin) {
      if (origin.includes('myAccount')) {
        navigate(constants.screens.profile);
      } else if (origin.includes('watch')) {
        origin.split('/');
        navigate(constants.newScreens.watch(origin.trim().split('watch/')[1], window.decodeURI(titleSlug)).as);
      } else if (origin[0] === '?') {
        if (origin.includes('?redirect_url=')) {
          const or = origin.split('?redirect_url=')[1];
          if (or.includes('live')) {
            navigate(or, { state: { isLiveFlow: true }, replace: true });
            return;
          }
          navigate(or, { replace: true })
        } else {
          navigate(`/${origin}`, { replace: true })
        }
      } else {
        navigate(origin)
      }
    } else {
      navigate(constants.screens.browse);
    }
  };

  useEffect(() => {
    if (
      location.pathname === constants.screens.paymentRedirection
      && ((result !== 'processing' && result) || error)
    ) {
      window.addEventListener(
        'popstate',
        (event) => {
          if (event.state) {
            if (origin.includes('watch')) {
              navigate(constants.screens.browse)
            } else {
              onClick();
            }
          }
        },
        false,
      );

      return () => {
        window.removeEventListener('popstate', onClick);
      };
    }
    return null;
  }, [onClick, result, error]);

  const getMessages = useCallback(() => {
    if (result === 'failed' || error) {
      return t('payment.failed');
    }
    if (result === 'processing') {
      return t('globals.processing');
    }

    return t('globals.paymentSuccess');
  }, [t, result, error]);

  return (
    <ErrorBoundary>
      <PaymentContainer
        isPaymentRedirection
        title={
          loading || result === 'processing' || result === 'Processing'
            ? t('globals.processing')
            : result === 'failed' || result === 'Failed' || error
              ? t('globals.paymentFailed')
              : t('globals.paymentSuccess')
        }
        onClick={onClick}
        contentClassName={styles.paymentRedirectionContent}
      >
        <div className="d-flex justify-content-center">
          {loading || result === 'processing' || result === 'Processing' ? (
            <div className={styles.pleaseWait}>
              <div className={styles.pleaseWaitSpinner}>
                <Loader size={25} color="white" />
              </div>
              <span>{t('globals.pleaseWait')}</span>
              <span>{t('globals.creditCardProcess')}</span>
            </div>
          ) : (
            <MessageBox
              message={getMessages() || (error ? t('payment.failed') : '')}
              isError={result === 'failed' || result === 'Failed' || error}
            />
          )}
        </div>
        {loading || result === 'processing' || result === 'Processing' ? null : (
          <ClickableItem
            onClick={onClick}
            classes={`${styles.link} label bold-text clickable text-center w-100 d-inline-block mt-3 mb-3`}
          >
            {t('globals.goBack')}
          </ClickableItem>
        )}
      </PaymentContainer>
    </ErrorBoundary>
  );
};

export default PaymentRedirection;
