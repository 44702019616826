import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Atoms/Loader/Loader";
import constants from "../../Utils/constants";
import styles from "./EditProfile.module.scss";
import { deleteProfilePerUser, getUserPerProfile } from "../../Store/Actions/UserProfilesActions";
import arrowRight from "../../Assets/Images/back/icons-arrows-arrow-right.svg";
import EditProfileName from "../../Components/Organisms/EditProfileNameForm/EditProfileName";
import deleteIcon from "../../Assets/Images/icons-delete.svg";
import NotFound from "../NotFound/NotFound";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const [show, setIsShow] = useState(false);
  const { result, error, loading } = useSelector((state) => state.ProfilesPerUser);
  const { language } = useSelector((state) => state.authentication);
  const { id } = useParams();
  const { profile } = useSelector((state) => state.authentication);
  const { t } = useTranslation();

  const myProfile = result?.data?.find((e) => e?.id === parseInt(id));
  const profileNameData = localStorage.getItem("profile_name_data");

  useEffect(() => {
    dispatch(getUserPerProfile());
  }, [dispatch]);

  useEffect(() => {
    if (formikRef.current && profileNameData) {
      const data = JSON.parse(profileNameData);

      if (data?.id === id) {
        formikRef.current.setValues({ name: data?.name });

        localStorage.removeItem("profile_name_data");
      }
    }
  }, [id, profileNameData]);

  const onEditPic = useCallback(() => {
    if (formikRef.current) {
      localStorage.setItem(
        "profile_name_data",
        JSON.stringify({
          id,
          name: formikRef.current.values.name,
        })
      );
    }

    navigate(constants.newScreens.profiles.edit(id).picture.as, { replace: true, unstable_viewTransition: true });
  }, [navigate, id]);

  if (!loading && !myProfile && result) {
    return <NotFound />;
  }

  return (
    <div className={styles.nonAuthenticatedPage}>
      <div className={styles.editRectangle}>
        <div className={styles.titleContainer} style={language === "ar" ? { left: "10px" } : { right: "10px" }}>
          <div onClick={() => navigate(-1)} className={language === "ar" ? styles.arrowRight : styles.arrowEng}>
            <img src={arrowRight} alt="" className="d-md-block" />
          </div>
          <div className={styles.title}>{t("profiles.editProfileUser")}</div>
        </div>
        {!loading && myProfile ? (
          <Fragment>
            <div className="d-flex justify-content-center">
              <div id="imageid" className={styles.rectangle}
                onClick={onEditPic}
                style={{
                  border: myProfile?.avatar ? "none" : "solid 2px #ffffff",
                }}
              >
                <img alt="current-image" src={myProfile?.avatar}
                    className={styles.imageAvatar}
                  />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className={styles.editPic} onClick={onEditPic}>
                {t("profiles.editImage")}
              </Button>
            </div>
            <EditProfileName errorAvatar={error} ref={formikRef} />
            {!myProfile?.main && profile !== myProfile.id && (
              <Button variant="none" className={styles.deleteContainer} onClick={() => setIsShow(true)}>
                <img src={deleteIcon} className={styles.deleteIcon} alt="trash-bin-icon" />
                <span className={`${styles.deleteLabel} ${language === "ar" ? "pr-2" : "pl-2"} label`} style={{ color: "red" }}>
                  {t("profiles.deleteProfile")}
                </span>
              </Button>
            )}

            <Modal
              show={show}
              backdrop
              onHide={() => setIsShow(false)}
              dialogClassName={styles.modalContainer}
              centered
            >
              <Modal.Body>
                <div className={styles.deleteModal}>
                  <div className="d-flex justify-content-center">
                    <span className={styles.deleteText}>
                      {t("profiles.areYouSureToDelete")}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center">
                    <span className={styles.deleteAlert}>
                      {t("profiles.noTurnBack")}
                    </span>
                  </div>
                  <div className={styles.buttonsGroup}>
                    <Button
                      variant="danger"
                      className={styles.confirmButton}
                      size="lg"
                      onClick={() => {
                        dispatch(deleteProfilePerUser(id)).then(() => {
                          navigate(constants.newScreens.profiles.view, { replace: true });
                        });
                      }}
                    >
                      <span>{t("profiles.deleteThisProfile")}</span>
                    </Button>
                    <Button
                      variant="outline-light"
                      size="lg"
                      className={styles.cancelButton}
                      onClick={() => setIsShow(false)}
                    >
                      <span className={styles.BlueBtnText}>
                        {t("globals.getBack")}
                      </span>
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </Fragment>
        ) : (
          <div className="loader mt-5 d-flex justify-content-center align-items-center w-100">
            <Loader size={34} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
