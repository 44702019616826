import { IBasicPlayer } from "@Pages/MovieDetails/Hero/Hero.types";
import { useEffect, useRef } from "react";
import { Dispatch, SetStateAction } from "react";
import { useDebouncedCallback } from "use-debounce";

const useDisablePlayerWhenOffViewPort = (
  playerStatus: IBasicPlayer,
  updatePlayer: Dispatch<SetStateAction<IBasicPlayer>>
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollFunc = useDebouncedCallback(() => {
    if (!containerRef?.current) {
      return;
    }
    const current = containerRef.current;
    if (
      !document.hidden &&
      current?.getBoundingClientRect()?.top < window.innerHeight &&
      current?.getBoundingClientRect()?.bottom >= 0
    ) {
      updatePlayer((prevState) => ({ ...prevState, isPlaying: true }));
    } else {
      updatePlayer((prevState) => ({ ...prevState, isPlaying: false }));
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [scrollFunc, playerStatus]);

  return containerRef;
};

export default useDisablePlayerWhenOffViewPort;
