import React from "react";
import logo from "../../Assets/Images/nouveau/ballam-logo.svg";
import styles from "./StandardHeroComponent.module.scss";
import classNames from "classnames";

function StandardHeroComponent({ className, children }) {
  return (
    <div className={classNames(styles.container, className)}>
      <img src={logo} alt="ALBALLAM Logo" className={styles.logo} />
      {children}
    </div>
  );
}

export default StandardHeroComponent;
