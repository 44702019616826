import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './watchedVideosHorizontalList.module.scss';
import MovieList from '../../Organisms/MoviesList/MoviesList';

const WatchedVideosHorizontalList = ({ data }) => {
  const { t } = useTranslation();

  return (
    data?.length > 0 && (
      <div className={styles.watchedVideosList}>
        <MovieList
          movies={data}
          sectionTitle={t('globals.continueWatching')}
          isContinueWatching
          listType="movie"

        />
      </div>
    )
  );
};

WatchedVideosHorizontalList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
};

export default WatchedVideosHorizontalList;
