import { useAppSelector } from "@Store/hooks";
import { handleGuestLogin } from "@Utils/common";
import { useEffect } from "react";
import { useCountdown } from "usehooks-ts";

function useKeepUserAuthenticated() {
  const { userAuthType } = useAppSelector((state) => state.authentication);
  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({ countStart: 5, intervalMs: 1000 });

  useEffect(() => {
    if (count === 0) {
      if (userAuthType === null) {
        handleGuestLogin();
      }
      stopCountdown();
      resetCountdown();
    }
  }, [count, resetCountdown, stopCountdown, userAuthType]);

  useEffect(() => {
    if (userAuthType === null) startCountdown();
  }, [startCountdown, userAuthType]);
}

export default useKeepUserAuthenticated;
