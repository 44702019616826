import React from 'react';
import Loader from '../../Components/Atoms/Loader/Loader.tsx';
import PageWrapper from '../PageWrapper/PageWrapper.tsx';

function Loading() {
  return (
    <PageWrapper loading>
      <div className="loader d-flex justify-content-center align-items-center w-100 AppLoader">
        <Loader size={44} />
      </div>
    </PageWrapper>
  );
}

export default Loading;
