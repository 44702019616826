const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
const SAVE_AUTHENTICATION = 'SAVE_AUTHENTICATION';

const LOGOUT_USER = 'LOGOUT_USER';
const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';

const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
const SAVE_LANGUAGE = 'SAVE_LANGUAGE';

// const SEND_DELETE_OTP = 'SEND_DELETE_OTP';
// const SEND_DELETE_OTP_SUCCESS = 'SEND_DELETE_OTP_SUCCESS';
// const SEND_DELETE_OTP_FAIL = 'SEND_DELETE_OTP_FAIL';

export {
  CLEAR_AUTHENTICATION,
  SAVE_AUTHENTICATION,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  SWITCH_LANGUAGE,
  SAVE_LANGUAGE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  // SEND_DELETE_OTP,
  // SEND_DELETE_OTP_SUCCESS,
  // SEND_DELETE_OTP_FAIL
};
