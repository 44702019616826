import Profiles from '@Pages/Profiles/Profiles';
import constants from '@Utils/constants';
import AddProfile from '@Pages/AddProfile/AddProfile';
import EditPic from '@Pages/EditPic/EditPic';
import EditProfile from '@Pages/EditProfile/EditProfile';
import ErrorComponent from 'src/HOC/ErrorComponent';
import ProtectedRoute from 'src/HOC/ProtectedRoute/ProtectedRoute';
import { RouteObject } from 'react-router-dom';

const ProfilesRouter: RouteObject = {
    path: constants.newScreens.profiles.base,
    hasErrorBoundary: true,
    errorElement: <ErrorComponent pageType={constants.screens.profiles} />,
    children: [
        {
            path: constants.newScreens.profiles.view,
            element: <ProtectedRoute />,
            children: [
                {
                    index: true,
                    element: <Profiles />,
                }
            ]
        },
        {
            path: constants.newScreens.profiles.add,
            element: <AddProfile />,
        },
        {
            path: constants.newScreens.profiles.edit().href,
            element: <EditProfile />,
        },
        {
            path: constants.newScreens.profiles.edit().picture.href,
            element: <EditPic />,
        }
    ],
};


export default ProfilesRouter