const CLEAR_NOTIFIACTIONS = 'CLEAR_NOTIFIACTIONS';
const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export {
  CLEAR_NOTIFIACTIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
};
