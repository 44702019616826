import React from 'react';
// import Facebook from '@Assets/Images/Icons/SocialMedia/facebook';
import Insta from '@Assets/Images/Icons/SocialMedia/insta';
import Twitter from '@Assets/Images/Icons/SocialMedia/twitter';
import Tiktok from '@Assets/Images/Icons/SocialMedia/tiktok';
// import Snapchat from '@Assets/Images/Icons/SocialMedia/snapchat';
import Youtube from '@Assets/Images/Icons/SocialMedia/youtube';
// import Threads from '@Assets/Images/Icons/SocialMedia/threads';
import styles from './SocialBox.module.scss';

const SocialBox = () => (
  <div className={styles.root}>
    <Youtube width={13} height={13} />
    <Tiktok width={13} height={13} />
    <Insta width={13} height={13} />
    <Twitter width={13} height={13} />
  </div>
);

export default SocialBox;
