import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import constants from '../../Utils/constants';
import ErrorComponent from '../../HOC/ErrorComponent';
import { isShowOrSeries } from '../../Utils/common';
import { getBookmarks } from '../../Store/Actions/BookmarkActions';
import Loader from '../../Components/Atoms/Loader/Loader';
import PageWrapper from '../PageWrapper/PageWrapper.tsx';
import NoData from '../../Components/Molecules/NoData/NoData';
import MovieCard from '../../Components/Molecules/MovieCard/MovieCard';
import './Bookmarks.scss';

export default function Bookmarks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, result, error } = useSelector((state) => state.bookmarks);
  const { isSignedIn } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    dispatch(getBookmarks());
  }, [dispatch, isSignedIn]);

  if (error) return <ErrorComponent error={error} pageType={constants.screens.bookmarks} />;
  if (result?.data?.length === 0) return <NoData addMarginTop source="bookmarks" />;
  return (
    <PageWrapper showFooter loading={loading}>
      <div className="mt-5 pt-5 mx-5">
        <div className="titleBookmarks">{t('landing.preferences')}</div>

        {loading && (
          <div className="loader mt-5 d-flex justify-content-center align-items-center w-100">
            <Loader size={34} />
          </div>
        )}

        <Row>
          {result?.data?.map(
            (bookmarkedItem) => {
              const isFree = isShowOrSeries(bookmarkedItem?.type || bookmarkedItem?.video?.type)
                ? bookmarkedItem?.containsFreeEpisodes
                : bookmarkedItem?.isContinueWatching
                  ? bookmarkedItem.video?.paid === false
                  : bookmarkedItem.paid === false;
              const shouldRender = bookmarkedItem?.id && bookmarkedItem?.title && bookmarkedItem?.medium;

              if (!shouldRender) return <Fragment />;
              return (
                <Col
                  className="my-3"
                  key={bookmarkedItem.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <MovieCard {...bookmarkedItem}
                    showBookmark
                    isFree={isFree}
                    titleUrl={window.decodeURI(bookmarkedItem?.slug)}
                  />
                </Col>
              )
            })}
        </Row>
      </div>
    </PageWrapper>
  );
}
