import { PaymentModalState } from "@Store/Reducers/PaymentModalReducer";
import { CLEAR_PAYMENT_MODAL_DATA, SET_PAYMENT_MODAL_DATA, TOGGLE_PAYMENT_MODAL } from "@Store/Constants/PaymentModalConstants";

export const setPaymentModalData = ({ purchaseType, purchaseId }: PaymentModalState) => ({
    type: SET_PAYMENT_MODAL_DATA,
    payload: { purchaseType, purchaseId },
});

export const togglePaymentModal = (override: boolean) => ({
    type: TOGGLE_PAYMENT_MODAL,
    payload: { override },
});

export const clearPaymentModalData = () => ({
    type: CLEAR_PAYMENT_MODAL_DATA,
});