import {
  GET_SEARCHDATA,
  CLEAR_SEARCHDATA,
  ADD_SEARCH_KEYWORD,
} from '../Constants/GlobalSearchConstants';

export const addSearchKeyword = (keyword) => ({
  type: ADD_SEARCH_KEYWORD,
  payload: { keyword },
});

export const getSearchData = (keyword, page) => {
  const params = {
    page,
    limit: 24,
  };
  return {
    type: GET_SEARCHDATA,
    payload: {
      request: {
        url: `api/video/webSearch?search=${keyword}`,
        method: 'get',
        authenticated: true,
        params,
      },
      page,
    },
  };
};

export const clearSearchData = () => ({
  type: CLEAR_SEARCHDATA,
  payload: null,
});
