import React, { forwardRef, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WatchNow from "../../../Assets/Images/playBlack.svg";
import comingSoon from "../../../Assets/Images/Icons/coming-soon.svg";
import InfoIcon from "../../../Assets/Images/icons-video-info.svg";
import styles from "./MovieCardInfo.module.scss";

const MovieCardInfo = forwardRef(
  (
    {
      title,
      onInfoClick,
      onWatchNowClick,
      rating,
      onAnimationEnd,
      date,
      numberOfSeasons,
      genres,
      isSeason,
      quality,
      isSecondSlider,
      generalText,
      isComingSoon,
      showName,
      seasonName,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const getSeasonsInfo = useCallback(
      () => (
        <div className={isSecondSlider ? "d-flex" : "d-block"}>
          {numberOfSeasons && (
            <span
              dir="ltr"
              className={classNames(styles.numberOfSeasons, {
                [styles.numberOfSeasonsSecondSlider]: isSecondSlider,
                [styles.numberOfSeasonsSecondSliderAddPadding]:
                  isSecondSlider && date,
              })}
            >
              {numberOfSeasons}
            </span>
          )}
          {numberOfSeasons && (
            <span
              className={classNames(styles.seasons, {
                [styles.seasonsSecondSlider]: isSecondSlider,
              })}
            >
              {numberOfSeasons === 1
                ? `${t("showDetails.season")}`
                : `${t("showDetails.seasons")}`}
            </span>
          )}
        </div>
      ),
      [numberOfSeasons, isSecondSlider, date, t]
    );

    return (
      <div
        className={classNames(styles.showInfo, {
          [styles.showInfoSecondSlider]: isSecondSlider,
        })}
        ref={ref}
        onAnimationEnd={onAnimationEnd}
      >
        <div
          className={classNames(styles.buttonsContainer, {
            [styles.buttonsContainerSecondSlider]: isSecondSlider,
          })}
        >
          <div className="d-flex align-items-center">
            <Button
              className={classNames(styles.movieButtonWatchNow, {
                [styles.movieButtonWatchNowSecondSlider]: isSecondSlider,
              })}
              onClick={onWatchNowClick}
            >
              <LazyLoadImage
                src={!isComingSoon ? WatchNow : comingSoon}
                alt="Watch Now"
                width={isSecondSlider ? (isComingSoon ? 25 : 34) : 24}
                height={isSecondSlider ? (isComingSoon ? 25 : 34) : 24}
              />
              {isSecondSlider && (
                <span className={styles.play}>
                  {!isComingSoon
                    ? t("globals.watchNow")
                    : t("globals.comingSoon")}
                </span>
              )}
            </Button>
            {!isSeason && (
              <div className={styles.buttonsContainerRight}>
                <Button
                  className={classNames(
                    styles.buttonNoBorder,
                    styles.movieButtonInfo,
                    { [styles.movieButtonInfoSecondSlider]: isSecondSlider }
                  )}
                  onClick={onInfoClick}
                >
                  <LazyLoadImage src={InfoIcon} alt="Info" />
                </Button>
              </div>
            )}
          </div>
        </div>
        {isSecondSlider && generalText && (
          <div className={styles.generalText}>{generalText}</div>
        )}
        <div className={styles.movieTitleContainer}>
          <div
            dir="auto"
            className={classNames(styles.movieTitle, {
              [styles.movieTitleSecondSlider]: isSecondSlider,
            })}
          >
            {isSecondSlider && showName && <span>{showName} - </span>}
            {isSecondSlider && seasonName && <span>{seasonName} - </span>}
            {title}
          </div>
        </div>
        <div className="d-flex align-items-center">
          {quality && !isSecondSlider && (
            <div className="d-flex justify-content-center">
              <span
                className={classNames(styles.quality, {
                  [styles.qualityBorder]: date,
                })}
              >
                {quality}
              </span>
            </div>
          )}
          <div className="d-flex gap-2 align-items-center">
            {date && (
              <div className={classNames(styles.dateWrapper)}>
                <span
                  dir="ltr"
                  className={classNames(styles.modalDate, {
                    [styles.modalDateSecondSlider]: isSecondSlider,
                    [styles.modalDateSecondSliderAddBorder]:
                      isSecondSlider && numberOfSeasons,
                  })}
                >
                  {parseInt(date, 10)}
                </span>
              </div>
            )}
            {numberOfSeasons && !isSecondSlider && (
              <div className="d-flex ml-0 justify-content-center">
                <span dir="ltr" className={styles.movieRating}>
                  {numberOfSeasons}
                  {` `}
                  {numberOfSeasons === 1
                    ? `${t("showDetails.season")}`
                    : `${t("showDetails.seasons")}`}
                </span>
              </div>
            )}
          </div>
          {isSecondSlider && getSeasonsInfo()}
        </div>
        {!isSecondSlider && (
          <div className={styles.genresWrapper}>
            {genres &&
              genres?.map((e, idx) => {
                if (idx < 3) {
                  return (
                    <span key={`${e.id}-${e.name}`} className={styles.genres}>
                      {idx !== 0 && genres.length > 0 && idx <= 2 && (
                        <span className={styles.circle} />
                      )}
                      {e.name}{" "}
                    </span>
                  );
                }
                return null;
              })}
          </div>
        )}
      </div>
    );
  }
);

MovieCardInfo.propTypes = {
  // id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  rating: PropTypes.string,
  onInfoClick: PropTypes.func,
  onWatchNowClick: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  date: PropTypes.string,
  numberOfSeasons: PropTypes.number,
  isSeason: PropTypes.bool,
};

MovieCardInfo.defaultProps = {
  title: undefined,
  rating: "",
  date: "",
  numberOfSeasons: null,
  onInfoClick: () => null,
  onWatchNowClick: () => null,
  onAnimationEnd: () => null,
  isSeason: null,
};

export default MovieCardInfo;
