import {
  VALIDATE_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_FAIL,
  VALIDATE_SUBSCRIPTION_SUCCESS,
  CLEAR_VALIDATE_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_FAIL,
  CHECK_SUBSCRIPTION_SUCCESS,
  CLEAR_CHECK_SUBSCRIPTION,
  VALIDATE_SUBSCRIPTION_KNET,
  VALIDATE_SUBSCRIPTION_KNET_SUCCESS,
  VALIDATE_SUBSCRIPTION_KNET_FAIL,
  VALIDATE_SUBSCRIPTION_MYFATOORAH,
  VALIDATE_SUBSCRIPTION_MYFATOORAH_SUCCESS,
  VALIDATE_SUBSCRIPTION_MYFATOORAH_FAIL,
} from '../Constants/SubscriptionValidationConstants';

const initialState = {
  error: null,
  loading: false,
  result: null,
  checkSubscription: {
    error: null,
    loading: false,
    result: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_SUBSCRIPTION: {
      return {
        ...initialState,
        loading: true,
        error: null,
      };
    }
    case VALIDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data?.result?.status,
      };
    }
    case VALIDATE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_VALIDATE_SUBSCRIPTION: {
      return {
        ...initialState,
        checkSubscription: {
          ...state.checkSubscription,
        },
      };
    }

    case VALIDATE_SUBSCRIPTION_KNET: {
      return {
        ...initialState,
        loading: true,
        error: null,
      };
    }
    case VALIDATE_SUBSCRIPTION_KNET_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data?.message,
      };
    }
    case VALIDATE_SUBSCRIPTION_KNET_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case VALIDATE_SUBSCRIPTION_MYFATOORAH: {
      return {
        ...initialState,
        loading: true,
        error: null,
      };
    }
    case VALIDATE_SUBSCRIPTION_MYFATOORAH_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data?.message,
      };
    }
    case VALIDATE_SUBSCRIPTION_MYFATOORAH_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case CHECK_SUBSCRIPTION: {
      return {
        ...initialState,
        checkSubscription: {
          loading: true,
          error: null,
        },
      };
    }
    case CHECK_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        checkSubscription: {
          loading: false,
          result: action.payload.data.data.result,
        },
      };
    }
    case CHECK_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        checkSubscription: {
          loading: false,
          error: action.error,
        },
      };
    }
    case CLEAR_CHECK_SUBSCRIPTION: {
      return {
        ...state,
        checkSubscription: {
          ...initialState.checkSubscription,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
