import React, { Fragment, ReactNode, SyntheticEvent } from 'react'
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import styles from './LandscapeImage.module.scss';
import Placeholder from '@Assets/Images/placeholders/16x9.svg';
import classNames from 'classnames';
import ImageTag, { ImageTagProps } from '@Atoms/ImageTag/ImageTag';
import { ProgressBar } from 'react-bootstrap';
import { useScrollContext } from "@Hooks/useScrollContext";

export interface ILandscapeImageProps {
    rootWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
    tagProps?: ImageTagProps;
    imageProps?: LazyLoadImageProps;
    progressBarPercentage?: number;
    children?: ReactNode;
}

const LandscapeImage: React.FC<ILandscapeImageProps> = (props) => {
    // if context exists, uses this to improve performance
    const scrollPosition = useScrollContext();
    function onError(e: SyntheticEvent<HTMLImageElement, Event>) {
        e.currentTarget.src = props.imageProps?.placeholderSrc ?? Placeholder;
        e.currentTarget.style.objectFit = 'cover';
    }

    return (
        <Fragment>
            <div {...props.rootWrapperProps} className={classNames(styles.root, props.rootWrapperProps?.className)}>
                <div className={styles.overlay}>
                    <ImageTag {...props.tagProps} />
                    {props.progressBarPercentage && (
                        <div className={styles.bottomInfoGroup}>
                            <ProgressBar variant='secondary' className={styles.progressBar}>
                                <ProgressBar
                                    now={props.progressBarPercentage}
                                    className={styles.progressBarInternal}
                                    variant='light' />
                            </ProgressBar>
                        </div>
                    )}
                </div>
                <LazyLoadImage
                    className={classNames(styles.image, props.imageProps?.className)}
                    scrollPosition={scrollPosition}
                    onError={onError}
                    placeholderSrc={Placeholder}
                    {...props.imageProps}
                />
            </div>
            {/* {props.children} */}
        </Fragment>
    )
}

export default LandscapeImage