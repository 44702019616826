import React, { useCallback, useRef } from 'react'
import VideoPlayer from '@Atoms/VideoPlayer';
import { IPlayerProps } from './BackgroundPlayer.types';

const BackgroundPlayer: React.FC<IPlayerProps> = (props) => {
    const { url, updatePlayerStatus, ...playerStatus } = props;
    const { hasLoaded, isPlaying, isMuted } = playerStatus;
    const videoPlayerRef = useRef<React.ForwardRefExoticComponent<React.RefAttributes<any>> | null>(null);

    function onBuffer() {
        updatePlayerStatus((prevState) => ({
            ...prevState,
            hasLoaded: false,
        }));
    }

    const onReady = useCallback(() => {
        if (props.playerOverrides?.onReady) {
            return props.playerOverrides.onReady();
        }

        updatePlayerStatus((prevState) => ({ ...prevState, hasLoaded: true, isPlaying: true }));
    }, [props.playerOverrides, updatePlayerStatus]);

    function onEnded() {
        if (props.playerOverrides?.onEnded) {
            return props.playerOverrides.onEnded();
        }

        updatePlayerStatus((prevState) => ({
            ...prevState,
            IsVisible: false,
            hasLoaded: false,
            hasEnded: true,
        }));
    }

    return (
        <div className={`${!hasLoaded ? 'd-none' : 'h-100'}`}>
            <VideoPlayer
                ref={videoPlayerRef}
                //@ts-expect-error must be a type issue
                onEnded={onEnded}
                playing={isPlaying}
                muted={isMuted}
                volume={1}
                onBuffer={onBuffer}
                onBufferEnd={onReady}
                onReady={onReady}
                url={url}
            />
        </div>
    )
}

export default BackgroundPlayer