import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import constants from "../../Utils/constants";
import styles from "./Search.module.scss";
import {
  getSearchData,
  clearSearchData,
} from "../../Store/Actions/GlobalSearchActions";
import {
  clearTopSearches,
  getTopSearches,
} from "../../Store/Actions/TopSearchesActions";
import PageWrapper from "../PageWrapper/PageWrapper.tsx";
import ErrorComponent from "../../HOC/ErrorComponent";
// import PopularSearches from "../../Components/Molecules/PopularSearches/PopularSearches";
import ResultCard from "./ResultCard/ResultCard";
import Loading from "./Loading";
import HasMore from "./HasMore";
import NoData from "../../Components/Molecules/NoData/NoData";
import "./Search.scss";

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchSelector = useParams();
  const {
    result: topSearchesResult,
    loading: topSearchesLoading,
    error: topSearchesError,
  } = useSelector((state) => state.topSearches);
  const { loading, items, array, error } = useSelector(
    (state) => state.searchData
  );

  useEffect(() => {
    dispatch(getSearchData(searchSelector.search, 1));
  }, [dispatch, searchSelector.search]);

  useEffect(() => {
    dispatch(clearSearchData());
  }, [dispatch, searchSelector.search]);

  useEffect(() => {
    dispatch(getTopSearches());

    return () => dispatch(clearTopSearches());
  }, [dispatch]);

  // const canRenderPopularSearches =
  //   !topSearchesLoading && !topSearchesError && topSearchesResult?.length >= 1;

  if (error)
    return <ErrorComponent error={error} pageType={constants.screens.search} />;
  if (loading) return <Loading />;
  return (
    <PageWrapper loading={loading && items?.length === 0}>
      <div className={styles.root}>
        {/* {canRenderPopularSearches && <PopularSearches result={topSearchesResult} />} */}
        <div className={styles.searchHeader}>
          <div className={styles.resultsContent}>
            <div className={classNames(styles.searchTitle, "mb-3")}>
              {t("search.searchResult")}
            </div>
            {items?.length === 0 ? (
              <div className={styles.noResultsContainer}>
                <NoData
                  searchWord={`"${searchSelector.search}"`}
                  hideBackToHomePage
                />
              </div>
            ) : (
              <Fragment>
                <p className={styles.keywordText}>
                  {`${t("search.keywordText")} "${searchSelector?.search}"`}
                </p>
                <div className={styles.resultsContainer}>
                  {items &&
                    array
                      ?.flat()
                      ?.map((searchItem, index) => (
                        <ResultCard
                          searchItem={searchItem}
                          index={index}
                          key={`${searchItem.id}-result-card`}
                        />
                      ))}
                </div>
              </Fragment>
            )}
          </div>
          <HasMore />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Search;
