import {
  GET_SEARCH_HISTORY,
  GET_SEARCH_HISTORY_SUCCESS,
  GET_SEARCH_HISTORY_FAIL,
  ADD_SEARCH_HISTORY,
  ADD_SEARCH_HISTORY_SUCCESS,
  ADD_SEARCH_HISTORY_FAIL,
  DELETE_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY_SUCCESS,
  DELETE_SEARCH_HISTORY_FAIL,
} from '../Constants/SearchHistoryConstants';

const initialState = {
  loading: null,
  result: null,
  error: null,
};

const searchHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_HISTORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SEARCH_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case GET_SEARCH_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case ADD_SEARCH_HISTORY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_SEARCH_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload.data.data.result,
      };
    }
    case ADD_SEARCH_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case DELETE_SEARCH_HISTORY: {
      return {
        ...state,
        loading: true,
        result: state.result.filter((e) => e.id !== action.payload.id),
      };
    }
    case DELETE_SEARCH_HISTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        //   result: action.payload.data.data.result,
      };
    }
    case DELETE_SEARCH_HISTORY_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    //   case CLEAR_SEASONS_PER_SHOW: {
    //     return initialState;
    //   }
    default:
      return state;
  }
};

export default searchHistoryReducer;
