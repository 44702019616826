const countryData = [
  { value: 'Afghanistan', label: '+93', code: 'AF' },
  { value: 'Aland Islands', label: '+358', code: 'AX' },
  { value: 'Albania', label: '+355', code: 'AL' },
  { value: 'Algeria', label: '+213', code: 'DZ' },
  { value: 'AmericanSamoa', label: '+1684', code: 'AS' },
  { value: 'Andorra', label: '+376', code: 'AD' },
  { value: 'Angola', label: '+244', code: 'AO' },
  { value: 'Anguilla', label: '+1264', code: 'AI' },
  { value: 'Antarctica', label: '+672', code: 'AQ' },
  { value: 'Antigua and Barbuda', label: '+1268', code: 'AG' },
  { value: 'Argentina', label: '+54', code: 'AR' },
  { value: 'Armenia', label: '+374', code: 'AM' },
  { value: 'Aruba', label: '+297', code: 'AW' },
  { value: 'Australia', label: '+61', code: 'AU' },
  { value: 'Austria', label: '+43', code: 'AT' },
  { value: 'Azerbaijan', label: '+994', code: 'AZ' },
  { value: 'Bahamas', label: '+1242', code: 'BS' },
  { value: 'Bahrain', label: '+973', code: 'BH' },
  { value: 'Bangladesh', label: '+880', code: 'BD' },
  { value: 'Barbados', label: '+1246', code: 'BB' },
  { value: 'Belarus', label: '+375', code: 'BY' },
  { value: 'Belgium', label: '+32', code: 'BE' },
  { value: 'Belize', label: '+501', code: 'BZ' },
  { value: 'Benin', label: '+229', code: 'BJ' },
  { value: 'Bermuda', label: '+1441', code: 'BM' },
  { value: 'Bhutan', label: '+975', code: 'BT' },
  { value: 'Bolivia, Plurinational State of bolivia', label: '+591', code: 'BO' },
  { value: 'Bosnia and Herzegovina', label: '+387', code: 'BA' },
  { value: 'Botswana', label: '+267', code: 'BW' },
  { value: 'Brazil', label: '+55', code: 'BR' },
  { value: 'British Indian Ocean Territory', label: '+246', code: 'IO' },
  { value: 'Brunei Darussalam', label: '+673', code: 'BN' },
  { value: 'Bulgaria', label: '+359', code: 'BG' },
  { value: 'Burkina Faso', label: '+226', code: 'BF' },
  { value: 'Burundi', label: '+257', code: 'BI' },
  { value: 'Cambodia', label: '+855', code: 'KH' },
  { value: 'Cameroon', label: '+237', code: 'CM' },
  { value: 'Canada', label: '+1', code: 'CA' },
  { value: 'Cape Verde', label: '+238', code: 'CV' },
  { value: 'Cayman Islands', label: '+ 345', code: 'KY' },
  { value: 'Central African Republic', label: '+236', code: 'CF' },
  { value: 'Chad', label: '+235', code: 'TD' },
  { value: 'Chile', label: '+56', code: 'CL' },
  { value: 'China', label: '+86', code: 'CN' },
  { value: 'Christmas Island', label: '+61', code: 'CX' },
  { value: 'Cocos (Keeling) Islands', label: '+61', code: 'CC' },
  { value: 'Colombia', label: '+57', code: 'CO' },
  { value: 'Comoros', label: '+269', code: 'KM' },
  { value: 'Congo', label: '+242', code: 'CG' },
  { value: 'Congo, The Democratic Republic of the Congo', label: '+243', code: 'CD' },
  { value: 'Cook Islands', label: '+682', code: 'CK' },
  { value: 'Costa Rica', label: '+506', code: 'CR' },
  { value: "Cote d'Ivoire", label: '+225', code: 'CI' },
  { value: 'Croatia', label: '+385', code: 'HR' },
  { value: 'Cuba', label: '+53', code: 'CU' },
  { value: 'Cyprus', label: '+357', code: 'CY' },
  { value: 'Czech Republic', label: '+420', code: 'CZ' },
  { value: 'Denmark', label: '+45', code: 'DK' },
  { value: 'Djibouti', label: '+253', code: 'DJ' },
  { value: 'Dominica', label: '+1767', code: 'DM' },
  { value: 'Dominican Republic', label: '+1849', code: 'DO' },
  { value: 'Ecuador', label: '+593', code: 'EC' },
  { value: 'Egypt', label: '+20', code: 'EG' },
  { value: 'El Salvador', label: '+503', code: 'SV' },
  { value: 'Equatorial Guinea', label: '+240', code: 'GQ' },
  { value: 'Eritrea', label: '+291', code: 'ER' },
  { value: 'Estonia', label: '+372', code: 'EE' },
  { value: 'Ethiopia', label: '+251', code: 'ET' },
  { value: 'Falkland Islands (Malvinas)', label: '+500', code: 'FK' },
  { value: 'Faroe Islands', label: '+298', code: 'FO' },
  { value: 'Fiji', label: '+679', code: 'FJ' },
  { value: 'Finland', label: '+358', code: 'FI' },
  { value: 'France', label: '+33', code: 'FR' },
  { value: 'French Guiana', label: '+594', code: 'GF' },
  { value: 'French Polynesia', label: '+689', code: 'PF' },
  { value: 'Gabon', label: '+241', code: 'GA' },
  { value: 'Gambia', label: '+220', code: 'GM' },
  { value: 'Georgia', label: '+995', code: 'GE' },
  { value: 'Germany', label: '+49', code: 'DE' },
  { value: 'Ghana', label: '+233', code: 'GH' },
  { value: 'Gibraltar', label: '+350', code: 'GI' },
  { value: 'Greece', label: '+30', code: 'GR' },
  { value: 'Greenland', label: '+299', code: 'GL' },
  { value: 'Grenada', label: '+1473', code: 'GD' },
  { value: 'Guadeloupe', label: '+590', code: 'GP' },
  { value: 'Guam', label: '+1671', code: 'GU' },
  { value: 'Guatemala', label: '+502', code: 'GT' },
  { value: 'Guernsey', label: '+44', code: 'GG' },
  { value: 'Guinea', label: '+224', code: 'GN' },
  { value: 'Guinea-Bissau', label: '+245', code: 'GW' },
  { value: 'Guyana', label: '+592', code: 'GY' },
  { value: 'Haiti', label: '+509', code: 'HT' },
  { value: 'Holy See (Vatican City State)', label: '+379', code: 'VA' },
  { value: 'Honduras', label: '+504', code: 'HN' },
  { value: 'Hong Kong', label: '+852', code: 'HK' },
  { value: 'Hungary', label: '+36', code: 'HU' },
  { value: 'Iceland', label: '+354', code: 'IS' },
  { value: 'India', label: '+91', code: 'IN' },
  { value: 'Indonesia', label: '+62', code: 'ID' },
  { value: 'Iran, Islamic Republic of Persian Gulf', label: '+98', code: 'IR' },
  { value: 'Iraq', label: '+964', code: 'IQ' },
  { value: 'Ireland', label: '+353', code: 'IE' },
  { value: 'Isle of Man', label: '+44', code: 'IM' },
  { value: 'Italy', label: '+39', code: 'IT' },
  { value: 'Jamaica', label: '+1876', code: 'JM' },
  { value: 'Japan', label: '+81', code: 'JP' },
  { value: 'Jersey', label: '+44', code: 'JE' },
  { value: 'Jordan', label: '+962', code: 'JO' },
  { value: 'Kazakhstan', label: '+7', code: 'KZ' },
  { value: 'Kenya', label: '+254', code: 'KE' },
  { value: 'Kiribati', label: '+686', code: 'KI' },
  { value: "Korea, Democratic People's Republic of Korea", label: '+850', code: 'KP' },
  { value: 'Korea, Republic of South Korea', label: '+82', code: 'KR' },
  { value: 'Kuwait', label: '+965', code: 'KW' },
  { value: 'Kyrgyzstan', label: '+996', code: 'KG' },
  { value: 'Laos', label: '+856', code: 'LA' },
  { value: 'Latvia', label: '+371', code: 'LV' },
  { value: 'Lebanon', label: '+961', code: 'LB' },
  { value: 'Lesotho', label: '+266', code: 'LS' },
  { value: 'Liberia', label: '+231', code: 'LR' },
  { value: 'Libyan Arab Jamahiriya', label: '+218', code: 'LY' },
  { value: 'Liechtenstein', label: '+423', code: 'LI' },
  { value: 'Lithuania', label: '+370', code: 'LT' },
  { value: 'Luxembourg', label: '+352', code: 'LU' },
  { value: 'Macao', label: '+853', code: 'MO' },
  { value: 'Macedonia', label: '+389', code: 'MK' },
  { value: 'Madagascar', label: '+261', code: 'MG' },
  { value: 'Malawi', label: '+265', code: 'MW' },
  { value: 'Malaysia', label: '+60', code: 'MY' },
  { value: 'Maldives', label: '+960', code: 'MV' },
  { value: 'Mali', label: '+223', code: 'ML' },
  { value: 'Malta', label: '+356', code: 'MT' },
  { value: 'Marshall Islands', label: '+692', code: 'MH' },
  { value: 'Martinique', label: '+596', code: 'MQ' },
  { value: 'Mauritania', label: '+222', code: 'MR' },
  { value: 'Mauritius', label: '+230', code: 'MU' },
  { value: 'Mayotte', label: '+262', code: 'YT' },
  { value: 'Mexico', label: '+52', code: 'MX' },
  { value: 'Micronesia, Federated States of Micronesia', label: '+691', code: 'FM' },
  { value: 'Moldova', label: '+373', code: 'MD' },
  { value: 'Monaco', label: '+377', code: 'MC' },
  { value: 'Mongolia', label: '+976', code: 'MN' },
  { value: 'Montenegro', label: '+382', code: 'ME' },
  { value: 'Montserrat', label: '+1664', code: 'MS' },
  { value: 'Morocco', label: '+212', code: 'MA' },
  { value: 'Mozambique', label: '+258', code: 'MZ' },
  { value: 'Myanmar', label: '+95', code: 'MM' },
  { value: 'Namibia', label: '+264', code: 'NA' },
  { value: 'Nauru', label: '+674', code: 'NR' },
  { value: 'Nepal', label: '+977', code: 'NP' },
  { value: 'Netherlands', label: '+31', code: 'NL' },
  { value: 'Netherlands Antilles', label: '+599', code: 'AN' },
  { value: 'New Caledonia', label: '+687', code: 'NC' },
  { value: 'New Zealand', label: '+64', code: 'NZ' },
  { value: 'Nicaragua', label: '+505', code: 'NI' },
  { value: 'Niger', label: '+227', code: 'NE' },
  { value: 'Nigeria', label: '+234', code: 'NG' },
  { value: 'Niue', label: '+683', code: 'NU' },
  { value: 'Norfolk Island', label: '+672', code: 'NF' },
  { value: 'Northern Mariana Islands', label: '+1670', code: 'MP' },
  { value: 'Norway', label: '+47', code: 'NO' },
  { value: 'Oman', label: '+968', code: 'OM' },
  { value: 'Pakistan', label: '+92', code: 'PK' },
  { value: 'Palau', label: '+680', code: 'PW' },
  { value: 'Palestinian Territory, Occupied', label: '+970', code: 'PS' },
  { value: 'Panama', label: '+507', code: 'PA' },
  { value: 'Papua New Guinea', label: '+675', code: 'PG' },
  { value: 'Paraguay', label: '+595', code: 'PY' },
  { value: 'Peru', label: '+51', code: 'PE' },
  { value: 'Philippines', label: '+63', code: 'PH' },
  { value: 'Pitcairn', label: '+64', code: 'PN' },
  { value: 'Poland', label: '+48', code: 'PL' },
  { value: 'Portugal', label: '+351', code: 'PT' },
  { value: 'Puerto Rico', label: '+1939', code: 'PR' },
  { value: 'Qatar', label: '+974', code: 'QA' },
  { value: 'Romania', label: '+40', code: 'RO' },
  { value: 'Russia', label: '+7', code: 'RU' },
  { value: 'Rwanda', label: '+250', code: 'RW' },
  { value: 'Reunion', label: '+262', code: 'RE' },
  { value: 'Saint Barthelemy', label: '+590', code: 'BL' },
  { value: 'Saint Helena, Ascension and Tristan Da Cunha', label: '+290', code: 'SH' },
  { value: 'Saint Kitts and Nevis', label: '+1869', code: 'KN' },
  { value: 'Saint Lucia', label: '+1758', code: 'LC' },
  { value: 'Saint Martin', label: '+590', code: 'MF' },
  { value: 'Saint Pierre and Miquelon', label: '+508', code: 'PM' },
  { value: 'Saint Vincent and the Grenadines', label: '+1784', code: 'VC' },
  { value: 'Samoa', label: '+685', code: 'WS' },
  { value: 'San Marino', label: '+378', code: 'SM' },
  { value: 'Sao Tome and Principe', label: '+239', code: 'ST' },
  { value: 'Saudi Arabia', label: '+966', code: 'SA' },
  { value: 'Senegal', label: '+221', code: 'SN' },
  { value: 'Serbia', label: '+381', code: 'RS' },
  { value: 'Seychelles', label: '+248', code: 'SC' },
  { value: 'Sierra Leone', label: '+232', code: 'SL' },
  { value: 'Singapore', label: '+65', code: 'SG' },
  { value: 'Slovakia', label: '+421', code: 'SK' },
  { value: 'Slovenia', label: '+386', code: 'SI' },
  { value: 'Solomon Islands', label: '+677', code: 'SB' },
  { value: 'Somalia', label: '+252', code: 'SO' },
  { value: 'South Africa', label: '+27', code: 'ZA' },
  { value: 'South Sudan', label: '+211', code: 'SS' },
  { value: 'South Georgia and the South Sandwich Islands', label: '+500', code: 'GS' },
  { value: 'Spain', label: '+34', code: 'ES' },
  { value: 'Sri Lanka', label: '+94', code: 'LK' },
  { value: 'Sudan', label: '+249', code: 'SD' },
  { value: 'Suriname', label: '+597', code: 'SR' },
  { value: 'Svalbard and Jan Mayen', label: '+47', code: 'SJ' },
  { value: 'Swaziland', label: '+268', code: 'SZ' },
  { value: 'Sweden', label: '+46', code: 'SE' },
  { value: 'Switzerland', label: '+41', code: 'CH' },
  { value: 'Syrian Arab Republic', label: '+963', code: 'SY' },
  { value: 'Taiwan', label: '+886', code: 'TW' },
  { value: 'Tajikistan', label: '+992', code: 'TJ' },
  { value: 'Tanzania, United Republic of Tanzania', label: '+255', code: 'TZ' },
  { value: 'Thailand', label: '+66', code: 'TH' },
  { value: 'Timor-Leste', label: '+670', code: 'TL' },
  { value: 'Togo', label: '+228', code: 'TG' },
  { value: 'Tokelau', label: '+690', code: 'TK' },
  { value: 'Tonga', label: '+676', code: 'TO' },
  { value: 'Trinidad and Tobago', label: '+1868', code: 'TT' },
  { value: 'Tunisia', label: '+216', code: 'TN' },
  { value: 'Turkey', label: '+90', code: 'TR' },
  { value: 'Turkmenistan', label: '+993', code: 'TM' },
  { value: 'Turks and Caicos Islands', label: '+1649', code: 'TC' },
  { value: 'Tuvalu', label: '+688', code: 'TV' },
  { value: 'Uganda', label: '+256', code: 'UG' },
  { value: 'Ukraine', label: '+380', code: 'UA' },
  { value: 'United Arab Emirates', label: '+971', code: 'AE' },
  { value: 'United Kingdom', label: '+44', code: 'GB' },
  { value: 'United States', label: '+1', code: 'US' },
  { value: 'Uruguay', label: '+598', code: 'UY' },
  { value: 'Uzbekistan', label: '+998', code: 'UZ' },
  { value: 'Vanuatu', label: '+678', code: 'VU' },
  { value: 'Venezuela, Bolivarian Republic of Venezuela', label: '+58', code: 'VE' },
  { value: 'Vietnam', label: '+84', code: 'VN' },
  { value: 'Virgin Islands, British', label: '+1284', code: 'VG' },
  { value: 'Virgin Islands, U.S.', label: '+1340', code: 'VI' },
  { value: 'Wallis and Futuna', label: '+681', code: 'WF' },
  { value: 'Yemen', label: '+967', code: 'YE' },
  { value: 'Zambia', label: '+260', code: 'ZM' },
  { value: 'Zimbabwe', label: '+263', code: 'ZW' },
];

export default countryData;
