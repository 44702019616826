import constants from "@Utils/constants";
import * as yup from 'yup';

export const createValidationSchema = (t, password) => yup.object({
  oldPassword: yup.string().required('validation.required').min(1, t('validation.required')),
  password: yup.string()
    .required('validation.required')
    .min(
      constants.validation.passwordMinLength,
      `${t('validation.fieldShouldBeAtLeast')} ${constants.validation.passwordMinLength} ${t('validation.characters')}`,
    ),
  confirmPassword: yup.string()
    .required('validation.required')
    .test('match', t('validation.passwordsDoNotMatch'), (value) => value === password),
});

export const initialValues = {
  name: '',
  username: '',
  password: '',
  confirmPassword: '',
  oldPassword: '',
}