import React, { useState } from 'react'
import styles from './MobileLayout.module.scss';
import { Button } from 'react-bootstrap';
import Share from '@Assets/Images/nouveau/share-variant.svg';
import classNames from 'classnames';
import TextTrimmer from '@Atoms/TextTrimmer/TextTrimmer';
import InformationGroup from '../../../../../Components/Atoms/InformationGroup/InformationGroup';
import { useTranslation } from 'react-i18next';
import { IDetailsProps } from '../Details.types';


const MobileLayout: React.FC<IDetailsProps> = ({
    generalText,
    title,
    description,
    rating,
    releaseDate,
    numberOfSeasons,
    genres,
    onClickPrimaryButton,
    isBookmarksButtonDisabled,
    isPlayButtonDisabled,
    onClickBookmarksButton,
    copyPathToClipboard,
    PlayButtonContent,
    BookmarkButtonContent,
}) => {
    const { t } = useTranslation();
    const [showNotification, setShowNotification] = useState(false);

    const handleCopyLink = () => {
        copyPathToClipboard();
        setShowNotification(true);

        setTimeout(() => setShowNotification(false), 2000);
    };

    return (
        <div className={styles.container}>
            <div className={styles.overlayInterface}>
                <div className={styles.tagGroup}>
                    <div className={styles.typographyGroup}>
                        <span className={styles.title}>{title}</span>
                        <InformationGroup genres={genres} numberOfSeasons={numberOfSeasons} rating={rating} releaseDate={releaseDate} />
                        {description && (
                            <div dir='auto' className={styles.description}>
                                <TextTrimmer text={description} more={t('globals.showMore')} less={t('globals.showLess')} lines={3} expanded={false} />
                            </div>
                        )}
                    </div>
                    {generalText && <div className={styles.tag}>{generalText}</div>}
                </div>
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={onClickPrimaryButton}
                        disabled={isPlayButtonDisabled}
                        className={classNames(styles.button, styles.flexedButton)}
                        variant='primary'
                        size='lg'
                    >
                        {PlayButtonContent}
                    </Button>
                    <div className={styles.buttonsGroup}>
                        <Button
                            onClick={onClickBookmarksButton}
                            className={classNames(styles.button, styles.flexedButton, styles.secondaryButton)}
                            disabled={isBookmarksButtonDisabled}
                            variant='secondary'
                            size='lg'
                        >
                            {BookmarkButtonContent}
                        </Button>
                        <Button variant='secondary' onClick={handleCopyLink} size='lg' className={classNames(styles.button, styles.shareButton)}>
                            <img src={Share} alt='share button' />
                        </Button>
                        {showNotification && (
                            <div className={styles.notification}>
                                <span>{t('globals.linkCopied')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MobileLayout