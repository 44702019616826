import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import styles from './Cookie.module.scss';

const Cookie = () => {
  const { t } = useTranslation();
  const firstSentence = t('globals.cookie1stSent');
  const secondSentence = t('globals.cookie2ndSent');

  return (
    <CookieConsent
      hideOnAccept
      flipButtons
      location="bottom"
      buttonText={t('globals.cookieAgree')}
      buttonClasses={styles.button}
      containerClasses={styles.cookieContainer}
    >
      <div className={styles.firstSentence}>{firstSentence}</div>
      <div className={styles.secondSentence}>{secondSentence}</div>
    </CookieConsent>
  );
};
export default Cookie;
