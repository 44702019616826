import Episode from "@Atoms/Episode/Episode";
import { checkUserLoggedIn, convertSeasonsObjToArray, getPackageById } from "@Utils/common";
import { PurchasableMedia, IPaymentInfo, PurchaseType } from "@Utils/commonTypes";
import constants from "@Utils/constants";
import queryString from "query-string";
import { redirect, LoaderFunctionArgs } from "react-router-dom";
import {
    getMyFatoorahPurchasePaymentMethods,
    getSeriesDetails,
    getUserPurchases,
    getMyFatoorahSubscriptionPaymentMethods,
    getUserSubscription,
    getMovieDetails
} from "src/alballam-api";
import { Package, WithApiMetaData, IMyFatoorahPaymentMethod } from "src/alballam-api-types";


// Edge case scenario
// if subscription and length of 12 months/1 year, hide the option to subscribe through mastercard/visa
// Reason: myFatoorah does not support recurring payments for mastercard/visa when duration longer than 10~ish months
export const paymentMethodBlacklist = [
    {
        name: 'VISA/MASTER',
        id: 2,
        code: 'vm'
    }
]
export const filterEdgeCaseScenarios = (pckg: Package | null | undefined, paymentMethods: WithApiMetaData<IMyFatoorahPaymentMethod[]>): WithApiMetaData<IMyFatoorahPaymentMethod[]> => {
    if (paymentMethods.result.length < 1) return paymentMethods;
    if (!pckg) return paymentMethods;
    if (pckg.duration <= 180) return paymentMethods;


    const methods = paymentMethods.result;
    const out: IMyFatoorahPaymentMethod[] = JSON.parse(JSON.stringify(methods));
    for (let i = 0; i < methods.length; i++) {
        for (let j = 0; j < paymentMethodBlacklist.length; j++) {
            if (methods[i].PaymentMethodCode === paymentMethodBlacklist[j].code) {
                if (methods[i].PaymentMethodCode === paymentMethodBlacklist[j].code) {
                    out.splice(i, 1);
                }
            }
        }
    }

    return {
        ...paymentMethods,
        result: out,
    } as WithApiMetaData<IMyFatoorahPaymentMethod[]>;
}


export function redirectToLogin(request: Request) {
    const destination = new URL(constants.screens.login, window.location.origin);
    const intendedDestination = new URL(request.url);
    const intendedDestinationParams = queryString.parse(intendedDestination.search);


    destination.searchParams.set('redirect_url', intendedDestination.pathname);
    destination.searchParams.set('redirect_search', queryString.stringify(intendedDestinationParams, { arrayFormat: 'bracket' }));

    return redirect(destination.toString());
}

export async function SeasonPurchasePaymentLoader({ params, request }: LoaderFunctionArgs) {
    if (checkUserLoggedIn() === false) return redirectToLogin(request);

    try {

        const paymentMethods = await getMyFatoorahPurchasePaymentMethods(params.purchaseId!, params.purchaseType! as PurchasableMedia);
        if (!paymentMethods) {
            return redirect(constants.screens.browse);
        }

        const paymentInfo: IPaymentInfo = {
            purchaseType: PurchaseType.Media,
            mediaType: params.purchaseType! as PurchasableMedia,
            title: 'episode',
        }


        //TODO: Refactor this
        if (params.purchaseType === PurchasableMedia.Season) {
            // purchase package can only be found on the details data of the series
            // step 1: get the series details
            // step 2: convert the season object to array
            // step 3: find the season by id
            // step 4: find the purchase package by search for package with 'purchase' type
            const seriesDetails = await getSeriesDetails(params.mediaId!);
            const seasonsArray = convertSeasonsObjToArray(seriesDetails.result?.seasons);
            const selectedSeason = seasonsArray.find(season => season.id === params.purchaseId);
            const selectedPackage = selectedSeason?.packages.find(bundle => bundle.type === 'purchase');
            if (selectedPackage === undefined || selectedSeason === undefined || seasonsArray === undefined) {
                return redirect(constants.screens.browse);
            }

            paymentInfo.mediaId = selectedSeason?.id.toString();
            paymentInfo.img = seriesDetails.result?.image;
            paymentInfo.title = seriesDetails.result?.sourceName;
            paymentInfo.seasonId = selectedSeason?.id.toString();

            const userPurchases = await getUserPurchases();
            if (userPurchases.result?.purchase.find(purchase => purchase._id === selectedPackage?.id)) {
                return redirect(constants.screens.browse);
            }

            return { paymentMethods, selectedPackage, currentUserSubscriptions: userPurchases, paymentInfo };
        } else if (params.purchaseType === PurchasableMedia.Episode) {

            const seriesDetails = await getSeriesDetails(params.mediaId!);
            const seasonsArray = convertSeasonsObjToArray(seriesDetails.result?.seasons);

            // find the episode in the seasons by id, we don't have the seasonId so we must find the episode in all seasons
            let selectedEpisode: Episode | undefined;
            seasonsArray.forEach(season => {
                season?.episodes?.forEach(episode => {
                    if (episode?.id?.toString() === params?.purchaseId) {
                        selectedEpisode = episode;
                        paymentInfo.seasonId = season.id.toString();
                    }
                });
            });

            const selectedPackage = selectedEpisode?.packages.find(bundle => bundle.type === 'purchase');
            if (selectedPackage === undefined) {
                return redirect(constants.screens.browse);
            }

            paymentInfo.mediaId = selectedEpisode?.id.toString();
            paymentInfo.img = selectedEpisode?.image;
            paymentInfo.title = selectedEpisode?.title ?? '';

            const userPurchases = await getUserPurchases();
            if (userPurchases?.result?.purchase?.find(purchase => purchase._id === selectedPackage?.id)) {
                return redirect(constants.screens.browse);
            }


            return { paymentMethods, selectedPackage, currentUserSubscriptions: userPurchases, paymentInfo };
        }

        return undefined;
    } catch {
        return redirect(constants.screens.browse)
    }
}

export async function SubscriptionPaymentLoader({ params, request }: LoaderFunctionArgs) {
    if (checkUserLoggedIn() === false) return redirectToLogin(request);

    try {

        const initialPaymentMethods = await getMyFatoorahSubscriptionPaymentMethods(params.packageId!);
        const selectedPackage = await getPackageById(parseInt(params.packageId!));

        // const paymentMethods = initialPaymentMethods;
        const paymentMethods = filterEdgeCaseScenarios(selectedPackage, initialPaymentMethods);

        const currentUserSubscriptions = await getUserSubscription();
        const paymentInfo: IPaymentInfo = {
            title: 'package',
            purchaseType: PurchaseType.Subscribe,
            mediaType: undefined,
            mediaId: undefined,
        };


        if (currentUserSubscriptions.result?.owned === true) {
            return redirect(constants.screens.browse);
        }

        return { paymentMethods, selectedPackage, currentUserSubscriptions, paymentInfo };
    } catch {
        return redirect(constants.screens.browse)
    }
}

export async function MoviePurchasePaymentLoader({ params, request }: LoaderFunctionArgs) {
    if (checkUserLoggedIn() === false) return redirectToLogin(request);

    const paymentMethods = await getMyFatoorahPurchasePaymentMethods(params.purchaseId!, params.mediaType! as PurchasableMedia);
    const movieDetails = await getMovieDetails(params.purchaseId!);
    const selectedPackage = movieDetails.result?.packages.find(bundle => bundle.type === 'purchase');
    const currentUserSubscriptions = await getUserPurchases();
    const paymentInfo: IPaymentInfo = {
        title: movieDetails.result?.title,
        purchaseType: PurchaseType.Media,
        mediaType: params.mediaType! as PurchasableMedia,
        mediaId: params.purchaseId
    };

    if (currentUserSubscriptions.result?.purchase.find(purchase => purchase._id === selectedPackage?.id)) {
        return redirect(constants.screens.browse);
    }

    return { paymentMethods, selectedPackage, currentUserSubscriptions, paymentInfo };
}