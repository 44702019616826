/* eslint-disable */
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseButton from '../../../Assets/Images/VideoControls/icons-close.svg';
import cautionIcon from '../../../Assets/Images/caution.svg';
import styles from './AutoRenew.module.scss';
import constants from '../../../Utils/constants';
import { clearSubscriptionSource, saveSubscriptionSource } from '../../../Store/Actions/subscriptionsSourceActions';
import { showPaymentAlert } from '../../../Store/Actions/ShowPaymentAlertActions';
import PropTypes from 'prop-types';

const AutoRenew = ({ isKillSession, onKillSessionClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location.pathname.slice(1) + (location.search ?? '');

  const onClose = useCallback(() => {
    dispatch(showPaymentAlert(true));
  }, [dispatch]);

  const onSubscriptionClick = useCallback(() => {
    dispatch(saveSubscriptionSource({
      sourceType: 'route',
      routeName: 'auto-renew', 
    }));
    dispatch(showPaymentAlert(true));
    dispatch(clearPostSubscription());
    if (redirectUrl) {
      navigate(`${constants.screens.subscribeAll}?redirect_url=${redirectUrl}`);
    } else {
      navigate(constants.screens.subscribeAll)
    }
  }, [navigate, dispatch]);

  return (
    <div className={styles.AutoRenewContainer} id="autoRenew">
      <div className={styles.firstLine}>
        <div>
          <img src={cautionIcon} height={40} width={40} alt="caution" />
          <span className={styles.subscriptionCancelled}>
            {isKillSession
              ? t("globals.tooManyPeople")
              : t("globals.subscriptionCancelled")}
          </span>
        </div>
        {!isKillSession && (
          <div className={styles.closeContainer}>
            <img
              src={CloseButton}
              className={styles.CloseButton}
              alt="close"
              onClick={onClose}
            />
          </div>
        )}
      </div>
      <div className={styles.secondLineContainer}>
        <span className={styles.secondLine}>
          {isKillSession
            ? t("globals.toContinueWatching")
            : t("globals.subscriptionCancelledSecondLine")}
        </span>
      </div>
      <div className={styles.btnContainer}>
        <Button
          label="دفع"
          variant="light"
          loadingSize="sm"
          className={styles.submitBtn}
          onClick={isKillSession ? onKillSessionClick : onSubscriptionClick}
        >
          {isKillSession
            ? t("globals.tryAgain")
            : t("globals.startSubscription")}
        </Button>
      </div>
    </div>
  );
};

AutoRenew.propTypes = {
  isKillSession: PropTypes.bool,
  onKillSessionClick: PropTypes.func,
};

AutoRenew.defaultProps = {
  isKillSession: null,
  onKillSessionClick: null,
};

export default AutoRenew;
