import styles from './PaymentGateway.module.scss';
import { Button } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from '@Utils/constants';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { clearSubscriptionSource } from '@Store/Actions/subscriptionsSourceActions';
import { useSessionStorage } from 'usehooks-ts';

interface IPaymentGatewayProps {
    title: string;
    destination?: string;
}

const PaymentGateway: React.FC<IPaymentGatewayProps> = ({ title, destination }) => {
    const [params] = useSearchParams();
    const origin = params.get('origin');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Set shouldGoToHomepage to true so that, on the player when the user clicks the back button, it does not drop him in the payment gateway again
    useSessionStorage(constants.sessionStorageKeys.shouldGoToHomepage, true);

    const continueButtonDestination = useMemo(() => {
        if (destination) {
            return destination;
        }
        if (origin) {
            return origin;
        }


        return constants.screens.browse;
    }, [destination, origin]);

    useEffect(() => {
        dispatch(clearSubscriptionSource());
    }, [dispatch]);

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.paymentResolution}>
                    <span className={styles.title}>{t(title)}</span>
                    <div className={styles.descriptionGroup}>
                        <Button
                            //@ts-expect-error - as={Link} is not a valid prop for Button
                            as={Link}
                            variant='light'
                            className={styles.continueButton}
                            to={continueButtonDestination}
                            unstable_viewTransition
                        >
                            <span>{t('payment.continue')}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentGateway;