
/* eslint-disable  react/no-array-index-key */
/* eslint-disable  react/forbid-prop-types */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import screenfull from "screenfull";
import { TimeSeekSlider } from "react-time-seek-slider";
import classNames from "classnames";
import { isMobile, isSafari, withOrientationChange } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { getVideoTime } from "../../../../Utils/common";
import PlayerButton from "../../../Atoms/PlayerButton/PlayerButton";
import PlayerControlButton from "../../../Molecules/PlayerControlButton/PlayerControlButton";
import ClickableItem from "../../../Atoms/ClickableItem/ClickableItem";
import VideoPlayerLoader from "../../../Atoms/VideoPlayerLoader/VideoPlayerLoader";
import VolumeSlider from "../../../Atoms/VolumeSlider/VolumeSlider";
import Play from "../../../../Assets/Images/VideoControls/Play.svg";
import Pause from "../../../../Assets/Images/VideoControls/Pause.svg";
import Fullscreen from "../../../../Assets/Images/VideoControls/Fullscreen.svg";
import ExitFullscreen from "../../../../Assets/Images/VideoControls/exit-full-screen.svg";
import "./seekBar.scss";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import {
  advertismentClicked,
  advertismentSkiped,
} from "../../../../Store/Actions/AdvertisementAnalyticsActions";
import { useNavigate } from "react-router-dom";
import constants from "../../../../Utils/constants";
import Back from '../../../../Assets/Images/VideoControls/Back.svg';

const SPACE_BAR = 32;
const UP_ARROW = 38;
const DOWN_ARROW = 40;
const BUTTON_F = 70;
const BUTTON_M = 77;

const restoreButtonsIndex = () => {
  try {
    document.getElementsByClassName(styles.rightButtons)[0].style.zIndex = 10;
  } catch { }
};

const toggleFullScreen = () => {
  if (isMobile && isSafari) {
    document.getElementsByTagName('video')[0].webkitEnterFullScreen();
  } else {
    try {
      screenfull.toggle();
      /* eslint-disable-next-line no-empty */
    } catch { }
  }
};

const stopPropagation = (e) => {
  e.stopPropagation();
};

const onSeek = () => {
  return null;
};
const AdvertisementPlayerControls = ({
  playing,
  buffering,
  duration,
  currentTime,
  bufferedTime,
  onPlay,
  volume,
  onVolumeClick,
  children,
  isLandscape,
  isSkippable,
  skipDuration,
  onSkipAd,
  redirectUrl,
  id,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showControlsTimer = useRef(null);
  const [removeControls, setRemoveControls] = useState(false);
  const playerBtnRef = useRef(null);
  const [status, setStatus] = useState({
    fullscreen: screenfull.isFullscreen,
    showControls: true,
    // showVolume: false,
  });
  const [isDraggingVolume, setIsDraggingVolume] = useState(false);
  const dispatch = useDispatch();
  const clearControlsTimer = useCallback(() => {
    clearTimeout(showControlsTimer.current);
    setRemoveControls(false);
  }, []);

  const onMouseMoveHandler = useCallback(
    (e) => {
      //   onActivity();
      const { currentTarget } = e ?? {};
      clearControlsTimer();
      if (currentTarget) currentTarget.style.cursor = 'default';
      setStatus((prevState) => ({
        ...prevState,
        showControls: true,
      }));
      showControlsTimer.current = setTimeout(() => {
        setStatus((prevState) => ({
          ...prevState,
          showControls: false,
        }));
        setTimeout(() => {
          if (currentTarget) currentTarget.style.cursor = 'none';
        }, 600);
      }, 4000);
    },
    [showControlsTimer, clearControlsTimer]
  );

  useEffect(
    () =>
      isSafari &&
      isMobile &&
      document.getElementsByTagName('video')[1].webkitExitFullScreen(),
    []
  );

  useEffect(() => {
    if (removeControls) {
      restoreButtonsIndex();
    }
  }, [removeControls]);

  const onKeyDownHandler = useCallback(
    (e) => {
      switch (e.which) {
        case SPACE_BAR:
          onPlay();
          break;
        case UP_ARROW:
          onVolumeClick(Math.min(volume + 0.1, 1));
          break;
        case DOWN_ARROW:
          onVolumeClick(Math.max(volume - 0.1, 0));
          break;
        case BUTTON_M:
          onVolumeClick();
          break;
        case BUTTON_F:
          toggleFullScreen();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line
    [onPlay, onVolumeClick, volume, toggleFullScreen]
  );

  useEffect(() => {
    const onFullscreen = () => {
      setStatus((prevState) => ({
        ...prevState,
        fullscreen: screenfull.isFullscreen,
      }));
    };

    document.body.addEventListener('keydown', onKeyDownHandler);
    if (!isMobile && !isSafari) {
      screenfull.on('change', onFullscreen);
    }

    return () => {
      document.body.removeEventListener('keydown', onKeyDownHandler);
      if (!isMobile && !isSafari) {
        screenfull.off('change', onFullscreen);
      }
    };
  }, [onKeyDownHandler]);

  const skipAdHandler = useCallback(() => {
    onSkipAd();
    dispatch(advertismentSkiped(id));
    // eslint-disable-next-line
  }, [dispatch, id]);

  const subscribeBtnHandler = useCallback(() => {
    navigate(constants.screens.subscribeAll);
  }, [navigate]);

  const onRedirectHandler = useCallback(() => {
    if (redirectUrl && !isDraggingVolume) {
      window.open(redirectUrl, '_blank');
      dispatch(advertismentClicked(id));
    }
  }, [dispatch, id, redirectUrl, isDraggingVolume]);

  const renderSkipAd = useCallback(() => {
    if (!isSkippable) return null;
    if (Math.trunc(currentTime) >= skipDuration) {
      return (
        <PlayerButton
          title={t('videoPlayer.skipAd')}
          onClick={skipAdHandler}
          filled
          className={classNames(
            styles.skipAdHandler,
            styles.skipAdWithoutDuration
          )}
        />
      );
    }
    return (
      <PlayerButton
        title={` ${t('videoPlayer.skipAd')} ${skipDuration - Math.trunc(currentTime)
          } `}
        onClick={() => { }}
        filled
        className={classNames(
          styles.waitingForSkipButton,
          styles.skipAdHandler,
          styles.skipAdWithDuration
        )}
      />
    );
    // eslint-disable-next-line
  }, [t, isSkippable, skipDuration, currentTime]);

  return (
    <div className="controllsContainer">
      {buffering && <VideoPlayerLoader />}
      <div className={styles.topControlsContainer}>
        <PlayerControlButton
          onClick={onBack}
          icon={Back}
          containerClasses={styles.backButton}
        />
      </div>
      <div className={isMobile && !isLandscape ? styles.rightMidBtn : styles.rightButtons} id='right-buttons'>
        <div className="d-flex">
          <PlayerButton
            title={t('videoPlayer.subscribeBtn')}
            onClick={subscribeBtnHandler}
            filled
          // className={styles.subscribeBtn}
          />
          {renderSkipAd()}
        </div>
      </div>
      <div
        className={classNames(
          styles.controlsWrapper,
          status.showControls ? styles.active : ''
        )}
        onDoubleClick={(event) => {
          if (isMobile && isSafari) return;
          event.stopPropagation();
          toggleFullScreen();
        }}
        onMouseMove={onMouseMoveHandler}
        role="button"
        tabIndex="0"
        onTransitionEnd={(e) => {
          e.stopPropagation();
          if (!status.showControls) {
            setRemoveControls(true);
          }
        }}
      >
        {!removeControls && (
          <div>
            <div className={styles.clickableArea} onClick={onRedirectHandler} />
            <ClickableItem
              classes={classNames(
                styles.bottomControlsContainer,
                styles.noOutline,
                isMobile && !isLandscape && styles.highBottom
              )}
              onClick={stopPropagation}
            >
              <div className={styles.seekTimeBarContainer}>
                <span className={styles.timeCurrent}>
                  {getVideoTime(currentTime)}
                </span>

                <TimeSeekSlider
                  max={duration || 1}
                  currentTime={currentTime}
                  progress={bufferedTime}
                  onSeeking={onSeek}
                  secondsPrefix="0:"
                  hideHoverTime
                />
                <span className={styles.timeDuration}>
                  {getVideoTime(duration - currentTime)}
                </span>
              </div>

              <div
                className={styles.videoPlayerControlsContainer}
                onDoubleClick={stopPropagation}
              >
                <div className={styles.leftControlsContainer}>
                  <PlayerControlButton
                    onClick={onPlay}
                    icon={playing ? Pause : Play}
                  />
                  <VolumeSlider
                    onClick={onVolumeClick}
                    containerClasses={styles.volumeButton}
                    volume={volume}
                    isDragging={setIsDraggingVolume}
                  />
                </div>

                {!isSafari && !isMobile && (
                  <div className={styles.rightControlsContainer}>
                    <PlayerControlButton
                      clickItemRef={playerBtnRef}
                      onClick={toggleFullScreen}
                      icon={status.fullscreen ? ExitFullscreen : Fullscreen}
                    />
                  </div>
                )}
              </div>
            </ClickableItem>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

AdvertisementPlayerControls.propTypes = {
  playing: PropTypes.bool.isRequired,
  buffering: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  bufferedTime: PropTypes.number,
  onPlay: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
  onVolumeClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isSkippable: PropTypes.bool.isRequired,
  skipDuration: PropTypes.number,
  onSkipAd: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  id: PropTypes.number.isRequired,
};

AdvertisementPlayerControls.defaultProps = {
  bufferedTime: undefined,
  children: null,
  skipDuration: null,
  redirectUrl: '',
};

export default withOrientationChange(AdvertisementPlayerControls);
