import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  loginUserUsingApple,
  loginUserUsingFacebook,
  loginUserUsingGoogle,
  signUp,
  checkOTP,
  resendOTP,
} from '../../Services/Authentication';
import { saveAuthentication } from '../../Store/Actions/AuthenticationActions';
import { handleError } from '../../Utils/httpClient';
import constants from '../../Utils/constants';
import NonAuthenticatedLayout from '../../Components/Organisms/NonAuthenticatedLayout/NonAuthenticatedLayout';
import SignUpForm from '../../Components/Organisms/SignUpForm';
import OTPForm from '../../Components/Organisms/OTPForm';
import { checkSubscription } from '../../Store/Actions/SubscriptionValidationActions';
import useQuery from '../../Hooks/useQuery';
import styles from './Signup.module.scss';

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [googleData, setGoogleData] = useState(null);
  const [facebookData, setFacebookData] = useState(null);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [errorOTP, setErrorOTP] = useState('');
  const [coolDownOTP, setcoolDownOTP] = useState(0);
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location?.state?.redirect_url;

  const { selected } = useSelector((state) => state.videoSubscription);
  const {
    checkSubscription: { error: checkError, result: checkResult },
  } = useSelector((state) => state.subscriptionValidation);
  const { language } = useSelector((state) => state.authentication);

  useEffect(() => {
    setError('');
  }, [location?.state]);

  const onCaptchaChange = useCallback((value) => {
    if (value) {
      setCaptchaValidated(true);
    }
  }, []);

  const paymentRedirection = useQuery()?.get('redirect_url');

  const redirectUser = useCallback(
    (searchRedirection = false) => {
      dispatch(saveAuthentication({ isSignedIn: true, isGuest: null }));
      setLoading(false);
      if (redirectUrl) {
        let redirectionUrl = redirectUrl;

        // if owned
        if (searchRedirection && paymentRedirection) {
          redirectionUrl = paymentRedirection[0] === '?'
            ? `/${paymentRedirection}`
            : paymentRedirection;
        }
        // if not owned
        if (!searchRedirection) {
          redirectionUrl += location?.search;
        }
        dispatch(saveAuthentication({ isSignedIn: true, isGuest: null }));
        navigate(`${constants.newScreens.profiles.view}${redirectionUrl}`, { state: { payment: true }, replace: true });
      } else {
        dispatch(saveAuthentication({ isSignedIn: true, isGuest: null }));
        navigate(`${constants.newScreens.profiles.view}${location?.search ?? ''}`, { replace: true });
      }
    },
    [dispatch, redirectUrl, paymentRedirection, navigate, location?.search],
  );

  const checkPayment = (authData) => {
    dispatch(saveAuthentication({ ...authData, isGuest: null }));
    if (selected) {
      dispatch(
        checkSubscription(
          /* eslint-disable-next-line no-underscore-dangle */
          selected?.id || selected?.package?._id || selected?.package?.id,
          selected?.type,
        ),
      );
    } else {
      redirectUser();
    }
  };

  useEffect(() => {
    if (selected && (checkResult || checkError)) {
      if (checkResult?.owned) {
        redirectUser(true);
      } else {
        redirectUser();
      }
    }
  }, [selected, checkResult, checkError, redirectUser]);

  const submitSignUp = async (user) => {
    user.name = user.name.trim().split(/ +/).join(' ');
    user.username = user.username.trim().split(/ +/).join(' ');
    try {
      setLoading(true);
      setError('');
      await signUp(user.name, user.username, user.password, language);
      setLoading(false);
      navigate(`${constants.screens.signUp}${location?.search ?? ''}`, { state: { isOtp: true, username: user.username, redirect_url: redirectUrl } });
    } catch (e) {
      setLoading(false);
      if (e.data.data) {
        setError(e.data.data.message);
      } else setError(t('errors.somethingWentWrong'));
    }

    return;
  };

  const submitOTP = async ({ otp }) => {
    try {
      setLoading(true);
      setError('');
      const authenticateUser = await checkOTP(
        location?.state.username,
        otp,
        language,
      );
      dispatch(saveAuthentication({ ...authenticateUser.data.result, isGuest: null }));
      redirectUser();
    } catch (e) {
      setLoading(false);
      if (e.data.data) {
        setError(e.data.data.message);
      } else setError(t('errors.somethingWentWrong'));
    }
  };

  const resendOtp = async () => {
    try {
      if (loadingOTP || coolDownOTP) {
        return;
      }

      setLoadingOTP(true);
      setErrorOTP('');
      await resendOTP(location?.state.username, language);
      let timer = constants.otp.resendTimer;
      setInterval(() => {
        if (timer >= 0) {
          setcoolDownOTP(timer);
          timer -= 1;
        }
      }, 1000);

      setLoadingOTP(false);
    } catch (e) {
      setLoadingOTP(false);
      if (e.data.data) {
        setErrorOTP(e.data.data.message);
      } else setErrorOTP(t('errors.somethingWentWrong'));
    }
  };

  const signInUsingGoogle = async (data) => {
    try {
      setLoading(true);
      setError(false);
      setGoogleData(null);
      const result = await loginUserUsingGoogle(data);
      setGoogleData(data);
      checkPayment(result.data.data.result);
    } catch (e) {
      setLoading(false);
      setError(handleError(e, t));
    }
  };

  const handleGoogleFailure = async (data) => {
    if (data === 'User already connected') {
      await signInUsingGoogle(googleData);
    }
  };

  const signInUsingFacebook = async (data) => {
    try {
      setLoading(true);
      setError(false);
      setFacebookData(null);
      const result = await loginUserUsingFacebook(data);
      setFacebookData(data);
      checkPayment(result.data.data.result);
    } catch (e) {
      setLoading(false);
      setError(handleError(e, t));
    }
  };

  const handleFacebookFailure = async (data) => {
    if (data === 'User already connected') {
      await signInUsingFacebook(facebookData);
    }
  };

  const signInUsingApple = async (data) => {
    try {
      setLoading(true);
      setError(false);
      await loginUserUsingApple(data).then((res) => checkPayment(res?.data?.data?.result));
    } catch (e) {
      setLoading(false);
      setError(handleError(e, t));
    }
  };

  return (
    <NonAuthenticatedLayout
      title={
        !(location?.state?.isOtp)
          ? t('globals.signUp')
          : t('globals.verifyYourEmail')
      }
      showLogo={false}
      contentContainerClassName={styles.container}
    >
      {!(location?.state?.isOtp) ? (
        <SignUpForm
          onSubmit={(user) => submitSignUp(user)}
          signUpError={error}
          onGoogleLogin={signInUsingGoogle}
          onGoogleFailure={handleGoogleFailure}
          onFacebookFailure={handleFacebookFailure}
          onFacebookLogin={signInUsingFacebook}
          onAppleLogin={signInUsingApple}
          loading={loading}
          captchaValidated={captchaValidated}
          onCaptchaChange={onCaptchaChange}
        />
      ) : (
        <OTPForm
          onSubmit={(otp) => submitOTP(otp)}
          resendOtp={resendOtp}
          signUpError={error}
          loading={loading}
          loadingOTP={loadingOTP}
          errorOTP={errorOTP}
          coolDownOTP={coolDownOTP}
          email={location?.state?.username}
        />
      )}
    </NonAuthenticatedLayout>
  );
};

export default SignUp;
