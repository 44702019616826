import React from 'react';

interface IClickableItemProps {
  children: React.ReactNode;
  onClick: (e: any) => void;
  classes?: string;
  style?: React.CSSProperties;
  onBlur?: () => void;
  itemRef?: React.RefObject<HTMLDivElement>;
}

const ClickableItem: React.FC<IClickableItemProps> = ({ children, onClick, classes, style, onBlur, itemRef }) => (
  <div
    ref={itemRef}
    role="button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={onClick}
    className={classes}
    style={style}
    onBlur={onBlur}
  >
    {children}
  </div>
);

export default ClickableItem;
