import {
  SAVE_PROFILE,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAIL,
  CLEAR_SAVE_PROFILE,
} from '../Constants/AuthProfileConstants';
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-plusplus */

const initialState = {
  type: null,
  result: null,
  loading: false,
  error: null,
};

const authProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROFILE:
      return {
        ...state,
        loading: true,
        result: action.payload,
      };
    case SAVE_PROFILE_SUCCESS:
      return {
        ...state,
        result: action.payload,
        loading: false,
      };
    case SAVE_PROFILE_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_SAVE_PROFILE:
      return initialState;
    default:
      return state;
  }
};

export default authProfileReducer;
