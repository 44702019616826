import LandscapeImage from "@Atoms/LandscapeImage/LandscapeImage";
import { Link, useOutletContext } from "react-router-dom";
import styles from './RelatedSection.module.scss';
import { MediaType, TagType } from '@Utils/commonTypes';
import utilStyles from "@Utils/utils.module.scss";
import constants from "@Utils/constants";
import classNames from "classnames";
import { IMediaDetailsContext } from "@Molecules/EpisodesRail/DesktopLayout/DesktopLayout";

function processRelatedItemLink(mediaType: MediaType, id: string) {
    switch (mediaType) {
        case MediaType.Movie:
            return constants.newScreens.details.movie(id).as;
        case MediaType.Show:
            return constants.newScreens.details.show(id).as;
        case MediaType.Series:
            return constants.newScreens.details.series(id).as;
        default:
            return '.';
    }
}

function RelatedSection() {
    const context = useOutletContext<IMediaDetailsContext>();

    return (
        <div className={styles.root}>
            {context?.media?.related?.map((relatedItem) => {
                const tagOptions = relatedItem?.comingSoon ? { type: TagType.ComingSoon } : undefined;
                const link = processRelatedItemLink(relatedItem.type, relatedItem.id);
                return (
                    <Link key={relatedItem?.id} to={link} unstable_viewTransition className={classNames(styles.related, utilStyles.noDecoration)}>
                        <LandscapeImage
                            imageProps={{ src: relatedItem.medium, className: styles.relatedItemImage, wrapperClassName: styles.relatedItemPlaceholder }}
                            rootWrapperProps={{ className: styles.relatedImageWrapper }}
                            tagProps={tagOptions}
                            key={relatedItem.id}
                        />
                    </Link>
                )
            })}
        </div>
    )
}

export default RelatedSection;