import {
  CLEAR_SHOWS_LANDING,
  GET_SHOWS_LANDING,
} from '../Constants/ShowsConstants';

const getShowsLanding = () => ({
  type: GET_SHOWS_LANDING,
  payload: {
    request: {
      url: 'api/shows/landing',
      method: 'get',
      authenticated: true,
      params: {
        isSeries: false,
      },
    },

  },
});

const clearShowsLanding = () => ({
  type: CLEAR_SHOWS_LANDING,
  payload: null,
});

export { getShowsLanding, clearShowsLanding };
