import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import constants from '../../../Utils/constants';
import { InputField, SubmitInput } from '../../Atoms';
import MessageBox from '../../Atoms/MessageBox';
import styles from './index.module.scss';
import classNames from 'classnames';

const initialValues = {
  name: '',
  username: '',
  password: '',
  confirmPassword: '',
}

const ResetPasswordForm = ({ onSubmit, signUpError, loading }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const validationSchema = yup.object({
    password: yup.string()
      .required('validation.required')
      .min(
        constants.validation.passwordMinLength,
        `${t('validation.fieldShouldBeAtLeast')} ${constants.validation.passwordMinLength} ${t('validation.characters')}`,
      ),
    confirmPassword: yup.string()
      .required('validation.required')
      .test('match', t('validation.passwordsDoNotMatch'), (value) => value === password),
  });
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(props) => onSubmit(props)}
    >
      {({ handleSubmit, handleChange, errors, isValid, isSubmitting }) => (
        <Form noValidate className={styles.form} onSubmit={(e) => handleSubmit(e)}>
          <div>
            <InputField
              name="password"
              label={t('globals.password')}
              type="password"
              placeholder={t('globals.password')}
              onChange={(e) => [handleChange(e), setPassword(e.target.value)]}
              alertText={errors.password}
              error={!!errors.password}
            />
          </div>
          <div>
            <InputField
              name="confirmPassword"
              label={t('globals.confirmPassword')}
              type="password"
              placeholder={t('globals.confirmPassword')}
              onChange={(e) => handleChange(e)}
              alertText={errors.confirmPassword}
              error={!!errors.confirmPassword}
            />
          </div>
          <SubmitInput
            isDisabled={!isValid || isSubmitting}
            value={t('globals.saveNewPassword')}
            className={styles.saveNewPassword}
            type="primary"
            loading={loading}
            size={24}
          />
          <MessageBox
            className={classNames(styles.messageBox, signUpError?.length <= 0 ? styles.hiddenMessageBox : '')}
            message={signUpError}
            isError
          />
        </Form>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  signUpError: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
