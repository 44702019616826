const GET_USER_PER_PROFILE = 'GET_USER_PER_PROFILE';
const GET_USER_PER_PROFILE_SUCCESS = 'GET_USER_PER_PROFILE_SUCCESS';
const GET_USER_PER_PROFILE_FAIL = 'GET_USER_PER_PROFILE_FAIL';

const UPDATE_USER_PER_PROFILE = 'UPDATE_USER_PER_PROFILE';
const UPDATE_USER_PER_PROFILE_SUCCESS = 'UPDATE_USER_PER_PROFILE_SUCCESS';
const UPDATE_USER_PER_PROFILE_FAIL = 'UPDATE_USER_PER_PROFILE_FAIL';

const ADD_PROFILE_PER_USER = 'ADD_PROFILE_PER_USER';
const ADD_PROFILE_PER_USER_SUCCESS = 'ADD_PROFILE_PER_USER_SUCCESS';
const ADD_PROFILE_PER_USER_FAIL = 'ADD_PROFILE_PER_USER_FAIL';

const DELETE_PROFILE_PER_USER = 'DELETE_PROFILE_PER_USER';
const DELETE_PROFILE_PER_USER_SUCCESS = 'DELETE_PROFILE_PER_USER_SUCCESS';
const DELETE_PROFILE_PER_USER_FAIL = 'DELETE_PROFILE_PER_USER_FAIL';

const CLEAR_USER_PER_PROFILE = 'CLEAR_USER_PER_PROFILE';

export {
  UPDATE_USER_PER_PROFILE,
  UPDATE_USER_PER_PROFILE_SUCCESS,
  UPDATE_USER_PER_PROFILE_FAIL,

  GET_USER_PER_PROFILE,
  GET_USER_PER_PROFILE_SUCCESS,
  GET_USER_PER_PROFILE_FAIL,

  ADD_PROFILE_PER_USER,
  ADD_PROFILE_PER_USER_SUCCESS,
  ADD_PROFILE_PER_USER_FAIL,

  DELETE_PROFILE_PER_USER,
  DELETE_PROFILE_PER_USER_SUCCESS,
  DELETE_PROFILE_PER_USER_FAIL,

  CLEAR_USER_PER_PROFILE,
};
