/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import constants from "../../../Utils/constants";
import styles from "./CardForm.module.scss";
import { postSubscription } from "../../../Store/Actions/SubscriptionsActions";
import useQuery from "../../../Hooks/useQuery";
import config from "../../../Utils/config";
import { Col } from 'react-bootstrap';
import Loader from "../../Atoms/Loader/Loader";


const style = {
  base: {
    backgroundColor: "transparent",
    color: "#535353",
    lineHeight: "18px",
    fontFamily: "sans-serif",
    fontSmoothing: "antialiased",
    fontSize: "17px",
    "::placeholder": {
      color: "rgba(0, 0, 0, 0.26)",
      fontSize: "17px",
    },
  },
  invalid: {
    color: "red",
  },
};

const CardForm = ({
  authorization,
  data,
  redirectUrl,
  callback,
  currency,
  id,
  coupon,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cardLoaded, setCardLoaded] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const card = useRef();
  const tap = useRef(window.Tapjsli(config.tapKey));
  const { language } = useSelector((state) => state.authentication);
  const { result: subsriptionResult, error } = useSelector(
    (state) => state.subscription
  );
  const { source } = useSelector((state) => state.saveSourceSubscription);
  const qrId = useQuery()?.get("qrId");
  const redirect_url = useQuery()?.get("redirect_url");

  const labels = useMemo(
    () => ({
      cardNumber: t("payment.cardNumber"),
      expirationDate: "MM/YY",
      cvv: "CVV",
      cardHolder: t("payment.cardHolder"),
    }),
    [t]
  );

  const paymentOptions = useMemo(
    () => ({
      currencyCode: ["USD", "KWD", currency],
      labels,
      TextDirection: language === "ar" ? "rtl" : "ltr",
    }),
    [language]
  );

  const formatError = (errors) => {
    try {
      if (!errors.key) {
        return "تحقق من اتصالك بالإنترنت";
      }
      switch (errors.key) {
        case "card_number_required":
          return t("payment.card_number_required");
        case "error_invalid_card":
          return t("payment.error_invalid_card");
        case "expiry_date_required":
        case "expiry_year_required":
          return t("payment.expiry_year_required");
        case "expiry_date_past":
          return t("payment.expiry_date_past");
        case "cvv_required":
          return t("payment.cvv_required");
        case "card_holder_required":
          return t("payment.card_holder_required");
        case "error_card_holder_short":
          return t("payment.error_card_holder_short");
        case "cvv_digit_required":
          return t("payment.cvv_digit_required");
        default:
          return errors.message;
      }
    } catch {
      return errors.message;
    }
  };

  useEffect(() => {
    try {
      if (!cardLoaded) {
        const elements = tap.current.elements({});
        // create element, pass style and payment options
        card.current = elements.create("card", { style }, paymentOptions);

        if (card.current) {
          // mount element
          card.current.mount("#element-container");
          // card change event listener
          card.current.addEventListener("change", (event) => {
            if (event.loaded) {
              setCardLoaded(true);
            }
            const displayError = document.getElementById("error-handler");
            if (event.error) {
              setLoadingCard(false);
              // displayError.textContent = formatError(event.error);
            } else {
              displayError.textContent = "";
            }
          });
        }
      }
    } catch { }
  }, [cardLoaded]);

  useEffect(() => {
    if (card.current) {
      card.current.clearForm();
    }
  }, [card]);

  useEffect(() => {
    if (subsriptionResult?.trackingId) {
      navigate(
        `${constants.screens.paymentRedirection}?tapId=${subsriptionResult.trackingId
        }${qrId ? `&qrId=${qrId}` : ""}${redirect_url ? `&origin=${redirect_url}` : ""
        }`
        , {
          state: {
            redirect_url: location.state?.redirect_url
          }
        });
    }
  }, [subsriptionResult, location, qrId, redirect_url]);

  useEffect(() => {
    if (error) {
      const errorElement = document.getElementById("error-handler");
      errorElement.textContent = error;
      setLoadingCard(false);
    }
  }, [error]);

  const onSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      setLoadingCard(true);
      const result = await tap.current.createToken(card.current);
      if (result) {
        if (result.error) {
          // Inform the user if there was an error
          const errorElement = document.getElementById("error-handler");
          errorElement.textContent = formatError(result.error);
        } else {
          const paymentForm = document.getElementById("form-container");
          const formData = {
            ...data,
            token: result.id,
            cardToken: result.card.id,
            authorization,
            redirectUrl,
            callback,
            source: redirectUrl,
            showToken: isMobile,
          };
          Object.keys(formData).map((key) => {
            const input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", key);
            input.setAttribute("id", key);
            input.setAttribute("value", formData[key]);
            paymentForm.appendChild(input);
          });
          if (result) {
            return dispatch(
              postSubscription({
                packageId: id,
                source,
                token: result?.id,
                cardToken: result?.card?.id,
                qrId,
                coupon
              })
            );
          }
        }
      }
    } catch { }
  };

  return (
    <Col className={styles.form}>
      <div
        className={styles.inputsContainer}
        onKeyDown={(event) => event.key === "Enter" && onSubmit()}
      >
        <form
          id="form-container"
          name="myform"
          className="form-group w-100"
          method="post"
        >
          <div id="element-container" />
          <div id="error-handler" className="text-danger" role="alert" />
          <div
            id="success"
            style={{ display: "none", position: "relative", float: "left" }}
          />

          <div className={styles.btnContainer}>
            <Button
              label="دفع"
              variant="light"
              loadingSize="sm"
              loading={loadingCard}
              className={styles.submitBtn}
              disabled={!cardLoaded}
              onClick={onSubmit}
            >
              {loadingCard ? <Loader color="black" size={16} /> : t("payment.pay")}
            </Button>
          </div>
        </form>
      </div>
    </Col>
  );
};

export default React.memo(CardForm);
