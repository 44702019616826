import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { getSearchData } from '../../Store/Actions/GlobalSearchActions';
import LoadMore from '../../Components/Molecules/LoadMoreButton/LoadMoreButton';

function HasMore() {
  const dispatch = useDispatch();
  const searchSelector = useParams();
  const { loading, hasMore, result, error } = useSelector((state) => state.searchData);
  const onClick = () => dispatch(getSearchData(searchSelector.search, result.currentPage + 1));

  if (!hasMore || error) return <></>;
  return <LoadMore onClick={onClick} isLoading={loading} className={isMobile ? 'mb-3' : ''} isRendered={hasMore} />;
}

export default HasMore;
