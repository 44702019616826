import React from 'react';
import constants from '@Utils/constants';
import { Navigate } from 'react-router-dom';
import Bookmarks from '@Pages/Bookmarks/Bookmarks';
import { useSelector } from 'react-redux';

function BookmarksRouter() {
    const { isSignedIn, profile } = useSelector((state) => state.authentication);
    if (!isSignedIn) {
        return <Navigate to={constants.screens.login} />
    }
    if (isSignedIn && !profile) {
        return <Navigate to={constants.newScreens.profiles.view} />
    }
    return <Bookmarks />;
}

export default BookmarksRouter