import React, { useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NonAuthenticatedLayout from '../../Components/Organisms/NonAuthenticatedLayout/NonAuthenticatedLayout';
import { forgotPassword } from '../../Store/Actions/ForgotPasswordActions';
import ForgotPasswordForm from '../../Components/Organisms/ForgotPasswordForm';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, error, result } = useSelector((state) => state.forgotPassword);
  const [params] = useSearchParams();

  const onSubmit = (values) => {
    dispatch(forgotPassword(values.email));
  };

  const getMessage = useCallback(() => {
    if (!(error || result)) return null;

    if (error) {
      if (error.response?.data?.data?.message) {
        return error.response?.data?.data?.message;
      }

      return t('errors.somethingWentWrong');
    }

    return t('globals.forgotPasswordEmailSent');
  }, [t, error, result]);

  return (
    <NonAuthenticatedLayout title={t('globals.forgotPass')} showLogo={false}>
      <ForgotPasswordForm
        email={params.get('email')}
        onSubmit={onSubmit}
        error={error}
        loading={loading}
        message={getMessage()}
      />
    </NonAuthenticatedLayout>
  );
};

export default ForgotPassword;
