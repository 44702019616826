import { HIDE_PAYMENT_ALERT, CLEAR_PAYMENT_ALERT } from '../Constants/ShowPaymentAlertConstants';

export const showPaymentAlert = (bool) => ({
  type: HIDE_PAYMENT_ALERT,
  payload: { bool },
});

export const clearShowPaymentAlert = () => ({
  type: CLEAR_PAYMENT_ALERT,
  payload: null,
});
