import PaymentGateway from "@Pages/PaymentGateway/PaymentGateway";
import SelectMethod from "@Pages/SelectMethod/SelectMethod";
import DefaultLayout from "@Templates/DefaultLayout";
import constants from "@Utils/constants";
import { RouteObject } from "react-router-dom";
import ErrorComponent from "src/HOC/ErrorComponent";
import { MoviePurchasePaymentLoader, SeasonPurchasePaymentLoader, SubscriptionPaymentLoader } from "./PaymentRoute.utils";

const PaymentRouter: Array<RouteObject> = [
    {
        element: <DefaultLayout />,
        errorElement: <ErrorComponent pageType={constants.screens.choosePayment} destination={constants.screens.browse} />,
        hasErrorBoundary: true,
        children: [
            {
                path: constants.newScreens.paymentSuccess,
                element: <PaymentGateway title={'payment.success'} />,
                errorElement: <ErrorComponent pageType={constants.screens.paymentRedirection} destination={constants.screens.browse} />,
            },
            {
                path: constants.newScreens.paymentFailure,
                element: <PaymentGateway destination={constants.screens.browse} title={'payment.failed'} />,
                hasErrorBoundary: true,
                errorElement: <ErrorComponent pageType={constants.screens.paymentRedirection} destination={constants.screens.browse} />,
            }
        ]
    },
    {
        element: <DefaultLayout navbarPosition={{ sticky: 'top' }} />,
        errorElement: <ErrorComponent pageType={constants.screens.choosePayment} destination={constants.screens.browse} />,
        hasErrorBoundary: true,
        children: [
            {
                path: constants.newScreens.pay.subscription.selectMethod().href,
                element: <SelectMethod />,
                loader: SubscriptionPaymentLoader,
                errorElement: <ErrorComponent pageType={constants.screens.choosePayment} destination={constants.screens.browse} />,
            },
            {
                path: constants.newScreens.pay.purchase.movie().href,
                element: <SelectMethod />,
                loader: MoviePurchasePaymentLoader,
            },
            {
                path: constants.newScreens.pay.purchase.series().season().href,
                element: <SelectMethod />,
                loader: SeasonPurchasePaymentLoader,
            },
            {
                path: constants.newScreens.pay.purchase.series().season().episode().href,
                element: <SelectMethod />,
                loader: SeasonPurchasePaymentLoader,
            },
            {
                path: constants.newScreens.pay.purchase.show().season().href,
                element: <SelectMethod />,
                loader: SeasonPurchasePaymentLoader,
            },
            {
                path: constants.newScreens.pay.purchase.show().season().episode().href,
                element: <SelectMethod />,
                loader: SeasonPurchasePaymentLoader,
            }
        ]
    }
]

export default PaymentRouter;