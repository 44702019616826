import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAvatars } from "../../Store/Actions/AvatarActions";
import constants from "../../Utils/constants";
import styles from "./AddPic.module.scss";
import { getUserPerProfile } from "../../Store/Actions/UserProfilesActions";
import ErrorComponent from "../../HOC/ErrorComponent";
import MessageBox from "../../Components/Atoms/MessageBox";
import classNames from "classnames";

const AddPic = ({ setAvatar, setAddPic }) => {
  const dispatch = useDispatch();
  const avatar = useSelector((state) => state.avatar.result);
  const error = useSelector((state) => state.avatar.error);
  const { t } = useTranslation();
  const [imageSelectedError, setImageSelectedError] = useState(null);

  const editProfilePicture = async (file, ext) => {
    setAvatar({ file, ext });
    setAddPic(false);
  };

  const fileSelectHandler = async (event) => {
    const file = event.target.files[0];
    const { type, size } = file;
    const imgData = file.name.split(".");
    const ext = imgData.pop();
    const name = imgData[0];
    const reader = new FileReader();
    if (!type.startsWith("image")) {
      setImageSelectedError(t("fileInput.notSupported"));
      return;
    }
    if (size > constants.fileInput.imageSize) {
      setImageSelectedError(`${t("fileInput.imageSize")} 6MBs`);
      return;
    }
    reader.readAsDataURL(file);
    reader.onload = () => {
      editProfilePicture(reader.result.split(","), ext, name);
      setImageSelectedError(null);
    };
  };

  useEffect(() => {
    dispatch(getUserPerProfile());
    dispatch(getAvatars());
  }, [dispatch]);

  if (error) {
    return (
      <ErrorComponent error={error} pageType={constants.newScreens.profiles.view} />
    );
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        <Button
          className={styles.blueButton}
          onClick={() => document.getElementById("selectImage").click()}>
          <span className={styles.blueBtnText}>
            {t("profiles.uploadNewImage")}
          </span>
          <div className="pb-1 editImageContainer">
            <input
              id="selectImage"
              hidden
              type="file"
              onChange={(event) => fileSelectHandler(event)}
              accept={constants.fileInput.acceptImagesOnly}
            />
          </div>
        </Button>
      </div>
      {imageSelectedError && (
        <div className={styles.messageBox}>
          <MessageBox isError message={imageSelectedError} />
        </div>
      )}
      {avatar?.length > 0 && (
        <div className="d-flex justify-content-center">
          <div className={styles.horizontalLine} />
          <span className={styles.options}>{t("profiles.orYouCanChoose")}</span>
          <div className={styles.horizontalLine} />
        </div>
      )}
      <div className={classNames(styles.grid, "pt-5")}>
        {avatar?.map((e) => (
          <div
            className={styles.rectangle}
            style={{ backgroundImage: `url(${e.link})` }}
            onClick={() => {
              setAvatar({ file: e.link, ext: "" });
              setAddPic(false);
            }}
          />
        ))}
      </div>
    </Fragment>
  );
};

AddPic.propTypes = {
  setAddPic: PropTypes.func.isRequired,
  setAvatar: PropTypes.func.isRequired,
};

export default AddPic;
