import {
  CLEAR_VIDEO_SETTINGS,
  GET_VIDEO_SETTINGS,
} from '../Constants/VideoSettingsConstants';

export const getVideoSettings = () => ({
  type: GET_VIDEO_SETTINGS,
  payload: {
    request: {
      url: 'api/video/settings',
      method: 'get',
    },
  },
});

export const clearVideoSettings = () => ({
  type: CLEAR_VIDEO_SETTINGS,
  payload: null,
});
