import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from '../../Utils/constants';
import Genre from '../../Components/Organisms/Genre/Genre';

import { getActorsMoviesShows } from '../../Store/Actions/GetCastAndCrewActions';
import ErrorComponent from '../../HOC/ErrorComponent';

let arr = [];
let limit = 5;

const Actors = () => {
  const dispatch = useDispatch();
  const idSelector = useParams();
  const { t } = useTranslation();
  const { result, loading, error } = useSelector((state) => state.castAndCrew);
  const [array, setArray] = useState([]);
  const { language } = useSelector((state) => state.authentication);

  useEffect(() => {
    arr = [];
    setArray([]);
    for (let i = 0; i < Math.min(result?.data?.length, 24); i += 1) {
      arr.push(result?.data[i]);
    }
    setArray([...arr]);
  }, [result]);

  const showLess = useCallback(() => {
    if (!result || !result?.data) {
      return;
    }
    let i = arr.length;
    limit += 24;
    if (
      array.length < result?.data?.length
      && result?.data?.length - array.length > limit
    ) {
      for (i; i < limit; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
    if (result?.data?.length - array.length < limit) {
      for (i; i < result?.data?.length; i += 1) {
        arr.push(result?.data[i]);
      }
      setArray([...arr]);
    }
  }, [result, array.length]);

  useEffect(() => {
    // dispatch(clearSelectedMovie());
    dispatch(getActorsMoviesShows(idSelector.id));
  }, [dispatch, idSelector.id]);

  if (error) {
    return (
      <ErrorComponent
        error={error}
        pageType={constants.screens.actor('').href}
      />
    );
  }

  return (
    <Genre
      loading={loading}
      pageTitle={result?.pageTitle || t('movieDetails.actors')}
      array={array}
      showLess={showLess}
      result={result}
    />
  );
};
export default Actors;
