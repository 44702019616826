import ShowMoreText from 'react-show-more-text';
import styles from './TextTrimmer.module.scss';
import { Fragment } from 'react/jsx-runtime';
import classNames from 'classnames';
import parse from 'html-react-parser';

interface ITextTrimmerProps {
  lines: number;
  text: string;
  expanded: boolean;
  more: string;
  less: string;
  overrideAnchorStyles?: string;
  onClick?: () => void;
}

const TextTrimmer: React.FC<ITextTrimmerProps> = ({ lines = 2, text = '', expanded = false, more = '', less = '', overrideAnchorStyles = '', onClick }) => {

  if (!text) return <Fragment />;
  return (
    <ShowMoreText
      lines={lines}
      more={more}
      less={less}
      expanded={expanded}
      anchorClass={classNames(styles.showMoreStyle, overrideAnchorStyles)}
      onClick={onClick}
    >
      {parse(text)}
    </ShowMoreText>
  );
};

export default TextTrimmer;
