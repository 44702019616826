import ChevronRight from '@Assets/Images/nouveau/chevron-right.svg';
import ChevronLeft from '@Assets/Images/nouveau/chevron-left.svg';
import { useTranslation } from 'react-i18next';
import styles from './DesktopLayout.module.scss';
import classNames from 'classnames';
import i18n from 'src/i18n';

function DesktopLayout({ goBack }: { goBack: () => void }) {
    const { t } = useTranslation();
    const dir = i18n.dir();
    const icon = dir === 'ltr' ? ChevronLeft : ChevronRight;


    return (
        <button className={classNames(styles.backButton, styles[dir])} onClick={goBack}>
            <img src={icon} alt="back" />
            <span>{t('details.goBack')}</span>
        </button>
    )
}

export default DesktopLayout