import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { DropdownMenu } from "react-bootstrap";
import MovieList from "../MoviesList/MoviesList";
import MovieCard from "../../Molecules/MovieCard/MovieCard";
import { isShowOrSeries } from "../../../Utils/common";
import LoadMore from "../../Molecules/LoadMoreButton/LoadMoreButton";
import styles from "./FilteredGenre.module.scss";
import Loading from "@Pages/Search/Loading";
import CustomToggle from "@Atoms/CustomToggle/CustomToggle";

const Genre = ({
  genresLoading,
  genres,
  selectedGenre,
  onGenreChange,
  data,
  showMoreBtn,
  onSelectMore,
  filtered,
  title,
  type,
  loading,
}) => {
  const { t } = useTranslation();

  const renderSections = () => {
    if (!data) return null;

    return (
      <div className={styles.sectionsWrapper}>
        {data.map((section, index) => (
          <MovieList
            key={index}
            movies={section.data}
            sectionTitle={section.title || section.type}
            listType={type}
            displayType={section.displayType}
            listHeaderWrapperClassName={styles.listHeaderWrapper}
            swiperClassName={styles.swiperClassName}
          />
        ))}
      </div>
    );
  };

  const renderList = () => {
    if (!data) return <Fragment />;
    return (
      <div className={styles.searchContainer}>
        {data.flat().map((item, index) => {
          const isFree = isShowOrSeries(type || item?.video?.type)
            ? item?.containsFreeEpisodes
            : item?.isContinueWatching
            ? item?.video?.paid === false
            : item?.paid === false;

          return (
            <MovieCard
              key={index}
              {...item}
              isFree={isFree}
              titleUrl={window.decodeURI(item?.slug)}
            />
          );
        })}
      </div>
    );
  };

  const renderLoading = () => {
    if (loading) return <Loading />;
    if (!data || data?.length === 0 || data?.flat().length === 0) {
      return (
        <div className={styles.noData}>
          <span>{`${t("globals.noSearchFound")} ${selectedGenre}`}</span>
        </div>
      );
    }

    return null;
  };

  if (genresLoading) return <Loading />;
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <Dropdown onSelect={onGenreChange}>
          <Dropdown.Toggle isLoading={genresLoading} as={CustomToggle}>
            <span>{selectedGenre || t("globals.all")}</span>
          </Dropdown.Toggle>
          <DropdownMenu
            style={{
              maxHeight: "35vh",
              overflowY: "auto",
            }}
          >
            {genres && (
              <Fragment>
                <Dropdown.Item as="button">{t("globals.all")}</Dropdown.Item>
                {genres?.map((genre) => (
                  <Dropdown.Item key={genre.id} as="button" eventKey={genre.id}>
                    {genre?.name}
                  </Dropdown.Item>
                ))}
              </Fragment>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
      {!filtered && renderSections()}
      {filtered && renderList()}
      {renderLoading()}
      <div className="d-flex justify-content-center mt-2 mb-4">
        <LoadMore onClick={onSelectMore} isRendered={showMoreBtn} />
      </div>
    </div>
  );
};

Genre.propTypes = {
  genresLoading: PropTypes.bool,
  genres: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, image: PropTypes.string })
  ),
  selectedGenre: PropTypes.string,
  onGenreChange: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    PropTypes.arrayOf(PropTypes.array),
  ]),
  showMoreBtn: PropTypes.bool,
  onSelectMore: PropTypes.func.isRequired,
  filtered: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Genre.defaultProps = {
  genresLoading: false,
  genres: null,
  data: null,
  showMoreBtn: false,
  selectedGenre: null,
};

export default Genre;
