import React from "react";
import SocialLogin from "react-social-login";

function SocialButton(props) {
  const { children, triggerLogin, ...rest } = props;
  return (
    <div
      className="clickable h-100 m-0 p-0"
      aria-hidden="true"
      onClick={triggerLogin}
      {...rest}
    >
      {children}
    </div>
  );
}

const MemoizedSocialLogin = SocialLogin(SocialButton);
export default MemoizedSocialLogin;
