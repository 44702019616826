import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RedAlertIcon from '../../../Assets/Images/redAlertInfo.svg';
import { verifyCoupon, setCoupon, clearCoupon } from '../../../Store/Actions/CouponActions';
import './CouponForm.scss';

const CouponForm = ({ price, currency }) => {
  const { coupon } = useSelector((state) => state.couponReducer);
  const [error, setError] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(price);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const couponVerification = useSelector((state) => state.couponReducer);
  const prevVerificationResultRef = useRef();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(verifyCoupon(values.coupon))
      .then(() => dispatch(setCoupon(values.coupon)))
      .catch(() => setVerificationStatus(null))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (coupon) {
      dispatch(verifyCoupon(coupon))
        .then(() => dispatch(setCoupon(coupon)))
        .catch(() => setVerificationStatus(null));
    }

    return () => {
      dispatch(clearCoupon());
    };
  }, [dispatch]);

  useEffect(() => {
    if (couponVerification?.verificationResult !== prevVerificationResultRef.current) {
      const { verificationResult } = couponVerification;

      if (verificationResult) {
        if (verificationResult.valid) {
          const discountPercent = verificationResult.discount || 0;
          const discountAmount = price - (price * discountPercent) / 100;
          setPriceAfterDiscount(discountAmount);
          setVerificationStatus(true);
          setError(null);
        } else {
          setVerificationStatus(false);
          setError(t('coupon.errorInvalidCoupon'));
        }
      } else {
        setVerificationStatus(null);
        setError(null);
      }

      prevVerificationResultRef.current = couponVerification?.verificationResult;
    }
  }, [couponVerification, t, price, dispatch]);

  const successMessage = t('coupon.successPromoCodeApplied');

  return (
    <div className="coupon-form-container">
      <Formik initialValues={{ coupon: coupon ?? '' }} onSubmit={onSubmit}>
        {({
          handleSubmit, errors, touched, isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="coupon-form">
            <div className="coupon-input-container">
              <Field
                id="coupon"
                name="coupon"
                type="text"
                className="coupon-input"
                placeholder={t('coupon.placeholderAddPromoCode')}
              />
              {errors.coupon && touched.coupon && (
                <div className="error-message">
                  <span className="error-icon">
                    <img src={RedAlertIcon} alt={t('altError')} />
                  </span>
                  {errors.coupon}
                </div>
              )}
              <Button
                type="submit"
                className="apply-button"
                disabled={isSubmitting}
              >
                {t('coupon.apply')}
              </Button>
            </div>

            {error && (
              <div className="error-message">
                <span className="error-icon">
                  <img src={RedAlertIcon} alt="error" />
                </span>
                {error}
              </div>
            )}

            {verificationStatus && (
              <div className="discounted-price-container">
                <span className="success-message">{successMessage}</span>
                <div className="prices">
                  <span className="new-price">
                    {priceAfterDiscount.toFixed(2)}
                    {currency}
                  </span>
                  <span className="old-price">
                    {price.toFixed(2)}
                    {currency}
                  </span>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CouponForm;
