//@ts-nocheck
//TODO: Remove @ts-nocheck
import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, } from 'react-redux';
import { useAppSelector } from '@Store/hooks';
import classNames from 'classnames';
import Play from '@Assets/Images/nouveau/play-black.svg';
import infoIcon from '@Assets/Images/info-icon.svg';
import comingSoon from '@Assets/Images/Icons/coming-soon.svg';
import constants from '../../../../Utils/constants';
import { isShowOrSeries, handleGuestLogin, redirectToDetails } from '../../../../Utils/common';
import styles from './CardDetails.module.scss';
import TextTrimmer from '../../../Atoms/TextTrimmer/TextTrimmer';
import { getPlayerStatus } from '../../../../Store/Actions/PlayerStatusActions';
import MuteButton from './MuteButton/MuteButton';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { IPlayerStatus, MediaType, PurchasableMedia } from '@Utils/commonTypes';
import { setPaymentModalData } from '@Store/Actions/PaymentModalActions';
import { setCookie } from '@Organisms/MarketingPopup/MarketingPopup.utils';

interface IFeaturedMediaCardDetailsProps {
  showInfoButton?: boolean;
  id: number;
  image?: string;
  description?: string;
  showMuteButton?: boolean;
  muted?: boolean;
  onClickMute?: () => void;
  playing: boolean;
  title: string;
  type: string;
  titleImage?: string;
  generalText?: string;
  isComingSoon?: boolean;
  showSlider?: boolean;
  slug: string;
  infoImage?: string;
  hasVideoEnded?: boolean;
}

const CardDetails: React.FC<IFeaturedMediaCardDetailsProps> = ({
  id,
  description,
  showMuteButton = false,
  muted = true,
  onClickMute,
  playing,
  title,
  type,
  titleImage,
  generalText = null,
  isComingSoon,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSignedIn, isGuest } = useAppSelector((state) => state.authentication);
  const { language } = useAppSelector((state) => state.authentication);

  const onClickSecondaryButton = useCallback(() => redirectToDetails(navigate, id, type), [id, navigate, type]);

  // eslint-disable-next-line consistent-return
  const onClickPrimaryButton = useCallback(async () => {
    if (isComingSoon) {
      return onClickSecondaryButton();
    }
    if (!isGuest && !isSignedIn) {
      await handleGuestLogin();
    }
    dispatch(getPlayerStatus(id, isShowOrSeries(type) ? 'show' : 'movie'))
      .then((response) => {
        const { result: playerRes }: { result: IPlayerStatus } = response?.payload?.data?.data || {};
        if ((playerRes?.owned || !playerRes?.paid) && !playerRes?.comingSoon) {
          navigate(constants.newScreens.watch(playerRes?.id).as);
          return;
        }

        let destination = '';
        if (playerRes?.comingSoon) {
          switch (type) {
            case 'show':
              destination = constants.newScreens.details.show(id).as;
              break;
            case 'movie':
              destination = constants.newScreens.details.movie(id).as;
              break;
            case 'series':
              destination = constants.newScreens.details.series(id).as;
              break;
          }
          navigate(destination);
          return;
        }

        switch (type) {
          case MediaType.Show:
            destination = constants.newScreens.details.show(id).season(playerRes?.season).as;
            dispatch(setPaymentModalData({ purchaseId: playerRes?.id, purchaseType: PurchasableMedia.Episode }));
            break;
          case MediaType.Movie:
            destination = constants.newScreens.details.movie(id).as;
            dispatch(setPaymentModalData({ purchaseId: playerRes?.id, purchaseType: PurchasableMedia.Movie }));
            break;

          case MediaType.Series:
            destination = constants.newScreens.details.series(id).season(playerRes?.season).as;
            dispatch(setPaymentModalData({ purchaseId: playerRes?.id, purchaseType: PurchasableMedia.Episode }));
            break;
          default:
            break;
        }

        setCookie(constants.locationState.openPayment, true);
        if (isSignedIn) navigate(destination);
        else {
          navigate({
            pathname: constants.screens.login,
            search: queryString.stringify({ redirect_url: destination }),
          });
        }
      });
  }, [isComingSoon, isGuest, isSignedIn, dispatch, id, type, onClickSecondaryButton, navigate]);

  const primaryButtonProps = useMemo(() => {
    const props = {
      icon: Play,
      text: t('globals.watchNow'),
    };

    switch (true) {
      case isComingSoon:
        props.text = t('globals.comingSoon');
        props.icon = comingSoon;
        break;

      default:
        props.icon = Play;
        props.text = t('globals.watchNow');
        break;
    }

    return props;
  }, [isComingSoon, t]);

  return (
    <div className={styles.detailsContainer}>
      {!isMobile && (
        <Fragment>
          <div className={styles.detailsContentContainer}>
            <div className={classNames(language === 'ar' ? styles.titleContainer : styles.titleContainerEng,
              playing ? styles.hiding : null,
              !description && playing ? styles.lessHiding : null,
            )}
            >
              {titleImage && (
                <div className={styles.title}>
                  <img src={titleImage} alt={title} width="100%" height="100%" />
                </div>
              )}
            </div>
          </div>
          <div className={styles.containerPadding}>
            {description && (
              <div className={classNames(styles.description, playing ? styles.hiding : null)}>
                <span className={classNames(styles.descriptionFade, playing ? styles.hiding : '')}>
                  <TextTrimmer text={description} expanded more="" less="" />
                </span>
              </div>
            )}
          </div>
        </Fragment>
      )}


      <div className={classNames(styles.actionsContainer, styles.containerPadding)}>
        <div className={styles.buttonsGroup}>
          <Button
            className={classNames(styles.primaryButton)}
            onClick={onClickPrimaryButton}
            variant='primary'
            size='lg'
          >
            {primaryButtonProps?.icon && <img src={primaryButtonProps.icon} className={styles.playButton} alt="primary button icon" />}
          </Button>
          <Button
            variant='secondary'
            className={styles.secondaryButton}
            onClick={onClickSecondaryButton}
            size='lg'
          >
            <img src={infoIcon} alt="info" />
          </Button>
        </div>
        <MuteButton status={muted ? 'muted' : 'unmuted'} shouldRender={showMuteButton} onClick={onClickMute} />
      </div>


      {generalText && (
        <div className={styles.containerPadding}>
          <div className={styles.tag}>{generalText}</div>
        </div>
      )}
    </div>
  );
};

export default memo(CardDetails);
