import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  CLEAR_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  CLEAR_EDIT_USER_IMAGE,
  EDIT_USER_IMAGE,
  EDIT_USER_IMAGE_FAIL,
  EDIT_USER_IMAGE_SUCCESS,
  REMOVE_USER_IMAGE,
  REMOVE_USER_IMAGE_SUCCESS,
} from '../Constants/UserConstants';

const initialState = {
  loading: null,
  profile: null,
  error: null,
  success: null,
  result: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        profile: action.payload.data.data.result,
      };
    }
    case GET_USER_PROFILE_FAIL: {
      return {
        ...state,
        profile: {
          fullName: '',
          email: '',
          address: '',
          message: '',
        },
        error: action.error,
        loading: false,
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }
    case CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        error: action.error?.response?.data?.data?.message,
        loading: false,
      };
    }
    case UPDATE_USER_PROFILE: {
      return {
        ...state,
        error: null,
        loading: true,
        success: null,
      };
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      const profile = { ...action.payload.data.data.result };
      delete profile.image;
      return {
        ...state,
        loading: false,
        profile: {
          ...state.profile,
          ...profile,
        },
        success: true,
      };
    }
    case UPDATE_USER_PROFILE_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
        success: false,
      };
    }
    case CLEAR_USER_PROFILE: {
      return {
        ...initialState,
      };
    }
    case REMOVE_USER_IMAGE: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case REMOVE_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
      };
    }
    case EDIT_USER_IMAGE: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case EDIT_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    }
    case EDIT_USER_IMAGE_FAIL: {
      return {
        loading: false,
        error: action.error,
      };
    }
    case CLEAR_EDIT_USER_IMAGE: {
      return {
        ...state,
        loading: false,
        result: null,
        error: null,
      };
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
