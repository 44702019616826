import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  Col, Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { InputField, SubmitInput } from '../../Atoms';
import MessageBox from '../../Atoms/MessageBox';
import {
  getUserProfile,
  updateUserProfile,
} from '../../../Store/Actions/UserActions';
import './ProfileForm.scss';
import countryData from '../../../Utils/countryData';
import CodeFlag from '../../Atoms/CodeFlag/CodeFlag';

/* eslint-disable  jsx-a11y/no-static-element-interactions */
/* eslint-disable  jsx-a11y/click-events-have-key-events */

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  username: yup.string().required('validation.required'),
  phoneNumber: yup.string().min(5, 'validation.invalidPhoneNumber').matches(phoneRegExp, 'validation.invalidPhoneNumber'),

});
const customStyles = {
  input: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    backgroundColor: '#13121b',

  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    paddingInlineStart: isMobile ? '2px' : '6px',
  }),
  control: (provided) => ({
    ...provided,
    zIndex: 10,
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',

  }),
  clearIndicator: (provided) => ({
    ...provided,
    zIndex: 10,
    color: '#fff',
    border: 'none',
    paddingInlineStart: isMobile ? '0' : '6px',
    paddingInlineEnd: isMobile ? '2px' : '6px',

  }),
  singleValue: (provided) => ({
    ...provided,
    zIndex: 10,
    color: '#fff',
    border: 'none',
    direction: 'ltr',
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 10,
    color: '#fff',
    border: '1px solid var(--color-action)',
  }),
  option: (styles, state) => ({
    ...styles,
    color: '#fff',
    backgroundColor: state.isSelected ? '#d0a453' : 'transparent',
    direction: 'ltr',
    textAlign: 'right',
    '&:hover': {
      backgroundColor: '#d0a453',
    },
    '&:active': {
      backgroundColor: '#d0a453 ',
    },
    '&:focus': {
      backgroundColor: '#d0a453',
    },
  }),

};
const ProfileForm = ({
  profile, loading, success, hasTouched, setHasTouched, language,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [counrtyCode, setcounrtyCode] = useState(profile?.phoneCode ? countryData.find((e) => e.label === profile.phoneCode) : null);
  const onSubmit = (value) => {
    dispatch(updateUserProfile(value.username || profile.name, counrtyCode?.label || '', value.phoneNumber)).then(() => {
      dispatch(getUserProfile());
    });
  };

  return (
    profile && (
      <div className="editContainer">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            username: profile?.name,
            phoneNumber: profile?.phoneNumber,
            email: profile?.email,
            password: '● ● ● ● ● ● ● ●',
          }}
          onSubmit={(props) => onSubmit(props)}
        >
          {({ handleSubmit, handleChange, errors }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                handleSubmit(e);
                setHasTouched(false);
              }}
            >
              <div>
                <div>
                  <InputField
                    name="email"
                    label={t('globals.emailAddress')}
                    type="email"
                    placeholder={t('globals.emailAddress')}
                    onChange={(e) => handleChange(e)}
                    alertText={errors.email}
                    error={!!errors.email}
                    autocomplete="off"
                    profile={profile && profile.email}
                    readOnly
                  />
                </div>
                <div>
                  <Row>
                    {language === 'ar' ? (
                      <div className='phoneNumberContainer'>
                        <Col lg={8} md={7} sm={8} xs={8} className="pl-3">
                          <InputField
                            name="phoneNumber"
                            label={t('globals.phoneNumber')}
                            type="text"
                            placeholder={t('globals.phoneNumber')}
                            onChange={(e) => {
                              handleChange(e);
                              setHasTouched(true);
                            }}
                            alertText={errors.phoneNumber}
                            error={!!errors.phoneNumber}
                            autocomplete="off"
                          />
                        </Col>
                        <Col lg={4} md={5} sm={4} xs={4} className="pt-3 pl-0 select" styles={{ writingDirection: 'ltr' }}>
                          <Select
                            options={countryData}
                            value={counrtyCode}
                            isClearable
                            name="country"
                            placeholder={language === 'ar' ? 'أختر' : 'Choose'}
                            onChange={(selected) => {
                              setcounrtyCode(selected);
                              setHasTouched(true);
                            }}
                            styles={customStyles}
                            components={{ Option: CodeFlag }}
                          />
                        </Col>
                      </div>
                    ) : (
                      <div className='phoneNumberContainer'>
                        <Col lg={4} md={5} sm={4} xs={4} className="pt-3 pl-0 select" styles={{ writingDirection: 'ltr' }}>
                          <Select
                            options={countryData}
                            value={counrtyCode}
                            isClearable
                            name="country"
                            placeholder={language === 'ar' ? 'أختر' : 'Choose'}
                            onChange={(selected) => {
                              setcounrtyCode(selected);
                              setHasTouched(true);
                            }}
                            styles={customStyles}
                            components={{ Option: CodeFlag }}
                          />
                        </Col>
                        <Col lg={8} md={7} sm={8} xs={8} className="pl-3">
                          <InputField
                            name="phoneNumber"
                            label={t('globals.phoneNumber')}
                            type="text"
                            placeholder={t('globals.phoneNumber')}
                            onChange={(e) => {
                              handleChange(e);
                              setHasTouched(true);
                            }}
                            alertText={errors.phoneNumber}
                            error={!!errors.phoneNumber}
                            autocomplete="off"
                          />
                        </Col>
                      </div>
                    )}

                  </Row>
                </div>
                {success === true
                  && !!errors.phoneNumber === false
                  && !!errors.username === false
                  && !hasTouched && <MessageBox message={t('globals.operationSuccess')} isError={false} />}
                {success === false
                  && !!errors.phoneNumber
                  && !!errors.username
                  && !hasTouched && <MessageBox message={t('errors.somethingWentWrong')} isError />}
                <div className="d-flex justify-content-center">
                  <SubmitInput value={t('globals.saveInfo')} type="editUserProfile" loading={loading} size={24} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    ));
};

ProfileForm.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  /* eslint-disable-next-line react/forbid-prop-types */
  profile: PropTypes.object,
  // canChangePassword: PropTypes.bool,
};

ProfileForm.defaultProps = {
  loading: false,
  success: false,
  profile: '',
};

export default ProfileForm;
