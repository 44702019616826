import React, { useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styles from './PlayerEpisodes.module.scss';
import ClickableItem from '@Atoms/ClickableItem/ClickableItem';
import Back from '../../../Assets/Images/VideoControls/Back.svg';
import PlayerSeason from '../../Atoms/PlayerSeason/PlayerSeason';
import PlayerEpisode from '../../Atoms/PlayerEpisode/PlayerEpisode';

const PlayerEpisodes = ({ episodes, season, episodeId, onClick }) => {
  const [showSeasons, setShowSeasons] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(season);
  const [activeKey, setActiveKey] = useState(episodeId);
  const { language } = useSelector((state) => state.authentication);

  const renderSeasons = () => {
    if (Object.entries(episodes).length <= 0) {
      return null;
    }

    return Object.entries(episodes).map((item, index) => {
      if (item[1]?.episodes?.length <= 0) {
        return null;
      }

      return (
        <PlayerSeason
          key={item[0]}
          title={item[1].title}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            setShowSeasons((prevState) => !prevState);
            setSelectedSeason(item[0]);
          }}
          showSeparator={
            Object.keys(episodes).length < 5
            || (Object.keys(episodes).length >= 5
              && index + 1 < Object.keys(episodes).length)
          }
        />
      );
    });
  };

  const renderEpisodes = () => (
    <Fragment>
      <ClickableItem
        classes={styles.episodesHeader}
        style={language === 'ar' ? {} : { direction: 'ltr' }}
        onClick={(event) => {
          if (Object.entries(episodes)?.length > 1) {
            event.preventDefault();
            event.stopPropagation();

            setShowSeasons((prevState) => !prevState);
          }
        }}
      >
        {Object.entries(episodes)?.length > 1 && (
          <div className={language === 'ar' ? styles.back : styles.backEng}>
            <img src={Back} alt="Go Back" />
          </div>
        )}

        <h5 className={classNames(styles.title, styles.textRight)}>
          {episodes[selectedSeason].title}
        </h5>
      </ClickableItem>
      <Accordion
        className={styles.scrollbar}
        activeKey={activeKey}
        onSelect={(e) => setActiveKey(e)}
        flush
      >
        {episodes[selectedSeason].episodes.map((episode, index) => (
          <PlayerEpisode
            language={language}
            key={episode.id}
            id={episode.id}
            episodeNbr={index + 1}
            title={episode.title}
            track={episode.track}
            duration={episode.duration}
            image={episode.image}
            description={episode.description || ''}
            isOpen={episode.id === activeKey}
            onClick={() => onClick(episode, episodes[selectedSeason])}
          />
        ))}
      </Accordion>
    </Fragment>
  );

  const renderContent = () => {
    if (!episodes || episodes.length === 0) {
      return null;
    }

    if (showSeasons) {
      return renderSeasons();
    }

    return renderEpisodes();
  };

  return (
    <>
      <Container
        fluid
        className={classNames(
          styles.episodesContainer,
          showSeasons ? styles.scrollbar : null,
        )}
      >
        {renderContent()}
      </Container>
    </>
  );
};

PlayerEpisodes.propTypes = {
  episodes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  season: PropTypes.number.isRequired,
  episodeId: PropTypes.string.isRequired,
};

export default memo(PlayerEpisodes);
