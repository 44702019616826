import {
  CLEAR_ACTORS,
  GET_ACTORS,
  GET_ACTORS_SUCCESS,
  GET_ACTORS_FAIL,
  CLEAR_AWARDS,
  GET_AWARDS,
  GET_AWARDS_SUCCESS,
  GET_AWARDS_FAIL,
} from '../Constants/CastAndCrewConstants';
/* eslint-disable  no-param-reassign */
/* eslint-disable  no-plusplus */

const initialState = {
  type: null,
  result: null,
  loading: false,
  error: null,
};

const CastAndCrewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTORS:
      return {
        ...state,
        loading: true,
      };
    case GET_ACTORS_SUCCESS:
      return {
        ...state,
        result: action.payload.data.data.result,
        loading: false,
      };
    case GET_ACTORS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_ACTORS:
      return initialState;

    case GET_AWARDS:
      return {
        ...state,
        loading: true,
      };
    case GET_AWARDS_SUCCESS:
      return {
        ...state,
        result: action.payload.data.data.result,
        loading: false,
      };
    case GET_AWARDS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case CLEAR_AWARDS:
      return initialState;

    default:
      return state;
  }
};

export default CastAndCrewReducer;
