import LandscapeImage from "@Atoms/LandscapeImage/LandscapeImage";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import styles from './DesktopLayout.module.scss';
import constants from "@Utils/constants";
import classNames from "classnames";
import utilStyles from "@Utils/utils.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "swiper/modules";
import { useMediaDetailsContext } from "../TrailersSection";
import { processDuration } from "@Utils/common";
import 'swiper/scss';
import 'swiper/scss/grid';


function DesktopLayout() {
    const context = useMediaDetailsContext();
    const { t } = useTranslation();

    const trailers = context?.currentSeason?.trailers ?? context?.media?.trailers;
    return (
        <Swiper spaceBetween={16} grid={{ rows: 3 }} modules={[Grid]} className={styles.root} slidesPerView={2}>
            {trailers?.map((trailer) => {
                const durationInMinutes = processDuration(trailer?.duration);
                return (
                    <SwiperSlide key={trailer.id}>
                        <Link unstable_viewTransition to={constants.newScreens.trailer(trailer.id).as} key={trailer.id} className={classNames(styles.trailer, utilStyles.noDecoration)}>
                            <LandscapeImage
                                imageProps={{ className: styles.image, wrapperProps: { className: styles.imageWrapper }, src: trailer.medium }}
                                rootWrapperProps={{ className: styles.imageWrapper }}
                            />
                            <div className={styles.trailerDescription}>
                                <div className={styles.trailerPrimaryInfo}>
                                    <span className={styles.title}>{trailer.title}</span>
                                    {/* @ts-expect-error issue with type GenericTrailer */}
                                    {trailer?.seasonNumber && <span className={styles.subtitle}>{`${t('details.promoSeason')}${trailer.seasonNumber}`}</span>}
                                </div>
                                {durationInMinutes && (
                                    <div className={styles.duration}>
                                        <span dir="auto">{durationInMinutes} </span>
                                        <span>{t('details.minutes')}</span>
                                    </div>
                                )}
                            </div>
                        </Link>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default DesktopLayout