import classNames from "classnames";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from './NavigationItem.module.scss';
import { INavigationItemProps } from "./NavigationItem.types";
import { NavLink, useLocation } from "react-router-dom";


const NavigationItem: React.FC<INavigationItemProps> = ({ elm, url, overrideClassName, shouldReplace = false, preventScrollReset = false, overrideVariant }) => {
    const { t } = useTranslation();
    const content = elm.type === 'bookmark' ? t('landing.preferences') : elm.name;
    const location = useLocation();

    return (
        <NavLink
            to={{ pathname: url, search: location.search }}
            replace={shouldReplace}
            className={classNames(styles.navbarItem, styles.noTextDecoration, overrideClassName)}
            preventScrollReset={preventScrollReset}
            end
        >
            {({ isActive }) => {
                const variant = overrideVariant ? overrideVariant(isActive) : isActive ? 'light' : 'none';
                return (
                    <Button
                        variant={variant}
                        className={classNames(styles.navbarItem, isActive ? styles.selectedNavbarItem : '')}
                        href={url}
                        as="button"
                    >
                        {content}
                    </Button>
                )
            }}
        </NavLink>
    )
};

export default NavigationItem;